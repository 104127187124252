import { businessApi } from 'helpers/api'
import moment from 'moment'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const schedulesActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'schedules' })

export const fetchSchedules = ({
  city,
  start = moment().startOf('month').format('YYYY-MM-DD'),
  end = moment().endOf('month').format('YYYY-MM-DD'),
  hostname,
  organizationIds,
  tags,
  types,
}) =>
  schedulesActions.fetch({
    url: '/api/plannings',
    query: {
      city,
      end: end,
      hostname,
      organization_ids: organizationIds,
      start: start,
      tags,
      types,
    },
    schema: {
      planning: {
        bookings: [schemas.bookings],
        events: [schemas.events],
        requests: [schemas.requests],
        schedule: [schemas.schedules],
      },
    },
  })

export const fetchSchedulesByEvent = (
  eventId,
  { start = moment().startOf('month').format('YYYY-MM-DD'), end = moment().endOf('month').format('YYYY-MM-DD') },
) =>
  schedulesActions.fetch({
    url: `/api/planning/${eventId}`,
    query: {
      end,
      start,
    },
    schema: {
      planning: {
        bookings: [schemas.bookings],
        events: [schemas.events],
        requests: [schemas.requests],
        schedule: [schemas.schedules],
      },
    },
  })

export const createScheduleEvent = (payload) =>
  schedulesActions.create({
    url: '/api/planning',
    payload,
    schema: { booking: schemas.bookings },
  })

export const scheduleEvent = (payload) =>
  schedulesActions.create({
    url: '/api/planning',
    payload,
    schema: { event: schemas.events },
  })
