// Libraries
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'helpers/proptypes'
import { withTranslation } from 'react-i18next'
// Components
import { Form, Button } from 'semantic-ui-react'
import { SearchInput, SelectInput } from 'components/inputs'
// Selectors
import { getLocation } from 'redux/reducers/router'
import { Link } from 'react-router'
import { getSortedTags } from 'redux/entities/selectors'
// Helpers
import { buildOptionsWithArray } from 'helpers/forms'
import { getTranslatedOptions } from 'helpers/options'
import { organizationTypes } from 'helpers/organizations'

const mapStateToProps = (state) => ({
  fromStore: {
    location: getLocation(state),
    getSortedTags: () => getSortedTags(state),
  },
})

class _OrganizationSearchForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fromStore: PropTypes.shape({
      location: PropTypes.immutable.map.isRequired,
      getSortedTags: PropTypes.func,
    }).isRequired,
  }

  static contextTypes = { router: PropTypes.object }

  tagsOpts = []

  state = {
    search: '',
    type: '',
    tags: '',
  }

  componentDidMount() {
    return this.updateStateFromLocation(this.props)
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.fromStore.location.equals(this.props.fromStore.location)) {
      return this.updateStateFromLocation(this.props)
    }
  }

  updateStateFromLocation(props) {
    return this.setState({
      search: props.fromStore.location.getIn(['query', 'search'], ''),
      type: props.fromStore.location.getIn(['query', 'type'], ''),
      tags: props.fromStore.location.getIn(['query', 'tags'], ''),
    })
  }

  updateQuery(query = this.state) {
    return this.context.router.push(this.props.fromStore.location.mergeIn(['query'], { ...query, offset: 0 }).toJS())
  }

  clearFilters() {
    return this.setState({ search: '', type: '', tags: '' }, () => this.updateQuery())
  }

  handleSubmit(e) {
    e.preventDefault()
    return this.updateQuery()
  }

  getTagsOpts() {
    if (this.tagsOpts.length === 0) {
      const tags = this.props.fromStore.getSortedTags()
      if (tags.size) this.tagsOpts = buildOptionsWithArray(tags.map((t) => t.title))
    }
    return this.tagsOpts
  }

  render() {
    const { t } = this.props
    return (
      <Form onSubmit={this.handleSubmit.bind(this)} style={{ marginBottom: '1em' }} autoComplete='off'>
        <Form.Group widths='equal'>
          <Form.Field>
            <label>{t('Organizations::Search by name')}</label>
            <SearchInput
              placeholder={t('Organizations::Id / Name')}
              fluid
              icon='search'
              iconPosition='left'
              value={this.state.search}
              onChange={(value) => this.setState({ search: value })}
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Organizations::Organization Type')}</label>
            <SelectInput
              queryName='type'
              fluid
              search
              options={getTranslatedOptions(t, organizationTypes)}
              value={this.state.type}
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Organizations::Organization Tags')}</label>
            <SelectInput queryName='tags' fluid multiple search options={this.getTagsOpts()} value={this.state.tags} />
          </Form.Field>

          <Form.Field>
            <label>&nbsp;</label>
            <Button type='submit'>{t('Organizations::Search')}</Button>

            <Button basic onClick={() => this.clearFilters()}>
              {t('Organizations::Clear filters')}
            </Button>

            <Button as={Link} to='/organizations/add' primary style={{ transform: 'translateX(15px)' }}>
              {t('Organizations::Create new')}
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    )
  }
}

export const OrganizationSearchForm = withTranslation('common')(connect(mapStateToProps)(_OrganizationSearchForm))
