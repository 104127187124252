// Libs
import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
// Components
import { Menu, Dropdown, Button } from 'semantic-ui-react'
import { Link } from 'react-router'
// Redux
import { logout } from 'redux/actions/login'
import { getLanguages } from 'redux/entities/selectors'
import { getAppLanguage } from 'redux/reducers/appSettings'
import { getAdminProfile } from 'redux/reducers/admin/selectors'
import { setAppLocale, updateAccountLanguage } from 'redux/entities/actions'
// helpers
import { getTitleFromLocale } from 'helpers/languages'
// style
import './Default.css'

const mapStateToProps = (state) => ({
  fromStore: {
    location: state.getIn(['routing', 'locationBeforeTransitions']),
    language: getAppLanguage(state),
    languages: getLanguages(state)
      .toList()
      .filter((language) => language.translated && language.activated),
    adminProfile: getAdminProfile(state),
  },
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    logout: () => dispatch(logout()),
    setAppLocale: (locale) => dispatch(setAppLocale(locale)),
    updateAccountLanguage: (payload) => dispatch(updateAccountLanguage(payload)),
  },
})

class DefaultHeader extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
      options: PropTypes.shape({
        whitelist: PropTypes.arrayOf(PropTypes.string).isRequired,
      }).isRequired,
    }).isRequired,
    fromStore: PropTypes.shape({
      location: PropTypes.immutable.map.isRequired,
      language: PropTypes.immutable.record.isRequired,
      languages: PropTypes.immutable.list.isRequired,
      adminProfile: PropTypes.immutable.record,
    }).isRequired,
    actions: PropTypes.shape({
      logout: PropTypes.func.isRequired,
      setAppLocale: PropTypes.func.isRequired,
      updateAccountLanguage: PropTypes.func.isRequired,
    }).isRequired,
  }

  handleSelectLanguage = (languageId, locale) => {
    const { fromStore, actions } = this.props
    if (!fromStore.adminProfile.id) return actions.setAppLocale(locale)
    return actions.setAppLocale(locale).then(updateAccountLanguage(fromStore.adminProfile.account_id, languageId))
  }

  render() {
    const { t, i18n, fromStore, actions } = this.props
    const pathname = fromStore.location.get('pathname')

    return (
      <Fragment>
        <Menu pointing borderless className='PrimaryMenu'>
          <Menu.Item as={Link} header to='/' active={pathname === '/'}>
            {t('Layout::Eatwith Community')}
          </Menu.Item>
          <Menu.Item position='right'>
            <Dropdown item text={fromStore.language.locale}>
              <Dropdown.Menu>
                {fromStore.languages
                  .filter(({ locale }) => i18n.options.whitelist.includes(locale))
                  .map(({ id, locale }) => (
                    <Dropdown.Item key={id} onClick={() => this.handleSelectLanguage(id, locale)}>
                      {getTitleFromLocale(locale)}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
            <Button content={t('Layout::Logout')} icon='power' labelPosition='left' basic onClick={actions.logout} />
          </Menu.Item>
        </Menu>
        <Menu pointing secondary borderless className='SecondaryMenu'>
          <Menu.Item as={Link} to={{ pathname: '/users' }} active={pathname.startsWith('/users')}>
            {t('Layout::Users')}
          </Menu.Item>
          <Menu.Item as={Link} to={{ pathname: '/requests' }} active={pathname.startsWith('/requests')}>
            {t('Layout::Requests')}
          </Menu.Item>
          <Menu.Item as={Link} to={{ pathname: '/bookings' }} active={pathname.startsWith('/bookings')}>
            {t('Layout::Bookings')}
          </Menu.Item>
          <Dropdown item text={t('Layout::Experiences')}>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to={{ pathname: '/events' }} active={pathname.startsWith('/events')}>
                {t('Layout::Experiences')}
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to={{ pathname: '/hosts-applications' }}
                active={pathname.startsWith('/hosts-applications')}
              >
                {t('Layout::Experiences to review')}
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to={{ pathname: '/host-applications' }}
                active={pathname.startsWith('/host-applications')}
              >
                {t('Layout::Host Applications')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Menu.Item as={Link} to={{ pathname: '/calendar-events' }} active={pathname.startsWith('/calendar-events')}>
            {t('Layout::Events calendar')}
          </Menu.Item>
          <Menu.Item as={Link} to={{ pathname: '/conversations' }} active={pathname.startsWith('/conversations')}>
            {t('Layout::Conversations')}
          </Menu.Item>
          <Menu.Item as={Link} to={{ pathname: '/offline-reviews' }} active={pathname.startsWith('/offline-reviews')}>
            {t('Layout::Offline reviews')}
          </Menu.Item>
          <Menu.Item as={Link} to={{ pathname: '/reviews' }} active={pathname.startsWith('/reviews')}>
            {t('Layout::Reviews')}
          </Menu.Item>
          <Menu.Item as={Link} to={{ pathname: '/playlists' }} active={pathname.startsWith('/playlists')}>
            {t('Layout::Playlists')}
          </Menu.Item>
          <Menu.Item as={Link} to={{ pathname: '/organizations' }} active={pathname.startsWith('/organizations')}>
            {t('Layout::Organizations')}
          </Menu.Item>
          <Dropdown item text={t('Layout::Partners')}>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to={{ pathname: '/partners' }} active={pathname.startsWith('/partners')}>
                {t('Layout::Partners')}
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to={{ pathname: '/partner-demands' }}
                active={pathname.startsWith('/partner-demands')}
              >
                {t('Layout::Partner Demands')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Menu.Item as={Link} to={{ pathname: '/campaigns' }} active={pathname.startsWith('/campaigns')}>
            {t('Layout::Campaigns')}
          </Menu.Item>
          <Menu.Item as={Link} to={{ pathname: '/tags' }} active={pathname.startsWith('/tags')}>
            {t('Layout::Tags')}
          </Menu.Item>
          <Menu.Item as={Link} to={{ pathname: '/payouts' }} active={pathname.startsWith('/payouts')}>
            {t('Layout::Payouts')}
          </Menu.Item>
          <Menu.Item as={Link} to={{ pathname: '/gallery' }} active={pathname.startsWith('/gallery')}>
            {t('Layout::Gallery')}
          </Menu.Item>
        </Menu>
      </Fragment>
    )
  }
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(DefaultHeader))
