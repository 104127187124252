import { fromJS, Map } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import { types } from './actions'

const initialState = fromJS({})
const handlers = {
  [types.CREATE]: (state, { payload: { formName, initialState } }) => state.merge(fromJS({ [formName]: initialState })),
  [types.REMOVE]: (state, { payload: { formName } }) => state.delete(formName),

  [types.SET_FORM_VALUE]: (state, { payload: { formName, value } }) => state.merge(fromJS({ [formName]: value })),

  [types.MERGE_IN_FORM]: (state, { payload: { formName, value } }) => state.mergeIn([formName], fromJS(value)),

  [types.SET_FORM_FIELD]: (state, { payload: { formName, field, value } }) =>
    state.mergeIn([formName, field], fromJS(value)),
}

export default createReducer(initialState, handlers)

export const getFormsState = (state) => state.get('forms')
export const getForm = (state, formName) => getFormsState(state).get(formName, new Map())
