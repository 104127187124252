import { createReducer } from 'redux-immutablejs'
import { actions } from 'redux/actions/login'
import { fromJS } from 'immutable'

export const initialState = fromJS({
  loading: false,
  error: null,
  profile: {},
  token: null,
})

const handlers = {
  [actions.LOGGING_IN]: (state) =>
    state.merge(
      fromJS({
        error: null,
        loading: true,
      })
    ),
  [actions.LOGGED_IN]: (state, { payload }) =>
    state.merge(
      fromJS({
        error: null,
        loading: false,
        adminId: payload.admin.id,
        userId: payload.user.id,
        token: payload.token || state.get('token') || null,
      })
    ),
  [actions.LOG_IN_ERROR]: (state, { error }) =>
    state.merge(
      fromJS({
        loading: false,
        error,
      })
    ),

  [actions.LOGOUT]: () => initialState,
}

export default createReducer(initialState, handlers)

export * from './selectors'
