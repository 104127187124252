// Libs
import React from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
// Components
import { Link } from 'react-router'
import { Grid, Segment } from 'semantic-ui-react'
// Redux
import { getPartnerDemandRequests, getRequestStatus } from 'redux/entities/selectors'

const mapStateToProps = (state, props) => ({
  fromStore: {
    getRequestStatus: (id) => getRequestStatus(state, { id }),
    requests: getPartnerDemandRequests(state, { id: props.demand.id }),
  },
})

function _DemandRequests({ fromStore: { getRequestStatus, requests } }) {
  const [t] = useTranslation()
  if (requests.size === 0) return null
  return (
    <Segment>
      <Grid>
        {requests.map((request) => (
          <Grid.Row key={request.id}>
            <Grid.Column width={2}>
              <label>{t('PartnerDemands::Summary::ID:')}</label>{' '}
              <Link to={`/requests?search=${request.id}`}>{request.id}</Link>
            </Grid.Column>
            <Grid.Column width={4}>
              <label>{t('PartnerDemands::Summary::Event:')}</label>{' '}
              <Link to={`/events/${request.event_id}`}>{`${request.event_id} - ${request.event.title}`}</Link>
            </Grid.Column>
            <Grid.Column width={4}>
              <label>{t('PartnerDemands::Summary::Host:')}</label>{' '}
              <Link to={`/users/${request.event.user_id}`}>
                {`${request.user_id} - ${request.event.user.firstname} ${request.event.user.lastname}`}
              </Link>
            </Grid.Column>
            <Grid.Column width={4}>
              <label>{t('PartnerDemands::Summary::Guest:')}</label>{' '}
              <Link to={`/users/${request.user_id}`}>
                {`${request.user_id} - ${request.user.firstname} ${request.user.lastname}`}
              </Link>
            </Grid.Column>
            <Grid.Column width={2}>
              <label>{t('PartnerDemands::Summary::Seats (status):')}</label>{' '}
              {`${request.seats} (${getRequestStatus(request.id)})`}
            </Grid.Column>
          </Grid.Row>
        ))}
      </Grid>
    </Segment>
  )
}

_DemandRequests.propTypes = {
  fromStore: PropTypes.shape({
    requests: PropTypes.immutable.list.isRequired,
    getRequestStatus: PropTypes.func,
  }).isRequired,
  demand: PropTypes.immutable.record.isRequired,
}

export const DemandRequests = connect(mapStateToProps)(_DemandRequests)
