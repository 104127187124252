// Libraries
import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
// Components
import { Form } from 'semantic-ui-react'
// Helpers
import PropTypes from 'helpers/proptypes'
class _OccasionSection extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    inputWitdh: PropTypes.number.isRequired,
    occasion: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
  }

  static defaultProps = {
    isDisabled: true,
  }

  getPrivateEventOccasions = (t) => [
    { text: t('EventsCalendar::Bachelorette'), value: 'bachelorette' },
    { text: t('EventsCalendar::Birthday'), value: 'birthday' },
    { text: t('EventsCalendar::Business dinner'), value: 'business-dinner' },
    { text: t('EventsCalendar::Delegation'), value: 'delegation' },
    { text: t('EventsCalendar::Off site'), value: 'off-site' },
    { text: t('EventsCalendar::Seminar'), value: 'seminar' },
    { text: t('EventsCalendar::Shabbat dinner'), value: 'shabbat-dinner' },
    { text: t('EventsCalendar::Workshop'), value: 'workshop' },
    { text: t('EventsCalendar::Other'), value: 'other' },
  ]

  render() {
    const { t, occasion, handleChange, isDisabled, inputWitdh } = this.props

    return (
      <Form.Field width={inputWitdh}>
        <label>{t('EventsCalendar::Occasion')}</label>
        <Form.Select
          placeholder={t('EventsCalendar::Select an occasion')}
          value={occasion}
          options={this.getPrivateEventOccasions(t)}
          onChange={(e, { value }) => handleChange({ occasion: value })}
          disabled={isDisabled}
        />
      </Form.Field>
    )
  }
}

export const OccasionSection = withTranslation('common')(_OccasionSection)
