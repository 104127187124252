import {
  getEntities,
  getError,
  isCreating,
  getEntity,
  getEntityCount,
  getEntityOrder,
  getEntityTotal,
  isFetching,
  isDeleting,
} from './_utils'

export const getFiles = getEntities('files')
export const getFile = getEntity('files')

export const getFilesCount = getEntityCount('files')
export const getFilesTotal = getEntityTotal('files')
export const getFilesOrder = getEntityOrder('files')

export const getFilesError = (state) => getError('/api/files')(state)
export const getEventFileError = (state, id) => getError(`/api/events/${id}/files`)(state)
export const getPlaylistFileError = (state, id) => getError(`/api/playlists/${id}/files`)(state)
export const getUserFileError = (state, id) => getError(`/api/users/${id}/files`)(state)

export const getFilesList = (state) => getFilesOrder(state).map((id) => getFile(state, { id }))
export const fetchingFiles = (state) => isFetching('/api/files')(state)

export const creatingEventFiles = (state, id) => isCreating(`/api/events/${id}/files`)(state)
export const creatingPlaylistFiles = (state, id) => isCreating(`/api/playlists/${id}/files`)(state)
export const creatingUserFiles = (state, id) => isCreating(`/api/users/${id}/files`)(state)

export const creatingFiles = (state, id) =>
  creatingEventFiles(state, id) || creatingPlaylistFiles(state, id) || creatingUserFiles(state, id)

export const deleteFileError = (state, id) => getError(`/api/files/${id}`)(state)
export const deletingFile = (state, id) => isDeleting(`/api/files/${id}`)(state)
