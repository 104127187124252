// Libraries
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
// Components
import DatePicker from 'react-datepicker'
import { Form, Button } from 'semantic-ui-react'
import { SearchInput, SelectInput } from 'components/inputs'
// Redux
import { getLocation } from 'redux/reducers/router'
// Helpers
import { getTranslatedOptions } from 'helpers/options'
import { fileDateRangeOptions, fileAttachableOptions } from 'helpers/files'

const mapStateToProps = (state) => ({
  location: getLocation(state),
})

export class FileSearchForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.object,
  }

  static contextTypes = { router: PropTypes.object }

  UNSAFE_componentWillMount() {
    return this.updateStateFromLocation(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.location.equals(this.props.location)) return this.updateStateFromLocation(nextProps)
  }

  updateStateFromLocation(props) {
    return this.setState({
      user: props.location.getIn(['query', 'user'], ''),
      daterange: props.location.getIn(['query', 'daterange'], ''),
      fromdate: props.location.getIn(['query', 'fromdate'], ''),
      todate: props.location.getIn(['query', 'todate'], ''),
      attachable: props.location.getIn(['query', 'attachable'], ''),
      attachableId: props.location.getIn(['query', 'attachableId'], ''),
    })
  }

  updateQuery(query = this.state) {
    return this.context.router.push(this.props.location.mergeIn(['query'], { ...query, offset: 0 }).toJS())
  }

  clearSorting = () => {
    return this.context.router.push(this.props.location.mergeIn(['query'], { sortBy: '', order: '' }).toJS())
  }

  clearFilters = () => {
    return this.setState(
      {
        user: '',
        daterange: '',
        fromdate: '',
        todate: '',
        attachable: '',
        attachableId: '',
      },
      () => this.updateQuery(),
    )
  }

  updateDateRange = (value) => {
    this.setState({ daterange: value })
    if (value === '') {
      this.setState({ fromdate: '' })
      this.setState({ todate: '' })
    }
  }

  updateFromDate = (date) => {
    const fromdate = moment(date).format('YYYY-MM-DD')
    if (!this.state.daterange) this.setState({ daterange: 'date' })
    this.setState({ fromdate })
    if (!this.state.todate || this.state.todate < fromdate) {
      this.setState({ todate: fromdate })
    }
  }

  updateToDate = (date) => {
    const todate = moment(date).format('YYYY-MM-DD')
    if (!this.state.daterange) this.setState({ daterange: 'date' })
    this.setState({ todate })
    if (!this.state.fromdate || this.state.fromdate > todate) {
      this.setState({ fromdate: todate })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    return this.updateQuery()
  }

  render() {
    const { t } = this.props

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group widths='equal'>
          <Form.Field>
            <label>{t('Gallery::Search by user')}</label>
            <SearchInput
              queryName='user'
              placeholder={t('Gallery::id, first/last name, email')}
              icon='search'
              iconPosition='left'
              value={this.state.user}
              onChange={(value) => this.setState({ user: value })}
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Gallery::Linked Entity')}</label>
            <SelectInput queryName='attachable' options={getTranslatedOptions(t, fileAttachableOptions)} fluid search />
          </Form.Field>

          {this.state.attachable && (
            <Form.Field>
              <label>{t('Gallery::Entity id')}</label>
              <SearchInput
                queryName='attachableId'
                placeholder={t('Gallery::id')}
                icon='search'
                iconPosition='left'
                value={this.state.attachableId}
                onChange={(value) => this.setState({ attachableId: value })}
              />
            </Form.Field>
          )}

          <Form.Field>
            <label>&nbsp;</label>
            <Button type='submit'>{t('Gallery::Search')}</Button>

            <Button.Group vertical basic compact size='small' style={{ transform: 'translateY(-15px)' }}>
              <Button onClick={this.clearFilters}>{t('Gallery::Clear filters')}</Button>
              <Button onClick={this.clearSorting}>{t('Gallery::Clear sorting')}</Button>
            </Button.Group>
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field width={3}>
            <label>{t('Gallery::Search by date range')}</label>
            <SelectInput
              queryName='daterange'
              options={getTranslatedOptions(t, fileDateRangeOptions)}
              onChange={this.updateDateRange}
              value={this.state.daterange}
              preventNavigation
              // single
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Gallery::Between')}</label>
            <DatePicker
              name='fromdate'
              todayButton={t('Gallery::Today')}
              selected={this.state.fromdate && moment(this.state.fromdate).isValid() ? moment(this.state.fromdate) : ''}
              onChange={this.updateFromDate}
              placeholderText={t('Gallery::start date')}
              dateFormat='DD/MM/YYYY'
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Gallery::And')}</label>
            <DatePicker
              name='todate'
              todayButton={t('Gallery::Today')}
              selected={this.state.todate && moment(this.state.todate).isValid() ? moment(this.state.todate) : ''}
              onChange={this.updateToDate}
              placeholderText={t('Gallery::end date')}
              dateFormat='DD/MM/YYYY'
            />
          </Form.Field>

          <Form.Field>
            <label>&nbsp;</label>
            <Button type='submit'>{t('Gallery::Search')}</Button>
          </Form.Field>
        </Form.Group>
      </Form>
    )
  }
}

export default withTranslation('common')(connect(mapStateToProps)(FileSearchForm))
