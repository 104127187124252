// Libs
import React, { useEffect, useState } from 'react'
import PropTypes from 'helpers/proptypes'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Grid, Icon } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
// Components
import { BookingDetails } from './BookingDetails'
import { PaymentDetails } from './PaymentDetails'
import { Conversation } from './Conversation'
import { Request } from './Request'
import { FollowupsList } from 'components/followups'
// Redux
import { fetchBooking, fetchSchedulesByEvent } from 'redux/entities/actions'
import { getBooking } from 'redux/entities/selectors'
import { EditInvoiceBookingModal } from './EditInvoiceBookingModal'

BookingPage.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
}
export default function BookingPage({ params: { id } }) {
  const { t } = useTranslation()
  const booking = useSelector((state) => getBooking(state, { id }))
  const dispatch = useDispatch()

  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  useEffect(() => {
    dispatch(fetchBooking(id))
  }, [dispatch, id])

  useEffect(() => {
    if (booking?.event_id)
      dispatch(
        fetchSchedulesByEvent(booking.event_id, {
          start: moment.utc(booking.date).format('YYYY-MM-DD'),
          end: moment.utc(booking.date).format('YYYY-MM-DD'),
        }),
      )
  }, [dispatch, booking?.event_id, booking?.date])

  return (
    <Grid centered>
      <Grid.Row>
        <Grid.Column width={10}>
          <div style={{ display: 'flex', gap: '12px', alignItems: 'baseline' }}>
            <h2>{t('Bookings::Summary::Details::Details')}</h2>
            {booking?.payment?.get('payin') === 'invoice' && (
              <Button basic icon labelPosition='left' compact color='blue' onClick={() => setIsEditModalOpen(true)}>
                <Icon name='edit' />
                {t('Bookings::Summary::Details::Edit booking details')}
              </Button>
            )}
          </div>
          <BookingDetails booking={booking} />
        </Grid.Column>
      </Grid.Row>

      {booking.payment && (
        <Grid.Row>
          <Grid.Column width={10}>
            <h2>{t('Bookings::Summary::Payment::Payment (guest currency)')}</h2>
            <PaymentDetails payment={booking.payment} />
          </Grid.Column>
        </Grid.Row>
      )}

      {booking.request_id && (
        <Grid.Row>
          <Grid.Column width={10}>
            <h2>{t('Bookings::Summary::Request::Request')}</h2>
            <Request id={booking.request_id} />
          </Grid.Column>
        </Grid.Row>
      )}

      {booking.conversation_id && (
        <Grid.Row>
          <Grid.Column width={10}>
            <h2>{t('Bookings::Summary::Conversation::Conversation')}</h2>
            <Conversation id={booking.conversation_id} />
          </Grid.Column>
        </Grid.Row>
      )}

      {booking.followups && booking.followups.size > 0 && (
        <Grid.Row>
          <Grid.Column width={10}>
            <h2>{t('Bookings::Summary::Followups')}</h2>
            <FollowupsList followups={booking.followups} />
          </Grid.Column>
        </Grid.Row>
      )}

      {isEditModalOpen && <EditInvoiceBookingModal booking={booking} handleClose={() => setIsEditModalOpen(false)} />}
    </Grid>
  )
}
