import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const partnerDemandsActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'partnerDemands' })

export function fetchPartnerDemands({ query } = {}) {
  return partnerDemandsActions.fetch({
    url: '/api/partner-demands',
    query,
    schema: { partnerDemands: [schemas.partnerDemands] },
  })
}
export function fetchPartnerDemand(id, { query } = {}) {
  return partnerDemandsActions.fetch({
    url: `/api/partner-demands/${id}`,
    query,
    schema: { partnerDemand: schemas.partnerDemands },
  })
}

export function createPartnerDemand(payload) {
  return partnerDemandsActions.create({
    url: '/api/partner-demands',
    payload,
    schema: { partnerDemand: schemas.partnerDemands },
  })
}

export function updatePartnerDemand(id, payload) {
  return partnerDemandsActions.update({
    url: `/api/partner-demands/${id}`,
    payload,
    schema: { partnerDemand: schemas.partnerDemands },
  })
}

export function deletePartnerDemand(id) {
  return partnerDemandsActions.delete({
    url: `/api/partner-demands/${id}`,
    deleted: { partnerDemands: [id] },
  })
}
