// Libraries
import React, { PureComponent } from 'react'
// Components
import { BookingListSearchForm } from './SearchForm'
import { BookingList } from './List'

export default class BookingIndex extends PureComponent {
  render() {
    return (
      <div>
        <BookingListSearchForm />
        <BookingList />
      </div>
    )
  }
}
