// Libraries
import React from 'react'
import PropTypes from 'helpers/proptypes'
import { Comment } from 'semantic-ui-react'
import moment from 'moment'

export const FormattedReplies = ({ replies, sender, recipient }) =>
  <Comment.Group minimal>
    {replies.map((reply) => {
      const user = reply.sender_id === sender.id ? sender : recipient
      return (
        <Comment key={String(reply.id)}>
          {user.avatar && user.avatar.path ? <Comment.Avatar src={user.avatar.path} /> : <Comment.Avatar />}
          <Comment.Content>
            <Comment.Author as='a' href={`/users/${user.id}`} target='_blank' rel='noopener noreferrer'>
              {user.firstname} {user.lastname}
            </Comment.Author>
            <Comment.Metadata>
              <div>{moment(reply.created_at).format('lll')}</div>
            </Comment.Metadata>
            <Comment.Text>{reply.body}</Comment.Text>
          </Comment.Content>
        </Comment>
      )
    })}
  </Comment.Group>

FormattedReplies.propTypes = {
  replies: PropTypes.arrayOf(PropTypes.immutable.record),
  sender: PropTypes.shape({
    id: PropTypes.number,
    avatar: PropTypes.immutable.record,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
  }).isRequired,
  recipient: PropTypes.shape({
    id: PropTypes.number,
    avatar: PropTypes.immutable.record,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
  }).isRequired,
}

FormattedReplies.defaultProps = {
  replies: [],
}
