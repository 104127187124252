// Libraries
import React, { PureComponent } from 'react'

// Components
import Gallery from './Gallery'
import SearchForm from './SearchForm'

export default class PayoutsIndex extends PureComponent {
  render () {
    return (
      <div>
        <SearchForm />
        <Gallery />
      </div>
    )
  }
}
