// libs
import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Map, fromJS } from 'immutable'

// components
import { Form } from 'semantic-ui-react'
import { TabsDescriptionsByLang } from '../../../components/inputs/TabsDescriptionsByLang'

// redux
import { getLanguage } from 'redux/entities/selectors'

const mapStateToProps = (state) => ({
  fromStore: {
    getLanguage: (id) => getLanguage(state, { id }),
  },
})

const userDescriptionSkeleton = fromJS({ body: '', language_id: undefined })

class _UserDescriptionsInput extends PureComponent {
  static propTypes = {
    hostLanguage: PropTypes.immutable.record.isRequired,
    updateForm: PropTypes.func.isRequired,
    formDescriptions: PropTypes.immutable.map.isRequired,
    fromStore: PropTypes.shape({
      getLanguage: PropTypes.func,
    }).isRequired,
  }

  componentDidMount() {
    const { formDescriptions, hostLanguage } = this.props
    const hostLanguageId = String(hostLanguage.id)
    if (!formDescriptions.has(hostLanguageId)) {
      this.addDescriptionForLanguage(hostLanguageId)
    }
  }

  addDescriptionForLanguage = (languageId) => {
    const { formDescriptions } = this.props
    let nextDescriptions
    if (!formDescriptions.size) {
      nextDescriptions = formDescriptions.set(
        String(languageId),
        userDescriptionSkeleton.merge({ language_id: languageId }),
      )
    } else {
      nextDescriptions = formDescriptions.set(
        String(languageId),
        formDescriptions.first().merge({
          id: undefined,
          language_id: languageId,
        }),
      )
    }
    this.props.updateForm(nextDescriptions)
  }

  removeDescriptionForLanguage = (languageId) => {
    const { formDescriptions, updateForm } = this.props
    updateForm(formDescriptions.delete(String(languageId)))
  }

  render() {
    const {
      hostLanguage,
      updateForm,
      formDescriptions,
      fromStore: { getLanguage },
    } = this.props
    return (
      <TabsDescriptionsByLang
        hostLanguage={hostLanguage}
        describes='user'
        addDescriptionsForLanguage={this.addDescriptionForLanguage}
        removeDescriptionsForLanguage={this.removeDescriptionForLanguage}
        getUsedLanguages={() => formDescriptions.keySeq().toList().sort().map(getLanguage)}
      >
        {({ languageId }) => (
          <Form.TextArea
            key={`description ${languageId}`}
            value={formDescriptions.get(String(languageId), new Map()).get('body', '')}
            onChange={(e) => updateForm(formDescriptions.setIn([String(languageId), 'body'], e.target.value))}
            rows={10}
            style={{ resize: 'vertical' }}
          />
        )}
      </TabsDescriptionsByLang>
    )
  }
}

export const UserDescriptionsInput = withTranslation('common')(connect(mapStateToProps)(_UserDescriptionsInput))
