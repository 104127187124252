// libs
import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
// helpers
import { buildSelectOptions } from 'helpers/forms'
// components
import { Form, Menu, Segment, Icon, Select, Button } from 'semantic-ui-react'
// styles
import './TabsDescriptionsByLang.css'

// redux
import { getSortedLanguages } from 'redux/entities/selectors'

const mapStateToProps = (state) => ({
  fromStore: {
    languages: getSortedLanguages(state).filter((l) => l.translated),
  },
})

class _TabsDescriptionsByLang extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    hostLanguage: PropTypes.immutable.record.isRequired,
    describes: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
    field: PropTypes.string,
    getUsedLanguages: PropTypes.func.isRequired,
    addDescriptionsForLanguage: PropTypes.func.isRequired,
    removeDescriptionsForLanguage: PropTypes.func.isRequired,
    fromStore: PropTypes.shape({
      languages: PropTypes.immutable.list,
    }).isRequired,
  }

  static defaultProps = {
    field: 'description',
  }

  state = { active: undefined, languageId: undefined }
  UNSAFE_componentWillMount() {
    this.initLanguageIdAndActiveTab(this.props)
  }

  initLanguageIdAndActiveTab(props) {
    const { hostLanguage, getUsedLanguages } = props
    let { languageId, active } = this.state
    const usedLanguages = getUsedLanguages()

    languageId =
      hostLanguage.id && usedLanguages.map(({ id }) => id).includes(hostLanguage.id) === false ? hostLanguage.id : ''
    if (usedLanguages.size) active = usedLanguages.first().id
    this.setState({ languageId, active })
  }

  onLanguageChange = (e, { value }) => this.setState({ languageId: value })

  addDescriptionsForLanguage = (e) => {
    if (e) e.preventDefault()
    const {
      props: { addDescriptionsForLanguage },
      state: { languageId },
    } = this
    if (!languageId) return
    addDescriptionsForLanguage(languageId)
    const nextActive = parseInt(languageId)
    return this.setState({ languageId: '', active: nextActive })
  }

  removeDescriptionsForLanguage(language) {
    const {
      props: { getUsedLanguages, removeDescriptionsForLanguage },
      state: { active },
    } = this
    if (active === language.id) {
      const usedLanguages = getUsedLanguages()
      const indexOfRemovedLanguage = usedLanguages.indexOf(language)
      const nextActiveIndex = indexOfRemovedLanguage === 0 ? 1 : indexOfRemovedLanguage - 1
      const nextActive = usedLanguages.get(nextActiveIndex).id
      this.setState({ active: nextActive })
    }
    removeDescriptionsForLanguage(language.id)
  }

  render() {
    const {
      t,
      children,
      fromStore: { languages },
      describes,
      field,
      getUsedLanguages,
    } = this.props
    const { active: activeLanguageId, languageId } = this.state

    const usedLanguages = getUsedLanguages()

    // NOTE: for the partners dashboard, only english descriptions are taken into account *shrug*
    const optionsLanguages =
      describes === 'partner_event' ? languages.filter((language) => language.locale === 'en') : languages
    const options = buildSelectOptions(optionsLanguages.filter((language) => !usedLanguages.includes(language)))
    return (
      <div className='EventForm-Description-TabsDescriptionsByLang'>
        <Form.Field inline>
          <label>
            {!!usedLanguages.size &&
              t('Experiences::Summary::Add {{describes}} {{field}} in', { describes: describes, field: field })}
            {!usedLanguages.size &&
              t('Experiences::Summary::Create {{describes}} {{field}} in', { describes: describes, field: field })}
          </label>
          <Select search value={languageId} onChange={this.onLanguageChange} options={options} />
          <Button onClick={this.addDescriptionsForLanguage}>{t('Experiences::Summary::OK')}</Button>
        </Form.Field>

        <Menu attached='top' tabular>
          {usedLanguages.map((language) => (
            <Menu.Item key={language.id} name={language.title} active={language.id === activeLanguageId}>
              <div className='__tabTitle' onClick={() => this.setState({ active: language.id })}>
                {language.title}
              </div>
              <Icon name='close' circular bordered onClick={() => this.removeDescriptionsForLanguage(language)} />
            </Menu.Item>
          ))}
        </Menu>
        {!!usedLanguages.size && (
          <Segment attached='bottom'>
            {children({
              languageId: activeLanguageId,
            })}
          </Segment>
        )}
      </div>
    )
  }
}

export const TabsDescriptionsByLang = withTranslation('common')(connect(mapStateToProps)(_TabsDescriptionsByLang))
