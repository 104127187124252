import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const conversationsActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'conversations' })

export const fetchConversations = ({ query } = {}) =>
  conversationsActions.fetch({
    url: '/api/conversations',
    query,
    schema: { conversations: [schemas.conversations] },
  })

export const fetchConversation = (id, { query } = {}) =>
  conversationsActions.fetch({
    url: `/api/conversations/${id}`,
    query,
    schema: { conversation: schemas.conversations },
  })

export const fetchConversationReplies = (id, { query } = {}) =>
  conversationsActions.fetch({
    url: `/api/conversations/${id}/replies`,
    schema: { replies: [schemas.replies] },
  })
