// Libraries
import React, { Fragment } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
// Components
import { Button, Form } from 'semantic-ui-react'
import { DietRestriction } from './DietRestriction'

export const RestrictionSection = ({ restrictions, diets, handleChange, isDisabled, additionalInfo }) => {
  const { t } = useTranslation()
  return (
    <div className='__item'>
      <label>{t('EventsCalendar::Diet restrictions')}</label>
      {additionalInfo ? (
        <Form.TextArea
          name='additionalInfo'
          placeholder={t('EventsCalendar::Write a message')}
          rows={2}
          value={additionalInfo}
          onChange={(e, input) => handleChange({ additionalInfo: input.value })}
        />
      ) : (
        <Fragment>
          {restrictions.length > 0 &&
            restrictions.map((restriction, i) => (
              <DietRestriction
                onDelete={() =>
                  handleChange({
                    restrictions: restrictions.filter((rest) => restrictions.indexOf(rest)),
                  })
                }
                onUpdate={(index, { guest, diets }) =>
                  handleChange({
                    restrictions: restrictions.map((restriction, i) => (index === i ? { guest, diets } : restriction)),
                  })
                }
                restriction={restriction}
                diets={diets}
                index={restrictions.indexOf(restriction)}
                key={i}
              />
            ))}
          <Button
            circular
            size='mini'
            type='button'
            disabled={isDisabled}
            onClick={() => handleChange({ restrictions: restrictions.concat({ guest: '', diets: [] }) })}
          >
            {t('EventsCalendar::+ add guest with diet restrictions')}
          </Button>
        </Fragment>
      )}
    </div>
  )
}

RestrictionSection.propTypes = {
  restrictions: PropTypes.arrayOf(PropTypes.object),
  additionalInfo: PropTypes.string,
  diets: PropTypes.immutable.map,
  handleChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
}

RestrictionSection.defaultProps = {
  restrictions: [],
  diets: undefined,
  isDisabled: false,
  additionalInfo: undefined,
}
