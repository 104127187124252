// libraries
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
// components
import { Table, Checkbox, Icon, Button } from 'semantic-ui-react'
import { Link } from 'react-router'
// Css
import './Row.css'

const { Row, Cell } = Table

const PayoutRow = ({ payout, selected, onSelect }) => (
  <Row>
    <Cell>{payout.id}</Cell>
    <Cell>{payout.gateway}</Cell>
    <Cell>
      <Link to={`/users/${payout.user.id}`}>
        {payout.user.id} - {payout.user.firstname} {payout.user.lastname}
      </Link>
    </Cell>
    <Cell singleLine>
      {payout.user.phone && (
        <React.Fragment>
          <Icon name='phone' />
          {payout.user.phone}
        </React.Fragment>
      )}
    </Cell>
    <Cell>{payout.currency.iso_3}</Cell>
    <Cell>{`${payout.amount / 10 ** payout.currency.decimal_point} ${payout.currency.symbol}`}</Cell>
    <Cell>{payout.paypal_id}</Cell>
    <Cell>
      <a href={payout.stripe_payout_receipt_url} target='_blank' rel='noopener noreferrer'>
        Payout receipt
      </a>
    </Cell>
    <Cell>{payout.stripe_status}</Cell>
    <Cell>{payout.stripe_reason}</Cell>
    <Cell>{payout.reference_codes && payout.reference_codes.join(',')}</Cell>
    <Cell>{payout.paid_at && moment(payout.paid_at).isValid() ? moment(payout.paid_at).format('ll') : ''}</Cell>
    <Cell>
      {payout.created_at && moment(payout.created_at).isValid() ? moment(payout.created_at).format('ll') : ''}
    </Cell>
    <Cell>
      <Button primary circular as={Link} to={`/users/${payout.user.id}/bank-accounts`} icon='eye' />
    </Cell>
    <Cell className='select'>
      <Checkbox onChange={(e, { checked }) => onSelect(checked, payout.id)} checked={selected} />
    </Cell>
  </Row>
)

PayoutRow.propTypes = {
  payout: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default PayoutRow
