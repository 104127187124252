// Libs
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
// Components
import { Image, Icon, Button, List, Header, Segment } from 'semantic-ui-react'
import { Link } from 'react-router'
import { PaymentPreferencesLabel } from 'components/labels'
import { CopyToClipboardButton } from 'components/buttons'
// Helpers
import { scaleCropFileUrl } from 'helpers/files'
// Utils
import { getLocalityAndCountry } from 'helpers/places'
// Styles
import './UserCard.css'

export const UserCard = ({ user, place, brandName, connectAs, showUserDetails, children }) => {
  const [t] = useTranslation()
  if (!user) return null

  return (
    <Segment>
      <div style={{ textAlign: 'center' }}>
        {user.avatar && <Image circular centered size='small' src={scaleCropFileUrl(user.avatar, '500x500')} />}
        <Header size='medium'>
          {`${user.firstname} ${user.lastname}`}
          {brandName && <Header.Subheader>{brandName}</Header.Subheader>}
        </Header>
        <Segment basic>
          {connectAs && (
            <Button primary basic compact onClick={connectAs}>
              {t('UserCard::Connect')}
            </Button>
          )}
          <Button as={Link} primary basic compact to={{ pathname: '/users', query: { search: user.id } }}>
            {t('UserCard::View in list')}
          </Button>
        </Segment>
      </div>

      <List>
        {user.account.email && (
          <List.Item style={{ padding: 0, display: 'flex', alignItems: 'center' }}>
            <Icon name='mail outline' />
            <List.Content style={{ width: 'auto', maxWidth: '80%', overflowWrap: 'break-word' }}>
              {user.account.email}
            </List.Content>
            <CopyToClipboardButton
              text={user.account.email}
              tooltipClassName='__userCardTooltip'
              style={{ position: 'static', marginLeft: 4 }}
            />
          </List.Item>
        )}
        {user.phone && (
          <List.Item style={{ padding: 0, display: 'flex', alignItems: 'center' }}>
            <Icon name='phone' />
            <List.Content style={{ width: 'auto' }} verticalAlign='middle'>
              {user.phone}
            </List.Content>
            <CopyToClipboardButton
              text={user.phone}
              tooltipClassName='__userCardTooltip'
              style={{ position: 'static', marginLeft: 4 }}
            />
          </List.Item>
        )}
        {(place || user.locality) && (
          <List.Item>
            <Icon name='point' />
            <List.Content verticalAlign='middle'>
              {getLocalityAndCountry(place) || `${user.locality}, ${user.country}`}
            </List.Content>
          </List.Item>
        )}

        {showUserDetails && (
          <Fragment>
            {user.created_at && (
              <List.Item>
                <Icon name='child' />
                <List.Content verticalAlign='middle'>
                  {t('UserCard::Joined on {{date}}', { date: moment(user.created_at).format('ll') })}
                </List.Content>
              </List.Item>
            )}
            {user.account.facebook_id && (
              <List.Item>
                <Icon name='facebook f' />
                <List.Content verticalAlign='middle'>{t('UserCard::Registered with Facebook')}</List.Content>
              </List.Item>
            )}
            {user.account.google_id && (
              <List.Item>
                <Icon name='google' />
                <List.Content verticalAlign='middle'>{t('UserCard::Registered with Google')}</List.Content>
              </List.Item>
            )}

            <List.Item>
              <Icon name='dollar' />
              <List.Content verticalAlign='middle'>
                <PaymentPreferencesLabel user={user} />
              </List.Content>
            </List.Item>
          </Fragment>
        )}
        {user.isHost && (
          <Segment basic textAlign='center'>
            <Button as={Link} primary basic compact to={{ pathname: '/events', query: { host: user.id } }}>
              {t('UserCard::View experiences in list')}
            </Button>
          </Segment>
        )}
      </List>

      {children}
    </Segment>
  )
}

UserCard.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    account: PropTypes.object,
    phone: PropTypes.string,
    created_at: PropTypes.string,
    locality: PropTypes.string,
    country: PropTypes.string,
    avatar: PropTypes.object,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    isHost: PropTypes.bool,
  }).isRequired,
  place: PropTypes.shape({
    id: PropTypes.number,
  }),
  connectAs: PropTypes.func,
  children: PropTypes.node,
  brandName: PropTypes.string,
  showUserDetails: PropTypes.bool,
}

UserCard.defaultProps = {
  children: undefined,
  showUserDetails: true,
  brandName: undefined,
  connectAs: undefined,
  place: undefined,
}
