import { Record, Map } from 'immutable'
import { isObject } from 'lodash'
import settings from 'settings'
import pDebounce from 'p-debounce'

export function getLocality(place) {
  return place.locality || place.administrative_area_level_2 || place.administrative_area_level_1
}

export function getLocalityAndCountry(place) {
  if (!place) return null
  if (Map.isMap(place)) place = place.toJS()

  return [getLocality(place), place.country].filter(Boolean).join(', ')
}

export const placeSkeleton = {
  address: null,
  formatted: null,
  street_number: null,
  route: null,
  locality: null,
  administrative_area_level_1: null,
  administrative_area_level_2: null,
  country: null,
  country_iso: null,
  postal_code: null,
  lat: null,
  lng: null,
  google_place_id: null,
}

/**
 * Extract a place component value based on type and format
 * @param  {Object} place  A Google Place object
 * @param  {String} type   A type of information to look for (country, locality...)
 * @param  {String} format A type of format to retrieve (long_name, short_name...)
 * @return {String}        The value for the specified request
 */
const getPlaceComponent = (place, type, format) => {
  // address_component have the following shape
  // { short_name: 'xyz', long_name: 'xyz', types: [ 'abc', 'def', 'ijk' ]}
  const component = place.address_components
    .filter((component) => component.types.includes(type))
    .filter((component) => format in component)
    .shift()

  return component ? component[format] : null
}

export const formatPlace = (googlePlace) => {
  return !googlePlace || googlePlace.name === ''
    ? placeSkeleton
    : {
        address: googlePlace.formatted_address,
        formatted: googlePlace.formatted_address,
        street_number: getPlaceComponent(googlePlace, 'street_number', 'short_name'),
        route: getPlaceComponent(googlePlace, 'route', 'long_name'),
        locality:
          getPlaceComponent(googlePlace, 'locality', 'long_name') ||
          getPlaceComponent(googlePlace, 'administrative_area_level_3', 'long_name'),
        administrative_area_level_1: getPlaceComponent(googlePlace, 'administrative_area_level_1', 'short_name'),
        administrative_area_level_2: getPlaceComponent(googlePlace, 'administrative_area_level_2', 'short_name'),
        country: getPlaceComponent(googlePlace, 'country', 'long_name'),
        country_iso: getPlaceComponent(googlePlace, 'country', 'short_name'),
        postal_code: getPlaceComponent(googlePlace, 'postal_code', 'short_name'),
        lat: googlePlace.geometry.location.lat(),
        lng: googlePlace.geometry.location.lng(),
        google_place_id: googlePlace.place_id,
      }
}

export function getCoordinatesFromPlace(place) {
  const coordinates = { lat: undefined, lng: undefined }
  if (Record.isRecord(place)) {
    coordinates.lat = place.coordinates.get('latitude')
    coordinates.lng = place.coordinates.get('longitude')
  } else if (Map.isMap(place)) {
    coordinates.lat = place.getIn(['coordinates', 'latitude'])
    coordinates.lng = place.getIn(['coordinates', 'longitude'])
  } else if (isObject(place) && isObject(place.coordinates)) {
    coordinates.lat = place.coordinates.latitude
    coordinates.lng = place.coordinates.longitude
  }
  return coordinates
}

export const debouncedfetchAutocomplete = pDebounce(fetchAutocomplete, 500)
export async function fetchAutocomplete({ query, type, prox, language }) {
  if (!query) return []
  const url = new URL(settings.cloudflareWorkersAutocompleteUrl)
  url.search = new URLSearchParams(
    Object.assign({ q: query }, type && { type }, prox && { prox }, language && { language }),
  )
  const response = await fetch(url)
  const { results } = await response.json()
  return results
}

export async function fetchPlace({ id, language }) {
  const url = new URL(settings.cloudflareWorkersPlaceUrl)
  url.search = new URLSearchParams(Object.assign({ id }, language && { language }))
  const response = await fetch(url)
  const item = await response.json()
  return item
}

export function stringifyGeometryCoordinate({ northeast, southwest }) {
  return [northeast.lat, northeast.lng, southwest.lat, southwest.lng].join('-')
}

export function formatViewportObject(nelat, nelng, swlat, swlng) {
  return {
    nelat,
    nelng,
    swlat,
    swlng,
  }
}

export function metersToKilometers(distance) {
  return distance / 1000
}
