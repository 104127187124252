import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const partnersActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'partners' })

export function fetchPartners ({ query } = {}) {
  return partnersActions.fetch({
    url: '/api/partners',
    query,
    schema: { partners: [schemas.partners] },
  })
}

export const fetchPartner = (id, { query } = {}) =>
  partnersActions.fetch({
    url: `/api/partners/${id}`,
    query,
    schema: { partner: schemas.partners },
  })

export function updatePartner (id, payload, { query } = {}) {
  return partnersActions.update({
    url: `/api/partners/${id}`,
    payload,
    query,
    schema: { partner: schemas.partners },
  })
}

export function createPartner (payload) {
  return partnersActions.create({
    url: '/api/partners',
    payload,
    schema: { partner: schemas.partners },
  })
}
