import { screenApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const amenitiesActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'amenities' })
export const fetchAmenities = () =>
  amenitiesActions.fetch({
    url: '/amenities',
    schema: { amenities: [schemas.amenities] },
  })

export const alcoholsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'alcohols' })
export const fetchAlcohols = () =>
  alcoholsActions.fetch({
    url: '/alcohols',
    schema: { alcohols: [schemas.alcohols] },
  })

export const currenciesActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'currencies' })
export const fetchCurrencies = () =>
  currenciesActions.fetch({
    url: '/currencies',
    schema: { currencies: [schemas.currencies] },
  })

export const dietsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'diets' })
export const fetchDiets = () =>
  dietsActions.fetch({
    url: '/diets',
    schema: { diets: [schemas.diets] },
  })

export const foodsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'foods' })
export const fetchFoods = () =>
  foodsActions.fetch({
    url: '/foods',
    schema: { foods: [schemas.foods] },
  })

export const languagesActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'languages' })
export const fetchLanguages = () =>
  languagesActions.fetch({
    url: '/languages',
    schema: { languages: [schemas.languages] },
  })

const fetch = {
  amenities: fetchAmenities,
  alcohols: fetchAlcohols,
  currencies: fetchCurrencies,
  diets: fetchDiets,
  foods: fetchFoods,
  languages: fetchLanguages,
}
export function fetchStatics (statics = []) {
  return (dispatch, getState) => Promise.all(statics.map((name) => dispatch(fetch[name]())))
}
