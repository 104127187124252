// Libraries
import React, { useCallback, useState, useEffect } from 'react'
import { Form } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import intersection from 'lodash/intersection'
// Helpers
import PropTypes from 'helpers/proptypes'
import { businessApi } from 'helpers/api'
import { getLocalityAndCountry } from 'helpers/places'

export function EventSection({ isDisabled, onEventChange, host, event, canShowLabel, filterTags }) {
  const [isLoading, setIsLoading] = useState(false)
  const [events, setEvents] = useState(event ? [event] : [])
  const [t] = useTranslation()

  const searchEvents = useCallback(async () => {
    if (!host) return
    setIsLoading(true)
    const {
      data: { events },
    } = await businessApi.get('/api/events', {
      params: {
        host: host.id,
        size: 50,
      },
    })
    let filteredEvents = events.filter((e) => e.status !== 'draft')

    if (filterTags.length) {
      filteredEvents = filteredEvents.filter((e) => intersection(e.tags, filterTags).length)
    }
    if (filteredEvents.length === 1) {
      onEventChange(filteredEvents[0])
    }
    setEvents(filteredEvents)
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [host, filterTags])

  useEffect(() => {
    searchEvents()
  }, [searchEvents])

  function formatEventsOptions() {
    if (!events) return []

    return events.map((event) => ({
      text: `${event.id} - ${event.title}${event.place ? ` - ${getLocalityAndCountry(event.place)}` : ''} - ${
        event.max_seats
      } seats`,
      value: event.id,
    }))
  }

  return (
    <Form.Field required className='__item' width={13}>
      {canShowLabel && <label>{t('EventsCalendar::Event')}</label>}
      <Form.Dropdown
        placeholder={t('EventsCalendar::Event name, event id')}
        search
        fluid
        selection
        options={formatEventsOptions()}
        onChange={(e, { value }) => onEventChange(events.find((ev) => ev.id === value))}
        disabled={isDisabled}
        noResultsMessage={isLoading ? t('EventsCalendar::Loading...') : t('EventsCalendar::No results found.')}
        value={event && event.id}
      />
    </Form.Field>
  )
}

EventSection.propTypes = {
  isDisabled: PropTypes.bool,
  onEventChange: PropTypes.func.isRequired,
  filterTags: PropTypes.arrayOf(PropTypes.string),
  canShowLabel: PropTypes.bool,
  host: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  event: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    max_seats: PropTypes.number.isRequired,
    place: PropTypes.shape(),
    tags: PropTypes.arrayOf(PropTypes.string),
  }),
}

EventSection.defaultProps = {
  isDisabled: true,
  canShowLabel: false,
  host: undefined,
  event: undefined,
  filterTags: [],
}
