import React, { useCallback } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RangeSlider } from '@vizeat/components/es6/components/RangeSlider'
import { DeletableBadge } from 'components/badges/DeletableBadge'
import { SelectCurrency } from 'components/inputs'
import { SectionWrapper } from './SectionWrapper'
import { useMakePriceFormatter } from 'hooks/playlists/useMakePriceFormatter'
import { getCurrency } from 'redux/entities/selectors'

const LIMIT_MIN = 1
const LIMIT_MAX = 100

export function Price({ currency, onChange, priceMax, priceMin, showSelectedFilters }) {
  const { t } = useTranslation()
  const defaultCurrency = useSelector((state) => getCurrency(state, { id: 1 }))
  const currencyId = currency || defaultCurrency.id

  const priceFormatter = useMakePriceFormatter(currencyId)

  const formatPrice = useCallback(
    (price) => {
      const priceToFormat = Math.min(price, LIMIT_MAX)
      const isLimitMaxPrice = priceToFormat === LIMIT_MAX
      const formattedPrice = priceFormatter({ price: priceToFormat * 100 })
      return isLimitMaxPrice ? `${formattedPrice}+` : formattedPrice
    },
    [priceFormatter],
  )

  const handlePriceChange = (min, max) => {
    if (priceMin !== min) return onChange({ name: 'priceMin', value: min })
    if (priceMax !== max) onChange({ name: 'priceMax', value: max === LIMIT_MAX ? 9999 : max })
  }

  function handleChangeCurrency(value) {
    onChange({ name: 'currency', value })
  }

  if (showSelectedFilters) {
    if (priceMin === LIMIT_MIN && priceMax === LIMIT_MAX) return null
    return (
      <DeletableBadge
        color='mediumGray'
        onClick={() => {
          handlePriceChange(LIMIT_MIN, LIMIT_MAX)
        }}
      >
        {formatPrice(priceMin)} - {formatPrice(priceMax)}
      </DeletableBadge>
    )
  }

  return (
    <SectionWrapper title={t('Playlists::Summary::Price')}>
      <SelectCurrency currencyId={currencyId} handleCurrencyChange={handleChangeCurrency} />
      <RangeSlider
        pb={{ default: 0, tablet: '16px' }}
        m='0 auto'
        width={{ default: '100%', tablet: '80%' }}
        minValue={priceMin}
        maxValue={priceMax}
        limitMin={LIMIT_MIN}
        limitMax={LIMIT_MAX}
        renderMinValueInfo={formatPrice}
        renderMaxValueInfo={formatPrice}
        onValueChange={handlePriceChange}
      />
    </SectionWrapper>
  )
}

Price.propTypes = {
  currency: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  showSelectedFilters: PropTypes.bool,
  priceMax: PropTypes.number,
  priceMin: PropTypes.number,
}

Price.defaultProps = {
  currency: undefined,
  showSelectedFilters: false,
  priceMax: LIMIT_MAX,
  priceMin: LIMIT_MIN,
}
