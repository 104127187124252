/**
 * Recursivelly decode a string input until either it's fully decoded or it's been 10 attempts
 * @param  {String} input     potentially encoded string
 * @param  {Number} [count=0] counter
 * @return {String}           decoded string, or last input if more than 10 attempts
 */

export function fullyDecode(input: string, count = 0): string {
  const decoded = decodeURIComponent(input)
  return decoded === input || count > 10 ? input : fullyDecode(decoded, ++count)
}
