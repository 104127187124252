// libs
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import debounce from 'p-debounce'
import cookies from 'helpers/cookies'
// components
import { Loader } from 'semantic-ui-react'
// redux
import { loginActions } from 'redux/actions'
import { fetchTags, fetchStatics } from 'redux/entities/actions'
import { getTags } from 'redux/entities/selectors'
import { getAuthToken, getLoading } from 'redux/reducers/admin'

const mapStateToProps = (state) => ({
  token: getAuthToken(state),
  loading: getLoading(state),
  getTags: () => getTags(state),
})

const mapDispatchToProps = (dispatch) => ({
  logInWithToken: debounce((token) => dispatch(loginActions.logInWithToken(token)), 200),
  loadLanguagesAndCurrencies: () => dispatch(fetchStatics(['languages', 'currencies'])),
  fetchTags: () => dispatch(fetchTags()),
})

class Authenticate extends PureComponent {
  static contextTypes = { router: PropTypes.object }
  static propTypes = {
    logInWithToken: PropTypes.func.isRequired,
    loadLanguagesAndCurrencies: PropTypes.func.isRequired,
    fetchTags: PropTypes.func.isRequired,
    getTags: PropTypes.func.isRequired,
    token: PropTypes.string,
    loading: PropTypes.bool,
    location: PropTypes.object,
    children: PropTypes.any,
  }

  UNSAFE_componentWillMount() {
    this.manageUser(this.props)
    // Load languages and currencies at start up
    this.props.loadLanguagesAndCurrencies()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.token && !this.props.token) nextProps.fetchTags()
    this.manageUser(nextProps)
  }

  manageUser(props) {
    if (props.token) return
    const kitchenToken = cookies.kitchenAuthToken.value
    if (!kitchenToken) return this.redirectToLogin(props.location)
    else if (kitchenToken && !props.loading) return props.logInWithToken(kitchenToken)
  }

  redirectToLogin(location) {
    if (location.pathname === '/login') return
    return this.context.router.replace({
      pathname: '/login',
      query: {
        redirect: location.pathname + location.search,
      },
    })
  }

  render() {
    if (this.props.loading) return <Loader />
    else if (!this.props.token && this.props.location.pathname !== '/login') return null
    else return this.props.children
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Authenticate)
