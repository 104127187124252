// libs
import React from 'react'
import styled from 'styled-components'
// components
import { Box } from '@vizeat/components/es6/components/Box'
import { Heading } from '@vizeat/components/es6/components/Heading'
// helpers
import PropTypes from 'helpers/proptypes'

SectionWrapper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
}

SectionWrapper.defaultProps = {
  title: undefined,
  children: undefined,
}

export const StyledTitle = styled(Heading)`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 24px;
`

export const StyledFiltersSection = styled(Box)`
  border-top: ${({ theme }) => `${theme.borders.sm} ${theme.colors.lightGray}`};
  margin: 24px 0;
  padding: 24px 0 0;

  &:first-of-type {
    margin-top: 0;
    border-top: 0;
    padding-top: 12px;
  }

  ${({ theme }) => theme.media.tablet`
    margin: 24px;
    padding: 24px 20px 0;
  `}

  ${({ theme }) => theme.media.desktop`
      padding: 24px 40px 0;
      margin: 24px 60px;
  `}
`

export function SectionWrapper({ title, children }) {
  return (
    <StyledFiltersSection>
      {title && <StyledTitle>{title}</StyledTitle>}
      {children}
    </StyledFiltersSection>
  )
}
