import { schema } from 'normalizr'
import { Record, List } from 'immutable'
import { accounts } from './accounts'
import { organizations } from './organizations'
import { users } from './users'
import { currencies } from './statics'

const _schema = new schema.Entity('partners', {
  organization: organizations.schema,
  account: accounts.schema,
  user: users.schema,
  currency: currencies.schema,
})

const _Record = Record({
  id: undefined,

  account_id: undefined,
  account: new accounts.Record(),
  organization_id: undefined,
  organization: new organizations.Record(),
  user: new users.Record(),
  currency: new currencies.Record(),
  followups: new List(),

  password: '',
  name: '',
  phone: '',
  description: '',
  billing_infos: '',
  api_key: '',
  desired_organization: undefined,

  created_at: undefined,
  updated_at: undefined,
  deleted_at: undefined,
})

export const partners = {
  schema: _schema,
  Record: _Record,
}
