import React, { PureComponent } from 'react'
import { CalendarSearchForm } from './searchForm/SearchForm'
import { Calendar } from './calendar/Calendar'

export default class EventsCalendar extends PureComponent {
  render () {
    return (
      <div className='events-calendar-page'>
        <CalendarSearchForm />
        <hr />
        <Calendar />
      </div>
    )
  }
}
