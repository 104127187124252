// Libs
import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
// Components
import { Grid } from 'semantic-ui-react'
import { RequestDetails } from './RequestDetails'
import { RequestBookings } from './RequestBookings'
import { Conversation } from './Conversation'
import { FollowupsList } from 'components/followups'
// Redux
import { fetchRequests } from 'redux/entities/actions'
import { getRequest } from 'redux/entities/selectors'

const mapStateToProps = (state, props) => ({
  fromStore: {
    request: props.params.id && getRequest(state, { id: props.params.id }),
  },
})

const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    // Necessary instead of just fetchRequest because we need nested entities
    // such as event or followups, not provided by /api/requests/:id
    loadRequest: (id) => dispatch(fetchRequests({ query: { search: id } })),
  },
})

class RequestPage extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fromStore: PropTypes.shape({
      request: PropTypes.object,
    }).isRequired,
    actions: PropTypes.shape({
      loadRequest: PropTypes.func,
    }).isRequired,
    params: PropTypes.object,
  }

  componentDidMount() {
    const { actions } = this.props
    if (this.props.params && this.props.params.id) {
      actions.loadRequest(this.props.params.id)
    }
  }

  render() {
    const { t, fromStore } = this.props
    const { request } = fromStore

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={3} />

          <Grid.Column width={10}>
            <h2>{t('Requests::Summary::Details::Details')}</h2>
            <RequestDetails request={request} />
          </Grid.Column>

          <Grid.Column width={3} />
        </Grid.Row>
        {request.booking_ids.size > 0 && (
          <Grid.Row>
            <Grid.Column width={3} />

            <Grid.Column width={10}>
              <h2>{t('Requests::Summary::Bookings::Bookings')}</h2>
              <RequestBookings bookingIds={request.booking_ids} />
            </Grid.Column>

            <Grid.Column width={3} />
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column width={3} />

          <Grid.Column width={10}>
            <h2>{t('Requests::Summary::Conversation::Conversation')}</h2>
            {request.conversation_id && <Conversation id={request.conversation_id} />}
          </Grid.Column>

          <Grid.Column width={3} />
        </Grid.Row>

        {request.followups && request.followups.size > 0 && (
          <Grid.Row>
            <Grid.Column width={3} />
            <Grid.Column width={10}>
              <h2>{t('Requests::Summary::Followups::Followups')}</h2>
              <FollowupsList followups={request.followups} />
            </Grid.Column>

            <Grid.Column width={3} />
          </Grid.Row>
        )}
      </Grid>
    )
  }
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(RequestPage))
