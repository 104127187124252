import { DEFAULT_CURRENCY } from './konstants.js'

// Output
export function formatPriceAsFloat(
  priceInCents: string | number,
  { decimal_point: decimalPoint = 2 } = DEFAULT_CURRENCY,
): number {
  return parseInt(`${priceInCents}`, 10) / 10 ** decimalPoint
}

/**
 * Format a price and only display the integer digits
 * @param  {Number} priceInCents
 * @param  {String} [locale='en']
 * @param  {String} [currency=DEFAULT_CURRENCY]
 * @return {String}
 */
export function formatIntegerPrice(priceInCents: number | number, locale = 'en', currency = DEFAULT_CURRENCY): string {
  // @ts-expect-error to handle immutable
  let devise = currency.toJS?.() ?? currency
  if (!devise.iso_3) devise = DEFAULT_CURRENCY
  const value = formatPriceAsFloat(priceInCents, devise)
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    minimumFractionDigits: value % 1 > 0 ? 0 : devise.decimal_point,
    currency: devise.iso_3,
  }).format(value)
}

// Input
export function formatPriceAsInt(
  priceInCurrency: string | number,
  { decimal_point: decimalPoint = 2 } = DEFAULT_CURRENCY,
): number {
  const sanitizedPrice = parseFloat(`${priceInCurrency}`).toFixed(decimalPoint)
  // Math.round to ensure JavaScript doesn't fuck up...
  // Calling formatPriceAsInt(69.9899, { decimal_point: 2 }) returned 6998.999999999999
  return Math.round(parseFloat(sanitizedPrice) * 10 ** decimalPoint)
}
