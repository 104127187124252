import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const payoutsActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'payouts' })

export const fetchPayouts = ({ query } = {}) =>
  payoutsActions.fetch({
    url: '/api/payouts',
    query,
    schema: {
      payouts: [schemas.payouts],
      batchedPayouts: [schemas.batchedPayouts],
    },
  })

export const updatePayouts = (payload, { query } = {}) =>
  payoutsActions.update({
    url: '/api/payouts',
    payload,
    query,
    schema: { payouts: [schemas.payouts] },
  })
