import cookies from 'helpers/cookies'
import { push } from 'react-router-redux'
import { getError } from 'redux/reducers/admin/selectors'
import { getLanguage } from 'redux/entities/selectors'
import { buildHandlers, businessApi } from 'helpers/api'
import { setAppLocale } from 'redux/entities/actions'

export const actions = {
  LOGGING_IN: 'LOGGING_IN',
  LOGGED_IN: 'LOGGED_IN',
  LOG_IN_ERROR: 'LOG_IN_ERROR',

  LOGOUT: 'LOGOUT',
}

const setKitchenAuthCookie = (token, user) => (dispatch, getState) => {
  cookies.kitchenAuthToken.save(token)
  cookies.vizeatAdmin.save(true)
  businessApi.setHeader('Authorization', `JWT ${token}`)
  if (user) {
    const locale = getLanguage(getState(), { id: user.language_id }).locale
    if (locale) dispatch(setAppLocale(locale))
  }
}

const logIn = (logInPromise, handle, withToken = true, payloadPatch = {}) => {
  const expectedKeys = ['user', 'admin']
  if (withToken) expectedKeys.push('token')
  return logInPromise.then(
    handle.response(actions.LOGGED_IN, expectedKeys, null, payloadPatch),
    handle.error(actions.LOG_IN_ERROR),
  )
}

export function logInWithEmailAndPassword(email, password) {
  return (dispatch) => {
    const handle = buildHandlers(dispatch)
    return logIn(businessApi.post('/login', { email, password }), handle).then((res) => {
      if (res && res.data && res.data.token && res.data.user) {
        dispatch(setKitchenAuthCookie(res.data.token, res.data.user))
      }
      return res
    })
  }
}

export function logInWithToken(token) {
  return (dispatch, getState, { Api }) => {
    const handle = buildHandlers(dispatch)
    businessApi.setHeader('Authorization', `JWT ${token}`)
    return logIn(businessApi.get('/api/admins/profile', null), handle, false, { token }).then((res) => {
      const error = getError(getState())
      if (error) return cookies.kitchenAuthToken.remove()
      return dispatch(setKitchenAuthCookie(token, res && res.data && res.data.user))
    })
  }
}

export function logout() {
  return (dispatch) => {
    cookies.kitchenAuthToken.remove()
    businessApi.removeHeader('Authorization')
    dispatch({ type: actions.LOGOUT })
    dispatch(push('/login'))
  }
}
