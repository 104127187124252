import React, { Fragment, useState, useEffect, useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { Form, Popup, Input, Message } from 'semantic-ui-react'
import { CalendarPicker } from '@vizeat/components/es6/components/CalendarPicker'
import { fetchSchedulesByEvent } from 'redux/entities/actions'
import {
  getIsBookableSchedule as getIsBookableScheduleSelector,
  getIsClosedSchedule as getIsClosedScheduleSelector,
} from 'redux/entities/selectors'

import './DateSection.css'

export function DateSection({ date, eventId, handleChange, handleDateChange, isDisabled, isTimeDisabled, from, to }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const mDate = useMemo(() => moment.utc(date), [date])
  const isDateInvalid = useMemo(() => mDate.isSameOrBefore(moment.utc().subtract(1, 'day')), [mDate])

  const [activeMonth, setActiveMonth] = useState(mDate.clone())

  const getIsBookableSchedule = useSelector((state) => (date) => getIsBookableScheduleSelector(state, date, eventId))
  const getIsClosedSchedule = useSelector((state) => (date) => getIsClosedScheduleSelector(state, date, eventId))

  useEffect(() => {
    if (eventId) {
      dispatch(
        fetchSchedulesByEvent(eventId, {
          start: activeMonth.startOf('month').format('YYYY-MM-DD'),
          end: activeMonth.endOf('month').format('YYYY-MM-DD'),
        }),
      )
    }
  }, [activeMonth, dispatch, eventId])

  return (
    <Fragment>
      <Form.Group widths='equal' className='__item'>
        <Form.Field required>
          <label>{t('EventsCalendar::Date')}</label>
          <Popup
            className='CalendarPickerPopup'
            on='click'
            trigger={
              <Input
                icon='calendar outline'
                disabled={isDisabled}
                value={date ? mDate.format('YYYY-MM-DD') : ''}
                placeholder={t('EventsCalendar::YYYY-MM-DD')}
              />
            }
            content={
              <CalendarPicker
                initialMonth={mDate}
                disabledDays={{ before: moment.utc() }}
                onDayClick={handleDateChange}
                onMonthChange={setActiveMonth}
                modifiers={{
                  selected: date ? mDate : undefined,
                  closed: getIsClosedSchedule,
                  opened: getIsBookableSchedule,
                }}
              />
            }
          />
        </Form.Field>

        <Form.Field required>
          <label>{t('EventsCalendar::From')}</label>
          <Form.Input
            type='time'
            step='900'
            value={from}
            onChange={(_e, { value }) =>
              handleChange({
                from: value,
                to: moment(value, 'HH:mm').add(3, 'hours').format('HH:mm'),
              })
            }
            disabled={isDisabled || isTimeDisabled}
          />
        </Form.Field>

        <Form.Field required>
          <label>{t('EventsCalendar::To')}</label>
          <Form.Input
            type='time'
            step='900'
            value={to}
            onChange={(_e, { value }) => handleChange({ to: value })}
            disabled={isDisabled || isTimeDisabled}
          />
        </Form.Field>
      </Form.Group>

      {isDateInvalid && <Message negative>{t('EventsCalendar::Experience date should not be in the past')}</Message>}

      {isTimeDisabled && (
        <Message
          info
          icon='info circle'
          content={t(
            'EventsCalendar::The date already has bookings, you cannot edit the price nor the time of the event',
          )}
        />
      )}
    </Fragment>
  )
}

DateSection.propTypes = {
  date: PropTypes.oneOfType([PropTypes.moment, PropTypes.string]),
  from: PropTypes.string,
  to: PropTypes.string,
  eventId: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isTimeDisabled: PropTypes.bool,
}

DateSection.defaultProps = {
  date: undefined,
  from: '',
  to: '',
  isDisabled: false,
  isTimeDisabled: false,
  eventId: undefined,
}
