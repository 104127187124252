import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Checkbox } from '@vizeat/components/es6/components/Checkbox/Checkbox'
import { Grid } from '@vizeat/components/es6/components/Grid'
import { SectionWrapper } from './SectionWrapper'
import { DeletableBadge } from 'components/badges/DeletableBadge'
import PropTypes from 'helpers/proptypes'
import { useMultiFiltersToggle } from './hooks/useMultiFiltersToggle'
import { getLanguages } from 'redux/entities/selectors'

export function Language({ onChange, showSelectedFilters, selectedValues }) {
  const { t } = useTranslation()

  const languages = useSelector(getLanguages)
    .toList()
    .filter((language) => language.translated && language.activated)

  const handleChange = useMultiFiltersToggle(selectedValues, { onChange, filterName: 'languages' })

  if (showSelectedFilters) {
    return (
      <Fragment>
        {selectedValues.map((title) => {
          return (
            <DeletableBadge
              key={`language-${title}`}
              color='mediumGray'
              onClick={() => {
                handleChange(null, title)
              }}
            >
              {t(title)}
            </DeletableBadge>
          )
        })}
      </Fragment>
    )
  }

  return (
    <SectionWrapper title={t('Playlists::Summary::Host Language')}>
      <Grid templateColumns={{ default: 'repeat(2, 1fr)', tablet: 'repeat(4, 1fr)' }} gap='28px'>
        {languages.map(({ title }) => {
          return (
            <Checkbox
              key={`language-${title}`}
              id={`language-${title}`}
              label={title}
              name={title}
              checked={selectedValues.includes(title)}
              onChange={handleChange}
              mt='0'
            />
          )
        })}
      </Grid>
    </SectionWrapper>
  )
}

Language.propTypes = {
  onChange: PropTypes.func.isRequired,
  showSelectedFilters: PropTypes.bool,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
}

Language.defaultProps = {
  showSelectedFilters: false,
  selectedValues: [],
}
