import { schema } from 'normalizr'
import { Record } from 'immutable'

// NOTE: Please keep the alphabetical order
// alcohols
export const alcohols = {
  schema: new schema.Entity('alcohols'),
  Record: Record({
    id: undefined,
    title: '',
    description: '',
  }),
}

// amenities
export const amenities = {
  schema: new schema.Entity('amenities'),
  Record: Record({
    id: undefined,
    picto: undefined,
    title: '',
  }),
}

// countries
export const countries = {
  schema: new schema.Entity('countries', {}, { idAttribute: 'country_iso' }),
  Record: Record({
    country_iso: '',
    country_name: '',
  }),
}

// criteria
export const criteria = {
  schema: new schema.Entity('criteria'),
  Record: Record({
    id: undefined,
    title: '',
    description: '',
    active: undefined,
  }),
}

// currencies
export const currencies = {
  schema: new schema.Entity('currencies'),
  Record: Record({
    id: undefined,
    iso_3: '',
    symbol: '',
    title: '',
    decimal_point: '',
  }),
}

// diets
export const diets = {
  schema: new schema.Entity('diets'),
  Record: Record({
    id: undefined,
    title: '',
    description: '',
  }),
}

// foods
export const foods = {
  schema: new schema.Entity('foods'),
  Record: Record({
    id: undefined,
    title: '',
    localized_title: '',
    description: '',
    localized_description: '',
  }),
}

// languages
export const languages = {
  schema: new schema.Entity('languages'),
  Record: Record({
    id: undefined,
    title: '',
    i_speak: '',
    alpha2: '',
    activated: undefined,
    translated: undefined,
    locale: undefined,
    'alpha3-b': null,
    'alpha3-t': null,
  }),
}
