export const statusOptions = [
  { text: __('PartnerDemands::StatusOptions::Cancelled by partner'), value: 'CancelledByPartner' },
  { text: __('PartnerDemands::StatusOptions::Cancelled by us'), value: 'CancelledByUs' },
  { text: __('PartnerDemands::StatusOptions::Completed'), value: 'Completed' },
  { text: __('PartnerDemands::StatusOptions::Booked'), value: 'Booked' },
  { text: __('PartnerDemands::StatusOptions::On it'), value: 'OnIt' },
  { text: __('PartnerDemands::StatusOptions::Details TBS'), value: 'DetailsTBS' },
  { text: __('PartnerDemands::StatusOptions::Prebooked'), value: 'Prebooked' },
  { text: __('PartnerDemands::StatusOptions::Requested By Partner'), value: 'RequestedByPartner' },
  { text: __('PartnerDemands::StatusOptions::Unknown'), value: 'Unknown' },
  { text: __('PartnerDemands::StatusOptions::Awaiting validation'), value: 'AwaitingValidation' },
  { text: __('PartnerDemands::StatusOptions::Invoiced'), value: 'Invoiced' },
]
