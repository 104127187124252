import React, { Fragment, PureComponent } from 'react'
import { connect } from 'react-redux'
import { Card, Header } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { getUserGallery } from 'redux/entities/selectors'
import FocusedFile from 'pages/gallery/FocusedFile'
import { LazyImage } from '@vizeat/components/es6/components/LazyImage'
import settings from 'settings'
import { withTranslation } from 'react-i18next'

const mapStateToProps = (state, props) => ({
  fromStore: {
    gallery: getUserGallery(state, { id: props.user.id }),
  },
})

class _SampleExperienceGallery extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      focusedFilePath: null,
    }
  }

  render() {
    const { t, fromStore } = this.props

    return fromStore.gallery ? (
      <Fragment>
        <Header size='small'>{t('HostApplications::Summary::Photos')}</Header>
        <Card.Group centered itemsPerRow={3}>
          {[...fromStore.gallery.values()].map((image) => (
            <Card key={image.id}>
              <LazyImage
                src={settings.globalUrlFactory.screen.files.transform(
                  image.id,
                  '/-/scale_crop/200x200/center/-/progressive/yes',
                )}
                onClick={() => this.setState({ focusedFilePath: image.path })}
              />
            </Card>
          ))}
        </Card.Group>

        {this.state.focusedFilePath && (
          <FocusedFile path={this.state.focusedFilePath} onFocus={() => this.setState({ focusedFilePath: null })} />
        )}
      </Fragment>
    ) : null
  }
}

_SampleExperienceGallery.propTypes = {
  t: PropTypes.func.isRequired,
  fromStore: PropTypes.shape({
    gallery: PropTypes.object.isRequired,
  }).isRequired,
}

export const SampleExperienceGallery = withTranslation('common')(
  connect(mapStateToProps, undefined)(_SampleExperienceGallery),
)
