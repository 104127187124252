import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const campaignsActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'campaigns' })

export function fetchCampaigns ({ query } = {}) {
  return campaignsActions.fetch({
    url: '/api/campaigns',
    query,
    schema: { campaigns: [schemas.campaigns] },
  })
}

export function fetchCampaign (id, { query } = {}) {
  return campaignsActions.fetch({
    url: `/api/campaigns/${id}`,
    query,
    schema: { campaign: schemas.campaigns },
  })
}

export const createCampaigns = (payload, { query } = {}) =>
  campaignsActions.create({
    url: '/api/campaigns',
    payload,
    query,
    schema: { campaigns: [schemas.campaigns] },
  })

export const updateCampaign = (id, payload, { query } = {}) =>
  campaignsActions.update({
    url: `/api/campaigns/${id}`,
    payload,
    query,
    schema: { campaign: schemas.campaigns },
  })
