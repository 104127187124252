// Libraries
import React, { Fragment } from 'react'
// Components
import { PartnerDemandSearchForm } from './PartnerDemandSearchForm'
import { PartnerDemandList } from './PartnerDemandList'
import { CreatePartnerDemand } from './CreatePartnerDemand'

export function PartnerDemandsIndex() {
  return (
    <Fragment>
      <PartnerDemandSearchForm />
      <CreatePartnerDemand />
      <PartnerDemandList />
    </Fragment>
  )
}
