import React, { Fragment } from 'react'
import { Segment, Header, Grid, Label } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export function ExperienceSection({ hostApplication: { sample_experience: sampleExperience } }) {
  const [t] = useTranslation()

  return (
    <Segment>
      <Header as='h3'>{t('HostApplications::Summary::Experience')}</Header>
      <Segment basic>
        <Grid columns={1}>
          <Grid.Column>
            {sampleExperience.get('experience_type') && (
              <Fragment>
                <Header size='small'>{t('HostApplications::Summary::Type of experience')}</Header>
                <Label basic circular>
                  {sampleExperience.get('experience_type')}
                </Label>
              </Fragment>
            )}
            {sampleExperience.getIn(['description', 'event_description']) && (
              <Fragment>
                <Header size='small'>{t('HostApplications::Summary::Description of the experience')}</Header>
                {sampleExperience.getIn(['description', 'event_description'])}
              </Fragment>
            )}
          </Grid.Column>
        </Grid>
      </Segment>
    </Segment>
  )
}

ExperienceSection.propTypes = {
  hostApplication: PropTypes.shape({
    sample_experience: PropTypes.object,
  }).isRequired,
}
