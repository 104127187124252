import settings from 'settings'

// Libs
import React, { useEffect, useState, useReducer } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import moment from 'moment'
import { useTranslation, Trans } from 'react-i18next'
// Components
import { Button, Card, Form, Icon, Modal, Dimmer, Loader, List, Rail, Segment, Header } from 'semantic-ui-react'
import { Link } from 'react-router'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
// Redux
import { fetchInvite, updateEventOnDate } from 'redux/entities/actions'
import {
  getEventError,
  getInvite,
  getRequestStatus,
  getScheduleEventRequests,
  getScheduleEventBookings,
  updatingEventOnDate,
} from 'redux/entities/selectors'
import './index.css'
// Helpers
import { numbers } from '@vizeat/helpers'
import { getBookingDeadlineOptions, getCancellationDeadlineOptions } from 'helpers/deadlines'
import { isDateTodayOrLater } from 'helpers/dates'
import { shortenLink } from 'helpers/url'

const { formatIntegerPrice } = numbers

const mapStateToProps = (state, props) => ({
  fromStore: {
    error: getEventError(state, props.eventWithOverrides.id, props.date),
    getInvite: (requestId) => getInvite(state, { date: props.date, eventId: props.eventWithOverrides.id, requestId }),
    getRequestStatus: (id) => getRequestStatus(state, { id }),
    requests: getScheduleEventRequests(state, { date: props.date, eventId: props.eventWithOverrides.id }),
    bookings: getScheduleEventBookings(state, { date: props.date, eventId: props.eventWithOverrides.id }),
    isUpdating: updatingEventOnDate(state, props.eventWithOverrides.id, props.date),
  },
})

const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    fetchInvite: (requestId) =>
      dispatch(
        fetchInvite({ query: { date: props.date, event_id: props.eventWithOverrides.id, request_id: requestId } }),
      ),
    updateEventOnDate: (payload) => dispatch(updateEventOnDate(props.eventWithOverrides.id, props.date, payload)),
  },
})

function _EventDetailModal(props) {
  const [publicEventUrl, setPublicEventUrl] = useState('')
  const [t] = useTranslation()

  function getDefaultBookingDeadline() {
    const { isPrivate, eventWithOverrides } = props
    const deadline = isPrivate ? eventWithOverrides.pe_booking_deadline : eventWithOverrides.booking_deadline
    return moment.duration(deadline).valueOf()
  }

  function getDefaultCancellationDeadline() {
    const { isPrivate, eventWithOverrides } = props
    const deadline = isPrivate ? eventWithOverrides.pe_cancellation_deadline : eventWithOverrides.cancellation_deadline
    return moment.duration(deadline).valueOf()
  }

  const initialState = {
    instant_booking: props.eventWithOverrides.instant_booking,
    max_seats: props.eventWithOverrides.max_seats,
    min_seats: props.eventWithOverrides.min_seats,
    begins_at: props.eventWithOverrides.begins_at,
    ends_at: props.eventWithOverrides.ends_at,
    booking_deadline: getDefaultBookingDeadline(),
    cancellation_deadline: getDefaultCancellationDeadline(),
  }
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'TOGGLE_INSTANT_BOOKING':
        return { ...state, instant_booking: !state.instant_booking }
      case 'SET_MAX_SEATS':
        return { ...state, max_seats: action.payload }
      case 'SET_MIN_SEATS':
        return { ...state, min_seats: action.payload }
      case 'SET_BEGINS_AT':
        return { ...state, begins_at: action.payload }
      case 'SET_ENDS_AT':
        return { ...state, ends_at: action.payload }
      case 'SET_BOOKING_DEADLINE':
        return { ...state, booking_deadline: action.payload }
      case 'SET_CANCELLATION_DEADLINE':
        return { ...state, cancellation_deadline: action.payload }
      default:
        throw new Error()
    }
  }, initialState)

  useEffect(() => {
    getPublicEventURL()
    if (props.isPrivate) {
      props.fromStore.requests.size === 0
        ? props.actions.fetchInvite()
        : props.fromStore.requests.map((request) => request.privatized_at && props.actions.fetchInvite(request.id))
    }
  }, [])

  function handleSubmit(e) {
    e.preventDefault()
    e.stopPropagation()
    const payload = {
      ...state,
      booking_deadline: moment.duration(state.booking_deadline).toISOString(),
      cancellation_deadline: moment.duration(state.cancellation_deadline).toISOString(),
    }
    return props.actions.updateEventOnDate(payload)
  }

  async function getPublicEventURL() {
    const url = await shortenLink(
      settings.webappUrlFactory.events.get(props.eventWithOverrides.id, 'en', {
        date: moment.utc(props.date).format('YYYY-MM-DD'),
      }),
      'kitchen-calendar',
    )

    setPublicEventUrl(url)
  }

  async function copyPrivateEventURL(eventId, token) {
    const url = await shortenLink(settings.webappUrlFactory.events.invite(eventId, token, 'en'), 'kitchen-calendar')

    navigator.clipboard.writeText(url)
  }

  const {
    bookedSeats,
    date,
    eventWithOverrides,
    fromStore: { error, getInvite, isUpdating, requests, bookings },
    hasPartnerDemand,
    isDemoEvent,
    isFrozen,
    isOpen,
    isPrivate,
    onClose,
    pendingSeats,
    requestedSeats,
    openInvoiceModal,
    openEditDietsModal,
  } = props
  const invite = getInvite()

  return (
    <Modal open={isOpen} onClose={onClose} closeIcon className='eventDetails'>
      <Modal.Header>
        <Trans
          i18nKey={__('EventsCalendar::Event Details ({{eventDate}}) ')}
          values={{ eventDate: moment.utc(date).format('ll') }}
        />
        {isPrivate && <Icon circular inverted color='red' name='lock' size='small' />}
        {eventWithOverrides.instant_booking && <Icon circular inverted color='green' name='lightning' size='small' />}
        {isDemoEvent && <Icon circular inverted color='yellow' name='plane' size='small' />}
        {isFrozen && <Icon circular inverted color='purple' name='asterisk' size='small' />}
        {hasPartnerDemand && <Icon circular inverted color='orange' name='group' size='small' />}
      </Modal.Header>
      <Modal.Content>
        <Dimmer inverted active={isUpdating}>
          <Loader inverted content={t('Loading::Loading')} />
        </Dimmer>
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            e.stopPropagation()
            return false
          }}
        >
          <List>
            <List.Item>
              <strong>{t('EventsCalendar::Event: ')}</strong>
              {eventWithOverrides.title}
              <a href={`/events/${eventWithOverrides.id}`} target='_blank' rel='noopener noreferrer'>
                {` (${eventWithOverrides.id})`}
              </a>
            </List.Item>
            {/* For events in the past, there is no invite_token, hence, no sense to show a Private Event URL */}
            {isPrivate && requests.size === 0 && invite ? (
              <List.Item>
                <strong>{t('EventsCalendar::Private event URL: ')}</strong>
                <Button onClick={() => copyPrivateEventURL(eventWithOverrides.id, invite.invite_token)}>
                  {t('EventsCalendar::Copy private event URL')}
                </Button>
              </List.Item>
            ) : (
              <List.Item>
                <strong>{t('EventsCalendar::Public event URL: ')}</strong>
                <a href={publicEventUrl} target='_blank' rel='noopener noreferrer'>
                  {publicEventUrl}
                </a>
              </List.Item>
            )}

            <List.Item>
              <strong>{t('EventsCalendar::Price set by host: ')}</strong>
              {formatIntegerPrice(eventWithOverrides.price, 'en', eventWithOverrides.user.currency.toJS())}
            </List.Item>

            <List.Item>
              <strong>{t('EventsCalendar::Price for guest: ')}</strong>
              {formatIntegerPrice(1.2 * eventWithOverrides.price, 'en', eventWithOverrides.user.currency.toJS())}
            </List.Item>
          </List>
          <Form.Group>
            <Form.Input
              label={t('EventsCalendar::Min seats')}
              type='number'
              value={state.min_seats}
              onChange={(e, { value }) =>
                dispatch({
                  type: 'SET_MIN_SEATS',
                  payload: parseInt(value, 10),
                })
              }
            />
            <Form.Input
              label={t('EventsCalendar::Max seats')}
              type='number'
              value={state.max_seats}
              onChange={(e, { value }) =>
                dispatch({
                  type: 'SET_MAX_SEATS',
                  payload: parseInt(value, 10),
                })
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              type='time'
              step='900'
              label={t('EventsCalendar::Begins at')}
              disabled={isFrozen}
              value={state.begins_at}
              onChange={(e, { value }) =>
                dispatch({
                  type: 'SET_BEGINS_AT',
                  payload: value,
                })
              }
            />
            <Form.Input
              type='time'
              step='900'
              label={t('EventsCalendar::Ends at')}
              disabled={isFrozen}
              value={state.ends_at}
              onChange={(e, { value }) =>
                dispatch({
                  type: 'SET_ENDS_AT',
                  payload: value,
                })
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Select
              compact
              disabled={isFrozen}
              label={t('EventsCalendar::Booking deadline')}
              onChange={(e, { value }) =>
                dispatch({
                  type: 'SET_BOOKING_DEADLINE',
                  payload: value,
                })
              }
              options={getBookingDeadlineOptions(t, {
                currentDeadline: state.booking_deadline,
                max: bookedSeats > 0 ? state.booking_deadline : undefined,
              })}
              value={state.booking_deadline}
            />
            <Form.Select
              compact
              disabled={isFrozen}
              label={t('EventsCalendar::Cancellation deadline')}
              onChange={(e, { value }) =>
                dispatch({
                  type: 'SET_CANCELLATION_DEADLINE',
                  payload: value,
                })
              }
              options={getCancellationDeadlineOptions(t, {
                currentDeadline: state.cancellation_deadline,
                max: bookedSeats > 0 ? state.cancellation_deadline : undefined,
                isPrivate,
              })}
              value={state.cancellation_deadline}
            />
          </Form.Group>
          <List>
            <List.Item>
              <strong>{t('EventsCalendar::Booked seats (pending): ')}</strong>
              {bookedSeats}
              {pendingSeats > 0 && ` ( ${pendingSeats})`}
            </List.Item>

            {requestedSeats > 0 &&
              requests.map((request, i) => {
                const invite = getInvite(request.id)
                return (
                  <Card key={request.id}>
                    <List>
                      <List.Item>
                        <strong>{t('EventsCalendar::Requested seats: ')}</strong>
                        {request.seats}
                      </List.Item>
                      <List.Item>
                        <strong>{t('EventsCalendar::Status: ')}</strong>
                        {props.fromStore.getRequestStatus(request.id)}
                      </List.Item>
                    </List>

                    {!!request.approved_at && invite && (
                      <Button.Group size='mini' widths='2'>
                        <Button
                          onClick={(e) => {
                            openInvoiceModal(request)
                          }}
                        >
                          {t('EventsCalendar::Create invoice')}
                        </Button>
                        <Button.Or />
                        <Button onClick={() => copyPrivateEventURL(eventWithOverrides.id, invite.invite_token)}>
                          {t('EventsCalendar::Copy Link')}
                        </Button>
                      </Button.Group>
                    )}
                  </Card>
                )
              })}

            <br />

            <List.Item>
              <strong>{t('EventsCalendar::Host: ')}</strong>
              {`${eventWithOverrides.user.firstname} ${eventWithOverrides.user.lastname}`}
              <a href={`/users/${eventWithOverrides.user.id}`} target='_blank' rel='noopener noreferrer'>
                {` (${eventWithOverrides.user.id})`}
              </a>
            </List.Item>
            <List.Item>
              <strong>{t('EventsCalendar::Host phone: ')}</strong>
              {eventWithOverrides.user.phone}
            </List.Item>
            <List.Item>
              <strong>{t('EventsCalendar::Host email: ')}</strong>
              {eventWithOverrides.user.account.email}
            </List.Item>
          </List>
          <br />
          <Form.Radio
            toggle
            label={t('EventsCalendar::Is Instant Booking')}
            disabled={isUpdating}
            checked={state.instant_booking}
            onChange={() => dispatch({ type: 'TOGGLE_INSTANT_BOOKING' })}
          />
          <Button primary size='big' type='submit' onClick={handleSubmit} disabled={isUpdating}>
            {t('EventsCalendar::Save updates')}
          </Button>
          <Rail internal attached position='right' className='__actions'>
            {isDateTodayOrLater(date) && requests.size === 0 && (
              <Segment>
                <Header size='small'>{t('EventsCalendar::EventsCalendar::Actions')}</Header>
                <hr />
                <Button
                  onClick={(e) => {
                    e.preventDefault()
                    openInvoiceModal()
                  }}
                >
                  {t('EventsCalendar::Create Invoice Booking')}
                </Button>
              </Segment>
            )}

            {bookings.size > 0 && (
              <Segment>
                <Header size='small'>{t('EventsCalendar::Dietary requirements')}</Header>
                {bookings.map((booking, i) => (
                  <div key={i}>
                    <hr />
                    <Trans
                      i18nKey={__('EventsCalendar::Booking: <bookingURL>{{bookingId}}</bookingURL>')}
                      values={{ bookingId: booking.id }}
                      components={{ bookingURL: <Link to={`/bookings/${booking.id}`} /> }}
                    />
                    <p>{booking.additional_info || t('EventsCalendar::(No requirements)')}</p>
                    <Button size='small' onClick={() => openEditDietsModal(booking)}>
                      {t('EventsCalendar::EventsCalendar::Edit')}
                    </Button>
                  </div>
                ))}
              </Segment>
            )}
          </Rail>
        </Form>

        <ApiErrorMessage error={error} modal />
      </Modal.Content>
    </Modal>
  )
}

_EventDetailModal.propTypes = {
  actions: PropTypes.shape({
    fetchInvite: PropTypes.func.isRequired,
    updateEventOnDate: PropTypes.func.isRequired,
  }).isRequired,
  bookedSeats: PropTypes.number,
  date: PropTypes.string.isRequired,
  eventWithOverrides: PropTypes.immutable.record.isRequired,
  fromStore: PropTypes.shape({
    error: PropTypes.immutable.map,
    getInvite: PropTypes.func.isRequired,
    getRequestStatus: PropTypes.func.isRequired,
    bookings: PropTypes.immutable.list.isRequired,
    requests: PropTypes.immutable.list.isRequired,
    isUpdating: PropTypes.bool.isRequired,
  }).isRequired,
  hasPartnerDemand: PropTypes.bool,
  isDemoEvent: PropTypes.bool.isRequired,
  isFrozen: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  pendingSeats: PropTypes.number,
  requestedSeats: PropTypes.number,
  openInvoiceModal: PropTypes.func.isRequired,
  openEditDietsModal: PropTypes.func.isRequired,
}
_EventDetailModal.defaultProps = {
  bookedSeats: 0,
  hasPartnerDemand: false,
  pendingSeats: undefined,
  requestedSeats: 0,
}

export const EventDetailModal = connect(mapStateToProps, mapDispatchToProps)(_EventDetailModal)
