// libraries
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { omitBy } from 'lodash'
import { withTranslation } from 'react-i18next'
// components
import { Form, Button, Icon, Loader, Pagination } from 'semantic-ui-react'
// helpers
import { getTranslatedOptions } from 'helpers/options'
import { mealTypesOptions } from 'helpers/events'
import { buildOptionsWithArray, isInvalid } from 'helpers/forms'

// redux
import {
  getSortedTags,
  getCampaignsCount,
  getEventsTotal,
  fetchingEvents,
  getEventsList,
} from 'redux/entities/selectors'
import { fetchEvents } from 'redux/entities/actions'
import { createForm, mergeInForm } from 'redux/forms/actions'
import { getForm } from 'redux/forms/reducer'
import { EventList } from 'components/lists'
import settings from 'settings'

const formName = 'events'

const initialState = {
  search: '',
  host: '',
  type: [],
  tags: [],
  offset: 0,
  size: settings.defaultPaginationSize,
  event_ids: [],
}

const mapStateToProps = (state) => ({
  fromStore: {
    events: getEventsList(state),
    eventForm: getForm(state, formName),
    tags: buildOptionsWithArray(getSortedTags(state).map(({ title }) => title)),
    total: getEventsTotal(state),
    count: getCampaignsCount(state),
    fetchingEvents: fetchingEvents(state),
  },
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    loadPaginatedEvents: (query = {}) => dispatch(fetchEvents({ query })),
    mergeInForm: (formData) => dispatch(mergeInForm({ formName: formName, value: formData })),
    createForm: ({ initialState }) => dispatch(createForm({ formName: formName, initialState })),
  },
})

class _EventSearch extends PureComponent {
  static contextTypes = { router: PropTypes.object }
  static propTypes = {
    fromStore: PropTypes.shape({
      tags: PropTypes.array,
      eventForm: PropTypes.map,
    }).isRequired,
    actions: PropTypes.object.isRequired, // eslint-disable-line
    eventIds: PropTypes.object.isRequired, // eslint-disable-line
  }

  static defaultProps = {
    eventIds: [],
  }

  state = {
    activePage: 1,
  }

  componentDidMount() {
    const { actions, eventIds } = this.props
    actions.createForm({ initialState: initialState })
    if (eventIds) {
      actions.mergeInForm({ event_ids: eventIds })
    }
    this.loadEvents(initialState)
  }

  handleEventsChange = (e, { value }) => this.props.actions.mergeInForm({ search: value })
  handleUsersChange = (e, { value }) => this.props.actions.mergeInForm({ host: value })
  handleTypesChange = (e, { value }) => this.props.actions.mergeInForm({ type: value })
  handleTagsChange = (e, { value }) => this.props.actions.mergeInForm({ tags: value })

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({ activePage: 1 })
    return this.loadEvents()
  }

  loadEvents() {
    const {
      fromStore: { eventForm },
      actions,
    } = this.props

    return actions.loadPaginatedEvents(
      omitBy(
        {
          host: eventForm.get('host'),
          offset: eventForm.get('offset'),
          search: eventForm.get('search'),
          size: settings.defaultPaginationSize,
          tags: eventForm.get('tags') && eventForm.get('tags').join(),
          types: eventForm.get('type') && eventForm.get('type').join(),
        },
        isInvalid,
      ),
    )
  }

  clearFilters = () => {
    const { actions } = this.props
    this.setState({ activePage: 1 })
    actions.mergeInForm(initialState)
  }

  SearchInput = (props) => <Form.Input icon='search' iconPosition='left' {...props} />

  handlePaginationChange = async (e, { activePage }) => {
    const {
      fromStore: { eventForm },
      actions,
    } = this.props
    this.setState({ activePage })

    const newEventForm = {
      search: eventForm.get('search'),
      host: eventForm.get('host'),
      type: eventForm.get('type'),
      tags: eventForm.get('tags'),
      offset: (activePage - 1) * settings.defaultPaginationSize,
      size: settings.defaultPaginationSize,
      event_ids: eventForm.get('event_ids'),
    }

    await actions.mergeInForm(newEventForm)
    await this.loadEvents()
  }

  getPageCount = (total) => Math.ceil(total / settings.defaultPaginationSize)

  render() {
    const {
      t,
      fromStore: { eventForm, fetchingEvents, total, events },
    } = this.props
    const search = eventForm.get('search') ? eventForm.get('search') : ''
    const host = eventForm.get('host') ? eventForm.get('host') : ''
    const typesArray = eventForm.get('type') ? eventForm.get('type').toArray() : []
    const tagsArray = eventForm.get('tags') ? eventForm.get('tags').toArray() : []
    return (
      <div className='eventSearch'>
        <Form onSubmit={this.handleSubmit}>
          <Form.Group widths='equal'>
            <Form.Field>
              <label>{t('Lists::Events::Search by experience')}</label>
              <this.SearchInput
                name='events'
                placeholder={t('Lists::Events::id or title')}
                value={search}
                onChange={this.handleEventsChange}
              />
            </Form.Field>

            <Form.Field>
              <label>{t('Lists::Events::Search by host')}</label>
              <this.SearchInput
                name='users'
                placeholder={t('Lists::Events::id, first/last name, email')}
                value={host}
                onChange={this.handleUsersChange}
              />
            </Form.Field>

            <Form.Field>
              <label>{t('Lists::Events::Experience type')}</label>
              <Form.Dropdown
                name='types'
                options={getTranslatedOptions(t, mealTypesOptions)}
                value={typesArray}
                onChange={this.handleTypesChange}
                fluid
                multiple
                selection
                search
              />
            </Form.Field>

            <Form.Field>
              <label>{t('Lists::Events::Experience tags')}</label>
              <Form.Dropdown
                name='tags'
                options={this.props.fromStore.tags}
                value={tagsArray}
                onChange={this.handleTagsChange}
                fluid
                multiple
                selection
                search
              />
            </Form.Field>
          </Form.Group>

          <div style={{ textAlign: 'center' }}>
            <Form.Field style={{ display: 'inline-block' }}>
              <Button type='submit'>{t('Lists::Events::Search')}</Button>
            </Form.Field>

            <Form.Field style={{ display: 'inline-block' }}>
              <Button type='button' basic onClick={this.clearFilters}>
                {t('Lists::Events::Clear filters')}
              </Button>
            </Form.Field>
          </div>
        </Form>
        {fetchingEvents ? (
          <div className='loadingEventSearch' style={{ minHeight: '5em' }}>
            <Loader inverted />
          </div>
        ) : (
          <div>
            <EventList events={events} />
            <div style={{ textAlign: 'center' }}>
              <Pagination
                activePage={this.state.activePage}
                onPageChange={this.handlePaginationChange}
                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
                totalPages={this.getPageCount(total)}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export const EventSearch = withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(_EventSearch))
