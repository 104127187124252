// libs
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
// components
import { Form } from 'semantic-ui-react'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
// helpers
import { getTranslatedOptions } from 'helpers/options'
import {
  bookingsFollowupsActions,
  bookingsFollowupsComments,
  eventsFollowupsActions,
  eventsFollowupsComments,
  partnersFollowupsActions,
  partnersFollowupsComments,
} from 'helpers/followups'

class _AddFollowupForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    followingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onSubmit: PropTypes.func.isRequired,
    error: PropTypes.shape({
      data: PropTypes.shape({
        message: PropTypes.string,
        stack_trace: PropTypes.any,
      }),
      status: PropTypes.number,
      statusText: PropTypes.string,
    }),
    type: PropTypes.string.isRequired,
  }

  static defaultProps = {
    followingId: undefined,
    error: undefined,
  }

  state = { action: '', staticComment: '', comment: '', error: null }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) this.setState({ error: this.props.error })
  }

  getOptions = (entityType, inputType) => {
    switch (entityType) {
      case 'bookings':
        return inputType === 'comment' ? bookingsFollowupsComments : bookingsFollowupsActions
      case 'events':
        return inputType === 'comment' ? eventsFollowupsComments : eventsFollowupsActions
      case 'partners':
        return inputType === 'comment' ? partnersFollowupsComments : partnersFollowupsActions
    }
  }

  render() {
    const { t } = this.props
    return (
      <Form
        onSubmit={() =>
          this.props.onSubmit({
            action: this.state.action,
            comment: this.state.comment,
          })
        }
        style={{ textAlign: 'center' }}
        error={!!this.props.error}
      >
        <label>{t('FollowupsModal::Action:')}</label>
        <Form.Select
          name='action'
          placeholder={t('FollowupsModal::Select an action')}
          value={this.state.action}
          onChange={(e, input) => this.setState({ action: input.value })}
          options={getTranslatedOptions(t, this.getOptions(this.props.type, 'action'))}
        />

        <label>{t('FollowupsModal::Comment:')}</label>
        <Form.Select
          name='comment'
          placeholder={t('FollowupsModal::Select a comment')}
          onChange={(e, input) => this.setState({ staticComment: input.value })}
          options={getTranslatedOptions(t, this.getOptions(this.props.type, 'comment'))}
        />

        {this.state.staticComment === 'Other' && (
          <Form.TextArea
            name='comment'
            placeholder={t('FollowupsModal::Write a comment')}
            onChange={(e, input) => this.setState({ comment: input.value })}
          />
        )}
        <Form.Button type='submit' primary>
          {t('FollowupsModal::FollowupsModal::OK')}
        </Form.Button>
        <ApiErrorMessage error={this.state.error} noStack />
      </Form>
    )
  }
}

export const AddFollowupForm = withTranslation('common')(_AddFollowupForm)
