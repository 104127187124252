import { schema } from 'normalizr'
import { Record, List } from 'immutable'
import { partners } from './partners'
import { users } from './users'

const _schema = new schema.Entity('campaigns', {
  partner: partners.schema,
  user: users.schema,
})

const _Record = Record({
  id: undefined,

  user_id: undefined,
  user: new users.Record(),

  title: '',
  description: '',
  category: '',
  code: '',
  active: undefined,
  begin_at: undefined,
  end_at: undefined,
  discount: undefined,
  type: '',
  usage: '',
  max_usage_limit: undefined,
  min_usage_limit: undefined,
  max_guest_limit: undefined,
  min_guest_limit: undefined,
  max_price_limit: undefined,
  min_price_limit: undefined,
  stripe_charge_id: '',
  free_only: undefined,
  first_time: undefined,
  event_ids: new List(),
  currency_id: undefined,

  created_at: undefined,
  updated_at: undefined,
  deleted_at: undefined,
})

export const campaigns = {
  schema: _schema,
  Record: _Record,
}
