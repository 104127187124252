import React, { PureComponent } from 'react'
import { LoginForm } from './Form'
import './index.css'

export default class LoginPage extends PureComponent {
  render () {
    return (
      <div className='login-page'>
        <LoginForm className='login-form' />
      </div>
    )
  }
}
