import settings from 'settings'
// libs
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'helpers/proptypes'
import { omitBy } from 'lodash'
import { withTranslation } from 'react-i18next'
// components
import { Paginate, List } from 'components/lists'
import { ConversationRow } from './Row'
import { Form, Select, Button, Checkbox } from 'semantic-ui-react'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
// helpers
import { getFullPath } from 'helpers/url'
import { isInvalid } from 'helpers/forms'
import { getTranslatedOptions } from '../../helpers/options'
import { conversationsFollowsUpActions } from 'helpers/followups'
// redux
import { getLocation } from 'redux/reducers/router'
import { fetchConversations, createFollowups } from 'redux/entities/actions'
import {
  getConversationsList,
  getConversationsCount,
  fetchingConversations,
  getConversationsError,
  getConversationsTotal,
} from 'redux/entities/selectors'
import { getAdminProfile } from 'redux/reducers/admin/selectors'

const mapStateToProps = (state) => ({
  location: getLocation(state),
  conversations: getConversationsList(state),
  count: getConversationsCount(state),
  total: getConversationsTotal(state),
  loading: fetchingConversations(state),
  error: getConversationsError(state),
  adminProfile: getAdminProfile(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    loadPaginatedConversations: ({ offset = 0, size = settings.defaultPaginationSize, ...rest }) =>
      dispatch(fetchConversations({ query: { offset, size, ...rest } })),
    addFollowups: (payload) => dispatch(createFollowups(payload)),
  },
})

const getStaticHeaders = (t) => [
  { label: t('Conversations::Id'), field: 'id' },
  { label: t('Conversations::Request/Bookings id') },
  { label: t('Conversations::Created'), field: 'created_at' },
  { label: t('Conversations::Sender') },
  { label: t('Conversations::Receiver') },
  { label: t('Conversations::Sender first message') },
  { label: t('Conversations::Receiver first message') },
  { label: t('Conversations::Status') },
  { label: t('Conversations::Last followup') },
]

class _ConversationList extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.shape({
      get: PropTypes.func,
      query: PropTypes.object,
      updateConversation: PropTypes.func,
    }).isRequired,
    conversations: PropTypes.immutable.list,
    count: PropTypes.number,
    total: PropTypes.number,
    loading: PropTypes.bool,
    actions: PropTypes.shape({
      loadPaginatedConversations: PropTypes.func,
      addFollowups: PropTypes.func,
    }).isRequired,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    adminProfile: PropTypes.shape({
      id: PropTypes.number,
    }),
  }

  static defaultProps = {
    conversations: undefined,
    count: 0,
    total: 0,
    loading: false,
    error: undefined,
    adminProfile: undefined,
  }

  state = { error: null, selectedAction: null, selection: [], comment: '' }

  UNSAFE_componentWillMount() {
    // Handle landing on /conversations without params
    const defaultQuery = { offset: 0, size: settings.defaultPaginationSize }
    const query = this.props.location.get('query')
    if (query.size) {
      this.loadConversations({ ...defaultQuery, ...query.toJS() })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (getFullPath(this.props.location) !== getFullPath(nextProps.location)) {
      this.loadConversations(nextProps.location.get('query').toJS())
    }
  }

  loadConversations(query) {
    return this.props.actions
      .loadPaginatedConversations(omitBy(query, isInvalid))
      .then(() => this.setState({ error: this.props.error }))
  }

  onSelect = (checked, payoutId) => {
    return this.setState({
      selection: checked ? [...this.state.selection, payoutId] : this.state.selection.filter((id) => id !== payoutId),
    })
  }

  selectAll = (e, { checked }) => {
    this.setState({
      selection: checked ? this.props.conversations.map((p) => p.id).toArray() : [],
    })
  }

  submit = () => {
    const followups = []

    this.state.selection.map((id) =>
      followups.push({
        following_id: id,
        following_type: 'conversations',
        action: this.state.selectedAction,
        comment: this.state.comment,
        admin_id: this.props.adminProfile.id,
      }),
    )
    return this.props.actions
      .addFollowups({ followups })
      .then(() => this.setState({ error: this.props.error, selection: [] }))
  }

  render() {
    const { t } = this.props
    const { selection, error } = this.state
    const allSelected = selection.length === this.props.conversations.size
    const headers = [...getStaticHeaders(t)].concat({
      label: <Checkbox onChange={this.selectAll} checked={allSelected} />,
    })

    return (
      <Paginate previous next count={this.props.total} loading={this.props.loading}>
        <Form className='vz-payout-paid-form' onSubmit={this.submit}>
          <Form.Group>
            <Form.Field>
              <label>{t('Conversations::Actions')}</label>
              <Select
                options={getTranslatedOptions(t, conversationsFollowsUpActions)}
                onChange={(e, { value }) => this.setState({ selectedAction: value })}
                value={this.state.selectedAction}
              />
            </Form.Field>

            <Form.Field>
              <label>{t('Conversations::Comment')}</label>
              <Form.TextArea
                rows={1}
                onChange={(e, { value }) => this.setState({ comment: value })}
                value={this.state.comment}
              />
            </Form.Field>

            <Form.Field>
              <label>&nbsp;</label>
              <Button type='submit' disabled={selection.length === 0}>
                {t('Conversations::Ok')}
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
        <List celled striped sortable headers={headers} location={this.props.location}>
          {this.props.conversations.toList().map((conversation, i) => (
            <ConversationRow
              key={i}
              conversation={conversation}
              onSelect={this.onSelect}
              selected={selection.includes(conversation.id)}
            />
          ))}
        </List>
        <ApiErrorMessage error={error} modal />
      </Paginate>
    )
  }
}

export const ConversationList = withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(_ConversationList),
)
