// Libs
import React, { useEffect } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { get } from 'lodash'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
// Components
import { Formik } from 'formik'
import { SeatsForm } from 'components/forms/seats'
import { useHasLoadingSucceeded } from 'hooks'
// Redux
import { updateBooking } from 'redux/entities/actions'
import { updatingBooking, getBookingError } from 'redux/entities/selectors'

const mapStateToProps = (state, props) => ({
  fromStore: {
    isUpdatingBooking: updatingBooking(state, props.booking && props.booking.id),
    bookingError: getBookingError(state, props.booking && props.booking.id),
  },
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    updateBooking: (id, payload) => dispatch(updateBooking(id, payload)),
  },
})

const _SeatsFormWrapper = ({ booking, actions, fromStore }) => {
  const isUpdatingSucceded = useHasLoadingSucceeded(fromStore.isUpdatingBooking, fromStore.bookingError)
  const [t] = useTranslation()

  useEffect(() => {
    if (isUpdatingSucceded) {
      toast.success(`${t('ToastNotification::The number of seats has been updated')} 👍`, { type: toast.TYPE.SUCCESS })
    }
  }, [isUpdatingSucceded])

  return booking && booking.id ? (
    <Formik
      initialValues={{
        seats: get(booking, 'seats', 0),
        ewFreeSeats: get(booking, 'ew_free_seats', 0),
        hostFreeSeats: get(booking, 'host_free_seats', 0),
        readOnly: booking.payment && booking.payment.get('payin') !== 'invoice',
      }}
      onSubmit={(values) =>
        actions.updateBooking(booking.id, {
          seats: values.seats,
          ew_free_seats: values.ewFreeSeats,
          host_free_seats: values.hostFreeSeats,
        })
      }
      component={SeatsForm}
      validationSchema={Yup.object().shape({
        seats: Yup.number().integer().positive(),
        ewFreeSeats: Yup.number().integer().min(0),
        hostFreeSeats: Yup.number().integer().min(0),
      })}
      validate={(values) => {
        const errors = {}

        if (values.seats < values.ewFreeSeats) {
          errors.ewFreeSeats = t('Forms::SeatsForm::EW free seats must be lower than the total number of seats')
        }

        if (values.seats < values.hostFreeSeats) {
          errors.hostFreeSeats = t('Forms::SeatsForm::Host free seats must be lower than the total number of seats')
        }

        if (values.ewFreeSeats + values.hostFreeSeats > values.seats) {
          errors.freeSeatsError = t(
            'Forms::SeatsForm::EW free seats + host free seats must be lower than the total number of seats',
          )
        }
        return errors
      }}
    />
  ) : null
}

_SeatsFormWrapper.propTypes = {
  booking: PropTypes.shape({
    id: PropTypes.number,
    payment: PropTypes.object,
  }).isRequired,
  fromStore: PropTypes.shape({
    isUpdatingBooking: PropTypes.bool,
    bookingError: PropTypes.object,
  }).isRequired,
  actions: PropTypes.shape({
    updateBooking: PropTypes.func,
  }).isRequired,
}

export const SeatsFormWrapper = connect(mapStateToProps, mapDispatchToProps)(_SeatsFormWrapper)
