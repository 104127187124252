import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import moment from 'moment'
import { List, Label } from 'semantic-ui-react'
import { Trans } from 'react-i18next'

export class FormattedFollowup extends PureComponent {
  static propTypes = {
    followups: PropTypes.immutable.list,
  }

  static defaultProps = {
    followups: undefined,
  }

  static actionsToExclude = ['ASSIGNMENT']

  render() {
    const lastFollowup =
      this.props.followups &&
      this.props.followups
        .filter((followup) => !FormattedFollowup.actionsToExclude.includes(followup.action))
        .sort((a, b) => a.id - b.id)
        .last()

    return lastFollowup ? (
      <List>
        {lastFollowup.admin && (
          <List.Item>
            <span>
              <Trans
                i18nKey={__('FollowupsModal::<label>{{adminName}}</label> on {{date}}')}
                values={{
                  adminName: lastFollowup.admin.get('fullname'),
                  date: moment(lastFollowup.created_at).format('lll'),
                }}
                components={{ label: <Label circular color='blue' /> }}
              />
            </span>
          </List.Item>
        )}
        <List.Item>
          {lastFollowup.action && (
            <strong> {lastFollowup.action.charAt(0).toUpperCase() + lastFollowup.action.slice(1).toLowerCase()}</strong>
          )}
          {lastFollowup.action && lastFollowup.comment ? <strong>: </strong> : ''}
          {lastFollowup.comment && lastFollowup.comment.toLowerCase()}
        </List.Item>
      </List>
    ) : null
  }
}
