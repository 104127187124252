import { PLATFORMS } from './konstants.js'

export function getEWPlatform(domainName: string): string {
  if (/eatwith\.net\.cn/gi.test(domainName)) {
    return PLATFORMS.CHINA
  }
  return PLATFORMS.INTERNATIONAL
}

export function isInternationalPlatform(domainName: string): boolean {
  return getEWPlatform(domainName) === PLATFORMS.INTERNATIONAL
}

export function isChinesePlatform(domainName: string): boolean {
  return getEWPlatform(domainName) === PLATFORMS.CHINA
}
