import React, { PureComponent } from 'react'
import { ConversationSearchForm } from './SearchForm'
import { ConversationList } from './List'

export default class ConversationsIndex extends PureComponent {
  render () {
    return (
      <div>
        <ConversationSearchForm />
        <ConversationList />
      </div>
    )
  }
}
