import React, { useState } from 'react'
import PropTypes from 'helpers/proptypes'
import { Form } from 'semantic-ui-react'
import { getTranslatedOptions } from 'helpers/options'
import { getCanTransitionToStatuses } from 'helpers/bookings'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { Trans, useTranslation } from 'react-i18next'

export function ChangeStatusForm({ id, status, onSubmit, error }) {
  const { t } = useTranslation()
  const [action, setAction] = useState('')

  return (
    <Form onSubmit={(e) => onSubmit(id, action, e)} style={{ textAlign: 'center' }} error={!!error}>
      <Trans
        i18nKey={__('Bookings::Actions::Current status: <b>{{status}}</b>')}
        values={{ status: status }}
        components={{ b: <b /> }}
      />

      <Form.Select
        name='status'
        placeholder={t('Bookings::Actions::Select a status')}
        value={action}
        onChange={(e, { value }) => setAction(value)}
        options={getTranslatedOptions(t, getCanTransitionToStatuses(status))}
      />

      <Form.Button type='submit' primary>
        {t('Bookings::Actions::OK')}
      </Form.Button>
      <ApiErrorMessage error={error} noStack />
    </Form>
  )
}

ChangeStatusForm.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  status: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.shape({
    data: PropTypes.shape({
      message: PropTypes.string,
      stack_trace: PropTypes.any,
    }),
    status: PropTypes.number,
    statusText: PropTypes.string,
  }),
}

ChangeStatusForm.defaultProps = {
  error: undefined,
}
