import { schema } from 'normalizr'
import { Record, List, Map } from 'immutable'

import { events } from './events'
import { partners } from './partners'
import { places } from './places'
import { users } from './users'

const _schema = new schema.Entity('bookings', {
  event: events.schema,
  partner: partners.schema,
  place: places.schema,
  user: users.schema,
})

const _Record = Record({
  additional_info: undefined,
  chosen_dates: new List(),
  conversation_id: undefined,
  conversation: undefined,
  coupon_code: undefined,
  cancellation_deadline: undefined,
  created_at: undefined,
  date: undefined,
  event_id: undefined,
  event: new events.Record(),
  ewid: undefined,
  followups: new List(),
  groupStatus: undefined,
  id: undefined,
  invoice_payment: undefined,
  occasion: undefined,
  partner_id: undefined,
  partner: new partners.Record(),
  payment: new Map(),
  place_id: undefined,
  place: new places.Record(),
  reference_code: undefined,
  request_id: undefined,
  seats: undefined,
  status: undefined,
  updated_at: undefined,
  user_id: undefined,
  user: new users.Record(),
  ew_free_seats: undefined,
  host_free_seats: undefined,
  partner_demand_id: undefined,
})

export const bookings = {
  schema: _schema,
  Record: _Record,
}
