import settings from 'settings'
// Libraries
import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { omitBy } from 'lodash'
import { withTranslation } from 'react-i18next'
// Components
import { FollowupsModal } from 'components/followups'
import { List, Paginate } from 'components/lists'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { PartnerRow } from './PartnerRow'
import { Segment, Loader } from 'semantic-ui-react'
// Utils
import { getFullPath } from 'helpers/url'
import { isInvalid } from 'helpers/forms'
// Redux
import { fetchPartners } from 'redux/entities/actions'
import {
  getPartnersList,
  getPartnersCount,
  getPartnersTotal,
  getPartnersError,
  fetchingPartners,
  creatingFollowup,
} from 'redux/entities/selectors'
import { getLocation } from 'redux/reducers/router'

const mapStateToProps = (state) => ({
  fromStore: {
    location: getLocation(state),
    error: getPartnersError(state),
    loading: fetchingPartners(state),
    partners: getPartnersList(state),
    count: getPartnersCount(state),
    total: getPartnersTotal(state),
    isFetching: fetchingPartners(state),
    addingFollowup: creatingFollowup(state),
  },
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    loadPaginatedPartners: (query) => dispatch(fetchPartners({ query })),
  },
})

const getHeaders = (t) => [
  { label: t('Partners::Id') },
  { label: t('Partners::Name') },
  { label: t('Partners::Email') },
  { label: t('Partners::Phone') },
  { label: t('Partners::API Key') },
  { label: t('Partners::Organization') },
  { label: t('Partners::Created At') },
  { label: t('Partners::Last followup') },
  { label: t('Partners::Actions') },
]

export class _PartnerList extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      loadPaginatedPartners: PropTypes.func,
    }).isRequired,
    fromStore: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.object,
      partners: PropTypes.object,
      count: PropTypes.number,
      total: PropTypes.number,
      location: PropTypes.immutable.map.isRequired,
      isFetching: PropTypes.bool.isRequired,
      addingFollowup: PropTypes.bool.isRequired,
    }).isRequired,
  }

  state = { error: null, openFollowup: false, selectedPartner: undefined }

  componentDidMount() {
    const defaultQuery = { offset: 0, size: settings.defaultPaginationSize }
    const query = this.props.fromStore.location.get('query')
    if (query.size > 0) {
      this.loadPartners({ ...defaultQuery, ...query.toJS() })
    }
  }

  componentDidUpdate(prevProps) {
    if (getFullPath(prevProps.fromStore.location) !== getFullPath(this.props.fromStore.location)) {
      this.loadPartners(this.props.fromStore.location.get('query').toJS())
    }
  }

  loadPartners(query) {
    const { actions, fromStore } = this.props
    return actions.loadPaginatedPartners(omitBy(query, isInvalid)).then(() => this.setState({ error: fromStore.error }))
  }

  render() {
    const { t, fromStore } = this.props
    return (
      <Fragment>
        {fromStore.isFetching ? (
          <Segment basic>
            <Loader active content={t('Loading::Loading')} inline='centered' />
          </Segment>
        ) : (
          <Paginate count={fromStore.total} previous next loading={fromStore.loading}>
            <List striped celled sortable headers={getHeaders(t)} location={fromStore.location}>
              {fromStore.partners.toList().map((partner) => (
                <PartnerRow
                  key={partner.id}
                  partner={partner}
                  onAddFollowup={() => this.setState({ openFollowup: true, selectedPartner: partner })}
                  addingFollowup={fromStore.addingFollowup}
                />
              ))}
            </List>
            <ApiErrorMessage error={fromStore.error} modal />
          </Paginate>
        )}
        {this.state.openFollowup && this.state.selectedPartner && (
          <FollowupsModal
            open={this.state.openFollowup}
            onClose={() => this.setState({ openFollowup: false })}
            followingType='partners'
            followingId={this.state.selectedPartner.id}
          />
        )}
      </Fragment>
    )
  }
}

export const PartnerList = withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(_PartnerList))
