import React, { PureComponent, Fragment } from 'react'
import { Header, Segment, Label, Feed, Button } from 'semantic-ui-react'
import PropTypes from 'helpers/proptypes'
import moment from 'moment'
import { getColorFromAction } from 'helpers/hostApplications'
import { Link } from 'react-router'
import { HostApplicationStatus } from '../HostApplicationStatus'
import { withTranslation } from 'react-i18next'

const NB_FOLLOWUPS_TO_DISPLAY = 3

class _HistorySection extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showAllFollowups: false,
    }
  }

  render() {
    const { t, status, completionRate } = this.props
    const followups = this.props.followups
      .filter((followup) => !!followup)
      .sort((f1, f2) => moment.utc(f2.get('created_at')) - moment.utc(f1.get('created_at')))

    const followupsToDisplay = this.state.showAllFollowups
      ? followups
      : followups.slice(0, followups.size > NB_FOLLOWUPS_TO_DISPLAY ? NB_FOLLOWUPS_TO_DISPLAY : followups.size)

    return (
      <Segment>
        <Header as='h3'>
          {t('HostApplications::Summary::Status:')}
          <HostApplicationStatus status={status} completionRate={completionRate} />
        </Header>

        {followups.size > 0 && (
          <Segment basic>
            <Feed>
              {followupsToDisplay.map((followup) => (
                <Feed.Event key={followup}>
                  <Feed.Content>
                    <Feed.Summary>
                      <Label circular empty color={getColorFromAction(followup.get('action'))} />
                      &nbsp;
                      <Link to={`/users/${followup.get('user_id')}`}>{followup.get('user_fullname')}</Link>&nbsp;
                      {followup.get('actionText')}
                      <Feed.Date>{moment.utc(followup.get('created_at')).format('lll')}</Feed.Date>
                    </Feed.Summary>
                    {followup.get('comment') && <Feed.Extra text>{followup.get('comment')}</Feed.Extra>}
                  </Feed.Content>
                </Feed.Event>
              ))}
            </Feed>
            {followups.size > NB_FOLLOWUPS_TO_DISPLAY && (
              <Fragment>
                {this.state.showAllFollowups ? (
                  <Button color='blue' size='mini' onClick={() => this.setState({ showAllFollowups: false })} basic>
                    {t('HostApplications::Summary::Hide')}
                  </Button>
                ) : (
                  <Button color='blue' size='mini' onClick={() => this.setState({ showAllFollowups: true })} basic>
                    {t('HostApplications::Summary::Show al')}l
                  </Button>
                )}
              </Fragment>
            )}
          </Segment>
        )}
      </Segment>
    )
  }
}

_HistorySection.propTypes = {
  t: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  completionRate: PropTypes.number,
  followups: PropTypes.immutable.list,
}

_HistorySection.defaultProps = {
  followups: [],
  completionRate: 0,
}

export const HistorySection = withTranslation('common')(_HistorySection)
