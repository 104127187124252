import settings from 'settings'
// Libraries
import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { omitBy } from 'lodash'
import { withTranslation } from 'react-i18next'
// Components
import { List, Paginate } from 'components/lists'

import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { OrganizationRow } from './OrganizationRow'
import { Segment, Loader } from 'semantic-ui-react'
// Utils
import { getFullPath } from 'helpers/url'
import { isInvalid } from 'helpers/forms'
// Redux
import { fetchOrganizations } from 'redux/entities/actions'
import {
  getOrganizationsError,
  fetchingOrganizations,
  getOrganizationsList,
  getOrganizationsCount,
  getOrganizationsTotal,
} from 'redux/entities/selectors'
import { getLocation } from 'redux/reducers/router'

const mapStateToProps = (state) => ({
  fromStore: {
    location: getLocation(state),
    error: getOrganizationsError(state),
    loading: fetchingOrganizations(state),
    organizations: getOrganizationsList(state),
    count: getOrganizationsCount(state),
    total: getOrganizationsTotal(state),
    isFetching: fetchingOrganizations(state),
  },
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    loadPaginatedOrganizations: (query) => dispatch(fetchOrganizations({ query })),
  },
})

const getHeaders = (t) => [
  { label: t('Organizations::Id') },
  { label: t('Organizations::Name') },
  { label: t('Organizations::Email') },
  { label: t('Organizations::Support Phone') },
  { label: t('Organizations::Online Payment') },
  { label: t('Organizations::Price visible'), field: 'type' },
  { label: t('Organizations::Revenue Share') },
  { label: t('Organizations::Playlists count') },
  { label: t('Organizations::API Key') },
  { label: t('Organizations::Actions') },
]

class _OrganizationList extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      loadPaginatedOrganizations: PropTypes.func,
    }).isRequired,
    fromStore: PropTypes.shape({
      location: PropTypes.immutable.map.isRequired,
      loading: PropTypes.bool,
      error: PropTypes.object,
      organizations: PropTypes.immutable.list,
      count: PropTypes.number,
      total: PropTypes.number,
      isFetching: PropTypes.bool.isRequired,
    }).isRequired,
  }

  state = { error: null }

  componentDidMount() {
    const defaultQuery = { offset: 0, size: settings.defaultPaginationSize }
    const query = this.props.fromStore.location.get('query')
    if (query.size > 0) {
      this.loadOrganizations({ ...defaultQuery, ...query.toJS() })
    }
  }

  componentDidUpdate(prevProps) {
    if (getFullPath(prevProps.fromStore.location) !== getFullPath(this.props.fromStore.location)) {
      this.loadOrganizations(this.props.fromStore.location.get('query').toJS())
    }
  }

  loadOrganizations = (query) => this.props.actions.loadPaginatedOrganizations(omitBy(query, isInvalid))

  render() {
    const {
      t,
      fromStore: { organizations, total, loading, location, error, isFetching },
    } = this.props

    return (
      <Fragment>
        {isFetching ? (
          <Segment basic>
            <Loader active content={t('Loading::Loading')} inline='centered' />
          </Segment>
        ) : (
          <Paginate count={total} previous next loading={loading}>
            <List striped celled sortable headers={getHeaders(t)} location={location}>
              {organizations.toList().map((organization) => (
                <OrganizationRow key={organization.id} organization={organization} />
              ))}
            </List>
            <ApiErrorMessage error={error} modal />
          </Paginate>
        )}
      </Fragment>
    )
  }
}

export const OrganizationList = withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(_OrganizationList),
)
