import settings from 'settings'
// libs
import React, { useMemo, useEffect } from 'react'
import { omitBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
// components
import { List, Paginate } from 'components/lists'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { PlaylistRow } from './PlaylistRow'
// helpers
import { getFullPath } from 'helpers/url'
import { isInvalid } from 'helpers/forms'
// redux
import { fetchPlaylists } from 'redux/entities/actions'
import {
  getFetchPlaylistsError,
  fetchingPlaylists,
  getPlaylistsList,
  getPlaylistsTotal,
} from 'redux/entities/selectors'
import { getLocation } from 'redux/reducers/router'
import { usePrevious } from 'hooks/usePrevious'

export default function PlaylistList() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const playlists = useSelector(getPlaylistsList)
  const total = useSelector(getPlaylistsTotal)
  const location = useSelector(getLocation)
  const loading = useSelector(fetchingPlaylists)
  const fetchError = useSelector(getFetchPlaylistsError)

  const headers = useMemo(
    () => [
      { label: t('Playlists::Id'), field: 'id' },
      { label: t('Playlists::Name') },
      { label: t('Playlists::Tag') },
      { label: t('Playlists::Location') },
      { label: t('Playlists::Selected events') },
      { label: t('Playlists::Order'), field: 'order' },
      { label: t('Playlists::Type') },
      { label: t('Playlists::Visibility') },
      { label: t('Playlists::Created'), field: 'created_at' },
      { label: t('Playlists::Updated'), field: 'updated_at' },
      { label: t('Playlists::Deleted'), field: 'deleted_at' },
      { label: t('Playlists::Actions') },
    ],
    [t],
  )
  const currentPath = useMemo(() => getFullPath(location), [location])
  const previousPath = usePrevious(currentPath)
  const hasPathChanged = location.get('search') && previousPath !== currentPath

  useEffect(() => {
    const defaultQuery = { offset: 0, size: settings.defaultPaginationSize }
    const locationQuery = location.get('query')?.toJS()
    const query = omitBy({ ...defaultQuery, ...locationQuery }, isInvalid)
    if (hasPathChanged) dispatch(fetchPlaylists({ query }))
  }, [dispatch, location, hasPathChanged])

  return (
    <Paginate count={total} previous next loading={loading}>
      <List striped celled sortable headers={headers} location={location}>
        {playlists.toList().map((playlist) => (
          <PlaylistRow key={playlist.id} playlist={playlist} />
        ))}
      </List>
      <ApiErrorMessage error={fetchError} modal />
    </Paginate>
  )
}
