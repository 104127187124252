import React from 'react'
import { connect } from 'react-redux'
import { Dropdown } from 'semantic-ui-react'
import PropTypes from 'helpers/proptypes'
import { getFormattedAdmins } from 'helpers/admins'
import { getAdmins, getAdmin } from 'redux/entities/selectors'
import { getAdminProfile } from 'redux/reducers/admin/selectors'
import { updateHostApplication } from 'redux/entities/actions'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'

const mapStateToProps = (state, props) => ({
  fromStore: {
    admins: getAdmins(state),
    getAdmin: (id) => getAdmin(state, { id }),
    adminProfile: getAdminProfile(state),
  },
})

const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    updateHostApplication: (payload) => dispatch(updateHostApplication(props.hostApplication.id, payload)),
  },
})

const _AssignmentDropdown = ({ hostApplication: { admin }, fromStore, actions }) => {
  const [t] = useTranslation()

  const handleAssignCM = (value) => {
    const admin = fromStore.getAdmin(value)
    const actionText = admin.id
      ? t('HostApplications::assigned the application to {{adminName}}', { adminName: admin.fullname })
      : t('HostApplications::removed the assignment')

    actions.updateHostApplication({
      followup: {
        user_id: get(fromStore.adminProfile, 'user.id'),
        user_fullname: fromStore.adminProfile.fullname,
        action: 'assignment',
        actionText,
      },
      admin: { assignment: { admin_id: admin && admin.id, admin_fullname: admin && admin.fullname } },
    })
  }

  return (
    <Dropdown
      search
      selection
      options={getFormattedAdmins(fromStore.admins).map(({ key, text }) => ({ text, value: key }))}
      onChange={(e, { value }) => handleAssignCM(value)}
      value={admin.get('assignment') && admin.get('assignment').get('admin_id')}
    />
  )
}

_AssignmentDropdown.propTypes = {
  actions: PropTypes.shape({
    updateHostApplication: PropTypes.func,
    loadIntercomUser: PropTypes.func,
  }).isRequired,
  hostApplication: PropTypes.immutable.record.isRequired,
  fromStore: PropTypes.shape({
    adminProfile: PropTypes.object,
    admins: PropTypes.object,
    getAdmin: PropTypes.func,
  }).isRequired,
}

export const AssignmentDropdown = connect(mapStateToProps, mapDispatchToProps)(_AssignmentDropdown)
