// Libs
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
// Redux
import { fetchAvailabilitiesByDate } from 'redux/entities/actions'
import { fetchingAvailabilitiesByDate, getAvailabilitiesByDate, getUser } from 'redux/entities/selectors'
// Components
import { Modal, Dimmer, Loader, Grid } from 'semantic-ui-react'
import { HostList } from './HostList'

const mapStateToProps = (state, props) => ({
  loadingAvailabilities: fetchingAvailabilitiesByDate(state),
  getAvailableHosts: () =>
    getAvailabilitiesByDate(state, { available: true, date: moment(props.selectedDate), city: props.city }), // Don't use UTC otherwise it will change the selected day
  getUnAvailableHosts: () =>
    getAvailabilitiesByDate(state, { available: false, date: moment(props.selectedDate), city: props.city }), // same here
  getUser: (id) => getUser(state, { id }),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    fetchAvailabilitiesByDate: (query) => dispatch(fetchAvailabilitiesByDate(query)),
  },
})

class _ModalAvailabilities extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    selectedDate: PropTypes.instanceOf(Date),
    city: PropTypes.string,
    loadingAvailabilities: PropTypes.bool,
    actions: PropTypes.shape({
      fetchAvailabilitiesByDate: PropTypes.func.isRequired,
    }).isRequired,
    getAvailableHosts: PropTypes.func.isRequired,
    getUnAvailableHosts: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
  }

  static defaultProps = {
    city: undefined,
    selectedDate: undefined,
    loadingAvailabilities: false,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectedDate, city, actions } = nextProps
    if (this.props.selectedDate !== nextProps.selectedDate) {
      actions.fetchAvailabilitiesByDate({
        query: { date: moment(selectedDate).format('YYYY-MM-DD'), city, availability: true },
      })
      actions.fetchAvailabilitiesByDate({
        query: { date: moment(selectedDate).format('YYYY-MM-DD'), city, availability: false },
      })
    }
  }

  render() {
    const {
      t,
      selectedDate,
      open,
      closeModal,
      loadingAvailabilities,
      getAvailableHosts,
      getUnAvailableHosts,
    } = this.props
    return (
      <Modal open={open} onClose={closeModal} closeIcon>
        <Modal.Header>
          {t('EventsCalendar::Host available for {{date}}', { date: moment(selectedDate).format('MMM Do, YYYY') })}
        </Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            {loadingAvailabilities ? (
              <Dimmer active inverted>
                <Loader inverted>{t('Loading::Loading')}</Loader>
              </Dimmer>
            ) : (
              <Grid columns={2} centered>
                <Grid.Row>
                  <Grid.Column>
                    <h4>{t('EventsCalendar::HOSTS AVAILABLE')}</h4>
                  </Grid.Column>
                  <Grid.Column>
                    <h4>{t('EventsCalendar::UNAVAILABLE HOSTS')}</h4>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row divided>
                  <Grid.Column>
                    <HostList hosts={getAvailableHosts().users} />
                  </Grid.Column>
                  <Grid.Column>
                    <HostList hosts={getUnAvailableHosts().users} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}

export const ModalAvailabilities = withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(_ModalAvailabilities),
)
