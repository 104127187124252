import { times, range, isBoolean, isArray, isEmpty, isString, isNil } from 'lodash'

function buildOption({ title: text, id: value }) {
  return { text, value }
}

/**
 * build select options [{ text, value }...] from array of object shaped { id, title }
 * @param  {Array} array of objects (immutable or not) shaped { id, title }
 * @return {Array} array of objects shaped { text, value }
 */
export function buildSelectOptions(arrayOfObj) {
  if (arrayOfObj && typeof arrayOfObj.toJS === 'function') arrayOfObj = arrayOfObj.toJS() // immutable arrayOfObj
  return arrayOfObj.map(buildOption)
}

export function buildOptionsWithArray(arrayOfStr) {
  if (arrayOfStr && arrayOfStr.toJS) arrayOfStr = arrayOfStr.toJS()
  return arrayOfStr.map((str) => ({ key: str, text: str, value: str }))
}

export const timeOptions = times(24 * 2, (n) => {
  let time = ''
  n = parseFloat(n / 2)
  if (parseInt(n) < 10) time = '0'
  time += n.toString().replace('.5', ':30')
  if ((n * 10) % 10 === 0) time += ':00'
  return { text: time, value: time }
})

export const seatOptions = range(1, 200).map((seat) => ({ text: seat, value: seat }))

export const isInvalid = (param) => {
  return (
    (!isBoolean(param) && param !== 0 && !param) ||
    (isArray(param) && isEmpty(param)) ||
    (isString(param) && isEmpty(param.trim())) ||
    isNil(param)
  )
}
