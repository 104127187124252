// Libraries
import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
// Components
import DatePicker from 'react-datepicker'
import { Form, Button } from 'semantic-ui-react'
import { InputExpandingOnFocus } from 'layouts'
import { SearchInput, SelectInput } from 'components/inputs'
// Selectors
import { getLocation } from 'redux/reducers/router'
// Redux
import { getSortedTags, getOrganizationsList } from 'redux/entities/selectors'
import { fetchOrganizations } from 'redux/entities/actions'
// Utils
import { buildOptionsWithArray } from 'helpers/forms'
import { getTranslatedOptions } from 'helpers/options'
import { statusOptions, dateRangeOptions } from 'helpers/bookings'
import { bookingsFollowupsActions } from 'helpers/followups'

const mapStateToProps = (state) => ({
  location: getLocation(state),
  getSortedTags: () => getSortedTags(state),
  organizations: getOrganizationsList(state),
})

const mapDispatchToProps = (dispatch) => ({
  loadOrganizations: () => dispatch(fetchOrganizations({ query: { all: true } })),
})

const getPrivatizedOptions = (t) => [
  { text: t('Bookings::PrivatizesOptions::All'), value: '' },
  { text: t('Bookings::PrivatizesOptions::Privatized'), value: 'true' },
  { text: t('Bookings::PrivatizesOptions::Public'), value: 'false' },
]
const getPartnerOptions = (t) => [
  { text: '', value: '' },
  { text: t('Bookings::PartnerOptions::Yes'), value: 'true' },
  { text: t('Bookings::PartnerOptions::No'), value: 'false' },
]

class _BookingListSearchForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.immutable.map.isRequired,
    getSortedTags: PropTypes.func.isRequired,
    organizations: PropTypes.immutable.list.isRequired,
    loadOrganizations: PropTypes.func.isRequired,
  }

  static contextTypes = { router: PropTypes.object }

  tagsOpts = []
  UNSAFE_componentWillMount() {
    this.props.loadOrganizations()
    return this.updateStateFromLocation(this.props)
  }

  componentDidUpdate(prevProps) {
    if (!this.props.location.equals(prevProps.location)) return this.updateStateFromLocation(this.props)
  }

  updateStateFromLocation(props) {
    return this.setState({
      search: props.location.getIn(['query', 'search'], ''),
      guest: props.location.getIn(['query', 'guest'], ''),
      host: props.location.getIn(['query', 'host'], ''),
      event: props.location.getIn(['query', 'event'], ''),
      location: props.location.getIn(['query', 'location'], ''),
      tags: props.location.getIn(['query', 'tags'], ''),
      organization_tags: props.location.getIn(['query', 'organization_tags'], ''),
      privatized: props.location.getIn(['query', 'privatized'], ''),
      partner: props.location.getIn(['query', 'partner'], ''),
      followups: props.location.getIn(['query', 'followups'], ''),
      status: props.location.getIn(['query', 'status'], ''),
      organizations: props.location.getIn(['query', 'organizations'], ''),
      minGuests: props.location.getIn(['query', 'minGuests'], ''),
      maxGuests: props.location.getIn(['query', 'maxGuests'], ''),
      reference_code: props.location.getIn(['query', 'reference_code'], ''),
    })
  }

  updateQuery(query = this.state) {
    return this.context.router.push(this.props.location.mergeIn(['query'], { ...query, offset: 0 }).toJS())
  }

  clearSorting = () => {
    return this.context.router.push(this.props.location.mergeIn(['query'], { sortBy: '', order: '' }).toJS())
  }

  clearFilters = () => {
    return this.setState(
      {
        search: '',
        guest: '',
        host: '',
        event: '',
        location: '',
        daterange: '',
        fromdate: '',
        todate: '',
        tags: '',
        organization_tags: '',
        userId: '',
        followups: '',
        organizations: '',
        privatized: '',
        partner: '',
        minGuests: '',
        maxGuests: '',
        reference_code: '',
      },
      () => this.updateQuery({ ...this.state, status: '' }),
    )
  }

  updateDateRange = (value) => {
    this.setState({ daterange: value })
    if (value === '') {
      this.setState({ fromdate: '' })
      this.setState({ todate: '' })
    }
  }

  updateFromDate = (date) => {
    const fromdate = moment(date).format('YYYY-MM-DD')
    if (!this.state.daterange) this.setState({ daterange: 'date' })
    this.setState({ fromdate })
    if (!this.state.todate || this.state.todate < fromdate) {
      this.setState({ todate: fromdate })
    }
  }

  updateToDate = (date) => {
    const todate = moment(date).format('YYYY-MM-DD')
    if (!this.state.daterange) this.setState({ daterange: 'date' })
    this.setState({ todate })
    if (!this.state.fromdate || this.state.fromdate > todate) {
      this.setState({ fromdate: todate })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    return this.updateQuery()
  }

  getTagsOpts() {
    if (this.tagsOpts.length === 0) {
      const tags = this.props.getSortedTags()
      if (tags.size) this.tagsOpts = buildOptionsWithArray(tags.map((t) => t.title).unshift(''))
    }
    return this.tagsOpts
  }

  formatOrganizationsOptions = () =>
    this.props.organizations.map((org) => ({ text: org.name, value: org.id.toString() }))

  render() {
    const { t } = this.props
    return (
      <Form onSubmit={this.handleSubmit} autoComplete='off'>
        <Form.Group className='__bookingFormGroup'>
          <InputExpandingOnFocus
            label={t('Bookings::Booking')}
            Component={SearchInput}
            queryName='search'
            placeholder={t('Bookings::id or created at')}
            icon='search'
            iconPosition='left'
            value={this.state.search}
            onChange={(value) => this.setState({ search: value })}
          />

          <InputExpandingOnFocus
            label={t('Bookings::Host')}
            focusWidth={220}
            Component={SearchInput}
            queryName='host'
            placeholder={t('Bookings::id, first/last name, email, phone')}
            icon='search'
            iconPosition='left'
            value={this.state.host}
            onChange={(value) => this.setState({ host: value })}
          />

          <InputExpandingOnFocus
            label={t('Bookings::Guest')}
            focusWidth={220}
            Component={SearchInput}
            queryName='guest'
            placeholder={t('Bookings::id, first/last name, email, phone')}
            icon='search'
            iconPosition='left'
            value={this.state.guest}
            onChange={(value) => this.setState({ guest: value })}
          />

          <InputExpandingOnFocus
            label={t('Bookings::Experience')}
            Component={SearchInput}
            queryName='event'
            icon='search'
            iconPosition='left'
            placeholder={t('Bookings::id, title or event date')}
            value={this.state.event}
            onChange={(value) => this.setState({ event: value })}
          />

          <InputExpandingOnFocus
            label={t('Bookings::Reference')}
            focusWidth={220}
            Component={SearchInput}
            queryName='reference_code'
            placeholder={t('Bookings::Reference')}
            icon='search'
            iconPosition='left'
            value={this.state.reference_code}
            onChange={(value) => this.setState({ reference_code: value })}
          />

          <InputExpandingOnFocus
            label={t('Bookings::Location')}
            focusWidth={220}
            Component={SearchInput}
            queryName='location'
            placeholder={t('Bookings::Address, city, country (iso), postal code')}
            icon='search'
            iconPosition='left'
            value={this.state.location}
            onChange={(value) => this.setState({ location: value })}
          />

          <InputExpandingOnFocus
            label={t('Bookings::Booking status')}
            blurWidth={90}
            Component={SelectInput}
            queryName='status'
            options={getTranslatedOptions(t, statusOptions)}
            multiple
            fluid
            search
            value={this.state.status}
          />

          <InputExpandingOnFocus
            label={t('Bookings::Privatized')}
            blurWidth={80}
            Component={SelectInput}
            queryName='privatized'
            options={getPrivatizedOptions(t)}
            fluid
            search
            value={this.state.privatized}
          />

          <InputExpandingOnFocus
            label={t('Bookings::Experience Tags')}
            blurWidth={80}
            Component={SelectInput}
            queryName='tags'
            options={this.getTagsOpts()}
            multiple
            fluid
            search
            value={this.state.tags}
          />

          <InputExpandingOnFocus
            label={t('Bookings::Followups')}
            blurWidth={80}
            Component={SelectInput}
            queryName='followups'
            options={getTranslatedOptions(t, bookingsFollowupsActions)}
            fluid
            search
            value={this.state.followups}
          />

          <Form.Field>
            <label>&nbsp;</label>
            <Button type='submit'>{t('Bookings::Search')}</Button>
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <InputExpandingOnFocus
            label={t('Bookings::Organizations')}
            blurWidth={120}
            Component={SelectInput}
            queryName='organizations'
            options={this.formatOrganizationsOptions().toArray()}
            multiple
            fluid
            search
            value={this.state.organizations}
          />

          <InputExpandingOnFocus
            label={t('Bookings::Organization Tags')}
            blurWidth={80}
            Component={SelectInput}
            queryName='organization_tags'
            options={this.getTagsOpts()}
            multiple
            fluid
            search
            value={this.state.organization_tags}
          />

          <Form.Field width={3}>
            <label>{t('Bookings::Search by date range')}</label>
            <SelectInput
              queryName='daterange'
              options={getTranslatedOptions(t, dateRangeOptions)}
              onChange={this.updateDateRange}
              value={this.state.daterange}
              preventNavigation
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Bookings::Between')}</label>
            <DatePicker
              name='fromdate'
              todayButton={t('Bookings::Today')}
              selected={this.state.fromdate && moment(this.state.fromdate).isValid() ? moment(this.state.fromdate) : ''}
              onChange={this.updateFromDate}
              placeholderText={t('Bookings::start date')}
              dateFormat='DD/MM/YYYY'
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Bookings::And')}</label>
            <DatePicker
              name='todate'
              todayButton={t('Bookings::Today')}
              selected={this.state.todate && moment(this.state.todate).isValid() ? moment(this.state.todate) : ''}
              onChange={this.updateToDate}
              placeholderText={t('Bookings::end date')}
              dateFormat='DD/MM/YYYY'
            />
          </Form.Field>

          <InputExpandingOnFocus
            label={t('Bookings::Min Guests')}
            blurWidth={80}
            Component={SearchInput}
            queryName='minGuests'
            placeholder={t('Bookings::Min Guests')}
            type='number'
            fluid
            value={this.state.minGuests}
            onChange={(value) => this.setState({ minGuests: value })}
          />

          <InputExpandingOnFocus
            label={t('Bookings::Max Guests')}
            blurWidth={80}
            Component={SearchInput}
            type='number'
            queryName='maxGuests'
            placeholder={t('Bookings::Max Guests')}
            fluid
            value={this.state.maxGuests}
            onChange={(value) => this.setState({ maxGuests: value })}
          />

          <InputExpandingOnFocus
            label={t('Bookings::Partner booking')}
            blurWidth={80}
            Component={SelectInput}
            queryName='partner'
            options={getPartnerOptions(t)}
            fluid
            search
            value={this.state.partner}
          />

          <Form.Field>
            <label>&nbsp;</label>
            <Button type='submit'>{t('Bookings::Search')}</Button>
          </Form.Field>

          <Button.Group vertical basic compact size='mini'>
            <Button onClick={this.clearFilters}>{t('Bookings::Clear filters')}</Button>
            <Button onClick={this.clearSorting}>{t('Bookings::Clear sorting')}</Button>
          </Button.Group>
        </Form.Group>
      </Form>
    )
  }
}

export const BookingListSearchForm = withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(_BookingListSearchForm),
)
