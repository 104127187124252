// libs
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
// helpers
import { numbers } from '@vizeat/helpers'
// redux
import { getAppLanguage } from 'redux/reducers/appSettings'
import { getCurrency } from 'redux/entities/selectors'

const { formatIntegerPrice, formatPriceAsFloat, formatPriceAsInt } = numbers

export function useMakePriceFormatter(currencyId) {
  const { t } = useTranslation()

  const currency = useSelector((state) => getCurrency(state, { id: currencyId }))
  const { locale } = useSelector(getAppLanguage)

  return useCallback(
    ({ price, showCents = false } = {}) => {
      if (price === undefined) return null
      if (price === 0) return t('Price::Free')
      if (showCents) return formatIntegerPrice(Math.ceil(price), locale, currency)
      const priceAsFloat = formatPriceAsFloat(price, currency)
      const roundedPriceAsInt = formatPriceAsInt(Math.round(priceAsFloat), currency)
      return formatIntegerPrice(Math.ceil(roundedPriceAsInt), locale, currency)
    },
    [currency, locale, t],
  )
}
