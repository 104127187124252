import turfCircle from '@turf/circle'

type CoordinateLong = { latitude: number; longitude: number }
type CoordinateShort = { lat: number; lng: number }

type BoundingBox = {
  southwest: CoordinateShort
  northeast: CoordinateShort
}

/**
 * Take a list of coordinates and determine the bouding box that goes around it
 * @param  {Array} coords  List of tuple [[y1, x1], [y2, x2]]
 * @return {Object}        Bounding box defined by southwest and northeast points
 */
export function boundingBoxAroundPolyCoords(coords: number[][]): BoundingBox {
  // [[y1, x1], [y2, x2]] => {x: [x1, x2], y: [y1, y2]}
  const res = coords.reduce<{ x: number[]; y: number[] }>(
    (acc, [y, x]) => {
      acc.x.push(x)
      acc.y.push(y)
      return acc
    },
    { x: [], y: [] },
  )

  res.x.sort((a, b) => a - b)
  res.y.sort((a, b) => a - b)

  return {
    southwest: { lat: res.x.shift() as number, lng: res.y.shift() as number },
    northeast: { lat: res.x.pop() as number, lng: res.y.pop() as number },
  }
}

/**
 * Take a point and a radius and calculate the bounding box containing the circle centered on the point and of the given radius
 * @param  {Object} point  Point defined by `{lat: ..., lng: ...}` or `{x: ..., y: ...}`
 * @return {Object}        Bounding box defined by southwest and northeast points
 */
export function boundingBoxAroundPoint(point: CoordinateShort | { x: number; y: number }, radius: number): BoundingBox {
  const { x, y } = 'lat' in point && 'lng' in point ? { x: point.lat, y: point.lng } : point
  return boundingBoxAroundPolyCoords(turfCircle([y, x], radius / 1000, { steps: 15 }).geometry.coordinates[0])
}

export function boundingBoxAroundPolyCoordsLatLng(coords: CoordinateShort[]): BoundingBox {
  // [{lat, lng}, {lat, lng}] => { lat: [], lng: [] }
  const res = coords.reduce<{ lat: number[]; lng: number[] }>(
    (acc, { lat, lng }) => {
      acc.lat.push(lat)
      acc.lng.push(lng)
      return acc
    },
    { lat: [], lng: [] },
  )

  res.lat.sort((a, b) => a - b)
  res.lng.sort((a, b) => a - b)

  return {
    southwest: { lat: res.lat.shift() as number, lng: res.lng.shift() as number },
    northeast: { lat: res.lat.pop() as number, lng: res.lng.pop() as number },
  }
}

// ************************************************************************** //

// CP from https://gitlab.com/snippets/28591
// http://gis.stackexchange.com/a/213898
// pointAtDistance(), pointInCircle(), and check results with distanceBetween().
const EARTH_RADIUS = 6371000 /* meters  */
const DEG_TO_RAD = Math.PI / 180.0
const THREE_PI = Math.PI * 3
const TWO_PI = Math.PI * 2

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isFloat(n: any): n is number {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

function recursiveConvert<T>(input: T, callback: (val: number) => number): typeof input {
  if (input instanceof Array) {
    return input.map((el) => recursiveConvert(el, callback)) as typeof input
  }
  if (input instanceof Object) {
    input = JSON.parse(JSON.stringify(input)) as typeof input
    for (const key in input) {
      const value = input[key]
      input[key] = recursiveConvert(value, callback) as typeof value
    }
    return input
  }
  if (isFloat(input)) {
    return callback(input) as T
  }
  return input
}

function toRadians<T>(input: T): typeof input {
  return recursiveConvert(input, (val) => val * DEG_TO_RAD)
}

function toDegrees<T>(input: T): typeof input {
  return recursiveConvert(input, (val) => val / DEG_TO_RAD)
}

/*
coords is an object: {latitude: y, longitude: x}
toRadians() and toDegrees() convert all values of the object
*/
export function pointAtDistance(inputCoords: CoordinateLong, distance: number, randFunc = Math.random): CoordinateLong {
  const coords = toRadians(inputCoords)
  const sinLat = Math.sin(coords.latitude)
  const cosLat = Math.cos(coords.latitude)
  /* go fixed distance in random direction */
  const bearing = randFunc() * TWO_PI
  const theta = distance / EARTH_RADIUS
  const sinBearing = Math.sin(bearing)
  const cosBearing = Math.cos(bearing)
  const sinTheta = Math.sin(theta)
  const cosTheta = Math.cos(theta)

  const latitude: number = Math.asin(sinLat * cosTheta + cosLat * sinTheta * cosBearing)
  let longitude: number =
    coords.longitude + Math.atan2(sinBearing * sinTheta * cosLat, cosTheta - sinLat * Math.sin(latitude))
  /* normalize -PI -> +PI radians */
  longitude = ((longitude + THREE_PI) % TWO_PI) - Math.PI
  return toDegrees({ latitude, longitude })
}

export function pointInCircle(coord: CoordinateLong, distance: number, randFunc = Math.random): CoordinateLong {
  const rnd = randFunc()
  /* use square root of random number to avoid high density at the center */
  const randomDist = Math.sqrt(rnd) * distance
  return pointAtDistance(coord, randomDist, randFunc)
}

/* haversine */
export function distanceBetween(start: CoordinateLong, end: CoordinateLong): number {
  const startPoint = toRadians(start)
  const endPoint = toRadians(end)
  const delta = {
    latitude: Math.sin((endPoint.latitude - startPoint.latitude) / 2),
    longitude: Math.sin((endPoint.longitude - startPoint.longitude) / 2),
  }
  const A =
    delta.latitude * delta.latitude +
    delta.longitude * delta.longitude * Math.cos(startPoint.latitude) * Math.cos(endPoint.latitude)

  return EARTH_RADIUS * 2 * Math.atan2(Math.sqrt(A), Math.sqrt(1 - A))
}
// ************************************************************************** //
