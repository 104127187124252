// libs
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
// helpers
import PropTypes from 'helpers/proptypes'
// components
import { Segment } from 'semantic-ui-react'
import { FileUploader } from 'components/inputs'
import { ImageCard } from './ImageCard'
import { PhotoSection } from './PhotoSection'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { DownloadPhotosModal } from './DownloadPhotosModal'
// redux
import { mergeInForm } from 'redux/forms/actions'
import { getForm } from 'redux/forms/reducer'
import { getUserGallery } from 'redux/entities/selectors'

const mapStateToProps = (state, props) => ({
  fromStore: {
    form: getForm(state, 'event'),
    gallery: getUserGallery(state, { id: props.event.user.id }),
  },
})
const mapDispatchToProps = (dispatch) => ({
  actions: {
    mergeInForm: (formData) => dispatch(mergeInForm({ formName: 'event', value: formData })),
  },
})

class _SectionMedia extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    event: PropTypes.immutable.record.isRequired,
    fromStore: PropTypes.shape({
      form: PropTypes.immutable.map,
      gallery: PropTypes.immutable.map,
    }).isRequired,
    actions: PropTypes.shape({
      mergeInForm: PropTypes.func,
    }).isRequired,
  }

  handleCoverFileUploaded = (fileInfo = [{}]) => {
    const {
      actions: { mergeInForm },
    } = this.props
    return mergeInForm({ cover: fileInfo.map((f) => f.uuid)[0] })
  }

  render() {
    const {
      t,
      event,
      fromStore: { form, gallery },
    } = this.props

    const cover = gallery.find((file) => form.get('cover') === file.uploadcare_id)
    const foodPhotos = gallery.filter((file) => form.get('foods').find((f) => f === file.uploadcare_id))
    const venuePhotos = gallery.filter((file) => form.get('venues').find((f) => f === file.uploadcare_id))
    const hostPhotos = gallery.filter((file) => form.get('sources').find((f) => f === file.uploadcare_id))
    const galleryPhotos = gallery.filter(
      (file) =>
        !hostPhotos.find((f) => f.id === file.id) &&
        !venuePhotos.find((f) => f.id === file.id) &&
        !foodPhotos.find((f) => f.id === file.id) &&
        form.get('cover') !== file.uploadcare_id,
    )

    return (
      <section className='section-media'>
        <Flex className='section-title'>
          <h3 style={{ marginBottom: 0 }}>{t('Experiences::Summary::Medias')}</h3>
          {gallery.size > 0 && <DownloadPhotosModal event={event} />}
        </Flex>

        <Segment>
          <h4>{t('Experiences::Summary::Cover photo')}</h4>
          {cover ? (
            <ImageCard file={cover} event={event} category='cover' />
          ) : (
            <FileUploader primary model='events' id={event.id} onUploaded={this.handleCoverFileUploaded} cover>
              {t('Experiences::Summary::Upload cover image')}
            </FileUploader>
          )}
        </Segment>

        <PhotoSection
          title={t('Experiences::Summary::Food photos')}
          photos={foodPhotos}
          event={event}
          category='foods'
        />

        <PhotoSection
          title={t('Experiences::Summary::Venue photos')}
          photos={venuePhotos}
          event={event}
          category='venues'
        />

        <PhotoSection
          title={t('Experiences::Summary::Host & atmosphere photos')}
          photos={hostPhotos}
          event={event}
          category='sources'
        />

        <PhotoSection
          title={t('Experiences::Summary::Other user photos not attached to this experience')}
          photos={galleryPhotos}
          event={event}
        />
      </section>
    )
  }
}

export const SectionMedia = withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(_SectionMedia))
