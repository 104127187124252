import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getFood, getFoods, getAlcohol, getAlcohols, getDiet, getDiets } from 'redux/entities/selectors'
import { fetchStatics } from 'redux/entities/actions'
import { Segment, Header, Divider, Grid, List } from 'semantic-ui-react'
import { SampleExperienceLocation } from './SampleExperienceLocation'
import { SampleExperienceDescription } from './SampleExperienceDescription'
import { SampleExperienceGallery } from './SampleExperienceGallery'
import { withTranslation, Trans } from 'react-i18next'

const mapStateToProps = (state, props) => ({
  statics: {
    foods: getFoods(state),
    diets: getDiets(state),
    alcohols: getAlcohols(state),
  },
  fromStore: {
    getFood: (id) => getFood(state, { id }),
    getAlcohol: (id) => getAlcohol(state, { id }),
    getDiet: (id) => getDiet(state, { id }),
  },
})

const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    loadResources: (resources) => dispatch(fetchStatics(resources)),
  },
})

class _SampleExperienceSection extends PureComponent {
  componentDidMount() {
    const resourcesToLoad = Object.keys(this.props.statics).filter((r) => !this.props.statics[r].size)
    if (resourcesToLoad.length) this.props.actions.loadResources(resourcesToLoad)
  }

  render() {
    const {
      t,
      hostApplication: { sample_experience: sampleExperience, user, place },
      fromStore,
    } = this.props
    const experienceDescription = sampleExperience.get('description')

    return sampleExperience ? (
      <Segment>
        <Header as='h3'>{t('HostApplications::Summary::Sample experience')}</Header>
        <Segment basic>
          <Grid>
            <Grid.Row columns={3}>
              <Grid.Column>
                <Header size='small'>{t('HostApplications::Summary::Type of experience')}</Header>
                {sampleExperience.get('experience_type')}

                <Header size='small'>{t('HostApplications::Summary::Experience for')}</Header>
                <Trans
                  i18nKey={__('HostApplications::Summary::From {{minSeatsNumber}} to {{maxSeatsNumber}} seats')}
                  values={{
                    minSeatsNumber: sampleExperience.get('min_seats') || '',
                    maxSeatsNumber: sampleExperience.get('max_seats') || '',
                  }}
                />
                <br />
                {sampleExperience.get('guest_type') && (
                  <List bulleted>
                    {sampleExperience.get('guest_type').map((guestType) => (
                      <List.Item key={guestType}>{guestType}</List.Item>
                    ))}
                  </List>
                )}
              </Grid.Column>

              <Grid.Column>
                {experienceDescription && experienceDescription.get('cuisine_ids') && (
                  <Fragment>
                    <Header size='small'>{t('HostApplications::Summary::Type of cuisine')}</Header>
                    <List bulleted>
                      {experienceDescription.get('cuisine_ids').map((cuisineId) => (
                        <List.Item key={cuisineId}>{fromStore.getFood(cuisineId).title}</List.Item>
                      ))}
                    </List>
                  </Fragment>
                )}

                <Header size='small'>{t('HostApplications::Summary::Price')}</Header>
                {`${sampleExperience.get('price')} ${sampleExperience.get('currency_iso3')}`}

                <Header size='small'>{t('HostApplications::Summary::Is price flexible?')}</Header>
                {sampleExperience.get('is_price_flexible')
                  ? t('HostApplications::Summary::Yes')
                  : t('HostApplications::Summary::No')}
              </Grid.Column>

              <Grid.Column>
                <Header size='small'>{t('HostApplications::Summary::Drinks')}</Header>
                <List bulleted>
                  {experienceDescription.get('alcohols_ids').map((alcoholId) => (
                    <List.Item key={alcoholId}>{fromStore.getAlcohol(alcoholId).title}</List.Item>
                  ))}
                </List>
                <Header size='small'>{t('HostApplications::Summary::Food intolerances')}</Header>
                <List bulleted>
                  {experienceDescription.get('diets_ids').map((dietid) => (
                    <List.Item key={dietid}>{fromStore.getDiet(dietid).title}</List.Item>
                  ))}
                </List>
              </Grid.Column>
            </Grid.Row>

            <Divider />

            <Grid.Row>
              <Grid.Column>
                <SampleExperienceLocation place={place} sampleExperience={sampleExperience} />
                <Divider />
              </Grid.Column>
            </Grid.Row>

            {sampleExperience.get('description') && (
              <Grid.Row>
                <Grid.Column>
                  <SampleExperienceDescription sampleExperienceDescription={sampleExperience.get('description')} />
                </Grid.Column>
              </Grid.Row>
            )}

            {user && (
              <Grid.Row>
                <Grid.Column>
                  <SampleExperienceGallery user={user} />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Segment>
      </Segment>
    ) : null
  }
}

_SampleExperienceSection.propTypes = {
  t: PropTypes.func.isRequired,
  hostApplication: PropTypes.shape({
    user: PropTypes.object,
    sample_experience: PropTypes.object,
    place: PropTypes.object,
  }).isRequired,
  statics: PropTypes.shape({
    foods: PropTypes.object,
  }).isRequired,
  fromStore: PropTypes.shape({
    getFood: PropTypes.func,
    getAlcohol: PropTypes.func,
    getDiet: PropTypes.func,
  }).isRequired,
  actions: PropTypes.shape({
    loadResources: PropTypes.func,
  }).isRequired,
}

export const SampleExperienceSection = withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(_SampleExperienceSection),
)
