// libraries
import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
// components
import { Link } from 'react-router'
import { Table, Item, Button, Input, Popup } from 'semantic-ui-react'
const { Row, Cell } = Table

export const OrganizationRow = ({ organization }) => {
  const [t] = useTranslation()
  return (
    <Row>
      <Cell>{organization.id}</Cell>
      {organization.logo_url ? (
        <Cell>
          <Item.Group>
            <Item>
              <Item.Image size='tiny' shape='rounded' src={organization.logo_url} />
              <Item.Content verticalAlign='middle'>{organization.name}</Item.Content>
            </Item>
          </Item.Group>
        </Cell>
      ) : (
        <Cell>{organization.name}</Cell>
      )}
      <Cell>{organization.email}</Cell>
      <Cell>{organization.phone}</Cell>
      <Cell>{organization.payment ? t('Organizations::yes') : t('Organizations::no')}</Cell>
      <Cell>{organization.display_price ? t('Organizations::yes') : t('Organizations::no')}</Cell>
      <Cell>{organization.revenue_share}</Cell>
      <Cell>{organization.playlist_nb || 0}</Cell>
      <Cell>
        <Input
          type='text'
          value={organization.api_key}
          readOnly
          onClick={(e) => {
            e.target.select()
            document.execCommand('copy')
          }}
        />
      </Cell>
      <Cell textAlign='center'>
        <Popup
          trigger={<Button primary circular as={Link} to={`/organizations/edit/${organization.id}`} icon='edit' />}
          content={t('Organizations::Edit Organization')}
          position='top right'
          inverted
        />
      </Cell>
    </Row>
  )
}

OrganizationRow.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    logo_url: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    payment: PropTypes.bool,
    revenue_share: PropTypes.number,
    playlist_nb: PropTypes.number,
    display_price: PropTypes.bool,
    api_key: PropTypes.string,
  }).isRequired,
}
