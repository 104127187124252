import settings from 'settings'
// libs
import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { omitBy } from 'lodash'
import { withTranslation } from 'react-i18next'
// components
import ReviewRow from './Row'
import { List, Paginate } from 'components/lists'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
// redux
import { getLocation } from 'redux/reducers/router'
import { fetchReviews } from 'redux/entities/actions'
import {
  fetchingReviews,
  getReviewsError,
  getReviewsList,
  getReviewsCount,
  getReviewsTotal,
} from 'redux/entities/selectors'
// helpers
import { getFullPath } from 'helpers/url'
import { isInvalid } from 'helpers/forms'

const mapStateToProps = (state) => ({
  error: getReviewsError(state),
  reviews: getReviewsList(state),
  count: getReviewsCount(state),
  loading: fetchingReviews(state),
  location: getLocation(state),
  total: getReviewsTotal(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    loadPaginatedReviews: (query) => dispatch(fetchReviews({ query })),
  },
})

const getHeaders = (t) => [
  { label: t('Reviews::Id'), field: 'id' },
  { label: t('Reviews::Review date'), field: 'review_date' },
  { label: t('Reviews::Event date') },
  { label: t('Reviews::Experience'), field: 'event_title' },
  { label: t('Reviews::Guest'), field: 'name' },
  { label: t('Reviews::Rating'), field: 'score' },
  { label: t('Reviews::Review'), field: 'review' },
  { label: t('Reviews::Type') },
  { label: t('Reviews::Status') },
]

export class ReviewList extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      loadPaginatedReviews: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.immutable.map.isRequired, // Immutable
    loading: PropTypes.bool,
    error: PropTypes.shape({
      data: PropTypes.shape({
        message: PropTypes.string,
        stack_trace: PropTypes.any,
      }),
      status: PropTypes.number,
      statusText: PropTypes.string,
    }),
    reviews: PropTypes.immutable.list,
    count: PropTypes.number,
    total: PropTypes.number,
  }

  static defaultProps = {
    loading: false,
    error: undefined,
    reviews: [],
    count: 0,
    total: 0,
  }

  state = { error: null }

  UNSAFE_componentWillMount() {
    const defaultQuery = { offset: 0, size: settings.defaultPaginationSize }
    let query
    if (this.props.location) {
      query = this.props.location.get('query')
      if (query.size > 0) {
        this.loadReviews({ ...defaultQuery, ...query.toJS() })
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (getFullPath(this.props.location) !== getFullPath(nextProps.location)) {
      this.loadReviews(nextProps.location.get('query').toJS())
    }
  }

  loadReviews(query) {
    return this.props.actions
      .loadPaginatedReviews(omitBy(query, isInvalid))
      .then(() => this.setState({ error: this.props.error }))
  }

  render() {
    const { t, reviews, loading, location, total } = this.props
    return (
      <Paginate count={total} previous next loading={loading}>
        <List striped celled sortable headers={getHeaders(t)} location={location}>
          {reviews.toList().map((review) => (
            <ReviewRow key={review.id} review={review} />
          ))}
        </List>
        <ApiErrorMessage error={this.state.error} modal />
      </Paginate>
    )
  }
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ReviewList))
