// Libraries
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withTranslation, Trans } from 'react-i18next'
// Components
import { PaymentPreferencesLabel } from 'components/labels'
import { AnonymiseUserButton } from './AnonymiseUserButton'
import { Table, Button, Modal } from 'semantic-ui-react'
import { Link } from 'react-router'
import { connectAs } from 'helpers/users'

import './Row.css'

const { Row, Cell } = Table

class _UserRow extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    anonymiseUser: PropTypes.func.isRequired,
    disableUser: PropTypes.func.isRequired,
    restoreUser: PropTypes.func.isRequired,
    loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
    index: PropTypes.number,
  }

  handleConnect = (e) => {
    connectAs(this.props.user.account.id)
  }

  handleDisable = (e) => {
    return this.props.disableUser(this.props.user.account.id)
  }

  handleAnonymise = (e) => {
    return this.props.anonymiseUser(this.props.user.account.id)
  }

  handleRestore = (e) => {
    return this.props.restoreUser(this.props.user.account.id)
  }

  EnableDisableButton = ({ user, ...props }) => {
    return !user.account.deactivated_at ? (
      <Button {...props} negative compact onClick={this.handleDisable}>
        {this.props.t('Users::Disable')}
      </Button>
    ) : (
      <Button {...props} color='yellow' compact onClick={this.handleRestore}>
        {this.props.t('Users::Restore')}
      </Button>
    )
  }

  render() {
    const { t, user, loading } = this.props

    return (
      <Row>
        <Cell className={user.ewid ? 'ew' : ''}>
          {user.id}
          {user.ewid && (
            <i>
              <br />
              {t('Users::ew-{{eatwithId}}', { eatwithId: user.ewid })}
            </i>
          )}
        </Cell>
        <Cell>
          {user.firstname} {user.lastname}
        </Cell>
        <Cell>{user.account.email}</Cell>
        <Cell>{user.phone}</Cell>
        <Cell>{user.locality}</Cell>
        <Cell>{user.country}</Cell>
        <Cell>{user.type}</Cell>
        <Cell>{user.type === 'host' && <PaymentPreferencesLabel user={user} />}</Cell>
        <Cell>{moment(user.created_at).format('lll')}</Cell>
        <Cell>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button as={Link} compact primary to={`/users/${user.id}`}>
              {t('Users::Edit')}
            </Button>
            {window.location.host.includes('eatwith.com') ? (
              <Modal
                trigger={
                  <Button primary compact>
                    {t('Users::Connect')}
                  </Button>
                }
              >
                <Modal.Header>
                  {t("Users::You're about to connect as the host in production (eatwith.com)")}
                </Modal.Header>
                <Modal.Description style={{ padding: 30, fontSize: 16 }}>
                  <p style={{ color: 'red' }}>
                    <Trans
                      i18nKey={__(
                        'Users::Are you sure you need to connect as the host in production? If possible, think of doing so via <anchor>kitchen.vizeater.info</anchor>',
                      )}
                      components={{ anchor: <a href='https://kitchen.vizeater.info' /> }}
                    />
                  </p>
                  <p>{t('Users::If you must connect in production, please')}</p>
                  <ul>
                    <li>
                      <Trans
                        i18nKey={__(
                          'Users::<bold>DO NOT CHANGE THE CURRENCY WHILE CONNECTED</bold>, otherwise you might change the price of the experience',
                        )}
                        components={{ bold: <b /> }}
                      />
                    </li>
                    <li>
                      {t(
                        'Users::Do not change the language of the site, otherwise the host might be confused the next time she visits her profile',
                      )}
                    </li>
                    <li>{t('Users::Think of logging out after doing what you have to do as the host')}</li>
                  </ul>
                  <Button primary negative onClick={this.handleConnect}>
                    {t('Users::Connect as the host in production')}
                  </Button>
                </Modal.Description>
              </Modal>
            ) : (
              <Button primary compact onClick={this.handleConnect}>
                {t('Users::Connect')}
              </Button>
            )}

            <this.EnableDisableButton user={user} loading={loading} />

            <AnonymiseUserButton user={user} />
          </div>
        </Cell>
      </Row>
    )
  }
}

export default withTranslation('common')(_UserRow)
