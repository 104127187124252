// libs
import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
// components
import { Form } from 'semantic-ui-react'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
// redux
import { getRequest } from 'redux/entities/selectors'
import { getAdminProfile } from 'redux/reducers/admin/selectors'

const mapStateToProps = (state) => ({
  fromStore: {
    adminProfile: getAdminProfile(state),
    getRequest: (id) => getRequest(state, { id }),
  },
})

class _ChangeStatusForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    status: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    error: PropTypes.object,
    fromStore: PropTypes.shape({
      adminProfile: PropTypes.immutable.record,
      getRequest: PropTypes.func,
    }).isRequired,
  }

  state = { action: '' }

  getStatusOptions = (t) => [
    { text: t('Requests::Actions::StatusOptions::Approve'), value: 'approve' },
    { text: t('Requests::Actions::StatusOptions::Cancel as guest'), value: 'canceled_as_guest' },
    { text: t('Requests::Actions::StatusOptions::Cancel as partner'), value: 'canceled_as_partner' },
    { text: t('Requests::Actions::StatusOptions::Cancel as host'), value: 'canceled_as_host' },
    { text: t('Requests::Actions::StatusOptions::Cancel as us'), value: 'canceled_as_us' },
    { text: t('Requests::Actions::StatusOptions::Expire'), value: 'expire' },
  ]

  submitForm = (e) => {
    e.preventDefault()
    const { action } = this.state
    const request = this.props.fromStore.getRequest(this.props.id)
    const admin = this.props.fromStore.adminProfile

    if (['approve', 'expire'].includes(action)) return this.props.onSubmit(this.props.id, { action })
    switch (action) {
      case 'canceled_as_guest':
      case 'canceled_as_partner':
        return this.props.onSubmit(this.props.id, { action: 'cancel', canceled_by: request.user_id })
      case 'canceled_as_host':
        return this.props.onSubmit(this.props.id, { action: 'cancel', canceled_by: request.event.user_id })
      case 'canceled_as_us':
        return this.props.onSubmit(this.props.id, { action: 'cancel', canceled_by: admin.id })
      default:
        return this.props.onSubmit(this.props.id, { action: '' })
    }
  }

  render() {
    const { t } = this.props
    return (
      <Form onSubmit={this.submitForm} style={{ textAlign: 'center' }} error={!!this.props.error}>
        <Form.Select
          name='status'
          placeholder={t('Requests::Actions::StatusOptions::Select a status')}
          onChange={(e, { value }) => this.setState({ action: value })}
          options={this.getStatusOptions(t)}
        />

        <Form.Button type='submit' primary>
          {t('Requests::Actions::OK')}
        </Form.Button>
        <ApiErrorMessage error={this.props.error} noStack />
      </Form>
    )
  }
}

export const ChangeStatusForm = withTranslation('common')(connect(mapStateToProps)(_ChangeStatusForm))
