// Libraries
import React, { Fragment } from 'react'
// Components
import { HostApplicationSearchForm } from './HostApplicationSearchForm'
import { HostApplicationList } from './HostApplicationList'

export const HostApplicationsIndex = () => (
  <Fragment>
    <HostApplicationSearchForm />
    <HostApplicationList />
  </Fragment>
)
