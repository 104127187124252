export const types = {
  CREATE: '@@forms/CREATE',
  REMOVE: '@@forms/REMOVE',
  SET_FORM_FIELD: '@@forms/SET_FORM_FIELD',
  SET_FORM_VALUE: '@@forms/SET_FORM_VALUE',
  MERGE_IN_FORM: '@@forms/MERGE_IN_FORM',
}

export function createForm ({ formName, initialState = {} }) {
  if (!formName) throw new Error('formName must be defined')
  return {
    type: types.CREATE,
    payload: {
      formName,
      initialState,
    },
  }
}

export const removeForm = ({ formName }) => ({
  type: types.REMOVE,
  payload: {
    formName,
  },
})

export const setForm = ({ formName, value }) => ({
  type: types.SET_FORM_VALUE,
  payload: { formName, value },
})

export const mergeInForm = ({ formName, value }) => ({
  type: types.MERGE_IN_FORM,
  payload: { formName, value },
})

export const setFormField = ({ formName, field, value }) => ({
  type: types.SET_FORM_FIELD,
  payload: { formName, field, value },
})
