import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { Segment, Button, List, Form, TextArea, Rating, Header, Container, Modal } from 'semantic-ui-react'
import PropTypes from 'helpers/proptypes'
import { updateHostApplication } from 'redux/entities/actions'
import { user as loadIntercomUser } from 'redux/actions/intercom/load'
import { getAdmins, getAdmin } from 'redux/entities/selectors'
import { getAdminProfile } from 'redux/reducers/admin/selectors'
import { getColorFromStatus } from 'helpers/hostApplications'
import { isInvalid } from 'helpers/forms'
import { merge, omitBy } from 'lodash'
import settings from 'settings'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { AssignmentDropdown } from '../AssignmentDropdown'
import { withTranslation } from 'react-i18next'

const mapStateToProps = (state, props) => ({
  fromStore: {
    admins: getAdmins(state),
    getAdmin: (id) => getAdmin(state, { id }),
    adminProfile: getAdminProfile(state),
  },
})

const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    updateHostApplication: (payload) => dispatch(updateHostApplication(props.hostApplication.id, payload)),
    loadIntercomUser: (userId) => dispatch(loadIntercomUser(userId)),
  },
})

const getDefaultState = (t) => ({
  comment: '',
  modalHeader: t('HostApplications::Summary::Are you sure?'),
  modalConfirmButton: t('HostApplications::Summary::Confirm'),
  modalConfirmButtonColor: 'blue',
  isConfirmModalOpen: false,
  action: '',
  actionText: '',
  status: '',
  error: undefined,
})

class _CommunitySection extends PureComponent {
  constructor(props) {
    super(props)
    this.state = getDefaultState(this.props.t)
  }

  actionButton = (status, action, actionText, text) => (
    <Button
      style={{ marginTop: '5px' }}
      floated='left'
      color={getColorFromStatus(status)}
      onClick={() =>
        this.setState({
          isConfirmModalOpen: true,
          status,
          action,
          actionText,
          modalHeader: this.props.t('HostApplications::Summary::Are you sure?'),
          modalConfirmButtonColor: getColorFromStatus(status),
          modalConfirmButton: text,
        })
      }
    >
      {text}
    </Button>
  )

  resetState = () => this.setState(getDefaultState(this.props.t))

  handleActionWithFollowup = ({ payload, action, actionText, comment }) => {
    const { actions, fromStore } = this.props
    const hostApplication = omitBy(
      merge(
        {
          followup: {
            user_id: fromStore.adminProfile.user,
            user_fullname: fromStore.adminProfile.fullname,
            action,
            actionText,
            comment: comment || undefined,
          },
        },
        payload,
      ),
      isInvalid,
    )
    if (this.state.status) {
      hostApplication.metadata = { status: this.state.status }
    }

    actions.updateHostApplication(hostApplication)
    this.resetState()
  }

  handleRate = (e, { rating }) =>
    this.handleActionWithFollowup({
      payload: { admin: { application_rate: rating } },
      action: 'rating',
      actionText: this.props.t('HostApplications::Summary::rated the application'),
    })

  render() {
    const { t, hostApplication } = this.props
    return hostApplication ? (
      <Segment>
        <Header as='h3'>{t('HostApplications::Summary::Actions')}</Header>
        <Form>
          <List relaxed>
            <List.Item>
              <Form.Field>
                <label>{t('HostApplications::Summary::Rate this application:')}</label>
                <Rating
                  icon='star'
                  maxRating={5}
                  defaultRating={hostApplication.admin.get('application_rate')}
                  onRate={this.handleRate}
                />
              </Form.Field>
            </List.Item>
            <List.Item>
              <Form.Field>
                <label>{t('HostApplications::Summary::Assign to:')}</label>
                <AssignmentDropdown hostApplication={hostApplication} />
              </Form.Field>
            </List.Item>
            <List.Item>
              {['to review', 're-submitted'].includes(hostApplication.metadata.get('status')) && (
                <Fragment>
                  {this.actionButton(
                    'approved',
                    'approval',
                    t('HostApplications::Summary::approved the application'),
                    t('HostApplications::Summary::Approve'),
                  )}
                  {this.actionButton(
                    'rejected',
                    'rejection',
                    t('HostApplications::Summary::rejected the application'),
                    t('HostApplications::Summary::Reject'),
                  )}
                  <Button
                    floated='left'
                    color='orange'
                    style={{ marginTop: '5px' }}
                    onClick={() => {
                      this.handleActionWithFollowup({
                        action: 'contact',
                        actionText: t('HostApplications::Summary::contacted the host'),
                      })
                      this.props.actions
                        .loadIntercomUser(hostApplication.metadata.get('user_id'))
                        .then((intercomResponse) => {
                          if (intercomResponse.data) {
                            window.open(
                              `https://app.intercom.com/a/apps/${settings.intercomApiId}/users/${intercomResponse.data.intercom_user.id}/messages`,
                            )
                          } else {
                            this.setState({
                              error: {
                                data: {
                                  message: intercomResponse.message,
                                  stack_trace: intercomResponse,
                                },
                                status: 404,
                              },
                            })
                          }
                        })
                    }}
                  >
                    {t('HostApplications::Summary::Contact the host')}
                  </Button>
                </Fragment>
              )}
              <Button
                style={{ marginTop: '5px' }}
                floated='left'
                color='blue'
                onClick={() =>
                  this.setState({
                    isConfirmModalOpen: true,
                    action: 'comment',
                    actionText: t('HostApplications::Summary::added a comment'),
                    modalHeader: t('HostApplications::Summary::Team comment'),
                    modalConfirmButton: t('HostApplications::Summary::Add comment'),
                    modalConfirmButtonColor: 'blue',
                  })
                }
              >
                {t('HostApplications::Summary::Team Comment')}
              </Button>
            </List.Item>
          </List>
        </Form>

        <Modal open={this.state.isConfirmModalOpen} onClose={this.handleClose} size='small'>
          <Header content={this.state.modalHeader} />
          <Modal.Content>
            <Container>
              <Form>
                <Form.Field>
                  <label>{t('HostApplications::Summary::Message to the candidate:')}</label>
                  <TextArea
                    placeholder={t('HostApplications::Summary::Note from the CM')}
                    onChange={(e) => this.setState({ comment: e.target.value })}
                    value={this.state.comment}
                  />
                </Form.Field>
              </Form>
            </Container>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.resetState()}>Cancel</Button>
            <Button
              color={this.state.modalConfirmButtonColor}
              onClick={() =>
                this.handleActionWithFollowup({
                  action: this.state.action,
                  actionText: this.state.actionText,
                  comment: this.state.comment,
                })
              }
            >
              {this.state.modalConfirmButton}
            </Button>
          </Modal.Actions>
        </Modal>

        <ApiErrorMessage error={this.state.error} modal />
      </Segment>
    ) : null
  }
}

_CommunitySection.propTypes = {
  t: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    updateHostApplication: PropTypes.func,
    loadIntercomUser: PropTypes.func,
  }).isRequired,
  hostApplication: PropTypes.immutable.record,
  fromStore: PropTypes.shape({
    adminProfile: PropTypes.object,
    admins: PropTypes.object,
    getAdmin: PropTypes.func,
  }).isRequired,
}

_CommunitySection.defaultProps = {
  hostApplication: undefined,
}

export const CommunitySection = withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(_CommunitySection),
)
