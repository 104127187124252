// Libraries
import React, { PureComponent } from 'react'
// Components
import SearchForm from './SearchForm'
import ReviewList from './List'

export default class ReviewIndex extends PureComponent {
  render () {
    return (
      <div>
        <SearchForm />
        <ReviewList />
      </div>
    )
  }
}
