import settings from 'settings'
// Libraries
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { omitBy } from 'lodash'
import { withTranslation } from 'react-i18next'
// Components
import { List, Paginate } from 'components/lists'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import PayoutRow from './Row'
import { Form, Checkbox, Button, Select } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
// Utils
import { getFullPath } from 'helpers/url'
import { getTranslatedOptions } from 'helpers/options'
import { paidAtActions } from 'helpers/payouts'
import { isInvalid } from 'helpers/forms'
// Redux
import { fetchPayouts, updatePayouts } from 'redux/entities/actions'
import {
  getPayoutsList,
  getPayoutsCount,
  getPayoutsTotal,
  getPayoutsError,
  fetchingPayouts,
} from 'redux/entities/selectors'
import { getLocation } from 'redux/reducers/router'
// Css
import './List.css'

const mapStateToProps = (state) => ({
  error: getPayoutsError(state),
  loading: fetchingPayouts(state),
  payouts: getPayoutsList(state),
  count: getPayoutsCount(state),
  total: getPayoutsTotal(state),
  location: getLocation(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    loadPaginatedPayouts: ({ offset = 0, size = settings.defaultPaginationSize, ...rest }) =>
      dispatch(fetchPayouts({ query: { offset, size, ...rest } })),
    updatePaidAt: (payload) => dispatch(updatePayouts(payload)),
  },
})

const getStaticHeaders = (t) => [
  { label: t('Payouts::Id') },
  { label: t('Payouts::Gateway') },
  { label: t('Payouts::User') },
  { label: t('Payouts::Contact Information') },
  { label: t('Payouts::Currency') },
  { label: t('Payouts::Amount') },
  { label: t('Payouts::Paypal Id') },
  { label: t('Payouts::Stripe Payout Receipt') },
  { label: t('Payouts::Stripe Status') },
  { label: t('Payouts::Failure Reason') },
  { label: t('Payouts::Reference Codes') },
  { label: t('Payouts::Paid at') },
  { label: t('Payouts::Created at') },
  { label: t('Payouts::Bank accounts') },
]

export class PayoutsList extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.object,
    payouts: PropTypes.object,
    count: PropTypes.number,
    total: PropTypes.number,
  }

  state = { error: null, selection: [], paidAt: moment().format('YYYY-MM-DD'), markPaid: true }
  UNSAFE_componentWillMount() {
    const defaultQuery = { offset: 0, size: settings.defaultPaginationSize }
    const query = this.props.location.get('query')
    if (query.size > 0) {
      this.loadPayouts({ ...defaultQuery, ...query.toJS() })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (getFullPath(this.props.location) !== getFullPath(nextProps.location)) {
      this.loadPayouts(nextProps.location.get('query').toJS())
    }
  }

  loadPayouts = (query) => {
    return this.props.actions
      .loadPaginatedPayouts(omitBy(query, isInvalid))
      .then(() => this.setState({ error: this.props.error }))
  }

  onSelect = (checked, payoutId) => {
    return this.setState({
      selection: checked ? [...this.state.selection, payoutId] : this.state.selection.filter((id) => id !== payoutId),
    })
  }

  selectAll = (e, { checked }) => {
    this.setState({
      selection: checked ? this.props.payouts.map((p) => p.id).toArray() : [],
    })
  }

  updatePaidAt = (date) => {
    return this.setState({ paidAt: moment(date).format('YYYY-MM-DD') })
  }

  submit = () => {
    return this.props.actions
      .updatePaidAt({
        markPaid: this.state.markPaid,
        date: this.state.paidAt,
        ids: this.state.selection,
      })
      .then(() => this.setState({ error: this.props.error }))
      .then(() => this.setState({ selection: [] }))
      .then(() => this.loadPayouts({ ...this.props.location.get('query').toJS() }))
  }

  render() {
    const { t, payouts, total, loading, location } = this.props
    const { selection, error } = this.state
    const allSelected = this.state.selection.length === this.props.payouts.size
    const headers = [...getStaticHeaders(t)].concat({
      label: <Checkbox onChange={this.selectAll} checked={allSelected} />,
    })

    return (
      <Paginate count={total} previous next loading={loading}>
        <Form className='vz-payout-paid-form' onSubmit={this.submit}>
          <Form.Group>
            <Form.Field>
              <label>{t('Payouts::For the selection')}</label>
              <Select
                options={getTranslatedOptions(t, paidAtActions)}
                onChange={(e, { value }) => this.setState({ markPaid: value })}
                value={this.state.markPaid}
              />
            </Form.Field>

            {this.state.markPaid && (
              <Form.Field>
                <label>{t('Payouts::Paid date')}</label>
                <DatePicker
                  name='paidat'
                  todayButton={t('Payouts::Today')}
                  selected={this.state.paidAt && moment(this.state.paidAt).isValid() ? moment(this.state.paidAt) : ''}
                  onChange={this.updatePaidAt}
                  placeholderText={t('Payouts::start date')}
                  dateFormat='DD/MM/YYYY'
                />
              </Form.Field>
            )}

            <Form.Field>
              <label>&nbsp;</label>
              <Button type='submit' disabled={selection.length === 0}>
                {t('Payouts::Ok')}
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
        <List striped celled sortable headers={headers} location={location}>
          {payouts.toList().map((payout) => (
            <PayoutRow
              key={payout.id}
              payout={payout}
              onSelect={this.onSelect}
              selected={selection.includes(payout.id)}
            />
          ))}
        </List>
        <ApiErrorMessage error={error} modal />
      </Paginate>
    )
  }
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(PayoutsList))
