import React, { Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@vizeat/components/es6/components/Grid'
import { Checkbox } from '@vizeat/components/es6/components/Checkbox/Checkbox'
import { SectionWrapper } from './SectionWrapper'
import { DeletableBadge } from 'components/badges/DeletableBadge'
import PropTypes from 'helpers/proptypes'
import { useMultiFiltersToggle } from './hooks/useMultiFiltersToggle'

export function MealType({ onChange, showSelectedFilters, selectedValues }) {
  const { t } = useTranslation()

  const mealtypes = useMemo(() => {
    return [
      {
        title: 'Aperitif',
        translation: t('MealType::Aperitif'),
      },
      {
        title: 'Breakfast',
        translation: t('MealType::Breakfast'),
      },
      {
        title: 'Brunch',
        translation: t('MealType::Brunch'),
      },
      {
        title: 'Dinner',
        translation: t('MealType::Dinner'),
      },
      {
        title: 'Lunch',
        translation: t('MealType::Lunch'),
      },
      {
        title: 'Tasting',
        translation: t('MealType::Tasting'),
      },
      {
        title: 'Picnic',
        translation: t('MealType::Picnic'),
      },
      {
        title: 'Cooking classes',
        translation: t('MealType::Cooking classes'),
      },
      {
        title: 'Food walk',
        translation: t('MealType::Food walk'),
      },
      {
        title: 'Tea time',
        translation: t('MealType::Tea time'),
      },
    ]
  }, [t])

  const handleChange = useMultiFiltersToggle(selectedValues, { onChange, filterName: 'mealtypes' })

  const selectedMealTypes = useMemo(
    () => mealtypes.filter(({ title }) => selectedValues.includes(title)),
    [mealtypes, selectedValues],
  )

  if (showSelectedFilters) {
    return (
      <Fragment>
        {selectedMealTypes.map(({ title, translation }) => {
          return (
            <DeletableBadge
              key={`mealtypes-${title}`}
              color='mediumGray'
              onClick={() => {
                handleChange(null, title)
              }}
            >
              {translation}
            </DeletableBadge>
          )
        })}
      </Fragment>
    )
  }

  return (
    <SectionWrapper title={t('Playlists::Summary::Type of experience')}>
      <Grid templateColumns={{ default: 'repeat(2, 1fr)', tablet: 'repeat(4, 1fr)' }} gap='28px'>
        {mealtypes.map(({ title, translation }) => {
          return (
            <Checkbox
              key={`mealtypes-${title}`}
              id={`mealtypes-${title}`}
              label={translation}
              name={title}
              checked={selectedValues.includes(title)}
              onChange={handleChange}
              mt='0'
            />
          )
        })}
      </Grid>
    </SectionWrapper>
  )
}

MealType.propTypes = {
  onChange: PropTypes.func.isRequired,
  showSelectedFilters: PropTypes.bool,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
}

MealType.defaultProps = {
  showSelectedFilters: false,
  selectedValues: [],
}
