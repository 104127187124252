import { getEntities, getEntity, getEntityOrder, isFetching } from './_utils'
import moment from 'moment'
import crypto from 'crypto'

export const getAvailabilitiesByDateOrder = getEntityOrder('availabilitiesByDate')
export const fetchingAvailabilitiesByDate = isFetching('/api/users/availabilities')
export const getAvailabilitiesByDates = getEntities('availabilitiesByDate')

export const getAvailabilitiesByDate = (state, { available, date, city }) => {
  if (!date || !city) return getEntity('availabilitiesByDate')(state, { id: undefined }) // default empty record
  const id = crypto
    .createHash('sha1')
    // Do not use moment.utc to prevent day switch on the US timezone
    .update(city.concat(moment(date).format('YYYY-MM-DD')).concat(available))
    .digest('hex')
  return getEntity('availabilitiesByDate')(state, { id })
}
