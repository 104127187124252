import { schema } from 'normalizr'
import { Record } from 'immutable'
import { users } from './users'
import { bookings } from './bookings'

const _schema = new schema.Entity('offlinereviews', {
  booking: bookings.schema,
  guest: users.schema,
  host: users.schema,
})

const _Record = Record({
  id: undefined,
  booking: new bookings.Record(),
  date: '',
  firstname: '',
  guest: new users.Record(),
  host: new users.Record(),
  score: undefined,

  created_at: undefined,
  updated_at: undefined,

  ewid: undefined,
})

export const offlinereviews = {
  schema: _schema,
  Record: _Record,
}
