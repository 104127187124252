import loadScript from 'load-script'
import { get } from 'lodash'

export default class LibLoader {
  constructor (name, url, objectPath) {
    this.url = url
    this.objectPath = objectPath
    this.loading = false
    this[name] = null
  }

  get (prop) {
    return this[prop]
  }

  init () {
    if (!this.initPromise) {
      this.initPromise = new Promise((resolve, reject) => {
        if (this[this.name]) return resolve(this[this.name])

        return loadScript(this.url, (err) => {
          if (err) return reject(err)
          this[this.name] = get(window, this.objectPath)
          resolve(this[this.name])
          this.initPromise = null
        })
      })
    }

    return this.initPromise
  }
}
