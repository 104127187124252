// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'helpers/proptypes'
import { withTranslation } from 'react-i18next'

// Components
import { RequestForm, ScheduleEventForm, InvoiceBookingForm } from '../privateEventForm'
import { FormModal } from 'components/modals'
import { Form, Button, Select } from 'semantic-ui-react'
import { SearchInput, SelectInput } from 'components/inputs'
// Redux
import { getLocation } from 'redux/reducers/router'
import { getOrganizationsList, getSortedTags } from 'redux/entities/selectors'
import { fetchSchedules, fetchOrganizations } from 'redux/entities/actions'
// Helpers
import { getTranslatedOptions } from 'helpers/options'
import { mealTypesOptions } from 'helpers/events'
import { konstants } from '@vizeat/helpers'
import './SearchForm.css'

const { SORTED_CITIES } = konstants

const mapStateToProps = (state) => ({
  fromStore: {
    location: getLocation(state),
    organizations: getOrganizationsList(state),
    sortedTags: getSortedTags(state),
  },
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    fetchSchedules: ({ city, start, hostname, organizationIds, end, tags, types }) =>
      dispatch(fetchSchedules({ city, start, hostname, organizationIds, end, tags, types })),
    loadOrganizations: () => dispatch(fetchOrganizations({ query: { all: true } })),
  },
})

const getParamsFromLocation = (props) => props.fromStore.location.get('query').toObject()

export class _CalendarSearchForm extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fromStore: PropTypes.shape({
      location: PropTypes.immutable.map.isRequired,
      organizations: PropTypes.immutable.list,
      sortedTags: PropTypes.immutable.list,
    }).isRequired,
    actions: PropTypes.shape({
      fetchSchedules: PropTypes.func.isRequired,
      loadOrganizations: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    organizations: undefined,
    sortedTags: undefined,
  }

  static contextTypes = { router: PropTypes.object }

  state = {
    city: undefined,
    hostname: '',
    statuses: [],
    types: [],
    organizations_ids: [],
    tags: [],
    isInvoiceModalVisible: false,
    isRequestModalVisible: false,
    isScheduleModalVisible: false,
  }

  UNSAFE_componentWillMount() {
    const {
      actions: { loadOrganizations },
      fromStore: { location },
    } = this.props
    loadOrganizations()
    this.updateStateFromLocation(location)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.shouldComponentUpdate(nextProps) && this.updateStateFromLocation(nextProps.fromStore.location)
  }

  // Update only if city or filters changed
  shouldComponentUpdate(nextProps, nextState) {
    const {
      fromStore: { location },
    } = this.props
    return (
      nextState !== this.state ||
      nextProps.fromStore.location.getIn(['query', 'city']) !== location.getIn(['query', 'city']) ||
      nextProps.fromStore.location.getIn(['query', 'hostname']) !== location.getIn(['query', 'hostname']) ||
      nextProps.fromStore.location.getIn(['query', 'statuses']) !== location.getIn(['query', 'statuses']) ||
      nextProps.fromStore.location.getIn(['query', 'types']) !== location.getIn(['query', 'types']) ||
      nextProps.fromStore.location.getIn(['query', 'tags']) !== location.getIn(['query', 'tags'])
    )
  }

  updateStateFromLocation(location) {
    this.setState({
      city: location.getIn(['query', 'city']),
      hostname: location.getIn(['query', 'hostname'], ''),
      statuses: this.urlParam2array(location.getIn(['query', 'statuses'], [])),
      types: this.urlParam2array(location.getIn(['query', 'types'], [])),
      organizationIds: this.urlParam2array(location.getIn(['query', 'organizationIds'], [])),
      tags: this.urlParam2array(location.getIn(['query', 'tags'], [])),
    })
  }

  urlParam2array = (values) => {
    return typeof values === 'string' ? values.split(',').filter((value) => value.trim() !== '') : values
  }

  updateQuery(query = { ...this.state }) {
    if (query.statuses && query.statuses.length > 0) query.statuses = query.statuses.join(',')
    if (query.types && query.types.length > 0) query.types = query.types.join(',')
    if (query.organizationIds && query.organizationIds.length > 0) {
      query.organizationIds = query.organizationIds.join(',')
    }
    if (query.tags && query.tags.length > 0) query.tags = query.tags.join(',')
    this.context.router.push(this.props.fromStore.location.mergeIn(['query'], query).toJS())
  }

  formatOrganizationsOptions = () =>
    this.props.fromStore.organizations.map((org) => ({ text: org.name, value: org.id.toString() }))

  handleFilterChange =
    (name) =>
    (e, { value }) =>
      this.setState({ [name]: value })

  clearFilters() {
    return this.setState(
      {
        city: undefined,
        hostname: '',
        statuses: [],
        types: [],
        organizationIds: [],
        tags: [],
      },
      () => this.updateQuery(),
    )
  }

  handleSubmit = (e) => {
    e.preventDefault()
    return this.updateQuery()
  }

  closeModal = (modalType) => () => {
    const { city, start, end, hostname, organizationIds, tags, types } = getParamsFromLocation(this.props)

    switch (modalType) {
      case 'schedule':
        this.setState({ isScheduleModalVisible: false })
        break
      case 'request':
        this.setState({ isRequestModalVisible: false })
        break
      case 'invoice':
        this.setState({ isInvoiceModalVisible: false })
        break
    }

    if (city) {
      this.props.actions.fetchSchedules({ city, start, end, hostname, organizationIds, tags, types })
    }
  }

  render() {
    const {
      city,
      hostname,
      statuses,
      types,
      organizationIds,
      tags,
      isScheduleModalVisible,
      isInvoiceModalVisible,
      isRequestModalVisible,
    } = this.state
    const {
      t,
      fromStore: { sortedTags },
    } = this.props
    return (
      <div>
        <div className='__add-private'>
          <FormModal
            openModal={() => this.setState({ isScheduleModalVisible: true })}
            closeModal={this.closeModal('schedule')}
            isOpen={isScheduleModalVisible}
            btnTitle={t('EventsCalendar::Schedule an event')}
            headerTitle={t('EventsCalendar::Schedule an event')}
          >
            <ScheduleEventForm
              handleSubmit={this.closeModal('schedule')}
              btnSubmitTitle={t('EventsCalendar::Schedule an event')}
            />
          </FormModal>

          <FormModal
            openModal={() => this.setState({ isInvoiceModalVisible: true })}
            closeModal={this.closeModal('invoice')}
            isOpen={isInvoiceModalVisible}
            btnTitle={t('EventsCalendar::PE Invoice')}
            headerTitle={t('EventsCalendar::Create a new private event paid by invoice')}
          >
            <InvoiceBookingForm
              handleSubmit={this.closeModal('invoice')}
              btnSubmitTitle={t('EventsCalendar::Create Private Event paid by invoice')}
            />
          </FormModal>

          <FormModal
            openModal={() => this.setState({ isRequestModalVisible: true })}
            closeModal={this.closeModal('request')}
            isOpen={isRequestModalVisible}
            btnTitle={t('EventsCalendar::PE Request')}
            headerTitle={t('EventsCalendar::Create a new private event request')}
          >
            <RequestForm
              onSubmit={this.closeModal('request')}
              btnSubmitTitle={t('EventsCalendar::Create Private Event Request')}
            />
          </FormModal>
        </div>

        <Form onSubmit={this.handleSubmit} className='searchForm' size='small' autoComplete='off'>
          <Form.Group>
            <Form.Field>
              <label>{t('EventsCalendar::City')}</label>
              <SelectInput
                search
                queryName='city'
                placeholder={t('EventsCalendar::Choose a city ...')}
                options={[
                  { text: '', value: '' },
                  { text: t('EventsCalendar::Rest of the world'), value: 'ROW' },
                ].concat(SORTED_CITIES)}
                value={city}
                onChange={(city) => this.setState({ city })}
              />
            </Form.Field>

            <Form.Field>
              <label>{t('EventsCalendar::Host')}</label>
              <SearchInput
                queryName='hostname'
                placeholder={t('EventsCalendar::first/last name')}
                icon='search'
                iconPosition='left'
                value={hostname}
                onChange={(hostname) => this.setState({ hostname })}
              />
            </Form.Field>

            <Form.Field>
              <label>{t('EventsCalendar::Status(es)')}</label>
              <Select
                multiple
                placeholder={t('Inputs::Select...')}
                options={[
                  { text: t('EventsCalendar::Private events'), value: 'private' },
                  { text: t('EventsCalendar::Public events'), value: 'public' },
                  { text: t('EventsCalendar::Demo events'), value: 'demo' },
                  { text: t('EventsCalendar::Events with instant booking'), value: 'instant' },
                  { text: t('EventsCalendar::Fully booked events'), value: 'full' },
                  { text: t('EventsCalendar::Above the min guests but still has space'), value: 'abovemin' },
                  { text: t('EventsCalendar::Below the min guests but has bookings'), value: 'belowmin' },
                  { text: t('EventsCalendar::Events with no booking'), value: 'nobooking' },
                ]}
                value={statuses}
                onChange={(e, { value }) => this.setState({ statuses: value })}
              />
            </Form.Field>

            <Form.Field>
              <label>{t('EventsCalendar::Event type(s)')}</label>
              <Select
                multiple
                placeholder={t('Inputs::Select...')}
                options={getTranslatedOptions(t, mealTypesOptions)}
                value={types}
                onChange={(e, { value }) => this.setState({ types: value })}
              />
            </Form.Field>

            <Form.Field>
              <label>{t('EventsCalendar::Organizations')}</label>
              <Select
                multiple
                placeholder={t('Inputs::Select...')}
                options={this.formatOrganizationsOptions().toArray()}
                value={organizationIds}
                onChange={(e, { value }) => this.setState({ organizationIds: value })}
              />
            </Form.Field>

            <Form.Field>
              <label>{t('EventsCalendar::Tags')}</label>
              <Select
                multiple
                placeholder={t('Inputs::Select...')}
                options={sortedTags.toArray().map((tag) => ({ text: tag.title, value: tag.title }))}
                value={tags}
                onChange={(e, { value }) => this.setState({ tags: value })}
                search
              />
            </Form.Field>

            <Form.Field className='buttons'>
              <Button type='submit'>{t('EventsCalendar::Search')}</Button>
              <Button basic onClick={() => this.clearFilters()}>
                {t('EventsCalendar::Clear filters')}
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
    )
  }
}

export const CalendarSearchForm = withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(_CalendarSearchForm),
)
