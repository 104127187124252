// Libs
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { omit } from 'lodash'
// Components
import { Table, Icon } from 'semantic-ui-react'
// Style
import './List.css'

/**
 * Renders a List that can be sortable. If it is:
 * - The class takes the headers, if a key 'field' is given, then the header is
 *  considered sortable.
 * - An immutable location (from redux store) must be provided since the component
 *  state is in the url: pathname, query: { sortBy, order }
 */
export default class List extends PureComponent {
  static propTypes = {
    headers: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.any.isRequired,
        field: PropTypes.string, // if sortable=true, field = field to sort by with. If no field, header is not sortable
      })
    ).isRequired,
    location: PropTypes.object, // immutable
    children: PropTypes.any.isRequired,
    sortable: PropTypes.bool,
  }

  static contextTypes = { router: PropTypes.object }

  handleSortBy (field) {
    const { pathname, query } = this.props.location.toJS()
    const active = field === query.sortBy
    const nextQuery = !active ? { sortBy: field, order: 'asc' } : { order: query.order === 'asc' ? 'desc' : 'asc' }

    this.context.router.push({
      pathname,
      query: { ...query, ...nextQuery },
    })
  }

  SortableHeader = this.SortableHeader.bind(this)
  SortableHeader ({ header }) {
    const active = header.field === this.props.location.getIn(['query', 'sortBy'], null)
    const order = this.props.location.getIn(['query', 'order'], '').toLowerCase() === 'asc' ? 'up' : 'down'
    const sortable = !!header.field

    return (
      <Table.HeaderCell
        onClick={sortable ? () => this.handleSortBy(header.field) : null}
        style={sortable ? { cursor: 'pointer' } : null}
      >
        {header.label}
        {active && <Icon name={`caret ${order}`} />}
      </Table.HeaderCell>
    )
  }

  render () {
    const nativeProps = omit(this.props, Object.keys(List.propTypes))

    return (
      <Table {...nativeProps}>
        {this.props.headers && this.props.headers.length > 0 && (
          <Table.Header>
            <Table.Row>
              {this.props.headers.map((header, i) =>
                this.props.sortable ? (
                  <this.SortableHeader key={i} header={header} />
                ) : (
                  <Table.HeaderCell key={i}>{header.label}</Table.HeaderCell>
                )
              )}
            </Table.Row>
          </Table.Header>
        )}
        <Table.Body>{this.props.children}</Table.Body>
      </Table>
    )
  }
}
