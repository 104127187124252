import settings from 'settings'
import moment from 'moment'
import cookie from 'react-cookie'

export function getCookieOpts({ duration = 24 * 30 * 3600, domain = settings.cookieDomainName, path = '/' }) {
  return {
    path,
    domain,
    maxAge: duration,
    expires: moment().add(duration, 'seconds').toDate(),
  }
}

class Cookie {
  constructor({ name, options = {} }) {
    this.name = name
    this.options = getCookieOpts(options)
  }

  save(value) {
    return cookie.save(this.name, value, this.options)
  }

  load() {
    return cookie.load(this.name, this.options)
  }

  remove(opts = {}) {
    return cookie.remove(this.name, { ...this.options, ...opts })
  }

  get value() {
    return this.load()
  }
}

const webappAuthToken = new Cookie({
  name: 'token',
  options: { duration: 3600 },
})

const partnerAuthToken = new Cookie({
  name: 'partner_token',
  options: { duration: 3600 },
})

const kitchenAuthToken = new Cookie({
  name: 'kitchen_token',
  options: { domain: settings.cookieDomainName ? `kitchen${settings.cookieDomainName}` : undefined },
})

const vizeatAdmin = new Cookie({
  name: 'vizeat-admin',
  options: { duration: 3600 * 24 }, // 1 day
})

export default {
  webappAuthToken,
  kitchenAuthToken,
  partnerAuthToken,
  vizeatAdmin,
}
