import { schema } from 'normalizr'
import { Record } from 'immutable'

import { accounts } from './accounts'
import { users } from './users'

const _schema = new schema.Entity('admins', {
  account: accounts.schema,
  user: users.schema,
})

const _Record = Record({
  id: undefined,

  fullname: undefined,
  role: undefined,
  created_at: undefined,
  updated_at: undefined,
  deleted_at: undefined,
  account: new accounts.Record(),
  account_id: undefined,
  user: new users.Record(),
})

export const admins = {
  schema: _schema,
  Record: _Record,
}
