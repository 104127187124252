import React, { useMemo, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Badge } from '@vizeat/components/es6/components/Badge'
import { Box } from '@vizeat/components/es6/components/Box'
import { Grid } from '@vizeat/components/es6/components/Grid'
import {
  IconCasualDinner,
  IconChefsTable,
  IconCommunalTable,
  IconCulturalImmersion,
  IconDinnerParty,
  IconHomeCook,
  IconIndividualTable,
  IconOutdoors,
  IconPrivateHouse,
  IconPrivateSpace,
  IconProfessionalChef,
  IconPublicVenue,
} from '@vizeat/components/es6/assets/icons'
import { SectionWrapper, StyledTitle } from './SectionWrapper'
import { DeletableBadge } from 'components/badges/DeletableBadge'
import PropTypes from 'helpers/proptypes'
import { useMultiFiltersToggle } from './hooks/useMultiFiltersToggle'

const DISCOVERABILITY = {
  venue: {
    title: __('Playlist::Summary::Venue'),
    items: {
      'venue-outdoor': {
        title: __('Playlist::Summary::Outdoors'),
        Icon: IconOutdoors,
      },
      'venue-public-venue': {
        title: __('Playlist::Summary::An eatwith table in a public venue'),
        Icon: IconPublicVenue,
      },
      'venue-private-venue': {
        title: __('Playlist::Summary::A private space only for eatwith'),
        Icon: IconPrivateSpace,
      },
      'venue-private-house': {
        title: __('Playlist::Summary::Private house'),
        Icon: IconPrivateHouse,
      },
    },
  },
  setting: {
    title: __('Playlist::Summary::Setting'),
    items: {
      'table-type-communal': {
        title: __('Playlist::Summary::Communal table'),
        Icon: IconCommunalTable,
      },
      'table-type-separated': {
        title: __('Playlist::Summary::Individual tables'),
        Icon: IconIndividualTable,
      },
    },
  },
  ambiance: {
    title: __('Playlist::Summary::Ambiance'),
    items: {
      'social-setting-casual-dinner': {
        title: __('Playlist::Summary::Casual dinner'),
        Icon: IconCasualDinner,
      },
      'social-setting-chef-table': {
        title: __("Playlist::Summary::Chef's table"),
        Icon: IconChefsTable,
      },
      'social-setting-cultural-immersion': {
        title: __('Playlist::Summary::Cultural immersion'),
        Icon: IconCulturalImmersion,
      },
      'social-setting-dinner-party': {
        title: __('Playlist::Summary::Dinner party'),
        Icon: IconDinnerParty,
      },
    },
  },
  hostExperience: {
    title: __('Playlist::Summary::Host Experience'),
    items: {
      'home-cook-host': {
        title: __('Playlist::Summary::Home cook'),
        Icon: IconHomeCook,
      },
      'professional-chef': {
        title: __('Playlist::Summary::Professional chef'),
        Icon: IconProfessionalChef,
      },
    },
  },
}

const StyledBadge = styled((props) => <Badge as='li' role='button' tabIndex={0} {...props} />)`
  cursor: pointer;
  border: ${({ theme, isSelected }) =>
    `${theme.borders.sm} ${isSelected ? theme.colors.eatwithOrange : theme.colors.lightGray}`};
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme, isSelected }) => (isSelected ? theme.colors.eatwithOrange : null)};
  border-radius: ${({ theme }) => theme.radii.md};
  padding: 8px;
  word-break: break-word;
  min-height: 70px;
`

export function Discoverability({ onChange, showSelectedFilters, selectedValues }) {
  const { t } = useTranslation()

  const handleChange = useMultiFiltersToggle(selectedValues, { onChange, filterName: 'tags' })

  const selectedDiscoverabilities = useMemo(
    () =>
      Object.values(DISCOVERABILITY).reduce(
        (acc, { items }) =>
          acc.concat(
            Object.keys(items)
              .filter((tag) => selectedValues.includes(tag))
              .map((tag) => ({ tag, title: items[tag].title })),
          ),
        [],
      ),
    [selectedValues],
  )

  if (showSelectedFilters) {
    return (
      <Fragment>
        {selectedDiscoverabilities.map(({ tag, title }) => (
          <DeletableBadge key={`discoverability-${tag}`} color='mediumGray' onClick={() => handleChange(null, tag)}>
            {t(title)}
          </DeletableBadge>
        ))}
      </Fragment>
    )
  }

  return (
    <SectionWrapper>
      {Object.entries(DISCOVERABILITY).map(([section, { title, items }]) => {
        return (
          <Box gap='12px' mb='24px' key={section}>
            <StyledTitle>{t(title)}</StyledTitle>
            <Grid templateColumns={{ default: 'repeat(2, 1fr)', tablet: 'repeat(4, 1fr)' }} gap='8px' forwardedAs='ul'>
              {Object.entries(items).map(([tag, { title, Icon }]) => {
                const isSelected = selectedValues.includes(tag)
                return (
                  <StyledBadge
                    key={tag}
                    onClick={() => {
                      handleChange(null, tag)
                    }}
                    isSelected={isSelected}
                  >
                    <Icon size='24px' mr='12px' />
                    {t(title)}
                  </StyledBadge>
                )
              })}
            </Grid>
          </Box>
        )
      })}
    </SectionWrapper>
  )
}

Discoverability.propTypes = {
  onChange: PropTypes.func.isRequired,
  showSelectedFilters: PropTypes.bool,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
}

Discoverability.defaultProps = {
  showSelectedFilters: false,
  selectedValues: [],
}
