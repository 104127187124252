import settings from 'settings'
// libs
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Uploadcare } from 'components/libs'
import { omit } from 'lodash'
import i18n from '../../i18n-client'
// components
import { Button } from 'semantic-ui-react'
// redux
import { createFiles } from 'redux/entities/actions'
import { creatingFiles } from 'redux/entities/selectors'
const mapStateToProps = (state, props) => ({
  fromStore: { loading: creatingFiles(state, props.id) },
})
const mapDispatchToProps = (dispatch, { model, id }) => ({
  actions: { uploadFiles: (data) => dispatch(createFiles({ model, id }, data)) },
})

const uploadcareTabs = [
  'file',
  'camera',
  'url',
  'facebook',
  'gdrive',
  'dropbox',
  'instagram',
  // 'evernote',
  // 'flickr',
  // 'skydrive',
  // 'box',
  // 'vk',
  // 'huddle'
]

// TODO: Rebuild from scratch this component to allow the file storage only when the attached entity id is provided
class _FileUploader extends PureComponent {
  static propTypes = {
    fromStore: PropTypes.shape({ loading: PropTypes.bool }).isRequired,
    actions: PropTypes.object.isRequired,

    model: PropTypes.string.isRequired, // users, events, playlists or gallery
    id: PropTypes.number, // userId or eventId or reviewId
    children: PropTypes.any.isRequired,
    avatar: PropTypes.bool, // For model 'users' only
    cover: PropTypes.bool, // For models 'events' and 'playlists' only
    multiple: PropTypes.bool,
    onUploaded: PropTypes.func,
    afterUpload: PropTypes.func,
    category: PropTypes.string,
  }

  static defaultProps = {
    id: undefined,
    multiple: true,
    avatar: false,
    cover: false,
  }

  UNSAFE_componentWillMount() {
    this.setState({ disabled: true })
    this.Uploadcare = null
  }

  componentDidMount() {
    Uploadcare.init().then((uploadcare) => {
      this.Uploadcare = uploadcare
      this.setState({ disabled: false })
    })
  }

  handleOpenDialog = this.handleOpenDialog.bind(this)
  handleOpenDialog(e) {
    e.preventDefault()
    const { multiple } = this.props
    const dialog = this.Uploadcare.openDialog(null, {
      publicKey: settings.uploadCareKey,
      locale: i18n.language,
      imagesOnly: true,
      tabs: uploadcareTabs.join(' '),
      multiple,
    })

    const done = dialog.done(
      !multiple ? this.handleSingleFileUpload.bind(this) : this.handleMultipleFileUpload.bind(this),
    )
    if (!multiple) done.fail(this.handleFileFail.bind(this))
  }

  async handleSingleFileUpload(file) {
    const { category, id } = this.props
    file.done(async (fileInfo) => {
      this.props.onUploaded?.(fileInfo, category)
      if (id) {
        const uploadedFile = await this.props.actions.uploadFiles(
          Object.assign(
            { [category || 'sources']: [fileInfo.uuid] },
            this.props.avatar && { avatar: fileInfo.uuid },
            this.props.cover && { cover: fileInfo.uuid },
          ),
        )
        this.props.afterUpload?.({ file: uploadedFile, fileInfo })
      } else {
        this.props.afterUpload?.({ file: undefined, fileInfo })
      }
      return file
    })
  }

  async handleMultipleFileUpload(result) {
    const filesInfo = []

    result.files().forEach((file) => {
      file.done((fileInfo) => filesInfo.push(fileInfo)).fail(this.handlFileFail)
    })
    const { category } = this.props
    const files = await this.props.actions.uploadFiles({
      [category || 'sources']: filesInfo.map((f) => f.uuid),
    })
    if (this.props.onUploaded) this.props.onUploaded(filesInfo, category)
    if (this.props.afterUpload) this.props.afterUpload({ files, filesInfo })
  }

  handleFileFail(error, fileInfo) {
    this.setState({
      errorMessage: `Error on ${fileInfo.name}: ${error}`,
    })
  }

  render() {
    const props = omit(this.props, Object.keys(_FileUploader.propTypes))
    const {
      props: {
        fromStore: { loading },
      },
      state: { disabled },
    } = this
    return (
      <Button loading={disabled || loading} disabled={disabled} onClick={this.handleOpenDialog} {...props}>
        {this.props.children}
      </Button>
    )
  }
}

export const FileUploader = connect(mapStateToProps, mapDispatchToProps)(_FileUploader)
