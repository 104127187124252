// Libs
import React, { Fragment } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
// Components
import { Link } from 'react-router'
import { Grid, Segment } from 'semantic-ui-react'
import { SeatsFormWrapper } from 'components/forms/seats'
// Redux
import { getPartnerDemandBookings } from 'redux/entities/selectors'
// Helpers
import { numbers } from '@vizeat/helpers'

const { formatIntegerPrice } = numbers

const mapStateToProps = (state, props) => ({
  fromStore: {
    bookings: getPartnerDemandBookings(state, { id: props.demand.id }),
  },
})

function _DemandBookings({ fromStore: { bookings } }) {
  const [t] = useTranslation()

  if (bookings.size === 0) return null
  return (
    <Segment>
      <Grid>
        {bookings.map((booking) => (
          <Fragment key={booking}>
            <Grid.Row width={16}>
              <Grid.Column width={4}>
                <label>{t('PartnerDemands::Summary::ID:')}</label>{' '}
                <Link to={`/bookings?search=${booking.id}`}>{booking.id}</Link>
              </Grid.Column>
              <Grid.Column width={4}>
                <label>{t('PartnerDemands::Summary::Event:')}</label>{' '}
                <Link to={`/events/${booking.event_id}`}>{`${booking.event_id} - ${booking.event.title}`}</Link>
              </Grid.Column>
              <Grid.Column width={4}>
                <label>{t('PartnerDemands::Summary::Host:')}</label>{' '}
                <Link to={`/users/${booking.event.user_id}`}>
                  {`${booking.event.user_id} - ${booking.event.user.firstname} ${booking.event.user.lastname}`}
                </Link>
              </Grid.Column>
              <Grid.Column width={4}>
                <label>{t('PartnerDemands::Summary::Guest:')}</label>{' '}
                <Link to={`/users/${booking.user_id}`}>
                  {`${booking.user_id} - ${booking.user.firstname} ${booking.user.lastname}`}
                </Link>
              </Grid.Column>
              {booking.payment && (
                <Fragment>
                  <Grid.Column width={4}>
                    <label>
                      {t('PartnerDemands::Summary::Host Price: {{price}}', {
                        price: formatIntegerPrice(
                          booking.payment.get('event_price'),
                          'en',
                          booking.payment.get('event_currency'),
                        ),
                      })}
                    </label>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <label>
                      {t('PartnerDemands::Summary::Total paid:: {{price}}', {
                        price: formatIntegerPrice(
                          booking.payment.get('total_paid'),
                          'en',
                          booking.payment.get('user_currency'),
                        ),
                      })}
                    </label>
                  </Grid.Column>
                </Fragment>
              )}
            </Grid.Row>
            <Grid.Row width={16}>
              <Segment basic>
                <SeatsFormWrapper booking={booking} />
              </Segment>
            </Grid.Row>
            <hr />
          </Fragment>
        ))}
      </Grid>
    </Segment>
  )
}

_DemandBookings.propTypes = {
  fromStore: PropTypes.shape({
    bookings: PropTypes.immutable.list.isRequired,
  }).isRequired,
  demand: PropTypes.immutable.record.isRequired,
}

export const DemandBookings = connect(mapStateToProps)(_DemandBookings)
