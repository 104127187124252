import { pick } from 'lodash'
import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const filesActions = new ActionsCreator({
  Api: businessApi,
  actionTypesPrefix: 'files',
  defaultSchema: { files: [schemas.files] },
})

export const fetchFiles = ({ query } = {}) =>
  filesActions.fetch({
    url: '/api/files',
    query,
    schema: { files: [schemas.files] },
  })

export const fetchEventFiles = (id, { query } = {}) =>
  filesActions.fetch({
    url: `/api/events/${id}/files`,
    query,
    schema: { files: [schemas.files] },
  })

export const updateEventFiles = (id, payload) =>
  filesActions.update({
    url: `/api/events/${id}/files`,
    payload: pick(payload, ['cover', 'sources', 'foods', 'venues']),
    schema: { cover: schemas.files, files: [schemas.files] },
  })

export const fetchUserFiles = (id, { query } = {}) =>
  filesActions.fetch({
    url: `/api/users/${id}/files`,
    query,
    schema: { files: [schemas.files] },
  })

export const createUserFiles = (id, { sources, avatar }, { query } = {}) =>
  filesActions.create({
    url: `/api/users/${id}/files`,
    payload: { sources, avatar },
    query,
    schema: { files: [schemas.files], avatar: schemas.files },
  })

export const createEventFiles = (id, { sources, foods, venues, cover }, { query } = {}) =>
  filesActions.create({
    url: `/api/events/${id}/files`,
    payload: { sources, foods, venues, cover },
    query,
    schema: { files: [schemas.files], cover: schemas.files },
  })

export const createPlaylistFiles = (id, { cover }, { query } = {}) =>
  filesActions.create({
    url: `/api/playlists/${id}/files`,
    payload: { cover, sources: [cover] },
    query,
    schema: { cover: schemas.files },
  })

export const createGalleryFiles = (payload) =>
  filesActions.create({
    url: '/api/files',
    payload,
    schema: { files: [schemas.files] },
  })

export const createFiles = ({ model, id }, payload) => {
  switch (model) {
    case 'users':
      return createUserFiles(id, payload)
    case 'events':
      return createEventFiles(id, payload)
    case 'playlists':
      return createPlaylistFiles(id, payload)
    case 'gallery':
      return createGalleryFiles(payload)
    default:
      throw new Error("model must be one of ['users', 'events', 'playlists', 'gallery']")
  }
}

export const deleteFile = (id) =>
  filesActions.delete({
    url: `/api/files/${id}`,
    defaultSchema: { files: [schemas.files] },
  })
