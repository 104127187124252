import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Map } from 'immutable'
// component
import { Form } from 'semantic-ui-react'
// helpers
import { buildSelectOptions, buildOptionsWithArray } from 'helpers/forms'
import { hostTypologyOptions } from 'helpers/users'
// redux
import { createForm, removeForm, mergeInForm } from 'redux/forms/actions'
import { getForm } from 'redux/forms/reducer'
import { updateUser } from 'redux/entities/actions'
import { getUser, updatingUser, getUserError, getSortedLanguages, getSortedTags } from 'redux/entities/selectors'
import { UserDescriptionsInput } from '../../users/id/UserDescriptionInput'

const mapStateToProps = (state, props) => ({
  fromStore: {
    user: getUser(state, { id: props.userId }),
    form: getForm(state, 'user'),
    updating: updatingUser(state, props.userId),
    languages: getSortedLanguages(state),
    tags: getSortedTags(state),
    error: getUserError(state),
  },
})

const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    createForm: ({ initialState }) => dispatch(createForm({ formName: 'user', initialState })),
    removeForm: () => dispatch(removeForm({ formName: 'user' })),
    mergeInForm: (payload) => dispatch(mergeInForm({ formName: 'user', value: payload })),
    updateUser: (payload) => dispatch(updateUser(props.userId, payload)),
  },
})

class _EventIdUserForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    fromStore: PropTypes.shape({
      updating: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
      user: PropTypes.object,
      form: PropTypes.object,
      languages: PropTypes.object, // immutable array
      tags: PropTypes.object, // immutable array
      error: PropTypes.bool,
    }).isRequired,
    actions: PropTypes.object,
  }

  UNSAFE_componentWillMount() {
    const {
      fromStore: { user },
      actions,
    } = this.props
    actions.createForm({ initialState: this.userEntityToUserForm(user) })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      fromStore: { user },
      actions,
    } = nextProps
    if (user.size && (!this.props.fromStore.user.size || !user.equals(this.props.fromStore.user))) {
      actions.mergeInForm(this.userEntityToUserForm(user))
    }
  }

  componentWillUnmount() {
    this.props.actions.removeForm()
  }

  userEntityToUserForm = (user = {}) => ({
    descriptions: user.descriptions,
    languages_ids: (user.languages || []).map((l) => l.id),
    tags: user.tags || [],
    properties: user.properties,
  })

  initFormWithUser(props) {
    props.actions.fillFormFromItem(props.user)
  }

  handleSubmit = (e) => {
    if (e) e.preventDefault()
    return this.props.actions.updateUser(this.props.fromStore.form.toJS()).then(() => {
      if (this.props.fromStore.error) this.setState({ error: this.props.fromStore.error })
    })
  }

  render() {
    const {
      t,
      fromStore: { user, updating, form, tags, languages },
      actions,
    } = this.props
    if (!form || !form.size) return null
    return (
      <Form onSubmit={this.handleSubmit} loading={!!updating}>
        <Form.Dropdown
          label={t('Experiences::Summary::User tags')}
          value={form.get('tags').toJS()}
          onChange={(e, { value }) => actions.mergeInForm({ tags: value })}
          multiple
          selection
          fluid
          search
          options={buildOptionsWithArray(tags.map((t) => t.title))}
        />

        <Form.Dropdown
          label={t('Experiences::Summary::Spoken Languages')}
          value={form.get('languages_ids').toJS()}
          onChange={(e, { value }) => actions.mergeInForm({ languages_ids: value })}
          multiple
          selection
          fluid
          search
          options={buildSelectOptions(languages)}
        />

        <Form.Select
          label={t('Experiences::Summary::Host Typology')}
          fluid
          options={hostTypologyOptions}
          value={form
            .get('properties', new Map())
            .filter((property) => property.get('key') === 'host_typology')
            .getIn(['0', 'value'], '')}
          onChange={(e, { value }) =>
            actions.mergeInForm({
              properties: [
                ...user.properties.toJS().filter((property) => property.key !== 'host_typology'),
                { key: 'host_typology', value, zone: 'private' },
              ],
            })
          }
        />
        <UserDescriptionsInput
          hostLanguage={user.account.language}
          updateForm={(formDescriptions) => actions.mergeInForm({ descriptions: formDescriptions.toList() })}
          formDescriptions={form
            .get('descriptions')
            .reduce((acc, cur) => acc.set(String(cur.get('language_id')), Map(cur)), Map())}
        />

        <Form.Field style={{ textAlign: 'center' }}>
          <Form.Button type='submit'>{t('Experiences::Summary::Save')}</Form.Button>
        </Form.Field>
      </Form>
    )
  }
}

export const EventIdUserForm = withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(_EventIdUserForm))
