// Libs
import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import moment from 'moment'
import { withTranslation, Trans } from 'react-i18next'
// Components
import { Link } from 'react-router'
import { Grid, Segment } from 'semantic-ui-react'
// Redux
import { fetchConversation } from 'redux/entities/actions'
import { getConversation } from 'redux/entities/selectors'

import { FormattedReplies } from '../../../components/conversations'

const mapStateToProps = (state, props) => ({
  conversation: props.id && getConversation(state, { id: props.id }),
})

const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    loadConversation: (id) => dispatch(fetchConversation(id)),
  },
})

class _Conversation extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    conversation: PropTypes.immutable.record.isRequired,
    replies: PropTypes.immutable.list,
    actions: PropTypes.shape({
      loadConversation: PropTypes.func,
    }).isRequired,
  }

  static defaultProps = {
    replies: undefined,
  }

  componentDidMount() {
    const { actions } = this.props
    if (this.props.id) {
      actions.loadConversation(this.props.id)
    }
  }

  render() {
    const { t, conversation } = this.props
    return (
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <label>
                <Trans
                  i18nKey={__(
                    'Requests::Summary::Conversations::ID: <conversationURL>{{conversationId}}</conversationURL>',
                  )}
                  values={{
                    conversationId: conversation.id,
                  }}
                  components={{
                    conversationURL: <Link to={`/conversations?search=${conversation.id}`} />,
                  }}
                />
              </label>
            </Grid.Column>

            <Grid.Column width={4}>
              <label>
                {t('Requests::Summary::Conversations::Created at: {{createdAt}}', {
                  createdAt: moment(conversation.created_at).format('lll'),
                })}
              </label>
            </Grid.Column>

            <Grid.Column width={4}>
              <label>
                <Trans
                  i18nKey={__(
                    'Requests::Summary::Conversations::Sender: <senderURL>{{senderId}} - {{senderFirstName}} {{senderLastName}}</senderURL>',
                  )}
                  values={{
                    senderId: conversation.sender.id,
                    senderFirstName: conversation.sender.firstname,
                    senderLastName: conversation.sender.lastname,
                  }}
                  components={{
                    senderURL: <Link to={`/users?search=${conversation.sender.id}`} />,
                  }}
                />
              </label>
            </Grid.Column>

            <Grid.Column width={4}>
              <label>
                <Trans
                  i18nKey={__(
                    'Requests::Summary::Conversations::Recipient: <recipientURL>{{recipientId}} - {{recipientFirstName}} {{recipientLastName}}</recipientURL>',
                  )}
                  values={{
                    recipientId: conversation.recipient.id,
                    recipientFirstName: conversation.recipient.firstname,
                    recipientLastName: conversation.recipient.lastname,
                  }}
                  components={{
                    recipientURL: <Link to={`/users?search=${conversation.recipient.id}`} />,
                  }}
                />
              </label>
            </Grid.Column>
          </Grid.Row>

          <FormattedReplies
            replies={conversation.replies}
            sender={conversation.sender}
            recipient={conversation.recipient}
          />
        </Grid>
      </Segment>
    )
  }
}

export const Conversation = withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(_Conversation))
