import moment from 'moment'
import { deadlines } from '@vizeat/helpers'

const { isCancellationDeadlineNonRefundable } = deadlines
const hostDecline = { text: __('Bookings::StatusOptions::Host declines'), value: 'host_declines' }
const hostCancel = { text: __('Bookings::StatusOptions::Host cancels'), value: 'host_cancels' }
const guestCancel = { text: __('Bookings::StatusOptions::Guest cancels'), value: 'guest_cancels' }
const weCancel = { text: __('Bookings::StatusOptions::We cancel'), value: 'we_cancel' }
const validate = { text: __('Bookings::StatusOptions::Validate'), value: 'validate' }
const payHost = { text: __('Bookings::StatusOptions::Pay host'), value: 'pay_host' }
const refund = { text: __('Bookings::StatusOptions::Refund'), value: 'refund' }
const fraud = { text: __('Bookings::StatusOptions::Fraud'), value: 'fraud' }
const litigate = { text: __('Bookings::StatusOptions::Litigate'), value: 'litigate' }

const statuses = {
  // standard workflow
  booking_request: [hostDecline, guestCancel, weCancel, fraud, litigate],
  schedule: [hostDecline, guestCancel, weCancel, fraud, litigate],
  tentative: [validate, hostDecline, guestCancel, weCancel, fraud, litigate],
  validated_by_host: [hostCancel, guestCancel, weCancel, fraud, litigate],
  completed: [payHost, hostCancel, guestCancel, weCancel, fraud, litigate],

  // cancellation
  rejected_by_host: [weCancel, fraud, litigate],
  canceled_by_guest: [refund, weCancel, fraud, litigate],
  canceled_by_partner: [refund, weCancel, fraud, litigate],
  canceled_by_host: [weCancel, fraud, litigate],
  canceled_by_us: [refund, fraud, litigate],
  canceled_under_delay: [payHost, refund, weCancel, fraud, litigate],
  fraud_suspicion: [payHost, guestCancel, hostCancel, weCancel, litigate],
  litigation: [payHost, guestCancel, hostCancel, weCancel, fraud],
  expired: [refund],

  // no transitions
  payment_tentative: [],
  payment_refused: [],
  host_payed: [],
  rescheduled: [],
  refunded: [],
  partially_refunded: [],
}

export const successfulBookingStatuses = ['validated_by_host', 'completed', 'host_payed']
export const statusOptions = [
  { text: __('Bookings::StatusOptions::Booking request'), value: 'booking_request' },
  { text: __('Bookings::StatusOptions::Completed'), value: 'completed' },
  { text: __('Bookings::StatusOptions::Canceled by guest'), value: 'canceled_by_guest' },
  { text: __('Bookings::StatusOptions::Canceled by partner'), value: 'canceled_by_partner' },
  { text: __('Bookings::StatusOptions::Canceled by host'), value: 'canceled_by_host' },
  { text: __('Bookings::StatusOptions::Canceled by us'), value: 'canceled_by_us' },
  { text: __('Bookings::StatusOptions::Canceled under delay'), value: 'canceled_under_delay' },
  { text: __('Bookings::StatusOptions::Expired'), value: 'expired' },
  { text: __('Bookings::StatusOptions::Fraud suspicion'), value: 'fraud_suspicion' },
  { text: __('Bookings::StatusOptions::Host payed'), value: 'host_payed' },
  { text: __('Bookings::StatusOptions::Litigation'), value: 'litigation' },
  { text: __('Bookings::StatusOptions::Partially refunded'), value: 'partially_refunded' },
  { text: __('Bookings::StatusOptions::Payment tentative'), value: 'payment_tentative' },
  { text: __('Bookings::StatusOptions::Payment refused'), value: 'payment_refused' },
  { text: __('Bookings::StatusOptions::Rejected by host'), value: 'rejected_by_host' },
  { text: __('Bookings::StatusOptions::Rescheduled'), value: 'rescheduled' },
  { text: __('Bookings::StatusOptions::Refunded'), value: 'refunded' },
  { text: __('Bookings::StatusOptions::Schedule'), value: 'schedule' },
  { text: __('Bookings::StatusOptions::Tentative'), value: 'tentative' },
  { text: __('Bookings::StatusOptions::Validated by host'), value: 'validated_by_host' },
]

const statusOptionsObject = Object.fromEntries(statusOptions.map(({ value, text }) => [value, text]))

export const aggregatedStatusOptions = [
  {
    text: __('Bookings::StatusOptions::Successful'),
    value: 'successful',
    aggregation: successfulBookingStatuses.map((status) => statusOptionsObject[status]),
  },
  {
    text: __('Bookings::StatusOptions::Pending'),
    value: 'pending',
    aggregation: [
      statusOptionsObject.booking_request,
      statusOptionsObject.schedule,
      statusOptionsObject.rescheduled,
      statusOptionsObject.payment_tentative,
      statusOptionsObject.tentative,
    ],
  },
  {
    text: __('Bookings::StatusOptions::Canceled'),
    value: 'canceled',
    aggregation: [
      statusOptionsObject.canceled_by_host,
      statusOptionsObject.canceled_by_us,
      statusOptionsObject.canceled_by_guest,
      statusOptionsObject.canceled_by_partner,
      statusOptionsObject.canceled_under_delay,
    ],
  },
  {
    text: __('Bookings::StatusOptions::Failed'),
    value: 'failed',
    aggregation: [
      statusOptionsObject.expired,
      statusOptionsObject.rejected_by_host,
      statusOptionsObject.payment_refused,
      statusOptionsObject.litigation,
      statusOptionsObject.fraud_suspicion,
      statusOptionsObject.partially_refunded,
      statusOptionsObject.refunded,
    ],
  },
]

export const dateRangeOptions = [
  { text: __('Bookings::DateRangeOptions::Date'), value: 'date' },
  { text: __('Bookings::DateRangeOptions::Created at'), value: 'created_at' },
  { text: __('Bookings::DateRangeOptions::Updated at'), value: 'updated_at' },
]

// Get all status it is possible to transit to
export const getCanTransitionToStatuses = (status) => statuses[status]

/**
 * checks if can transit to status passed as param
 * @param  {String}  status Status to check
 * @return {boolean}        result
 */
export const canTransitionToStatus = (status) => statuses[status] && statuses[status].length > 0

// Check if status is editable
export const isStatusEditable = (status) => !!statuses[status]

export const computeCancellationDeadline = (date, cancellationDeadline) => {
  if (!date || !cancellationDeadline) return null

  if (isCancellationDeadlineNonRefundable(cancellationDeadline)) return __('Bookings::CancellationDeadline::No refund')

  return moment.utc(date).subtract(moment.duration(cancellationDeadline), 'ms').format('lll')
}
