export const defaultSearchStatus = 'to review,approved,re-submitted'

export const statusOptions = [
  {
    text: __('HostApplications::StatusOptions::Draft'),
    value: 'draft',
  },
  {
    text: __('HostApplications::StatusOptions::To review'),
    value: 'to review',
  },
  {
    text: __('HostApplications::StatusOptions::Approved'),
    value: 'approved',
  },
  {
    text: __('HostApplications::StatusOptions::Rejected'),
    value: 'rejected',
  },
  {
    text: __('HostApplications::StatusOptions::Re-submitted'),
    value: 're-submitted',
  },
]

export const seasonsOptions = [
  {
    text: __('HostApplications::StatusOptions::Summer'),
    value: 'summer',
  },
  {
    text: __('HostApplications::StatusOptions::Spring'),
    value: 'spring',
  },
  {
    text: __('HostApplications::StatusOptions::Autumn'),
    value: 'autumn',
  },
  {
    text: __('HostApplications::StatusOptions::Winter'),
    value: 'winter',
  },
]

export const basicOptions = [
  { text: __('HostApplications::StatusOptions::All'), value: '' },
  { text: __('HostApplications::StatusOptions::Yes'), value: '1' },
  { text: __('HostApplications::StatusOptions::No'), value: '0' },
]

export const getColorFromStatus = (status) => {
  switch (status) {
    case 'to review':
      return 'blue'
    case 'rejected':
      return 'red'
    case 'approved':
      return 'green'
    case 're-submitted':
      return 'teal'
    default:
      return 'grey'
  }
}

export const getColorFromAction = (action) => {
  switch (action) {
    case 'comment':
      return 'blue'
    case 'rejection':
      return 'red'
    case 'contact':
      return 'orange'
    case 'approval':
      return 'green'
    case 're-submission':
      return 'teal'
    case 'assignment':
      return 'violet'
    case 'rating':
      return 'yellow'
    default:
      return 'grey'
  }
}

export const culinaryLevelOrder = ['professional', 'semi-professional', 'intermediate', 'beginner']
