// libs
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
// components
import { Segment, Message, Button } from 'semantic-ui-react'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
// style
import './index.css'

// redux
import { uploadOfflineReviews } from 'redux/entities/actions'
import { creatingOfflineReviews, getOfflineReviewsError } from 'redux/entities/selectors'

const mapStateToProps = (state) => ({
  uploading: creatingOfflineReviews(state),
  error: getOfflineReviewsError(state),
})
const mapDispatchToProps = (dispatch) => ({
  dispatch: {
    upload: (file) => dispatch(uploadOfflineReviews(file)),
  },
})
class OfflineReviewsIndex extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    uploading: PropTypes.bool.isRequired,
    error: PropTypes.shape({
      data: PropTypes.shape({
        message: PropTypes.string,
        stack_trace: PropTypes.any,
      }),
      status: PropTypes.number,
      statusText: PropTypes.string,
    }),
    dispatch: PropTypes.shape({
      upload: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    error: undefined,
  }

  state = { file: null, message: null, apiError: null, disabled: false }

  setInput = this.setInput.bind(this)
  setInput(ref) {
    this.input = ref
  }

  handleChange = this.handleChange.bind(this)
  handleChange() {
    if (this.input.files.length) {
      if (!this.input.files[0].name.endsWith('.csv')) {
        this.setState({
          message: <Message error>{this.props.t('OfflineReviews::You must select a .csv file')}</Message>,
          disabled: true,
        })
      } else if (this.state.message) this.setState({ message: null, disabled: false })
    }
  }

  upload = this.upload.bind(this)
  upload() {
    const { t, dispatch, error } = this.props
    if (!this.input.files.length) {
      this.setState({
        message: <Message error>{t('OfflineReviews::OfflineReviews::You must select a file')}</Message>,
        disabled: true,
      })
    } else {
      this.setState({ message: null })
      return dispatch.upload(this.input.files[0]).then(({ payload: { data } } = {}) => {
        if (error) this.setState({ apiError: error })
        else {
          const { entries, successful_imports, message } = data.result
          const content = t(
            'OfflineReviews::{{entries}} offline reviews were treated, {{successfulImports}} were imported successfully',
            { entries: entries, successfulImports: successful_imports },
          )
          this.setState({
            message: (
              <Message success>
                {message}
                <br />
                {content}
              </Message>
            ),
          })
        }
      })
    }
  }

  render() {
    const { t } = this.props
    return (
      <div className='offline-reviews-page'>
        <div className='custom-container'>
          <h1>{t('OfflineReviews::Upload offline reviews to the format .csv')}</h1>

          <Segment className='custom-row' loading={this.props.uploading}>
            <input ref={this.setInput} type='file' onChange={this.handleChange} />
            <Button onClick={this.upload} disabled={this.state.disabled}>
              {t('OfflineReviews::Upload')}
            </Button>
          </Segment>

          {this.state.message}
          <ApiErrorMessage error={this.state.apiError} />
        </div>
      </div>
    )
  }
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(OfflineReviewsIndex))
