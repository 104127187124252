// Libraries
import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'

// Components
import { Form, Message } from 'semantic-ui-react'

export const SeatsSection = ({ seats, hostFreeSeats, ewFreeSeats, handleChange }) => {
  const [t] = useTranslation()

  return (
    <div>
      <Form.Group widths='equal' className='__item'>
        <Form.Field>
          <label>{t('EventsCalendar::Total seats (including free seats')})</label>
          <Form.Input
            type='number'
            min={1}
            placeholder={t('EventsCalendar::How many guests?')}
            value={seats}
            onChange={(e, { value }) => handleChange({ seats: parseInt(value, 10) })}
            error={ewFreeSeats + hostFreeSeats > seats}
          />
        </Form.Field>
        <Form.Field>
          <label>{t('EventsCalendar::Seats paid by EW')}</label>
          <Form.Input
            type='number'
            min={0}
            max={seats - hostFreeSeats}
            placeholder={t('EventsCalendar::How many free seats?')}
            value={ewFreeSeats}
            onChange={(e, { value }) => handleChange({ ewFreeSeats: parseInt(value, 10) })}
            error={ewFreeSeats + hostFreeSeats > seats}
          />
        </Form.Field>
        <Form.Field>
          <label>{t('EventsCalendar::Seats paid by host')}</label>
          <Form.Input
            type='number'
            min={0}
            max={seats - ewFreeSeats}
            placeholder={t('EventsCalendar::How many free seats?')}
            value={hostFreeSeats}
            onChange={(e, { value }) => handleChange({ hostFreeSeats: parseInt(value, 10) })}
            error={ewFreeSeats + hostFreeSeats > seats}
          />
        </Form.Field>
      </Form.Group>

      {ewFreeSeats + hostFreeSeats > seats && (
        <Message negative>
          {t('EventsCalendar::The total number of seats must be above the number of free seats')}
        </Message>
      )}
    </div>
  )
}
SeatsSection.propTypes = {
  seats: PropTypes.number,
  hostFreeSeats: PropTypes.number,
  ewFreeSeats: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
}

SeatsSection.defaultProps = {
  seats: 0,
  hostFreeSeats: 0,
  ewFreeSeats: 0,
}
