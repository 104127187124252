import React from 'react'
import { Label, Popup } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export const PaymentPreferencesLabel = ({ hasBankAccount }) => {
  const [t] = useTranslation()
  const labelMessage = hasBankAccount ? t('Labels::set') : t('Labels::not set')
  const popupMessage = t('Labels::Payment preferences {{labelMessage}}', { labelMessage })
  const color = hasBankAccount ? 'green' : 'red'

  return (
    <Popup
      content={popupMessage}
      inverted
      trigger={
        <Label color={color} horizontal>
          {' '}
          {labelMessage.toUpperCase()}
        </Label>
      }
    />
  )
}

PaymentPreferencesLabel.propTypes = {
  hasBankAccount: PropTypes.bool,
}

PaymentPreferencesLabel.defaultProps = {
  hasBankAccount: false,
}
