import React, { PureComponent } from 'react'
import PropTypes, { omitSelfProps } from 'helpers/proptypes'
import { Message, Modal } from 'semantic-ui-react'

export default class ApiErrorMessage extends PureComponent {
  static propTypes = {
    // Error is immutable type
    error: PropTypes.shape({
      data: PropTypes.shape({
        message: PropTypes.string,
        stack_trace: PropTypes.any,
      }),
      status: PropTypes.number,
      statusText: PropTypes.string,
    }),
    modal: PropTypes.bool,
    noStack: PropTypes.bool,
  }

  static defaultProps = {
    modal: false,
    noStack: true,
    error: undefined,
  }

  getContent (e) {
    // TODO: rework this, current is hacky to avoid red boxes but does not fix anything
    const error = e.toJS ? e.toJS() : e
    console.error('APIERROR', error)
    return (
      <Message error>
        <Message.Header>{`${error.status} ${error.statusText}`}</Message.Header>
        {error.data && (
          <Message.Content>
            <h4>{error.data.message}</h4>
            {!this.props.noStack && <p>{error.data.stack_trace}</p>}
          </Message.Content>
        )}
      </Message>
    )
  }

  render () {
    const { modal, error } = this.props
    if (!this.props.error) return null // Do not display error if none to display :)
    const modalProps = { basic: true, ...omitSelfProps(this) }
    if (typeof modalProps.open === 'undefined') modalProps.defaultOpen = true
    return modal ? <Modal {...modalProps}>{this.getContent(error)}</Modal> : this.getContent(error)
  }
}
