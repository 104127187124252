// libs
import React from 'react'
import PropTypes from 'helpers/proptypes'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
// components
import { Table, Icon } from 'semantic-ui-react'
import { Link } from 'react-router'
import { ReviewCriteria } from 'components/reviews/ReviewCriteria'
import { ReviewPublicationModal } from 'components/modals/ReviewPublicationModal'

function ReviewRow({ review }) {
  const { t } = useTranslation()
  const eventId = review.getIn(['booking', 'event', 'id'])
  const userId = review.getIn(['user', 'id'])

  return (
    <Table.Row>
      <Table.Cell>
        <Link to={`/reviews/${review.id}`}>{review.id}</Link>
      </Table.Cell>
      <Table.Cell>{moment(review.created_at).format('ll')}</Table.Cell>
      <Table.Cell>{moment(review.getIn(['booking', 'date'])).format('ll')}</Table.Cell>
      <Table.Cell>
        {eventId && (
          <Link to={`/events/${eventId}`}>{`${eventId} - ${review.getIn(['booking', 'event', 'title'])}`}</Link>
        )}
      </Table.Cell>
      <Table.Cell>
        {userId ? (
          <Link
            to={`/users/${review.user.id}`}
          >{`${review.user.id} - ${review.user.firstname} ${review.user.lastname}`}</Link>
        ) : (
          review.firstname
        )}
      </Table.Cell>
      <Table.Cell>
        <div style={{ display: 'flex', gap: '4px' }}>
          {review.score} <Icon color='yellow' name='star' />
        </div>
      </Table.Cell>
      <Table.Cell>
        <div style={{ display: 'flex', marginBottom: '8px' }}>
          <ReviewCriteria ratings={review.ratings} />
        </div>
        {review.body}
      </Table.Cell>
      <Table.Cell>{review.offline ? t('Reviews::Offline') : t('Reviews::Online')}</Table.Cell>
      <Table.Cell>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: review.hidden_at ? 'red' : 'green' }}>
          <React.Fragment>
            {review.hidden_at ? (
              <Icon name='times circle outline' size='large' />
            ) : (
              <Icon name='check circle' size='large' />
            )}
            <ReviewPublicationModal review={review} />
          </React.Fragment>
        </div>
      </Table.Cell>
    </Table.Row>
  )
}

ReviewRow.propTypes = {
  review: PropTypes.immutable.record.isRequired,
}

export default ReviewRow
