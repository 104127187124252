import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getUser } from 'redux/entities/selectors'
import { Header, Segment, Grid, List } from 'semantic-ui-react'
import { QuickViewSection } from './QuickViewSection'
import { SampleExperienceSection } from './SampleExperienceSection'

const mapStateToProps = (state, props) => ({
  fromStore: {
    user: getUser(state, { id: props.hostApplication.user.id }),
  },
})

function _LegacyDataSection({ hostApplication, fromStore }) {
  const { t } = useTranslation()
  const { host, sample_experience: sampleExperience } = hostApplication

  function formatList(items) {
    return items ? (
      <List bulleted>
        {items.map((item) => (
          <List.Item key={item}>{item}</List.Item>
        ))}
      </List>
    ) : null
  }

  return (
    <Fragment>
      <QuickViewSection hostApplication={hostApplication} />

      {/* Host Section */}
      <Segment>
        <Header as='h3'>
          {t('HostApplications::Summary::About {{userName}}', { userName: fromStore?.user?.firstname })}
        </Header>
        <Segment basic>
          <Grid columns={2}>
            <Grid.Column>
              {sampleExperience.get('description') && (
                <Fragment>
                  <Header size='small'>{t('HostApplications::Summary::The host')}</Header>
                  {sampleExperience.get('description').get('host_description')}
                </Fragment>
              )}

              {host.get('motivation') && host.get('motivation').get('why_excellent') && (
                <Fragment>
                  <Header size='small'>{t('HostApplications::Summary::Why excellent?')}</Header>
                  {formatList(host.get('motivation').get('why_excellent'))}
                </Fragment>
              )}

              {host.get('description') && (
                <Fragment>
                  <Header size='small'>{t('HostApplications::Summary::Host description & hobbies')}</Header>
                  {host.get('description')}
                </Fragment>
              )}

              {host.get('interested_in_experience') && (
                <Fragment>
                  <Header size='small'>{t('HostApplications::Summary::Interested in hosting...')}</Header>
                  {host.get('interested_in_experience').get('meals') && (
                    <Fragment>
                      <Header size='tiny'>{t('HostApplications::Summary::Meals')}</Header>
                      {formatList(host.get('interested_in_experience').get('meals'))}
                    </Fragment>
                  )}

                  {host.get('interested_in_experience').get('classes') && (
                    <Fragment>
                      <Header size='tiny'>{t('HostApplications::Summary::Classes')}</Header>
                      {formatList(host.get('interested_in_experience').get('classes'))}
                    </Fragment>
                  )}

                  {host.get('interested_in_experience').get('activities') && (
                    <Fragment>
                      <Header size='tiny'>{t('HostApplications::Summary::Activities')}</Header>
                      {formatList(host.get('interested_in_experience').get('activities'))}
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Grid.Column>

            <Grid.Column>
              {host.get('motivation') && host.get('motivation').get('to_become_host') && (
                <Fragment>
                  <Header size='small'>{t('HostApplications::Summary::Motivation')}</Header>
                  {formatList(host.get('motivation').get('to_become_host'))}
                </Fragment>
              )}

              {host.get('culinary_experience') && (
                <Fragment>
                  <Header size='small'>{t('HostApplications::Summary::Culinary experience')}</Header>
                  <List bulleted>
                    {host.get('culinary_experience').map((culinaryExperience) => (
                      <List.Item key={culinaryExperience}>
                        {`${
                          culinaryExperience.get('other_type')
                            ? culinaryExperience.get('other_type')
                            : culinaryExperience.get('experience_type')
                        }
                  : ${culinaryExperience.get('level')}`}
                      </List.Item>
                    ))}
                  </List>
                </Fragment>
              )}

              {host.get('availabilities') && (
                <Fragment>
                  <Header size='small'>{t('HostApplications::Summary::Season')}</Header>
                  {formatList(host.get('availabilities').get('seasons'))}

                  <Header size='small'>{t('HostApplications::Summary::How often')}</Header>
                  {host.get('availabilities').get('frequency')}
                </Fragment>
              )}
            </Grid.Column>
          </Grid>
        </Segment>
      </Segment>

      <SampleExperienceSection hostApplication={hostApplication} />
    </Fragment>
  )
}

_LegacyDataSection.propTypes = {
  hostApplication: PropTypes.shape({
    sample_experience: PropTypes.object,
    host: PropTypes.object,
  }).isRequired,
  fromStore: PropTypes.shape({
    user: PropTypes.object,
  }).isRequired,
  actions: PropTypes.shape({
    loadResources: PropTypes.func,
  }).isRequired,
}

export const LegacyDataSection = connect(mapStateToProps)(_LegacyDataSection)
