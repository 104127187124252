// libs
import React, { useState, useEffect } from 'react'
import PropTypes from 'helpers/proptypes'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
// Hooks
import { useHasLoadingSucceeded } from 'hooks'
// Components
import { Modal, Button } from 'semantic-ui-react'
// Redux
import { publishReview, unpublishReview, fetchReview } from 'redux/entities/actions'
import { getReviewError, publishingReview, unpublishingReview } from 'redux/entities/selectors'

export function ReviewPublicationModal({ review }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [showPublicationModal, setShowPublicationModal] = useState()

  const error = useSelector((state) => getReviewError(state, review.id))
  const isUnpublishingReview = useSelector((state) => unpublishingReview(state, review.id))
  const isPublishingReview = useSelector((state) => publishingReview(state, review.id))
  const hasPublicationStatusChanged = useHasLoadingSucceeded(isPublishingReview || isUnpublishingReview, error)

  useEffect(() => {
    if (hasPublicationStatusChanged) {
      setShowPublicationModal(false)
      dispatch(fetchReview(review.id))
    }
  }, [dispatch, hasPublicationStatusChanged, review.id])

  function handleReviewPublicationStatus() {
    return review.hidden_at ? dispatch(publishReview(review.id)) : dispatch(unpublishReview(review.id))
  }

  return (
    <Modal
      open={showPublicationModal}
      onClose={() => setShowPublicationModal(false)}
      closeIcon
      trigger={
        <Button basic color={review.hidden_at ? 'green' : 'red'} onClick={() => setShowPublicationModal(true)}>
          {review.hidden_at ? t('Reviews::Summary::Publish') : t('Reviews::Summary::Unpublish')}
        </Button>
      }
      basic
      size='small'
    >
      <Modal.Content>
        {review.hidden_at
          ? t('Reviews::Summary::Are you sure you want to publish this review?')
          : t('Reviews::Summary::Are you sure you want to unpublish this review?')}
      </Modal.Content>
      <Modal.Actions>
        <Button positive loading={isPublishingReview || isUnpublishingReview} onClick={handleReviewPublicationStatus}>
          {t('Review::Summary::Continue')}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

ReviewPublicationModal.propTypes = {
  review: PropTypes.immutable.record.isRequired,
}
