import React from 'react'
import PropTypes from 'helpers/proptypes'
import { List } from 'components/lists'
import { EventRow } from './EventRow'
import { useTranslation } from 'react-i18next'

export const EventList = ({ events }) => {
  const [t] = useTranslation()

  const headers = [
    { label: '' },
    { label: t('Lists::Events::Id') },
    { label: t('Lists::Events::Title') },
    { label: t('Lists::Events::User Id') },
    { label: t('Lists::Events::Type') },
    { label: t('Lists::Events::Price') },
    { label: t('Lists::Events::Published at') },
  ]

  return (
    <List celled striped headers={headers}>
      {events.map((event) => (
        <EventRow key={event.id} event={event} />
      ))}
    </List>
  )
}

EventList.propTypes = {
  events: PropTypes.immutable.list,
}

EventList.defaultProps = {
  events: [],
}
