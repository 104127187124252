import React from 'react'
import { Header, Grid } from 'semantic-ui-react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'

export const SampleExperienceDescription = ({ sampleExperienceDescription }) => {
  const [t] = useTranslation()

  if (!sampleExperienceDescription) return null

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Header size='small'>{t('HostApplications::Summary::Title of experience')}</Header>
          {sampleExperienceDescription.get('title')}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Header size='small'>{t('HostApplications::Summary::Experience description')}</Header>
          {sampleExperienceDescription.get('event_description')}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Header size='small'>{sampleExperienceDescription.get('first_highlight_title')}</Header>
          {sampleExperienceDescription.get('first_highlight_description') || t('HostApplications::Summary::N/A')}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Header size='small'>{sampleExperienceDescription.get('second_highlight_title')}</Header>
          {sampleExperienceDescription.get('second_highlight_description') || t('HostApplications::Summary::N/A')}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

SampleExperienceDescription.propTypes = {
  sampleExperienceDescription: PropTypes.immutable.map.isRequired,
}
