import { createSelector } from 'reselect'
import {
  getEntities,
  getError,
  isFetching,
  getEntity,
  getEntityCount,
  getEntityOrder,
  getEntityTotal,
  isCreating,
  isUpdating,
  getCreatingError,
  getUpdatingError,
} from './_utils'
import { getUser } from './users'
import { getPlace } from './places'
import { getEvent } from './events'

export const getHostApplications = getEntities('hostApplications')
export const getHostApplicationsCount = getEntityCount('hostApplications')

export const getHostApplication = (state, { id }) => {
  const hostApplication = getEntity('hostApplications')(state, { id })

  return hostApplication
    .set('user', getUser(state, { id: hostApplication.user.id || hostApplication.user }))
    .set('place', getPlace(state, { id: hostApplication.place.id || hostApplication.place }))
    .set('event', getEvent(state, { id: hostApplication.event.id || hostApplication.event }))
}

export const getHostApplicationHost = createSelector(
  getHostApplication,
  (state) => state,
  (hostApplication, state) => hostApplication.get('host') || Map()
)

export const getHostApplicationSampleExperience = createSelector(
  getHostApplication,
  (state) => state,
  (hostApplication, state) => hostApplication.get('sample_experience')
)

export const getHostApplicationsOrder = getEntityOrder('hostApplications')
export const getHostApplicationsTotal = getEntityTotal('hostApplications')
export const getHostApplicationsList = (state) => getHostApplicationsOrder(state)
  .map((id) => getHostApplication(state, { id }))

export const fetchingHostApplications = isFetching('/api/hostApplications')
export const fetchingHostApplication = (state, id) => isFetching(`/api/host-applications/${id}`)(state)
export const updatingHostApplication = (state, id) => isUpdating(`/api/host-applications/${id}`)(state)
export const creatingHostApplication = isCreating('/api/hostApplications')

// Errors
export const getCreatingHostApplicationError = getCreatingError('/api/host-applications')
export const getHostApplicationsError = getError('/api/host-applications')
export const getHostApplicationError = (state, id) => getError(`/api/host-applications/${id}`)(state)
export const getUpdatingHostApplicationError = (state, id) => getUpdatingError(`/api/host-applications/${id}`)(state)
