import { getOrganization } from './organizations'
import { getAccount } from './accounts'
import { getUser } from './users'
import { getCurrency } from './statics'
import { getFollowup } from './followups'
import {
  getEntities,
  getError,
  isFetching,
  getEntity,
  getEntityCount,
  getEntityOrder,
  getEntityTotal,
  isCreating,
  isUpdating,
  getCreatingError,
  getUpdatingError,
} from './_utils'

export const getPartners = getEntities('partners')
export const getPartnersCount = getEntityCount('partners')
export const getPartnersTotal = getEntityTotal('partners')
export const getPartnersOrder = getEntityOrder('partners')

export function getPartner (state, { id }) {
  const partner = getEntity('partners')(state, { id })
  return partner
    .set('organization', getOrganization(state, { id: partner.organization_id || partner.organization }))
    .set('account', getAccount(state, { id: partner.account_id || partner.account }))
    .set('user', getUser(state, { id: partner.user }))
    .set('currency', getCurrency(state, { id: partner.currency }))
    .set('followups', partner.followups ? partner.followups.map((id) => getFollowup(state, { id })) : null)
}

export const getPartnersList = (state) => getPartnersOrder(state).map((id) => getPartner(state, { id }))

export const fetchingPartners = isFetching('/api/partners')

export const updatingPartner = (state, id) => isUpdating(`/api/partners/${id}`)(state)
export const creatingPartner = isCreating('/api/partners')

// Errors
export const getCreatingPartnerError = getCreatingError('/api/partners')
export const getPartnersError = getError('/api/partners')
export const getPartnerError = (state, id) => getError(`/api/partners/${id}`)(state)
export const getUpdatingPartnerError = (state, id) => getUpdatingError(`/api/partners/${id}`)(state)
