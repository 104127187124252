import settings from 'settings'
// libs
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { omitBy } from 'lodash'
import { withTranslation } from 'react-i18next'
// components
import { List, Paginate } from 'components/lists'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import TagRow from './Row'
// helpers
import { getFullPath } from 'helpers/url'
import { isInvalid } from 'helpers/forms'
// redux
import { getLocation } from 'redux/reducers/router'

import { fetchTags } from 'redux/entities/actions'
import { getTagsList, getTagsCount, getTagsError, fetchingTags, getTagsTotal } from 'redux/entities/selectors'

const mapStateToProps = (state) => ({
  error: getTagsError(state),
  loading: fetchingTags(state),
  tags: getTagsList(state),
  count: getTagsCount(state),
  total: getTagsTotal(state),
  location: getLocation(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    loadPaginatedTags: (query) => dispatch(fetchTags({ query })),
  },
})

const getHeaders = (t) => [
  { label: t('Tags::Id') },
  { label: t('Tags::Title'), field: 'tag' },
  { label: t('Tags::Actions') },
]

export class TagList extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.object,
    tags: PropTypes.object,
    count: PropTypes.number,
    total: PropTypes.number,
  }

  state = { error: null }

  UNSAFE_componentWillMount() {
    const defaultQuery = { offset: 0, size: settings.defaultPaginationSize }
    const query = this.props.location.get('query')
    if (query.size > 0) {
      this.loadTags({ ...defaultQuery, ...query.toJS() })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (getFullPath(this.props.location) !== getFullPath(nextProps.location)) {
      this.loadTags(nextProps.location.get('query').toJS())
    }
  }

  loadTags(query) {
    return this.props.actions
      .loadPaginatedTags(omitBy(query, isInvalid))
      .then(() => this.setState({ error: this.props.error }))
  }

  render() {
    const { t, tags } = this.props

    return (
      <Paginate count={this.props.total} previous next loading={this.props.loading}>
        <List striped celled sortable headers={getHeaders(t)} location={this.props.location}>
          {tags.toList().map((tag) => (
            <TagRow key={tag.id} tag={tag} />
          ))}
        </List>
        <ApiErrorMessage error={this.state.error} modal />
      </Paginate>
    )
  }
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(TagList))
