import settings from 'settings'
// Libs
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'helpers/proptypes'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
// Components
import { PaymentPreferencesLabel } from 'components/labels'
import { Link } from 'react-router'
import { Table, Button, Icon, Label, Popup, Dropdown, Rating } from 'semantic-ui-react'
import { createFollowups } from 'redux/entities/actions'
import { getAdminProfile } from 'redux/reducers/admin/selectors'
import { getAdmins } from 'redux/entities/selectors'
import { FormattedFollowup } from 'components/followups'
// Helpers
import { getFormattedAdmins } from 'helpers/admins'
import { connectAs } from 'helpers/users'
import './Row.css'

const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    addFollowups: (payload) => dispatch(createFollowups(payload)),
  },
})

const mapStateToProps = (state, props) => ({
  adminProfile: getAdminProfile(state),
  admins: getAdmins(state),
})

class _EventRow extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    event: PropTypes.immutable.record.isRequired,
    eventsType: PropTypes.string,
    onAddFollowup: PropTypes.func.isRequired,
    addingFollowup: PropTypes.bool,
    actions: PropTypes.shape({
      connectAs: PropTypes.func.isRequired,
      addFollowups: PropTypes.func.isRequired,
    }).isRequired,
    adminProfile: PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
    admins: PropTypes.immutable.map.isRequired,
  }

  static defaultProps = {
    locale: 'en',
    addingFollowup: false,
    eventsType: undefined,
  }

  state = { assignedAdmin: '' }

  UNSAFE_componentWillMount() {
    let assignmentFollowup
    const { event } = this.props
    if (event.followups && event.followups.size > 0) {
      assignmentFollowup = event.followups
        .filter((followup) => followup.action === 'ASSIGNMENT')
        .sort((a, b) => a.id - b.id)
        .last()
      this.setState({
        assignedAdmin: assignmentFollowup ? assignmentFollowup.comment : '',
      })
    }
  }

  previewEvent(accountId) {
    connectAs(accountId, settings.globalUrlFactory.dashboard.events.index())
  }

  handleAssignEvent = (e, value) => {
    e.preventDefault()
    const { actions, adminProfile, event } = this.props
    return actions
      .addFollowups({
        followups: [
          {
            admin_id: adminProfile.id,
            following_id: event.id,
            following_type: 'events',
            action: 'ASSIGNMENT',
            comment: value,
          },
        ],
      })
      .then(() => {
        this.setState({ assignedAdmin: value })
      })
  }

  render() {
    const { t, event, eventsType } = this.props

    return (
      <Table.Row>
        <Table.Cell className={event.ewid ? 'ew' : ''}>
          {event.tags.includes('flag-event') && (
            <Label color='blue' ribbon>
              <Rating icon='star' defaultRating={1} />
            </Label>
          )}
          {event.id}
          {event.ewid && (
            <i>
              <br />
              {t('Experiences::ew-{{ewId}}', { ewId: event.ewid })}
            </i>
          )}
        </Table.Cell>

        <Table.Cell>{moment(event.created_at).format('lll')}</Table.Cell>

        <Table.Cell>{moment(event.submitted_at).isValid() ? moment(event.submitted_at).format('lll') : ''}</Table.Cell>

        <Table.Cell>
          {event.published_at && (
            <a href={settings.webappUrlFactory.events.get(event.id)} target='_blank' rel='noopener noreferrer'>
              {event.title}
            </a>
          )}
          {!event.published_at && event.title}
          {event.privatized_by && <Label size='small'>{t('Experiences::Private')}</Label>}
          {event.frozen_at && <Icon circular inverted color='purple' name='asterisk' size='small' />}
          {event.situation && (
            <span>
              <br />
              <Label size='small'>{event.situation}</Label>
            </span>
          )}
        </Table.Cell>
        <Table.Cell>{`${event.price / 100}${event.user.currency.symbol}`}</Table.Cell>
        <Table.Cell>{`${event.priceWithFees / 100}${event.user.currency.symbol}`}</Table.Cell>

        <Table.Cell className={event.user.ewid ? 'ew' : ''}>
          <Link to={`/users?search=${event.user.id}`}>
            {`${event.user.id} - ${event.user.firstname} ${event.user.lastname}`}
          </Link>
        </Table.Cell>

        <Table.Cell>
          <PaymentPreferencesLabel user={event.user} />
        </Table.Cell>

        <Table.Cell>{event.type}</Table.Cell>

        <Table.Cell>{event.place.locality}</Table.Cell>

        <Table.Cell>{event.place.country_iso}</Table.Cell>

        {eventsType !== 'review' && (
          <Table.Cell>{event.published_at ? moment(event.published_at).format('lll') : undefined}</Table.Cell>
        )}

        <Table.Cell>{event.updated_at ? moment(event.updated_at).format('lll') : undefined}</Table.Cell>

        <Table.Cell>{event.status}</Table.Cell>

        <Table.Cell>
          <FormattedFollowup followups={event.followups} />
          &nbsp;
          {this.props.addingFollowup && <Icon loading name='circle notched' />}
        </Table.Cell>

        <Table.Cell>
          <Dropdown
            placeholder={t('Inputs::Select...')}
            fluid
            search
            selection
            value={this.state.assignedAdmin}
            options={getFormattedAdmins(this.props.admins)}
            onChange={(e, { value }) => this.handleAssignEvent(e, value)}
          />
        </Table.Cell>

        <Table.Cell textAlign='center'>
          <Popup
            trigger={<Button primary circular as={Link} to={`/events/${event.id}`} icon='edit' />}
            content={t('Experiences::Actions::Edit')}
            inverted
          />
          <Popup
            trigger={<Button primary circular onClick={() => this.props.onAddFollowup()} icon='file text' />}
            content={t('Experiences::Actions::Followup')}
            inverted
          />
          <Popup
            trigger={<Button primary circular onClick={() => this.previewEvent(event.user.account_id)} icon='eye' />}
            content={t('Experiences::Actions::Preview')}
            inverted
          />
        </Table.Cell>
      </Table.Row>
    )
  }
}

export const EventRow = withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(_EventRow))
