import React, { Fragment } from 'react'
import { Segment, Header, Grid, Label } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { VideoModal } from './VideoModal'
import { useTranslation } from 'react-i18next'

export function HostSection({ hostApplication: { host, sample_experience: sampleExperience } }) {
  const { t } = useTranslation()

  return (
    <Segment>
      <Header as='h3'>{t('HostApplications::Summary::About the applicant')}</Header>
      <Segment basic>
        <Grid columns={2}>
          <Grid.Column>
            {host.get('culinary_expertise') && (
              <Fragment>
                <Header size='small'>{t('HostApplications::Summary::Hosting expertise')}</Header>
                <Label basic circular>
                  {host.get('culinary_expertise')}
                </Label>
              </Fragment>
            )}
            {host.get('description') && (
              <Fragment>
                <Header size='small'>{t('HostApplications::Summary::Host presentation')}</Header>
                {host.get('description')}
              </Fragment>
            )}
            {sampleExperience.get('user_video') && (
              <Segment basic>
                <VideoModal videoUrl={sampleExperience.get('user_video').get('cdn_url')} />
              </Segment>
            )}
          </Grid.Column>
          <Grid.Column>
            {host.get('host_typology') && (
              <Fragment>
                <Header size='small'>{t('HostApplications::Summary::Host typology')}</Header>
                <Label basic circular color='blue'>
                  {host.get('host_typology')}
                </Label>
              </Fragment>
            )}
            {host.getIn(['availabilities', 'frequency']) && (
              <Fragment>
                <Header size='small'>{t('HostApplications::Summary::How often')}</Header>
                <Label basic circular>
                  {host.getIn(['availabilities', 'frequency'])}
                </Label>
              </Fragment>
            )}
          </Grid.Column>
        </Grid>
      </Segment>
    </Segment>
  )
}

HostSection.propTypes = {
  hostApplication: PropTypes.shape({
    host: PropTypes.object,
    sample_experience: PropTypes.object,
  }).isRequired,
}
