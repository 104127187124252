// libs
import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get, omitBy } from 'lodash'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { withTranslation } from 'react-i18next'

// components
import EditPageMenu from 'components/menus/EditPageMenu'
import { OrganizationForm } from './OrganizationForm'
import { Grid, Rail, Segment, Loader } from 'semantic-ui-react'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { toast } from 'react-toastify'

// helpers
import { isInvalid } from 'helpers/forms'

// redux
import { fetchOrganization, fetchTags, updateOrganization, createOrganization } from 'redux/entities/actions'
import {
  getOrganization,
  getUpdatingOrganizationError,
  getCreatingOrganizationError,
  getSortedTags,
  fetchingOrganization,
  fetchingTags,
} from 'redux/entities/selectors'

const mapStateToProps = (state, props) => ({
  fromStore: {
    organization: props.params && getOrganization(state, { id: props.params.id }),
    error:
      props.params && props.params.id
        ? getUpdatingOrganizationError(state, props.params.id)
        : getCreatingOrganizationError(state),
    tags: getSortedTags(state),
    isFetchingOrganization: fetchingOrganization(state, props.params && props.params.id),
    isFetchingTags: fetchingTags(state),
  },
})

const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    fetchOrganization: (id) => dispatch(fetchOrganization(id)),
    updateOrganization: (id, payload) => dispatch(updateOrganization(id, payload)),
    createOrganization: (payload) => dispatch(createOrganization(payload)),
    fetchTags: () => dispatch(fetchTags()),
  },
})

class _OrganizationFormWrapper extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      submitted: false,
      isEditing: props.params && !!props.params.id,
    }
  }

  componentDidMount() {
    const { params, actions } = this.props

    if (params && params.id) {
      actions.fetchOrganization(params.id)
    }

    actions.fetchTags()
  }

  componentDidUpdate(prevProps) {
    const { submitted } = this.state
    const {
      t,
      fromStore: { error },
    } = this.props

    if (submitted && error === undefined) {
      toast.success(
        this.state.isEditing
          ? t('ToastNotification::Summary::The organization {{name}} has been updated', { name: this.state.name })
          : t("ToastNotification::Summary::Well done! You've just saved a new organization"),
      )

      this.setState({ submitted: false })
      this.props.onSubmit && this.props.onSubmit(this.state.name)
    }
  }

  getMenuItems = (t) => [
    { text: t('Organizations::Summary::Tags'), value: 'tags' },
    { text: t('Organizations::Summary::Name'), value: 'name' },
    { text: t('Organizations::Summary::Organization type'), value: 'type' },
    { text: t('Organizations::Summary::Logo'), value: 'logoUrl' },
    { text: t('Organizations:Summary::Cancellation delay'), value: 'cancellation' },
    { text: t('Organizations:Summary::Revenue share'), value: 'revenue' },
    { text: t('Organizations:Summary::Support url'), value: 'url' },
    { text: t('Organizations:Summary::Support email'), value: 'email' },
    { text: t('Organizations:Summary::Support phone number'), value: 'phone' },
    { text: t('Organizations:Summary::Display prices'), value: 'displayPrice' },
    { text: t('Organizations:Summary::Online payment'), value: 'onlinePayment' },
  ]

  handleChange = (data) => {
    this.setState({
      ...this.state.form,
      ...data,
    })
  }

  extractIdFromMap = (map, attributeName, filter) => {
    const filteredObject = map
      .filter((item) => item[attributeName] === filter)
      .values()
      .next().value

    return filteredObject ? filteredObject.id : undefined
  }

  handleSubmit = async (values) => {
    const { actions, params } = this.props

    this.setState({ submitted: true })

    const organizationPayload = omitBy(
      {
        tags: values.tags,
        name: values.name,
        logo_url: values.logoUrl,
        type: values.type,
        cancellation_delay: values.cancellationDelay,
        revenue_share: values.revenueShare,
        support_url: values.supportUrl,
        support_email: values.supportEmail,
        support_phone: values.supportPhone,
        display_price: values.displayPrice,
        online_payment: values.onlinePayment,
      },
      isInvalid,
    )

    if (this.state.isEditing) {
      await actions.updateOrganization(params.id, organizationPayload)
    } else {
      await actions.createOrganization(organizationPayload)
    }

    this.setState({ submitted: false })
  }

  getColumnNumber = () => {
    switch (true) {
      case this.props.hideMenu:
        return 1
      default:
        return 2
    }
  }

  render() {
    const { t, fromStore, hideMenu } = this.props

    return (
      <Fragment>
        <Grid centered columns={this.getColumnNumber()}>
          <Grid.Column>
            <Segment basic>
              {!hideMenu && (
                <Rail position='left'>
                  <EditPageMenu listName='organizations' menuItems={this.getMenuItems(t)} />
                </Rail>
              )}

              {fromStore.isFetchingOrganization || fromStore.isFetchingTags ? (
                <Segment basic>
                  <Loader active content={t('Loading::Loading')} inline='centered' />
                </Segment>
              ) : (
                <Formik
                  initialValues={{
                    name: get(fromStore.organization, 'name'),
                    type: get(fromStore.organization, 'type', 'Other'),
                    tags: get(fromStore.organization, 'tags') ? Array.from(get(fromStore.organization, 'tags')) : [],
                    tagsList: fromStore.tags,
                    logoUrl: get(fromStore.organization, 'logo_url'),
                    cancellationDelay: get(fromStore.organization, 'cancellation_delay', 1),
                    revenueShare: get(fromStore.organization, 'revenue_share', 0.5),
                    supportUrl: get(fromStore.organization, 'url'),
                    supportEmail: get(fromStore.organization, 'email'),
                    supportPhone: get(fromStore.organization, 'phone'),
                    displayPrice: get(fromStore.organization, 'display_price', true),
                    onlinePayment: get(fromStore.organization, 'payment', true),
                  }}
                  onSubmit={this.handleSubmit}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().trim().required(),
                    type: Yup.string().required(),
                    logoUrl: Yup.string().nullable(),
                    cancellationDelay: Yup.number().positive().required(),
                    revenueShare: Yup.number().required(),
                    supportUrl: Yup.string().nullable(),
                    supportEmail: Yup.string().email().nullable(),
                    supportPhone: Yup.string().nullable(),
                    displayPrice: Yup.boolean(),
                    onlinePayment: Yup.boolean(),
                  })}
                  component={OrganizationForm}
                />
              )}
            </Segment>
          </Grid.Column>
        </Grid>

        <ApiErrorMessage error={fromStore.error} modal />
      </Fragment>
    )
  }
}

_OrganizationFormWrapper.propTypes = {
  t: PropTypes.func.isRequired,
  fromStore: PropTypes.shape({
    organization: PropTypes.object,
    tags: PropTypes.arrayOf(PropTypes.string),
    error: PropTypes.error,
    isFetchingOrganization: PropTypes.bool,
    isFetchingTags: PropTypes.bool,
  }).isRequired,
  actions: PropTypes.shape({
    fetchOrganization: PropTypes.func,
    fetchOrganizations: PropTypes.func,
    updateOrganization: PropTypes.func,
    createOrganization: PropTypes.func,
    fetchTags: PropTypes.func,
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
  hideMenu: PropTypes.bool,
  hideCard: PropTypes.bool,
  onSubmit: PropTypes.func,
}

_OrganizationFormWrapper.defaultProps = {
  params: undefined,
  hideMenu: false,
  hideCard: false,
  onSubmit: undefined,
}

export const OrganizationFormWrapper = withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(_OrganizationFormWrapper),
)
