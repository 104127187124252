// Libraries
import React, { Fragment, useState, useEffect, useCallback } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation, Trans } from 'react-i18next'
// Helpers
import { numbers } from '@vizeat/helpers'
import { businessApi } from 'helpers/api'
// Components
import { Tooltip } from '@vizeat/components/es6/components/Tooltip'
import { Form, Label, Icon } from 'semantic-ui-react'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { SelectCurrency } from 'components/inputs'

const { formatIntegerPrice, formatPriceAsFloat, formatPriceAsInt } = numbers

export function PricingSection({
  allowGuestPriceEdition,
  currency,
  date,
  event,
  handleChange,
  isCurrencyDisabled,
  isPriceDisabled,
  price,
  isFixedPrice,
  initialGuestPrice,
  seats,
  user,
}) {
  const [t] = useTranslation()
  const [pricing, setPricing] = useState()
  const [hostPrice, setHostPrice] = useState(price)
  const [guestPrice, setGuestPrice] = useState(initialGuestPrice)
  const [guestCurrencyId, setGuestCurrencyId] = useState(currency.id)
  const [pricingError, setPricingError] = useState(undefined)
  const [isFixedGuestPriceMode, setIsFixedGuestPriceMode] = useState(isFixedPrice)

  const stableHandleChange = useCallback(handleChange, [])

  const fetchPricing = useCallback(() => {
    if (!event?.id || !user.id || !date || !seats) return
    setPricingError(undefined)

    const fixedGuestPrice = isFixedGuestPriceMode ? guestPrice : undefined

    businessApi.delayedGet({
      path: `/api/events/${event.id}/pricing`,
      config: {
        params: {
          currency_id: isFixedGuestPriceMode ? guestCurrencyId : user.currency_id,
          date: typeof date === 'string' ? date : date.format('YYYY-MM-DD'),
          guest_price: fixedGuestPrice,
          host_price: hostPrice,
          seats,
          user_id: user.id,
          gateway: 'invoice', // doesn't add any currency conversion fees
        },
      },
      successCallback: ({ pricing, pricing: { payee, payer } }) => {
        setPricing(pricing)
        setHostPrice(payee.price)
        setGuestCurrencyId(payer.currency.id)
        if (!isFixedGuestPriceMode) setGuestPrice(payer.unit_price_with_fees)

        stableHandleChange({
          isFixedPrice: isFixedGuestPriceMode,
          currency: payer.currency,
          guestPrice: fixedGuestPrice,
          price: payee.price,
          totalPrice: payer.total_paid,
        })
      },
      errorHandler: ({ response }) => {
        setPricingError(response)
        stableHandleChange({
          isFixedPrice: isFixedGuestPriceMode,
          currency: {},
          guestPrice: undefined,
          price: undefined,
          totalPrice: undefined,
        })
      },
      delay: 1000,
    })
  }, [
    date,
    event?.id,
    guestCurrencyId,
    guestPrice,
    hostPrice,
    isFixedGuestPriceMode,
    seats,
    stableHandleChange,
    user.currency_id,
    user.id,
  ])

  useEffect(() => {
    fetchPricing()
  }, [fetchPricing])

  function handleHostPriceChange(e, { value }) {
    const newHostPrice = formatPriceAsInt(value, pricing.payee.currency)
    setHostPrice(newHostPrice)
  }

  function handleGuestPriceChange(e, { value }) {
    const newGuestPrice = formatPriceAsInt(value, pricing.payer.currency)
    setGuestPrice(newGuestPrice)
  }

  if (!pricing) return null

  return (
    <Fragment>
      <Form.Group widths='equal' className='__item'>
        <Form.Field width={1}>
          <label>{t('EventsCalendar::Host price/seat {{currency}}', { currency: pricing.payee.currency.iso_3 })}</label>
          <Form.Input
            type='number'
            disabled={isPriceDisabled}
            placeholder={t('EventsCalendar::Enter a price per seat')}
            value={formatPriceAsFloat(hostPrice, pricing.payee.currency)}
            onChange={handleHostPriceChange}
            step={pricing.payee.currency.iso_3 === 'JPY' ? 1 : 0.5}
          />
        </Form.Field>
        <Form.Field width={1}>
          <label>
            {isFixedGuestPriceMode
              ? t('EventsCalendar::Fixed guest price/seat {{currency}}', { currency: pricing.payer.currency.iso_3 })
              : t('EventsCalendar::Estimated guest price/seat {{currency}}', {
                  currency: pricing.payer.currency.iso_3,
                })}
            {allowGuestPriceEdition && (
              <Tooltip
                renderContent={() => t('EventsCalendar::Click the icon to toggle edit mode for the guest price')}
              >
                <Icon
                  name='edit'
                  color={isFixedGuestPriceMode ? 'green' : 'red'}
                  onClick={() => {
                    setIsFixedGuestPriceMode(!isFixedGuestPriceMode)
                  }}
                />
              </Tooltip>
            )}
          </label>
          <Form.Input
            type='number'
            disabled={!isFixedGuestPriceMode}
            placeholder={t('EventsCalendar::Enter a price per seat')}
            value={formatPriceAsFloat(guestPrice, pricing.payer.currency)}
            onChange={handleGuestPriceChange}
            step={pricing.payee.currency.iso_3 === 'JPY' ? 1 : 0.5}
            error={pricingError}
          />
        </Form.Field>
        {isFixedGuestPriceMode && (
          <Form.Field width={1}>
            <label>{t('EventsCalendar::Guest currency')}</label>
            <SelectCurrency
              isDisabled={isCurrencyDisabled}
              currencyId={guestCurrencyId}
              handleCurrencyChange={(currencyId) => setGuestCurrencyId(currencyId)}
              error={pricingError}
            />
          </Form.Field>
        )}
      </Form.Group>

      {pricingError && <ApiErrorMessage error={pricingError} modal />}
      <Trans
        i18nKey={__(
          'EventsCalendar::Fees rate: {{feesRate}}% | Fees: {{fees}} | Total guest price incl. fees: <label>{{totalPrice}}</label>',
        )}
        values={{
          feesRate: (pricing.fees.rate * 100).toFixed(2),
          fees: formatIntegerPrice(pricing.fees.total, 'en', pricing.fees.currency),
          totalPrice: formatIntegerPrice(pricing.payer.total_paid, 'en', pricing.payer.currency),
        }}
        components={{ label: <Label circular color='blue' /> }}
      />
    </Fragment>
  )
}

PricingSection.propTypes = {
  allowGuestPriceEdition: PropTypes.bool,
  currency: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  date: PropTypes.oneOfType([PropTypes.moment, PropTypes.string]).isRequired,
  event: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  handleChange: PropTypes.func.isRequired,
  isCurrencyDisabled: PropTypes.bool,
  isPriceDisabled: PropTypes.bool,
  price: PropTypes.number,
  isFixedPrice: PropTypes.bool,
  initialGuestPrice: PropTypes.number,
  seats: PropTypes.number.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency_id: PropTypes.number,
  }),
}

PricingSection.defaultProps = {
  allowGuestPriceEdition: false,
  event: undefined,
  isFixedPrice: false,
  isCurrencyDisabled: false,
  isPriceDisabled: false,
  price: undefined,
  initialGuestPrice: undefined,
  user: undefined,
}
