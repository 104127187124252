import { fromJS } from 'immutable'
import { load } from 'redux/actions/intercom'
import { createReducer } from 'redux-immutablejs'

const initialState = fromJS({
  items: {},
  loading: null,
  error: null,
})

const handlers = {
  [load.actions.LOADING_USER]: (state, { id }) => state.merge(fromJS({ loading: id, error: null })),
  [load.actions.LOAD_USER_ERROR]: (state, { error, id }) => state.merge(fromJS({ loading: null, error: id })),
  [load.actions.LOADED_USER]: (state, { payload }) => {
    return state.mergeIn(['items'], fromJS(payload.entities.intercomUsers))
  },
}

export default createReducer(initialState, handlers)
