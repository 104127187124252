import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Button, Modal } from 'semantic-ui-react'

import { getUserError, deletingUser } from 'redux/entities/selectors'
import { deleteUser } from 'redux/entities/actions'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { useHasLoadingSucceeded } from 'hooks'

const mapStateToProps = (state, props) => ({
  fromStore: {
    error: getUserError(state, props.user.id),
    loading: deletingUser(state, props.user.id),
  },
})

const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    anonymiseUser: () => dispatch(deleteUser(props.user.id)),
  },
})

function _AnonymiseUserButton({ actions: { anonymiseUser }, fromStore: { loading, error }, t }) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const hasLoadingSucceded = useHasLoadingSucceeded(loading, error)

  useEffect(() => {
    if (hasLoadingSucceded) setIsModalOpen(false)
  }, [hasLoadingSucceded, setIsModalOpen, isModalOpen])

  if (!window.location.host.includes('eatwith.com')) return null
  return (
    <React.Fragment>
      <Button negative compact onClick={() => setIsModalOpen(true)}>
        {t('Users::Anonymise (GDPR)')}
      </Button>

      <ApiErrorMessage error={error} modal />

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Modal.Header>
          {t("Users::You're 'You're about to delete all user's information. Proceed with caution")}
        </Modal.Header>
        <Modal.Description style={{ padding: 30, fontSize: 16 }}>
          <p style={{ color: 'red' }}>
            {t('Users::This operation cannot be undone and will impact services outside of Eatwith as well')}
          </p>
          <p>{t('Users::By clicking this button you will:')}</p>
          <ul>
            <li>
              {t(
                "Users::Anonymise and or delete all data related to the user in Eatwith's related database. This will impact files, places, experiences, account and user",
              )}
            </li>
            <li>{t('Users::It will permanentely delete the user information at Intercom')}</li>
            <li>{t('Users::It will permanentely delete the user information at Mailchimp')}</li>
          </ul>
          <Button negative onClick={anonymiseUser} loading={loading}>
            {t('Users::Confirm I want to proceed')}
          </Button>
        </Modal.Description>
      </Modal>
    </React.Fragment>
  )
}

_AnonymiseUserButton.propTypes = {
  actions: PropTypes.shape({
    anonymiseUser: PropTypes.func.isRequired,
  }).isRequired,
  fromStore: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    error: PropTypes.object,
  }).isRequired,
  user: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

export const AnonymiseUserButton = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(_AnonymiseUserButton))
