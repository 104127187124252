import settings from 'settings'
// Components
import FileInfo from './FileInfo'
import FocusedFile from './FocusedFile'
import UploadForm from './UploadForm'
import { Paginate } from 'components/lists'
// Libraries
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { omitBy } from 'lodash'
import { isInvalid } from 'helpers/forms'
// Utils
import { getFullPath } from 'helpers/url'
// Redux
import { fetchFiles } from 'redux/entities/actions'
import { getFilesList, getFilesCount, getFilesTotal, getFilesError, fetchingFiles } from 'redux/entities/selectors'
import { getLocation } from 'redux/reducers/router'
// Style
import './Gallery.css'

const mapStateToProps = (state) => ({
  error: getFilesError(state),
  loading: fetchingFiles(state),
  files: getFilesList(state),
  count: getFilesCount(state),
  total: getFilesTotal(state),
  location: getLocation(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    loadPaginatedFiles: (query) => dispatch(fetchFiles({ query })),
  },
})

export class FilesGallery extends PureComponent {
  static propTypes = {
    actions: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.object,
    files: PropTypes.object,
    count: PropTypes.number,
    total: PropTypes.number,
  }

  state = { focusedFilePath: null }
  UNSAFE_componentWillMount() {
    const defaultQuery = { offset: 0, size: settings.defaultGallerySize }
    const query = this.props.location.get('query')
    this.loadFiles({ ...defaultQuery, ...query.toJS() })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (getFullPath(this.props.location) !== getFullPath(nextProps.location)) {
      const defaultQuery = { offset: 0, size: settings.defaultGallerySize }
      this.loadFiles({ ...defaultQuery, ...nextProps.location.get('query').toJS() })
    }
  }

  loadFiles = (query) => {
    return this.props.actions
      .loadPaginatedFiles(omitBy(query, isInvalid))
      .then(() => this.setState({ error: this.props.error }))
  }

  handleFocusFile = (path) => this.setState({ focusedFilePath: path })
  removeFocusFile = () => this.setState({ focusedFilePath: null })

  render() {
    const { files, total, loading } = this.props
    const { focusedFilePath } = this.state

    return (
      <Paginate count={total} previous next loading={loading} size={settings.defaultGallerySize}>
        <UploadForm onFilesUploaded={this.loadFiles} />
        <div className='vz-gallery'>
          {files.toList().map((file) => (
            <FileInfo file={file} onFocus={this.handleFocusFile} key={file.id} />
          ))}
        </div>
        {focusedFilePath && <FocusedFile path={focusedFilePath} onFocus={this.removeFocusFile} />}
      </Paginate>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilesGallery)
