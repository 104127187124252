// Libraries
import React, { PureComponent } from 'react'
// Components
import SearchForm from './SearchForm'
import PayoutsList from './List'
import CsvForm from './CsvForm'

export default class PayoutsIndex extends PureComponent {
  render () {
    return (
      <div>
        <SearchForm />
        <CsvForm />
        <PayoutsList />
      </div>
    )
  }
}
