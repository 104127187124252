// Libs
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import debounce from 'p-debounce'
// Components
import { Search } from 'semantic-ui-react'
// Redux
import { fetchPartners } from 'redux/entities/actions'
import { getPartnersList, fetchingPartners } from 'redux/entities/selectors'

const mapStateToProps = (state) => ({
  fromStore: {
    loadingPartners: fetchingPartners(state),
    partners: getPartnersList(state),
  },
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    fetchPartners: debounce((search) => dispatch(fetchPartners({ query: { search, size: 10 } })), 700),
  },
})

function _PartnerAutocomplete ({
  actions: { fetchPartners },
  fromStore: { loadingPartners, partners },
  handleResultSelect,
  initialValue = '',
  disabled,
}) {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    if (value) fetchPartners(value)
  }, [fetchPartners, value])

  function getPartnerOptions () {
    return partners
      .toList()
      .toJS()
      .map(({ id, name, organization: { name: organizationName } }) => ({
        description: organizationName,
        id,
        title: name,
      }))
  }

  function onResultSelect (e, { result: { id, title } }) {
    setValue(title)
    handleResultSelect(id)
  }

  return (
    <Search
      disabled={disabled}
      loading={loadingPartners}
      value={value}
      onResultSelect={onResultSelect}
      onSearchChange={(e) => setValue(e.target.value)}
      results={getPartnerOptions()}
    />
  )
}

_PartnerAutocomplete.propTypes = {
  actions: PropTypes.shape({
    fetchPartners: PropTypes.func.isRequired,
  }).isRequired,
  fromStore: PropTypes.shape({
    loadingPartners: PropTypes.bool.isRequired,
    partners: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        organization: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  handleResultSelect: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
  disabled: PropTypes.bool,
}

_PartnerAutocomplete.defaultProps = {
  disabled: false,
  initialValue: '',
}

export const PartnerAutocomplete = connect(
  mapStateToProps,
  mapDispatchToProps
)(_PartnerAutocomplete)
