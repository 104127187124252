// libs
import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'
import { List } from 'immutable'
// components
import { Form } from 'semantic-ui-react'
import { SearchModal } from 'components/modals'
import { EventSearch } from 'components/lists'
// helpers
import { getTranslatedOptions } from 'helpers/options'
import { campaignCategoryOptions, campaignTypeOptions, campaignUsageOptions } from 'helpers/campaigns'
// styles
import './index.css'

// redux
import { createForm, removeForm, mergeInForm } from 'redux/forms/actions'
import { getForm } from 'redux/forms/reducer'
import { creatingCampaigns, updatingCampaign, getCurrencies } from 'redux/entities/selectors'
import { withTranslation } from 'react-i18next'

const mapStateToProps = (state, props) => ({
  fromStore: {
    currencies: getCurrencies(state),
    form: getForm(state, 'campaign'),
    eventForm: getForm(state, 'events'),
    creating: creatingCampaigns(state),
    updating: props.campaign && updatingCampaign(state, props.campaign.id),
  },
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    createForm: ({ initialState }) => dispatch(createForm({ formName: 'campaign', initialState })),
    removeForm: () => dispatch(removeForm('campaign')),
    removeEventForm: () => dispatch(removeForm({ formName: 'events' })),
    mergeInForm: (formData) => dispatch(mergeInForm({ formName: 'campaign', value: formData })),
  },
})

class _CampaignForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    campaign: PropTypes.object,
    fromStore: PropTypes.shape({
      currencies: PropTypes.array,
      form: PropTypes.object,
      creating: PropTypes.bool,
      updating: PropTypes.bool,
    }).isRequired,
    actions: PropTypes.shape({
      createForm: PropTypes.func.isRequired,
      removeForm: PropTypes.func.isRequired,
      mergeInForm: PropTypes.func.isRequired,
    }).isRequired,
  }

  UNSAFE_componentWillMount() {
    const { campaign, actions } = this.props
    const initialState = this.campaignEntityToCampaignForm(campaign)
    actions.createForm({ initialState })
  }

  UNSAFE_componentWillReceiveProps({ campaign, actions }) {
    const shouldSetForm = campaign && (!this.props.campaign || campaign.id !== this.props.campaign.id)
    if (shouldSetForm) actions.mergeInForm(this.campaignEntityToCampaignForm(campaign))
  }

  componentWillUnmount() {
    this.props.actions.removeForm()
  }

  campaignEntityToCampaignForm = (campaign = {}) => ({
    n: get(campaign, 'n', 1),
    title: get(campaign, 'title', ''),
    description: get(campaign, 'description', ''),
    user_id: get(campaign, 'user_id', null),
    category: get(campaign, 'category', 'OTHER'),
    code: get(campaign, 'code', null),
    begin_at: get(campaign, 'begin_at', moment().format('YYYY-MM-DD')),
    end_at: get(campaign, 'end_at', moment().add(1, 'year').format('YYYY-MM-DD')),
    type: get(campaign, 'type', 'Value'),
    currency_id: get(campaign, 'currency_id', 1),
    discount: get(campaign, 'discount', 0),
    usage: get(campaign, 'usage', 'Booking'),
    max_usage_limit: get(campaign, 'max_usage_limit', 1),
    // min_usage_limit: get(campaign, 'min_usage_limit', null),
    max_guest_limit: get(campaign, 'max_guest_limit', null),
    min_guest_limit: get(campaign, 'min_guest_limit', null),
    max_price_limit: get(campaign, 'max_price_limit', null),
    min_price_limit: get(campaign, 'min_price_limit', null),
    first_time: get(campaign, 'first_time', false),
    free_only: get(campaign, 'free_only', false),
    active: get(campaign, 'active', true),
    event_ids: get(campaign, 'event_ids', new List()),
  })

  Section = ({ id, title, subtitle, className, children }) => (
    <section id={id} className={className}>
      <h3 className='section-title'>
        {title}
        <small className='vz-campaigns-add-small-subtitle'>{subtitle}</small>
      </h3>
      {children}
    </section>
  )

  onTypeChange = (e, { value }) => {
    const {
      fromStore: { form },
    } = this.props
    const currencyId = form.get('currency_id') || 1
    this.props.actions.mergeInForm({ type: value, currency_id: currencyId })
  }

  handleSubmitModal = () => {
    const {
      fromStore: { eventForm },
      actions,
    } = this.props
    if (!eventForm || !eventForm.size) return

    const eventIds = eventForm.getIn(['event_ids'])
    actions.mergeInForm({ event_ids: eventIds })
    actions.removeEventForm()
  }

  render() {
    const {
      t,
      fromStore: { currencies, form, creating },
      actions,
    } = this.props
    if (!form || !form.size) return null

    const currenciesOpts = currencies
      .toList()
      .sortBy((currency) => currency.id)
      .map(({ id, iso_3 }) => ({ value: id, text: iso_3 }))
      .toJS()

    return (
      <Form className='vz-campaigns-add-form'>
        {creating && (
          <Fragment>
            <this.Section
              id='help'
              title={t('Campaigns::Guidelines::How To')}
              subtitle={t('Campaigns::Guidelines::Guidelines to create coupons')}
            >
              <p>{t('Campaigns::Guidelines::Mainly for -10€ or -10%')}</p>
              <ul>
                <li>
                  {t('Campaigns::Guidelines::If you need ONE coupon for MANY users:')}
                  <ul>
                    <li>{t('Campaigns::Guidelines::Create 1 campaign')}</li>
                    <li>
                      {t(
                        'Campaigns::Guidelines::Choose your Code (you cannot reuse a past coupons, names must be unique)',
                      )}
                    </li>
                    <li>{t('Campaigns::Guidelines::Set a Max Usage to the number of users expected')}</li>
                    <li>
                      {t(
                        'Campaigns::Guidelines::Set other parameters to your needs (max price, discount, min guests etc...)',
                      )}
                    </li>
                  </ul>
                </li>
                <li>
                  {t('Campaigns::Guidelines::If you need multiple individual coupons')}
                  <ul>
                    <li>{t('Campaigns::Guidelines::Create many campaigns')}</li>
                    <li>{t('Campaigns::Guidelines::Do not choose a code')}</li>
                    <li>{t('Campaigns::Guidelines::The Max Usage should most likely be 1')}</li>
                    <li>
                      {t(
                        'Campaigns::Guidelines::Set other parameters to your needs (max price, discount, min guests etc...)',
                      )}
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                <strong>
                  {t(
                    'Campaigns::Guidelines::Remember: If you use the type "value", the amount must be in cents (10€ → 1000)',
                  )}
                </strong>
              </p>
              <p>
                {t(
                  'Campaigns::Guidelines::Remember we do not do 100% off coupon anymore. If you need for example for a blogger → Spendesk is your friend!',
                )}
              </p>
            </this.Section>
            <this.Section
              id='amount'
              title={t('Campaigns::Summary::Amount')}
              subtitle={t('Campaigns::Summary::number of campaigns to create')}
            >
              <Form.Input
                type='number'
                min='0'
                value={form.get('n')}
                onChange={(e, { value }) => actions.mergeInForm({ n: value })}
              />
            </this.Section>
          </Fragment>
        )}

        <this.Section
          id='title'
          title={t('Campaigns::Summary::Title')}
          subtitle={t('Campaigns::Summary::campaign name')}
        >
          <Form.Input value={form.get('title')} onChange={(e, { value }) => actions.mergeInForm({ title: value })} />
        </this.Section>

        <this.Section
          id='description'
          title={t('Campaigns::Summary::Description')}
          subtitle={t('Campaigns::Summary::small campaign description')}
        >
          <Form.TextArea
            value={form.get('description')}
            onChange={(e, { value }) => actions.mergeInForm({ description: value })}
          />
        </this.Section>

        <this.Section
          id='user_id'
          title={t('Campaigns::Summary::User')}
          subtitle={t('Campaigns::Summary::owner of the campaign')}
        >
          <Form.Input value={form.get('user_id')} disabled />
        </this.Section>

        <this.Section
          id='category'
          title={t('Campaigns::Summary::Category')}
          subtitle={t('Campaigns::Summary::campaign category')}
        >
          <Form.Select
            value={form.get('category')}
            onChange={(e, { value }) => actions.mergeInForm({ category: value })}
            search
            options={getTranslatedOptions(t, campaignCategoryOptions)}
          />
        </this.Section>

        <this.Section id='code' title={t('Campaigns::Summary::Code')} subtitle={t('Campaigns::Summary::coupons code')}>
          <Form.Input
            value={form.get('code') || ''}
            onChange={(e, { value }) => actions.mergeInForm({ code: value || null })}
            placeholder='Only if generating 1 campaign, if empty, will be random'
          />
        </this.Section>

        <this.Section
          id='begin_at'
          title={t('Campaigns::Summary::Start date')}
          subtitle={t('Campaigns::Summary::when does the campaign start ?')}
        >
          <Form.Input
            value={form.get('begin_at')}
            onChange={(e, { value }) => actions.mergeInForm({ begin_at: value })}
            placeholder='YYYY-MM-DD'
          />
        </this.Section>

        <this.Section
          id='end_at'
          title={t('Campaigns::Summary::End date')}
          subtitle={t('Campaigns::Summary::when does the campaign end ?')}
        >
          <Form.Input
            value={form.get('end_at')}
            onChange={(e, { value }) => actions.mergeInForm({ end_at: value })}
            placeholder='YYYY-MM-DD'
          />
        </this.Section>

        <this.Section
          id='type'
          title={t('Campaigns::Summary::Type')}
          subtitle={t('Campaigns::Summary::type of discount')}
        >
          <Form.Select
            value={form.get('type')}
            onChange={this.onTypeChange}
            options={getTranslatedOptions(t, campaignTypeOptions)}
          />
        </this.Section>

        <this.Section
          id='currency'
          title={t('Campaigns::Summary::Currency')}
          subtitle={t('Campaigns::Summary::campaign currency')}
        >
          <Form.Select
            value={form.get('currency_id')}
            onChange={(e, { value }) => actions.mergeInForm({ currency_id: value })}
            options={currenciesOpts}
          />
        </this.Section>

        <this.Section
          id='discount'
          title={t('Campaigns::Summary::Discount')}
          subtitle={t(
            'Campaigns::Summary::campaign discount in cents (ie: 1000 => 10€) or in percentage (ie: 10 => 10%)',
          )}
        >
          <Form.Input
            value={form.get('discount')}
            onChange={(e, { value }) => actions.mergeInForm({ discount: value })}
          />
        </this.Section>

        <this.Section
          id='usage'
          title={t('Campaigns::Summary::Usage')}
          subtitle={t('Campaigns::Summary::campaign usage: booking or meal')}
        >
          <Form.Select
            value={form.get('usage')}
            onChange={(e, { value }) => actions.mergeInForm({ usage: value })}
            options={getTranslatedOptions(t, campaignUsageOptions)}
          />
        </this.Section>

        <this.Section
          id='max_usage_limit'
          title={t('Campaigns::Summary::Max usage')}
          subtitle={t('Campaigns::Summary::how many time the code can be used')}
        >
          <Form.Input
            value={form.get('max_usage_limit')}
            onChange={(e, { value }) => actions.mergeInForm({ max_usage_limit: value || 1 })}
            type='number'
            min='1'
          />
        </this.Section>

        {/* <this.Section id='min_usage_limit' title='Min usage'>
          <Form.Input
            value={form.get('min_usage_limit') || ''}
            onChange={(e, { value }) => actions.mergeInForm({ min_usage_limit: value || null })}
            type='number'
            min='0'
          />
        </this.Section> */}

        <this.Section
          id='max_guest_limit'
          title={t('Campaigns::Summary::Max guests')}
          subtitle={t('Campaigns::Summary::maximum amount of guests')}
        >
          <Form.Input
            value={form.get('max_guest_limit') || ''}
            onChange={(e, { value }) => actions.mergeInForm({ max_guest_limit: value || null })}
            type='number'
            min='0'
          />
        </this.Section>

        <this.Section
          id='min_guest_limit'
          title={t('Campaigns::Summary::Min guests')}
          subtitle={t('Campaigns::Summary::minimum amount of guests')}
        >
          <Form.Field>
            <Form.Input
              value={form.get('min_guest_limit') || ''}
              onChange={(e, { value }) => actions.mergeInForm({ min_guest_limit: value || null })}
              type='number'
              min='0'
            />
          </Form.Field>
        </this.Section>

        <this.Section
          id='max_price_limit'
          title={t('Campaigns::Summary::Max price')}
          subtitle={t('Campaigns::Summary::maximum price the coupon code can be used on in cents (ie: 1000 => 10€)')}
        >
          <Form.Input
            value={form.get('max_price_limit') || ''}
            onChange={(e, { value }) => actions.mergeInForm({ max_price_limit: value || null })}
            type='number'
            min='0'
          />
        </this.Section>

        <this.Section
          id='min_price_limit'
          title={t('Campaigns::Summary::Min price')}
          subtitle={t('Campaigns::Summary::minimum price the coupon code can be used on in cents (ie: 1000 => 10€)')}
        >
          <Form.Input
            value={form.get('min_price_limit') || ''}
            onChange={(e, { value }) => actions.mergeInForm({ min_price_limit: value || null })}
            type='number'
            min='0'
          />
        </this.Section>

        <this.Section id='first_time' title={t('Campaigns::Summary::First time')}>
          <Form.Radio
            toggle
            label={t('Campaigns::Summary::The coupon will work for the first booking only')}
            checked={form.get('first_time')}
            onChange={(e, { checked }) => actions.mergeInForm({ first_time: checked })}
          />
        </this.Section>

        <this.Section id='free_only' title={t('Campaigns::Summary::Free only')}>
          <Form.Radio
            toggle
            label={t('Campaigns::Summary::The coupon will work only it the end result is a free booking')}
            checked={form.get('free_only')}
            onChange={(e, { checked }) => actions.mergeInForm({ free_only: checked })}
          />
        </this.Section>

        <this.Section id='active' title={t('Campaigns::Summary::Active')}>
          <Form.Radio
            toggle
            label={t('Campaigns::Summary::is the campaign active ?')}
            checked={form.get('active')}
            onChange={(e, { checked }) => actions.mergeInForm({ active: checked })}
          />
        </this.Section>

        <this.Section
          id='events'
          title={t('Campaigns::Summary::Events')}
          subtitle={t('Campaigns::Summary::Restrict coupon usage to a selected list of events')}
        >
          <SearchModal title={t('Campaigns::Summary::Search Events')} handleSubmit={this.handleSubmitModal}>
            <EventSearch eventIds={form.getIn(['event_ids'])} />
          </SearchModal>
        </this.Section>
      </Form>
    )
  }
}

export const CampaignForm = withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(_CampaignForm))
