// libraries
import React, { useEffect } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation, Trans } from 'react-i18next'
// redux
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from 'redux/entities/selectors'
import { fetchUser } from 'redux/entities/actions'
// components
import { Link } from 'react-router'
import { Table, Button, Popup, Label, Rating, List } from 'semantic-ui-react'
import moment from 'moment'
import { culinaryLevelOrder } from 'helpers/hostApplications'
import { upperFirst, truncate } from 'lodash'
import { AssignmentDropdown } from './AssignmentDropdown'
import { ExperienceIdWithCheckMark } from 'components/labels'
import { HostApplicationStatus } from './HostApplicationStatus'

const { Row, Cell } = Table

const getLastComment = (followups) => {
  if (!followups || followups.length === 0) return

  const lastFollowup = followups
    .filter((followup) => followup && followup.get('action') === 'comment')
    .sort((a, b) => a && b && a.get('created_at') - b.get('created_at'))
    .last()

  return (
    lastFollowup && (
      <List>
        <List.Item>
          <span>
            <Trans
              i18nKey={__('HostApplications::<label>{{userName}}</label> on {{date}}')}
              values={{
                userName: lastFollowup.get('user_fullname'),
                date: moment(lastFollowup.get('created_at')).format('lll'),
              }}
              components={{ label: <Label circular color='blue' /> }}
            />
          </span>
        </List.Item>
        <List.Item>{lastFollowup.get('comment') && lastFollowup.get('comment').toLowerCase()}</List.Item>
      </List>
    )
  )
}

const getExperienceType = (experience) =>
  upperFirst(
    experience.get('experience_type') === 'other' ? experience.get('other_type') : experience.get('experience_type'),
  )

const getCulinaryExperience = (host) => {
  const culinaryExpertise = host.get('culinary_expertise')
  const culinaryExperience = host.get('culinary_experience')

  if (culinaryExpertise)
    return (
      <Label basic>
        <b>{culinaryExpertise}</b>
      </Label>
    )

  if (!culinaryExperience || culinaryExperience.size === 0) return

  const sortedExperiences = culinaryExperience.sort(
    (exp1, exp2) => culinaryLevelOrder.indexOf(exp1.get('level')) - culinaryLevelOrder.indexOf(exp2.get('level')),
  )
  const experienceToDisplay = sortedExperiences.first()
  const otherExperiences = sortedExperiences.size > 1 && sortedExperiences.slice(1, sortedExperiences.size)

  return (
    <span>
      <Label basic>
        <b>{getExperienceType(experienceToDisplay)}</b>
        <Label.Detail>{experienceToDisplay.get('level')}</Label.Detail>
      </Label>
      {otherExperiences && (
        <Popup
          trigger={<Label>{`+${culinaryExperience.size - 1}`}</Label>}
          content={
            <List>
              {otherExperiences.map((experience) => (
                <List.Item key={`${getExperienceType(experience)}: ${experience.get('level')}`}>
                  <b>{getExperienceType(experience)}</b>
                  {`: ${experience.get('level')}`}
                </List.Item>
              ))}
            </List>
          }
          position='top right'
        />
      )}
    </span>
  )
}

export function HostApplicationRow({
  hostApplication,
  hostApplication: { host, sample_experience: sampleExperience, user, metadata, admin, event },
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const applicant = useSelector((state) => getUser(state, { id: user.id }))

  // user's payload from hostApplication does not contain the locality and country
  // needed to be shown in the <UserCard />
  useEffect(() => {
    dispatch(fetchUser(user.id))
  }, [dispatch, user.id])

  return (
    <Row negative={hostApplication.toReviewOrder > 0}>
      <Cell>{moment(metadata.get('created_at')).format('lll')}</Cell>
      <Cell>{moment(metadata.get('submitted_at')).format('lll')}</Cell>
      <Cell>{moment(metadata.get('updated_at')).format('lll')}</Cell>
      <Cell>
        <Link to={`/users/${user.id}`}>{`${user.id} - ${user.firstname} ${user.lastname}`}</Link>
      </Cell>
      <Cell>
        {metadata.get('event_id') && <ExperienceIdWithCheckMark event={event || { id: metadata.get('event_id') }} />}
      </Cell>
      <Cell>{applicant.country}</Cell>
      <Cell>{applicant.locality}</Cell>
      <Cell>{sampleExperience.get('experience_type')}</Cell>
      <Cell>{getCulinaryExperience(host)}</Cell>
      <Cell>
        {sampleExperience.get('place') &&
          sampleExperience.get('place').get('WOW_factor') &&
          sampleExperience
            .get('place')
            .get('WOW_factor')
            .map((wowFactor) => (
              <Label basic key={wowFactor} horizontal>
                {truncate(upperFirst(wowFactor))}
              </Label>
            ))}
      </Cell>
      <Cell>
        <AssignmentDropdown hostApplication={hostApplication} />
      </Cell>
      <Cell>{getLastComment(admin.get('followups'))}</Cell>
      <Cell>
        <HostApplicationStatus status={metadata.get('status')} completionRate={metadata.get('completion_rate')} />
      </Cell>
      <Cell>
        <Rating icon='star' maxRating={5} defaultRating={admin.get('application_rate')} disabled />
      </Cell>

      <Cell textAlign='center'>
        <Popup
          trigger={
            <Button primary circular as={Link} to={`/host-applications/edit/${hostApplication.id}`} icon='edit' />
          }
          content={t('HostApplications::Review Host Application')}
          inverted
          position='top right'
        />
      </Cell>
    </Row>
  )
}

HostApplicationRow.propTypes = {
  hostApplication: PropTypes.shape({
    id: PropTypes.string,
    toReviewOrder: PropTypes.number,
    sample_experience: PropTypes.immutable.map,
    host: PropTypes.immutable.map,
    user: PropTypes.immutable.record,
    admin: PropTypes.immutable.map,
    metadata: PropTypes.immutable.map,
    place: PropTypes.immutable.record,
    event: PropTypes.immutable.record,
  }).isRequired,
}
