import { schema } from 'normalizr'
import { Record, List } from 'immutable'
import { references } from './references'
import { bookings } from './bookings'
import { followups } from './followups'
import { requests } from './requests'
import { currencies } from './statics'

const _schema = new schema.Entity('partnerDemands', {
  bookings: [bookings.schema],
  currency: currencies.schema,
  reference: references.schema,
  requests: [requests.schema],
  followups: [followups.schema],
})

const _Record = Record({
  age_interval: undefined,
  bookings: new List(),
  currency: new currencies.Record(),
  country_iso: undefined,
  begins_at: undefined,
  date: undefined,
  event_type: undefined,
  followups: new List(),
  id: undefined,
  invoice_id: undefined,
  locality: undefined,
  price: undefined,
  reference: new references.Record(),
  requests: new List(),
  seats: undefined,
  free_seats: undefined,
  status: undefined,
  updated_at: undefined,
})

export const partnerDemands = {
  schema: _schema,
  Record: _Record,
}
