// Libraries
import React, { Fragment, PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'helpers/proptypes'
import moment from 'moment'
import { omitBy } from 'lodash'
import { withTranslation } from 'react-i18next'
// Components
import { Form, Button, Divider, Message } from 'semantic-ui-react'
import { DateSection, PricingSection, ToggleSection, UserSection, EventSection } from './components'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { toast } from 'react-toastify'
// Helpers
import { isDateTodayOrLater } from 'helpers/dates'
// Redux
import { scheduleEvent } from 'redux/entities/actions'
import { getCreatingScheduleError, getSchedule } from 'redux/entities/selectors'

import './form.css'
import { isInvalid } from '../../../helpers/forms'

const mapStateToProps = (state, props) => ({
  fromStore: {
    errors: {
      schedule: getCreatingScheduleError(state),
    },
    getSchedule: (date) => getSchedule(state, date),
  },
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    scheduleEvent: (date, payload) => dispatch(scheduleEvent(date, payload)),
  },
})

class _ScheduleEventForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    fromStore: PropTypes.shape({
      getSchedule: PropTypes.func.isRequired,
      errors: PropTypes.shape({
        schedule: PropTypes.object,
      }).isRequired,
    }).isRequired,
    actions: PropTypes.shape({
      scheduleEvent: PropTypes.func.isRequired,
    }).isRequired,
    btnSubmitTitle: PropTypes.string.isRequired,
  }

  state = {
    error: undefined,
    submitted: false,
    host: undefined,
    event: undefined,
    schedule: undefined,
    date: undefined,
    from: '19:00',
    to: '22:00',
    minSeats: 1,
    maxSeats: 1,
    price: undefined,
    restrictions: [],
    isInstantBooking: true,
    isPrivateEvent: true,
    additionalInfo: undefined,
  }

  componentDidUpdate(prevProps) {
    const { submitted, event } = this.state
    const {
      t,
      fromStore: { errors },
      handleSubmit,
    } = this.props
    if (submitted && errors.schedule === undefined) {
      toast.success(
        `${t('ToastNotification::{{eventTitle}} has been correctly scheduled', { eventTitle: event.title })} 👍`,
        {
          type: toast.TYPE.SUCCESS,
        },
      )

      this.setState({ submitted: false })

      handleSubmit()
    }
  }

  handleSubmit = async () => {
    const { event, date, host, minSeats, maxSeats, isInstantBooking, isPrivateEvent, from, to, price } = this.state
    const { actions } = this.props

    this.setState({ submitted: false })

    const schedule = omitBy(
      {
        event,
        date: moment(date).format('YYYY-MM-DD'),
        min_seats: minSeats,
        max_seats: maxSeats,
        begins_at: from,
        ends_at: to,
        price,
        instant_booking: isInstantBooking,
      },
      isInvalid,
    )

    Object.assign(schedule, isPrivateEvent ? { privatizer_id: host.id } : {})

    await actions.scheduleEvent(schedule)

    this.setState({ submitted: true })
  }

  render() {
    const {
      host,
      event,
      maxSeats,
      minSeats,
      date,
      from,
      to,
      price,
      isInstantBooking,
      isPrivateEvent,
      schedule,
    } = this.state
    const {
      t,
      fromStore: { errors, getSchedule },
      btnSubmitTitle,
    } = this.props
    const canSubmit =
      host && event && minSeats && maxSeats && date && from && to && price >= 0 && isDateTodayOrLater(date)
    const isOverrideDisabled = schedule && schedule.booking_ids.size > 0

    return (
      <Form onSubmit={this.handleSubmit}>
        <UserSection
          placeholder={t('EventsCalendar::Host name, email or id')}
          inputLabel={t('EventsCalendar::Host')}
          onUserChange={(host) => host && this.setState({ host })}
          isHost
          user={host}
        />

        <EventSection
          host={host}
          isDisabled={!host}
          onEventChange={(event) =>
            event &&
            this.setState({
              event,
              from: event.begins_at,
              to: event.ends_at,
              minSeats: event.min_seats,
              maxSeats: event.max_seats,
            })
          }
        />

        <Divider />

        <DateSection
          from={from}
          to={to}
          date={date}
          handleChange={(value) => this.setState(value)}
          handleDateChange={(dte) => this.setState({ date: dte, schedule: getSchedule(dte) })}
          isDisabled={!event}
          eventId={event && event.id}
          isTimeDisabled={isOverrideDisabled}
          showFromTime
          showToTime
        />

        <Divider />

        {event && date && (
          <Fragment>
            <Form.Group widths='equal' className='__item'>
              <Form.Field width={1}>
                <label>{t('EventsCalendar::Min seats')}</label>
                <Form.Input
                  type='number'
                  min='1'
                  placeholder={t('EventsCalendar::How many guests?')}
                  value={minSeats}
                  onChange={(e, { value }) => this.setState({ minSeats: parseInt(value) })}
                />
              </Form.Field>

              <Form.Field width={1}>
                <label>{t('EventsCalendar::Max seats')}</label>
                <Form.Input
                  type='number'
                  min='1'
                  placeholder={t('EventsCalendar::How many guests?')}
                  value={maxSeats}
                  onChange={(e, { value }) => this.setState({ maxSeats: parseInt(value) })}
                />
              </Form.Field>
            </Form.Group>

            <PricingSection
              currency={host.currency}
              date={date}
              event={event}
              handleChange={(value) => value && this.setState(value)}
              isPriceDisabled={isOverrideDisabled}
              price={price}
              seats={1}
              user={host}
            />
          </Fragment>
        )}

        {price < 0 && <Message negative>{t('EventsCalendar::Price should not be negative')}</Message>}

        <Divider />

        <ToggleSection
          handleChange={(value) => this.setState(value)}
          isDisabled={!event}
          isPrivateEvent={isPrivateEvent}
          isInstantBooking={isInstantBooking}
          showInstantBooking
          showPrivateEvent
        />

        <Divider />

        <Form.Field className='__btnSubmit'>
          <Button primary size='big' type='submit' disabled={!canSubmit} content={btnSubmitTitle.toUpperCase()} />
        </Form.Field>
        <ApiErrorMessage error={errors.schedule} modal />
      </Form>
    )
  }
}

export const ScheduleEventForm = withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(_ScheduleEventForm),
)
