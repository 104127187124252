// libs
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
// helpers
import PropTypes from 'helpers/proptypes'
// components
import { Form } from 'semantic-ui-react'
// styles
import './index.css'

// redux
import { createForm, removeForm, mergeInForm } from 'redux/forms/actions'
import { getForm } from 'redux/forms/reducer'
import { creatingTag, updatingTag } from 'redux/entities/selectors'

const mapStateToProps = (state, props) => ({
  fromStore: {
    form: getForm(state, 'tag'),
    updating: props.tag && updatingTag(state, props.tag.id),
    creating: creatingTag(state),
  },
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    createForm: ({ initialState }) => dispatch(createForm({ formName: 'tag', initialState })),
    removeForm: () => dispatch(removeForm('tag')),
    mergeInForm: (formData) => dispatch(mergeInForm({ formName: 'tag', value: formData })),
  },
})

export class TagForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    tag: PropTypes.immutable.record,
    fromStore: PropTypes.shape({
      form: PropTypes.object,
      updating: PropTypes.bool,
      creating: PropTypes.bool,
    }).isRequired,
    actions: PropTypes.shape({
      createForm: PropTypes.func.isRequired,
      removeForm: PropTypes.func.isRequired,
      mergeInForm: PropTypes.func.isRequired,
    }).isRequired,
  }

  UNSAFE_componentWillMount() {
    const { tag, actions } = this.props
    const initialState = { title: tag && tag.title }
    actions.createForm({ initialState })
  }

  UNSAFE_componentWillReceiveProps({ tag, actions }) {
    if (tag && (!this.props.tag || tag.id !== this.props.tag.id)) actions.mergeInForm({ title: tag.title })
  }

  componentWillUnmount() {
    this.props.actions.removeForm()
  }

  render() {
    const {
      t,
      fromStore: { form },
      actions,
    } = this.props
    return (
      <Form className='vz-tag-add-form'>
        <section id='title'>
          <h3 className='section-title'>
            {t('Tags::Summary::Title')}
            <small className='vz-tag-add-small-subtitle'>{t("Tags::Summary::Tag's title")}</small>
          </h3>
          <Form.Input
            value={form.get('title', '')}
            onChange={(e, { value }) => actions.mergeInForm({ title: value })}
          />
        </section>
      </Form>
    )
  }
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(TagForm))
