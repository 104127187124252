import settings from 'settings'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getUserGallery } from 'redux/entities/selectors'
import { Segment, Header, Card } from 'semantic-ui-react'
import { LazyImage } from '@vizeat/components/es6/components/LazyImage'
import FocusedFile from 'pages/gallery/FocusedFile'

const mapStateToProps = (state, props) => ({
  fromStore: {
    getGallery: (userId) => getUserGallery(state, { id: userId }),
  },
})

function _PhotoSection({ hostApplication: { user }, fromStore: { getGallery } }) {
  const { t } = useTranslation()
  const userGallery = getGallery(user.get('id'))
  const [focusedFilePath, setFocusedFilePath] = useState()

  return userGallery ? (
    <Segment>
      <Header as='h3'>{t('HostApplications::Summary::Photos')}</Header>
      <Card.Group centered itemsPerRow={4}>
        {[...userGallery.values()].map((image) => (
          <Card key={image.id}>
            <LazyImage
              src={settings.globalUrlFactory.screen.files.transform(
                image.id,
                '/-/scale_crop/200x200/center/-/progressive/yes',
              )}
              onClick={() => setFocusedFilePath(image.path)}
            />
          </Card>
        ))}
      </Card.Group>

      {focusedFilePath && <FocusedFile path={focusedFilePath} onFocus={() => setFocusedFilePath()} />}
    </Segment>
  ) : null
}

_PhotoSection.propTypes = {
  hostApplication: PropTypes.shape({
    user: PropTypes.object,
  }).isRequired,
  fromStore: PropTypes.shape({
    getGallery: PropTypes.object,
  }).isRequired,
}

export const PhotoSection = connect(mapStateToProps)(_PhotoSection)
