// Libs
import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import moment from 'moment'
import { get } from 'lodash'
// Components
import { Checkbox, Dropdown, Form, Table, List, Button, Popup, Modal } from 'semantic-ui-react'
import { Link } from 'react-router'
import { FormattedFollowup } from 'components/followups'
// Redux
import {
  getAdmins,
  getPartnerDemandBookingsValidatedSeats,
  getPartnerDemandBookingsEwFreeSeats,
  getPartnerDemandBookingsHostFreeSeats,
} from 'redux/entities/selectors'
// Helpers
import { getLastAssigne } from 'helpers/followups'
import { getFormattedAdmins } from 'helpers/admins'
import { getTranslatedOptions } from 'helpers/options'
import { statusOptions } from 'helpers/partnerDemands'

const { Row, Cell } = Table

const mapStateToProps = (state, props) => ({
  fromStore: {
    admins: getAdmins(state),
    validatedSeats: getPartnerDemandBookingsValidatedSeats(state, { id: props.partnerDemand.id }),
    ewFreeSeats: getPartnerDemandBookingsEwFreeSeats(state, { id: props.partnerDemand.id }),
    hostFreeSeats: getPartnerDemandBookingsHostFreeSeats(state, { id: props.partnerDemand.id }),
  },
})

function getBackgroundColor(validatedSeats, totalSeats) {
  switch (true) {
    case validatedSeats > 0 && validatedSeats < totalSeats:
      return { background: '#ffe9a878' }
    case validatedSeats === totalSeats:
      return { background: '#c1e6bc' }
    case validatedSeats > totalSeats:
      return { background: '#e29292' }
    default:
      return undefined
  }
}

function _PartnerDemandRow({
  fromStore: { admins, validatedSeats, ewFreeSeats, hostFreeSeats },
  onAssignTask,
  onSelect,
  onUpdate,
  onDelete,
  partnerDemand,
  selected,
}) {
  const { t } = useTranslation()
  const [showConfirmDelete, setShowConfirmDelete] = React.useState(false)
  const seatsToPayToHost = validatedSeats - hostFreeSeats > 0 ? validatedSeats - hostFreeSeats : ''
  const seatsToPayToClient =
    validatedSeats - hostFreeSeats - ewFreeSeats > 0 ? validatedSeats - hostFreeSeats - ewFreeSeats : ''
  return (
    <Row style={getBackgroundColor(validatedSeats, partnerDemand.seats)}>
      <Cell>
        <Link to={`partner-demands/${partnerDemand.id}`}>{partnerDemand.id}</Link>
      </Cell>
      <Cell>{moment.utc(partnerDemand.updated_at).format('lll')}</Cell>
      <Cell>{get(partnerDemand, ['reference', 'partner', 'organization', 'name'])}</Cell>
      <Cell>{get(partnerDemand, ['reference', 'partner', 'name'])}</Cell>
      <Cell>{partnerDemand.locality}</Cell>
      <Cell>{partnerDemand.date && moment(partnerDemand.date).format('ll')}</Cell>
      <Cell>{get(partnerDemand, ['reference', 'code'])}</Cell>
      <Cell>{partnerDemand.event_type}</Cell>
      <Cell>{`${validatedSeats}/${partnerDemand.seats}`}</Cell>
      <Cell>
        <List>
          {hostFreeSeats > 0 && (
            <List.Item>{t('PartnerDemands::Host: {{hostFreeSeats)}}', { hostFreeSeats })}</List.Item>
          )}
          {ewFreeSeats > 0 && <List.Item>{t('PartnerDemands::EW: {{ewFreeSeats}}', { ewFreeSeats })}</List.Item>}
        </List>
      </Cell>
      <Cell>
        <List>
          {seatsToPayToHost && (
            <List.Item>{t('PartnerDemands::Host: {{seatsToPayToHost}}', { seatsToPayToHost })}</List.Item>
          )}
          {seatsToPayToClient && (
            <List.Item>{t('PartnerDemands::Client: {{seatsToPayToClient}}', { seatsToPayToClient })}</List.Item>
          )}
        </List>
      </Cell>
      <Cell>
        <Form.Select
          value={partnerDemand.status}
          options={getTranslatedOptions(t, statusOptions)}
          onChange={(e, { value }) => onUpdate({ status: value })}
        />
      </Cell>
      <Cell>
        <FormattedFollowup followups={partnerDemand.followups} />
      </Cell>
      <Cell>
        <Dropdown
          placeholder={t('Inputs::Select...')}
          fluid
          search
          selection
          value={getLastAssigne(partnerDemand.followups)}
          options={getFormattedAdmins(admins)}
          onChange={onAssignTask}
        />
      </Cell>
      <Cell textAlign='center'>
        <Popup
          content={t('PartnerDemands::Delete')}
          trigger={
            <Button
              primary
              circular
              disabled={partnerDemand.get('bookings').size || partnerDemand.get('requests').size}
              onClick={() => setShowConfirmDelete(true)}
              icon='trash'
            />
          }
        />
      </Cell>
      <Cell className='select'>
        <Checkbox onChange={(e, { checked }) => onSelect(checked, partnerDemand.id)} checked={selected} />
      </Cell>
      <Modal open={showConfirmDelete} closeIcon onClose={() => setShowConfirmDelete(false)}>
        <Modal.Header>{t('PartnerDemands::Are you sure you want to delete this partner demand?')}</Modal.Header>
        <Modal.Actions>
          <Button
            positive
            basic
            onClick={() => {
              onDelete()
              setShowConfirmDelete(false)
            }}
            content={t('PartnerDemands::Yes')}
          />
        </Modal.Actions>
      </Modal>
    </Row>
  )
}

_PartnerDemandRow.propTypes = {
  fromStore: PropTypes.shape({
    admins: PropTypes.immutable.map.isRequired,
    validatedSeats: PropTypes.number.isRequired,
    ewFreeSeats: PropTypes.number,
    hostFreeSeats: PropTypes.number,
  }).isRequired,
  onAssignTask: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  partnerDemand: PropTypes.immutable.record.isRequired,
  selected: PropTypes.bool.isRequired,
}

export const PartnerDemandRow = connect(mapStateToProps)(_PartnerDemandRow)
