import { getPartner } from './partners'

import {
  getEntities,
  getEntity,
  getEntityCount,
  isFetching,
  isUpdating,
  isCreating,
  getError,
  getEntityTotal,
  getEntityOrder,
} from './_utils'

export const getCampaigns = getEntities('campaigns')
export const getCampaignsCount = getEntityCount('campaigns')
export const getCampaignsTotal = getEntityTotal('campaigns')
export const getCampaignsOrder = getEntityOrder('campaigns')
export const getCampaign = (state, { id }) => {
  const campaign = getEntity('campaigns')(state, { id })
  return campaign.set('partner', getPartner(state, { id: campaign.partner_id || campaign.partner }))
}

export const getCampaignsList = (state) => getCampaignsOrder(state).map((id) => getCampaign(state, { id }))

export const fetchingCampaigns = isFetching('/api/campaigns')
export const fetchingCampaign = (state, id) => isFetching(`/api/campaigns/${id}`)(state)
export const updatingCampaign = (state, id) => isUpdating(`/api/campaigns/${id}`)(state)
export const creatingCampaigns = isCreating('/api/campaigns')

export const getCampaignsError = getError('/api/campaigns')
export const getCampaignError = (state, id) => getError(`/api/campaigns/${id}`)(state)
