// Libs
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
// Components
import { Modal, Button } from 'semantic-ui-react'

export const SearchModal = ({ title, children, handleSubmit }) => {
  const [t] = useTranslation()
  const [openModal, setOpenModal] = useState(false)

  const onSubmit = () => {
    handleSubmit()
    setOpenModal(false)
  }

  return (
    <div className='SearchModal'>
      <Button primary onClick={() => setOpenModal(true)}>
        {title}
      </Button>
      <Modal open={openModal} size='large' closeOnDimmerClick={false} closeIcon onClose={() => setOpenModal(false)}>
        <Modal.Header>{title || t('SearchModal::Select')}</Modal.Header>

        <Modal.Content>
          <Modal.Description>{children}</Modal.Description>
        </Modal.Content>

        <Modal.Actions>
          <Button type='button' onClick={() => setOpenModal(false)}>
            {t('SearchModal::Cancel')}
          </Button>
          <Button primary onClick={onSubmit}>
            {t('SearchModal::Done')}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  )
}

SearchModal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}
