// libs
import React, { useState } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
// components
import { IconButton } from './IconButton'

export const CopyToClipboardButton = ({ text, size, color, style, tooltipClassName }) => {
  const { t } = useTranslation()
  const [isCopied, setIsCopied] = useState(false)
  const tooltipContent = isCopied ? __('Utils::Copied!') : __('Utils::Copy to clipboard')

  const handleCopy = async () => {
    await navigator.clipboard.writeText(text)
    setIsCopied(true)
  }

  const handleMouseLeave = () => {
    setIsCopied(false)
  }

  if (text.trim().length === 0) return null

  return (
    <IconButton
      name='copy'
      style={{ position: 'absolute', ...style }}
      size={size}
      onClick={handleCopy}
      onMouseLeave={handleMouseLeave}
      tooltipContent={t(tooltipContent)}
      tooltipClassName={tooltipClassName}
      alignTooltipLeft
    />
  )
}

CopyToClipboardButton.propTypes = {
  text: PropTypes.string.isRequired,
  tooltipClassName: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.shape({}),
}

CopyToClipboardButton.defaultProps = {
  tooltipClassName: undefined,
  size: undefined,
  color: undefined,
  style: undefined,
}
