import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
// Components
import { Link } from 'react-router'
import { Grid, Loader, Button } from 'semantic-ui-react'
import { UsersIdForm } from './Form'
import { UserCard } from 'components/cards/UserCard'
// Redux
import { connectAs } from 'helpers/users'

import { fetchUser } from 'redux/entities/actions'
import { getUser, fetchingUser } from 'redux/entities/selectors'
const mapStateToProps = (state, props) => ({
  user: getUser(state, { id: props.params.id }),
  fetching: fetchingUser(state, props.params.id),
})

const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    fetchUser: () => dispatch(fetchUser(props.params.id)),
  },
})

export class UsersIdIndex extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    user: PropTypes.object,
    fetching: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    actions: PropTypes.object,
  }

  state = { initialized: false }
  UNSAFE_componentWillMount() {
    return this.props.actions.fetchUser().then(() => this.setState({ initialized: true }))
  }

  Spinner = (
    <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <Loader />
    </div>
  )

  render() {
    const { user, fetching, t } = this.props
    if (!this.state.initialized) {
      if (fetching) return this.Spinner
      else return null
    }

    return (
      <div style={{ width: '60vw', margin: 'auto' }}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={5}>
              <UserCard user={user} connectAs={() => connectAs(user.account.id)}>
                <div style={{ textAlign: 'center' }}>
                  <Button as={Link} primary basic compact to={{ pathname: `/users/${user.id}/bank-accounts` }}>
                    {t('UserCard::View payout details')}
                  </Button>
                </div>
              </UserCard>
            </Grid.Column>

            <Grid.Column width={9}>
              <UsersIdForm user={user} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(UsersIdIndex))
