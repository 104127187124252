// libs
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { range } from 'lodash'
// components
import { Menu } from 'semantic-ui-react'

export default class Pagination extends PureComponent {
  static propTypes = {
    previous: PropTypes.bool,
    next: PropTypes.bool,
    page: PropTypes.number.isRequired,
    buttonsNb: PropTypes.number,
    maxPage: PropTypes.number,
    onSelect: PropTypes.func.isRequired, // (page) => {...}
  }

  static defaultProps = {
    buttonsNb: 5,
  }

  handleSelect (page) {
    this.props.onSelect(page)
  }

  handlePrevious () {
    if (this.props.page === 1) return
    this.handleSelect(this.props.page - 1)
  }

  handleNext () {
    if (this.props.page === this.props.maxPage) return
    this.handleSelect(this.props.page + 1)
  }

  render () {
    if (!this.props.maxPage) return null

    // the vars 'first' and 'last' are used to compute the _.range used to display a certain amount of buttons.
    // If the page is 10, then [first = 10 - buttonsNb/2] and [last = 10 + buttonsNb/2]
    let first = Math.max(1, this.props.page - parseInt(this.props.buttonsNb / 2))
    let last = Math.min(this.props.maxPage + 1, first + this.props.buttonsNb)

    if (this.props.maxPage > this.props.buttonsNb) {
      first = Math.min(first, this.props.maxPage - this.props.buttonsNb + 1)
    } else {
      last = this.props.maxPage + 1
    }

    return (
      <Menu pagination>
        {this.props.previous && (
          <Menu.Item onClick={this.handlePrevious.bind(this)} disabled={this.props.page === 1}>
            {'<'}
          </Menu.Item>
        )}

        {first > 1 && <Menu.Item onClick={this.handleSelect.bind(this, 1)}>{1}</Menu.Item>}

        {first > 2 && <Menu.Item disabled>...</Menu.Item>}

        {range(first, last).map((i) => (
          <Menu.Item key={i} active={this.props.page === i} onClick={this.handleSelect.bind(this, i)}>
            {i}
          </Menu.Item>
        ))}

        {last < this.props.maxPage - 1 && <Menu.Item disabled>...</Menu.Item>}

        {last < this.props.maxPage && (
          <Menu.Item onClick={this.handleSelect.bind(this, this.props.maxPage)}>{this.props.maxPage}</Menu.Item>
        )}

        {this.props.next && (
          <Menu.Item onClick={this.handleNext.bind(this)} disabled={this.props.page === this.props.maxPage}>
            {'>'}
          </Menu.Item>
        )}
      </Menu>
    )
  }
}
