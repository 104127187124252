import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import { settingsActions } from 'redux/entities/actions/appSettings'
import settings from 'settings'

const initialState = fromJS({
  locale: settings.defaultLocale,
})

const handlers = {
  [settingsActions.SET_LOCALE]: (state, action) => state.setIn(['locale'], action.locale),
}

export default createReducer(initialState, handlers)

export * from './selectors'
