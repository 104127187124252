import { getEntities, getEntity, isFetching, getError } from './_utils'
import { languages } from '../schemas/statics'

const sortTitleComparator = (t1, t2) => t1 > t2
// alcohols
export const getAlcohols = getEntities('alcohols')
export const getAlcohol = getEntity('alcohols')
export function getSortedAlcohols(state) {
  return getAlcohols(state)
    .toList()
    .sortBy((alcohol) => alcohol.title, sortTitleComparator)
}

// amenities
export const getAmenities = getEntities('amenities')
export const getAmenity = getEntity('amenities')
export function getSortedAmenities(state) {
  return getAmenities(state)
    .toList()
    .sortBy((amenity) => amenity.title, sortTitleComparator)
}

//  countries
export const getCountries = getEntities('countries')
export const getCountry = getEntity('countries')

// currencies
export const getCurrencies = getEntities('currencies')
export const getCurrency = getEntity('currencies')
export function getSortedCurrencies(state) {
  return getCurrencies(state)
    .toList()
    .sortBy((currency) => currency.title, sortTitleComparator)
}

// diets
export const getDiets = getEntities('diets')
export const getDiet = getEntity('diets')
export function getSortedDiets(state) {
  return getDiets(state)
    .toList()
    .sortBy((diet) => diet.title, sortTitleComparator)
}

// foods
export const getFoods = getEntities('foods')
export const getFood = getEntity('foods')
export function getSortedFoods(state) {
  return getFoods(state)
    .toList()
    .sortBy((food) => food.title, sortTitleComparator)
}

// languages
export const getLanguages = getEntities('languages')
export const getLanguage = getEntity('languages')
export function getSortedLanguages(state) {
  return getLanguages(state)
    .toList()
    .sortBy((language) => language.title, sortTitleComparator)
}
export function getLanguageByLocale(state, locale) {
  const emptyLanguage = new languages.Record()
  return getLanguages(state).find((language) => language.locale === locale, null, emptyLanguage)
}

export const fetchingAmenities = isFetching('/api/amenities')
export const fetchingAlcohols = isFetching('/api/alcohols')
export const fetchingCurrencies = isFetching('/api/currencies')
export const fetchingDiets = isFetching('/api/diets')
export const fetchingFoods = isFetching('/api/foods')
export const fetchingLanguages = isFetching('/api/languages')

export const fetchingStaticResource = (state) =>
  fetchingAmenities(state) ||
  fetchingAlcohols(state) ||
  fetchingCurrencies(state) ||
  fetchingDiets(state) ||
  fetchingFoods(state) ||
  fetchingLanguages(state)

export const getAmenitiesError = getError('/api/amenities')
export const getAlcoholsError = getError('/api/alcohols')
export const getCurrenciesError = getError('/api/currencies')
export const getDietsError = getError('/api/diets')
export const getFoodsError = getError('/api/foods')
export const getLanguagesError = getError('/api/languages')

export const getStaticsError = (state) =>
  getAmenitiesError(state) ||
  getAlcoholsError(state) ||
  getCurrenciesError(state) ||
  getDietsError(state) ||
  getFoodsError(state) ||
  getLanguagesError(state)
