import { schema } from 'normalizr'
import { files } from './files'
import { playlists } from './playlists'

const _schema = new schema.Entity('destinations', {
  cover: files.schema,
})

export const destinations = {
  schema: _schema,
  Record: playlists.Record, // pass on the same object
}
