// libs
import React, { Fragment } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
// components
import { Menu } from 'semantic-ui-react'
import { Link } from 'react-router'
import { Link as ScrollLink } from 'react-scroll'

const EditPageMenu = ({ goBack, listName, menuItems }) => {
  const [t] = useTranslation()

  return (
    <div>
      <Fragment>
        ←{' '}
        <Link onClick={goBack} to={listName && `/${listName}`} style={{ cursor: 'pointer' }}>
          {t('EditPageMenu::Back to the list')}
        </Link>
      </Fragment>

      <Menu pointing vertical secondary>
        {menuItems.map(({ text, value, isDisabled = false }) => (
          <Menu.Item key={value} as={ScrollLink} to={value} duration={500} smooth spy disabled={isDisabled}>
            {text}
          </Menu.Item>
        ))}
      </Menu>
    </div>
  )
}

EditPageMenu.propTypes = {
  goBack: PropTypes.func,
  listName: PropTypes.string,
  menuItems: PropTypes.array.isRequired,
}

EditPageMenu.defaultProps = {
  goBack: (f) => f,
}

export default EditPageMenu
