import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const bookingsActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'bookings' })
export const customBookingActions = { CLEAR_ALL: 'CLEAR_ALL' }

export const fetchBookings = ({ query } = {}) =>
  bookingsActions.fetch({
    url: '/api/bookings',
    query,
    schema: { bookings: [schemas.bookings] },
  })

export const fetchBooking = (id, { query } = {}) =>
  bookingsActions.fetch({
    url: `/api/bookings/${id}`,
    query,
    schema: { booking: schemas.bookings },
  })

export const createBooking = (payload) =>
  bookingsActions.create({
    url: '/api/bookings',
    payload,
    schema: { booking: schemas.bookings },
  })

export const createDirectBooking = (payload) =>
  bookingsActions.create({
    url: '/api/bookings/direct',
    payload,
    schema: { booking: schemas.bookings },
  })

export const updateBooking = (id, payload, { query } = {}) =>
  bookingsActions.update({
    url: `/api/bookings/${id}`,
    payload,
    query,
    schema: { booking: schemas.bookings },
  })

export const updateBookings = (payload, { query } = {}) =>
  bookingsActions.update({
    url: '/api/bookings',
    payload,
    query,
    schema: { bookings: [schemas.bookings] },
  })

export const clearAllBookings = () => (dispatch, getState) => {
  return dispatch({ type: customBookingActions.CLEAR_ALL })
}
