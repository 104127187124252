import settings from 'settings'
// Libraries
import React, { useEffect, useState } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { Table } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
// Components
import { List, Paginate } from 'components/lists'
// Helpers
import { getLocalityAndCountry } from 'helpers/places'
// Redux
import { fetchBookings } from 'redux/entities/actions'
import { fetchingBookings, getBookingsTotal, getBookingsError, getBookingsList } from 'redux/entities/selectors'

const { Row, Cell } = Table

const getHeaders = (t) => [
  { label: t('Bookings::BookingHistory::Id') },
  { label: t('Bookings::BookingHistory::Event') },
  { label: t('Bookings::BookingHistory::Locality') },
  { label: t('Bookings::BookingHistory::Date') },
  { label: t('Bookings::BookingHistory::Host') },
  { label: t('Bookings::BookingHistory::Guest') },
  { label: t('Bookings::BookingHistory::Coupon') },
  { label: t('Bookings::BookingHistory::Status') },
]

const mapStateToProps = (state) => ({
  error: getBookingsError(state),
  loading: fetchingBookings(state),
  bookings: getBookingsList(state),
  total: getBookingsTotal(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    loadPaginatedBookings: ({ offset, userId }) =>
      dispatch(fetchBookings({ query: { offset, size: settings.defaultPaginationSize, userId } })),
  },
})

const _BookingsHistory = ({ actions: { loadPaginatedBookings }, userId, total, loading, bookings }) => {
  const [offset, setOffset] = useState(0)
  const [t] = useTranslation()

  useEffect(() => {
    loadPaginatedBookings({ offset, userId: userId })
  }, [offset, loadPaginatedBookings, userId])

  return (
    <Paginate
      count={total}
      previous
      next
      loading={loading}
      ignoreLocation
      customOffset={offset}
      onPageChange={(offset) => setOffset(offset)}
    >
      <List striped celled headers={getHeaders(t)}>
        {bookings &&
          bookings
            .sort((b1, b2) => b2.created_at - b1.created_at)
            .map((booking, i) => (
              <Row key={i}>
                <Cell>{booking.id}</Cell>
                <Cell>
                  {booking.event.id} - {booking.event.title}
                </Cell>
                <Cell>{getLocalityAndCountry(booking.place)}</Cell>
                <Cell>{booking.date}</Cell>
                <Cell>
                  {booking.event.user.id} - {booking.event.user.firstname} {booking.event.user.lastname}
                </Cell>
                <Cell>
                  {booking.user.id} - {booking.user.firstname} {booking.user.lastname}
                </Cell>
                <Cell>{booking.coupon_code}</Cell>
                <Cell>{booking.status}</Cell>
              </Row>
            ))}
      </List>
    </Paginate>
  )
}

_BookingsHistory.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  error: PropTypes.shape({
    list: PropTypes.immutable.list,
    data: PropTypes.shape({
      message: PropTypes.string,
      stack_trace: PropTypes.any,
    }),
    booking: PropTypes.immutable.object,
    status: PropTypes.number,
    statusText: PropTypes.string,
  }),
  actions: PropTypes.shape({
    loadPaginatedBookings: PropTypes.func.isRequired,
  }).isRequired,
  bookings: PropTypes.immutable.list,
  total: PropTypes.number,
  loading: PropTypes.bool,
}

_BookingsHistory.defaultProps = {
  total: 0,
  loading: false,
  error: undefined,
  bookings: undefined,
}

export const BookingsHistory = connect(mapStateToProps, mapDispatchToProps)(_BookingsHistory)
