import { schema } from 'normalizr'
import { Record } from 'immutable'

const _schema = new schema.Entity('files')

const _Record = Record({
  id: undefined,
  account_id: undefined,
  uploadcare_id: undefined,
  path: '',
  attachable_id: undefined,
  attachable_type: '',
  attachable_title: undefined,
  category: undefined,
  order: undefined,
  show: undefined,
  ewid: undefined,
  created_at: undefined,
  updated_at: undefined,
  deleted_at: undefined,
})

export const files = {
  schema: _schema,
  Record: _Record,
}
