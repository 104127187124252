import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPlaylistsNextAllowedOrder } from 'redux/entities/selectors'
import { fetchPlaylists } from 'redux/entities/actions'

export function useNextAvailableOrder() {
  const dispatch = useDispatch()
  const order = useSelector(getPlaylistsNextAllowedOrder)

  // The next allowed order is set in the metadata of the response of playlists fetch request
  useEffect(() => {
    if (!order) dispatch(fetchPlaylists({ query: { size: 0 } }))
  }, [dispatch, order])

  return order
}
