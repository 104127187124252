// libs
import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
// components
import { Card, List, Button } from 'semantic-ui-react'
// styles
import './Card.css'

// redux
import { getForm } from 'redux/forms/reducer'
import { createTag, updateTag } from 'redux/entities/actions'
import { creatingTag, updatingTag, getTagError, getTagsError } from 'redux/entities/selectors'

const mapStateToProps = (state, props) => ({
  fromStore: {
    form: getForm(state, 'tag'),
    updating: props.tag && updatingTag(state, props.tag.id),
    creating: creatingTag(state),
    error: !props.tag ? getTagsError(state) : getTagError(state, props.tag.id),
  },
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    createTag: (payload) => dispatch(createTag(payload)),
    updateTag: (id, payload) => dispatch(updateTag(id, payload)),
  },
})

class TagCard extends PureComponent {
  static contextTypes = { router: PropTypes.object.isRequired }
  static propTypes = {
    t: PropTypes.func.isRequired,
    tag: PropTypes.immutable.record,
    onSaved: PropTypes.func, // ({ error: Immutable.Map, success: bool, title: String }) => {}
    children: PropTypes.any,
    fromStore: PropTypes.shape({
      form: PropTypes.immutable.map,
      updating: PropTypes.bool,
      creating: PropTypes.bool,
      error: PropTypes.immutable.map,
    }).isRequired,
    actions: PropTypes.shape({
      createTag: PropTypes.func.isRequired,
      updateTag: PropTypes.func.isRequired,
    }),
  }

  save = (e) => {
    if (e) e.preventDefault()
    const {
      tag,
      onSaved,
      actions,
      fromStore: { form },
    } = this.props
    const promise = !tag ? actions.createTag(form.toJS()) : actions.updateTag(tag.id, form.toJS())
    promise.then(({ payload: { data } }) => {
      const { error } = this.props.fromStore
      if (onSaved) onSaved({ error, success: !error, title: form.get('title') })
      if (!tag && !error) this.context.router.push(`/tags/edit/${data.result.tag}`)
    })
  }

  render() {
    const { t, fromStore } = this.props
    const { form, updating, creating } = fromStore

    return (
      <Card className='Tag-Card'>
        <Card.Content>
          <Card.Header>{t('Tags::Summary::Tag preview')}</Card.Header>
          <List bulleted>
            <List.Item>{t('Tags::Summary::Title: {{title}}', { title: form.get('title') })}</List.Item>
          </List>
        </Card.Content>

        <Card.Content>
          <div style={{ textAlign: 'center' }}>
            <Button primary onClick={this.save} loading={updating || creating}>
              {t('Tags::Summary::Save tag')}
            </Button>
          </div>
        </Card.Content>

        {this.props.children && <Card.Content>{this.props.children}</Card.Content>}
      </Card>
    )
  }
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(TagCard))
