// Libs
import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { withTranslation, Trans } from 'react-i18next'
// Components
import { Link } from 'react-router'
import { Grid, Segment } from 'semantic-ui-react'
// Redux
import { fetchRequest } from 'redux/entities/actions'
import { getRequest, getRequestStatus } from 'redux/entities/selectors'

const mapStateToProps = (state, props) => ({
  fromStore: {
    request: props.id && getRequest(state, { id: props.id }),
    getRequestStatus: (id) => getRequestStatus(state, { id }),
  },
})

const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    loadRequest: (id) => dispatch(fetchRequest(id)),
  },
})

class _Request extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    actions: PropTypes.shape({
      loadRequest: PropTypes.func,
    }).isRequired,
    fromStore: PropTypes.shape({
      request: PropTypes.immutable.record.isRequired,
      getRequestStatus: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    replies: undefined,
  }

  componentDidMount() {
    const { actions } = this.props
    if (this.props.id) {
      actions.loadRequest(this.props.id)
    }
  }

  render() {
    const {
      t,
      fromStore: { getRequestStatus, request },
    } = this.props
    return (
      <Segment>
        <Grid>
          {request && (
            <Grid.Row>
              <Grid.Column width={4}>
                <label>
                  <Trans
                    i18nKey={__('Bookings::Summary::Request::Request ID: <requestURL>{{requestId}}</requestURL>')}
                    values={{
                      requestId: request.id,
                    }}
                    components={{ requestURL: <Link to={`/requests?search=${request.id}`} /> }}
                  />
                </label>
              </Grid.Column>
              <Grid.Column width={12}>
                <label>
                  {t('Bookings::Summary::Request::Request status: {{status}}', {
                    status: getRequestStatus(request.id),
                  })}
                </label>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Segment>
    )
  }
}

export const Request = withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(_Request))
