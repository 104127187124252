import {
  getEntities,
  isFetching,
  isUpdating,
  isCreating,
  getError,
  getEntity,
  getEntityCount,
  getEntityOrder,
  getEntityTotal,
  getCreatingError,
} from './_utils'
import { getEvent } from './events'
import { getPartner } from './partners'
import { getUser } from './users'
import { getPlace } from './places'
import { getFollowup } from './followups'

export const getRequests = getEntities('requests')
export const getRequestsCount = getEntityCount('requests')
export const getRequest = (state, { id }) => {
  const request = getEntity('requests')(state, { id })

  return request
    .set('user', getUser(state, { id: request.user_id || request.user }))
    .set('event', getEvent(state, { id: request.event_id || request.event, date: request.date }))
    .set('partner', getPartner(state, { id: request.partner_id || request.partner }))
    .set('place', getPlace(state, { id: request.place_id || request.place }))
    .set('followups', request.followups ? request.followups.map((id) => getFollowup(state, { id })) : null)
}

export const getRequestStatus = (state, { id }) => {
  const {
    approved_at: approvedAt,
    canceled_by: canceledBy,
    expired_at: expiredAt,
    event,
    user,
  } = getRequest(state, { id })
  if (canceledBy) {
    if (canceledBy === user.id) {
      return user.partner ? 'Canceled by partner' : 'Canceled by guest'
    }
    if (canceledBy === event.user_id) return 'Canceled by host'
    return `Canceled by us`
  }
  if (expiredAt) return 'Expired'
  if (approvedAt) return 'Approved'
  return 'Pending'
}

export const creatingRequest = isCreating('/api/requests')
export const creatingDirectRequest = isCreating('/api/approved_requests')
export const fetchingRequests = isFetching('/api/requests')
export const fetchingRequest = (state, id) => isFetching(`/api/requests/${id}`)(state)
export const updatingRequest = (state, id) => isUpdating(`/api/requests/${id}`)(state)
export const updatingRequests = isUpdating('/api/requests')

export const getCreatingRequestError = getCreatingError('/api/requests')
export const getCreatingDirectRequestError = getCreatingError('/api/approved_requests')
export const getRequestsError = getError('/api/requests')
export const getRequestError = (state, id) => getError(`/api/requests/${id}`)(state)

export const getRequestsOrder = getEntityOrder('requests')
export const getRequestsTotal = getEntityTotal('requests')
export const getRequestsList = (state) => getRequestsOrder(state).map((id) => getRequest(state, { id }))
