// Libraries
import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
// Components
import { AddFollowupForm } from './AddFollowupForm'
import PropTypes from 'helpers/proptypes'
import { Modal } from 'semantic-ui-react'
// Redux
import { createFollowups } from 'redux/entities/actions'
import { getAdminProfile } from 'redux/reducers/admin/selectors'

const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    addFollowups: (payload) => dispatch(createFollowups(payload)),
  },
})

const mapStateToProps = (state, props) => ({
  fromStore: {
    adminProfile: getAdminProfile(state),
  },
})

export const _FollowupsModal = ({ open, onClose, followingId, followingType, actions, fromStore }) => {
  const [t] = useTranslation()
  return (
    <Modal open={open} onClose={onClose} size='small' closeIcon>
      <Modal.Header>{t('FollowupsModal::Add a followup')}</Modal.Header>
      <Modal.Content>
        <AddFollowupForm
          onSubmit={({ action, comment }) =>
            actions
              .addFollowups({
                followups: [
                  {
                    admin_id: fromStore.adminProfile.id,
                    following_id: followingId,
                    following_type: followingType,
                    action,
                    comment,
                  },
                ],
              })
              .then(onClose)
          }
          type={followingType}
          followingId={followingId}
        />
      </Modal.Content>
    </Modal>
  )
}

_FollowupsModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  actions: PropTypes.shape({
    addFollowups: PropTypes.func.isRequired,
  }).isRequired,
  fromStore: PropTypes.shape({
    adminProfile: PropTypes.immutable.record,
  }).isRequired,
  followingType: PropTypes.string.isRequired,
  followingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

_FollowupsModal.defaultProps = {
  open: true,
  onClose: undefined,
}

export const FollowupsModal = connect(mapStateToProps, mapDispatchToProps)(_FollowupsModal)
