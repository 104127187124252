// libs
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
// components
import { Form, Button } from 'semantic-ui-react'
import { SearchInput, SelectInput } from 'components/inputs'
import DatePicker from 'react-datepicker'
import { InputExpandingOnFocus } from 'layouts'
// selectors
import { getLocation } from 'redux/reducers/router'

const mapStateToProps = (state) => ({ location: getLocation(state) })

export class PlaylistListSearchForm extends PureComponent {
  static propTypes = { t: PropTypes.func.isRequired, location: PropTypes.object }
  static contextTypes = { router: PropTypes.object }

  UNSAFE_componentWillMount() {
    return this.updateStateFromLocation(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.location.equals(this.props.location)) return this.updateStateFromLocation(nextProps)
  }

  getIsPublishedOpts() {
    const { t } = this.props
    return [
      { text: t('Experiences::PublishOptions::All'), value: '' },
      { text: t('Experiences::PublishOptions::Published only'), value: '1' },
      { text: t('Experiences::PublishOptions::Unpublished only'), value: '0' },
    ]
  }

  updateStateFromLocation(props) {
    return this.setState({
      id: props.location.getIn(['query', 'id'], ''),
      host: props.location.getIn(['query', 'host'], ''),
      location: props.location.getIn(['query', 'location'], ''),
      event: props.location.getIn(['query', 'event'], ''),
      booking: props.location.getIn(['query', 'booking'], ''),
      minscore: props.location.getIn(['query', 'minscore'], ''),
      maxscore: props.location.getIn(['query', 'maxscore'], ''),
      fromdate: props.location.getIn(['query', 'fromdate'], ''),
      todate: props.location.getIn(['query', 'todate'], ''),
      published: props.location.getIn(['query', 'published'], ''),
    })
  }

  updateFromDate(date) {
    const fromdate = moment(date).format('YYYY-MM-DD')
    this.setState({ fromdate })
    if (!this.state.todate || this.state.todate < fromdate) {
      this.setState({ todate: fromdate })
    }
  }

  updateToDate(date) {
    const todate = moment(date).format('YYYY-MM-DD')
    this.setState({ todate })
    if (!this.state.fromdate || this.state.fromdate > todate) {
      this.setState({ fromdate: todate })
    }
  }

  updateQuery(query = this.state) {
    return this.context.router.push(this.props.location.mergeIn(['query'], { ...query, offset: 0 }).toJS())
  }

  clearSorting() {
    return this.context.router.push(this.props.location.mergeIn(['query'], { sortBy: '', order: '' }).toJS())
  }

  clearFilters() {
    return this.setState(
      {
        id: '',
        host: '',
        location: '',
        event: '',
        booking: '',
        minscore: '',
        maxscore: '',
        fromdate: '',
        todate: '',
        published: '',
      },
      () => this.updateQuery(),
    )
  }

  handleSubmit(e) {
    e.preventDefault()
    return this.updateQuery()
  }

  render() {
    const { t } = this.props
    return (
      <Form onSubmit={this.handleSubmit.bind(this)} autoComplete='off'>
        <Form.Group widths='equal'>
          <Form.Field>
            <label>{t('Reviews::Search by id')}</label>
            <SearchInput
              placeholder={t('Reviews::Id')}
              fluid
              icon='search'
              iconPosition='left'
              value={this.state.id}
              onChange={(value) => this.setState({ id: value })}
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Reviews::Search by host')}</label>
            <SearchInput
              placeholder={t('Reviews::id')}
              fluid
              icon='search'
              iconPosition='left'
              value={this.state.host}
              onChange={(value) => this.setState({ host: value })}
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Reviews::Locality')}</label>
            <SearchInput
              queryName='location'
              placeholder={t('Reviews::Address, city, country (iso), postal code')}
              icon='search'
              iconPosition='left'
              value={this.state.location}
              onChange={(value) => this.setState({ location: value })}
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Reviews::Event')}</label>
            <SearchInput
              queryName='event'
              placeholder={t('Reviews::id, title')}
              icon='search'
              iconPosition='left'
              value={this.state.event}
              onChange={(value) => this.setState({ event: value })}
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Reviews::Booking')}</label>
            <SearchInput
              queryName='booking'
              placeholder={t('Reviews::Id')}
              icon='search'
              iconPosition='left'
              value={this.state.booking}
              onChange={(value) => this.setState({ booking: value })}
            />
          </Form.Field>

          <Form.Field>
            <InputExpandingOnFocus
              label={t('Experiences::Published')}
              blurWidth={120}
              Component={SelectInput}
              queryName='published'
              fluid
              search
              options={this.getIsPublishedOpts()}
              value={this.state.published}
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Reviews::Score between')}</label>
            <SearchInput
              type='number'
              min='1'
              max='5'
              step='0.1'
              queryName='minscore'
              placeholder={t('Reviews::Minimum score (between 1 and 5)')}
              icon='search'
              iconPosition='left'
              value={this.state.minscore}
              onChange={(value) =>
                this.setState({
                  minscore: value,
                  maxscore: Math.max(value, this.state.maxscore).toString(),
                })
              }
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Reviews::and')}</label>
            <SearchInput
              type='number'
              min='1'
              max='5'
              step='0.1'
              queryName='maxscore'
              placeholder={t('Reviews::Maximum score (between 1 and 5)')}
              icon='search'
              iconPosition='left'
              value={this.state.maxscore}
              onChange={(value) =>
                this.setState({
                  minscore: Math.min(value, this.state.minscore).toString(),
                  maxscore: value,
                })
              }
            />
          </Form.Field>

          <Form.Field>
            <label>&nbsp;</label>
            <Button type='submit'>{t('Reviews::Search')}</Button>
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field>
            <label>{t('Reviews::Date between')}</label>
            <DatePicker
              name='fromdate'
              todayButton={t('Reviews::Today')}
              selected={this.state.fromdate && moment(this.state.fromdate).isValid() ? moment(this.state.fromdate) : ''}
              onChange={this.updateFromDate.bind(this)}
              placeholderText={t('Reviews::start date')}
              dateFormat='DD/MM/YYYY'
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Reviews::and')}</label>
            <DatePicker
              name='todate'
              todayButton={t('Reviews::Today')}
              selected={this.state.todate && moment(this.state.todate).isValid() ? moment(this.state.todate) : ''}
              onChange={this.updateToDate.bind(this)}
              placeholderText={t('Reviews::end date')}
              dateFormat='DD/MM/YYYY'
            />
          </Form.Field>

          <Form.Field>
            <label>&nbsp;</label>
            <Button type='submit'>{t('Reviews::Search')}</Button>
            <Button.Group vertical basic compact size='small' style={{ transform: 'translateY(-15px)' }}>
              <Button onClick={() => this.clearFilters()}>{t('Reviews::Clear filters')}</Button>
              <Button onClick={() => this.clearSorting()}>{t('Reviews::Clear sorting')}</Button>
            </Button.Group>
          </Form.Field>
        </Form.Group>
      </Form>
    )
  }
}

export default withTranslation('common')(connect(mapStateToProps)(PlaylistListSearchForm))
