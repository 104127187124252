// Libraries
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'helpers/proptypes'
import { withTranslation } from 'react-i18next'

// Components
import { Form, Button } from 'semantic-ui-react'
import { SearchInput, SelectInput } from 'components/inputs'

// Selectors
import { getLocation } from 'redux/reducers/router'
import { Link } from 'react-router'

// Helpers
import { getTranslatedOptions } from 'helpers/options'
import { organizationTypes } from 'helpers/organizations'

const mapStateToProps = (state) => ({
  fromStore: {
    location: getLocation(state),
  },
})

class _PartnerSearchForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fromStore: PropTypes.shape({
      location: PropTypes.immutable.map.isRequired,
    }).isRequired,
  }

  static contextTypes = { router: PropTypes.object }

  state = {
    search: '',
    organization: undefined,
    organization_type: '',
  }

  componentDidMount() {
    return this.updateStateFromLocation(this.props)
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.fromStore.location.equals(this.props.fromStore.location)) {
      return this.updateStateFromLocation(this.props)
    }
  }

  updateStateFromLocation(props) {
    return this.setState({
      search: props.fromStore.location.getIn(['query', 'search'], ''),
      organization: props.fromStore.location.getIn(['query', 'organization'], ''),
      organization_type: props.fromStore.location.getIn(['query', 'organization_type'], ''),
    })
  }

  updateQuery(query = this.state) {
    return this.context.router.push(this.props.fromStore.location.mergeIn(['query'], { ...query, offset: 0 }).toJS())
  }

  clearFilters() {
    return this.setState({ search: '', organization: '', organization_type: '' }, () => this.updateQuery())
  }

  handleSubmit(e) {
    e.preventDefault()
    return this.updateQuery()
  }

  render() {
    const { t } = this.props
    return (
      <Form onSubmit={this.handleSubmit.bind(this)} style={{ marginBottom: '1em' }} autoComplete='off'>
        <Form.Group widths='equal'>
          <Form.Field>
            <label>{t('Partners::Partner')}</label>
            <SearchInput
              placeholder={t('Partners::Id / Email / Name')}
              fluid
              icon='search'
              iconPosition='left'
              value={this.state.search}
              onChange={(value) => this.setState({ search: value })}
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Partners::Organization')}</label>
            <SearchInput
              placeholder={t('Partners::Id / Name')}
              fluid
              icon='search'
              iconPosition='left'
              value={this.state.organization}
              onChange={(value) => this.setState({ organization: value })}
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Partners::Organization Type')}</label>
            <SelectInput
              queryName='organization_type'
              fluid
              search
              options={getTranslatedOptions(t, organizationTypes)}
              value={this.state.organization_type}
            />
          </Form.Field>

          <Form.Field>
            <label>&nbsp;</label>
            <Button type='submit'>{t('Partners::Search')}</Button>

            <Button basic onClick={() => this.clearFilters()}>
              {t('Partners::Clear filters')}
            </Button>

            <Button as={Link} to='/partners/add' primary style={{ transform: 'translateX(15px)' }}>
              {t('Partners::Create new')}
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    )
  }
}

export const PartnerSearchForm = withTranslation('common')(connect(mapStateToProps)(_PartnerSearchForm))
