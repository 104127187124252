import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const adminsActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'admins' })

export const fetchAdmins = ({ query } = {}) =>
  adminsActions.fetch({
    url: '/api/admins',
    query,
    schema: { admins: [schemas.admins] },
  })

export const fetchAdmin = (id, { query } = {}) =>
  adminsActions.fetch({
    url: `/api/admins/${id}`,
    query,
    schema: { admin: schemas.admins },
  })

export const updateAdmin = (id, payload, { query } = {}) =>
  adminsActions.update({
    url: `/api/admins/${id}`,
    payload,
    query,
    schema: { admin: schemas.admins },
  })
