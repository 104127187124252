import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const offlinereviewsActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'offlinereviews' })

export const uploadOfflineReviews = (file) => {
  const payload = new FormData()
  payload.append('file', file)

  return offlinereviewsActions.create({
    url: '/api/offlinereviews/import',
    payload,
    schema: { offlinereviews: [schemas.offlinereviews] },
  })
}
