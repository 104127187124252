import { schema } from 'normalizr'
import { Record, List } from 'immutable'

const _schema = new schema.Entity('organizations')

const _Record = Record({
  id: undefined,
  name: undefined,
  type: undefined,
  email: '',
  url: '',
  phone: '',
  payment: undefined,
  display_price: undefined,
  cancellation_delay: undefined,
  revenue_share: undefined,
  playlist_nb: 0,
  logo_url: '',
  tags: new List(),
  api_key: '',
})

export const organizations = {
  schema: _schema,
  Record: _Record,
}
