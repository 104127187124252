import i18next from 'i18next'
import XHR from 'i18next-xhr-backend'
import settings from 'settings'
import { screenApi, businessApi } from 'helpers/api'

const defaultLocale = settings.defaultLocale

// Set language for momentjs and axios queries
screenApi.setHeader('X-Language', defaultLocale)
businessApi.setHeader('X-Language', defaultLocale)

export function initI18next() {
  i18next.use(XHR).init({
    lng: defaultLocale,
    whitelist: ['en', 'zh-CN'],
    fallbackLng: 'en',
    nsSeparator: '~',
    keySeparator: '::',

    ns: ['common'],
    defaultNS: 'common',
    keySpaces: false,
    interpolation: {
      escape: false,
      escapeValue: false,
    },
    returnEmptyString: false,
    react: {
      useSuspense: false,
    },
    debug: __DEBUG_I18N__,
  })
  return i18next
}

const i18n = initI18next()

// Ensure Intl exists in global context
if (!global.Intl) {
  global.Intl = require('intl')
}

export default i18n
