import settings from 'settings'
import { getLanguageByLocale } from 'redux/entities/selectors'

function getAppLocale(state) {
  return state.getIn(['appSettings', 'locale']) || settings.defaultLocale
}

export function getAppLanguage(state) {
  return getLanguageByLocale(state, getAppLocale(state))
}
