import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import { LOCATION_CHANGE } from 'react-router-redux'

const initialState = fromJS({
  locationBeforeTransitions: null,
})

const handlers = {
  [LOCATION_CHANGE]: (state, { payload }) =>
    state.merge(
      fromJS({
        locationBeforeTransitions: payload,
      })
    ),
}

export default createReducer(initialState, handlers)

export * from './selectors'
