import React from 'react'
import PropTypes from 'prop-types'

export function Section({ id, title, subtitle, children }) {
  return (
    <section id={id} style={{ marginBottom: '70px' }}>
      <h3 style={{ color: '#8E8F93', borderBottom: 'solid 1px #8E8F93' }}>
        {title}
        <i style={{ marginLeft: '8px', color: '#5E6065', fontSize: '14px' }}>{subtitle}</i>
      </h3>
      {children}
    </section>
  )
}

Section.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Section.defaultProps = {
  subtitle: undefined,
}
