import {
  getEntities,
  getEntity,
  getEntityCount,
  isFetching,
  getError,
  getEntityOrder,
  getEntityTotal,
  isUpdating,
  isDeleting,
} from './_utils'
import { getPlace } from './places'
import { getUser } from './users'

export const getReviews = getEntities('reviews')
export const getReviewsTotal = getEntityTotal('reviews')
export const getReviewsOrder = getEntityOrder('reviews')
export const getReviewsCount = getEntityCount('reviews')
export const getReview = (state, { id }) => {
  const review = getEntity('reviews')(state, { id })
  return review
    .set('place', getPlace(state, { id: review.place_id || review.place }))
    .set('user', getUser(state, { id: review.user_id || review.user }))
}

export const getReviewsList = (state) => getReviewsOrder(state).map((id) => getReview(state, { id }))

export const getReviewFiles = (state, { id }) => {
  const review = getEntity('reviews')(state, { id })
  const files = getEntities('files')(state)
  return files.filter((file) => file.attachable_type === 'reviews' && file.attachable_id === review.id).toList()
}

export const fetchingReviews = isFetching('/api/reviews')
export const getReviewsError = getError('/api/reviews')
export const getReviewError = (state, id) => getError(`/api/reviews/${id}`)(state)
export const publishingReview = (state, id) => isUpdating(`/api/reviews/${id}`)(state)
export const unpublishingReview = (state, id) => isDeleting(`/api/reviews/${id}`)(state)
