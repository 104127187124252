// Libraries
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
// Components
import { CSVLink } from 'react-csv'
import { Form, Button } from 'semantic-ui-react'
import { SelectInput } from 'components/inputs'
// Utils
import { getTranslatedOptions } from 'helpers/options'
import { csvSeparatorOptions, payoutFormatOptions, formatBatchedPayouts } from 'helpers/payouts'
// Redux
import { getLocation } from 'redux/reducers/router'
import { getBatchedPayouts } from 'redux/entities/selectors'
import './CsvForm.css'

const mapStateToProps = (state) => ({
  location: getLocation(state),
  batchedPayouts: getBatchedPayouts(state),
})

export class PayoutCsvForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.object,
    batchedPayouts: PropTypes.object,
  }

  static contextTypes = { router: PropTypes.object }

  currenciesOpts = []
  paidStatusesOpts = []
  UNSAFE_componentWillMount() {
    return this.setState({ separator: ';', csvFormat: 'all_fields' })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    return this.context.router.push(this.props.location.mergeIn(['query'], { batch: true }).toJS())
  }

  render() {
    const { t } = this.props
    const formatedBatch = formatBatchedPayouts(this.props.batchedPayouts.toJS(), this.state.csvFormat)

    return (
      <Form onSubmit={this.handleSubmit} className='__csvForm'>
        <Form.Group>
          <Form.Field>
            <label>{t('Payouts::CSV Separator')}</label>
            <SelectInput
              queryName='separator'
              options={csvSeparatorOptions}
              value={this.state.separator}
              onChange={(value) => this.setState({ separator: value })}
              fluid
              search
              preventNavigation
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Payouts::Format')}</label>
            <SelectInput
              options={getTranslatedOptions(t, payoutFormatOptions)}
              value={this.state.csvFormat}
              onChange={(value) => this.setState({ csvFormat: value })}
              fluid
              search
              preventNavigation
            />
          </Form.Field>

          <Form.Field>
            <label>&nbsp;</label>
            {formatedBatch.data.length > 0 ? (
              <CSVLink
                headers={getTranslatedOptions(t, formatedBatch.headers)}
                data={formatedBatch.data}
                separator={this.state.separator}
                filename='payouts.csv'
                className='ui button'
              >
                {t('Payouts::Download CSV')}
              </CSVLink>
            ) : (
              <Button type='submit'>{t('Payouts::Generate CSV')}</Button>
            )}
          </Form.Field>
        </Form.Group>
      </Form>
    )
  }
}

export default withTranslation('common')(connect(mapStateToProps)(PayoutCsvForm))
