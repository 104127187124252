import settings from 'settings'
// Libraries
import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { omitBy } from 'lodash'
import { withTranslation } from 'react-i18next'
// Components
import { List, Paginate } from 'components/lists'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { HostApplicationRow } from './HostApplicationRow'
import { Segment, Loader } from 'semantic-ui-react'
// Utils
import { getFullPath } from 'helpers/url'
import { isInvalid } from 'helpers/forms'
import { defaultSearchStatus } from 'helpers/hostApplications'
// Redux
import { fetchHostApplications } from 'redux/entities/actions'
import {
  getHostApplicationsError,
  fetchingHostApplications,
  getHostApplicationsList,
  getHostApplicationsCount,
  getHostApplicationsTotal,
} from 'redux/entities/selectors'
import { getLocation } from 'redux/reducers/router'

const mapStateToProps = (state) => ({
  fromStore: {
    location: getLocation(state),
    error: getHostApplicationsError(state),
    loading: fetchingHostApplications(state),
    hostApplications: getHostApplicationsList(state),
    count: getHostApplicationsCount(state),
    total: getHostApplicationsTotal(state),
    isFetching: fetchingHostApplications(state),
  },
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    loadPaginatedHostApplications: (query) => dispatch(fetchHostApplications({ query })),
  },
})

const getHeaders = (t) => [
  { label: t('HostApplications::Creation date') },
  { label: t('HostApplications::Submission date') },
  { label: t('HostApplications::Last update') },
  { label: t('HostApplications::User ID - Full name') },
  { label: t('HostApplications::Experience Id') },
  { label: t('HostApplications::Country') },
  { label: t('HostApplications::City') },
  { label: t('HostApplications::Type of experience') },
  { label: t('HostApplications::Experience level') },
  { label: t('HostApplications::Wow factor') },
  { label: t('HostApplications::Assigned CM') },
  { label: t('HostApplications::CM follow up') },
  { label: t('HostApplications::Status') },
  { label: t('HostApplications::Evaluation') },
  { label: t('HostApplications::Actions') },
]

class _HostApplicationList extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      loadPaginatedHostApplications: PropTypes.func,
    }).isRequired,
    fromStore: PropTypes.shape({
      location: PropTypes.immutable.map.isRequired,
      loading: PropTypes.bool,
      error: PropTypes.object,
      hostApplications: PropTypes.immutable.list,
      count: PropTypes.number,
      total: PropTypes.number,
      isFetching: PropTypes.bool.isRequired,
    }).isRequired,
  }

  state = { error: undefined }

  componentDidMount() {
    const defaultQuery = { offset: 0, size: settings.defaultPaginationSize, status: defaultSearchStatus }
    const query = this.props.fromStore.location.get('query')
    if (query.size > 0) {
      this.loadHostApplications({ ...defaultQuery, ...query.toJS() })
    }
  }

  componentDidUpdate(prevProps) {
    if (getFullPath(prevProps.fromStore.location) !== getFullPath(this.props.fromStore.location)) {
      this.loadHostApplications(this.props.fromStore.location.get('query').toJS())
    }
  }

  loadHostApplications = (query) => this.props.actions.loadPaginatedHostApplications(omitBy(query, isInvalid))

  render() {
    const {
      t,
      fromStore: { hostApplications, total, loading, location, error, isFetching },
    } = this.props

    return (
      <div style={{ overflowX: 'auto' }}>
        {isFetching ? (
          <Segment basic>
            <Loader active content={t('Loading::Loading')} inline='centered' />
          </Segment>
        ) : (
          <Paginate count={total} previous next loading={loading}>
            <List striped celled headers={getHeaders(t)} location={location}>
              {hostApplications.map((hostApplication) => (
                <HostApplicationRow key={hostApplication.id} hostApplication={hostApplication} />
              ))}
            </List>
            <ApiErrorMessage error={error} modal />
          </Paginate>
        )}
      </div>
    )
  }
}

export const HostApplicationList = withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(_HostApplicationList),
)
