import { mapValues, mapKeys, capitalize } from 'lodash'

import { admins } from './admins'
import { accounts } from './accounts'
import { banks } from './banks'
import { batchedPayouts } from './batchedPayouts'
import { bookings } from './bookings'
import { campaigns } from './campaigns'
import { conversations } from './conversations'
import { destinations } from './destinations'
import { events } from './events'
import { files } from './files'
import { followups } from './followups'
import { hostApplications } from './hostApplications'
import { intercomUsers } from './intercomUsers'
import { invites } from './invites'
import { offlinereviews } from './offlinereviews'
import { organizations } from './organizations'
import { overrides } from './overrides'
import { partners } from './partners'
import { partnerDemands } from './partnerDemands'
import { payouts } from './payouts'
import { places } from './places'
import { playlists } from './playlists'
import { references } from './references'
import { replies } from './replies'
import { requests } from './requests'
import { reviews } from './reviews'
import { schedules, emptySchedule } from './schedules'
import { searches } from './searches'
import { tags } from './tags'
import { users } from './users'
import { availabilitiesByDate } from './availabilitiesByDate'
import { alcohols, amenities, criteria, countries, currencies, diets, foods, languages } from './statics'

conversations.schema.define({ followups: [followups.schema] })
followups.schema.define({ conversation: conversations.schema })

events.schema.define({ followups: [followups.schema] })
followups.schema.define({ event: events.schema })

bookings.schema.define({ followups: [followups.schema] })
followups.schema.define({ booking: bookings.schema })

requests.schema.define({ followups: [followups.schema] })
followups.schema.define({ request: requests.schema })

partners.schema.define({ followups: [followups.schema] })
followups.schema.define({ partner: partners.schema })

const schemasAndRecordsByEntity = {
  accounts,
  admins,
  alcohols,
  amenities,
  availabilitiesByDate,
  banks,
  batchedPayouts,
  bookings,
  campaigns,
  conversations,
  countries,
  criteria,
  currencies,
  destinations,
  diets,
  emptySchedule,
  events,
  files,
  followups,
  foods,
  hostApplications,
  intercomUsers,
  invites,
  languages,
  offlinereviews,
  organizations,
  overrides,
  partners,
  partnerDemands,
  payouts,
  places,
  playlists,
  references,
  replies,
  requests,
  reviews,
  schedules,
  searches,
  tags,
  users,
}

export function getSchemaByEntity(key) {
  return schemasAndRecordsByEntity[key].schema
}
export function getRecordByEntity(key) {
  return schemasAndRecordsByEntity[key].Record
}

export const schemas = mapValues(schemasAndRecordsByEntity, (value) => value.schema)
export const records = mapValues(
  mapKeys(schemasAndRecordsByEntity, (v, key) => capitalize(key)), // turn { accounts: [object] } into { Accounts: [object] }
  (value) => value.Record, // map Record
)

export const recordsByEntities = mapValues(schemasAndRecordsByEntity, (value) => value.Record)

export default schemasAndRecordsByEntity
