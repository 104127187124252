// libs
import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
// components
import { Form } from 'semantic-ui-react'

export const ReferencesForm = ({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => {
  const [t] = useTranslation()

  return (
    <Form onSubmit={handleSubmit} autoComplete='off'>
      <Form.Group widths='equal'>
        <Form.Input
          id='referenceCode'
          name='referenceCode'
          label={t('Forms::ReferencesForm::Reference')}
          value={values.referenceCode}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.referenceCode && touched.referenceCode}
        />

        <Form.Button primary type='submit' style={{ marginTop: '24px' }}>
          {t('Forms::ReferencesForm::Submit')}
        </Form.Button>
      </Form.Group>
    </Form>
  )
}

ReferencesForm.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.shape({
    id: PropTypes.number,
    referenceCode: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    referenceCode: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    referenceCode: PropTypes.bool,
  }).isRequired,
}
