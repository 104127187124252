import { vizeatUrls } from '@vizeat/helpers'

function getDomainSpecificVariables() {
  const hostname = window.location.hostname

  if (hostname === 'kitchen.eatwith.com')
    return {
      cookieDomainName: '.eatwith.com',
      domainName: 'eatwith.com',
      screenApiUrl: 'https://screen-api.eatwith.com',
      businessApiUrl: 'https://business-api.eatwith.com',
    }
  if (hostname === 'kitchen.vizeater.co')
    return {
      cookieDomainName: '.vizeater.co',
      domainName: 'vizeater.co',
      screenApiUrl: 'https://screen-api.vizeater.co',
      businessApiUrl: 'https://business-api.vizeater.co',
    }

  if (hostname === 'kitchen.eatwith.net.cn')
    return {
      cookieDomainName: '.eatwith.net.cn',
      domainName: 'eatwith.net.cn',
      screenApiUrl: 'https://screen-api.eatwith.net.cn',
      businessApiUrl: 'https://business-api.eatwith.net.cn',
    }
  if (hostname === 'uat-kitchen.eatwith.net.cn')
    return {
      cookieDomainName: '.eatwith.net.cn',
      domainName: 'eatwith.net.cn',
      screenApiUrl: 'https://uat-screen-api.eatwith.net.cn',
      businessApiUrl: 'https://uat-business-api.eatwith.net.cn',
    }
  if (hostname === 'kitchen.eatwith.dev')
    return {
      cookieDomainName: '.eatwith.dev',
      domainName: 'eatwith.dev',
      screenApiUrl: 'https://screen-api.eatwith.dev',
      businessApiUrl: 'https://business-api.eatwith.dev',
    }

  return {
    cookieDomainName: undefined, // Must be undefined so each deploy link can store cookies
    domainName: 'vizeater.co',
    screenApiUrl: process.env.SCREEN_API_URL || 'https://screen-api.vizeater.co',
    businessApiUrl: process.env.BUSINESS_API_URL || 'https://business-api.vizeater.co',
  }
}

const { cookieDomainName, domainName, screenApiUrl, businessApiUrl } = getDomainSpecificVariables()

function getEWUrlBuilder(host) {
  return vizeatUrls.vizeatUrlsFactory({ config: { url: { host }, env: 'production' } })
}

export default {
  defaultPaginationSize: 25,

  screenApiVersion: process.env.SCREEN_API_VERSION,
  businessApiVersion: process.env.BUSINESS_API_VERSION,
  webappUrlFactory: getEWUrlBuilder('eatwith.com').web,
  globalUrlFactory: getEWUrlBuilder(domainName),
  cookieDomainName,
  screenApiUrl: screenApiUrl,
  businessApiUrl: businessApiUrl,
  cloudflareWorkersAutocompleteUrl: process.env.CLOUDFLARE_WORKERS_AUTOCOMPLETE_URL,
  cloudflareWorkersPlaceUrl: process.env.CLOUDFLARE_WORKERS_PLACE_URL,
  sentry: {
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,
    release: process.env.CF_PAGES_COMMIT_SHA, // this comes directly from Cloudflare Pages environment variables
    tracesSampleRate: process.env.SENTRY_TRACES_SAMPLE_RATE,
  },
  uploadCareKey: process.env.UPLOADCARE_KEY,
  intercomApiId: process.env.INTERCOMAPI_ID,
  defaultLocale: process.env.DEFAULT_LOCALE,
  areCollectionsActive: process.env.ARE_COLLECTIONS_ACTIVE,
}
