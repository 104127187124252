// Libs
import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { withTranslation, Trans } from 'react-i18next'
// Components
import { UserCard } from 'components/cards/UserCard'
import {
  Dimmer,
  Loader,
  Grid,
  TextArea,
  Segment,
  Form,
  Button,
  Divider,
  Popup,
  Accordion,
  Icon,
  List,
} from 'semantic-ui-react'
// Redux
import { fetchUser, fetchBanks } from 'redux/entities/actions'
import { getUser, getUserBankAccounts, getBankAccountsError, fetchingBankAccounts } from 'redux/entities/selectors'
import { connectAs } from 'helpers/users'

const mapStateToProps = (state, props) => ({
  fromStore: {
    user: getUser(state, { id: parseInt(props.params.id, 10) }),
    bankAccounts: getUserBankAccounts(state, { id: parseInt(props.params.id, 10) }),
    isFetchingBankAccounts: fetchingBankAccounts(state),
    error: getBankAccountsError(state),
  },
})

const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    fetchBankAccounts: () => dispatch(fetchBanks({ query: { user_id: props.params.id } })),
    fetchUser: () => dispatch(fetchUser(props.params.id)),
  },
})

export class BankAccountsPage extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fromStore: PropTypes.shape({
      bankAccounts: PropTypes.immutable.list,
      error: PropTypes.immutable.map,
      isFetchingBankAccounts: PropTypes.bool,
      user: PropTypes.immutable.record,
    }).isRequired,
    actions: PropTypes.shape({
      fetchBankAccounts: PropTypes.func,
      fetchUser: PropTypes.func,
    }).isRequired,
  }

  static contextTypes = {
    router: PropTypes.shape({
      goBack: PropTypes.func.isRequired,
    }).isRequired,
  }

  state = { activeIndex: 0 }

  componentDidMount() {
    this.props.actions.fetchUser()
    this.props.actions.fetchBankAccounts()
  }

  handleClick = (e, { index }) => {
    return this.setState({ activeIndex: this.state.activeIndex === index ? -1 : index })
  }

  handleCopyBankAccountToClipboard = ({ bankName, accountNumber, accountHolderName }) => {
    navigator.clipboard.writeText(
      `Bank name: ${bankName}, Account number: ${accountNumber}, Account holder name: ${accountHolderName}`,
    )
  }

  render() {
    const {
      t,
      fromStore: { bankAccounts, error, isFetchingBankAccounts, user },
    } = this.props
    const { activeIndex } = this.state
    const { goBack } = this.context.router

    if (error)
      return (
        <Dimmer active>
          <Icon name='warning sign' />
          {t('Users::BankAccounts::You are not authorized to view this page')}
        </Dimmer>
      )

    if (isFetchingBankAccounts)
      return (
        <Dimmer active>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      )

    return (
      <div style={{ width: '60vw', margin: 'auto' }}>
        <Button content='Back' icon='long arrow alternate left' basic color='black' onClick={goBack} />
        <Divider horizontal />
        <Grid>
          <Grid.Row>
            <Grid.Column width={5}>
              <UserCard user={user} connectAs={() => connectAs(user.account.id)} />
            </Grid.Column>
            <Grid.Column width={9}>
              <Segment>
                <h2>{t('Users::BankAccounts::Billing Address')}</h2>
                <Divider horizontal />
                {user.billing_address ? (
                  <Grid verticalAlign='bottom'>
                    <Grid.Row>
                      <Grid.Column width='14'>
                        <Form>
                          <TextArea value={user.billing_address} disabled />
                        </Form>
                      </Grid.Column>
                      <Grid.Column width='2'>
                        <Popup
                          content={t('Users::BankAccounts::Copy to clipboard')}
                          trigger={
                            <Button
                              icon='clipboard'
                              basic
                              onClick={() => navigator.clipboard.writeText(user.billing_address)}
                            />
                          }
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                ) : (
                  t('Users::BankAccounts::No billing address provided')
                )}
              </Segment>

              <Segment>
                <h2>{t('Users::BankAccounts::Bank Accounts')}</h2>
                <Divider horizontal />
                {bankAccounts.size > 0 ? (
                  <Accordion styled>
                    {bankAccounts.map(
                      (
                        {
                          id,
                          bank_name: bankName,
                          account_number: accountNumber,
                          account_holder_name: accountHolderName,
                        },
                        key,
                      ) => (
                        <div key={id}>
                          <Accordion.Title active={activeIndex === +key} index={+key} onClick={this.handleClick}>
                            <Icon name='dropdown' />
                            {`Bank Account ${+key + 1}`}
                          </Accordion.Title>

                          <Accordion.Content active={activeIndex === +key}>
                            <List>
                              <List.Item>
                                <Trans
                                  i18nKey={__('Users::BankAccounts::<bold>Bank name</bold>: {{bankName}}')}
                                  values={{ bankName }}
                                  components={{ bold: <b /> }}
                                />
                              </List.Item>
                              <List.Item style={{ overflowWrap: 'break-word' }}>
                                <Trans
                                  i18nKey={__('Users::BankAccounts::<bold>Account number</bold>: {{accountNumber}}')}
                                  values={{ accountNumber }}
                                  components={{ bold: <b /> }}
                                />
                              </List.Item>
                              <List.Item>
                                <Trans
                                  i18nKey={__(
                                    'Users::BankAccounts::<bold>Account holder name</bold>: {{accountHolderName}}',
                                  )}
                                  values={{ accountHolderName }}
                                  components={{ bold: <b /> }}
                                />
                              </List.Item>
                              <List.Item style={{ textAlign: 'right' }}>
                                <Popup
                                  content={t('Users::BankAccounts::Copy to clipboard')}
                                  trigger={
                                    <Button
                                      basic
                                      icon='clipboard'
                                      onClick={() =>
                                        this.handleCopyBankAccountToClipboard({
                                          bankName,
                                          accountNumber,
                                          accountHolderName,
                                        })
                                      }
                                    />
                                  }
                                />
                              </List.Item>
                            </List>
                          </Accordion.Content>
                        </div>
                      ),
                    )}
                  </Accordion>
                ) : (
                  t('Users::BankAccounts::No bank accounts found')
                )}
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(BankAccountsPage))
