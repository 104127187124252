import { schema } from 'normalizr'
import { Record } from 'immutable'

const _schema = new schema.Entity('tags')

const _Record = Record({
  id: undefined,
  title: '',
})

export const tags = {
  schema: _schema,
  Record: _Record,
}
