import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const hostApplicationsActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'hostApplications' })

export function fetchHostApplications ({ query } = {}) {
  return hostApplicationsActions.fetch({
    url: '/api/host-applications',
    query,
    schema: { hostApplications: [schemas.hostApplications] },
  })
}

export const fetchHostApplication = (id, query) =>
  hostApplicationsActions.fetch({
    url: `/api/host-applications/${id}`,
    query,
    schema: { hostApplication: schemas.hostApplications },
  })

export const updateHostApplication = (id, payload, { query } = {}) =>
  hostApplicationsActions.update({
    url: `/api/host-applications/${id}`,
    payload,
    query,
    schema: { hostApplication: schemas.hostApplications },
  })
