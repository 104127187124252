// Libs
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
// Helpers
import { getFullPath } from 'helpers/url'
// Redux
import { fetchSchedules } from 'redux/entities/actions'
import { fetchingSchedules, getSchedulesError } from 'redux/entities/selectors'
import { getLocation } from 'redux/reducers/router'
// Components
import { Dimmer, Loader } from 'semantic-ui-react'
import { DesktopCalendar } from '@vizeat/components/es6/components/DesktopCalendar'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { Toolbar } from './Toolbar'
import { DayCell } from './DayCell'
import { ModalAvailabilities } from 'pages/events-calendar/availabilities/ModalAvailabilities'

// Style
import './Calendar.css'

const getParamsFromLocation = (props) => props.fromStore.location.get('query').toObject()

const mapStateToProps = (state) => ({
  fromStore: {
    error: getSchedulesError(state),
    loading: fetchingSchedules(state),
    location: getLocation(state),
  },
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    fetchSchedules: ({ city, start, hostname, organizationIds, end, tags, types }) =>
      dispatch(fetchSchedules({ city, start, hostname, organizationIds, end, tags, types })),
  },
})

class _Calendar extends Component {
  static contextTypes = { router: PropTypes.object }

  static propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      fetchSchedules: PropTypes.func.isRequired,
    }).isRequired,
    fromStore: PropTypes.shape({
      location: PropTypes.object,
    }).isRequired,
  }

  state = { selectedDate: null, showAvailabilitiesModal: false }

  fetchSchedules = () => {
    const { t, ...rest } = this.props
    const { city, start, end, hostname, organizationIds, tags, types } = getParamsFromLocation(rest)

    if (city) {
      this.props.actions.fetchSchedules({
        city: city === 'ROW' ? undefined : city,
        start,
        end,
        hostname,
        organizationIds,
        tags,
        types,
      })
    }
  }

  componentDidMount = () => this.fetchSchedules()

  componentDidUpdate(prevProps) {
    if (getFullPath(prevProps.fromStore.location) !== getFullPath(this.props.fromStore.location)) this.fetchSchedules()
  }

  onNextMonthClick = () => this.handleDateChange('next')
  onPrevMonthClick = () => this.handleDateChange('previous')

  handleDateChange = (operation = 'next') => {
    const { start } = getParamsFromLocation(this.props)
    const newMonth = operation === 'next' ? moment(start).add(1, 'M') : moment(start).subtract(1, 'M')
    return this.context.router.push(
      this.props.fromStore.location
        .mergeIn(['query'], {
          start: moment(newMonth).startOf('month').format('YYYY-MM-DD'),
          end: moment(newMonth).endOf('month').format('YYYY-MM-DD'),
        })
        .toJS(),
    )
  }

  openAvailabilitiesModal = (date) => () =>
    this.setState({ selectedDate: new Date(date), showAvailabilitiesModal: true })

  closeAvailabilitiesModal = () => this.setState({ showAvailabilitiesModal: false })

  render() {
    const { selectedDate, showAvailabilitiesModal } = this.state
    const { t, ...rest } = this.props
    const { city, start } = getParamsFromLocation(rest)
    const {
      fromStore: { error, loading },
    } = this.props
    if (!city) return null
    return (
      <div className='Calendar'>
        <Dimmer inverted active={loading}>
          <Loader inverted content={t('Loading::Loading')} />
        </Dimmer>
        <Toolbar />
        <ApiErrorMessage error={error} />
        <DesktopCalendar
          month={moment.utc(start)}
          onPrevMonthClick={this.onPrevMonthClick}
          onNextMonthClick={this.onNextMonthClick}
          renderDay={(mUtcDate) => (
            <DayCell date={mUtcDate.format('YYYY-MM-DD')} openAvailabilitiesModal={this.openAvailabilitiesModal} />
          )}
        />
        <ModalAvailabilities
          selectedDate={selectedDate}
          open={showAvailabilitiesModal}
          closeModal={this.closeAvailabilitiesModal}
          city={city}
        />
      </div>
    )
  }
}

export const Calendar = withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(_Calendar))
