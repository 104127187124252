// Libraries
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'helpers/proptypes'
import moment from 'moment'
import { omitBy, sum, set, first, compact, min } from 'lodash'
import { withTranslation } from 'react-i18next'
// Components
import { Form, Button, Divider, Message } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import {
  DateSection,
  PricingSection,
  RestrictionSection,
  UserSection,
  OccasionSection,
  EventSection,
} from './components'
import { SelectInput } from 'components/inputs'
import { Autocomplete } from '@vizeat/components/es6/components/Autocomplete'

import ApiErrorMessage from 'components/errors/ApiErrorMessage'
// Helpers
import { businessApi } from 'helpers/api'
import { formatRestrictions } from 'helpers/restrictions'
import { isDateTodayOrLater } from 'helpers/dates'
import { isInvalid } from 'helpers/forms'
import { debouncedfetchAutocomplete as fetchAutocomplete, getLocalityAndCountry } from 'helpers/places'
// Redux
import { createRequest, createDirectRequest, fetchDiets } from 'redux/entities/actions'
import { getCreatingRequestError, getOrganization, getDiets, getSchedule, getTags } from 'redux/entities/selectors'
import { getLocation } from 'redux/reducers/router'
import './form.css'
import './BatchRequestForm.css'

const mapStateToProps = (state, props) => ({
  fromStore: {
    errors: {
      request: getCreatingRequestError(state),
    },
    diets: getDiets(state),
    getOrganization: (id) => getOrganization(state, { id }),
    getSchedule: (date) => getSchedule(state, date),
    location: getLocation(state),
    tags: getTags(state),
  },
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    createRequest: (payload) => dispatch(createRequest(payload)),
    createDirectRequest: (payload) => dispatch(createDirectRequest(payload)),
    loadDiets: () => dispatch(fetchDiets()),
  },
})

class _BatchRequestForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    fromStore: PropTypes.shape({
      diets: PropTypes.immutable.map,
      tags: PropTypes.immutable.map,
      location: PropTypes.immutable.map,
      getOrganization: PropTypes.func.isRequired,
      getSchedule: PropTypes.func.isRequired,
      errors: PropTypes.shape({
        request: PropTypes.object,
      }).isRequired,
    }).isRequired,
    actions: PropTypes.shape({
      createRequest: PropTypes.func.isRequired,
      createDirectRequest: PropTypes.func.isRequired,
      loadDiets: PropTypes.func.isRequired,
    }).isRequired,
    btnSubmitTitle: PropTypes.string.isRequired,
    partnerDemandId: PropTypes.number,
    date: PropTypes.string,
    locality: PropTypes.string,
    bookAs: PropTypes.immutable.record,
  }

  state = {
    submitted: false,
    submitting: false,
    host: undefined,
    event: undefined,
    schedule: undefined,
    body: '',
    date: this.props.date,
    from: '19:00',
    to: '22:00',
    occasion: 'other',
    seats: 0,
    price: undefined,
    restrictions: [],
    bookAs: this.props.bookAs,
    partnerDemandId: this.props.partnerDemandId,
    location: this.props.locality,
    tags: [],
    users: [],
    selectedHost: [],
    selectedEventPerHost: {},
    loadingUsers: false,
    totalSeats: 0,
    isApprovedRequests: false,
    leadId: undefined,
  }

  componentDidMount() {
    this.props.actions.loadDiets()
  }

  componentDidUpdate(prevProps) {
    const { submitted } = this.state
    const {
      t,
      fromStore: { errors },
      onSubmit,
    } = this.props
    if (submitted && errors.request === undefined) {
      toast.success(`${t('ToastNotification::The requests have been correctly created')} 👍`, {
        type: toast.TYPE.SUCCESS,
      })
      this.setState({ submitted: false })
      onSubmit()
    }
  }

  searchUsers = () => {
    const { location, tags } = this.state
    if (location && !!tags.length) {
      this.setState({ loadingUsers: true }, () => {
        const query = { location, tags, hostOnly: true, size: 100 }
        businessApi.delayedGet({
          path: '/api/users',
          config: { params: query },
          successCallback: ({ users }) => this.setState({ users, loadingUsers: false }),
        })
      })
    }
  }

  getTagsOpts() {
    if (this.tagsOpts && this.tagsOpts.length) return this.tagsOpts
    this.tagsOpts = this.props.fromStore.tags
      .toList()
      .sortBy((tag) => tag.title)
      .toJS()
      .map((tag) => ({ text: tag.title, value: tag.title }))
    return this.tagsOpts
  }

  handleSubmit = async () => {
    const {
      date,
      from,
      to,
      bookAs,
      seats,
      occasion,
      body,
      price,
      isPrivateEvent,
      partnerDemandId,
      restrictions,
      selectedEventPerHost,
      isApprovedRequests,
      leadId,
    } = this.state
    const { fromStore, actions } = this.props

    this.setState({ submitted: false })
    this.setState({ submitting: true })

    await Promise.all(
      compact(Object.values(selectedEventPerHost)).map((event) => {
        const request = omitBy(
          {
            user: bookAs,
            event_id: event.id,
            date: moment(date).format('YYYY-MM-DD'),
            begins_at: from,
            ends_at: to,
            price,
            seats: min([seats, event.max_seats]),
            occasion,
            private: true,
            additional_info: formatRestrictions(restrictions, fromStore.diets),
            partner_demand_id: partnerDemandId,
            body,
            lead_id: leadId,
          },
          isInvalid,
        )

        Object.assign(request, isPrivateEvent ? { privatizer_id: bookAs.id } : {})

        if (isApprovedRequests) {
          return actions.createDirectRequest(request)
        }
        return actions.createRequest(request)
      }),
    )

    this.setState({ submitted: true })
    this.setState({ submitting: false })
  }

  formatPlace(place) {
    return getLocalityAndCountry(place) || place?.address || place?.formatted
  }

  render() {
    const {
      occasion,
      seats,
      leadId,
      date,
      tags,
      price,
      restrictions,
      bookAs,
      schedule,
      from,
      to,
      users,
      selectedHost,
      selectedEventPerHost,
      loadingUsers,
      totalSeats,
      isApprovedRequests,
      submitting,
    } = this.state

    const {
      t,
      fromStore: { errors, diets, getSchedule },
      btnSubmitTitle,
    } = this.props
    const selectedEvents = Object.values(selectedEventPerHost).filter((exp) => !!exp)
    const canSubmit =
      seats &&
      seats <= totalSeats &&
      date &&
      price >= 0 &&
      bookAs &&
      isDateTodayOrLater(date) &&
      !!selectedEvents.length &&
      !submitting
    const isOverrideDisabled = schedule && schedule.booking_ids.size > 0

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group widths='equal'>
          <Form.Field required>
            <label>{t('Users::Location')}</label>
            <div className='autocomplete'>
              <Autocomplete
                placeholder={t('Users::Address, city, country (iso), postal code')}
                initialValue={this.state.location}
                initialItem={this.state.location}
                getItems={async (value) => fetchAutocomplete({ query: value, language: 'en' })}
                itemToString={this.formatPlace}
                onChange={(value) => this.setState({ location: value.value }, this.searchUsers)}
                preventNavigation
              />
            </div>
          </Form.Field>
          <Form.Field required>
            <label>{t('Users::Tags')}</label>
            <SelectInput
              queryName='tags'
              placeholder={t('Users::tags')}
              multiple
              search
              options={this.getTagsOpts()}
              value={tags.join(',')}
              onChange={(value) => this.setState({ tags: value })}
              onClose={() => this.searchUsers()}
              preventNavigation
            />
          </Form.Field>
        </Form.Group>
        <Form.Field className='__item'>
          <label>{t('EventsCalendar::Pick hosts to request')}</label>
          <Form.Dropdown
            fluid
            multiple
            search
            selection
            noResultsMessage={
              loadingUsers ? t('EventsCalendar::Loading...') : t('EventsCalendar::Select a location and a tag first.')
            }
            onChange={(e, { value }) => this.setState({ selectedHost: value })}
            options={users.map((user) => ({
              text: `${user.id} - ${user.firstname} ${user.lastname} - ${user.account.email}`,
              value: user,
            }))}
          />
        </Form.Field>

        {selectedHost && (
          <Form.Group key='header'>
            <Form.Field width={6}>
              <label>{t('EventsCalendar::Selected Hosts')}</label>
            </Form.Field>
            <Form.Field width={8}>
              <label>{t('EventsCalendar::Event to request')}</label>
            </Form.Field>
            <Form.Field width={5}>
              <label>
                {t('EventsCalendar::Total seats: {{totalSeats}}', {
                  totalSeats,
                })}
              </label>
            </Form.Field>
          </Form.Group>
        )}

        {selectedHost &&
          selectedHost.map((user) => {
            return (
              <Form.Group key={user.id}>
                <Form.Input
                  className='__item'
                  width={5}
                  value={`${user.firstname} ${user.lastname}`}
                  transparent
                  readOnly
                />
                <EventSection
                  canShowLabel
                  host={user}
                  isDisabled={false}
                  event={selectedEventPerHost[user.id]}
                  filterTags={tags}
                  onEventChange={(event) => {
                    const updatedList = set(selectedEventPerHost, user.id, event)
                    this.setState({
                      selectedEventPerHost: updatedList,
                      totalSeats: sum(Object.values(updatedList).map((event) => event.max_seats)),
                    })
                  }}
                />
              </Form.Group>
            )
          })}
        <Divider />
        <UserSection
          placeholder={t('EventsCalendar::Guest name, email or id')}
          inputLabel={t('EventsCalendar::Guest (to book as)')}
          onUserChange={(guest) => this.setState({ bookAs: guest })}
          user={bookAs}
        />

        <Divider />

        <DateSection
          eventId={selectedEvents.length === 1 ? selectedEvents[0].id : undefined}
          date={date}
          handleDateChange={(dte) => this.setState({ date: dte, schedule: getSchedule(dte) })}
          handleChange={(value) => this.setState(value)}
          isTimeDisabled={isOverrideDisabled}
          from={from}
          to={to}
        />

        <Divider />

        <Form.Group className='__item'>
          <OccasionSection
            inputWitdh={4}
            occasion={occasion}
            handleChange={(value) => this.setState(value)}
            isDisabled={false}
          />
          <Form.Field width={4}>
            <label>{t('EventsCalendar::Seats')}</label>
            <Form.Input
              type='number'
              min={0}
              placeholder={t('EventsCalendar::How many guests?')}
              value={seats}
              onChange={(e, { value }) => this.setState({ seats: parseInt(value, 10) })}
            />
          </Form.Field>
          <Form.Field width={4}>
            <label>{t('EventsCalendar::Lead')}</label>
            <Form.Input
              type='number'
              min={0}
              placeholder={t('EventsCalendar::Lead id')}
              value={leadId}
              onChange={(_, { value }) => this.setState({ leadId: parseInt(value, 10) })}
            />
          </Form.Field>
          <Form.Field width={4}>
            <Form.Radio
              label={t('EventsCalendar::Create approved requests')}
              toggle
              checked={isApprovedRequests}
              onChange={(e, { checked }) => this.setState({ isApprovedRequests: checked })}
              style={{ marginTop: 32 }}
            />
          </Form.Field>
        </Form.Group>

        {seats > totalSeats && (
          <Message negative>
            {t("EventsCalendar::The number of seats can't be over the sum of the events' capacities: {{totalSeats}}.", {
              totalSeats,
            })}
          </Message>
        )}
        <Message
          info
          icon='info circle'
          content={t(
            'EventsCalendar::The number of seats asked for each request will be the lower between the number required and the event capacity',
          )}
        />
        {/* Use the first selected event to initiate price as it's not critical */}
        {bookAs && date && (
          <PricingSection
            currency={bookAs.currency}
            date={date}
            event={first(selectedEvents)}
            handleChange={(value) => value && this.setState(value)}
            isPriceDisabled={isOverrideDisabled}
            price={price}
            seats={seats}
            user={bookAs}
          />
        )}

        {price < 0 && <Message negative>{t('EventsCalendar::Price should not be negative')}</Message>}

        <RestrictionSection restrictions={restrictions} diets={diets} handleChange={(value) => this.setState(value)} />

        <Divider />

        <Form.Field className='__item'>
          <label>{t('EventsCalendar::Message to the hosts (optional):')}</label>
          <Form.TextArea
            name='body'
            placeholder={t('EventsCalendar::Write a message')}
            rows={2}
            onChange={(e, input) => this.setState({ body: input.value, notifyHost: true })}
          />
        </Form.Field>

        <Form.Field className='__btnSubmit'>
          <Button primary size='big' type='submit' disabled={!canSubmit} content={btnSubmitTitle.toUpperCase()} />
        </Form.Field>
        <ApiErrorMessage error={errors.request} modal />
      </Form>
    )
  }
}

export const BatchRequestForm = withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(_BatchRequestForm),
)
