import { schemas } from 'redux/entities/schemas'
import { buildHandlers, businessApi } from 'helpers/api'

export const actions = {
  LOADING_USER: 'INTERCOM_LOADING_USER',
  LOADED_USER: 'INTERCOM_LOADED_USER',
  LOAD_USER_ERROR: 'INTERCOM_LOAD_USER_ERROR',
}

export const user = (id) => (dispatch, getState, { Api: businessApi }) => {
  dispatch({ type: actions.LOADING_USER, id })
  const handle = buildHandlers(dispatch)
  return businessApi
    .get(`/api/intercom/users/${id}`)
    .then(
      handle.normalizeResponse({ intercom_user: schemas.intercomUsers }, actions.LOADED_USER, undefined, id),
      handle.error(actions.LOAD_USER_ERROR, id)
    )
}
