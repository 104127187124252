// Libraries
import React from 'react'
import { Form } from 'semantic-ui-react'

// Helpers
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'

export const ReferenceSection = ({ referenceCode, isDisabled, handleChange }) => {
  const [t] = useTranslation()

  return (
    <Form.Field className='__item'>
      <label>{t('EventsCalendar::Booking reference (optional)')}</label>
      <Form.Input
        placeholder={t('EventsCalendar::1DF3E7D7')}
        value={referenceCode}
        disabled={isDisabled}
        onChange={(e, { value }) => handleChange({ referenceCode: value })}
      />
    </Form.Field>
  )
}

ReferenceSection.propTypes = {
  referenceCode: PropTypes.string,
  isDisabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
}

ReferenceSection.defaultProps = {
  referenceCode: '',
  isDisabled: false,
}
