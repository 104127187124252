// Libraries

// React.PureComponent is exactly like React.Component but implements
// shouldComponentUpdate() with a shallow prop and state comparison.
// If your React component's render() function renders the same result given
// the same props and state, you can use React.PureComponent for a performance
// boost in some cases.
import React, { PureComponent } from 'react'
// Components
import SearchForm from './SearchForm'
import TagList from './List'

export default class UserIndex extends PureComponent {
  render () {
    return (
      <div>
        <SearchForm />
        <TagList />
      </div>
    )
  }
}
