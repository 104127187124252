export const organizationTypes = [
  { text: __('Organizations::OrganizationTypes::Travel agency'), value: 'Travel agency' },
  { text: __('Organizations::OrganizationTypes::Offline Partner'), value: 'Offline Partner' },
  { text: __('Organizations::OrganizationTypes::Online Partner - Merchant'), value: 'Online Partner - Merchant' },
  {
    text: __('Organizations::OrganizationTypes::Online Partner - Affiliate'),
    value: 'Online Partner - Affiliate',
  },
  { text: __('Organizations::OrganizationTypes::Other'), value: 'Other' },
]
