import { schema } from 'normalizr'
import { Record, List, Set, Map } from 'immutable'

import { accounts } from './accounts'
import { files } from './files'
import { currencies, languages } from './statics'

const _schema = new schema.Entity('users', {
  account: accounts.schema,
  currency: currencies.schema,
  avatar: files.schema,
  languages: [languages.schema],
  files: [files.schema],
})

const _Record = Record({
  id: undefined,

  account: new accounts.Record(),
  account_id: undefined,
  avatar: new files.Record(),
  avatar_id: undefined,
  billing_address: undefined,
  birth_date: '',
  civility: '',
  currency: currencies.Record(),
  currency_id: undefined,
  descriptions: new Map(),
  events: new List(),
  files: new Set(),
  firstname: '',
  isPro: false, // amateur vs professional host
  languages: new List(),
  lastname: '',
  newsletter: undefined,
  phone: undefined,
  locality: undefined,
  country: undefined,
  tags: new List(),
  acceptance_rate: undefined,
  has_bank_account: undefined,
  tos: undefined,
  type: '',
  created_at: undefined,
  updated_at: undefined,
  isHost: undefined,
  properties: new Map(),
  ewid: undefined,
  partner: undefined,
})

export const users = {
  schema: _schema,
  Record: _Record,
}
