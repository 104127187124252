import React, { Fragment } from 'react'
import { Segment, Header, Grid, Label, List } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export function LocationAndVenueSection({ hostApplication: { sample_experience: sampleExperience } }) {
  const { t } = useTranslation()

  return (
    <Segment>
      <Header as='h3'>{t('HostApplications::Summary::Location & Venue')}</Header>
      <Segment basic>
        <Grid columns={1}>
          <Grid.Column>
            {sampleExperience.getIn(['description', 'neighbourhood']) && (
              <Fragment>
                <Header size='small'>{t('HostApplications::Summary::Additional place details')}</Header>
                {sampleExperience.getIn(['description', 'neighbourhood'])}
              </Fragment>
            )}
            {(sampleExperience.getIn(['place', 'place_type']) || sampleExperience.getIn(['place', 'event_space'])) && (
              <Fragment>
                <Header size='small'>{t('HostApplications::Summary::Type of venue')}</Header>
                <Label basic circular>
                  {sampleExperience.getIn(['place', 'place_type']) || sampleExperience.getIn(['place', 'event_space'])}
                </Label>
              </Fragment>
            )}
            {sampleExperience.getIn(['place', 'WOW_factor']).size > 0 && (
              <Fragment>
                <Header size='small'>{t('HostApplications::Summary::WOW factors')}</Header>
                <List horizontal>
                  {sampleExperience.getIn(['place', 'WOW_factor']).map((wowFactor) => (
                    <List.Item key={wowFactor}>
                      <Label basic circular>
                        {wowFactor}
                      </Label>
                    </List.Item>
                  ))}
                </List>
              </Fragment>
            )}
          </Grid.Column>
        </Grid>
      </Segment>
    </Segment>
  )
}

LocationAndVenueSection.propTypes = {
  hostApplication: PropTypes.shape({
    sample_experience: PropTypes.object,
  }).isRequired,
}
