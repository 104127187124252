// Libs
import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import moment from 'moment'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
// Components
import { Link } from 'react-router'
import { Table, Checkbox } from 'semantic-ui-react'
import { getBooking, getRequest, getRequestStatus, getUser } from 'redux/entities/selectors'
import { FormattedFollowup } from 'components/followups'
// Style
import './Row.css'

const { Row, Cell } = Table

const mapStateToProps = (state, props) => ({
  fromStore: {
    getBooking: (id) => getBooking(state, { id }),
    getRequest: (id) => getRequest(state, { id }),
    getRequestStatus: (id) => getRequestStatus(state, { id }),
    getUser: (id) => getUser(state, { id, raw: true }),
  },
})

class _ConversationRow extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    conversation: PropTypes.immutable.record.isRequired,
    selected: PropTypes.bool.isRequired,
    onSelect: PropTypes.func.isRequired,
    fromStore: PropTypes.shape({
      getBooking: PropTypes.func.isRequired,
      getRequest: PropTypes.func.isRequired,
      getRequestStatus: PropTypes.func.isRequired,
      getUser: PropTypes.func.isRequired,
    }).isRequired,
  }

  formatRequestBookingsId = (conversation) => {
    const { t } = this.props
    const request = this.props.fromStore.getRequest(conversation.request)
    return (
      <Fragment>
        {conversation.request && (
          <Link to={`/requests?search=${conversation.request}`}>
            {request.date
              ? t('Conversations::R-{{request}} (RAD)', { request: conversation.request })
              : t('Conversations::R-{{request}} (RI)', { request: conversation.request })}
          </Link>
        )}
        {conversation.request && conversation.bookings.size > 0 && <hr />}
        {conversation.bookings.size > 0 &&
          conversation.bookings.map((booking) => (
            <Link key={booking} to={`/bookings?search=${booking}`}>
              {t('Conversations::B-{{booking}}', { booking: booking })}
            </Link>
          ))}
      </Fragment>
    )
  }

  formatStatus = (conversation) => {
    const { getBooking, getRequestStatus } = this.props.fromStore
    if (conversation.bookings.size === 1) return getBooking(conversation.bookings.first()).status
    return getRequestStatus(conversation.request)
  }

  shortenReplyBody(body) {
    if (body && body.length > 1250) return body.slice(0, 1000) + '\n...'
    else return body
  }

  getUserIdAndName({ id, firstname, lastname }) {
    return `${id} - ${firstname} ${lastname}`
  }

  render() {
    const { conversation } = this.props
    return (
      <Row className='conversations-list-row'>
        <Cell>{conversation.id}</Cell>

        <Cell>{this.formatRequestBookingsId(conversation)}</Cell>

        <Cell>{moment(conversation.created_at).format('lll')}</Cell>

        <Cell>
          <Link to={`/users?search=${conversation.sender.id}`}>{this.getUserIdAndName(conversation.sender)}</Link>
        </Cell>

        <Cell>
          <Link to={`/users?search=${conversation.recipient.id}`}>{this.getUserIdAndName(conversation.recipient)}</Link>
        </Cell>

        <Cell className='reply-cell'>{this.shortenReplyBody(conversation.sender_first_reply.body)}</Cell>

        <Cell className='reply-cell'>{this.shortenReplyBody(conversation.recipient_first_reply.body)}</Cell>

        <Cell>{this.formatStatus(conversation)}</Cell>

        <Cell>
          <FormattedFollowup followups={conversation.followups} />
        </Cell>

        <Cell className='select'>
          <Checkbox
            onChange={(e, { checked }) => this.props.onSelect(checked, conversation.id)}
            checked={this.props.selected}
          />
        </Cell>
      </Row>
    )
  }
}

export const ConversationRow = withTranslation('common')(connect(mapStateToProps, null)(_ConversationRow))
