import { schema } from 'normalizr'
import { Record } from 'immutable'
import { users } from './users'
import { currencies } from './statics'

const _schema = new schema.Entity('payouts', {
  user: users.schema,
  currency: currencies.schema,
})

const _Record = Record({
  id: undefined,

  user_id: undefined,
  user: new users.Record(),

  currency_id: undefined,
  currency: new currencies.Record(),

  amount: undefined,
  gateway: undefined,

  paypal_id: undefined,

  stripe_payout_id: undefined,
  stripe_status: undefined,
  stripe_reason: undefined,
  stripe_payout_receipt_url: undefined,

  reference_codes: undefined,

  paid_at: undefined,
  created_at: undefined,
  updated_at: undefined,
})

export const payouts = {
  schema: _schema,
  Record: _Record,
}
