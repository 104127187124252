import React from 'react'
import { Link } from 'react-router'
import { Popup, Icon } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

export const ExperienceIdWithCheckMark = ({ event }) => {
  const [t] = useTranslation()
  if (!event) return null
  return (
    <span>
      <Link to={`/events/${event.id}`}>{event.id}</Link>

      {!!event.published_at && (
        <Popup
          trigger={<Icon color='green' name='check circle' />}
          content={t('Labels::Experience first published at: {{date}}', {
            date: moment(event.published_at).format('lll'),
          })}
          inverted
        />
      )}
    </span>
  )
}

ExperienceIdWithCheckMark.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    published_at: PropTypes.string,
  }).isRequired,
}
