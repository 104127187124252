// Libs
import React from 'react'
import PropTypes from 'helpers/proptypes'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
// Components
import { Link } from 'react-router'
import { Grid, Segment } from 'semantic-ui-react'
import { numbers } from '@vizeat/helpers'
import { ReferencesFormWrapper } from 'components/forms/references'

const { formatIntegerPrice } = numbers

export function DemandDetails({
  demand,
  demand: {
    age_interval: ageInterval,
    currency,
    country_iso: countryIso,
    begins_at: beginsAt,
    date,
    event_type: eventType,
    id,
    invoice_id: invoiceId,
    locality,
    price,
    reference,
    seats,
    free_seats: freeSeats,
    status,
  },
  requestedSeats,
  validatedSeats,
}) {
  const [t] = useTranslation()
  return (
    <Segment>
      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <label>{t('PartnerDemands::Summary::ID:')}</label> {id}
          </Grid.Column>

          <Grid.Column width={4}>
            <label>{t('PartnerDemands::Summary::Date:')}</label> {date && moment(date).format('ll')}
          </Grid.Column>

          <Grid.Column width={4}>
            <label>{t('PartnerDemands::Summary::Status:')}</label> {status}
          </Grid.Column>

          <Grid.Column width={4}>
            <label>{t('PartnerDemands::Summary::Event type:')}</label> {eventType}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={4}>
            <label>{t('PartnerDemands::Summary::Country:')}</label> {countryIso}
          </Grid.Column>

          <Grid.Column width={4}>
            <label>{t('PartnerDemands::Summary::Locality:')}</label> {locality}
          </Grid.Column>

          <Grid.Column width={4}>
            <label>{t('PartnerDemands::Summary::Begins at:')}</label> {beginsAt}
          </Grid.Column>

          <Grid.Column width={4}>
            <label>{t('PartnerDemands::Summary::Age interval:')}</label> {ageInterval}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={4}>
            <label>
              {t('PartnerDemands::Summary::Demanded seats: {{seats}}', { seats })}{' '}
              {freeSeats && t('PartnerDemands::Summary::(including {{freeSeats}} free seats)', { freeSeats })}
            </label>
          </Grid.Column>

          <Grid.Column width={4}>
            <label>{t('PartnerDemands::Summary::Validated seats:')}</label> {validatedSeats}
          </Grid.Column>

          <Grid.Column width={4}>
            <label>{t('PartnerDemands::Summary::Requested seats:')}</label> {requestedSeats}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={4}>
            <label>{t('PartnerDemands::Summary::Partner price:')}</label> {formatIntegerPrice(price, 'en', currency)}
          </Grid.Column>

          <Grid.Column width={4}>
            <label>{t('PartnerDemands::Summary::Invoice ID:')}</label> {invoiceId}
          </Grid.Column>

          <Grid.Column width={4}>
            <label>{t('PartnerDemands::Summary::Organization:')}</label>{' '}
            <Link to={`/organizations&search=${reference.partner.organization.id}`}>
              {reference.partner.organization.id} - {reference.partner.organization.name}
            </Link>
          </Grid.Column>

          <Grid.Column width={4}>
            <label>{t('PartnerDemands::Summary::Partner:')}</label>{' '}
            <Link to={`/partners/edit/${reference.partner.id}`}>
              {reference.partner.id} - {reference.partner.name}
            </Link>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={8}>
            <ReferencesFormWrapper partnerDemand={demand} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

DemandDetails.propTypes = {
  demand: PropTypes.immutable.record.isRequired,
  requestedSeats: PropTypes.number.isRequired,
  validatedSeats: PropTypes.number.isRequired,
}
