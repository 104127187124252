import React from 'react'
import { IconCross } from '@vizeat/components/es6/assets/icons'
import { Badge } from '@vizeat/components/es6/components/Badge'
import PropTypes from 'helpers/proptypes'

export function DeletableBadge({ children, onClick, ...styledProps }) {
  return (
    <Badge
      onClick={onClick}
      borderRadius='full'
      bg='white'
      border='sm'
      borderColor='lightGray'
      color='darkGray'
      p='8px 12px'
      minWidth='auto'
      {...styledProps}
      css={`
        white-space: nowrap;
        cursor: pointer;
      `}
    >
      {children}
      <IconCross size='18px' />
    </Badge>
  )
}

DeletableBadge.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
}
