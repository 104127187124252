// Libraries
import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'

// Components
import { Form } from 'semantic-ui-react'

export const ToggleSection = ({
  notifyHost,
  notifyGuest,
  handleChange,
  isInstantBooking,
  isDisabled,
  isPrivateEvent,
  showInstantBooking,
  showNotifyHost,
  showNotifyGuest,
  showPrivateEvent,
}) => {
  const [t] = useTranslation()

  return (
    <Form.Group widths='equal' className='__item'>
      {showInstantBooking && (
        <Form.Radio
          label={t('EventsCalendar::Instant Booking')}
          toggle
          disabled={isDisabled}
          checked={isInstantBooking}
          onChange={(e, { checked }) => handleChange({ isInstantBooking: checked })}
        />
      )}

      {showNotifyHost && (
        <Form.Radio
          label={t('EventsCalendar::Notify Host')}
          toggle
          disabled={isDisabled}
          checked={notifyHost}
          onChange={(e, { checked }) => handleChange({ notifyHost: checked })}
        />
      )}

      {showNotifyGuest && (
        <Form.Radio
          label={t('EventsCalendar::Notify Guest')}
          toggle
          disabled={isDisabled}
          checked={notifyGuest}
          onChange={(e, { checked }) => handleChange({ notifyGuest: checked })}
        />
      )}

      {showPrivateEvent && (
        <Form.Radio
          label={t('EventsCalendar::Private Event')}
          toggle
          disabled={isDisabled}
          checked={isPrivateEvent}
          onChange={(e, { checked }) => handleChange({ isPrivateEvent: checked })}
        />
      )}
    </Form.Group>
  )
}

ToggleSection.propTypes = {
  notifyHost: PropTypes.bool,
  notifyGuest: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  isInstantBooking: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isPrivateEvent: PropTypes.bool,
  showInstantBooking: PropTypes.bool,
  showNotifyHost: PropTypes.bool,
  showNotifyGuest: PropTypes.bool,
  showPrivateEvent: PropTypes.bool,
}

ToggleSection.defaultProps = {
  isInstantBooking: true,
  isPrivateEvent: true,
  isDisabled: true,
  notifyHost: false,
  notifyGuest: false,
  showInstantBooking: false,
  showNotifyHost: false,
  showNotifyGuest: false,
  showPrivateEvent: false,
}
