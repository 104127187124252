import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Badge } from '@vizeat/components/es6/components/Badge'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { DeletableBadge } from 'components/badges/DeletableBadge'
import { SectionWrapper } from './SectionWrapper'
import PropTypes from 'helpers/proptypes'
import { useMultiFiltersToggle } from './hooks/useMultiFiltersToggle'

const COLLECTION = {
  chef: __('Playlist::Badge::Chef'),
  rooftop: __('Playlist::Badge::Rooftop'),
  'eco-hero': __('Playlist::Badge::EcoHero'),
}

const StyledBadge = styled((props) => <Badge as='li' role='button' tabIndex={0} {...props} />)`
  cursor: pointer;
  border: ${({ theme, isSelected }) =>
    `${theme.borders.sm} ${isSelected ? theme.colors.eatwithOrange : theme.colors.lightGray}`};
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme, isSelected }) => (isSelected ? theme.colors.eatwithOrange : null)};
  border-radius: ${({ theme }) => theme.radii.full};
  padding: 12px 16px;
`

export function Collection({ onChange, showSelectedFilters, selectedValues }) {
  const { t } = useTranslation()

  const handleChange = useMultiFiltersToggle(selectedValues, { onChange, filterName: 'tags' })

  if (showSelectedFilters) {
    return (
      <Fragment>
        {selectedValues.map((tag) => {
          const selectedTagName = COLLECTION[tag]
          if (!selectedTagName) return null
          return (
            <DeletableBadge key={tag} id={tag} color='mediumGray' onClick={() => handleChange(null, tag)}>
              {t(selectedTagName)}
            </DeletableBadge>
          )
        })}
      </Fragment>
    )
  }

  return (
    <SectionWrapper title={t('Playlists::Summary::Collection')}>
      <Flex gap='8px' forwardedAs='ul' flexWrap='wrap' flexDirection='row'>
        {Object.entries(COLLECTION).map(([tag, name]) => {
          const isSelected = selectedValues.includes(tag)
          return (
            <StyledBadge
              key={tag}
              onClick={() => {
                handleChange(null, tag)
              }}
              isSelected={isSelected}
            >
              {t(name)}
            </StyledBadge>
          )
        })}
      </Flex>
    </SectionWrapper>
  )
}

Collection.propTypes = {
  onChange: PropTypes.func.isRequired,
  showSelectedFilters: PropTypes.bool,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
}

Collection.defaultProps = {
  showSelectedFilters: false,
  selectedValues: [],
}
