import settings from 'settings'
import { isString } from 'lodash'
import cookies from 'helpers/cookies'
import { businessApi } from 'helpers/api'
import { konstants } from '@vizeat/helpers'

const { HOST_TYPOLOGY } = konstants

export function connectAs(accountId, targettedUrl = null) {
  return businessApi
    .get(`/api/accounts/${accountId}/token/`)
    .then(({ data: { token } }) => {
      if (!isString(token)) throw new Error('Response is missing token')
      cookies.webappAuthToken.remove({ maxAge: 0, expires: new Date(1970, 1, 1, 0, 0, 1) })
      cookies.webappAuthToken.save(token)

      window.open(targettedUrl || settings.globalUrlFactory.dashboard.users.profile())
    })
    .catch((error) => alert(error))
}

export const userCivilityOptions = [
  { text: __('Users:CivilityOptions::MR'), value: 'MR' },
  { text: __('Users:CivilityOptions::MRS'), value: 'MRS' },
  { text: __('Users:CivilityOptions::OTHER'), value: 'OTHER' },
]

export const hostTypologyOptions = [
  { key: '', value: '' },
  ...Object.keys(HOST_TYPOLOGY).map((key) => ({
    text: `${HOST_TYPOLOGY[key].charAt(0).toUpperCase()}${HOST_TYPOLOGY[key].slice(1)}`,
    value: HOST_TYPOLOGY[key],
  })),
]
