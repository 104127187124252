/* eslint-disable react/forbid-prop-types */
import settings from 'settings'
// Libs
import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Label, Segment, Loader, Select } from 'semantic-ui-react'
// Components
import Pagination from './Pagination'
import { withTranslation } from 'react-i18next'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Box } from '@vizeat/components/es6/components/Box'

const mapStateToProps = (state) => ({
  location: state.getIn(['routing', 'locationBeforeTransitions']),
  offset: state.getIn(['routing', 'locationBeforeTransitions', 'query', 'offset']),
  size: state.getIn(['routing', 'locationBeforeTransitions', 'query', 'size']),
})

const ITEMS_PER_PAGE = [
  {
    text: 2,
    value: 2,
  },
  {
    text: 4,
    value: 4,
  },
  {
    text: 8,
    value: 8,
  },
  {
    text: 10,
    value: 10,
  },
]

export class Paginate extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.object,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // Props to provide:
    children: PropTypes.any.isRequired,
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // /!\ number of items, not pages, ITEMS!
    loading: PropTypes.bool,
    onPageChange: PropTypes.func,
    previous: PropTypes.bool,
    next: PropTypes.bool,
    buttonsNb: PropTypes.number,
    customOffset: PropTypes.number,
    customSize: PropTypes.number,
    ignoreLocation: PropTypes.bool,
    itemsPerPage: PropTypes.number,
    onItemsPerPageChange: PropTypes.func,
  }

  static defaultProps = {
    offset: 0,
    size: settings.defaultPaginationSize,
    ignoreLocation: false,
    customOffset: undefined,
    customSize: undefined,
    itemsPerPage: undefined,
    onItemsPerPageChange: undefined,
  }

  static contextTypes = {
    router: PropTypes.object,
  }

  componentDidMount() {
    const size = this.props.customSize !== undefined ? this.props.customSize : this.props.size
    const offset = this.props.customOffset !== undefined ? this.props.customOffset : this.props.offset
    this.changePage(this.offsetToPage(offset, size))
  }

  pageToOffset(page, size) {
    return (page - 1) * size
  }

  offsetToPage(offset, size) {
    return Math.trunc(offset / size) + 1
  }

  changePage(nextPage) {
    const size = this.props.customSize !== undefined ? this.props.customSize : this.props.size
    const offset = this.pageToOffset(nextPage, size)
    if (this.props.ignoreLocation !== true) {
      const route = this.props.location.mergeIn(['query'], { offset, size }).toJS()
      this.context.router.push(route)
    }

    if (this.props.onPageChange) this.props.onPageChange(offset, size, nextPage)
  }

  render() {
    const { t } = this.props
    const size = this.props.customSize !== undefined ? this.props.customSize : this.props.size
    const offset = this.props.customOffset !== undefined ? this.props.customOffset : this.props.offset
    const pages = Math.ceil(this.props.count / size)

    return this.props.loading ? (
      <Segment basic>
        <Loader active inline='centered'>
          {t('Loading::Loading')}
        </Loader>
      </Segment>
    ) : (
      <Fragment>
        {this.props.children}

        <Flex mb='14px' alignItems='center'>
          {this.props.count > 0 && (
            <Label basic>{t('Lists::Total number of results: {{results}}', { results: this.props.count })}</Label>
          )}

          {!!this.props.itemsPerPage && !!this.props.onItemsPerPageChange && (
            <Box marginLeft='auto' fontSize='12px'>
              <Select
                style={{
                  minWidth: 0,
                  marginRight: 4,
                  fontSize: 12,
                }}
                options={ITEMS_PER_PAGE}
                onChange={(_, { value }) => this.props.onItemsPerPageChange(value)}
                value={this.props.itemsPerPage}
              />
              {t('Paginate::Per page')}
            </Box>
          )}
        </Flex>

        <div style={{ textAlign: 'center', marginBottom: 30 }}>
          {this.props.count > 0 && (
            <Pagination
              previous={this.props.previous}
              next={this.props.next}
              buttonsNb={this.props.buttonsNb}
              page={this.offsetToPage(offset, size)}
              maxPage={pages}
              onSelect={this.changePage.bind(this)}
            />
          )}
        </div>
      </Fragment>
    )
  }
}

export default withTranslation('common')(connect(mapStateToProps)(Paginate))
