import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const playlistsActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'playlists' })

export const fetchPlaylists = ({ query } = {}) =>
  playlistsActions.fetch({
    url: '/api/playlists',
    query,
    schema: { playlists: [schemas.playlists] },
  })

export const fetchPlaylist = (id, { query } = {}) =>
  playlistsActions.fetch({
    url: `/api/playlists/${id}`,
    query,
    schema: { playlist: schemas.playlists },
  })

export const updatePlaylist = (id, payload, { query } = {}) =>
  playlistsActions.update({
    url: `/api/playlists/${id}`,
    payload,
    query,
    schema: { playlist: schemas.playlists },
  })

export const createPlaylist = (payload, { query } = {}) =>
  playlistsActions.create({
    url: '/api/playlists',
    payload,
    query,
    schema: { playlist: schemas.playlists },
  })

export const deletePlaylist = (id) =>
  playlistsActions.delete({
    url: `/api/playlists/${id}`,
  })

export const restorePlaylist = (id) =>
  playlistsActions.update({
    url: `/api/playlists/restore/${id}`,
    schema: { playlist: schemas.playlists },
  })
