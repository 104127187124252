import React, { PureComponent } from 'react'
import { EventListSearchForm } from './EventListSearchForm'
import { EventList } from './List'
import PropTypes from 'helpers/proptypes'

export default class EventIndex extends PureComponent {
  static propTypes = { route: PropTypes.object }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.route.type !== nextProps.route.type
  }

  render() {
    return (
      <div>
        <EventListSearchForm eventsType={this.props.route.type} />
        <EventList eventsType={this.props.route.type} />
      </div>
    )
  }
}
