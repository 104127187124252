import React, { useState, useMemo } from 'react'
import PropTypes, { playlistPropTypes } from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getEventsFromIdsArray, getAlgoliaGeoQuery } from 'redux/entities/selectors'
import { Box } from '@vizeat/components/es6/components/Box'
import { Map, Source, Layer } from '@vizeat/components/es6/components/Map'
import { RangeSlider } from '@vizeat/components/es6/components/RangeSlider'
import { MapEventMarker } from './MapEventMarker'
import { debounce } from 'lodash'
import { formatViewportObject, metersToKilometers } from 'helpers/places'
import turfCircle from '@turf/circle'

const RADIUS_VARIATION_STEPS = 50
const INITIAL_ZOOM = 7

export const PlaylistMap = ({ eventsIds, onViewportChange, place, handleChangeForm, form }) => {
  const { t } = useTranslation()
  const algoliaGeoQuery = useSelector((state) => getAlgoliaGeoQuery(state, place?.coordinates?.geometry?.viewport))
  const events = useSelector((state) => getEventsFromIdsArray(state, eventsIds))

  const placeRadius = algoliaGeoQuery.get('aroundPrecision') || 0
  const circleRadius = form.search.radius || placeRadius
  const sliderValue = useMemo(
    // the results returned by the provider may change over time so we need a rounded value
    () => {
      if (!placeRadius) return 0
      return Math.round(((circleRadius - placeRadius) / placeRadius) * 100)
    },
    [circleRadius, placeRadius],
  )
  const geoJSON = useMemo(
    () =>
      turfCircle([place.coordinates.longitude, place.coordinates.latitude], metersToKilometers(circleRadius), {
        steps: 30,
        units: 'kilometers',
      }).geometry,
    [place.coordinates.latitude, place.coordinates.longitude, circleRadius],
  )

  const [zoom, setZoom] = useState(INITIAL_ZOOM)

  function handleViewportChange({ latitude, longitude, zoom } = {}, bounds = {}) {
    const northeast = bounds?.getNorthEast()
    const southwest = bounds?.getSouthWest()
    const geometry = { viewport: { northeast, southwest } }
    const coordinates = { latitude, longitude, geometry }
    const viewport = formatViewportObject(northeast.lat, northeast.lng, southwest.lat, southwest.lng)
    onViewportChange(coordinates, viewport)
    setZoom(zoom)
  }

  const handleBounds = debounce(handleViewportChange, 500, { trailing: true })

  function onSliderChange(value) {
    handleChangeForm({
      search: { ...form.search, ...(value && { radius: placeRadius + placeRadius * (value / 100) }) },
    })
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box
        width='100%'
        height='600px'
        mb='16px'
        css={`
          .mapboxgl-ctrl-group {
            position: absolute;
            bottom: 0;
          }
        `}
      >
        <Map
          mapViewport={{ latitude: place.coordinates.latitude, longitude: place.coordinates.longitude, zoom }}
          onViewportChange={handleBounds}
        >
          <div>
            <Source id='events_wrapper' type='geojson' data={geoJSON}>
              <Layer
                source='events_wrapper'
                type='fill'
                paint={{
                  'fill-opacity': 0.2,
                  'fill-color': '#007cbf',
                  'fill-outline-color': '#0044bf',
                }}
              />
            </Source>
            {events.map((event) => (
              <MapEventMarker key={event.id} place={event.place} />
            ))}
          </div>
        </Map>
      </Box>

      <RangeSlider
        limitMin={RADIUS_VARIATION_STEPS * -1}
        limitMax={RADIUS_VARIATION_STEPS}
        value={sliderValue}
        renderValueInfo={(value) =>
          value === 0 ? t('Playlists::Summary::Default radius') : t('Playlists::Summary::{{value}} %', { value })
        }
        onValueChange={onSliderChange}
      />
    </div>
  )
}

PlaylistMap.propTypes = {
  eventsIds: PropTypes.arrayOf(PropTypes.number),
  place: PropTypes.shape({
    coordinates: PropTypes.shape({
      latitude: undefined,
      longitude: undefined,
      zoom: undefined,
      radius: undefined,
      geometry: PropTypes.shape({
        viewport: PropTypes.shape({}),
      }),
    }),
  }).isRequired,
  onViewportChange: PropTypes.func.isRequired,
  handleChangeForm: PropTypes.func.isRequired,
  form: playlistPropTypes.isRequired,
}

PlaylistMap.defaultProps = {
  eventsIds: [],
}
