import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { List } from 'immutable'
import { connect } from 'react-redux'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
// components
import { Form, Input, Grid, Message, Checkbox } from 'semantic-ui-react'
import { EditPlaceInput } from 'components/inputs'
import { SectionMedia, SectionCapacityAndTime, SectionDeadlines, SectionDescription } from './sections'
import { CopyToClipboardButton } from 'components/buttons'
// styles
import './index.css'
// helpers
import { buildOptionsWithArray } from 'helpers/forms'
import { konstants, numbers } from '@vizeat/helpers'
// redux
import { createForm, removeForm, mergeInForm } from 'redux/forms/actions'
import { fetchSchedulesByEvent } from 'redux/entities/actions'
import { getForm } from 'redux/forms/reducer'
import {
  getSortedAmenities,
  getSortedTags,
  fetchingStaticResource,
  fetchingEvent,
  fetchingSchedule,
  updatingEvent,
  hasOpenedSchedules,
} from 'redux/entities/selectors'
import { getExperienceAppearanceInSearchError } from 'helpers/events'
import { places } from '../../../../redux/entities/schemas/places'

const {
  DEFAULT_BOOKING_DEADLINE,
  DEFAULT_PE_BOOKING_DEADLINE,
  DEFAULT_CANCELLATION_DEADLINE,
  DEFAULT_PE_CANCELLATION_DEADLINE,
} = konstants

const { formatPriceAsFloat, formatPriceAsInt } = numbers

const mapStateToProps = (state, props) => ({
  fromStore: {
    statics: {
      amenities: getSortedAmenities(state),
      tags: getSortedTags(state),
      loading: fetchingStaticResource(state),
    },
    form: getForm(state, 'event'),
    updating: updatingEvent(state, props.event.id),
    fetching: fetchingEvent(state, props.event.id),
    isFetchingEventSchedules: fetchingSchedule(state, props.event.id),
    hasOpenedScheduledEvents: hasOpenedSchedules(state, props.event.id),
  },
})
const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    createForm: ({ initialState }) => dispatch(createForm({ formName: 'event', initialState })),
    removeForm: () => dispatch(removeForm({ formName: 'event' })),
    mergeInForm: (formData) => dispatch(mergeInForm({ formName: 'event', value: formData })),
    fetchSchedules: ({ start, end }) => dispatch(fetchSchedulesByEvent(props.event.id, { start, end })),
  },
})

class _EventForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fromStore: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      statics: PropTypes.object.isRequired,
      form: PropTypes.immutable.map,
      updating: PropTypes.bool,
      fetching: PropTypes.bool,
      isFetchingEventSchedules: PropTypes.bool,
      hasOpenedScheduledEvents: PropTypes.bool,
    }).isRequired,
    actions: PropTypes.shape({
      createForm: PropTypes.func.isRequired,
      removeForm: PropTypes.func.isRequired,
      mergeInForm: PropTypes.func.isRequired,
      fetchSchedules: PropTypes.func.isRequired,
    }).isRequired,
    event: PropTypes.immutable.record.isRequired,
  }

  UNSAFE_componentWillMount() {
    const { event, actions } = this.props
    actions.createForm({ initialState: this.eventEntityToEventForm(event) })
    this.setState({
      place: event.place || {},
    })
  }

  UNSAFE_componentWillReceiveProps({ event, fromStore, actions }) {
    if (event.id && !event.equals(this.props.event)) {
      const nextFormState = this.eventEntityToEventForm(event)
      actions.mergeInForm(nextFormState)
      this.setState({ place: event.place || {} })
    }
  }

  componentWillUnmount() {
    this.props.actions.removeForm()
  }

  componentDidMount() {
    this.props.actions.fetchSchedules({
      start: moment.utc(),
      end: moment.utc().add(3, 'month'),
    })
  }

  formatPlace(amenities, eventPlace) {
    return eventPlace.merge({
      amenities_ids: amenities.map(({ id }) => id),
      ...(isNaN(eventPlace.lat) && { lat: eventPlace.getIn(['coordinates', 'latitude']) }),
      ...(isNaN(eventPlace.lng) && { lng: eventPlace.getIn(['coordinates', 'longitude']) }),
    })
  }

  eventEntityToEventForm(event) {
    const { alcohols = [], diets = [], cover = {}, descriptions = [] } = event
    return {
      ...(event.place && { place: this.formatPlace(event.place.amenities, event.place) }),
      alcohols_ids: alcohols.map(({ id }) => id),
      cover: cover.uploadcare_id || '',
      delete_pictures_ids: [],
      descriptions,
      diets_ids: diets.map(({ id }) => id),
      begins_at: (event.begins_at || '').slice(0, 5),
      ends_at: (event.ends_at || '').slice(0, 5),
      food_id: event.food_id,
      max_seats: event.max_seats,
      min_seats: event.min_seats,
      price: event.price || 0,
      fees_rate: event.fees_rate,
      sources: (event.files || [])
        .filter((f) => f.category === 'default')
        .map((f) => f.uploadcare_id)
        .filter((uid) => !!uid),
      foods: (event.files || [])
        .filter((f) => f.category === 'food')
        .map((f) => f.uploadcare_id)
        .filter((uid) => !!uid),
      venues: (event.files || [])
        .filter((f) => f.category === 'venue')
        .map((f) => f.uploadcare_id)
        .filter((uid) => !!uid),
      tags: event.tags,
      title: event.title,
      type: event.type,
      followup: event.followups.size >= 1 ? event.followups.sort((a, b) => a.id - b.id).last().comment : '',
      booking_deadline: moment.duration(event.booking_deadline || DEFAULT_BOOKING_DEADLINE).valueOf(),
      pe_booking_deadline: moment.duration(event.pe_booking_deadline || DEFAULT_PE_BOOKING_DEADLINE).valueOf(),
      cancellation_deadline: moment.duration(event.cancellation_deadline || DEFAULT_CANCELLATION_DEADLINE).valueOf(),
      pe_cancellation_deadline: moment
        .duration(event.pe_cancellation_deadline || DEFAULT_PE_CANCELLATION_DEADLINE)
        .valueOf(),
      allow_private_bookings: event.allow_private_bookings,
      allow_public_bookings: event.allow_public_bookings,
      instant_booking: event.instant_booking,
    }
  }

  handleAmenityChecked = (_, { value }) => {
    const {
      fromStore: { form },
      actions,
    } = this.props

    let amenitiesIdsSet = form.getIn(['place', 'amenities_ids']).toSet()
    amenitiesIdsSet = amenitiesIdsSet.has(value) ? amenitiesIdsSet.delete(value) : amenitiesIdsSet.add(value)

    const place = form.get('place').set('amenities_ids', amenitiesIdsSet.toList())
    this.setState({ place }, () => actions.mergeInForm({ place }))
  }

  onEditPlace = () => this.props.actions.mergeInForm({ place: this.state.place })

  onCancelPlace = () => {
    this.props.actions.mergeInForm({ place: undefined })
    this.setState({ place: this.props.event.place || {} })
  }

  handlePlaceChange = ({ item }) => {
    const { fromStore, actions } = this.props
    const amenities = fromStore.form.getIn(['place', 'amenities_ids']).map((id) => ({ id }))
    const place = this.formatPlace(amenities, places.Record(item))
    this.setState({ place }, () => actions.mergeInForm({ place }))
  }

  handleInstantBookingToggle = (_e, { checked }) => {
    this.props.actions.mergeInForm({ instant_booking: checked })
  }

  flagEvent = (e, { rating }) => {
    let tags = this.props.fromStore.form.get('tags', new List()).toJS()

    tags = rating === 1 ? ['flag-event'].concat(tags) : tags.filter((tag) => tag !== 'flag-event')
    this.props.actions.mergeInForm({ tags })
  }

  getFormattedPriceWithFees() {
    const {
      event,
      fromStore: { form },
    } = this.props
    const price = form.get('price', 0)
    const fees = price * form.get('fees_rate', 0)
    const priceWithFees = price + fees

    return formatPriceAsFloat(priceWithFees, event.user.currency)
  }

  render() {
    const {
      t,
      event,
      fromStore: {
        form,
        fetching,
        isFetchingEventSchedules,
        hasOpenedScheduledEvents,
        updating,
        statics: { tags, amenities },
      },
      actions,
    } = this.props
    const addressHref = `https://www.google.com/maps/place/${encodeURIComponent(form.getIn(['place', 'address']))}`
    const isEventFrozen = !!event.frozen_at

    const searchError = getExperienceAppearanceInSearchError(event, hasOpenedScheduledEvents)

    return (
      <Form className='vz-event-id-form' loading={!!fetching || !!updating}>
        {!isFetchingEventSchedules && searchError.hasError && (
          <section id='section-error-infos'>
            <Message negative>
              <Message.Header>
                {t(
                  'Experiences::Summary::This experience will not be displayed in the search for the following reasons:',
                )}
              </Message.Header>
              <Message.List>
                {searchError.errors.doesNotHaveVizeatTag && (
                  <Message.Item>{t('Experiences::Summary::The experience does not have the vizeat tag')}</Message.Item>
                )}
                {searchError.errors.hostHasChargesDisabled && (
                  <Message.Item>
                    {t('Experiences::Summary::The host has not completed the payment settings')}
                  </Message.Item>
                )}
                {searchError.errors.eventIsUnpublished && (
                  <Message.Item>{t('Experiences::Summary::The experience is not published')}</Message.Item>
                )}
                {searchError.errors.hasFutureDateClosed && (
                  <Message.Item>{t('Experiences::Summary::The experience has no scheduled dates')}</Message.Item>
                )}
                {searchError.errors.reindexHasNotFinished && (
                  <Message.Item>
                    {t('Experiences::Summary::Reindexing the event may not have completed yet')}
                  </Message.Item>
                )}
              </Message.List>
            </Message>
          </section>
        )}

        <section id='section-tags'>
          <h3 className='section-title'>{t('Experiences::Summary::Experience Tags')}</h3>
          <Form.Dropdown
            search
            multiple
            selection
            value={form.get('tags', new List()).toJS()}
            onChange={(e, { value }) => actions.mergeInForm({ tags: value })}
            options={buildOptionsWithArray(tags.map((t) => t.title))}
          />
          <Form.Checkbox
            toggle
            checked={form.get('instant_booking')}
            label={
              form.get('instant_booking')
                ? t('Experiences::Summary::Disable instant booking')
                : t('Experiences::Summary::Enable instant booking')
            }
            onClick={this.handleInstantBookingToggle}
          />
        </section>

        <section id='booking-modes'>
          <h3 className='section-title'>{t('Experiences::Summary::Booking Modes')}</h3>
          <Form.Field
            control={Checkbox}
            label={t('Experiences::Summary::Allow open events booking')}
            onChange={(e, { checked }) => actions.mergeInForm({ allow_public_bookings: checked })}
            checked={form.get('allow_public_bookings')}
          />
          <Form.Field
            control={Checkbox}
            label={t('Experiences::Summary::Allow private events booking')}
            onChange={(e, { checked }) => actions.mergeInForm({ allow_private_bookings: checked })}
            checked={form.get('allow_private_bookings')}
          />
        </section>

        <section id='comment'>
          <h3 className='section-title'>{t('Experiences::Summary::Community comment')}🦄 🌈</h3>
          <Form.Field>
            <Form.TextArea
              placeholder={t('Experiences::Summary::Leave a comment')}
              style={{ backgroundColor: '#ffffe6' }}
              value={form.get('followup', '')}
              onChange={(e, { value }) => actions.mergeInForm({ followup: value })}
            />
          </Form.Field>
        </section>

        <SectionDescription event={event} />

        <section id='section-pricing'>
          <h3 className='section-title'>{t('Experiences::Summary::Pricing')}</h3>
          <Grid columns='equal'>
            <Grid.Column floated='left'>
              <Input
                type='number'
                min='0'
                label={event.user.currency.iso_3}
                labelPosition='right'
                value={formatPriceAsFloat(form.get('price', 0), event.user.currency)}
                onChange={(e, { value }) =>
                  actions.mergeInForm({
                    price: formatPriceAsInt(isNaN(parseFloat(value)) ? 0 : value, event.user.currency),
                  })
                }
                disabled={isEventFrozen}
              />
            </Grid.Column>

            <Grid.Column floated='right'>
              <label style={{ fontWeight: 'bold', marginRight: 8 }}>{t('Experiences::Summary::Including Fees')}</label>
              <Input
                type='number'
                min='0'
                label={event.user.currency.iso_3}
                labelPosition='right'
                value={this.getFormattedPriceWithFees()}
                disabled
              />
            </Grid.Column>
          </Grid>

          <h3 className='section-title'>{t('Experiences::Summary::Fees')}</h3>
          <Input
            label='%'
            labelPosition='right'
            value={form.get('fees_rate', 0) * 100}
            onChange={(e, { value }) => actions.mergeInForm({ fees_rate: parseInt(value) / 100 })}
            disabled={isEventFrozen}
            type='number'
          />
        </section>

        <SectionCapacityAndTime isEventFrozen={isEventFrozen} />

        <SectionDeadlines isEventFrozen={isEventFrozen} />

        <section id='section-amenities'>
          <h3 className='section-title'>{t('Experiences::Summary::Amenities')}</h3>
          <Form.Group>
            <Grid>
              <Grid.Row>
                {amenities.map((amenity) => (
                  <Grid.Column key={amenity.id} width={4} style={{ marginBottom: 15 }}>
                    <Form.Checkbox
                      label={amenity.title}
                      value={amenity.id}
                      onChange={this.handleAmenityChecked}
                      checked={form.getIn(['place', 'amenities_ids'])?.includes(amenity.id)}
                      disabled={isEventFrozen}
                    />
                  </Grid.Column>
                ))}
              </Grid.Row>
            </Grid>
          </Form.Group>
        </section>

        <section id='section-address'>
          <h3 className='section-title'>{t('Experiences::Summary::Address & Map')}</h3>

          <Form.Field disabled={isEventFrozen}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <label>{t('Experiences::Summary::Formatted address')}</label>
                  <EditPlaceInput
                    onEdit={this.onEditPlace}
                    onCancel={this.onCancelPlace}
                    locale={event.user.account.locale}
                    defaultValue={this.state.place}
                    onPlaceChanged={this.handlePlaceChange}
                    forced
                  />
                  {form.getIn(['place', 'address']) && (
                    <a href={addressHref} target='_blank' rel='noopener noreferrer'>
                      {t('Experiences::Summary::View in google maps')}
                    </a>
                  )}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Grid.Row>
                    <label>{t('Experiences::Summary::Address')}</label>
                  </Grid.Row>
                  <Grid.Row>{this.state.place.address}</Grid.Row>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Grid.Row>
                    <label>{t('Experiences::Summary::City')}</label>
                  </Grid.Row>
                  <Grid.Row>{this.state.place.locality}</Grid.Row>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Grid.Row>
                    <label>{t('Experiences::Summary::Zip code')}</label>
                  </Grid.Row>
                  <Grid.Row>{this.state.place.postal_code}</Grid.Row>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Grid.Row>
                    <label>{t('Experiences::Summary::Country')}</label>
                  </Grid.Row>
                  <Grid.Row>{this.state.place.country}</Grid.Row>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form.Field>
          <br />
          {this.state.place && (
            <Form.Field disabled={isEventFrozen} style={{ position: 'relative' }}>
              <label>{t('Experiences::Summary::Additional information')}</label>
              <Form.TextArea
                placeholder={t('Experiences::Summary::Additional information')}
                value={this.state.place.additional_info}
                style={{ resize: 'vertical', paddingRight: 32 }}
                onChange={(_, { value }) => {
                  let place = this.state.place.set('additional_info', value)
                  place = place.set('lat', this.state.place.coordinates.get('latitude'))
                  place = place.set('lng', this.state.place.coordinates.get('longitude'))
                  this.setState({ place }, () => actions.mergeInForm({ place }))
                }}
              />
              <CopyToClipboardButton text={this.state.place.additional_info || ''} style={{ top: 48, right: 4 }} />
            </Form.Field>
          )}
        </section>
        <SectionMedia event={event} />
      </Form>
    )
  }
}

export const EventForm = withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(_EventForm))
