import { schema } from 'normalizr'
import { Record } from 'immutable'
import { partners } from './partners'

const _schema = new schema.Entity('references', {
  partner: partners.schema,
})

const _Record = Record({
  id: undefined,
  code: undefined,
  partner: new partners.Record(),
})

export const references = {
  schema: _schema,
  Record: _Record,
}
