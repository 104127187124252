// libs
import React from 'react'
import PropTypes from 'helpers/proptypes'
// components
import { Tooltip } from '@vizeat/components/es6/components/Tooltip'
import { Icon } from 'semantic-ui-react'

import './IconButton.css'

export const IconButton = ({
  name,
  color,
  style,
  size,
  loading,
  disabled,
  onClick,
  onMouseLeave,
  tooltipContent,
  tooltipClassName,
  alignTooltipLeft,
}) => {
  const tooltipContentLength = tooltipContent?.trim().length ?? 0
  const IconElement = <Icon name={name} loading={loading} style={{ color }} size={size} />

  return (
    <button
      type='button'
      className='IconButton'
      disabled={disabled}
      style={style}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
    >
      {tooltipContentLength > 0 ? (
        <Tooltip
          className={tooltipClassName}
          position='top'
          alignLeft={alignTooltipLeft}
          renderContent={() => tooltipContent}
        >
          {IconElement}
        </Tooltip>
      ) : (
        IconElement
      )}
    </button>
  )
}

IconButton.propTypes = {
  name: PropTypes.string.isRequired,
  tooltipContent: PropTypes.string,
  tooltipClassName: PropTypes.string,
  alignTooltipLeft: PropTypes.bool,
  size: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.shape({}),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func,
}

IconButton.defaultProps = {
  tooltipContent: undefined,
  tooltipClassName: undefined,
  alignTooltipLeft: false,
  size: 'large',
  color: '#767676',
  style: undefined,
  loading: false,
  disabled: false,
  onMouseLeave: undefined,
}
