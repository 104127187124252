import { schema } from 'normalizr'
import { Record } from 'immutable'

const _schema = new schema.Entity('invites', {}, { idAttribute: 'invite_token' })

const _Record = Record({
  invite_token: undefined,
  date: undefined,
  event_id: undefined,
  request_id: undefined,
})

export const invites = {
  schema: _schema,
  Record: _Record,
}
