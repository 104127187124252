// Libraries
import React, { Fragment, PureComponent } from 'react'
// Components
import { OrganizationSearchForm } from './OrganizationSearchForm'
import { OrganizationList } from './OrganizationList'

export class OrganizationsIndex extends PureComponent {
  render() {
    return (
      <Fragment>
        <OrganizationSearchForm />
        <OrganizationList />
      </Fragment>
    )
  }
}
