import { schema } from 'normalizr'
import { Record, List } from 'immutable'

import { bookings } from './bookings'
import { events } from './events'
import { partners } from './partners'
import { places } from './places'
import { users } from './users'

const _schema = new schema.Entity('requests', {
  bookings: [bookings.schema],
  event: events.schema,
  partner: partners.schema,
  place: places.schema,
  user: users.schema,
})

const _Record = Record({
  additional_info: undefined,
  approved_at: undefined,
  begins_at: undefined,
  booking_ids: new List(),
  bookings: [new bookings.Record()],
  canceled_at: undefined,
  canceled_by: undefined,
  chosen_dates: new List(),
  conversation_id: undefined,
  conversation: undefined,
  coupon_code: undefined,
  created_at: undefined,
  date: undefined,
  event_id: undefined,
  event: new events.Record(),
  expired_at: undefined,
  followups: new List(),
  id: undefined,
  occasion: undefined,
  partner_id: undefined,
  partner: new partners.Record(),
  place_id: undefined,
  place: new places.Record(),
  privatized_at: undefined,
  seats: undefined,
  updated_at: undefined,
  user_id: undefined,
  user: new users.Record(),
  will_expire_at: undefined,
  partner_demand_id: undefined,
})

export const requests = {
  schema: _schema,
  Record: _Record,
}
