// libs
import React, { PureComponent } from 'react'
// Components
import { CampaignSearchForm } from './SearchForm'
import { CampaignsList } from './CampaignsList'

export default class CampaignsIndex extends PureComponent {
  render () {
    return (
      <div>
        <CampaignSearchForm />
        <CampaignsList />
      </div>
    )
  }
}
