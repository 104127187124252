import { getEntities, isDeleting, isUpdating, getError, getEntity } from './_utils'
import { getLanguage } from './statics'

export const getAccounts = getEntities('accounts')
export const getAccount = (state, { id }) => {
  const account = getEntity('accounts')(state, { id })
  return account.set('language', getLanguage(state, { id: account.language_id || account.language }))
}

export const deletingAccount = (state, id) => isDeleting(`/api/accounts/${id}`)(state)
export const updatingAccount = (state, id) => isUpdating(`/api/accounts/${id}`)(state)

export const getAccountError = (state, id) => getError(`/api/accounts/${id}`)(state)
