import { screenApi } from './api'

export const getFullPath = (location) => {
  if (!location) return null
  return !location.pathname
    ? location.get('pathname') + location.get('search') // Immutable
    : location.pathname + location.search // plain JS
}

export const shortenLink = async (link, referrer) => {
  const { data } = await screenApi.post('/shorten', {
    url: link,
    referrer,
  })
  return data && data.url.shortenUrl
}
