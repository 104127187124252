/* eslint-disable import/first */
import React from 'react'
import { render, hydrate } from 'react-dom'
import settings from 'settings'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { Router, browserHistory, RouterContext } from 'react-router'
import routes from './router'
import { syncHistoryWithStore } from 'react-router-redux'
import { Provider } from 'react-redux'
import { fromJS } from 'immutable'
import createStore from 'redux/createStore'
// i18n
import { I18nextProvider } from 'react-i18next'
import i18n from 'i18n-client'
// style
import { ThemeProvider } from 'styled-components'
import { eatwithTheme } from '@vizeat/components/es6/theme'
import 'semantic-ui-css/semantic.min.css'

if (settings.sentry.dsn) {
  Sentry.init({
    dsn: settings.sentry.dsn,
    environment: settings.sentry.environment || 'development',
    release: settings.sentry.release,

    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: parseFloat(settings.sentry.tracesSampleRate) || 0,
  })
}

const store = createStore(fromJS({}))
const history = syncHistoryWithStore(browserHistory, store, {
  selectLocationState(state) {
    return state.get('routing').toJS()
  },
})

const App = () => (
  <ThemeProvider theme={eatwithTheme}>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <Router history={history} render={(props) => <RouterContext {...props} />}>
          {routes}
        </Router>
      </Provider>
    </I18nextProvider>
  </ThemeProvider>
)

render(<App />, document.getElementById('root'))

i18n.on('languageChanged', () => {
  hydrate(<App />, document.getElementById('root'))
})
