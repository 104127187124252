// Libraries
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
// Components
import { Form } from 'semantic-ui-react'
// Redux
import { getCurrencies } from 'redux/entities/selectors'

const mapStateToProps = (state) => ({
  fromStore: {
    currencies: getCurrencies(state),
  },
})

const _SelectCurrency = ({ isDisabled, currencyId, error, fromStore: { currencies }, handleCurrencyChange }) => {
  const [t] = useTranslation()
  const getCurrencyOptions = () => {
    return currencies
      .toList()
      .toJS()
      .map(({ id, iso_3: iso3 }) => ({ key: id, value: id, text: iso3 }))
  }

  return (
    <Form.Select
      disabled={isDisabled}
      error={error}
      placeholder={t('Inputs::Select...')}
      value={currencyId}
      options={getCurrencyOptions()}
      onChange={(e, { value }) => handleCurrencyChange(value)}
    />
  )
}

_SelectCurrency.propTypes = {
  isDisabled: PropTypes.bool,
  currencyId: PropTypes.number,
  error: PropTypes.bool,
  fromStore: PropTypes.shape({
    currencies: PropTypes.immutable.map.isRequired,
  }).isRequired,
  handleCurrencyChange: PropTypes.func.isRequired,
}

_SelectCurrency.defaultProps = {
  currencyId: undefined,
  isDisabled: false,
  error: false,
}

export const SelectCurrency = connect(mapStateToProps)(_SelectCurrency)
