import {
  getEntities,
  getEntity,
  isFetching,
  isUpdating,
  isCreating,
  isDeleting,
  getError,
  getEntityTotal,
  getEntityOrder,
} from './_utils'
import { getFile } from './files'

export const getPlaylists = getEntities('playlists')
export const getPlaylistsTotal = getEntityTotal('playlists')
export const getPlaylistsOrder = getEntityOrder('playlists')
export const getPlaylistsNextAllowedOrder = (state) => state.getIn(['entities', 'metadata', 'playlists', 'next_order'])
export const getPlaylist = (state, { id }) => {
  const playlist = getEntity('playlists')(state, { id })
  return playlist.set('cover', getFile(state, { id: playlist.cover_id || playlist.cover }))
}

export const getPlaylistsList = (state) => getPlaylistsOrder(state).map((id) => getPlaylist(state, { id }))

export const fetchingPlaylists = isFetching('/api/playlists')
export const fetchingPlaylist = (state, id) => isFetching(`/api/playlists/${id}`)(state)
export const updatingPlaylist = (state, id) => isUpdating(`/api/playlists/${id}`)(state)
export const updatingRestorePlaylist = (state, id) => isUpdating(`/api/playlists/restore/${id}`)(state)
export const creatingPlaylist = (state) => isCreating('/api/playlists')(state)
export const deletingPlaylist = (state, id) => isDeleting(`/api/playlists/${id}`)(state)

export const getPlaylistsError = getError('/api/playlists')
export const getFetchPlaylistsError = (state) => state.getIn(['entities', 'errors', `GET /api/playlists`])
export const getPlaylistError = (state, id) => getError(`/api/playlists/${id}`)(state)
export const getRestorePlaylistError = (state, id) => getError(`/api/playlists/restore/${id}`)(state)
