import { schema } from 'normalizr'
import { Record, Map, List } from 'immutable'
import { files } from './files'
import { events } from './events'

const _schema = new schema.Entity('playlists', {
  cover: files.schema,
  events: [events.schema],
})

const _Record = Record({
  id: undefined,
  type: '',
  order: undefined,
  zone: '',
  display_type: '',
  title: '',
  body: '',
  cover: new files.Record(),
  events: new List(),
  key_words: new Map({
    locality: undefined,
    country: undefined,
  }),
  search: new Map({
    includeEventIds: undefined,
    radius: undefined,
  }),
  tags: new List(),
  created_at: undefined,
  updated_at: undefined,
  deleted_at: undefined,
})

export const playlists = {
  schema: _schema,
  Record: _Record,
}
