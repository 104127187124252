import { createSelector } from 'reselect'
import moment from 'moment'
import { emptySchedule } from 'redux/entities/schemas/schedules'

import { getEvent } from './events'
import { getBooking } from './bookings'
import { getRequest } from './requests'
import { getOverride } from './overrides'
import { getEntity, getEntities, isFetching, getError, getCreatingError } from './_utils'

export const isEventSoldOut = ({ bookedSeats, maxSeats }) => bookedSeats >= maxSeats
export const isEventAboveMinSeats = ({ bookedSeats, minSeats, maxSeats }) =>
  bookedSeats > 0 && bookedSeats >= minSeats && bookedSeats < maxSeats
export const isEventBelowMinSeats = ({ bookedSeats, minSeats }) => bookedSeats > 0 && bookedSeats < minSeats
export const hasEventNoBookings = ({ bookedSeats }) => bookedSeats === 0

const isEventVisible = ({
  bookedSeats,
  minSeats,
  maxSeats,
  isPrivate,
  isDemoEvent,
  isInstantBooking,
  statuses = 'private,full,abovemin,belowmin',
}) => {
  return statuses.split(',').reduce((acc, status) => {
    if (status === 'nobooking') return hasEventNoBookings({ bookedSeats }) || acc
    if (status === 'belowmin') return isEventBelowMinSeats({ bookedSeats, minSeats }) || acc
    if (status === 'abovemin') return isEventAboveMinSeats({ bookedSeats, minSeats, maxSeats }) || acc
    if (status === 'full') return isEventSoldOut({ bookedSeats, maxSeats }) || acc
    if (status === 'private') return isPrivate || acc
    if (status === 'public') return !isPrivate || acc
    if (status === 'demo') return isDemoEvent || acc
    if (status === 'instant') return isInstantBooking || acc
    return acc
  }, false)
}

export const getScheduleError = getError('/api/planning')
export const getCreatingScheduleError = getCreatingError('/api/planning')
export const getSchedulesError = getError('/api/plannings')
export const fetchingSchedules = isFetching('/api/plannings')
export const fetchingSchedule = (state, id) => isFetching(`/api/planning/${id}`)(state)

export const getArgs = (state, mDate, id) => ({ state, mDate, id })
export const getSchedules = getEntities('schedules')

export const getSchedulesList = (state, { month }) => {
  return getSchedules(state)
    .filter((schedule) => moment(schedule.date).isSame(month, 'month')) // MomentJS handles if month in the same year as well
    .toList()
    .sort()
}

const getScheduleEvents = (state, { date }) => {
  const schedule = getEntity('schedules')(state, { id: date })
  const openedEvents = schedule.get('opened_event_ids').map((id) => getEvent(state, { id }))
  const eventsWithRequest = schedule.get('request_ids').map((id) => {
    const request = getRequest(state, { id })
    return getEvent(state, { id: request.event_id })
  })
  return openedEvents.merge(eventsWithRequest).toSet().toList()
}

export const getScheduleVisibleEvents = (state, { date, statuses }) => {
  return getScheduleEvents(state, { date })
    .map((event) => getScheduleEventWithOverrides(state, { date, eventId: event.id }))
    .filter((eventWithOverrides) =>
      isEventVisible({
        bookedSeats: getScheduleEventBookedSeats(state, { date, eventId: eventWithOverrides.id }),
        minSeats: eventWithOverrides.min_seats,
        maxSeats: eventWithOverrides.max_seats,
        isDemoEvent: eventWithOverrides.tags.includes('demo_event'),
        isInstantBooking: eventWithOverrides.instant_booking,
        isPrivate:
          !!eventWithOverrides.privatized_at ||
          hasScheduleEventPrivateRequest(state, { date, eventId: eventWithOverrides.id }),
        statuses: statuses,
      }),
    )
    .sort((a, b) => {
      // Items listed in the date tooltip must be ordered as follows:
      // Pending bookings
      const pendingBookingSeatsForA = getScheduleEventPendingSeats(state, { date, eventId: a.id })
      const pendingBookingSeatsForB = getScheduleEventPendingSeats(state, { date, eventId: b.id })
      if (pendingBookingSeatsForA > 0) return -1
      if (pendingBookingSeatsForB > 0) return 1
      // Pending requests
      const pendingRequestSeatsForA = getScheduleEventPendingRequestedSeats(state, { date, eventId: a.id })
      const pendingRequestSeatsForB = getScheduleEventPendingRequestedSeats(state, { date, eventId: b.id })
      if (pendingRequestSeatsForA > 0) return -1
      if (pendingRequestSeatsForB > 0) return 1
      // PE without bookings
      const aIsPrivate = !!a.privatized_at || hasScheduleEventPrivateRequest(state, { date, eventId: a.id })
      const bIsPrivate = !!b.privatized_at || hasScheduleEventPrivateRequest(state, { date, eventId: b.id })
      const bookedSeatsForA = getScheduleEventBookedSeats(state, { date, eventId: a.id })
      const bookedSeatsForB = getScheduleEventBookedSeats(state, { date, eventId: b.id })
      if (aIsPrivate && hasEventNoBookings({ bookedSeats: bookedSeatsForA })) return -1
      if (bIsPrivate && hasEventNoBookings({ bookedSeats: bookedSeatsForB })) return 1
      // Bookings
      // Below min
      if (isEventBelowMinSeats({ bookedSeats: bookedSeatsForA, minSeats: a.min_seats })) return -1
      if (isEventBelowMinSeats({ bookedSeats: bookedSeatsForB, minSeats: b.min_seats })) return 1
      // Above min with available seats
      if (isEventAboveMinSeats({ bookedSeats: bookedSeatsForA, minSeats: a.min_seats, maxSeats: a.max_seats }))
        return -1
      if (isEventAboveMinSeats({ bookedSeats: bookedSeatsForB, minSeats: b.min_seats, maxSeats: b.max_seats })) return 1
      // Sold out
      if (isEventSoldOut({ bookedSeats: bookedSeatsForA, maxSeats: a.max_seats })) return -1
      if (isEventSoldOut({ bookedSeats: bookedSeatsForB, maxSeats: b.max_seats })) return 1
      // Scheduled
      if (hasEventNoBookings({ bookedSeats: bookedSeatsForA })) return -1
      if (hasEventNoBookings({ bookedSeats: bookedSeatsForB })) return 1
      // else
      return 0
    })
}

export const getScheduleEventWithOverrides = (state, { date, eventId }) => {
  const event = getEvent(state, { id: eventId })
  const overrides = getOverride(state, { id: `${eventId}-${date}` })
  return overrides.date ? event.mergeWith((a, b) => (b === undefined ? a : b), overrides) : event
}

export const getScheduleEventBookedSeats = (state, { date, eventId }) => {
  const schedule = getEntity('schedules')(state, { id: date })
  return schedule.booked_seats.get(String(eventId)) || 0
}

export const getScheduleEventPendingSeats = (state, { date, eventId }) => {
  const schedule = getEntity('schedules')(state, { id: date })
  return schedule.booking_ids
    .map((id) => getBooking(state, { id }))
    .filter((booking) => {
      return booking.event_id === eventId
    })
    .reduce((acc, booking) => {
      return booking.status === 'tentative' ? (acc += booking.seats) : acc
    }, 0)
}

export const getScheduleEventRequests = (state, { date, eventId }) => {
  const schedule = getEntity('schedules')(state, { id: date })
  return schedule.request_ids.map((id) => getRequest(state, { id })).filter((request) => request.event_id === eventId)
}

export const getScheduleEventBookings = (state, { date, eventId }) => {
  const schedule = getEntity('schedules')(state, { id: date })
  return schedule.booking_ids
    .map((id) => getBooking(state, { id }))
    .filter((booking) => {
      const bookingEventId = booking.event_id || booking.event.id
      return bookingEventId === eventId
    })
}

export const hasSchedulePartnerDemand = createSelector(
  getScheduleEventRequests,
  getScheduleEventBookings,
  (requests, bookings) => {
    return requests
      .concat(bookings)
      .reduce((acc, { partner_demand_id: partnerDemandId }) => acc || partnerDemandId, false)
  },
)

export const getScheduleEventPendingRequestedSeats = (state, { date, eventId }) =>
  getScheduleEventRequests(state, { date, eventId }).reduce(
    (acc, request) => (acc += !request.approved_at && !request.cancelled_at && !request.expired_at ? request.seats : 0),
    0,
  )

export const getScheduleEventRequestedSeats = (state, { date, eventId }) =>
  getScheduleEventRequests(state, { date, eventId }).reduce((acc, request) => (acc += request.seats), 0)

export const hasScheduleEventPrivateRequest = (state, { date, eventId }) =>
  getScheduleEventRequests(state, { date, eventId }).reduce((acc, request) => !!request.privatized_at || acc, false)

export const getSchedule = createSelector(getSchedules, getArgs, (schedules, { mDate }) => {
  if (!mDate || !moment.isMoment(mDate) || !mDate.isValid()) {
    throw new Error("Can't call getSchedule without a valid moment date")
  }
  return schedules.get(mDate.format('YYYY-MM-DD'), emptySchedule)
})

export const getIsBookableSchedule = createSelector(getSchedule, getArgs, (schedule, { id }) =>
  schedule.bookable_event_ids.includes(id),
)

export const getRequestableSchedules = createSelector(getSchedule, getArgs, (schedule, { id }) =>
  schedule.requestable_event_ids.includes(id),
)

export const getIsClosedSchedule = createSelector(getSchedule, getArgs, (schedule, { id }) =>
  schedule.closed_event_ids.includes(id),
)

export const hasOpenedSchedules = createSelector(
  getSchedules,
  (_, id) => id,
  (schedules, id) => schedules.some((schedule) => schedule.opened_event_ids.includes(id)),
)
