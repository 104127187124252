// be sure to keep it in sync with Campaign model
export const campaignCategoryOptions = [
  { text: __('Campaigns::CategoryOptions::ADWORDS'), value: 'ADWORDS' },
  { text: __('Campaigns::CategoryOptions::BLOG'), value: 'BLOG' },
  { text: __('Campaigns::CategoryOptions::CRM'), value: 'CRM' },
  { text: __('Campaigns::CategoryOptions::FACEBOOK'), value: 'FACEBOOK' },
  { text: __('Campaigns::CategoryOptions::GIFT'), value: 'GIFT' },
  { text: __('Campaigns::CategoryOptions::LANGUAGE SCHOOL'), value: 'LANGUAGE SCHOOL' },
  { text: __('Campaigns::CategoryOptions::MARKETING OFFLINE'), value: 'MARKETING OFFLINE' },
  { text: __('Campaigns::CategoryOptions::MARKETING ONLINE'), value: 'MARKETING ONLINE' },
  { text: __('Campaigns::CategoryOptions::MEETUP'), value: 'MEETUP' },
  { text: __('Campaigns::CategoryOptions::OTHER'), value: 'OTHER' },
  { text: __('Campaigns::CategoryOptions::PARTNERS'), value: 'PARTNERS' },
  { text: __('Campaigns::CategoryOptions::PRESS'), value: 'PRESS' },
  { text: __('Campaigns::CategoryOptions::REFERRAL'), value: 'REFERRAL' },
  { text: __('Campaigns::CategoryOptions::REFERRER WALLET'), value: 'REFERRER WALLET' },
  { text: __('Campaigns::CategoryOptions::SUPPORT'), value: 'SUPPORT' },
  { text: __('Campaigns::CategoryOptions::TEAM'), value: 'TEAM' },
  { text: __('Campaigns::CategoryOptions::TRAVEL AGENCY'), value: 'TRAVEL AGENCY' },
]

export const campaignTypeOptions = [
  { text: __('Campaigns::UsageOptions::value'), value: 'value' },
  { text: '%', value: '%' },
]

export const campaignUsageOptions = [
  { text: __('Campaigns::UsageOptions::per booking'), value: 'booking' },
  { text: __('Campaigns::UsageOptions::per seat'), value: 'seat' },
]
