// libs
import React, { useEffect, useMemo } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
import { List } from 'immutable'
// components
import { Button, Table, Item, Label } from 'semantic-ui-react'
import { Link } from 'react-router'
// helpers
import { getLocalityAndCountry } from 'helpers/places'
import { omitBy, isNil } from 'lodash'
import { konstants } from '@vizeat/helpers'
import { scaleCropFileUrl } from 'helpers/files'
// hooks
import { useHasLoadingSucceeded } from 'hooks'
// redux
import { deletePlaylist, restorePlaylist, fetchPlaylists } from 'redux/entities/actions'
import {
  deletingPlaylist,
  updatingRestorePlaylist,
  getPlaylistError,
  getRestorePlaylistError,
} from 'redux/entities/selectors'
import { getLocation } from 'redux/reducers/router'

export function PlaylistRow({ playlist }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const location = useSelector(getLocation)
  const isDeletingPlaylist = useSelector((state) => deletingPlaylist(state, playlist.id))
  const isUpdatingRestorePlaylist = useSelector((state) => updatingRestorePlaylist(state, playlist.id))
  const playlistError = useSelector((state) => getPlaylistError(state, playlist.id))
  const restorePlaylistError = useSelector((state) => getRestorePlaylistError(state, playlist.id))
  const isPlaylistDeleted = useHasLoadingSucceeded(isDeletingPlaylist, playlistError)
  const isPlaylistRestored = useHasLoadingSucceeded(isUpdatingRestorePlaylist, restorePlaylistError)

  const playlistType = useMemo(() => {
    if (playlist.tags.includes(konstants.TAGS.DESTINATION_PLAYLIST)) return t('Playlists::destination')
    return t('Playlists::playlist')
  }, [playlist.tags, t])

  const createdDate = useMemo(
    () => (playlist.created_at ? moment.utc(playlist.created_at).format('lll') : undefined),
    [playlist.created_at],
  )
  const updatedDate = useMemo(
    () => (playlist.updated_at ? moment.utc(playlist.updated_at).format('lll') : undefined),
    [playlist.updated_at],
  )
  const deletedDate = useMemo(
    () => (playlist.deleted_at ? moment.utc(playlist.deleted_at).format('lll') : undefined),
    [playlist.deleted_at],
  )

  const place = useMemo(() => getLocalityAndCountry(playlist.key_words), [playlist.key_words])
  const eventSize = useMemo(() => playlist.getIn(['search', 'includeEventIds'], new List()).size, [playlist])

  useEffect(() => {
    if (isPlaylistDeleted || isPlaylistRestored) {
      const query = omitBy(location.get('query').toJS(), isNil())
      dispatch(fetchPlaylists({ query }))
    }
  }, [dispatch, isPlaylistDeleted, isPlaylistRestored, location])

  return (
    <Table.Row>
      <Table.Cell>{playlist.id}</Table.Cell>
      <Table.Cell>
        <Item.Group>
          <Item>
            {playlist.cover.id && (
              <Item.Image size='tiny' shape='rounded' src={scaleCropFileUrl(playlist.cover, '100x50')} />
            )}
            <Item.Content verticalAlign='middle'>{playlist.title}</Item.Content>
          </Item>
        </Item.Group>
      </Table.Cell>
      <Table.Cell>
        {playlist.tags.map((tag) => (
          <Label key={tag} style={{ margin: 3 }}>
            {tag}
          </Label>
        ))}
      </Table.Cell>
      <Table.Cell style={{ textTransform: 'capitalize' }}>{place}</Table.Cell>
      <Table.Cell>{eventSize}</Table.Cell>
      <Table.Cell>{playlist.order}</Table.Cell>
      <Table.Cell>{playlistType}</Table.Cell>
      <Table.Cell style={{ textTransform: 'capitalize' }}>{playlist.zone}</Table.Cell>
      <Table.Cell>{createdDate}</Table.Cell>
      <Table.Cell>{updatedDate}</Table.Cell>
      <Table.Cell>{deletedDate}</Table.Cell>
      <Table.Cell>
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          <Button primary as={Link} to={`/playlists/edit/${playlist.id}`} disabled={deletedDate}>
            {t('Playlists::Edit')}
          </Button>
          {deletedDate ? (
            <Button color='green' onClick={() => dispatch(restorePlaylist(playlist.id))}>
              {t('Playlists::Restore')}
            </Button>
          ) : (
            <Button color='red' onClick={() => dispatch(deletePlaylist(playlist.id))}>
              {t('Playlists::Archive')}
            </Button>
          )}
        </div>
      </Table.Cell>
    </Table.Row>
  )
}

PlaylistRow.propTypes = {
  playlist: PropTypes.immutable.record.isRequired,
}
