// libs
import React, { PureComponent, createRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { withTranslation } from 'react-i18next'
import { Element, scroller } from 'react-scroll'

// components
import { Grid, Segment, Loader, Sticky, Container, Ref, Icon, Button } from 'semantic-ui-react'
import { UserSection } from './UserSection'
import { HostSection } from './HostSection'
import { ExperienceSection } from './ExperienceSection'
import { LocationAndVenueSection } from './LocationAndVenueSection'
import { PhotoSection } from './PhotoSection'
import { CommunitySection } from './CommunitySection'
import { HistorySection } from './HistorySection'
import { LegacyDataSection } from './legacy-sections/LegacyDataSection'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'

// redux
import { fetchUserFiles, fetchHostApplication, fetchAdmins } from 'redux/entities/actions'
import {
  getHostApplication,
  getUpdatingHostApplicationError,
  getCreatingHostApplicationError,
  fetchingHostApplication,
  fetchingFiles,
} from 'redux/entities/selectors'

import { konstants } from '@vizeat/helpers'
const { HOST_APPLICATION_SCHEMA_VERSION } = konstants

const mapStateToProps = (state, props) => ({
  fromStore: {
    hostApplication: props.params && getHostApplication(state, { id: props.params.id }),
    error:
      props.params && props.params.id
        ? getUpdatingHostApplicationError(state, props.params.id)
        : getCreatingHostApplicationError(state),
    isFetchingFiles: fetchingFiles(state),
    isFetchingHostApplication: fetchingHostApplication(state, props.params && props.params.id),
  },
})

const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    fetchUserFiles: (id) => dispatch(fetchUserFiles(id)),
    fetchAdmins: (query) => dispatch(fetchAdmins({ query })),
    fetchHostApplication: () => dispatch(fetchHostApplication(props.params.id)),
  },
})

class _HostApplicationPage extends PureComponent {
  contextRef = createRef()

  state = { showLegacyData: false }

  loadApplicationData = () => {
    const { actions, fromStore } = this.props

    actions.fetchAdmins()
    actions.fetchHostApplication()

    if (fromStore.hostApplication.user.id) {
      actions.fetchUserFiles(fromStore.hostApplication.user.id)
    }
  }

  componentDidMount() {
    this.loadApplicationData()
  }

  componentDidUpdate(prevProps) {
    const { fromStore } = this.props

    if (fromStore.hostApplication.user.id && !prevProps.fromStore.hostApplication.user.id) {
      this.loadApplicationData()
    }
  }

  handleLegacyDataDisplay = () => {
    this.setState({ showLegacyData: !this.state.showLegacyData }, () =>
      scroller.scrollTo('legacy-section', {
        duration: 1000,
        delay: 0,
        smooth: true,
      }),
    )
  }

  render() {
    const { t, fromStore } = this.props
    const { showLegacyData } = this.state
    const hostAppliedWithLatestHOVersion =
      fromStore.hostApplication.getIn(['metadata', 'schema_version']) === `${HOST_APPLICATION_SCHEMA_VERSION}`
    return fromStore.isFetchingHostApplication || !fromStore.hostApplication.id ? (
      <Segment basic>
        <Loader active content={t('Loading::Loading')} inline='centered' />
      </Segment>
    ) : (
      <Container textAlign='center'>
        <Ref innerRef={this.contextRef}>
          <Grid centered columns={2}>
            <Grid.Column width={5}>
              <Segment basic>
                <UserSection hostApplication={fromStore.hostApplication} />
                <Sticky context={this.contextRef}>
                  <CommunitySection hostApplication={fromStore.hostApplication} />
                  <Link to='/host-applications'>
                    <Icon name='chevron left' />
                    {t('HostApplications::Summary::Back to the list')}
                  </Link>
                </Sticky>
              </Segment>
            </Grid.Column>
            <Grid.Column width={11}>
              {fromStore.hostApplication.admin && fromStore.hostApplication.metadata && (
                <Grid.Row>
                  <Segment basic>
                    <HistorySection
                      status={fromStore.hostApplication.metadata.get('status')}
                      completionRate={fromStore.hostApplication.metadata.get('completion_rate')}
                      followups={fromStore.hostApplication.admin.get('followups')}
                    />
                  </Segment>
                </Grid.Row>
              )}

              <Grid.Row>
                <Segment basic>
                  <HostSection hostApplication={fromStore.hostApplication} />
                </Segment>
              </Grid.Row>

              <Grid.Row>
                <Segment basic>
                  <ExperienceSection hostApplication={fromStore.hostApplication} />
                </Segment>
              </Grid.Row>

              <Grid.Row>
                <Segment basic>
                  <LocationAndVenueSection hostApplication={fromStore.hostApplication} />
                </Segment>
              </Grid.Row>

              <Grid.Row>
                <Segment basic>
                  <PhotoSection hostApplication={fromStore.hostApplication} />
                </Segment>
              </Grid.Row>

              {!hostAppliedWithLatestHOVersion && (
                <Grid.Row>
                  <Segment basic>
                    <Element name='legacy-section'>
                      <Button basic active={showLegacyData} onClick={this.handleLegacyDataDisplay}>
                        {showLegacyData
                          ? t('HostApplications::Summary::Hide legacy data')
                          : t('HostApplications::Summary::View legacy data')}
                      </Button>
                    </Element>
                  </Segment>
                  {showLegacyData && (
                    <Grid.Row>
                      <LegacyDataSection hostApplication={fromStore.hostApplication} />{' '}
                    </Grid.Row>
                  )}
                </Grid.Row>
              )}
            </Grid.Column>
          </Grid>
        </Ref>
        <ApiErrorMessage error={fromStore.error} modal />
      </Container>
    )
  }
}

_HostApplicationPage.propTypes = {
  t: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    fetchUserFiles: PropTypes.func,
    fetchAdmins: PropTypes.func,
    fetchHostApplication: PropTypes.func,
  }).isRequired,
  fromStore: PropTypes.shape({
    isFetchingHostApplication: PropTypes.bool,
    hostApplication: PropTypes.object,
    error: PropTypes.object,
  }).isRequired,
}

export const HostApplicationPage = withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(_HostApplicationPage),
)
