// Libs
import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import moment from 'moment'
import { withTranslation, Trans } from 'react-i18next'
// Components
import { Link } from 'react-router'
import { Grid, Segment } from 'semantic-ui-react'

class _RequestDetails extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    request: PropTypes.immutable.record.isRequired,
  }

  getRequestStatus(t, { approved_at: approvedAt, canceled_at: canceledAt, expired_at: expiredAt }) {
    if (canceledAt) return t('Requests::Summary::Details::Status::Status: Canceled')
    if (expiredAt) return t('Requests::Summary::Details::Status::Status: Expired')
    if (approvedAt) return t('Requests::Summary::Details::Status::Status: Approved')
    return t('Requests::Summary::Details::Status::Status: Pending')
  }

  render() {
    const { t, request } = this.props

    return (
      <Segment>
        <Grid className='vz-request-detail'>
          <Grid.Row>
            <Grid.Column width={4}>
              <label>{t('Requests::Summary::Details::ID: {{id}}', { id: request.id })}</label>
            </Grid.Column>

            <Grid.Column width={4}>
              <label>
                {t('Requests::Summary::Details::Created at: {{createdAt}}', {
                  createdAt: moment(request.created_at).format('lll'),
                })}
              </label>
            </Grid.Column>

            <Grid.Column width={4}>
              <label>
                {t('Requests::Summary::Details::Date: {{date}}', {
                  date: request.date ? moment(request.date).format('lll') : '',
                })}
              </label>
            </Grid.Column>

            <Grid.Column width={4}>
              <label>{t('Requests::Summary::Details::Seats: {{seats}}', { seats: request.seats })}</label>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={4}>
              <label>
                <Trans
                  i18nKey={__('Requests::Summary::Details::Event: <eventURL>{{eventId}} - {{eventTitle}}</eventURL>')}
                  values={{
                    eventId: request.event.id,
                    eventTitle: request.event.title,
                  }}
                  components={{
                    eventURL: <Link to={`/events?search=${request.event.id}`} />,
                  }}
                />
              </label>
            </Grid.Column>

            <Grid.Column width={4}>
              <label>
                <Trans
                  i18nKey={__(
                    'Requests::Summary::Details::Host: <userURL>{{userId}} - {{userFirstName}} {{userLastName}}</userURL>',
                  )}
                  values={{
                    userId: request.event.user.id,
                    userFirstName: request.event.user.firstname,
                    userLastName: request.event.user.lastname,
                  }}
                  components={{
                    userURL: <Link to={`/users?search=${request.event.user.id}`} />,
                  }}
                />
              </label>
            </Grid.Column>

            <Grid.Column width={4}>
              <label>
                <Trans
                  i18nKey={__(
                    'Requests::Summary::Details::Guest: <userURL>{{userId}} - {{userFirstName}} {{userLastName}}</userURL>',
                  )}
                  values={{
                    userId: request.user.id,
                    userFirstName: request.user.firstname,
                    userLastName: request.user.lastname,
                  }}
                  components={{
                    userURL: <Link to={`/users?search=${request.user.id}`} />,
                  }}
                />
              </label>
            </Grid.Column>

            <Grid.Column width={4}>
              <label>{this.getRequestStatus(t, request)}</label>
            </Grid.Column>
          </Grid.Row>

          {request.partner.id && (
            <Grid.Row>
              <Grid.Column width={4}>
                <label>
                  <Trans
                    i18nKey={__(
                      'Requests::Summary::Details::Partner: <partnerURL>{{partnerId}} - {{partnerName}}</partnerURL>',
                    )}
                    values={{
                      partnerId: request.partner.id,
                      partnerName: request.partner.name,
                    }}
                    components={{
                      partnerURL: <Link to={`/partners/edit/${request.partner.id}`} />,
                    }}
                  />
                </label>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Segment>
    )
  }
}

export const RequestDetails = withTranslation('common')(_RequestDetails)
