import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const banksActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'banks' })

export const fetchBanks = ({ query } = {}) =>
  banksActions.fetch({
    url: '/api/banks',
    query,
    schema: {
      banks: [schemas.banks],
    },
  })
