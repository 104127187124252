import { schema } from 'normalizr'
import { Record } from 'immutable'

import { users } from './users'

const _schema = new schema.Entity('followups', {
  user: users.schema,
})

const _Record = Record({
  action: '',
  admin: undefined,
  booking: undefined,
  comment: '',
  conversation: undefined,
  created_at: undefined,
  event: undefined,
  following_id: undefined,
  following_type: '',
  id: undefined,
  request: undefined,
})

export const followups = {
  schema: _schema,
  Record: _Record,
}
