import settings from 'settings'
import { Map } from 'immutable'

// this can probably be replaced by { responseType: 'blob' } axios config
export function base64ToBlob(base64, type) {
  const byteString = atob(base64)
  // write the bytes of the string to an ArrayBuffer
  const arrayBuffer = new ArrayBuffer(byteString.length)
  const typedArray = new Uint8Array(arrayBuffer)
  for (let i = 0; i < byteString.length; i++) {
    typedArray[i] = byteString.charCodeAt(i)
  }
  return new Blob([arrayBuffer], { type })
}

export function downloadFromBlob(blob, title) {
  // we are missing the revokeObjectURL when the consumer (component) will unmount
  const data = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = data
  link.download = title
  link.click()
}

export const scaleCropFileUrl = (file, size = '710x420', placement = 'center') => {
  if (Map.isMap(file)) file = file.toJS()
  if (!file || !file.id) return null
  return `${settings.screenApiUrl}/files/${file.id}/-/scale_crop/${size}/${placement}/-/progressive/yes/`
}

// If findIndex is not the same as current index, it means it's not the first elmt found
// so we remove it from the list
export const removeDuplicates = (files = []) => {
  return files.toJS // check if immutable
    ? files.filter((file, i) => files.findIndex((f) => f.get('uploadcare_id') === file.get('uploadcare_id')) === i)
    : files.filter((file, i) => files.findIndex((f) => f.uploadcare_id === file.uploadcare_id) === i)
}

export const fileDateRangeOptions = [
  { text: '', value: '' },
  { text: __('Files::FileDateRangeOptions::Created at'), value: 'created_at' },
  { text: __('Files::FileDateRangeOptions::Updated at'), value: 'updated_at' },
]

export const fileAttachableOptions = [
  { text: '', value: '' },
  { text: __('Files::FileAttachableOptions::Events'), value: 'events' },
  { text: __('Files::FileAttachableOptions::Users'), value: 'users' },
  { text: __('Files::FileAttachableOptions::Playlists'), value: 'playlists' },
]

export const fileFormatOptions = [
  { text: __('Files::FileFormatOptions::Original'), value: 'original' },
  { text: __('Files::FileFormatOptions::Square Small'), value: '200x200' },
  { text: __('Files::FileFormatOptions:: - Medium'), value: '600x600' },
  { text: __('Files::FileFormatOptions:: - Large'), value: '1200x1200' },
  { text: __('Files::FileFormatOptions::Portrait Small'), value: '200x324' },
  { text: __('Files::FileFormatOptions:: - Medium'), value: '600x972' },
  { text: __('Files::FileFormatOptions:: - Large'), value: '1000x1618' },
  { text: __('Files::FileFormatOptions::Landscape Small'), value: '324x200' },
  { text: __('Files::FileFormatOptions:: - Medium'), value: '972x600' },
  { text: __('Files::FileFormatOptions:: - Large'), value: '1618x1000' },
]

export const fileCategoryOptions = [
  { text: __('Files::FileCategoryOptions::Cover'), value: 'cover' },
  { text: __('Files::FileCategoryOptions::Food photos'), value: 'foods' },
  { text: __('Files::FileCategoryOptions::Venue photos'), value: 'venues' },
  { text: __('Files::FileCategoryOptions::Host & atmosphere photos'), value: 'sources' },
]
