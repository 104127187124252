import { point, Point } from '@turf/helpers'
import turfDistance from '@turf/distance'
import turfMidpoint from '@turf/midpoint'
import { GEOSEARCH_RADIUS } from './konstants.js'

const {
  DEFAULT_VALUE: DEFAULT_RADIUS_VALUE,
  COMPUTED_RADIUS_SCALE_FACTOR,
  AROUND_RADIUS_SCALE_FACTOR,
} = GEOSEARCH_RADIUS

function computeCircularAreaFromBounds(bounds): { lng: number; lat: number; radius: number } {
  const distance = turfDistance(
    point([bounds.northeast.lng, bounds.northeast.lat]),
    point([bounds.southwest.lng, bounds.southwest.lat]),
  )
  const {
    geometry: { coordinates },
  } = turfMidpoint(
    point([bounds.northeast.lng, bounds.northeast.lat]),
    point([bounds.southwest.lng, bounds.southwest.lat]),
  ) as { geometry: Point }

  return {
    lng: coordinates[0],
    lat: coordinates[1],
    radius: Math.round(distance * COMPUTED_RADIUS_SCALE_FACTOR),
  }
}

function normalizeLongitude(lng) {
  if (!lng) return lng
  // https://gis.stackexchange.com/questions/303300/calculating-correct-longitude-when-its-over-180
  return (((lng % 360) + 360 + 180) % 360) - 180
}

type Viewport = { northeast: { lat: number; lng: number }; southwest: { lat: number; lng: number } }

type Geometry = {
  location: {
    latitude?: number
    longitude?: number
    lat?: number
    lng?: number
  }
  bounds?: Viewport
  viewport?: Viewport
}

type Place = {
  geometry?: Geometry
  coordinates?: {
    latitude?: number
    longitude?: number
    geometry: Geometry
  }
}

type AlgoliaGeoQuery =
  // geo levelling
  | {
      aroundLatLng: string
      aroundRadius: number
      aroundPrecision: number
    }
  // geo distance
  | {
      aroundLatLng: string
    }
  // inside viewport
  | {
      insideBoundingBox: string
    }
  // no geo search
  | Record<string, never>

export function formatAlgoliaQuery(
  place: Place,
  {
    forcedRadius,
    maximumRadius,
    useGeoLeveling = true,
  }: { forcedRadius?: number; maximumRadius?: number; useGeoLeveling?: boolean },
): AlgoliaGeoQuery {
  const location = place?.geometry?.location
  const lat = place?.coordinates?.latitude ?? location?.lat ?? location?.latitude
  const lng = place?.coordinates?.longitude ?? location?.lng ?? location?.longitude
  const geometry = place?.geometry ?? place?.coordinates?.geometry
  const bounds = geometry?.viewport ?? geometry?.bounds
  const areCoordinatesDefined = !!lat && !!lng

  if (!areCoordinatesDefined && bounds) {
    const insideBoundingBox = `${bounds.northeast.lat},${normalizeLongitude(bounds.northeast.lng)},${
      bounds.southwest.lat
    },${normalizeLongitude(bounds.southwest.lng)}`
    return { insideBoundingBox }
  }

  if (areCoordinatesDefined && !useGeoLeveling) {
    return { aroundLatLng: `${lat}, ${normalizeLongitude(lng)}` }
  }

  if (areCoordinatesDefined) {
    const circularArea = bounds && computeCircularAreaFromBounds(bounds)
    const radius = forcedRadius ?? circularArea?.radius ?? DEFAULT_RADIUS_VALUE
    const aroundLatLng = `${circularArea?.lat ?? lat}, ${normalizeLongitude(circularArea?.lng ?? lng)}`
    const aroundRadius = Math.round((radius * AROUND_RADIUS_SCALE_FACTOR) / Math.log10(radius)) // Bahadir's magic 🌟
    return {
      aroundLatLng,
      aroundRadius: maximumRadius ?? forcedRadius ?? aroundRadius,
      aroundPrecision: radius,
    }
  }

  return {}
}
