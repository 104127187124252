// libs
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { get, isNil, omitBy } from 'lodash'
// helpers
import PropTypes from 'helpers/proptypes'
import { isInvalid } from 'helpers/forms'
import { numbers } from '@vizeat/helpers'
// components
import { Card, List, Button } from 'semantic-ui-react'

// redux
import { getForm } from 'redux/forms/reducer'
import { createCampaigns, updateCampaign } from 'redux/entities/actions'
import {
  creatingCampaigns,
  updatingCampaign,
  getCampaignError,
  getCampaignsError,
  getCurrency,
  getUser,
} from 'redux/entities/selectors'
import { withTranslation } from 'react-i18next'

const { formatIntegerPrice } = numbers

const mapStateToProps = (state, props) => ({
  fromStore: {
    form: getForm(state, 'campaign'),
    updating: props.campaign && updatingCampaign(state, props.campaign.id),
    creating: creatingCampaigns(state),
    error: !props.campaign ? getCampaignsError(state) : getCampaignError(state, props.campaign.id),
    getUser: (id) => getUser(state, { id, raw: true }),
    getCurrency: (id) => getCurrency(state, { id }),
  },
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    createCampaigns: (payload) => dispatch(createCampaigns(payload)),
    updateCampaign: (id, payload) => dispatch(updateCampaign(id, payload)),
  },
})

class _CampaignCard extends PureComponent {
  static contextTypes = { router: PropTypes.object.isRequired }
  static propTypes = {
    t: PropTypes.func.isRequired,
    campaign: PropTypes.immutable.record,
    onSaved: PropTypes.func.isRequired, // ({ error: Immutable.Map, success: bool, title: String }) => {}
    children: PropTypes.any,
    fromStore: PropTypes.shape({
      form: PropTypes.immutable.map,
      updating: PropTypes.bool,
      creating: PropTypes.bool,
      error: PropTypes.immutable.map,
      getUser: PropTypes.func,
      getCampaign: PropTypes.func,
      getCurrency: PropTypes.func,
    }).isRequired,
    actions: PropTypes.shape({
      createCampaigns: PropTypes.func.isRequired,
      updateCampaign: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    campaign: undefined,
  }

  save = (e) => {
    if (e) e.preventDefault()
    const {
      onSaved,
      actions,
      fromStore: { form },
      campaign,
    } = this.props
    if (!campaign) {
      actions.createCampaigns(form.toJS()).then(({ payload: { data } }) => {
        const { error } = this.props.fromStore
        const codes = data.result.campaigns.map((id) => data.entities.campaigns[id].code)
        if (onSaved) onSaved({ error, success: !error, title: form.get('title'), codes })
      })
    } else {
      actions.updateCampaign(campaign.id, omitBy(form.toJS(), isInvalid)).then(({ payload: { data } }) => {
        const { error } = this.props.fromStore
        if (onSaved) {
          onSaved({
            error,
            success: !error,
            title: form.get('title'),
            codes: [data.entities.campaigns[campaign.id].code],
          })
        }
      })
    }
  }

  render() {
    const { t, fromStore } = this.props
    const { getCurrency, getUser, form, updating, creating } = fromStore
    const currency = getCurrency(form.get('currency_id'))
    const user = getUser(form.get('user_id'))
    const typeValue = form.get('type', '').toLowerCase() === 'value'

    return (
      <Card className='Playlist-Card'>
        <Card.Content>
          <Card.Header>{t('Campaigns::Summary::Campaign preview')}</Card.Header>
          <List>
            <List.Item>
              <strong>{t('Campaigns::Summary::Title:')}</strong> {form.get('title')}
            </List.Item>
            <List.Item>
              <strong>{t('Campaigns::Summary::Description:')}</strong> {form.get('description')}
            </List.Item>
            <List.Item>
              <strong>{t('Campaigns::Summary::User:')}</strong> {`${get(user, 'firstname')} ${get(user, 'lastname')}`}
            </List.Item>
            <List.Item>
              <strong>{t('Campaigns::Summary::Category:')}</strong> {form.get('category')}
            </List.Item>
            <List.Item>
              <strong>{t('Campaigns::Summary::Code:')}</strong> {form.get('code')}
            </List.Item>
            <List.Item>
              <strong>{t('Campaigns::Summary::Start date:')}</strong> {form.get('begin_at')}
            </List.Item>
            <List.Item>
              <strong>{t('Campaigns::Summary::End date:')}</strong> {form.get('end_at')}
            </List.Item>
            <List.Item>
              <strong>{t('Type:')}</strong> {form.get('type')}
            </List.Item>
            <List.Item>
              <strong>{t('Campaigns::Summary::Discount:')}</strong>{' '}
              {typeValue
                ? formatIntegerPrice(parseInt(form.get('discount'), 10), 'en', currency)
                : `${form.get('discount')}%`}
            </List.Item>
            <List.Item>
              <strong>{t('Campaigns::Summary::Usage:')}</strong> {form.get('usage')}
            </List.Item>
            <List.Item>
              <strong>{t('Campaigns::Summary::Max usage:')}</strong>{' '}
              {isNil(form.get('max_usage_limit')) ? t('Campaigns::Summary::none') : form.get('max_usage_limit')}
            </List.Item>
            <List.Item>
              <strong>{t('Campaigns::Summary::Min usage:')}</strong>{' '}
              {isNil(form.get('min_usage_limit')) ? t('Campaigns::Summary::none') : form.get('min_usage_limit')}
            </List.Item>
            <List.Item>
              <strong>{t('Campaigns::Summary::Max Guests:')}</strong>{' '}
              {isNil(form.get('max_guest_limit')) ? t('Campaigns::Summary::none') : form.get('max_guest_limit')}
            </List.Item>
            <List.Item>
              <strong>{t('Campaigns::Summary::Min Guests:')}</strong>{' '}
              {isNil(form.get('min_guest_limit')) ? t('Campaigns::Summary::none') : form.get('min_guest_limit')}
            </List.Item>
            <List.Item>
              <strong>{t('Campaigns::Summary::Max price:')}</strong>{' '}
              {isNil(form.get('max_price_limit'))
                ? t('Campaigns::Summary::none')
                : formatIntegerPrice(parseInt(form.get('max_price_limit'), 10), 'en', currency)}
            </List.Item>
            <List.Item>
              <strong>{t('Campaigns::Summary::Min price:')}</strong>{' '}
              {isNil(form.get('min_price_limit'))
                ? t('Campaigns::Summary::none')
                : formatIntegerPrice(parseInt(form.get('min_price_limit'), 10), 'en', currency)}
            </List.Item>
            <List.Item>
              <strong>{t('Campaigns::Summary::First time:')}</strong>{' '}
              {form.get('first_time') ? t('Campaigns::Summary::yes') : t('Campaigns::Summary::no')}
            </List.Item>
            <List.Item>
              <strong>{t('Campaigns::Summary::Free only:')}</strong>{' '}
              {form.get('free_only') ? t('Campaigns::Summary::yes') : t('Campaigns::Summary::no')}
            </List.Item>
            <List.Item>
              <strong>{t('Campaigns::Summary::Active:')}</strong>{' '}
              {form.get('active') ? t('Campaigns::Summary::yes') : t('Campaigns::Summary::no')}
            </List.Item>
            <List.Item>
              <strong>{t('Campaigns::Summary::Events:')}</strong>{' '}
              {!form.get('event_ids').isEmpty() ? form.get('event_ids').join() : t('Campaigns::Summary::none')}
            </List.Item>
          </List>
        </Card.Content>

        <Card.Content>
          <div style={{ textAlign: 'center' }}>
            <Button primary onClick={this.save} loading={updating || creating}>
              {t('Campaigns::Summary::Save campaign')}
            </Button>
          </div>
        </Card.Content>

        {this.props.children && <Card.Content>{this.props.children}</Card.Content>}
      </Card>
    )
  }
}

export const CampaignCard = withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(_CampaignCard))
