// libs
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
// components
import { Form } from 'semantic-ui-react'
// helpers
import PropTypes from 'helpers/proptypes'
import { getBookingDeadlineOptions, getCancellationDeadlineOptions } from 'helpers/deadlines'
// redux
import { mergeInForm } from 'redux/forms/actions'
import { getForm } from 'redux/forms/reducer'

const mapStateToProps = (state, props) => ({
  fromStore: {
    form: getForm(state, 'event'),
  },
})
const mapDispatchToProps = (dispatch) => ({
  actions: {
    mergeInForm: (formData) => dispatch(mergeInForm({ formName: 'event', value: formData })),
  },
})

class _SectionDeadlines extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    isEventFrozen: PropTypes.bool,
    fromStore: PropTypes.shape({
      form: PropTypes.immutable.map,
    }).isRequired,
    actions: PropTypes.shape({
      mergeInForm: PropTypes.func,
    }).isRequired,
  }

  static defaultProps = {
    isEventFrozen: false,
  }

  render() {
    const {
      t,
      fromStore: { form },
      actions,
      isEventFrozen,
    } = this.props

    return (
      <section id='section-event-deadlines'>
        <h3 className='section-title'>{t('Experiences::Summary::Experience deadline')}</h3>

        <Form.Group widths='equal'>
          <Form.Field disabled={isEventFrozen}>
            <label>{t('Experiences::Summary::Booking deadline')}</label>
            <Form.Group widths='equal'>
              <Form.Field>
                <label>{t('Experiences::Summary::Public')}</label>
                <Form.Select
                  compact
                  onChange={(e, { value }) => actions.mergeInForm({ booking_deadline: value })}
                  options={getBookingDeadlineOptions(t, { currentDeadline: form.get('booking_deadline') })}
                  value={form.get('booking_deadline')}
                />
              </Form.Field>

              <Form.Field>
                <label>{t('Experiences::Summary::Private')}</label>
                <Form.Select
                  compact
                  onChange={(e, { value }) => actions.mergeInForm({ pe_booking_deadline: value })}
                  options={getBookingDeadlineOptions(t, { currentDeadline: form.get('pe_booking_deadline') })}
                  value={form.get('pe_booking_deadline')}
                />
              </Form.Field>
            </Form.Group>
          </Form.Field>

          <Form.Field disabled={isEventFrozen}>
            <label>{t('Experiences::Summary::Cancellation deadline')}</label>
            <Form.Group widths='equal'>
              <Form.Field>
                <label>{t('Experiences::Summary::Public')}</label>
                <Form.Select
                  compact
                  onChange={(e, { value }) => actions.mergeInForm({ cancellation_deadline: value })}
                  options={getCancellationDeadlineOptions(t, { currentDeadline: form.get('cancellation_deadline') })}
                  value={form.get('cancellation_deadline')}
                />
              </Form.Field>

              <Form.Field>
                <label>{t('Experiences::Summary::Private')}</label>
                <Form.Select
                  compact
                  onChange={(e, { value }) => actions.mergeInForm({ pe_cancellation_deadline: value })}
                  options={getCancellationDeadlineOptions(t, { currentDeadline: form.get('pe_cancellation_deadline') })}
                  value={form.get('pe_cancellation_deadline')}
                />
              </Form.Field>
            </Form.Group>
          </Form.Field>
        </Form.Group>
      </section>
    )
  }
}

export const SectionDeadlines = withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(_SectionDeadlines),
)
