type QueryPrimitive = number | string | boolean
type QueryObject<T = QueryPrimitive> = Record<string, T>

export function parseQueryValue(value: string): QueryPrimitive {
  if (value === 'true') return true
  if (value === 'false') return false
  const number = Number(value)
  if (number) return number
  return value
}

export function reqQueryToQuery(reqQuery: Record<string, string>): QueryObject {
  const query = JSON.parse(JSON.stringify(reqQuery))
  const keys = Object.keys(query)
  for (const key of keys) {
    if (Array.isArray(query[key])) query[key] = query[key].map(parseQueryValue)
    else query[key] = parseQueryValue(query[key])
  }
  return query
}

export function queryToString(query: QueryObject<QueryPrimitive | number[]> = {}): string {
  return (
    Object.keys(query)
      // @ts-expect-error includes assumes the value must exist in the base array
      .filter((key) => ![null, undefined, ''].includes(query[key]))
      .map((key) => {
        const param = query[key]
        if (!Array.isArray(param)) return `${key}=${param}`
        return (
          param
            // @ts-expect-error includes assumes the value must exist in the base array
            .filter((value) => ![null, undefined, ''].includes(value))
            .map((value) => `${key}[]=${value}`)
            .join('&')
        )
      })
      .join('&')
  )
}

export function stringToQuery(strQuery = ''): QueryObject {
  if (strQuery && strQuery[0] === '?') strQuery = strQuery.slice(1)
  if (!strQuery) return {}
  return strQuery
    .split('&')
    .map((tuple) => tuple.split('='))
    .reduce((query, [key, value]) => {
      if (key.indexOf('[]') !== -1 && key.indexOf('[]') === key.length - 2) {
        key = key.slice(0, key.length - 2)
        return Object.assign(query, { [key]: [...(query[key] || []), parseQueryValue(value)] })
      }
      return Object.assign(query, { [key]: parseQueryValue(value) })
    }, {})
}
