import { createStore, applyMiddleware, compose } from 'redux'
import { combineReducers } from 'redux-immutablejs'
import ReduxThunk from 'redux-thunk'
import { routerMiddleware } from 'react-router-redux'
import { browserHistory } from 'react-router'
import { businessApi } from 'helpers/api'
// Reducers
import * as reducers from 'redux/reducers'

const crashReporter = (store) => (next) => (action) => {
  try {
    return next(action)
  } catch (err) {
    console.error(`Error caught in reducers while dispatching ${action.type}`, err) // eslint-disable-line no-console
    throw err
  }
}

export default function configureStore(initialState) {
  const enhancer = compose(
    applyMiddleware(
      ReduxThunk.withExtraArgument({ Api: businessApi }),
      routerMiddleware(browserHistory),
      crashReporter,
    ),
    // https://github.com/zalmoxisus/redux-devtools-extension
    window && window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__({ trace: process.env.NODE_ENV !== 'production' })
      : (f) => f,
  )

  const rootReducer = combineReducers(reducers)

  return createStore(rootReducer, initialState, enhancer)
}
