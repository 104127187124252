import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const followupsActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'followups' })

export const fetchFollowups = (bookingId, { query } = {}) =>
  followupsActions.fetch({
    url: `/api/bookings/${bookingId}/followups`,
    query,
    schema: { followups: [schemas.followups] },
  })

export const createFollowups = (payload, { query } = {}) =>
  followupsActions.create({
    url: '/api/followups',
    payload,
    query,
    schema: { followups: [schemas.followups] },
  })
