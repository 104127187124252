export const payoutGatewayOptions = [
  { text: __('Payouts::GatewayOptions::Stripe'), value: 'stripe' },
  { text: __('Payouts::GatewayOptions::Paypal'), value: 'paypal' },
  { text: __('Payouts::GatewayOptions::Manual'), value: 'manual' },
]

export const payoutStatusOptions = [
  { text: __('Payouts::StatusOptions::Canceled'), value: 'canceled' },
  { text: __('Payouts::StatusOptions::Created'), value: 'created' },
  { text: __('Payouts::StatusOptions::Failed'), value: 'failed' },
  { text: __('Payouts::StatusOptions::Paid'), value: 'paid' },
  { text: __('Payouts::StatusOptions::Updated'), value: 'updated' },
]

export const payoutDateRangeOptions = [
  { text: '', value: '' },
  { text: __('Payouts::DateRangeOptions::Paid at'), value: 'paid_at' },
  { text: __('Payouts::DateRangeOptions::Created at'), value: 'created_at' },
]

export const payoutPaidStatusOptions = [
  { text: '', value: null },
  { text: __('Payouts::DateRangeOptions::Paid'), value: 1 },
  { text: __('Payouts::DateRangeOptions::Unpaid'), value: 0 },
]

export const csvSeparatorOptions = [
  { text: ';', value: ';' },
  { text: ',', value: ',' },
]

export const payoutFormatOptions = [
  { text: __('Payouts::FormatOptions::All fields'), value: 'all_fields' },
  { text: __('Payouts::FormatOptions::Paypal formatted'), value: 'paypal_formatted' },
]

export const formatBatchedPayouts = (batchedPayouts, format) => {
  const showAllFields = format === 'all_fields'
  const headers = showAllFields
    ? [
        __('Payouts::FormatBatchedOptions::User ID'),
        __('Payouts::FormatBatchedOptions::User Name'),
        __('Payouts::FormatBatchedOptions::Payout IDs'),
        __('Payouts::FormatBatchedOptions::Gateway'),
        __('Payouts::FormatBatchedOptions::Currency'),
        __('Payouts::FormatBatchedOptions::Amount'),
        __('Payouts::FormatBatchedOptions::PayPal ID'),
        __('Payouts::FormatBatchedOptions::Stripe Payout IDs'),
        __('Payouts::FormatBatchedOptions::Stripe Statuses'),
        __('Payouts::FormatBatchedOptions::Failure Reasons'),
        __('Payouts::FormatBatchedOptions::Reference codes'),
        __('Payouts::FormatBatchedOptions::Already Paid'),
      ]
    : [
        __('Payouts::FormatBatchedOptions::paypal_account'),
        __('Payouts::FormatBatchedOptions::payment_amount'),
        __('Payouts::FormatBatchedOptions::host_currency'),
      ]
  const data = Object.values(batchedPayouts).map(
    ({
      user_id: userId,
      user_name: userName,
      payout_ids: payoutIds,
      gateway,
      currency,
      amount,
      paypal_id: paypalId,
      stripe_payout_ids: stripePayoutIds,
      stripe_statuses: stripeStatuses,
      stripe_reasons: stripeReasons,
      reference_codes: referenceCodes,
      paid,
    }) =>
      showAllFields
        ? [
            userId,
            userName,
            payoutIds.join(','),
            gateway,
            currency,
            amount,
            paypalId,
            stripePayoutIds.join(','),
            stripeStatuses.join(','),
            stripeReasons.join(','),
            referenceCodes.join(','),
            paid ? 'yes' : 'no',
          ]
        : [paypalId, amount, currency],
  )

  return { headers, data }
}

export const paidAtActions = [
  { text: __('Payout::PaidAtActions::Mark as paid'), value: true },
  { text: __('Payout::PaidAtActions::Mark as unpaid'), value: false },
]
