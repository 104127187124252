import { useCallback } from 'react'

export function useMultiFiltersToggle(selectedValues, { onChange, filterName }) {
  return useCallback(
    (e, value) => {
      const selectedValue = e?.target.name || value
      const nextValuesSet = new Set(selectedValues)

      if (nextValuesSet.has(selectedValue)) {
        nextValuesSet.delete(selectedValue)
      } else {
        nextValuesSet.add(selectedValue)
      }

      onChange({ name: filterName, value: nextValuesSet.size > 0 ? [...nextValuesSet] : [] })
    },
    [filterName, onChange, selectedValues],
  )
}
