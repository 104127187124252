// Libs
import React from 'react'
import PropTypes from 'prop-types'
import { Image } from 'semantic-ui-react'
// Style
import './FocusedFile.css'

const FocusedFile = ({ path, onFocus }) => (
  <div className='__focused-file' onClick={onFocus}>
    <Image src={path} className='__focused-image' />
  </div>
)

FocusedFile.propTypes = {
  path: PropTypes.string.isRequired,
  onFocus: PropTypes.func.isRequired,
}

export default FocusedFile
