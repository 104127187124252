import { omit } from 'lodash'
import PropTypes from 'prop-types'
import IPropTypes from 'react-immutable-proptypes'
import MomentPropTypes from 'react-moment-proptypes'

export default { ...PropTypes, immutable: IPropTypes, moment: MomentPropTypes.momentObj }

export const omitSelfProps = (self) => omit(self.props, Object.keys(self.constructor.propTypes))

export const playlistPropTypes = PropTypes.shape({
  cover: PropTypes.shape({
    id: PropTypes.number,
  }),
  title: PropTypes.string,
  body: PropTypes.string,
  visibility: PropTypes.string,
  order: PropTypes.number,
  display_type: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  key_words: PropTypes.shape({
    locality: PropTypes.string,
    country: PropTypes.string,
  }),
  search: PropTypes.shape({
    allowPrivateBookings: PropTypes.bool,
    allowPublicBookings: PropTypes.bool,
    currency: PropTypes.number,
    includeEventIds: PropTypes.arrayOf(PropTypes.number),
    languages: PropTypes.arrayOf(PropTypes.string),
    mealtypes: PropTypes.arrayOf(PropTypes.string),
    online: PropTypes.bool,
    priceMax: PropTypes.number,
    priceMin: PropTypes.number,
    radius: PropTypes.number,
    tags: PropTypes.arrayOf(PropTypes.string),
  }),
})
