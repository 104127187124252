import { schema } from 'normalizr'
import { Record } from 'immutable'

const _schema = new schema.Entity('batchedPayouts')

const _Record = Record({
  id: undefined,

  user_id: undefined,
  user_name: undefined,
  payout_ids: [],
  gateway: undefined,
  currency: undefined,
  amount: undefined,
  paypal_id: undefined,
  stripe_payout_ids: [],
  stripe_statuses: [],
  stripe_reasons: [],
  reference_codes: [],
  paid: undefined,
})

export const batchedPayouts = {
  schema: _schema,
  Record: _Record,
}
