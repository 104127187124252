import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import DefaultHeader from 'layouts/headers/Default'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './Default.css'

export default class DefaultLayout extends PureComponent {
  static propTypes = {
    children: PropTypes.any,
  }

  UNSAFE_componentWillMount() {}

  render() {
    return (
      <div>
        <DefaultHeader />

        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className='default-layout-body'>{this.props.children}</div>
      </div>
    )
  }
}
