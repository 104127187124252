// Libraries
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
// Components
import { Link } from 'react-router'
import { Form, Button } from 'semantic-ui-react'
import { SearchInput } from 'components/inputs'
// Selectors
import { getLocation } from 'redux/reducers/router'

const mapStateToProps = (state) => ({ location: getLocation(state) })

export class TagListSearchForm extends PureComponent {
  static propTypes = { t: PropTypes.func.isRequired, location: PropTypes.object }
  static contextTypes = { router: PropTypes.object }

  UNSAFE_componentWillMount() {
    return this.updateStateFromLocation(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.location.equals(this.props.location)) return this.updateStateFromLocation(nextProps)
  }

  updateStateFromLocation(props) {
    return this.setState({
      search: props.location.getIn(['query', 'search'], ''),
      location: props.location.getIn(['query', 'location'], ''),
    })
  }

  updateQuery(query = this.state) {
    return this.context.router.push(this.props.location.mergeIn(['query'], { ...query, offset: 0 }).toJS())
  }

  clearSorting() {
    return this.context.router.push(this.props.location.mergeIn(['query'], { sortBy: '', order: '' }).toJS())
  }

  clearFilters() {
    return this.setState({ search: '', location: '' }, () => this.updateQuery())
  }

  handleSubmit(e) {
    e.preventDefault()
    return this.updateQuery()
  }

  render() {
    const { t } = this.props
    return (
      <Form onSubmit={this.handleSubmit.bind(this)} style={{ marginBottom: '1em' }} autoComplete='off'>
        <Form.Group widths='equal'>
          <Form.Field>
            <label>{t('Tags::Search by tag title')}</label>
            <SearchInput
              placeholder={t('Tags::Title')}
              fluid
              icon='search'
              iconPosition='left'
              value={this.state.search}
              onChange={(value) => this.setState({ search: value })}
            />
          </Form.Field>

          <Form.Field>
            <label>&nbsp;</label>
            <Button type='submit'>{t('Tags::Search')}</Button>

            <Button basic onClick={() => this.clearFilters()}>
              {t('Tags::Clear filters')}
            </Button>

            <Button as={Link} to='/tags/add' primary style={{ transform: 'translateX(15px)' }}>
              {t('Tags::Create new')}
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    )
  }
}

export default withTranslation('common')(connect(mapStateToProps)(TagListSearchForm))
