import settings from 'settings'
// Libraries
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { omitBy } from 'lodash'
import { withTranslation } from 'react-i18next'
// Components
import { List, Paginate } from 'components/lists'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import UserRow from './Row'
// Utils
import { getFullPath } from 'helpers/url'
import { isInvalid } from 'helpers/forms'
// Redux
import { getLocation } from 'redux/reducers/router'

import { fetchUsers, activateAccount, deactivateAccount } from 'redux/entities/actions'
import {
  getUsersError,
  fetchingUsers,
  getUserError,
  updatingAccount,
  deletingAccount,
  getUsersList,
  getUsersCount,
  getUsersTotal,
} from 'redux/entities/selectors'
const mapStateToProps = (state) => ({
  error: getUsersError(state) || getUserError(state),
  loading: fetchingUsers(state),
  changingActivation: (accountId) => updatingAccount(state, accountId) || deletingAccount(state, accountId),
  users: getUsersList(state),
  count: getUsersCount(state),
  total: getUsersTotal(state),
  location: getLocation(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    loadPaginatedUsers: (query) => dispatch(fetchUsers({ query })),
    disableUser: (accountId) => dispatch(deactivateAccount(accountId)),
    restoreUser: (accountId) => dispatch(activateAccount(accountId)),
  },
})

const getHeaders = (t) => [
  { label: t('Users::Id'), field: 'id' },
  { label: t('Users::Full Name'), field: 'name' },
  { label: t('Users::Email'), field: 'email' },
  { label: t('Users::Phone'), field: 'phone' },
  { label: t('Users::Place') },
  { label: t('Users::Country'), field: 'country' },
  { label: t('Users::Type') },
  { label: t('Users::Payment preferences') },
  { label: t('Users::Signup Date'), field: 'created_at' },
  { label: t('Users::Actions') },
]

class UserList extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,
    changingActivation: PropTypes.func,
    error: PropTypes.object,
    users: PropTypes.object,
    count: PropTypes.number,
    total: PropTypes.number,
  }

  UNSAFE_componentWillMount() {
    // Handle landing on /bookings without params
    const defaultQuery = { offset: 0, size: settings.defaultPaginationSize }
    const query = this.props.location.get('query')
    if (query.size > 0) {
      this.loadUsers({ ...defaultQuery, ...query.toJS() })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (getFullPath(this.props.location) !== getFullPath(nextProps.location)) {
      this.loadUsers(nextProps.location.get('query').toJS())
    }
  }

  loadUsers = (query) => this.props.actions.loadPaginatedUsers(omitBy(query, isInvalid))

  disableUser = (userId) => this.props.actions.disableUser(userId)

  restoreUser = (userId) => this.props.actions.restoreUser(userId)

  render() {
    const { t, users } = this.props

    return (
      <Paginate count={this.props.total} previous next loading={this.props.loading}>
        <List striped celled sortable headers={getHeaders(t)} location={this.props.location}>
          {users.toList().map((user) => (
            <UserRow
              key={user.id}
              user={user}
              loading={this.props.changingActivation(user.account.id)}
              disableUser={this.disableUser}
              restoreUser={this.restoreUser}
            />
          ))}
        </List>
        <ApiErrorMessage error={this.props.error} modal />
      </Paginate>
    )
  }
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(UserList))
