import settings from 'settings'
import React, { useMemo, useState } from 'react'
import PropTypes, { playlistPropTypes } from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
import { Modal, Button } from 'semantic-ui-react'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { DeletableBadge } from 'components/badges/DeletableBadge'
import { Collection } from './Collection'
import { Discoverability } from './Discoverability'
import { EventType } from './EventType'
import { Language } from './Language'
import { MealType } from './MealType'
import { Price } from './Price'

const UNRESTORABLE_KEYS = ['radius', 'includeEventIds', 'q', 'pid']
const CLEARABLE_KEYS = [
  'mealtypes',
  'languages',
  'priceMin',
  'priceMax',
  'currency',
  'tags',
  'allowPublicBookings',
  'allowPrivateBookings',
  'online',
]

function Filters({ onChange, showSelectedFilters, searchParams }) {
  return (
    <React.Fragment>
      <MealType onChange={onChange} showSelectedFilters={showSelectedFilters} selectedValues={searchParams.mealtypes} />
      <Price
        onChange={onChange}
        showSelectedFilters={showSelectedFilters}
        currency={searchParams.currency}
        priceMax={searchParams.priceMax}
        priceMin={searchParams.priceMin}
      />
      {settings.areCollectionsActive === 'true' && (
        <Collection onChange={onChange} showSelectedFilters={showSelectedFilters} selectedValues={searchParams.tags} />
      )}
      <Language onChange={onChange} showSelectedFilters={showSelectedFilters} selectedValues={searchParams.languages} />
      <EventType
        onChange={onChange}
        showSelectedFilters={showSelectedFilters}
        allowPrivateBookings={searchParams.allowPrivateBookings}
        allowPublicBookings={searchParams.allowPublicBookings}
        online={searchParams.online}
      />
      <Discoverability
        onChange={onChange}
        showSelectedFilters={showSelectedFilters}
        selectedValues={searchParams.tags}
      />
    </React.Fragment>
  )
}

Filters.propTypes = {
  onChange: PropTypes.func.isRequired,
  searchParams: playlistPropTypes.isRequired,
  showSelectedFilters: PropTypes.bool,
}

Filters.defaultProps = {
  showSelectedFilters: undefined,
}

function omitKeysFromPayload(keysToRemove, payload) {
  if (!payload) return {}
  const validEntries = Object.entries(payload).filter(([key]) => !keysToRemove.includes(key))
  return Object.fromEntries(validEntries)
}

export function FormFiltersModal({ handleChangeForm, form, playlist }) {
  const { t } = useTranslation()
  const shouldShowRestoreAllButton = useMemo(() => {
    const clearableFormParams = Object.values(omitKeysFromPayload(UNRESTORABLE_KEYS, form.search)).flat()
    const clearablePlaylistParams = Object.values(omitKeysFromPayload(UNRESTORABLE_KEYS, playlist.search)).flat()

    if (clearablePlaylistParams.length > 0 && clearableFormParams.length !== clearablePlaylistParams.length) return true
    return !clearablePlaylistParams.every((param) => clearableFormParams.includes(param))
  }, [form.search, playlist.search])
  const shouldShowClearAllButton = useMemo(
    () => Object.keys(omitKeysFromPayload(UNRESTORABLE_KEYS, form.search)).length > 0,
    [form.search],
  )

  const [showModal, setShowModal] = useState(false)

  function onChange({ name, value }) {
    handleChangeForm({ search: { ...form.search, [name]: value } })
  }

  function toggleModalVisibility() {
    setShowModal(!showModal)
  }

  function handleClear() {
    handleChangeForm({ search: { ...omitKeysFromPayload(CLEARABLE_KEYS, form.search) } })
  }

  function handleRestore() {
    handleChangeForm({ search: { ...form.search, ...omitKeysFromPayload(UNRESTORABLE_KEYS, playlist.search) } })
  }

  return (
    <div style={{ textAlign: 'center' }}>
      {(shouldShowClearAllButton || shouldShowRestoreAllButton) && (
        <Flex
          py='8px'
          m='12px auto'
          alignItems='center'
          justifyContent='center'
          maxWidth='100%'
          flexWrap='wrap'
          gap='8px'
        >
          {shouldShowRestoreAllButton && (
            <DeletableBadge onClick={handleRestore} border='none' bg='eatwithOrange' color='white'>
              {t('Playlists::Summary::Restore all')}
            </DeletableBadge>
          )}
          {shouldShowClearAllButton && (
            <React.Fragment>
              <DeletableBadge onClick={handleClear} borderColor='eatwithOrange' color='eatwithOrange'>
                {t('Playlists::Summary::Clear all')}
              </DeletableBadge>
              <Filters onChange={onChange} showSelectedFilters searchParams={form.search} />
            </React.Fragment>
          )}
        </Flex>
      )}

      <Button
        type='button'
        onClick={toggleModalVisibility}
        size='huge'
        primary
        style={{ transform: 'translateX(15px)' }}
      >
        {t('Playlists::Summary::Select filters')}
      </Button>

      <Modal open={showModal} size='large' closeIcon onClose={toggleModalVisibility}>
        <Modal.Header>{t('Playlists::Summary::Select filters')}</Modal.Header>
        <Modal.Content scrolling>
          <Filters onChange={onChange} searchParams={form.search} />
        </Modal.Content>
        <Modal.Actions style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Button primary onClick={toggleModalVisibility}>
            {t('Playlists::Summary::Done')}
          </Button>
          <Button basic color='blue' onClick={handleClear}>
            {t('Playlists::Summary::Clear all')}
          </Button>
          <Button basic color='blue' style={{ boxShadow: 'none !important' }} onClick={handleRestore}>
            {t('Playlists::Summary::Restore all')}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  )
}

FormFiltersModal.propTypes = {
  form: playlistPropTypes.isRequired,
  playlist: playlistPropTypes,
  handleChangeForm: PropTypes.func.isRequired,
}

FormFiltersModal.defaultProps = {
  playlist: undefined,
}
