import settings from 'settings'
// Libraries
import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { isInvalid } from 'helpers/forms'
import { connect } from 'react-redux'
import { omitBy } from 'lodash'
import { withTranslation } from 'react-i18next'
// Components
import { Modal, Button, Form, Select, Checkbox } from 'semantic-ui-react'
import { List, Paginate } from 'components/lists'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { BookingRow } from './Row'
import { ChangeStatusForm } from './ChangeStatusForm'
import { BookingsHistory } from './BookingsHistory'
// Utils
import { getFullPath } from 'helpers/url'
import { getTranslatedOptions } from 'helpers/options'
import { bookingsFollowupsActions } from 'helpers/followups'
// Redux
import { fetchBookings, updateBooking, createFollowups, clearAllBookings } from 'redux/entities/actions'
import {
  fetchingBookings,
  updatingBooking,
  getBookingsError,
  getBookingError,
  getBookingsList,
  getBookingsTotal,
  creatingFollowup,
} from 'redux/entities/selectors'
import { getAdminProfile } from 'redux/reducers/admin/selectors'
import { getLocation } from 'redux/reducers/router'

const mapStateToProps = (state) => ({
  getBookingListError: () => getBookingsError(state),
  getBookingError: (id) => getBookingError(state, id),
  loading: fetchingBookings(state),
  updating: (id) => updatingBooking(state, id),
  addingFollowup: creatingFollowup(state),
  bookings: getBookingsList(state),
  total: getBookingsTotal(state),
  location: getLocation(state),
  adminProfile: getAdminProfile(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    loadPaginatedBookings: ({ offset = 0, size = settings.defaultPaginationSize, ...rest }) =>
      dispatch(fetchBookings({ query: { offset, size, ...rest } })),
    updateBooking: (id, payload) => dispatch(updateBooking(id, payload)),
    addFollowups: (payload) => dispatch(createFollowups(payload)),
    clearAllBookings: () => dispatch(clearAllBookings()),
  },
})

const getStaticHeaders = (t) => [
  { label: t('Bookings::Id'), field: 'id' },
  { label: t('Bookings::Last update'), field: 'updated_at' },
  { label: t('Bookings::Country'), field: 'country' },
  { label: t('Bookings::Locality'), field: 'locality' },
  { label: t('Bookings::Status'), field: 'status' },
  { label: t('Bookings::Event date'), field: 'date' },
  { label: t('Bookings::Expiration date'), field: '' },
  { label: t('Bookings::Cancellation deadline') },
  { label: t('Bookings::Guest (id, name & email)'), field: 'guest' },
  { label: t('Bookings::Host (id, name)'), field: 'host' },
  { label: t('Bookings::Experience (id - title)'), field: 'event' },
  { label: t('Bookings::Total paid'), field: 'total_paid' },
  { label: t('Bookings::Seats'), field: 'seats' },
  { label: t('Bookings::Coupon'), field: 'coupon_code' },
  { label: t('Bookings::Last followup'), field: 'followups' },
  { label: t('Bookings::Actions::Actions') },
]

const getFollowUpsOptions = (t) => [
  ...getTranslatedOptions(t, bookingsFollowupsActions),
  { text: t('FollowUps::Actions::Read Inbox/messages'), value: 'Read Inbox/messages' },
  { text: t('FollowUps::Actions::Rejected'), value: 'Rejected' },
]

class _BookingList extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      clearAllBookings: PropTypes.func.isRequired,
      loadPaginatedBookings: PropTypes.func,
      updateBooking: PropTypes.func,
      addFollowups: PropTypes.func,
    }).isRequired,
    location: PropTypes.immutable.map.isRequired,
    loading: PropTypes.bool,
    getBookingListError: PropTypes.immutable.list,
    getBookingError: PropTypes.immutable.object,
    bookings: PropTypes.immutable.list,
    total: PropTypes.number,
    updating: PropTypes.func.isRequired,
    addingFollowup: PropTypes.bool.isRequired,
    adminProfile: PropTypes.shape({
      id: PropTypes.number,
    }),
  }

  static defaultProps = {
    loading: false,
    total: 0,
    bookings: undefined,
    adminProfile: undefined,
  }

  state = { listError: undefined, bookingStatusError: undefined, selection: [], comment: '' }

  UNSAFE_componentWillMount() {
    // Handle landing on /bookings without params
    const defaultQuery = { offset: 0, size: settings.defaultPaginationSize }
    const query = this.props.location.get('query')
    if (query.size) {
      this.loadBookings({ ...defaultQuery, ...query.toJS() })
    }
    this.setState({
      openStatus: false,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (getFullPath(this.props.location) !== getFullPath(nextProps.location)) {
      this.loadBookings(nextProps.location.get('query').toJS())
    }
  }

  loadBookings(query) {
    return this.props.actions
      .loadPaginatedBookings(omitBy(query, isInvalid))
      .then(() => this.setState({ listError: this.props.getBookingListError() }))
  }

  handleEditStatus = (booking) => {
    this.setState({
      openStatus: true,
      bookingToEdit: booking,
    })
  }

  handleAddFollowup = (booking) => {
    this.setState({
      openFollowup: true,
      bookingToEdit: booking,
    })
  }

  handleOpenHistory = (user) => {
    this.props.actions.clearAllBookings()
    this.setState({
      openHistory: true,
      userToShow: user,
    })
  }

  handleCloseHistory = () => {
    this.props.actions.clearAllBookings()
    this.setState({ openHistory: false })
    this.loadBookings({ offset: 0, size: settings.defaultPaginationSize })
  }

  handleStatusSubmit = (id, action, e) => {
    e.preventDefault()
    return this.props.actions.updateBooking(id, { action }).then(() => {
      const error = this.props.getBookingError(id)
      this.setState({ openStatus: !!error, bookingStatusError: error })
    })
  }

  onSelect = (checked, payoutId) => {
    return this.setState({
      selection: checked ? [...this.state.selection, payoutId] : this.state.selection.filter((id) => id !== payoutId),
    })
  }

  selectAll = (e, { checked }) => {
    this.setState({
      selection: checked ? this.props.bookings.map((p) => p.id).toArray() : [],
    })
  }

  submit = () => {
    const followups = []

    this.state.selection.map((id) =>
      followups.push({
        following_id: id,
        following_type: 'bookings',
        action: this.state.selectedAction,
        comment: this.state.comment,
        admin_id: this.props.adminProfile.id,
      }),
    )
    return this.props.actions
      .addFollowups({ followups })
      .then(() => this.setState({ selection: [] }))
      .then(() => this.setState({ listError: this.props.getBookingListError() }))
  }

  render() {
    const { t, bookings } = this.props
    const allSelected = this.state.selection.length === this.props.bookings.size
    const headers = [...getStaticHeaders(t)].concat({
      label: <Checkbox onChange={this.selectAll} checked={allSelected} />,
    })

    return (
      <div className='__bookingList'>
        <Modal
          open={this.state.openStatus}
          size='small'
          closeIcon
          onClose={() => this.setState({ openStatus: false, bookingStatusError: undefined })}
        >
          {this.state.bookingToEdit && (
            <Modal.Header>
              {t('Bookings::Actions::Change status of booking n° {{bookingId}}', {
                bookingId: this.state.bookingToEdit.id,
              })}
            </Modal.Header>
          )}

          <Modal.Content>
            {this.state.openStatus && (
              <ChangeStatusForm
                id={this.state.bookingToEdit.id}
                status={this.state.bookingToEdit.status}
                onSubmit={this.handleStatusSubmit}
                error={this.state.bookingStatusError}
              />
            )}
          </Modal.Content>
        </Modal>

        <Modal open={this.state.openHistory} size='large' onClose={this.handleCloseHistory}>
          {this.state.userToShow && (
            <Modal.Header>
              {t('Bookings::Actions::Bookings history for user n° {{userId}}', {
                userId: this.state.userToShow.id,
              })}
            </Modal.Header>
          )}

          <Modal.Content>
            {this.state.openHistory && <BookingsHistory userId={this.state.userToShow.id} />}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleCloseHistory}>{t('Bookings::Actions::Exit')}</Button>
          </Modal.Actions>
        </Modal>

        <Paginate count={this.props.total} previous next loading={this.props.loading}>
          <Form onSubmit={this.submit} className='__followupForm'>
            <Form.Group>
              <Form.Field>
                <label>{t('Bookings::Actions::Actions')}</label>
                <Select
                  options={getFollowUpsOptions(t)}
                  onChange={(e, { value }) => this.setState({ selectedAction: value })}
                  value={this.state.selectedAction}
                />
              </Form.Field>

              <Form.Field>
                <label>{t('Bookings::Actions::Comment')}</label>
                <Form.TextArea
                  rows={1}
                  onChange={(e, { value }) => this.setState({ comment: value })}
                  value={this.state.comment}
                />
              </Form.Field>

              <Form.Field>
                <label>&nbsp;</label>
                <Button type='submit' disabled={this.state.selection.length === 0}>
                  {t('Bookings::Actions::Ok')}
                </Button>
              </Form.Field>
            </Form.Group>
          </Form>

          <List striped celled sortable headers={headers} location={this.props.location}>
            {bookings.toList().map((booking) => (
              <BookingRow
                key={booking.id}
                booking={booking}
                onEditStatus={this.handleEditStatus}
                onAddFollowup={this.handleAddFollowup}
                onOpenHistory={this.handleOpenHistory}
                onOpenConversation={() => this.setState({ openConversation: true })}
                changingStatus={this.props.updating(booking.id)}
                addingFollowup={this.props.addingFollowup}
                onSelect={this.onSelect}
                selected={this.state.selection.includes(booking.id)}
              />
            ))}
          </List>
        </Paginate>
        <ApiErrorMessage error={this.state.listError} modal />
      </div>
    )
  }
}

export const BookingList = withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(_BookingList))
