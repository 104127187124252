// Libs
import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { withTranslation, Trans } from 'react-i18next'
// Components
import { Link } from 'react-router'
import { Grid, Segment } from 'semantic-ui-react'
// Redux
import { fetchBooking } from 'redux/entities/actions'
import { getBooking } from 'redux/entities/selectors'

const mapStateToProps = (state) => ({
  fromStore: {
    getBooking: (id) => getBooking(state, { id }),
  },
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    loadBooking: (id) => dispatch(fetchBooking(id)),
  },
})

class _RequestBookings extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    bookingIds: PropTypes.immutable.list.isRequired,
    actions: PropTypes.shape({
      loadBooking: PropTypes.func,
    }).isRequired,
    fromStore: PropTypes.shape({
      getBooking: PropTypes.func,
    }).isRequired,
  }

  componentDidMount() {
    const { actions, bookingIds } = this.props
    bookingIds.map((id) => actions.loadBooking(id))
  }

  formatStatus = (status) => {
    if (typeof status !== 'string') return ''
    return status.replace(/_/g, ' ')
  }

  renderBooking = (id) => {
    const { t, fromStore } = this.props
    const booking = fromStore.getBooking(id)
    if (!booking) return null
    return (
      <Grid.Row>
        <Grid.Column width={4}>
          <label>
            <Trans
              i18nKey={__('Requests::Summary::Bookings::ID: <bookingURL>{{bookingId}}</bookingURL>')}
              values={{
                bookingId: booking.id,
              }}
              components={{
                bookingURL: <Link to={`/bookings?search=${booking.id}`} />,
              }}
            />
          </label>
        </Grid.Column>
        <Grid.Column width={4}>
          <label>
            <Trans
              i18nKey={__(
                'Requests::Summary::Bookings::Guest: <bookingURL>{{guestId}} - {{guestFirstName}} {{guestLastName}}</bookingURL>',
              )}
              values={{
                guestId: booking.user.id,
                guestFirstName: booking.user.firstname,
                guestLastName: booking.user.lastname,
              }}
              components={{
                bookingURL: <Link to={`/users?search=${booking.user.id}`} />,
              }}
            />
          </label>
        </Grid.Column>
        <Grid.Column width={4}>
          <label>{t('Requests::Summary::Bookings::Seats: {{seats}}', { seats: booking.seats })}</label>
        </Grid.Column>
        <Grid.Column width={4}>
          <label>
            {t('Requests::Summary::Bookings::Status: {{status}}', { status: this.formatStatus(booking.status) })}
          </label>
        </Grid.Column>
      </Grid.Row>
    )
  }

  render() {
    const { bookingIds } = this.props
    if (!bookingIds.size) return null
    return (
      <Segment>
        <Grid className='vz-booking-conversation'>{bookingIds.map((id) => this.renderBooking(id))}</Grid>
      </Segment>
    )
  }
}

export const RequestBookings = withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(_RequestBookings))
