import {
  getEntities,
  getError,
  isFetching,
  getEntity,
  getEntityCount,
  getEntityTotal,
  getEntityOrder,
  isUpdating,
  isCreating,
} from './_utils'

export const getTags = getEntities('tags')
export const getTag = getEntity('tags')
export const getTagsCount = getEntityCount('tags')
export const getTagsOrder = getEntityOrder('tags')
export const getTagsTotal = getEntityTotal('tags')
export const getTagsList = (state) => getTagsOrder(state).map((id) => getTag(state, { id }))
export const getSortedTags = (state) =>
  getTags(state)
    .toList()
    .sortBy((tag) => tag.title)

export const fetchingTags = isFetching('/api/tags')
export const fetchingTag = (state, id) => isFetching(`/api/tags/${id}`)(state)
export const creatingTag = isCreating('/api/tags')
export const updatingTag = (state, id) => isUpdating(`/api/tags/${id}`)(state)

export const getTagError = (state, id) => getError(`/api/tags/${id}`)(state)
export const getTagsError = getError('/api/tags')
