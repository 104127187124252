import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const invitesActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'invites' })

export const fetchInvite = ({ query } = {}) =>
  invitesActions.fetch({
    url: '/api/invites',
    schema: { invites: schemas.invites },
    query,
  })
