// Libs
import React, { Fragment, useReducer } from 'react'
import moment from 'moment'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'
// Helpers
import { numbers } from '@vizeat/helpers'
import { getTranslatedOptions } from 'helpers/options'
import { statusOptions } from 'helpers/partnerDemands'
import { debouncedfetchAutocomplete as fetchAutocomplete } from 'helpers/places'
// Components
import { Button, Divider, Form, Input, Popup } from 'semantic-ui-react'
import { MultiRangeDayPicker } from '@vizeat/components/es6/components/MultiRangeDayPicker'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { CalendarPicker } from '@vizeat/components/es6/components/CalendarPicker'
import { Autocomplete } from '@vizeat/components/es6/components/Autocomplete'
import { PartnerAutocomplete, SelectCurrency } from 'components/inputs'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
// Redux
import { getCurrency } from 'redux/entities/selectors'

const { formatPriceAsFloat, formatPriceAsInt } = numbers

const mapStateToProps = (state) => ({
  fromStore: {
    getCurrency: (id) => getCurrency(state, { id }),
  },
})

function _PartnerDemandForm({ fromStore: { getCurrency }, formError, handleFormSubmission, partnerDemand, isEdit }) {
  const { t, i18n } = useTranslation()
  const initialState = {
    ageInterval: partnerDemand.age_interval || 'All',
    beginsAt: partnerDemand.begins_at || '20:00',
    countryIso: partnerDemand.country_iso,
    currencyId: get(partnerDemand, ['currency', 'id'], 1),
    selectedDays: partnerDemand.date ? [partnerDemand.date] : [],
    eventType: partnerDemand.event_type || 'Dinner',
    locality: partnerDemand.locality,
    partnerId: get(partnerDemand, ['reference', 'partner', 'id']),
    price: partnerDemand.price,
    referenceCode: get(partnerDemand, ['reference', 'code']),
    referenceCodePerDate: {},
    seats: partnerDemand.seats,
    freeSeats: partnerDemand.free_seats,
    status: partnerDemand.status || 'RequestedByPartner',
  }

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_AGE_INTERVAL':
        return { ...state, ageInterval: action.payload }
      case 'SET_BEGINS_AT':
        return { ...state, beginsAt: action.payload }
      case 'SET_CURRENCY':
        return { ...state, currencyId: action.payload }
      case 'SET_DATES':
        return { ...state, selectedDays: action.payload }
      case 'SET_EVENT_TYPE':
        return { ...state, eventType: action.payload }
      case 'SET_PARTNER':
        return { ...state, partnerId: action.payload }
      case 'SET_PLACE':
        return { ...state, countryIso: action.payload.countryIso, locality: action.payload.locality }
      case 'SET_PRICE':
        return { ...state, price: formatPriceAsInt(action.payload, getCurrency(state.currencyId)) }
      case 'SET_REFERENCE_CODE':
        return { ...state, referenceCode: action.payload }
      case 'SET_REFERENCE_CODE_PER_DATE':
        return {
          ...state,
          referenceCodePerDate: {
            ...state.referenceCodePerDate,
            [moment(action.payload.date).format('YYYY-MM-DD')]: action.payload.value,
          },
        }
      case 'SET_SEATS':
        return { ...state, seats: action.payload }
      case 'SET_FREE_SEATS':
        return { ...state, freeSeats: action.payload }
      case 'SET_STATUS':
        return { ...state, status: action.payload }
      default:
        throw new Error()
    }
  }, initialState)

  const dispatchAction = (actionName, payload) => dispatch({ type: actionName, payload })

  function handleSubmit(e) {
    e.preventDefault()
    e.stopPropagation()
    handleFormSubmission(state)
  }

  async function handleAutocompleteItems(value) {
    const places = await fetchAutocomplete({ query: value, language: i18n.language, type: 'city' })
    if (places && Array.isArray(places)) {
      return places.filter((place) => place.locality || place.administrative_area_level_2)
    }
    return []
  }

  return (
    <Fragment>
      <Form>
        <Form.Group widths='equal'>
          <Form.Field required>
            <label>{t('PartnerDemands::Summary::Partner')}</label>
            <PartnerAutocomplete
              disabled={isEdit}
              initialValue={get(partnerDemand, ['reference', 'partner', 'name'])}
              handleResultSelect={(partnerId) => dispatchAction('SET_PARTNER', partnerId)}
            />
          </Form.Field>
          {isEdit && (
            <Form.Field>
              <label>{t('PartnerDemands::Summary::Reference code')}</label>
              <Form.Input
                disabled
                value={state.referenceCode}
                onChange={(e, { value }) => dispatchAction('SET_REFERENCE_CODE', value)}
              />
            </Form.Field>
          )}
        </Form.Group>
        <Form.Field required>
          <label>{t('PartnerDemands::Summary::Place')}</label>
          <Autocomplete
            initialValue={state.locality && state.countryIso ? `${state.locality}, ${state.countryIso}` : undefined}
            getItems={handleAutocompleteItems}
            itemToString={(item) =>
              (item && `${item.locality || item.administrative_area_level_2}, ${item.country_iso}`) || ''
            }
            onChange={({ value, item }) =>
              dispatchAction('SET_PLACE', {
                countryIso: item.country_iso,
                locality: item.locality || item.administrative_area_level_2,
              })
            }
          />
        </Form.Field>
        <Divider />
        <Form.Group widths='equal'>
          <Form.Field required>
            <label>{t('PartnerDemands::Summary::Total number of seats')}</label>
            <Form.Input
              type='number'
              min='1'
              placeholder={t('PartnerDemands::Summary::Enter number of seats?')}
              value={state.seats}
              onChange={(e, { value }) => dispatchAction('SET_SEATS', parseInt(value))}
            />
          </Form.Field>
          <Form.Field required>
            <label>{t('PartnerDemands::Summary::Number of free seats')}</label>
            <Form.Input
              type='number'
              min='0'
              placeholder={t('PartnerDemands::Summary::Enter number of free seats?')}
              value={state.freeSeats}
              onChange={(e, { value }) => dispatchAction('SET_FREE_SEATS', parseInt(value))}
            />
          </Form.Field>
          <Form.Field required>
            <label>{t('PartnerDemands::Summary::Partner price')}</label>
            <Form.Input
              type='number'
              min='1'
              placeholder={t('PartnerDemands::Summary::Enter price per seat')}
              value={formatPriceAsFloat(state.price, getCurrency(state.currencyId))}
              onChange={(e, { value }) => dispatchAction('SET_PRICE', value)}
            />
          </Form.Field>
          <Form.Field required>
            <label>{t('PartnerDemands::Summary::Currency')}</label>
            <SelectCurrency
              currencyId={state.currencyId}
              handleCurrencyChange={(currencyId) => dispatchAction('SET_CURRENCY', parseInt(currencyId))}
            />
          </Form.Field>
        </Form.Group>
        <Divider />
        <Form.Group widths='equal'>
          <Form.Field required>
            <label>{t('PartnerDemands::Summary::Date(s)')}</label>
            <Popup
              className='CalendarPickerPopup'
              on='click'
              trigger={
                <Input
                  disabled={partnerDemand.bookings && !!partnerDemand.bookings.size}
                  icon='calendar outline'
                  value={state.selectedDays ? state.selectedDays.map((date) => moment(date).format('YYYY-MM-DD')) : ''}
                  placeholder={t('PartnerDemands::Summary::YYYY-MM-DD')}
                />
              }
              content={
                isEdit ? ( // On edition, you can select only one date.
                  <CalendarPicker
                    selectedDays={state.selectedDays}
                    onDayClick={(date) => dispatchAction('SET_DATES', [moment(date).format('YYYY-MM-DD')])}
                  />
                ) : (
                  <MultiRangeDayPicker
                    onChange={(selectedDays) => dispatchAction('SET_DATES', selectedDays)}
                    render={({ handleClear, ...props }) => (
                      <div>
                        <Button onClick={handleClear}>{t('PartnerDemands::Summary::Clear')}</Button>
                        <CalendarPicker selectedDays={state.selectedDays} {...props} />
                      </div>
                    )}
                  />
                )
              }
            />
          </Form.Field>

          <Form.Field>
            <label>{t('PartnerDemands::Summary::Start time')}</label>
            <Form.Input
              type='time'
              step='900'
              value={state.beginsAt}
              onChange={(e, { value }) => dispatchAction('SET_BEGINS_AT', value)}
            />
          </Form.Field>
        </Form.Group>
        {!isEdit && (
          <Flex maxHeight='140px' flexDirection='column' overflowY='scroll' overflowX='hidden'>
            {state.selectedDays.map((date, index) => (
              <Form.Group key={date} widths='equal'>
                <Form.Field>
                  {index === 0 && <label>{t('PartnerDemands::Summary::Date')}</label>}
                  <Form.Input disabled value={moment.utc(date).format('YYYY-MM-DD')} />
                </Form.Field>
                <Form.Field>
                  {index === 0 && <label>{t('PartnerDemands::Summary::Associated reference code')}</label>}
                  <Form.Input
                    value={get(state.referenceCodePerDate, moment(date).format('YYYY-MM-DD'))}
                    onChange={(e, { value }) => dispatchAction('SET_REFERENCE_CODE_PER_DATE', { date, value })}
                  />
                </Form.Field>
              </Form.Group>
            ))}
          </Flex>
        )}
        <Divider />
        <Form.Group widths='equal'>
          <Form.Field required>
            <label>{t('PartnerDemands::Summary::Event type')}</label>
            <Form.Select
              value={state.eventType}
              options={[
                { text: t('PartnerDemands::Summary::Aperitif'), value: 'Aperitif' },
                { text: t('PartnerDemands::Summary::Breakfast'), value: 'Breakfast' },
                { text: t('PartnerDemands::Summary::Brunch'), value: 'Brunch' },
                { text: t('PartnerDemands::Summary::Cooking classes'), value: 'Cooking classes' },
                { text: t('PartnerDemands::Summary::Dinner'), value: 'Dinner' },
                { text: t('PartnerDemands::Summary::Food walk'), value: 'Food walk' },
                { text: t('PartnerDemands::Summary::Lunch'), value: 'Lunch' },
                { text: t('PartnerDemands::Summary::Tasting'), value: 'Tasting' },
                { text: t('PartnerDemands::Summary::Tea time'), value: 'Tea time' },
                { text: t('PartnerDemands::Summary::Picnic'), value: 'Picnic' },
              ]}
              onChange={(e, { value }) => dispatchAction('SET_EVENT_TYPE', value)}
            />
          </Form.Field>
          <Form.Field required>
            <label>{t('PartnerDemands::Summary::Age interval')}</label>
            <Form.Select
              value={state.ageInterval}
              options={[
                { text: t('PartnerDemands::Summary::Adult'), value: 'Adult' },
                { text: t('PartnerDemands::Summary::All'), value: 'All' },
                { text: t('PartnerDemands::Summary::Young'), value: 'Young' },
              ]}
              onChange={(e, { value }) => dispatchAction('SET_AGE_INTERVAL', value)}
            />
          </Form.Field>

          <Form.Field>
            <label>{t('PartnerDemands::Summary::Status')}</label>
            <Form.Select
              value={state.status}
              options={getTranslatedOptions(t, statusOptions)}
              onChange={(e, { value }) => dispatchAction('SET_STATUS', value)}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <div style={{ textAlign: 'center' }}>
        <Button primary size='big' content={t('PartnerDemands::Summary::Submit')} onClick={handleSubmit} />
      </div>

      <ApiErrorMessage error={formError} modal />
    </Fragment>
  )
}

_PartnerDemandForm.propTypes = {
  fromStore: PropTypes.shape({
    getCurrency: PropTypes.func.isRequired,
  }).isRequired,
  formError: PropTypes.object,
  handleFormSubmission: PropTypes.func.isRequired,
  partnerDemand: PropTypes.immutable.record,
  isEdit: PropTypes.bool,
}

_PartnerDemandForm.defaultProps = {
  formError: undefined,
  isEdit: false,
  partnerDemand: {},
}

export const PartnerDemandForm = connect(mapStateToProps)(_PartnerDemandForm)
