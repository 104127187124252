// libs
import React, { PureComponent } from 'react'
import PropTypes, { omitSelfProps } from 'helpers/proptypes'
import cn from 'classnames'
// components
import { Form } from 'semantic-ui-react'
// styles
import './InputExpandingOnFocus.css'

export class InputExpandingOnFocus extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    Component: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    className: PropTypes.string,
    style: PropTypes.object,
    blurWidth: PropTypes.number,
    focusWidth: PropTypes.number,
  }

  static defaultProps = {
    Component: 'input',
    label: undefined,
    className: undefined,
    style: {},
    blurWidth: 70,
    focusWidth: 180,
  }

  state = { width: undefined }
  UNSAFE_componentWillMount() {
    this.setWidth(this.props.blurWidth)
  }

  setWidth = (width) => this.setState({ width })
  setWidthOnFocus = () => this.setWidth(this.props.focusWidth)
  setWidthOnBlur = () => this.setWidth(this.props.blurWidth)

  getStyle = () => this.state

  render() {
    const { label, Component, className, style } = this.props
    return (
      <Form.Field className='InputExpandingOnFocus'>
        {label && <label className='InputExpandingOnFocus-label'>{label}</label>}

        <Component
          onFocus={this.setWidthOnFocus}
          onBlur={this.setWidthOnBlur}
          className={cn('InputExpandingOnFocus-input', className)}
          style={Object.assign({}, style, this.getStyle())}
          {...omitSelfProps(this)}
        />
      </Form.Field>
    )
  }
}
