// Libraries
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
// Components
import DatePicker from 'react-datepicker'
import { Form, Button } from 'semantic-ui-react'
import { SearchInput, SelectInput } from 'components/inputs'
// Redux
import { getLocation } from 'redux/reducers/router'
import { getSortedCurrencies } from 'redux/entities/selectors'
// Utils
import { buildSelectOptions } from 'helpers/forms'
import { getTranslatedOptions } from 'helpers/options'
import {
  payoutStatusOptions,
  payoutDateRangeOptions,
  payoutGatewayOptions,
  payoutPaidStatusOptions,
} from 'helpers/payouts'

const mapStateToProps = (state) => ({
  location: getLocation(state),
  getSortedCurrencies: () => getSortedCurrencies(state),
})

export class PayoutSearchForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.object,
    getSortedCurrencies: PropTypes.func.isRequired,
  }

  static contextTypes = { router: PropTypes.object }

  currenciesOpts = []
  paidStatusesOpts = []

  UNSAFE_componentWillMount() {
    return this.updateStateFromLocation(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.location.equals(this.props.location)) return this.updateStateFromLocation(nextProps)
  }

  updateStateFromLocation(props) {
    const currenciesIds = props.location.getIn(['query', 'currenciesIds'], '')
    const paid = props.location.getIn(['query', 'paid'], null)
    return this.setState({
      user: props.location.getIn(['query', 'user'], ''),
      statuses: props.location.getIn(['query', 'statuses'], ''),
      gateways: props.location.getIn(['query', 'gateways'], ''),
      daterange: props.location.getIn(['query', 'daterange'], ''),
      fromdate: props.location.getIn(['query', 'fromdate'], ''),
      todate: props.location.getIn(['query', 'todate'], ''),
      currenciesIds,
      paid,

      selectedCurrencies: currenciesIds === '' ? [] : currenciesIds.split(',').map((id) => parseInt(id, 10)),
      selectedPaidStatus: !isNaN(parseInt(paid, 10)) ? parseInt(paid, 10) : null,
    })
  }

  updateQuery(query = this.state) {
    const { selectedCurrencies, selectedPaidStatus, separator, ...cleanedQuery } = query
    return this.context.router.push(
      this.props.location.mergeIn(['query'], { ...cleanedQuery, offset: 0, batch: false }).toJS(),
    )
  }

  clearSorting = () => {
    return this.context.router.push(this.props.location.mergeIn(['query'], { sortBy: '', order: '' }).toJS())
  }

  clearFilters = () => {
    return this.setState(
      {
        user: '',
        daterange: '',
        fromdate: '',
        todate: '',
        statuses: '',
        gateways: '',
        currenciesIds: '',
        paid: null,
        selectedCurrencies: [],
        selectedPaidStatus: null,
      },
      () => this.updateQuery(),
    )
  }

  updateDateRange = (value) => {
    this.setState({ daterange: value })
    if (value === '') {
      this.setState({ fromdate: '' })
      this.setState({ todate: '' })
    }
  }

  updateFromDate = (date) => {
    const fromdate = moment(date).format('YYYY-MM-DD')
    if (!this.state.daterange) this.setState({ daterange: 'date' })
    this.setState({ fromdate })
    if (!this.state.todate || this.state.todate < fromdate) {
      this.setState({ todate: fromdate })
    }
  }

  updateToDate = (date) => {
    const todate = moment(date).format('YYYY-MM-DD')
    if (!this.state.daterange) this.setState({ daterange: 'date' })
    this.setState({ todate })
    if (!this.state.fromdate || this.state.fromdate > todate) {
      this.setState({ fromdate: todate })
    }
  }

  updateCurrency = (ids) => {
    this.setState(
      {
        currenciesIds: ids.join(','),
        selectedCurrencies: ids.map((id) => parseInt(id, 10)),
      },
      this.updateQuery,
    )
  }

  updatePaidStatus = (value) => {
    this.setState(
      {
        paid: value,
        selectedPaidStatus: !isNaN(parseInt(value, 10)) ? parseInt(value, 10) : null,
      },
      this.updateQuery,
    )
  }

  handleSubmit = (e) => {
    e.preventDefault()
    return this.updateQuery()
  }

  getCurrenciesOpts() {
    if (this.currenciesOpts.length === 0) {
      const currencies = this.props.getSortedCurrencies()
      if (currencies.size) {
        this.currenciesOpts = buildSelectOptions(currencies.map((c) => ({ title: c.iso_3, id: c.id })))
      }
    }
    return this.currenciesOpts
  }

  render() {
    const { t } = this.props
    return (
      <Form onSubmit={this.handleSubmit} autoComplete='off'>
        <Form.Group widths='equal'>
          <Form.Field>
            <label>{t('Payouts::Search by user')}</label>
            <SearchInput
              queryName='user'
              placeholder={t('Payouts::id, first/last name, email')}
              icon='search'
              iconPosition='left'
              value={this.state.user}
              onChange={(value) => this.setState({ user: value })}
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Payouts::Stripe Status')}</label>
            <SelectInput
              queryName='statuses'
              options={getTranslatedOptions(t, payoutStatusOptions)}
              multiple
              fluid
              search
              value={this.state.statuses}
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Payouts::Gateway')}</label>
            <SelectInput
              queryName='gateways'
              options={getTranslatedOptions(t, payoutGatewayOptions)}
              multiple
              fluid
              search
              value={this.state.gateways}
            />
          </Form.Field>

          <Form.Dropdown
            label={t('Payouts::Currency')}
            placeholder={t('Inputs::Select...')}
            options={this.getCurrenciesOpts()}
            onChange={(e, { value }) => this.updateCurrency(value)}
            value={this.state.selectedCurrencies}
            multiple
            fluid
            search
            selection
          />

          <Form.Dropdown
            label={t('Payouts::Paid Status')}
            placeholder={t('Inputs::Select...')}
            options={getTranslatedOptions(t, payoutPaidStatusOptions)}
            onChange={(e, { value }) => this.updatePaidStatus(value)}
            value={this.state.selectedPaidStatus}
            fluid
            search
            selection
          />

          <Form.Field>
            <label>&nbsp;</label>
            <Button type='submit'>{t('Payouts::Search')}</Button>

            <Button.Group vertical basic compact size='small' style={{ transform: 'translateY(-15px)' }}>
              <Button onClick={this.clearFilters}>{t('Payouts::Clear filters')}</Button>
              <Button onClick={this.clearSorting}>{t('Payouts::Clear sorting')}</Button>
            </Button.Group>
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field width={3}>
            <label>{t('Payouts::Search by date range')}</label>
            <SelectInput
              queryName='daterange'
              options={getTranslatedOptions(t, payoutDateRangeOptions)}
              onChange={this.updateDateRange}
              value={this.state.daterange}
              preventNavigation
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Payouts::Between')}</label>
            <DatePicker
              name='fromdate'
              todayButton={t('Payouts::Today')}
              selected={this.state.fromdate && moment(this.state.fromdate).isValid() ? moment(this.state.fromdate) : ''}
              onChange={this.updateFromDate}
              placeholderText={t('Payouts::start date')}
              dateFormat='DD/MM/YYYY'
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Payouts::And')}</label>
            <DatePicker
              name='todate'
              todayButton={t('Payouts::Today')}
              selected={this.state.todate && moment(this.state.todate).isValid() ? moment(this.state.todate) : ''}
              onChange={this.updateToDate}
              placeholderText={t('Payouts::end date')}
              dateFormat='DD/MM/YYYY'
            />
          </Form.Field>

          <Form.Field>
            <label>&nbsp;</label>
            <Button type='submit'>{t('Payouts::Search')}</Button>
          </Form.Field>
        </Form.Group>
      </Form>
    )
  }
}

export default withTranslation('common')(connect(mapStateToProps)(PayoutSearchForm))
