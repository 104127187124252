import settings from 'settings'
import React, { useState, useMemo, useEffect, useCallback } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useToggle } from '@vizeat/components/es6/hooks'
import { Modal, Button, Checkbox, Table, Label } from 'semantic-ui-react'
import { Link } from 'react-router'
import { EventsSearchForm } from './EventsSearchForm'
import { Pagination } from 'components/lists'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { usePrevious } from 'hooks/usePrevious'
import { fetchEvents } from 'redux/entities/actions'
import { getEventsError, getEventsFromIdsArray } from 'redux/entities/selectors'

const EVENTS_PER_PAGE = 20

export function EventsSelectionModalTrigger({ children, handleChangeForm, formSearchParams }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [showModal, { toggle: toggleModalVisibility }] = useToggle()
  const fetchingExperiencesError = useSelector(getEventsError)

  const previousIncludeEventIds = usePrevious(formSearchParams?.includeEventIds)
  const initialState = useMemo(
    () => ({
      page: 1,
      fetchedExperienceCount: 0,
      searchedEventsIds: [],
      checkedEventIds: formSearchParams?.includeEventIds || [],
      query: undefined,
    }),
    [formSearchParams.includeEventIds],
  )
  const [state, setState] = useState(initialState)

  const checkedEvents = useSelector((reduxState) => getEventsFromIdsArray(reduxState, state.checkedEventIds))
  const searchedEvents = useSelector((reduxState) => getEventsFromIdsArray(reduxState, state.searchedEventsIds))

  const paginatedEvents = useMemo(() => {
    const startIndex = EVENTS_PER_PAGE * (state.page - 1)
    const endIndex = EVENTS_PER_PAGE * state.page
    return state.query
      ? searchedEvents.toJS().filter(({ id }) => !!id)
      : checkedEvents
          .toJS()
          .filter(({ id }) => !!id)
          .slice(startIndex, endIndex)
  }, [searchedEvents, checkedEvents, state.page, state.query])

  const arePaginatedEventsChecked = useMemo(
    () => paginatedEvents.every(({ id }) => state.checkedEventIds.includes(id)),
    [paginatedEvents, state.checkedEventIds],
  )
  const maxPage = useMemo(
    () => Math.ceil((state.query ? state.fetchedExperienceCount : state.checkedEventIds.length) / EVENTS_PER_PAGE),
    [state.checkedEventIds.length, state.fetchedExperienceCount, state.query],
  )

  const handleFetchEvents = useCallback(
    async (query) => {
      const {
        payload: { data, error },
      } = await dispatch(fetchEvents({ query }))
      if (!error)
        return setState((prev) => ({
          ...prev,
          searchedEventsIds: data.result.events,
          fetchedExperienceCount: data.result.metadata.count,
        }))
    },
    [dispatch],
  )

  useEffect(() => {
    if (state.query) handleFetchEvents(state.query)
  }, [state.query, handleFetchEvents])

  useEffect(() => {
    // force the comparison of JS primitives because the array references are different
    if (previousIncludeEventIds?.join(',') !== formSearchParams?.includeEventIds?.join(',')) {
      setState(initialState)
    }
  }, [formSearchParams?.includeEventIds, initialState, previousIncludeEventIds])

  if (!children) return null

  function handleModalVisibility() {
    toggleModalVisibility()
    handleClearFilters()
  }

  function handleEventsSearch(searchQuery) {
    const query = { ...searchQuery, offset: 0, size: EVENTS_PER_PAGE }
    setState((prev) => ({ ...prev, page: 1, query: { ...prev.query, ...query } }))
  }

  function handleClearFilters() {
    setState({ ...initialState, checkedEventIds: state.checkedEventIds })
  }

  function handleRestoreSelection() {
    setState(initialState)
    toggleModalVisibility()
  }

  function handlePageChange(pageNum) {
    const offset = (pageNum - 1) * EVENTS_PER_PAGE
    setState((prev) => ({
      ...prev,
      page: pageNum,
      ...(state.query && { query: { ...state.query, offset, size: EVENTS_PER_PAGE } }),
    }))
  }

  function handleRowSelection(_, { value, checked }) {
    const eventId = parseInt(value)
    const nextEventsIds = checked
      ? [...state.checkedEventIds, eventId]
      : state.checkedEventIds.filter((id) => id !== eventId)
    setState((prev) => ({ ...prev, checkedEventIds: nextEventsIds }))
  }

  function handlePageBatchSelection(_, { checked }) {
    const paginatedEventIds = paginatedEvents.map(({ id }) => id)
    if (checked)
      return setState((prev) => ({
        ...prev,
        checkedEventIds: Array.from(new Set(prev.checkedEventIds.concat(paginatedEventIds))),
      }))

    setState((prev) => ({
      ...state,
      checkedEventIds: prev.checkedEventIds.filter((id) => !paginatedEventIds.includes(id)),
      ...(!prev.query && state.page > 1 && { page: prev.page - 1 }),
    }))
  }

  function handleDoneSelection() {
    handleChangeForm({ search: { ...formSearchParams, includeEventIds: state.checkedEventIds } })
    handleModalVisibility()
  }

  return (
    <React.Fragment>
      <Button type='button' size='mini' color='blue' onClick={handleModalVisibility} style={{ marginLeft: '8px' }}>
        {children}
      </Button>

      <Modal open={showModal} size='large' closeIcon onClose={handleRestoreSelection}>
        <Modal.Header>{t('Playlists::Summary::Select events')}</Modal.Header>

        <Modal.Content scrolling>
          <Modal.Description>
            <EventsSearchForm onSearch={handleEventsSearch} onClearFilters={handleClearFilters} />
            <ApiErrorMessage error={fetchingExperiencesError} />
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <Checkbox checked={arePaginatedEventsChecked} onChange={handlePageBatchSelection} />
                  </Table.HeaderCell>
                  <Table.HeaderCell>{t('Playlists::Summary::Id')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('Playlists::Summary::Name')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('Playlists::Summary::Host')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('Playlists::Summary::Price')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('Playlists::Summary::Event type')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('Playlists::Summary::Tags')}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {paginatedEvents.map((event) => (
                  <Table.Row key={event.id}>
                    <Table.Cell>
                      <Checkbox
                        value={String(event.id)}
                        onChange={handleRowSelection}
                        checked={state.checkedEventIds.includes(event.id)}
                      />
                    </Table.Cell>
                    <Table.Cell>{event.id}</Table.Cell>
                    <Table.Cell>
                      <Link to={settings.webappUrlFactory.events.get(event.id)} target='_blank' rel='noreferrer'>
                        {event.title}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      <Link to={`/users?search=${event.user.id}`}>
                        {`${event.user.id} - ${event.user.firstname} ${event.user.lastname}`}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>{`${event.user.currency.symbol}${event.price / 100}`}</Table.Cell>
                    <Table.Cell>{event.type}</Table.Cell>
                    <Table.Cell>
                      {event.tags.map((tag) => (
                        <Label key={tag}>{tag}</Label>
                      ))}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              <Table.Footer>
                <Table.Row textAlign='center'>
                  <Table.HeaderCell colSpan='12'>
                    <Pagination previous next maxPage={maxPage} page={state.page} onSelect={handlePageChange} />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Modal.Description>
        </Modal.Content>

        <Modal.Actions style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <h3 style={{ margin: 0 }}>
            {t('Playlists::Summary::{{selectedEventsCount}} events selected', {
              selectedEventsCount: state.checkedEventIds.length,
            })}
          </h3>

          <Button primary inverted onClick={handleDoneSelection}>
            {t('Playlists::Summary::Done selecting events')}
          </Button>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  )
}

EventsSelectionModalTrigger.propTypes = {
  children: PropTypes.node,
  handleChangeForm: PropTypes.func.isRequired,
  formSearchParams: PropTypes.shape({
    includeEventIds: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
}

EventsSelectionModalTrigger.defaultProps = {
  children: undefined,
}
