import {
  getEntities,
  isFetching,
  isUpdating,
  isCreating,
  getError,
  getEntity,
  getEntityCount,
  getEntityOrder,
  getEntityTotal,
  getCreatingError,
} from './_utils'
import { getEvent } from './events'
import { getPartner } from './partners'
import { getUser } from './users'
import { getPlace } from './places'
import { getFollowup } from './followups'

export const getBookings = getEntities('bookings')
export const getBookingsCount = getEntityCount('bookings')
export const getBooking = (state, { id }) => {
  const booking = getEntity('bookings')(state, { id })

  return booking
    .set('user', getUser(state, { id: booking.user_id || booking.user }))
    .set('event', getEvent(state, { id: booking.event_id || booking.event }))
    .set('partner', getPartner(state, { id: booking.partner_id || booking.partner }))
    .set('place', getPlace(state, { id: booking.place_id || booking.place }))
    .set('followups', booking.followups ? booking.followups.map((id) => getFollowup(state, { id })) : null)
}

export const creatingBooking = isCreating('/api/bookings')
export const fetchingBookings = isFetching('/api/bookings')
export const fetchingBooking = (state, id) => isFetching(`/api/bookings/${id}`)(state)
export const updatingBooking = (state, id) => isUpdating(`/api/bookings/${id}`)(state)
export const updatingBookings = isUpdating('/api/bookings')

export const getBookingsError = getError('/api/bookings')
export const getBookingError = (state, id) => getError(`/api/bookings/${id}`)(state)
export const getDirectBookingsError = getError('/api/bookings/direct')
export const getCreatingDirectBookingError = getCreatingError('/api/bookings/direct')

export const getBookingsOrder = getEntityOrder('bookings')
export const getBookingsTotal = getEntityTotal('bookings')
export const getBookingsList = (state) => getBookingsOrder(state).map((id) => getBooking(state, { id }))
