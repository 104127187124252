import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'helpers/proptypes'
import { Checkbox, Table } from 'semantic-ui-react'
import settings from 'settings'
import { Link } from 'react-router'
import { mergeInForm } from 'redux/forms/actions'
import { getForm } from 'redux/forms/reducer'

const formName = 'events'

const mapStateToProps = (state, props) => ({
  fromStore: { form: getForm(state, formName) },
})

const mapDispatchToProps = (dispatch) => ({
  actions: { mergeInForm: (formData) => dispatch(mergeInForm({ formName: formName, value: formData })) },
})

class Row extends PureComponent {
  handleChange = (e, { value, checked }) => {
    const {
      actions,
      fromStore: { form },
    } = this.props
    const eventId = parseInt(value)
    const selectedEvents = form.getIn(['event_ids']).toArray()
    const nextEvents = !checked ? selectedEvents.filter((id) => id !== eventId) : [...selectedEvents, eventId]
    return actions.mergeInForm({ event_ids: nextEvents })
  }

  static propTypes = {
    fromStore: PropTypes.shape({
      form: PropTypes.object,
    }).isRequired,
    actions: PropTypes.shape({
      mergeInForm: PropTypes.func.isRequired,
    }).isRequired,
    event: PropTypes.immutable.record.isRequired,
  }

  render() {
    const {
      event,
      fromStore: { form },
    } = this.props
    const isSelected = form.getIn(['event_ids']).includes(event.id)
    return (
      <Table.Row>
        <Table.Cell>
          <Checkbox value={String(event.id)} onChange={this.handleChange} checked={isSelected} />
        </Table.Cell>
        <Table.Cell>{event.id}</Table.Cell>
        <Table.Cell>
          <a href={settings.webappUrlFactory.events.get(event.id)} target='_blank' rel='noopener noreferrer'>
            {event.title}
          </a>
        </Table.Cell>
        <Table.Cell>
          <Link to={`/users?search=${event.user.id}`}>{`${event.user.id}`}</Link>
        </Table.Cell>
        <Table.Cell>{event.type}</Table.Cell>
        <Table.Cell>{`${event.user.currency.symbol}${event.price / 100}`}</Table.Cell>
        <Table.Cell>{event.published_at}</Table.Cell>
      </Table.Row>
    )
  }
}

export const EventRow = connect(mapStateToProps, mapDispatchToProps)(Row)
