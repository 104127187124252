// Libraries
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
// Components
import { FileUploader } from 'components/inputs'
import { Icon } from 'semantic-ui-react'
// Style
import './UploadForm.css'

const UploadForm = ({ onFilesUploaded }) => {
  const [t] = useTranslation()

  return (
    <div className='__uploader'>
      <FileUploader model='gallery' id={0} color='blue' cover multiple afterUpload={onFilesUploaded}>
        <Icon className='__upload-button' name='photo' />
        {t('Gallery::Upload')}
      </FileUploader>
    </div>
  )
}

UploadForm.propTypes = {
  onFilesUploaded: PropTypes.func.isRequired,
}

export default UploadForm
