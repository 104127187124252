// Libs
import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import moment from 'moment'
import { businessApi } from 'helpers/api'
import { base64ToBlob } from 'helpers/blob'
import saveAs from 'file-saver'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
// redux
import { getEventOverrides } from 'redux/entities/selectors'
// Components
import { Table, Button, Icon, Label, Popup, Checkbox, List } from 'semantic-ui-react'
import { Link } from 'react-router'
import { FormattedFollowup } from 'components/followups'
import { ConversationModal } from '../../components/conversations/'
// Utils
import { isStatusEditable, computeCancellationDeadline, successfulBookingStatuses } from 'helpers/bookings'
import { getLocalityAndCountry } from 'helpers/places'
import { numbers } from '@vizeat/helpers'

import './Row.css'

const { Row, Cell } = Table
const { formatIntegerPrice } = numbers

const mapStateToProps = (state, props) => ({
  fromStore: {
    getEventOverrides: (eventId, date) => getEventOverrides(state, { id: eventId, date }),
  },
})

class _BookingRow extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    booking: PropTypes.immutable.record.isRequired,
    onEditStatus: PropTypes.func.isRequired,
    onAddFollowup: PropTypes.func.isRequired,
    onOpenHistory: PropTypes.func.isRequired,
    changingStatus: PropTypes.bool,
    addingFollowup: PropTypes.bool,
    selected: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    fromStore: PropTypes.shape({
      getEventOverrides: PropTypes.immutable.record,
    }).isRequired,
  }

  static defaultProps = {
    changingStatus: undefined,
    addingFollowup: undefined,
    selected: false,
  }

  renderUser(t, user, hasLeftReview = false) {
    return (
      <Cell className={user.ewid ? 'ew' : ''}>
        <Link to={`/users?search=${user.id}`}>{`${user.id} - ${user.firstname} ${user.lastname}`}</Link>
        <br />
        <Popup
          trigger={<Button circular size='mini' icon='history' onClick={() => this.props.onOpenHistory(user)} />}
          content={t('Bookings::History')}
          inverted
        />
        <br />
        {hasLeftReview && (
          <span>
            {t('Bookings::(has left a review)')}
            <br />
          </span>
        )}
      </Cell>
    )
  }

  getPrice(payment) {
    if (!payment || !payment.get('total_paid') || !payment.get('user_currency')) return null
    return formatIntegerPrice(payment.get('total_paid'), 'en', payment.get('user_currency'))
  }

  getDate() {
    const { booking } = this.props
    let dates
    if (booking.date && moment(booking.date).isValid()) dates = moment(booking.date).format('ll')
    else if (booking.get('chosen_dates') && booking.get('chosen_dates').size) {
      dates = booking
        .get('chosen_dates')
        .map((d) => (d && moment(d).isValid ? moment(d).format('ll') : null))
        .filter((d) => !!d)
        .join('\n')
    }
    return dates
  }

  getExpirationDate(eventBeginningTime) {
    const { booking } = this.props
    const lastProposedDate =
      booking.chosen_dates && booking.chosen_dates.size
        ? booking.chosen_dates
            .map((d) => moment(`${d} ${eventBeginningTime}`))
            .sort((d1, d2) => d1.diff(d2))
            .first()
        : null
    const createdAt = moment(booking.created_at).add(3, 'days')
    const dateToConsider = lastProposedDate && lastProposedDate.isBefore(createdAt) ? lastProposedDate : createdAt
    return dateToConsider.format('lll')
  }

  getPartnerAndOrga(booking = this.props.booking) {
    if (!booking.partner.id) return null
    return (
      <Label style={{ textAlign: 'center', marginTop: 5 }}>
        {booking.partner.name}
        <br />({booking.partner.organization.name})
      </Label>
    )
  }

  getStatusLabel(t) {
    const {
      booking: { status, seats, date, chosen_dates: dates, event },
    } = this.props
    const isRequest =
      (status === 'request_information' || status === 'schedule') && seats > 0 && dates && (date || dates.size > 0)
    if (!isRequest) return null
    const awaitingPayment = !!date
    return (
      <Label style={{ textAlign: 'center', marginTop: 5 }}>
        {event.privatized_by ? t('Bookings::PE Request') : t('Bookings::Date Request')}
        <br />
        {awaitingPayment ? t('Bookings::Awaiting Payment') : t('Bookings::Pending Host Approval')}
      </Label>
    )
  }

  handlePDFClick = () =>
    businessApi
      .post('/api/bookings/pdf', { booking_id: this.props.booking.id })
      .then(({ data: { base64 } }) =>
        saveAs(base64ToBlob(base64, 'application/pdf'), `Booking_${this.props.booking.id}.pdf`),
      )

  render() {
    const { t, booking, onEditStatus, addingFollowup, changingStatus, fromStore } = this.props
    const eventOverride = fromStore.getEventOverrides(booking.event.id, booking.date)
    const isEventPrivate =
      booking.event.privatized_at ||
      booking.event.privatized_by ||
      eventOverride.privatized_at ||
      eventOverride.privatized_by
    const eventBeginningTime = eventOverride.begins_at || booking.event.begins_at
    const shouldDisplayPDFDownloadButton = successfulBookingStatuses.includes(booking.status)

    return (
      <Row>
        <Cell className={booking.ewid ? 'ew' : ''}>
          <Link to={`/bookings/${booking.id}`}>{booking.id}</Link>
          {booking.ewid && (
            <i>
              <br />
              {t('Bookings::ew-{{bookingEWId}}', { bookingEWId: booking.ewid })}
            </i>
          )}
          {booking.reference_code && (
            <span>
              <br />
              {t('Bookings::Code: {{referenceCode}}', { referenceCode: booking.reference_code })}
            </span>
          )}
          {booking.request_id && (
            <span>
              <br />
              <Link to={`/requests?search=${booking.request_id}`}>
                {t('Bookings::R-{{requestId}}', { requestId: booking.request_id })}
              </Link>
            </span>
          )}
        </Cell>

        <Cell>{moment(booking.updated_at).format('lll')}</Cell>

        <Cell>{booking.place.country_iso}</Cell>

        <Cell>{getLocalityAndCountry(booking.place)}</Cell>

        <Cell>
          {booking.status}
          &nbsp;
          {changingStatus && <Icon loading name='circle notched' />}
          <br />
          {this.getPartnerAndOrga()}
          {this.getStatusLabel(t)}
          {isEventPrivate && <Label size='small'>{t('Bookings::PRIVATE EVENT')}</Label>}
        </Cell>

        <Cell style={{ whiteSpace: 'pre-line' }}>{this.getDate()}</Cell>

        <Cell style={{ whiteSpace: 'pre-line' }}>{this.getExpirationDate(eventBeginningTime)}</Cell>

        <Cell>
          {computeCancellationDeadline(`${this.getDate()} ${eventBeginningTime}`, booking.cancellation_deadline)}
        </Cell>

        {this.renderUser(t, booking.user, booking.guest_already_left_review)}

        {this.renderUser(t, booking.event.user, booking.host_already_left_review)}

        <Cell className={booking.event.ewid ? 'ew' : ''}>
          <Link to={`/events?search=${booking.event.id}`}>{`${booking.event.id} - ${booking.event.title}`}</Link>
        </Cell>

        <Cell style={{ whiteSpace: 'pre-line' }}>{this.getPrice(booking.payment)}</Cell>

        <Cell>
          <span>{booking.seats}</span>

          {(!!booking.ew_free_seats || !!booking.host_free_seats) && (
            <Popup
              trigger={<Icon circular name='gift' />}
              content={
                <List>
                  <List.Item>{t('Bookings::Total seats: {{seats}}', { seats: booking.seats })}</List.Item>
                  <List.Item>
                    {t('Bookings::EW free seats: {{ewFreeSeats}}', { ewFreeSeats: booking.ew_free_seats })}
                  </List.Item>
                  <List.Item>
                    {t('Bookings::Host free seats: {{hostFreeSeats}}', { hostFreeSeats: booking.host_free_seats })}
                  </List.Item>
                </List>
              }
              inverted
            />
          )}
        </Cell>

        <Cell>{booking.coupon_code}</Cell>

        <Cell>
          <FormattedFollowup followups={booking.followups} />
          &nbsp;
          {addingFollowup && <Icon loading name='circle notched' />}
        </Cell>

        <Cell textAlign='center' className='__actionButtons'>
          <Popup
            trigger={
              <Button
                primary
                circular
                onClick={() => onEditStatus(booking)}
                disabled={!isStatusEditable(booking.status)}
                icon='compose'
              />
            }
            content={t('Bookings::Change Status')}
            inverted
          />

          {shouldDisplayPDFDownloadButton && (
            <Popup
              trigger={<Button primary circular icon='file' onClick={this.handlePDFClick} />}
              content={t('Bookings::Download PDF')}
              inverted
            />
          )}

          <ConversationModal
            conversationId={booking.conversation_id}
            senderId={booking.user.id}
            recipientId={booking.event.user.id}
          />
        </Cell>

        <Cell className='select'>
          <Checkbox
            onChange={(e, { checked }) => this.props.onSelect(checked, booking.id)}
            checked={this.props.selected}
          />
        </Cell>
      </Row>
    )
  }
}

export const BookingRow = withTranslation('common')(connect(mapStateToProps)(_BookingRow))
