import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

// Reindex
export const reindexEvent = (id) =>
  searchEventsActions.create({
    url: `/api/search/reindex/events/${id}`,
    schema: { event: schemas.events },
  })

// search
export const searchEventsActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'searches' })

export const searchEventsInMap = ({ q, pid }) =>
  searchEventsActions.fetch({
    url: '/api/search',
    query: { q, pid, size: 200, offset: 0 },
    schema: { search: schemas.searches },
  })
