import { schema } from 'normalizr'
import { Record, List } from 'immutable'
import { overrides } from './overrides'

const _schema = new schema.Entity(
  'schedules',
  {
    overrides: [overrides.schema],
  },
  { idAttribute: 'date' }
)

const _Record = Record({
  date: undefined,

  opened_event_ids: new List(),
  opened_events: new Map(),

  closed_event_ids: new List(),
  closed_events: new Map(),

  bookable_event_ids: new List(),
  bookable_events: new Map(),

  instant_booking_event_ids: new List(),
  instant_booking_events: new Map(),

  requestable_event_ids: new List(),
  requestable_events: new Map(),

  booking_ids: new List(),
  bookings: new Map(),

  request_ids: new List(),
  requests: new Map(),

  note_id: undefined,
  note: undefined,

  booked_seats: new Map(),
  requested_seats: new Map(),

  overrides: new Map(),
})

export const schedules = {
  schema: _schema,
  Record: _Record,
}

export const emptySchedule = new _Record()
