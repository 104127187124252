import settings from 'settings'
// Libraries
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { omitBy } from 'lodash'
import { isInvalid } from 'helpers/forms'
// Components
import { List, Paginate } from 'components/lists'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { CampaignRow } from './CampaignRow'
// Utils
import { getFullPath } from 'helpers/url'
// Redux
import { fetchCampaigns } from 'redux/entities/actions'
import {
  getCampaignsList,
  getCampaignsCount,
  getCampaignsTotal,
  getCampaignsError,
  fetchingCampaigns,
} from 'redux/entities/selectors'
import { getLocation } from 'redux/reducers/router'
import { withTranslation } from 'react-i18next'

const mapStateToProps = (state) => ({
  error: getCampaignsError(state),
  loading: fetchingCampaigns(state),
  campaigns: getCampaignsList(state),
  count: getCampaignsCount(state),
  total: getCampaignsTotal(state),
  location: getLocation(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    loadPaginatedCampaigns: (query) => dispatch(fetchCampaigns({ query })),
  },
})

const getHeaders = (t) => [
  { label: t('Campaigns::Id') },
  { label: t('Campaigns::Title') },
  { label: t('Campaigns::User Id') },
  { label: t('Campaigns::Category') },
  { label: t('Campaigns::Code') },
  { label: t('Campaigns::Currency') },
  { label: t('Campaigns::Start date') },
  { label: t('Campaigns::End date') },
  { label: t('Campaigns::Active') },
  { label: t('Campaigns::Actions') },
]

class _CampaignsList extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.object,
    campaigns: PropTypes.object,
    count: PropTypes.number,
    total: PropTypes.number,
  }

  state = { error: null }
  UNSAFE_componentWillMount() {
    const defaultQuery = { offset: 0, size: settings.defaultPaginationSize }
    const query = this.props.location.get('query')
    this.loadCampaigns({ ...defaultQuery, ...query.toJS() })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (getFullPath(this.props.location) !== getFullPath(nextProps.location)) {
      this.loadCampaigns(nextProps.location.get('query').toJS())
    }
  }

  loadCampaigns(query) {
    return this.props.actions
      .loadPaginatedCampaigns(omitBy(query, isInvalid))
      .then(() => this.setState({ error: this.props.error }))
  }

  render() {
    const { t, campaigns } = this.props

    return (
      <Paginate count={this.props.total} previous next loading={this.props.loading}>
        <List striped celled sortable headers={getHeaders(t)} location={this.props.location}>
          {campaigns.toList().map((campaign) => (
            <CampaignRow key={campaign.id} campaign={campaign} />
          ))}
        </List>
        <ApiErrorMessage error={this.state.error} modal />
      </Paginate>
    )
  }
}

export const CampaignsList = withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(_CampaignsList))
