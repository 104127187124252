import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'semantic-ui-react'

export const FormModal = ({ btnTitle, headerTitle, isOpen, openModal, closeModal, children }) => (
  <div>
    {openModal && btnTitle && <Button primary onClick={openModal} icon='plus' content={btnTitle} />}
    <Modal open={isOpen} size='large' closeOnDimmerClick={false} closeIcon onClose={closeModal}>
      <Modal.Header>{headerTitle}</Modal.Header>

      <Modal.Content>
        <Modal.Description>{children}</Modal.Description>
      </Modal.Content>
    </Modal>
  </div>
)

FormModal.propTypes = {
  btnTitle: PropTypes.string,
  headerTitle: PropTypes.string,
  isOpen: PropTypes.bool,
  openModal: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

FormModal.defaultProps = {
  btnTitle: undefined,
  headerTitle: '',
  isOpen: false,
  openModal: undefined,
}
