// libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import moment from 'moment'
import { businessApi } from 'helpers/api'
import { base64ToBlob } from 'helpers/blob'
import saveAs from 'file-saver'
import { useTranslation } from 'react-i18next'
// redux
import { getCurrency } from 'redux/entities/selectors'
// components
import { Table, Button, Popup } from 'semantic-ui-react'
const { Row, Cell } = Table

const mapStateToProps = (state, props) => ({
  get: {
    currency: getCurrency(state, { id: props.campaign.currency_id }),
  },
})

const _CampaignRow = ({ campaign, get: { currency } }) => {
  const [t] = useTranslation()

  const handlePDFClick = () =>
    businessApi
      .post('/api/gifts/pdf', {
        campaign_id: campaign.id,
        lang: 40, // TODO: allow language choice
      })
      .then(({ data: { base64 } }) => saveAs(base64ToBlob(base64, 'application/pdf'), `${campaign.code}.pdf`))

  return (
    <Row>
      <Cell>{campaign.id}</Cell>
      <Cell>{campaign.title}</Cell>
      <Cell>{campaign.user_id}</Cell>
      <Cell>{campaign.category}</Cell>
      <Cell>{campaign.code}</Cell>
      <Cell>{currency.iso_3}</Cell>
      <Cell>{moment(campaign.begin_at).format('ll')}</Cell>
      <Cell>{moment(campaign.end_at).format('ll')}</Cell>
      <Cell>{campaign.active ? t('Campaigns::yes') : t('Campaigns::no')}</Cell>
      <Cell>
        <Popup
          trigger={<Button primary as={Link} to={`/campaigns/edit/${campaign.id}`} circular icon='pencil' />}
          content={t('Campaigns::Edit campaign')}
          inverted
        />
        {campaign.category === 'GIFT' && (
          <Popup
            trigger={<Button primary circular icon='file' onClick={handlePDFClick} />}
            content={t('Campaigns::Download PDF')}
            inverted
          />
        )}
      </Cell>
    </Row>
  )
}

_CampaignRow.propTypes = {
  campaign: PropTypes.object.isRequired,
  get: PropTypes.shape({
    currency: PropTypes.object.isRequired,
  }).isRequired,
}

export const CampaignRow = connect(mapStateToProps)(_CampaignRow)
