import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const organizationsActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'organizations' })

export function fetchOrganizations ({ query } = {}) {
  return organizationsActions.fetch({
    url: '/api/organizations',
    query,
    schema: { organizations: [schemas.organizations] },
  })
}

export const fetchOrganization = (id, query) =>
  organizationsActions.fetch({
    url: `/api/organizations/${id}`,
    query,
    schema: { organization: schemas.organizations },
  })

export function updateOrganization (id, payload) {
  return organizationsActions.update({
    url: `/api/organizations/${id}`,
    payload,
    schema: { organization: schemas.organizations },
  })
}

export function createOrganization (payload) {
  return organizationsActions.create({
    url: '/api/organizations',
    payload,
    schema: { organization: schemas.organizations },
  })
}

export const deleteOrganization = (id) =>
  organizationsActions.delete({
    url: `/api/organizations/${id}`,
    deleted: { organizations: [id] },
  })
