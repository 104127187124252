// Libs
import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
// Components
import { Icon, Form, Modal } from 'semantic-ui-react'
import { EventDetailModal } from '../EventDetailModal'
import { InvoiceBookingForm } from '../privateEventForm'
import { FormModal } from 'components/modals'
// Redux
import {
  getScheduleEventBookedSeats,
  getScheduleEventPendingSeats,
  getScheduleEventRequestedSeats,
  getScheduleEventWithOverrides,
  hasEventNoBookings,
  hasScheduleEventPrivateRequest,
  hasSchedulePartnerDemand,
  isEventAboveMinSeats,
  isEventBelowMinSeats,
  isEventSoldOut,
} from 'redux/entities/selectors'
import { getLocation } from 'redux/reducers/router'
import { fetchSchedules, updateBooking } from 'redux/entities/actions'

const mapStateToProps = (state, props) => ({
  fromStore: {
    bookedSeats: getScheduleEventBookedSeats(state, { date: props.date, eventId: props.event.id }),
    eventWithOverrides: getScheduleEventWithOverrides(state, { date: props.date, eventId: props.event.id }),
    hasPrivateRequest: hasScheduleEventPrivateRequest(state, { date: props.date, eventId: props.event.id }),
    hasPartnerDemand: hasSchedulePartnerDemand(state, { date: props.date, eventId: props.event.id }),
    pendingSeats: getScheduleEventPendingSeats(state, { date: props.date, eventId: props.event.id }),
    requestedSeats: getScheduleEventRequestedSeats(state, { date: props.date, eventId: props.event.id }),
    getParamsFromLocation: () => getLocation(state).get('query').toObject(),
  },
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    fetchSchedules: ({ city, start, hostname, organizationIds, end, tags, types }) =>
      dispatch(fetchSchedules({ city, start, hostname, organizationIds, end, tags, types })),
    updateBooking: (id, payload) => dispatch(updateBooking(id, payload)),
  },
})

class _Event extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    date: PropTypes.string.isRequired,
    event: PropTypes.immutable.record.isRequired,
    fromStore: PropTypes.shape({
      bookedSeats: PropTypes.number.isRequired,
      eventWithOverrides: PropTypes.immutable.record.isRequired,
      hasPrivateRequest: PropTypes.bool.isRequired,
      pendingSeats: PropTypes.number.isRequired,
      requestedSeats: PropTypes.number.isRequired,
      getParamsFromLocation: PropTypes.func.isRequired,
    }).isRequired,
    actions: PropTypes.shape({
      fetchSchedules: PropTypes.func.isRequired,
      updateBooking: PropTypes.func.isRequired,
    }).isRequired,
    showDetailModalForEvent: PropTypes.bool.isRequired,
    setShowDetailModalForEvent: PropTypes.func.isRequired,
  }

  state = {
    selectedRequest: false,
    isInvoiceModalVisible: false,
  }

  getEventCardBackgroundColor({ bookedSeats, isPrivateEvent, isPrivateRequest, maxSeats, minSeats, pendingSeats }) {
    const hasPendingSeats = pendingSeats > 0
    const hasPendingRequest = isPrivateRequest && !isPrivateEvent
    const noBookingsOnPrivateEvent = isPrivateEvent && bookedSeats === 0

    switch (true) {
      case noBookingsOnPrivateEvent:
        return '#FDC67B'
      case hasPendingSeats || hasPendingRequest:
        return '#FC9306'
      case isEventBelowMinSeats({ bookedSeats, minSeats }):
        return '#25ABF2'
      case isEventAboveMinSeats({ bookedSeats, maxSeats, minSeats }):
        return '#4588DD'
      case isEventSoldOut({ bookedSeats, maxSeats }):
        return '#8EA3A6'
      case hasEventNoBookings({ bookedSeats }):
        return '#808080'
    }
  }

  closeModal = () => {
    const { city, start, end, hostname, organizationIds, tags, types } = this.props.fromStore.getParamsFromLocation()
    if (city) {
      this.props.actions.fetchSchedules({ city, start, end, hostname, organizationIds, tags, types })
    }
    this.setState({ isInvoiceModalVisible: false })
  }

  editDiets = (e) => {
    if (e) e.stopPropagation()
    const { selectedBooking, dietsToEdit } = this.state

    this.props.actions.updateBooking(selectedBooking.id, { additional_info: dietsToEdit })
    this.setState({ isEditDietsModalVisible: false })
  }

  handleDietFormKeyDown = (e) => {
    e.stopPropagation()
    // arrow keys are intercepted by calendar causing switch of current month viewed
    // ancestors applyies preventDefault on spacebar pressure
  }

  render() {
    const { isInvoiceModalVisible, selectedRequest, isEditDietsModalVisible, dietsToEdit } = this.state
    const {
      t,
      fromStore: { bookedSeats, eventWithOverrides, hasPrivateRequest, hasPartnerDemand, pendingSeats, requestedSeats },
      date,
      setShowDetailModalForEvent,
      showDetailModalForEvent,
    } = this.props

    const isFrozen = !!eventWithOverrides.frozen_at
    const isPrivateEvent = !!eventWithOverrides.privatized_at
    const isDemoEvent = eventWithOverrides.tags.includes('demo_event')

    const background = this.getEventCardBackgroundColor({
      bookedSeats,
      isPrivateEvent,
      hasPrivateRequest,
      minSeats: eventWithOverrides.min_seats,
      maxSeats: eventWithOverrides.max_seats,
      pendingSeats,
    })
    return (
      <Fragment>
        <div
          className='CalendarEventCard'
          onClick={setShowDetailModalForEvent(eventWithOverrides.id)}
          style={{ background }}
        >
          <div className='collapsable-text'>{eventWithOverrides.title}</div>
          <div className='collapsable-text'>
            {t('EventsCalendar::by {{firstName}} {{firstName}}, at {{beginsAt}}', {
              firstName: eventWithOverrides.user.firstname,
              lastName: eventWithOverrides.user.lastname,
              beginsAt: eventWithOverrides.begins_at,
            })}
          </div>
          <div className='collapsable-text'>
            {t('EventsCalendar::{{bookedSeats}} of {{maxSeats}} booked ({{pendingSeats}} pending)', {
              bookedSeats: bookedSeats,
              maxSeats: eventWithOverrides.max_seats,
              pendingSeats: pendingSeats,
            })}
          </div>
          {requestedSeats > 0 && (
            <div>
              {t('EventsCalendar::{{requestedSeats}} of {{maxSeats}} requested', {
                requestedSeats: requestedSeats,
                maxSeats: eventWithOverrides.max_seats,
              })}
            </div>
          )}
          <div>
            {(hasPrivateRequest || isPrivateEvent) && <Icon circular inverted color='red' name='lock' size='small' />}
            {eventWithOverrides.instant_booking && (
              <Icon circular inverted color='green' name='lightning' size='small' />
            )}
            {isDemoEvent && <Icon circular inverted color='yellow' name='plane' size='small' />}
            {isFrozen && <Icon circular inverted color='purple' name='asterisk' size='small' />}
            {hasPartnerDemand && <Icon circular inverted color='orange' name='group' size='small' />}
          </div>
        </div>
        {showDetailModalForEvent === eventWithOverrides.id && (
          <EventDetailModal
            bookedSeats={bookedSeats}
            date={date}
            eventWithOverrides={eventWithOverrides}
            hasPartnerDemand={hasPartnerDemand}
            isDemoEvent={isDemoEvent}
            isOpen
            isFrozen={isFrozen}
            isPrivate={isPrivateEvent || hasPrivateRequest}
            onClose={setShowDetailModalForEvent(undefined)}
            pendingSeats={pendingSeats}
            requestedSeats={requestedSeats}
            openInvoiceModal={(request) => this.setState({ isInvoiceModalVisible: true, selectedRequest: request })}
            openEditDietsModal={(booking) =>
              this.setState({
                isEditDietsModalVisible: true,
                selectedBooking: booking,
                dietsToEdit: booking.additional_info,
              })
            }
          />
        )}
        {isInvoiceModalVisible && (
          <FormModal
            closeModal={() => this.setState({ isInvoiceModalVisible: false })}
            isOpen={isInvoiceModalVisible}
            btnTitle={t('EventsCalendar::PE Invoice')}
            headerTitle={t('EventsCalendar::Create a new private event paid by invoice')}
          >
            <InvoiceBookingForm
              handleSubmit={this.closeModal}
              eventWithOverrides={eventWithOverrides && eventWithOverrides.toJS()}
              request={selectedRequest && selectedRequest.toJS()}
              date={date}
              btnSubmitTitle={t('EventsCalendar::Create Private Event paid by invoice')}
            />
          </FormModal>
        )}
        {isEditDietsModalVisible && (
          <Modal
            open={isEditDietsModalVisible}
            onClose={() => this.setState({ isEditDietsModalVisible: false })}
            closeOnDimmerClick={false}
            closeIcon
            size='small'
          >
            <Modal.Header>{t('EventsCalendar::Edit dietary requirements')}</Modal.Header>
            <Modal.Content>
              <Form style={{ textAlign: 'center' }}>
                <Form.TextArea
                  value={dietsToEdit}
                  onChange={(e, input) => this.setState({ dietsToEdit: input.value })}
                  onKeyDown={this.handleDietFormKeyDown}
                />
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Form.Button onClick={this.editDiets}>{t('EventsCalendar::Save')}</Form.Button>
            </Modal.Actions>
          </Modal>
        )}
      </Fragment>
    )
  }
}

export const Event = withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(_Event))
