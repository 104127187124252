import settings from 'settings'
import { businessApi } from 'helpers/api'
import { statusOptions, toReviewStatusOptions } from 'helpers/events'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'
import { user as loadIntercomUser } from 'redux/actions/intercom/load'
import { getEvents, getEventError } from 'redux/entities/selectors'

export const eventsActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'events' })

export const actions = {
  EVENT_PRICING_LOADED: 'EVENT_PRICING_LOADED',
  EVENT_PRICING_LOADING: 'EVENT_PRICING_LOADING',
  EVENT_PRICING_ERROR: 'EVENT_PRICING_ERROR',
  DATES_AND_SEATS_FORM: 'DATES_AND_SEATS_FORM',

  UPDATE_PROMO_FORM: 'UPDATE_PROMO_FORM',
}

export const fetchEvents = ({ query } = {}) =>
  eventsActions.fetch({
    url: '/api/events',
    query,
    schema: { events: [schemas.events] },
  })

export const fetchReviewedEvents = ({ status, ...rest }) =>
  fetchEvents({ query: { status: status || statusOptions.map((option) => option.value).join(','), ...rest } })
export const fetchEventsToReview = ({ status, ...rest }) =>
  fetchEvents({ query: { status: status || toReviewStatusOptions.map((option) => option.value).join(','), ...rest } })

export const fetchEvent = (id, { query } = {}) =>
  eventsActions.fetch({
    url: `/api/events/${id}`,
    query,
    schema: { event: schemas.events },
  })

export const updateEvent = (id, payload, { query } = {}) =>
  eventsActions.update({
    url: `/api/events/${id}`,
    payload,
    query,
    schema: { event: schemas.events },
  })

export const updateEventOnDate = (id, date, payload) =>
  eventsActions.update({
    url: `/api/events/${id}/planning/${date}`,
    payload,
    schema: { overrides: schemas.overrides },
  })

export const duplicateEvent = (id, payload, { query } = {}) =>
  eventsActions.create({
    url: `/api/events/${id}/duplicate`,
    payload,
    query,
    schema: { event: schemas.events },
  })

export const updateEventStatus = (id, { status } = {}, { query } = {}) =>
  eventsActions.update({
    url: `/api/events/${id}/status`,
    payload: { status },
    query,
    schema: { event: schemas.events },
  })

export const approveEvent = (id) => updateEventStatus(id, { status: 'approved' })
export const saveAsDraft = (id) => updateEventStatus(id, { status: 'draft' })
export const rejectEvent = (id) => updateEventStatus(id, { status: 'rejected' })
export const requestChangeOnEvent =
  (id) =>
  (dispatch, getState, { Api }) => {
    const userId = getEvents(getState()).getIn([String(id), 'user'])
    return Promise.all([
      dispatch(updateEventStatus(id, { status: 'change_requested' })),
      dispatch(loadIntercomUser(userId)),
    ]).then(
      ([eventResponse, intercomResponse]) => {
        const error = getEventError(getState()) || !!intercomResponse.response // axios sends error.response when there's an error...
        if (!error) {
          window.open(
            `https://app.intercom.com/a/apps/${settings.intercomApiId}/users/${intercomResponse.data.intercom_user.id}/messages`,
          )
        }
        return { eventResponse, intercomResponse }
      },
      ([eventResponse, intercomResponse]) => ({ eventResponse, intercomResponse }),
    )
  }

export const freezeEvent = (id) =>
  eventsActions.update({
    url: `/api/events/${id}/freeze`,
    schema: { event: schemas.events },
  })

export const unfreezeEvent = (id) =>
  eventsActions.update({
    url: `/api/events/${id}/unfreeze`,
    schema: { event: schemas.events },
  })

export const closeAllDatesEvent = (id) =>
  eventsActions.update({
    url: `/api/events/${id}/closeAllDates`,
    schema: { event: schemas.events },
  })

export const openAllDatesEvent = (id) =>
  eventsActions.update({
    url: `/api/events/${id}/openAllDates`,
    schema: { event: schemas.events },
  })
