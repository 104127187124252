import settings from 'settings'
// Libs
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'helpers/proptypes'
import { useDispatch, useSelector } from 'react-redux'
import { omitBy } from 'lodash'
import { isInvalid } from 'helpers/forms'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { usePrevious } from 'hooks/usePrevious'
// Components
import { Paginate, List } from 'components/lists'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { FollowupsModal } from 'components/followups'
import { EventRow } from './Row'
// Utils
import { getFullPath } from 'helpers/url'

// Redux
import { fetchEventsToReview, fetchReviewedEvents, fetchAdmins } from 'redux/entities/actions'
import { getLocation } from 'redux/reducers/router'
import { fetchingEvents, getEventsError, getEventsList, getEventsTotal } from 'redux/entities/selectors'

export function _EventList({ eventsType, router }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [selectedEventId, setSelectedEventId] = useState()
  const [showFollowupModal, setShowFollowupModal] = useState(false)

  const location = useSelector(getLocation)
  const events = useSelector(getEventsList)
  const total = useSelector(getEventsTotal)
  const loading = useSelector(fetchingEvents)
  const eventsFetchingError = useSelector(getEventsError)

  const currentPath = useMemo(() => getFullPath(router.location), [router.location])
  const previousPath = usePrevious(currentPath)
  const hasPathChanged = previousPath !== currentPath

  const headers = useMemo(() => {
    const isShowingExperienceToReview = eventsType === 'review'
    return [
      { label: t('Experiences::Id'), field: 'id' },
      { label: t('Experiences::Created'), field: 'created_at' },
      { label: t('Experiences::Submitted'), field: 'submitted_at' },
      { label: t('Experiences::Experience Title'), field: 'title' },
      { label: t('Experiences::Price'), field: 'price' },
      { label: t('Experiences::Price with fees') },
      { label: t('Experiences::Host'), field: 'host' },
      { label: t('Experiences::Host payment preferences') },
      { label: t('Experiences::Type'), field: 'mealtype' },
      { label: t('Experiences::Locality'), field: 'locality' },
      { label: t('Experiences::Country'), field: 'country' },
      ...(isShowingExperienceToReview ? [] : [{ label: t('Experiences::Published at'), field: 'published_at' }]),
      { label: t('Experiences::Updated at'), field: 'updated_at' },
      { label: t('Experiences::Status'), field: 'status' },
      { label: t('Experiences::Last followup') },
      { label: t('Experiences::Assignment') },
      { label: t('Experiences::Actions::Actions') },
    ]
  }, [eventsType, t])

  const query = useMemo(
    () => ({
      offset: 0,
      size: settings.defaultPaginationSize,
      ...(eventsType === 'review' && { sortBy: 'submitted_at' }),
      ...router.location.query,
    }),
    [eventsType, router.location.query],
  )

  const loadEvents = useCallback(() => {
    if (eventsType === 'review') return dispatch(fetchEventsToReview(query))
    return dispatch(fetchReviewedEvents(omitBy(query, isInvalid)))
  }, [dispatch, eventsType, query])

  useEffect(() => {
    dispatch(fetchAdmins())
  }, [dispatch])

  useEffect(() => {
    if (hasPathChanged) {
      if (router.location.search) loadEvents()
      router.replace({ pathname: router.location.pathname, query })
    }
  }, [loadEvents, hasPathChanged, query, router])

  function onAddFollowup(eventId) {
    setSelectedEventId(eventId)
    setShowFollowupModal(true)
  }

  return (
    <div style={{ overflowX: 'auto' }}>
      {selectedEventId && (
        <FollowupsModal
          open={showFollowupModal && selectedEventId}
          onClose={() => setShowFollowupModal(false)}
          followingType='events'
          followingId={selectedEventId}
        />
      )}
      <Paginate previous next count={total} loading={loading}>
        <List celled striped sortable headers={headers} location={location}>
          {events.toList().map((event, i) => (
            <EventRow
              key={event.id}
              event={event}
              eventsType={eventsType}
              onAddFollowup={() => onAddFollowup(event.id)}
            />
          ))}
        </List>

        <ApiErrorMessage error={eventsFetchingError} modal />
      </Paginate>
    </div>
  )
}

_EventList.propTypes = {
  eventsType: PropTypes.string,
  router: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      query: PropTypes.shape({}),
    }),
    replace: PropTypes.func,
  }).isRequired,
}

_EventList.defaultProps = {
  eventsType: undefined,
}

export const EventList = withRouter(_EventList)
