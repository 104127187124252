import { getEntities } from './_utils'

const getInvites = getEntities('invites')

export const getInvite = (state, { date, eventId, requestId }) => {
  const invites = getInvites(state)
  return invites.find(
    (invite) =>
      invite.date === date && invite.event_id === eventId && (requestId ? invite.request_id === requestId : true)
  )
}
