// Libs
import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { withTranslation } from 'react-i18next'

import { connect } from 'react-redux'
// Components
import { Form } from 'semantic-ui-react'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
// Redux
import { logInWithEmailAndPassword } from 'redux/actions/login'
import { getAuthToken, getLoading, getError } from 'redux/reducers/admin'
import { getLocation } from 'redux/reducers/router'

const mapStateToProps = (state) => ({
  kitchenToken: getAuthToken(state),
  error: getError(state),
  loading: getLoading(state),
  location: getLocation(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    logIn: ({ email, password }) => dispatch(logInWithEmailAndPassword(email, password)),
  },
})

class _LoginForm extends PureComponent {
  static contextTypes = { router: PropTypes.object }
  static propTypes = {
    t: PropTypes.func.isRequired,
    kitchenToken: PropTypes.string,
    error: PropTypes.shape({
      data: PropTypes.shape({
        message: PropTypes.string,
        stack_trace: PropTypes.any,
      }),
      status: PropTypes.number,
      statusText: PropTypes.string,
    }),
    loading: PropTypes.bool,
    location: PropTypes.immutable.map,
    className: PropTypes.string,
    actions: PropTypes.shape({
      logIn: PropTypes.func,
    }).isRequired,
  }

  static defaultProps = {
    kitchenToken: undefined,
    error: undefined,
    loading: false,
    location: undefined,
    className: undefined,
  }

  UNSAFE_componentWillMount() {
    this.setState({
      email: '',
      password: '',
      loading: false,
    })
  }

  redirectAfterLogIn() {
    if (this.props.error) return this.setState({ error: this.props.error })
    if (!this.props.kitchenToken) return

    const nextLocation = this.props.location.getIn(['query', 'redirect'], '/')
    return this.context.router.push(nextLocation)
  }

  handleSubmit(e) {
    e.preventDefault()
    const { email, password } = this.state

    this.props.actions.logIn({ email, password }).then(() => this.redirectAfterLogIn())
  }

  render() {
    const { t } = this.props
    return (
      <Form onSubmit={this.handleSubmit.bind(this)} className={this.props.className} error={!!this.state.error}>
        <h3 className='login-form-title'>{t('Account::Login')}</h3>

        <Form.Input
          icon='mail'
          iconPosition='left'
          placeholder={t('Account::Email')}
          name='email'
          value={this.state.email}
          onChange={({ target: { value } }) => this.setState({ email: value })}
        />
        <Form.Input
          type='password'
          icon='spy' // or 'lock', or 'privacy'
          iconPosition='left'
          placeholder={t('Account::Password')}
          name='password'
          value={this.state.password}
          onChange={({ target: { value } }) => this.setState({ password: value })}
        />

        <Form.Button positive loading={this.props.loading}>
          {t('Account::OK')}
        </Form.Button>

        <ApiErrorMessage error={this.state.error} noStack />
      </Form>
    )
  }
}

export const LoginForm = withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(_LoginForm))
