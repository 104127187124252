import React, { useEffect, useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Divider, Form, Modal } from 'semantic-ui-react'
import { useFormik } from 'formik'
import { useHasLoadingSucceeded } from 'hooks'
import { PricingSection } from 'pages/events-calendar/privateEventForm/components/PricingSection'
import { updateBooking } from 'redux/entities/actions'
import { updatingBooking, getBookingError, getScheduleEventWithOverrides } from 'redux/entities/selectors'
import { toast } from 'react-toastify'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'

EditInvoiceBookingModal.propTypes = {
  booking: PropTypes.immutable.record.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export function EditInvoiceBookingModal({ booking, handleClose }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const eventWithOverrides = useSelector((state) =>
    getScheduleEventWithOverrides(state, {
      date: moment.utc(booking.date).format('YYYY-MM-DD'),
      eventId: booking.event.id,
    }),
  )
  const isUpdatingBooking = useSelector((state) => updatingBooking(state, booking.id))
  const bookingError = useSelector((state) => getBookingError(state, booking.id))
  const isUpdatingSucceded = useHasLoadingSucceeded(isUpdatingBooking, bookingError)
  const initialValues = useMemo(() => {
    const isFixedPrice = booking.getIn(['payment', 'is_fixed_price']) || false
    return {
      date: booking.date,
      begins_at: eventWithOverrides.begins_at || booking.event.begins_at,
      ends_at: eventWithOverrides.ends_at || booking.event.ends_at,
      seats: booking.seats,
      ew_free_seats: booking.ew_free_seats,
      host_free_seats: booking.host_free_seats,
      is_fixed_price: isFixedPrice,
      price: booking.getIn(['payment', 'booking_price']),
      guest_price: isFixedPrice
        ? Math.round(booking.getIn(['payment', 'total_paid']) / booking.getIn(['payment', 'seats']))
        : undefined,
    }
  }, [booking, eventWithOverrides.begins_at, eventWithOverrides.ends_at])

  useEffect(() => {
    if (isUpdatingSucceded) {
      toast.success(`${t('ToastNotification::The booking has been successfully updated')} 👍`, {
        type: toast.TYPE.SUCCESS,
      })
      handleClose()
    }
  }, [isUpdatingSucceded, t, handleClose])

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      dispatch(updateBooking(booking.id, values))
    },
  })

  function handlePricingChange({ isFixedPrice, price, guestPrice }) {
    formik.setFieldValue('is_fixed_price', isFixedPrice)
    formik.setFieldValue('price', price)
    formik.setFieldValue('guest_price', isFixedPrice ? guestPrice : undefined)
  }

  return (
    <Modal open size='large' closeOnDimmerClick={false} closeIcon onClose={handleClose}>
      <Modal.Header>{t('Bookings::EditBookingModal::Edit booking')}</Modal.Header>

      <Modal.Content>
        <Modal.Description>
          <div style={{ marginBottom: '24px' }}>
            <b>{t('Bookings::EditBookingModal::Host')}</b>
            {`: ${booking.event.user.id} ${booking.event.user.firstname} ${booking.event.user.lastname}`}
          </div>

          <div style={{ marginBottom: '24px' }}>
            <b>{t('Bookings::EditBookingModal::Event')}</b>
            {`: ${booking.event.id} ${booking.event.title}`}
          </div>

          <div style={{ marginBottom: '24px' }}>
            <b>{t('Bookings::EditBookingModal::Guest')}</b>
            {`: ${booking.user.id} ${booking.user.firstname} ${booking.user.lastname}`}
          </div>

          <Divider style={{ margin: '32px 0px' }} />

          <Form onSubmit={formik.handleSubmit} autoComplete='off'>
            <Form.Group widths='equal'>
              <Form.Input
                id='date'
                label={t('Bookings::EditBookingModal::Date')}
                type='date'
                value={formik.values.date}
                onChange={formik.handleChange}
              />

              <Form.Input
                id='begins_at'
                label={t('Bookings::EditBookingModal::Begins at')}
                type='time'
                value={formik.values.begins_at}
                onChange={formik.handleChange}
              />

              <Form.Input
                id='ends_at'
                label={t('Bookings::EditBookingModal::Ends at')}
                type='time'
                value={formik.values.ends_at}
                onChange={formik.handleChange}
              />
            </Form.Group>

            <Divider style={{ margin: '32px 0px' }} />

            <Form.Group widths='equal'>
              <Form.Input
                id='seats'
                label={t('Forms::SeatsForm::Seats')}
                type='number'
                min={0}
                value={formik.values.seats}
                onChange={formik.handleChange}
              />

              <Form.Input
                id='ew_free_seats'
                label={t('Forms::SeatsForm::EW free seats')}
                type='number'
                min={0}
                value={formik.values.ew_free_seats}
                onChange={formik.handleChange}
              />

              <Form.Input
                id='host_free_seats'
                label={t('Forms::SeatsForm::Host free seats')}
                type='number'
                min={0}
                value={formik.values.host_free_seats}
                onChange={formik.handleChange}
              />
            </Form.Group>

            <PricingSection
              handleChange={handlePricingChange}
              allowGuestPriceEdition
              currency={booking.getIn(['payment', 'user_currency']).toJS()}
              date={formik.values.date}
              event={booking.event}
              isPriceDisabled={false}
              isCurrencyDisabled
              isFixedPrice={formik.values.is_fixed_price}
              price={formik.values.price}
              initialGuestPrice={formik.values.guest_price}
              seats={formik.values.seats - formik.values.host_free_seats}
              user={booking.user}
            />

            <ApiErrorMessage error={bookingError} noStack={false} modal />

            <Form.Button primary type='submit' disabled={formik.values.readOnly} style={{ marginTop: '24px' }}>
              {t('Forms::SeatsForm::Submit')}
            </Form.Button>
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}
