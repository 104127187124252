import { schema } from 'normalizr'
import { Record, List } from 'immutable'
import { users } from './users'
import { bookings } from './bookings'
import { requests } from './requests'
import { replies } from './replies'

const _schema = new schema.Entity('conversations', {
  bookings: [bookings.schema],
  request: requests.schema,
  recipient: users.schema,
  sender: users.schema,
  recipient_first_reply: replies.schema,
  sender_first_reply: replies.schema,
  replies: [replies.schema],
})

const _Record = Record({
  id: undefined,
  sender_id: undefined,
  sender: new users.Record(),
  recipient_id: undefined,
  recipient: new users.Record(),
  bookings: [new bookings.Record()],
  request: new requests.Record(),
  followups: new List(),
  recipient_first_reply: new replies.Record(),
  sender_first_reply: new replies.Record(),
  replies: [new replies.Record()],
  created_at: undefined,
})

export const conversations = {
  schema: _schema,
  Record: _Record,
}
