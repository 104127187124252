// Libraries
import React, { Fragment, useState } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
// Components
import { Loader, Dimmer, Modal, Button, Popup } from 'semantic-ui-react'
import { FormattedReplies } from './FormattedReplies'
// Redux
import { fetchConversation, fetchUser } from 'redux/entities/actions'
import { getConversation, fetchingConversation, getUser } from 'redux/entities/selectors'

const mapStateToProps = (state, props) => ({
  fromStore: {
    conversation: getConversation(state, { id: props.conversationId }),
    isConversationLoading: fetchingConversation(state, props.conversationId),
    sender: getUser(state, { id: props.senderId }),
    recipient: getUser(state, { id: props.recipientId }),
  },
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    loadConversation: (id) => dispatch(fetchConversation(id)),
    loadUser: (id) => dispatch(fetchUser(id)),
  },
})

const _ConversationModal = ({
  fromStore: { conversation, sender, recipient, isConversationLoading },
  actions: { loadConversation, loadUser },
  senderId,
  recipientId,
  conversationId,
}) => {
  const [t] = useTranslation()
  const [showModal, setShowModal] = useState(false)

  const handleConversationLoading = () => {
    loadConversation(conversationId)
    loadUser(senderId)
    loadUser(recipientId)
    setShowModal(true)
  }

  return (
    <Fragment>
      <Modal open={showModal} size='large' onClose={() => setShowModal(false)}>
        <Modal.Header>{t('ConversationModal::Conversation n° {{conversationId}}', { conversationId })}</Modal.Header>

        <Modal.Content>
          {isConversationLoading ? (
            <Dimmer active inverted>
              <Loader inverted content={t('Loading::Loading')} />
            </Dimmer>
          ) : (
            <FormattedReplies replies={conversation.replies} sender={sender} recipient={recipient} />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setShowModal(false)}>{t('ConversationModal::Exit')}</Button>
        </Modal.Actions>
      </Modal>

      <Popup
        trigger={<Button circular primary icon='conversation' onClick={handleConversationLoading} />}
        content={t('ConversationModal::Show conversation')}
        inverted
      />
    </Fragment>
  )
}

_ConversationModal.propTypes = {
  conversationId: PropTypes.number.isRequired,
  senderId: PropTypes.number.isRequired,
  recipientId: PropTypes.number.isRequired,
  fromStore: PropTypes.shape({
    conversation: PropTypes.immutable.record,
    sender: PropTypes.immutable.record,
    recipient: PropTypes.immutable.record,
    isConversationLoading: PropTypes.bool,
  }).isRequired,
  actions: PropTypes.shape({
    loadConversation: PropTypes.func,
    loadUser: PropTypes.func,
  }).isRequired,
}

export const ConversationModal = connect(mapStateToProps, mapDispatchToProps)(_ConversationModal)
