import React, { Fragment } from 'react'
import { Header, Segment, Grid, List } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { ExperienceIdWithCheckMark } from 'components/labels'
import { useTranslation, Trans } from 'react-i18next'

export const QuickViewSection = ({
  hostApplication: { host, event, sample_experience: sampleExperience, metadata },
}) => {
  const [t] = useTranslation()
  return (
    <Segment>
      <Header as='h3'>
        {t('HostApplications::Summary::Quick view')}
        {metadata.get('event_id') && (
          <div style={{ float: 'right' }}>
            <Trans
              i18nKey={__('HostApplications::Summary::Experience ID: <experience/>')}
              components={{
                experience: <ExperienceIdWithCheckMark event={event || { id: metadata.get('event_id') }} />,
              }}
            />
          </div>
        )}
      </Header>

      <Segment basic>
        <Grid columns={2}>
          <Grid.Column>
            {host.get('culinary_experience') && (
              <Fragment>
                <Header size='small'>{t('HostApplications::Summary::Culinary experience')}</Header>
                <List bulleted>
                  {host.get('culinary_experience').map((experience) => (
                    <List.Item key={experience}>{`${experience.get('experience_type')} : ${experience.get(
                      'level',
                    )}`}</List.Item>
                  ))}
                </List>
              </Fragment>
            )}

            {sampleExperience.get('min_price') && sampleExperience.get('max_price') && (
              <Fragment>
                <Header size='small'>{t('HostApplications::Summary::Price range')}</Header>
                <Trans
                  i18nKey={__('HostApplications::Summary::From {{minPrice}} to {{maxPrice}} {{currency}}')}
                  values={{
                    minPrice: sampleExperience.get('min_price'),
                    maxPrice: sampleExperience.get('max_price'),
                    currency: sampleExperience.get('currency_iso3'),
                  }}
                />
              </Fragment>
            )}

            <Header size='small'>{t('HostApplications::Summary::How often')}</Header>
            {host.get('availabilities').get('frequency')}
          </Grid.Column>
          <Grid.Column>
            <Header size='small'>{t('HostApplications::Summary::Experience for')}</Header>
            <Trans
              i18nKey={__('HostApplications::Summary::From {{minSeatsNumber}} to {{maxSeatsNumber}} seats')}
              values={{
                minSeatsNumber: sampleExperience.get('min_seats') || '',
                maxSeatsNumber: sampleExperience.get('max_seats') || '',
              }}
            />
            <br />
            {sampleExperience.get('guest_type') && (
              <List bulleted>
                {sampleExperience.get('guest_type').map((guestType) => (
                  <List.Item key={guestType}>{guestType}</List.Item>
                ))}
              </List>
            )}

            {sampleExperience.get('place') && sampleExperience.get('place').get('WOW_factor') && (
              <Fragment>
                <Header size='small'>{t('HostApplications::Summary::WOW factor')}</Header>
                <List bulleted>
                  {sampleExperience
                    .get('place')
                    .get('WOW_factor')
                    .map((wowFactor) => (
                      <List.Item key={wowFactor}>{wowFactor}</List.Item>
                    ))}
                </List>
              </Fragment>
            )}
          </Grid.Column>
        </Grid>
      </Segment>
    </Segment>
  )
}

QuickViewSection.propTypes = {
  hostApplication: PropTypes.shape({
    sample_experience: PropTypes.object,
    host: PropTypes.object,
    event: PropTypes.object,
    metadata: PropTypes.object,
  }).isRequired,
}
