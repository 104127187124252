// Libraries
import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
// Components
import { Form, Button, Modal } from 'semantic-ui-react'
import { SearchInput, SelectInput } from 'components/inputs'
// Selectors
import { getLocation } from 'redux/reducers/router'
// Helpers
import { getTranslatedOptions } from 'helpers/options'
import { aggregatedStatusOptions } from 'helpers/bookings'
import { omit } from 'lodash'

const mapStateToProps = (state) => ({ location: getLocation(state) })

const getPrivatizedOptions = (t) => [
  { text: t('Conversations::All'), value: '' },
  { text: t('Conversations::Privatized'), value: 'true' },
  { text: t('Conversations::Public'), value: 'false' },
]

class _ConversationSearchForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.immutable.map.isRequired,
  }

  static contextTypes = { router: PropTypes.object }

  UNSAFE_componentWillMount() {
    return this.updateStateFromLocation(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.location.equals(this.props.location)) return this.updateStateFromLocation(nextProps)
  }

  updateStateFromLocation = (props) => {
    return this.setState({
      search: props.location.getIn(['query', 'search'], ''),
      sender: props.location.getIn(['query', 'sender'], ''),
      recipient: props.location.getIn(['query', 'recipient'], ''),
      status: props.location.getIn(['query', 'status'], ''),
      location: props.location.getIn(['query', 'location'], ''),
      privatized: props.location.getIn(['query', 'privatized', '']),
    })
  }

  updateQuery = () => {
    const query = omit(this.state, 'isBookingStatusModalVisible')
    return this.context.router.push(this.props.location.mergeIn(['query'], { ...query, offset: 0 }).toJS())
  }

  clearSorting = () => {
    return this.context.router.push(this.props.location.mergeIn(['query'], { sortBy: '', order: '' }).toJS())
  }

  clearFilters = () => {
    return this.setState({ search: '', sender: '', recipient: '', status: '', location: '', privatized: '' }, () =>
      this.updateQuery(),
    )
  }

  handleSubmit = (e) => {
    e.preventDefault()
    return this.updateQuery()
  }

  handleBookingStatusModalVisibility = () => {
    return this.setState({
      isBookingStatusModalVisible: !this.state.isBookingStatusModalVisible,
    })
  }

  render() {
    const { t } = this.props

    return (
      <React.Fragment>
        <Form onSubmit={this.handleSubmit} autoComplete='off'>
          <Form.Group widths='equal'>
            <Form.Field>
              <label>{t('Conversations::Search by conversation')}</label>
              <SearchInput
                queryName='search'
                icon='search'
                fluid
                iconPosition='left'
                placeholder={t('Conversations::Id, booking id, title or created at')}
                value={this.state.search}
                onChange={(value) => this.setState({ search: value })}
              />
            </Form.Field>

            <Form.Field>
              <label>{t('Conversations::Search by sende')}r</label>
              <SearchInput
                queryName='sender'
                placeholder={t('Conversations::id, first/last name, email, phone')}
                icon='search'
                fluid
                iconPosition='left'
                value={this.state.sender}
                onChange={(value) => this.setState({ sender: value })}
              />
            </Form.Field>

            <Form.Field>
              <label>{t('Conversations::Search by receiver')}</label>
              <SearchInput
                queryName='recipient'
                placeholder={t('Conversations::id, first/last name, email, phone')}
                icon='search'
                fluid
                iconPosition='left'
                value={this.state.recipient}
                onChange={(value) => this.setState({ recipient: value })}
              />
            </Form.Field>

            <Form.Field>
              <label>{t('Conversations::Search by location')}</label>
              <SearchInput
                queryName='location'
                placeholder={t('Conversations::Address, city, country (iso), postal code')}
                icon='search'
                fluid
                iconPosition='left'
                value={this.state.location}
                onChange={(value) => this.setState({ location: value })}
              />
            </Form.Field>

            <Form.Field>
              <div>
                <label style={{ fontWeight: '700' }}>{t('Conversations::Filter by booking status')}</label>
                <Button
                  type='button'
                  basic
                  color='black'
                  icon='info'
                  circular
                  size='mini'
                  onClick={this.handleBookingStatusModalVisibility}
                  style={{ marginLeft: '4px', marginBottom: '4px', padding: '4px' }}
                />
              </div>
              <SelectInput
                queryName='status'
                fluid
                multiple
                options={getTranslatedOptions(t, aggregatedStatusOptions)}
                value={this.state.status}
              />
            </Form.Field>

            <Form.Field>
              <label>{t('Conversations::Privatized')}</label>
              <SelectInput
                queryName='privatized'
                fluid
                options={getPrivatizedOptions(t)}
                value={this.state.privatized}
              />
            </Form.Field>

            <Form.Field>
              <label>&nbsp;</label>
              <Button type='submit'>{t('Conversations::Conversations::Search')}</Button>
              <Button.Group vertical basic compact size='mini' style={{ transform: 'translateY(-15px)' }}>
                <Button onClick={() => this.clearFilters()}>{t('Conversations::Clear filters')}</Button>
                <Button onClick={() => this.clearSorting()}>{t('Conversations::Clear sorting')}</Button>
              </Button.Group>
            </Form.Field>
          </Form.Group>
        </Form>
        <Modal onClose={this.handleBookingStatusModalVisibility} open={this.state.isBookingStatusModalVisible}>
          <Modal.Header>{t('Conversations::Booking status list')}</Modal.Header>
          <Modal.Content>
            {aggregatedStatusOptions.map(({ text, value, aggregation }) => (
              <div key={value}>
                <h3>{t('Conversations::{{status}} booking status', { status: t(text) })}</h3>
                <ul>
                  {aggregation.map((status) => (
                    <li key={status}>{t(status)}</li>
                  ))}
                </ul>
              </div>
            ))}
          </Modal.Content>
        </Modal>
      </React.Fragment>
    )
  }
}

export const ConversationSearchForm = withTranslation('common')(connect(mapStateToProps)(_ConversationSearchForm))
