// libs
import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { isEmpty } from 'lodash'
import { withTranslation, Trans } from 'react-i18next'
// helpers
import { getTranslatedOptions } from 'helpers/options'
import { userCivilityOptions } from 'helpers/users'
// components
import { Form, Button, Message, Divider, Segment } from 'semantic-ui-react'

class _PartnerForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    showPasswordInput: PropTypes.bool,
    civility: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    billingInfos: PropTypes.string.isRequired,
    organizations: PropTypes.immutable.map.isRequired,
    languages: PropTypes.immutable.map.isRequired,
    currencies: PropTypes.immutable.map.isRequired,
    organization: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    newsletter: PropTypes.bool.isRequired,
    tos: PropTypes.bool.isRequired,
    desiredOrganization: PropTypes.string,
    handleAddOrganization: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    desiredOrganization: '',
    showPasswordInput: true,
  }

  formatOptions = (items, attribute) =>
    !isEmpty(items)
      ? items
          .toList()
          .map((item) => ({ key: item[attribute], text: item[attribute], value: item[attribute] }))
          .toArray()
      : []

  state = { isModalOpen: false }

  render() {
    const {
      t,
      firstname,
      lastname,
      email,
      password,
      desiredOrganization,
      showPasswordInput,
      civility,
      description,
      billingInfos,
      organizations,
      languages,
      currencies,
      organization,
      language,
      currency,
      phone,
      newsletter,
      tos,
      handleAddOrganization,
      handleChange,
      handleSubmit,
    } = this.props
    return (
      <Fragment>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Form.Group widths='equal'>
            <Form.Input
              id='firstname'
              label={t('Partners::Summary::Firstname')}
              required
              value={firstname}
              onChange={(e, { value }) =>
                handleChange({ firstname: value, billingInfos: `${value} ${lastname} - ${email}` })
              }
            />

            <Form.Input
              id='lastname'
              label={t('Partners::Summary::Lastname')}
              required
              value={lastname}
              onChange={(e, { value }) =>
                handleChange({ lastname: value, billingInfos: `${firstname} ${value} - ${email}` })
              }
            />
          </Form.Group>

          <Form.Input
            id='email'
            label={t('Partners::Summary::Email')}
            type='email'
            required
            value={email}
            onChange={(e, { value }) =>
              handleChange({ email: value, billingInfos: `${firstname} ${lastname} - ${value}` })
            }
          />

          <Form.Select
            id='civility'
            label={t('Partners::Summary::Civility')}
            required
            value={civility}
            onChange={(e, { value }) => handleChange({ civility: value || null })}
            options={getTranslatedOptions(t, userCivilityOptions)}
          />

          {showPasswordInput && (
            <Form.Input
              id='password'
              label={t('Partners::Summary::Password')}
              type='password'
              autoComplete='off'
              value={password}
              onChange={(e, { value }) => handleChange({ password: value })}
            />
          )}

          {desiredOrganization && (
            <Fragment>
              <Divider />
              <Message info>
                <Trans
                  i18nKey={__('Partners::Summary::Desired organization: <b>{{organization}}</b>')}
                  values={{ organization: desiredOrganization }}
                  components={{ b: <b /> }}
                />
              </Message>
            </Fragment>
          )}

          <Form.Group>
            <Form.Select
              id='organization'
              width={13}
              label={t('Partners::Summary::Organization')}
              required
              selection
              search
              value={organization}
              onChange={(e, { value }) => handleChange({ organization: value, description: value })}
              options={this.formatOptions(organizations, 'name')}
              fluid
            />

            <Form.Button
              basic
              color='blue'
              type='button'
              onClick={handleAddOrganization}
              style={{ marginTop: 1.65 + 'em' }}
            >
              {t('Partners::Summary::Add')}
            </Form.Button>
          </Form.Group>

          {desiredOrganization && <Divider />}

          <Form.TextArea
            id='description'
            label={t('Partners::Summary::Description')}
            value={description}
            onChange={(e, { value }) => handleChange({ description: value })}
          />

          <Form.TextArea
            id='billing_infos'
            label={t('Partners::Summary::Billing informations')}
            value={billingInfos}
            onChange={(e, { value }) => handleChange({ billingInfos: value })}
          />

          <Form.Select
            id='language'
            label={t("Partners::Summary::Partner's account language")}
            value={language}
            required
            onChange={(e, { value }) => handleChange({ language: value })}
            search
            options={this.formatOptions(languages, 'title')}
          />

          <Form.Select
            id='currency'
            label={t('Partners::Summary::Currency')}
            value={currency}
            required
            onChange={(e, { value }) => handleChange({ currency: value })}
            search
            options={this.formatOptions(currencies, 'iso_3')}
          />

          <Form.Input
            id='phone'
            label={t('Partners::Summary::Phone')}
            value={phone}
            required
            onChange={(e, { value }) => handleChange({ phone: value })}
          />

          <Form.Checkbox
            id='newsletter'
            label={t("Partners::Summary::Partner's subscription to VizEat newsletter")}
            onChange={(e, { checked }) => handleChange({ newsletter: checked })}
            checked={newsletter}
          />

          <Form.Checkbox
            id='tos'
            label={t("Partners::Summary::Whether or not the partner's has accepted VizEat terms of services")}
            onChange={(e, { checked }) => handleChange({ tos: checked })}
            checked={tos}
          />

          <Segment basic textAlign='center'>
            <Button primary type='submit' size='big'>
              {t('Partners::Summary::Submit')}
            </Button>
          </Segment>
        </Form>
      </Fragment>
    )
  }
}

export const PartnerForm = withTranslation('common')(_PartnerForm)
