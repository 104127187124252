// Libraries
import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
// Components
import { Form, Button } from 'semantic-ui-react'
import { InputExpandingOnFocus } from 'layouts'
import { SearchInput, SelectInput, SelectCurrency } from 'components/inputs'
// Selectors
import { getLocation } from 'redux/reducers/router'
import { getTranslatedOptions } from 'helpers/options'
import { statusOptions, seasonsOptions, basicOptions, defaultSearchStatus } from 'helpers/hostApplications'
import { getFormattedAdmins } from 'helpers/admins'
import { getAdmins } from 'redux/entities/selectors'
import { fetchAdmins } from 'redux/entities/actions'

const mapStateToProps = (state) => ({
  fromStore: {
    location: getLocation(state),
    admins: getAdmins(state),
  },
})

const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    fetchAdmins: (query) => dispatch(fetchAdmins({ query })),
  },
})

class _HostApplicationSearchForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      id: '',
      status: defaultSearchStatus,
      location: '',
      seasons: '',
      assignment: '',
      minSeats: '',
      maxSeats: '',
      minPrice: '',
      maxPrice: '',
      hasVideo: '',
      hasMediaLinks: '',
    }
  }

  static contextTypes = { router: PropTypes.object }

  static propTypes = {
    t: PropTypes.func.isRequired,
    fromStore: PropTypes.shape({
      location: PropTypes.immutable.map.isRequired,
      admins: PropTypes.object,
    }).isRequired,
    actions: PropTypes.shape({
      fetchAdmins: PropTypes.func,
    }).isRequired,
  }

  static contextTypes = { router: PropTypes.object }

  componentDidMount() {
    this.updateStateFromLocation(this.props)
    this.props.actions.fetchAdmins()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.fromStore.location.equals(this.props.fromStore.location)) {
      return this.updateStateFromLocation(this.props)
    }
  }

  updateStateFromLocation(props) {
    return this.setState({
      id: props.fromStore.location.getIn(['query', 'id'], ''),
      user: props.fromStore.location.getIn(['query', 'user'], ''),
      status: props.fromStore.location.getIn(['query', 'status'], defaultSearchStatus),
      location: props.fromStore.location.getIn(['query', 'location'], ''),
      seasons: props.fromStore.location.getIn(['query', 'seasons'], ''),
      assignment: props.fromStore.location.getIn(['query', 'assignment'], ''),
      minSeats: props.fromStore.location.getIn(['query', 'minSeats'], ''),
      maxSeats: props.fromStore.location.getIn(['query', 'maxSeats'], ''),
      minPrice: props.fromStore.location.getIn(['query', 'minPrice'], ''),
      maxPrice: props.fromStore.location.getIn(['query', 'maxPrice'], ''),
      hasVideo: props.fromStore.location.getIn(['query', 'hasVideo'], ''),
      hasMediaLinks: props.fromStore.location.getIn(['query', 'hasMediaLinks'], ''),
    })
  }

  updateQuery(query = this.state) {
    return this.context.router.push(this.props.fromStore.location.mergeIn(['query'], { ...query, offset: 0 }).toJS())
  }

  clearFilters() {
    return this.setState(
      {
        id: '',
        status: '',
        location: '',
        assignment: '',
        seasons: '',
        minSeats: '',
        maxSeats: '',
        minPrice: '',
        maxPrice: '',
        hasVideo: '',
        hasMediaLinks: '',
      },
      () => this.updateQuery(),
    )
  }

  handleSubmit(e) {
    e.preventDefault()
    return this.updateQuery()
  }

  render() {
    const { t, fromStore } = this.props
    return (
      <Form onSubmit={this.handleSubmit.bind(this)} style={{ marginBottom: '1em' }} autoComplete='off'>
        <Form.Group>
          <InputExpandingOnFocus
            label={t('HostApplications::Id')}
            Component={SearchInput}
            queryName='id'
            placeholder={t('HostApplications::id')}
            icon='search'
            iconPosition='left'
            value={this.state.id}
            onChange={(value) => this.setState({ id: value })}
          />

          <InputExpandingOnFocus
            label={t('HostApplications::User')}
            Component={SearchInput}
            queryName='user'
            placeholder={t('HostApplications::id, first/last name, email, phone')}
            icon='search'
            iconPosition='left'
            value={this.state.user}
            onChange={(value) => this.setState({ user: value })}
          />

          <InputExpandingOnFocus
            label={t('HostApplications::Location')}
            Component={SearchInput}
            queryName='location'
            placeholder={t('HostApplications::City, country (iso), postal code')}
            icon='search'
            iconPosition='left'
            value={this.state.location}
            onChange={(value) => this.setState({ location: value })}
          />

          <InputExpandingOnFocus
            label={t('HostApplications::Min guests')}
            blurWidth={80}
            Component={SearchInput}
            queryName='minSeats'
            placeholder={t('HostApplications::Min guests')}
            type='number'
            fluid
            value={this.state.minSeats}
            onChange={(value) => this.setState({ minSeats: value })}
          />

          <InputExpandingOnFocus
            label={t('HostApplications::Max guests')}
            blurWidth={80}
            Component={SearchInput}
            type='number'
            queryName='maxSeats'
            placeholder={t('HostApplications::Max guests')}
            fluid
            value={this.state.maxSeats}
            onChange={(value) => this.setState({ maxSeats: value })}
          />

          <Form.Field>
            <label>{t('HostApplications::Currency')}</label>
            <SelectCurrency
              currencyId={this.state.currencyId}
              handleCurrencyChange={(value) => this.setState({ currencyId: value })}
            />
          </Form.Field>

          <InputExpandingOnFocus
            label={t('HostApplications::Min price')}
            blurWidth={80}
            Component={SearchInput}
            queryName='minPrice'
            placeholder={t('HostApplications::Min price')}
            type='number'
            fluid
            value={this.state.minPrice}
            onChange={(value) => this.setState({ minPrice: value })}
          />

          <InputExpandingOnFocus
            label={t('HostApplications::Max price')}
            blurWidth={80}
            Component={SearchInput}
            type='number'
            queryName='maxPrice'
            placeholder={t('HostApplications::Max price')}
            fluid
            value={this.state.maxPrice}
            onChange={(value) => this.setState({ maxPrice: value })}
          />

          <Form.Field>
            <label>{t('HostApplications::Assignment')}</label>
            <SelectInput
              queryName='assignment'
              fluid
              search
              options={getFormattedAdmins(fromStore.admins)}
              value={this.state.assignment}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field>
            <label>{t('HostApplications::Has video')}?</label>
            <SelectInput
              queryName='hasVideo'
              fluid
              search
              options={getTranslatedOptions(t, basicOptions)}
              value={this.state.hasVideo}
            />
          </Form.Field>

          <Form.Field>
            <label>{t('HostApplications::Has social media links')}?</label>
            <SelectInput
              queryName='hasMediaLinks'
              fluid
              search
              options={getTranslatedOptions(t, basicOptions)}
              value={this.state.hasMediaLinks}
            />
          </Form.Field>

          <Form.Field>
            <label>{t('HostApplications::Availability seasons')}</label>
            <SelectInput
              queryName='seasons'
              fluid
              multiple
              search
              options={getTranslatedOptions(t, seasonsOptions)}
              value={this.state.seasons}
            />
          </Form.Field>

          <Form.Field>
            <label>{t('HostApplications::Application status')}</label>
            <SelectInput
              queryName='status'
              fluid
              multiple
              search
              options={getTranslatedOptions(t, statusOptions)}
              value={this.state.status}
            />
          </Form.Field>
          <Form.Field>
            <label>&nbsp;</label>
            <Button type='submit'>{t('HostApplications::Search')}</Button>

            <Button basic onClick={() => this.clearFilters()}>
              {t('HostApplications::Clear filters')}
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    )
  }
}

export const HostApplicationSearchForm = withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(_HostApplicationSearchForm),
)
