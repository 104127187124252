import { schema } from 'normalizr'
import { Record, Map, List } from 'immutable'
import { events } from './events'
import { stringifyGeometryCoordinate } from 'helpers/places'

const _schema = new schema.Entity(
  'searches',
  {
    events: [events.schema],
  },
  {
    idAttribute: (entity) => stringifyGeometryCoordinate(entity.google_place.coordinates.geometry.viewport),
  },
)

const _Record = Record({
  events: new List(),
  algoliaGeoQuery: Map({
    aroundLatLng: undefined,
    aroundRadius: undefined,
    aroundPrecision: undefined,
  }),
})

export const searches = {
  schema: _schema,
  Record: _Record,
}
