// Libraries
import React from 'react'
import { useTranslation } from 'react-i18next'
// Components
import { Message } from 'semantic-ui-react'
// Style
import './common.css'

const NotFound = () => {
  const [t] = useTranslation()
  return (
    <div className='common-error'>
      <Message error>
        <Message.Header>{t('Error::Not Found')}</Message.Header>
        <Message.Content>{t("Error::There's nothing here...")}</Message.Content>
      </Message>
    </div>
  )
}
export default NotFound
