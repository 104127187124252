// Libraries
import React, { PureComponent } from 'react'
// Components
import SearchForm from './SearchForm'
import PlaylistList from './List'

export default class UserIndex extends PureComponent {
  render() {
    return (
      <div>
        <SearchForm />
        <PlaylistList />
      </div>
    )
  }
}
