import React, { Fragment, useEffect } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getUser, getLanguage } from 'redux/entities/selectors'
import { fetchUser } from 'redux/entities/actions'
import { Header, List, Icon } from 'semantic-ui-react'
import { UserCard } from 'components/cards/UserCard'

const SocialLink = ({ url, icon }) => {
  if (!url) return null
  return (
    <List.Item>
      <Icon name={icon} />
      <List.Content verticalAlign='middle'>
        <a
          href={url}
          style={{ display: 'inline-block', wordBreak: 'break-word' }}
          target='_blank'
          rel='noopener noreferrer'
        >
          {url}
        </a>
      </List.Content>
    </List.Item>
  )
}

SocialLink.propTypes = {
  url: PropTypes.string,
  icon: PropTypes.string,
}

SocialLink.defaultProps = {
  url: undefined,
  icon: undefined,
}

const mapStateToProps = (state) => ({
  fromStore: {
    getApplicant: (id) => getUser(state, { id }),
    getSpokenLanguage: (id) => getLanguage(state, { id }),
  },
})

const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    fetchApplicant: (id) => dispatch(fetchUser(id)),
  },
})

function _UserSection({
  hostApplication: { user, host },
  fromStore: { getApplicant, getSpokenLanguage },
  actions: { fetchApplicant },
}) {
  const [t] = useTranslation()
  const applicant = getApplicant(user.id)
  const spokenLanguages = host
    .get('spoken_languages')
    .map(
      (language) =>
        language && (
          <List.Item key={language.get('language_id')}>{`${
            getSpokenLanguage(language.get('language_id')).title
          }`}</List.Item>
        ),
    )

  // user's payload from hostApplication does not contain the locality and country
  // needed to be shown in the <UserCard />
  useEffect(() => {
    if (user.id) fetchApplicant(user.id)
  }, [user.id])

  if (!user) return null

  const onlineProfiles = host && host.get('online_profiles')
  const businessName = host && host.get('motivation') && host.get('motivation').get('business_name')

  const facebookUrl = onlineProfiles && onlineProfiles.get('facebook')
  const instagramUrl = onlineProfiles && onlineProfiles.get('instagram')
  const twitterUrl = onlineProfiles && onlineProfiles.get('twitter')
  const tripAdvisorUrl = onlineProfiles && onlineProfiles.get('tripAdvisor')
  const websiteUrl = onlineProfiles && onlineProfiles.get('website')
  const otherUrl = onlineProfiles && onlineProfiles.get('other')

  return (
    <UserCard user={applicant} brandName={businessName} showUserDetails={false}>
      <List>
        <List.Item>
          <Icon name='language' />
          <List.Content>{spokenLanguages}</List.Content>
        </List.Item>
      </List>
      {(facebookUrl || instagramUrl || twitterUrl || tripAdvisorUrl || websiteUrl || otherUrl) && (
        <Fragment>
          <Header size='small'>{t('HostApplications::Summary::Social Media Links')}</Header>
          <List>
            {[
              { url: facebookUrl, icon: 'facebook' },
              { url: instagramUrl, icon: 'instagram' },
              { url: twitterUrl, icon: 'twitter' },
              { url: tripAdvisorUrl, icon: 'tripadvisor' },
              { url: websiteUrl, icon: 'linkify' },
              { url: otherUrl, icon: 'linkify' },
            ].map(({ url, icon }) => (
              <SocialLink key={icon} url={url} icon={icon} />
            ))}
          </List>
        </Fragment>
      )}
    </UserCard>
  )
}

_UserSection.propTypes = {
  hostApplication: PropTypes.shape({
    place: PropTypes.immutable.record,
    host: PropTypes.immutable.map,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }),
  fromStore: PropTypes.shape({
    getApplicant: PropTypes.func,
    getSpokenLanguage: PropTypes.immutable.Record,
  }).isRequired,
  actions: PropTypes.shape({
    fetchApplicant: PropTypes.func,
  }).isRequired,
}

_UserSection.defaultProps = {
  hostApplication: {
    place: undefined,
    host: undefined,
    user: undefined,
  },
}

export const UserSection = connect(mapStateToProps, mapDispatchToProps)(_UserSection)
