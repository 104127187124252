export const getTitleFromLocale = (locale) => {
  switch (locale) {
    case 'fr':
      return 'Français'
    case 'es':
      return 'Español'
    case 'it':
      return 'Italiano'
    case 'zh-CN':
      return '中文（简体）'
    case 'zh-TW':
      return '中文（繁體）'
    case 'he':
      return 'עברית'
    case 'en':
    default:
      return 'English'
  }
}
