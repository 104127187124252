import { schema } from 'normalizr'
import { Record } from 'immutable'

const _schema = new schema.Entity('replies')

const _Record = Record({
  id: undefined,
  sender_id: undefined,
  body: '',
  read: undefined,
  created_at: undefined,
  updated_at: undefined,
})

export const replies = {
  schema: _schema,
  Record: _Record,
}
