// Libs
import React from 'react'
import PropTypes from 'helpers/proptypes'
import { Link } from 'react-router'
import { useTranslation, Trans } from 'react-i18next'
// Components
import { Grid, Label, Segment } from 'semantic-ui-react'
import { numbers } from '@vizeat/helpers'

const { formatIntegerPrice } = numbers

export const PaymentDetails = ({ payment }) => {
  const [t] = useTranslation()

  if (!payment) return null

  return (
    <Segment>
      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <label>
              {t('Bookings::Summary::Payment::Payment ID: {{paymentId}}', {
                paymentId: payment.get('id'),
              })}
            </label>
          </Grid.Column>
          <Grid.Column width={4}>
            <label>
              {t('Bookings::Summary::Payment::Payment Method: {{paymentMethod}}', {
                paymentMethod: payment.get('payin'),
              })}
            </label>
          </Grid.Column>
          <Grid.Column width={4}>
            <label>
              <Trans
                i18nKey={__('Bookings::Summary::Payment::Payer ID: <userURL>{{userId}}</userURL>')}
                values={{
                  userId: payment.get('payer_id'),
                }}
                components={{ userURL: <Link to={`/users?search=${payment.get('payer_id')}`} /> }}
              />
            </label>
          </Grid.Column>
          <Grid.Column width={4}>
            <label>
              <Trans
                i18nKey={__('Bookings::Summary::Payment::Payee ID: <userURL>{{userId}}</userURL>')}
                values={{
                  userId: payment.get('payee_id'),
                }}
                components={{ userURL: <Link to={`/users?search=${payment.get('payee_id')}`} /> }}
              />
            </label>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            <label>
              {t('Bookings::Summary::Payment::Number of seats paid to host: {{seats}}', {
                seats: payment.get('seats'),
              })}
            </label>
          </Grid.Column>
          <Grid.Column width={4}>
            <label>
              {t('Bookings::Summary::Payment::Host Price: {{hostPrice}}', {
                hostPrice: formatIntegerPrice(payment.get('event_price'), 'en', payment.get('event_currency')),
              })}
            </label>
          </Grid.Column>
          <Grid.Column width={4}>
            <label>
              {t('Bookings::Summary::Payment::Guest Price: {{price}}', {
                price: formatIntegerPrice(
                  Math.round(payment.get('total_paid') / payment.get('seats')),
                  'en',
                  payment.get('user_currency'),
                ),
              })}
            </label>
          </Grid.Column>
          <Grid.Column width={4}>
            <label>
              {t('Bookings::Summary::Payment::Discount:  {{discount}}', {
                discount: formatIntegerPrice(payment.get('discount'), 'en', payment.get('user_currency')),
              })}
            </label>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <label>
              <Trans
                i18nKey={__(
                  'EventsCalendar::Fees rate: {{feesRate}}% | Fees: {{fees}} | Total guest price incl. fees: <label>{{totalPrice}}</label>',
                )}
                values={{
                  feesRate: (payment.get('fees_rate') * 100).toFixed(2),
                  fees: formatIntegerPrice(payment.get('fees'), 'en', payment.get('user_currency')),
                  totalPrice: formatIntegerPrice(payment.get('total_paid'), 'en', payment.get('user_currency')),
                }}
                components={{ label: <Label circular color='blue' /> }}
              />
            </label>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

PaymentDetails.propTypes = {
  payment: PropTypes.immutable.map.isRequired,
}
