import { getUser, getAdmin } from 'redux/entities/selectors'

export function getReducer (state) {
  return state.get('admin')
}

export function getAuthToken (state) {
  return getReducer(state).get('token')
}

export function getLoading (state) {
  return getReducer(state).get('loading')
}

export function getError (state) {
  return getReducer(state).get('error')
}

export function getCurrentUser (state) {
  return getUser(state, { id: getReducer(state).get('userId') })
}

export function getAdminProfile (state) {
  return getAdmin(state, { id: getReducer(state).get('adminId') })
}
