// libs
import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useSelector } from 'react-redux'
// redux
import { getAppLanguage } from 'redux/reducers/appSettings'
// components
import { Icon } from 'semantic-ui-react'

export function ReviewCriteria({ ratings }) {
  const { locale } = useSelector(getAppLanguage)

  if (!ratings.size) return null

  return (
    <div style={{ display: 'flex', textTransform: 'capitalize' }}>
      {ratings.map((rating, key) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px', marginRight: '12px' }} key={key}>
          <b>{`${rating.getIn(['criterion', 'title', locale])}: `}</b>
          {rating.get('score')}
          <Icon color='yellow' name='star' size='small' />
        </div>
      ))}
    </div>
  )
}

ReviewCriteria.propTypes = {
  ratings: PropTypes.immutable.list,
}

ReviewCriteria.defaultProps = {
  ratings: undefined,
}
