// Libraries
import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { Form, Button } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'

class _DietRestriction extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    restriction: PropTypes.shape({
      diets: PropTypes.array,
      guest: PropTypes.string,
    }).isRequired,
    diets: PropTypes.immutable.map,
    onDelete: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
  }

  static defaultProps = {
    diets: undefined,
  }

  formatDietsOptions = () =>
    Object.values(this.props.diets.toJS()).map((diet) => ({ text: diet.title, value: diet.id }))

  render() {
    const {
      t,
      restriction: { guest, diets },
      onUpdate,
      onDelete,
      index,
    } = this.props

    return (
      <Form.Group widths='equal' className='__item'>
        <Form.Field>
          <Form.Input
            placeholder={t('EventsCalendar::Name of guest')}
            value={guest}
            onChange={(e, { value }) => onUpdate(index, { guest: value, diets })}
          />
        </Form.Field>

        <Form.Field>
          <Form.Dropdown
            placeholder={t('EventsCalendar::Select diets')}
            search
            fluid
            selection
            multiple
            value={diets}
            options={this.formatDietsOptions()}
            onChange={(e, { value }) => onUpdate(index, { guest, diets: value })}
          />
        </Form.Field>
        <Button circular onClick={() => onDelete(index)} type='button' size='mini'>
          X
        </Button>
      </Form.Group>
    )
  }
}

export const DietRestriction = withTranslation('common')(_DietRestriction)
