import settings from 'settings'
// Libraries
import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { omitBy } from 'lodash'
import { withTranslation } from 'react-i18next'
// Components
import { Button, Form, Select, Checkbox, Modal } from 'semantic-ui-react'
import { List, Paginate } from 'components/lists'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { RequestRow } from './RequestRow'
import { ChangeStatusForm } from './ChangeStatusForm'
import { FormModal } from 'components/modals'
import { InvoiceBookingForm } from 'pages/events-calendar/privateEventForm'
// Utils
import { getFullPath } from 'helpers/url'
import { isInvalid } from 'helpers/forms'
import { getTranslatedOptions } from '../../helpers/options'
import { requestsFollowupsActions } from 'helpers/followups'
// Redux
import { createFollowups, fetchRequests, updateRequest } from 'redux/entities/actions'
import {
  creatingFollowup,
  fetchingRequests,
  getRequestsList,
  getRequestsError,
  getRequestError,
  getRequestsTotal,
} from 'redux/entities/selectors'
import { getAdminProfile } from 'redux/reducers/admin/selectors'
import { getLocation } from 'redux/reducers/router'
// Style
import './RequestList.css'

const mapStateToProps = (state) => ({
  addingFollowup: creatingFollowup(state),
  adminProfile: getAdminProfile(state),
  error: {
    list: getRequestsError(state),
    request: (id) => getRequestError(state, id),
  },
  location: getLocation(state),
  loading: fetchingRequests(state),
  requests: getRequestsList(state),
  total: getRequestsTotal(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    addFollowups: (payload) => dispatch(createFollowups(payload)),
    loadPaginatedRequests: ({ offset = 0, size = settings.defaultPaginationSize, ...rest }) =>
      dispatch(fetchRequests({ query: { offset, size, ...rest } })),
    updateRequest: (id, payload) => dispatch(updateRequest(id, payload)),
  },
})

const getStaticHeaders = (t) => [
  { label: t('Requests::Id'), field: 'id' },
  { label: t('Requests::Last update'), field: 'updated_at' },
  { label: t('Requests::Country'), field: 'country' },
  { label: t('Requests::Locality'), field: 'locality' },
  { label: t('Requests::Status'), field: 'status' },
  { label: t('Requests::Date'), field: 'date' },
  { label: t('Requests::Expiration date'), field: 'will_expire_at' },
  { label: t('Requests::Guest (id, name & email)'), field: 'guest' },
  { label: t('Requests::Host (id, name)'), field: 'host' },
  { label: t('Requests::Experience (id - title)'), field: 'event' },
  { label: t('Requests::Seats'), field: 'seats' },
  { label: t('Requests::Last followup'), field: 'followups' },
  { label: t('Requests::Actions::Actions') },
]

class _RequestList extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      addFollowups: PropTypes.func.isRequired,
      loadPaginatedRequests: PropTypes.func.isRequired,
      updateRequest: PropTypes.func.isRequired,
    }).isRequired,
    addingFollowup: PropTypes.bool.isRequired,
    adminProfile: PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
    loading: PropTypes.bool,
    error: PropTypes.shape({
      list: PropTypes.immutable.list,
      data: PropTypes.shape({
        message: PropTypes.string,
        stack_trace: PropTypes.any,
      }),
      request: PropTypes.immutable.object,
      status: PropTypes.number,
      statusText: PropTypes.string,
    }),
    requests: PropTypes.immutable.list,
    location: PropTypes.immutable.map.isRequired,
    total: PropTypes.number,
  }

  static defaultProps = {
    loading: false,
    total: 0,
    requests: undefined,
    error: undefined,
  }

  state = {
    error: null,
    selectedAction: undefined,
    selection: [],
    comment: '',
    requestToEdit: undefined,
    isInvoiceModalVisible: false,
  }

  UNSAFE_componentWillMount() {
    const query = this.props.location.get('query')
    if (query.size) {
      this.loadRequests(query.toJS())
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (getFullPath(this.props.location) !== getFullPath(nextProps.location)) {
      this.loadRequests(nextProps.location.get('query').toJS())
    }
  }

  handleAddFollowup = (request) => {
    this.setState({
      openFollowup: true,
      requestToEdit: request,
    })
  }

  handleEditStatus = (request) => {
    this.setState({
      openStatus: true,
      requestToEdit: request,
    })
  }

  handleOpenInvoiceModal = (request) => {
    this.setState({
      isInvoiceModalVisible: true,
      requestToEdit: request,
    })
  }

  handleStatusSubmit = async (id, payload) => {
    await this.props.actions.updateRequest(id, payload)
    this.setState({ openStatus: !!this.props.error.request(id) })
  }

  loadRequests(query) {
    return this.props.actions
      .loadPaginatedRequests(omitBy(query, isInvalid))
      .then(() => this.setState({ error: this.props.error.list }))
  }

  onSelect = (checked, payoutId) => {
    return this.setState({
      selection: checked ? [...this.state.selection, payoutId] : this.state.selection.filter((id) => id !== payoutId),
    })
  }

  selectAll = (e, { checked }) => {
    this.setState({
      selection: checked ? this.props.requests.map((p) => p.id).toArray() : [],
    })
  }

  closeInvoiceModal = () => this.setState({ isInvoiceModalVisible: false })

  submit = async () => {
    const followups = []

    this.state.selection.map((id) =>
      followups.push({
        following_id: id,
        following_type: 'requests',
        action: this.state.selectedAction,
        comment: this.state.comment,
        admin_id: this.props.adminProfile.id,
      }),
    )

    await this.props.actions.addFollowups({ followups })
    await this.setState({ selection: [] })
    if (this.props.error.list) await this.setState({ error: this.props.error })
  }

  render() {
    const { t, requests } = this.props
    const allSelected = this.state.selection.length === this.props.requests.size
    const headers = [...getStaticHeaders(t)].concat({
      label: <Checkbox onChange={this.selectAll} checked={allSelected} />,
    })
    return (
      <div className='__bookingList'>
        <Paginate count={this.props.total} previous next loading={this.props.loading}>
          <Form onSubmit={this.submit} className='__followupForm'>
            <Form.Group>
              <Form.Field>
                <label>{t('Requests::Actions::Actions')}</label>
                <Select
                  options={getTranslatedOptions(t, requestsFollowupsActions)}
                  onChange={(e, { value }) => this.setState({ selectedAction: value })}
                  value={this.state.selectedAction}
                />
              </Form.Field>

              <Form.Field>
                <label>{t('Requests::Comment')}</label>
                <Form.TextArea
                  rows={1}
                  onChange={(e, { value }) => this.setState({ comment: value })}
                  value={this.state.comment}
                />
              </Form.Field>

              <Form.Field>
                <label>&nbsp;</label>
                <Button type='submit' disabled={this.state.selection.length === 0 || this.props.addingFollowup}>
                  {t('Requests::Ok')}
                </Button>
              </Form.Field>
            </Form.Group>
          </Form>

          <List striped celled sortable headers={headers} location={this.props.location}>
            {requests.toList().map((request) => (
              <RequestRow
                key={request.id}
                request={request}
                onOpenInvoiceModal={this.handleOpenInvoiceModal}
                onEditStatus={this.handleEditStatus}
                onAddFollowup={this.handleAddFollowup}
                addingFollowup={this.props.addingFollowup}
                onSelect={this.onSelect}
                selected={this.state.selection.includes(request.id)}
              />
            ))}
          </List>
        </Paginate>

        {this.state.isInvoiceModalVisible && (
          <FormModal
            closeModal={this.closeInvoiceModal}
            isOpen={this.state.isInvoiceModalVisible}
            btnTitle={t('Requests::Actions::PE Invoice')}
            headerTitle={t('Requests::Actions::Create a new private event paid by invoice')}
          >
            <InvoiceBookingForm
              handleSubmit={this.closeInvoiceModal}
              eventWithOverrides={this.state.requestToEdit.event}
              request={this.state.requestToEdit}
              btnSubmitTitle={t('Requests::Actions::Create Private Event paid by invoice')}
            />
          </FormModal>
        )}

        <Modal open={this.state.openStatus} size='small' closeIcon onClose={() => this.setState({ openStatus: false })}>
          {this.state.requestToEdit && (
            <Modal.Header>
              {t('Requests::Actions::Change status of request n° {{requestId}}', {
                requestId: this.state.requestToEdit.id,
              })}
            </Modal.Header>
          )}

          <Modal.Content>
            {this.state.openStatus && (
              <ChangeStatusForm
                id={this.state.requestToEdit.id}
                error={this.props.error.request(this.state.requestToEdit.id)}
                onSubmit={this.handleStatusSubmit}
              />
            )}
          </Modal.Content>
        </Modal>
        <ApiErrorMessage error={this.state.error} modal />
      </div>
    )
  }
}

export const RequestList = withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(_RequestList))
