// libraries
import React from 'react'
import PropTypes from 'helpers/proptypes'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
// components
import { Link } from 'react-router'
import { Table, Button, Input, Popup, Icon } from 'semantic-ui-react'
import { FormattedFollowup } from 'components/followups'
// helpers
import { connectAs } from 'helpers/users'
const { Row, Cell } = Table

export const PartnerRow = ({ partner, onAddFollowup, addingFollowup }) => {
  const [t] = useTranslation()
  return (
    <Row>
      <Cell>{partner.id}</Cell>
      <Cell>{partner.name}</Cell>
      <Cell>{partner.account.email}</Cell>
      <Cell>{partner.phone}</Cell>
      <Cell>
        <Input
          type='text'
          value={partner.api_key}
          readOnly
          onClick={(e) => {
            e.target.select()
            document.execCommand('copy')
          }}
        />
      </Cell>
      <Cell>{partner.organization.name}</Cell>
      <Cell>{moment(partner.created_at).format('lll')}</Cell>
      <Cell>
        <FormattedFollowup followups={partner.followups} />
        &nbsp;
        {addingFollowup && <Icon loading name='circle notched' />}
      </Cell>
      <Cell textAlign='center'>
        <Popup
          trigger={<Button primary circular as={Link} to={`partners/edit/${partner.id}`} icon='edit' />}
          content={t('Partners::Edit Partner')}
          inverted
        />
        <Popup
          trigger={<Button primary circular onClick={() => onAddFollowup()} icon='file text' />}
          content={t('Partners::Followup')}
          inverted
        />
        <Popup
          trigger={<Button primary circular onClick={() => connectAs(partner.account.id)} icon='user' />}
          content={t('Partners::Connect as {{partner}} on Eatwith.com', { partner: partner.name })}
          inverted
        />
      </Cell>
    </Row>
  )
}

PartnerRow.propTypes = {
  partner: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    account: PropTypes.object,
    phone: PropTypes.string,
    api_key: PropTypes.string,
    organization: PropTypes.immutable.record,
    created_at: PropTypes.string,
    followups: PropTypes.object,
  }).isRequired,
  onAddFollowup: PropTypes.func.isRequired,
  addingFollowup: PropTypes.bool,
}

PartnerRow.defaultProps = {
  addingFollowup: false,
}
