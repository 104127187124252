// libs
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
// components
import { Form } from 'semantic-ui-react'
// helpers
import PropTypes from 'helpers/proptypes'
import { timeOptions, seatOptions } from 'helpers/forms'
// redux
import { mergeInForm } from 'redux/forms/actions'
import { getForm } from 'redux/forms/reducer'

const mapStateToProps = (state, props) => ({
  fromStore: {
    form: getForm(state, 'event'),
  },
})
const mapDispatchToProps = (dispatch) => ({
  actions: {
    mergeInForm: (formData) => dispatch(mergeInForm({ formName: 'event', value: formData })),
  },
})

class _SectionCapacityAndTime extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    isEventFrozen: PropTypes.bool,
    fromStore: PropTypes.shape({
      form: PropTypes.immutable.map,
    }).isRequired,
    actions: PropTypes.shape({
      mergeInForm: PropTypes.func,
    }).isRequired,
  }

  static defaultProps = {
    isEventFrozen: false,
  }

  render() {
    const {
      t,
      fromStore: { form },
      actions,
      isEventFrozen,
    } = this.props

    return (
      <section id='section-capacity-and-time'>
        <h3 className='section-title'>{t('Experiences::Summary::Experience capacity & time')}</h3>

        <Form.Group widths='equal'>
          <Form.Field>
            <label>{t('Experiences::Summary::Number of guest')}</label>
            <Form.Group widths='equal'>
              <Form.Field>
                <label>{t('Experiences::Summary::Min')}</label>
                <Form.Select
                  compact
                  options={seatOptions}
                  value={form.get('min_seats')}
                  onChange={(e, { value }) => actions.mergeInForm({ min_seats: parseInt(value) })}
                />
              </Form.Field>

              <Form.Field>
                <label>{t('Experiences::Summary::Max')}</label>
                <Form.Select
                  compact
                  options={seatOptions}
                  value={form.get('max_seats')}
                  onChange={(e, { value }) => actions.mergeInForm({ max_seats: parseInt(value) })}
                />
              </Form.Field>
            </Form.Group>
          </Form.Field>

          <Form.Field disabled={isEventFrozen}>
            <label>{t('Experiences::Summary::Guest arrival time')}</label>
            <Form.Group widths='equal'>
              <Form.Field>
                <label>{t('Experiences::Summary::From')}</label>
                <Form.Select
                  compact
                  options={timeOptions}
                  value={form.get('begins_at')}
                  onChange={(e, { value }) => actions.mergeInForm({ begins_at: value })}
                />
              </Form.Field>

              <Form.Field>
                <label>{t('Experiences::Summary::To')}</label>
                <Form.Select
                  compact
                  options={timeOptions}
                  value={form.get('ends_at')}
                  onChange={(e, { value }) => actions.mergeInForm({ ends_at: value })}
                />
              </Form.Field>
            </Form.Group>
          </Form.Field>
        </Form.Group>
      </section>
    )
  }
}

export const SectionCapacityAndTime = withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(_SectionCapacityAndTime),
)
