// libs
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation, Trans } from 'react-i18next'

// components
import EditPageMenu from 'components/menus/EditPageMenu'
import { CampaignForm } from './Form'
import { CampaignCard } from './Card'
import { Grid, Modal, Message, Icon } from 'semantic-ui-react'
import Sticky from 'react-stickynode'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'

// redux
import { fetchCampaign, fetchUser } from 'redux/entities/actions'
import { getCampaign } from 'redux/entities/selectors'

const mapStateToProps = (state, props) => ({
  fromStore: {
    campaign: props.params.id && getCampaign(state, { id: props.params.id }),
  },
})

const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    fetchCampaign: (id) => dispatch(fetchCampaign(id)),
    fetchUser: (id) => dispatch(fetchUser(id)),
  },
})

class CampaignsEdit extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fromStore: PropTypes.shape({
      campaign: PropTypes.object,
    }),
    actions: PropTypes.shape({
      fetchCampaign: PropTypes.func,
      fetchUser: PropTypes.func,
    }),
    params: PropTypes.object,
  }

  UNSAFE_componentWillMount() {
    const { params, actions, fromStore } = this.props
    this.setState({ error: null, initialized: !params.id })

    if (params && params.id) actions.fetchCampaign(params.id).then(() => this.setState({ initialized: true }))
    if (fromStore.campaign && fromStore.campaign.user_id) actions.fetchUser(fromStore.campaign.user_id)
  }

  getMenuItems = (t) => [
    { text: t('Campaigns::Summary::Amount'), value: 'amount' },
    { text: t('Campaigns::Summary::Title'), value: 'title' },
    { text: t('Campaigns::Summary::Description'), value: 'description' },
    { text: t('Campaigns::Summary::User'), value: 'user_id' },
    { text: t('Campaigns::Summary::Category'), value: 'category' },
    { text: t('Campaigns::Summary::Code'), value: 'code' },
    { text: t('Campaigns::Summary::Start date'), value: 'begin_at' },
    { text: t('Campaigns::Summary::End date'), value: 'end_at' },
    { text: t('Campaigns::Summary::Type'), value: 'type' },
    { text: t('Campaigns::Summary::Currency'), value: 'currency' },
    { text: t('Campaigns::Summary::Discount'), value: 'discount' },
    { text: t('Campaigns::Summary::Usage'), value: 'usage' },
    { text: t('Campaigns::Summary::Max usage'), value: 'max_usage_limit' },
    { text: t('Campaigns::Summary::Max guests'), value: 'max_guest_limit' },
    { text: t('Campaigns::Summary::Min guests'), value: 'min_guest_limit' },
    { text: t('Campaigns::Summary::Max price'), value: 'max_price_limit' },
    { text: t('Campaigns::Summary::Min price'), value: 'min_price_limit' },
    { text: t('Campaigns::Summary::First time'), value: 'first_time' },
    { text: t('Campaigns::Summary::Free only'), value: 'free_only' },
    { text: t('Campaigns::Summary::Active'), value: 'active' },
    { text: t('Campaigns::Summary::Events'), value: 'events' },
  ]

  closeErrorModal = () => this.setState({ error: null })
  closeSuccessModal = () => this.setState({ success: undefined })
  onSaved = ({ error, success, title, codes }) => this.setState({ error, success, title, codes })

  render() {
    const {
      props: {
        t,
        fromStore: { campaign },
      },
      state: { error, success, title, codes = [], initialized },
    } = this

    return (
      <Grid padded>
        <Grid.Row>
          <Grid.Column width={3}>
            <Sticky>
              <EditPageMenu listName='campaigns' menuItems={this.getMenuItems(t)} />
            </Sticky>
          </Grid.Column>
          <Grid.Column width={8}>{initialized && <CampaignForm campaign={campaign} />}</Grid.Column>
          <Grid.Column width={3}>
            <Sticky>{initialized && <CampaignCard campaign={campaign} onSaved={this.onSaved} />}</Sticky>
          </Grid.Column>
        </Grid.Row>

        <ApiErrorMessage open={!!error} onClose={this.closeErrorModal} error={error} modal />
        <Modal open={success} onClose={this.closeSuccessModal}>
          <Message success>
            <Icon name='thumbs outline up' className='icon-opacify' color='green' />
            <Trans
              i18nKey={__("Campaigns::Summary::Well done! You've just saved campaign <i>{{title}}</i>.")}
              values={{ title }}
              components={{ i: <i /> }}
            />
            <br />
            {t('Campaigns::Summary::The coupon codes are: {{coupons}}', { coupons: codes.join(', ') })}
          </Message>
        </Modal>
      </Grid>
    )
  }
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(CampaignsEdit))
