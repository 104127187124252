// libs
import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
// components
import { Form, Message } from 'semantic-ui-react'

export const SeatsForm = ({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => {
  const [t] = useTranslation()

  return (
    <Form onSubmit={handleSubmit} autoComplete='off'>
      <Form.Group widths='equal'>
        <Form.Input
          id='seats'
          label={t('Forms::SeatsForm::Seats')}
          type='number'
          min={0}
          value={values.seats}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.seats &&
            touched.seats &&
            !values.readOnly && {
              content: errors.seats,
            }
          }
          readOnly={values.readOnly}
        />

        <Form.Input
          id='ewFreeSeats'
          label={t('Forms::SeatsForm::EW free seats')}
          type='number'
          min={0}
          value={values.ewFreeSeats}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.ewFreeSeats &&
            touched.ewFreeSeats &&
            !values.readOnly && {
              content: errors.ewFreeSeats,
            }
          }
          readOnly={values.readOnly}
        />

        <Form.Input
          id='hostFreeSeats'
          label={t('Forms::SeatsForm::Host free seats')}
          type='number'
          min={0}
          value={values.hostFreeSeats}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.hostFreeSeats &&
            touched.hostFreeSeats &&
            !values.readOnly && {
              content: errors.hostFreeSeats,
            }
          }
          readOnly={values.readOnly}
        />

        <Form.Button primary type='submit' disabled={values.readOnly} style={{ marginTop: '24px' }}>
          {t('Forms::SeatsForm::Submit')}
        </Form.Button>
      </Form.Group>

      {errors.freeSeatsError && (
        <Message negative>
          <p>{errors.freeSeatsError}</p>
        </Message>
      )}
    </Form>
  )
}

SeatsForm.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.shape({
    id: PropTypes.number,
    seats: PropTypes.number,
    ewFreeSeats: PropTypes.number,
    hostFreeSeats: PropTypes.number,
    readOnly: PropTypes.bool,
  }).isRequired,
  errors: PropTypes.shape({
    seats: PropTypes.string,
    ewFreeSeats: PropTypes.string,
    hostFreeSeats: PropTypes.string,
    freeSeatsError: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    seats: PropTypes.bool,
    ewFreeSeats: PropTypes.bool,
    hostFreeSeats: PropTypes.bool,
  }).isRequired,
}
