import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const requestsActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'requests' })

export const createRequest = (payload, { query } = {}) =>
  requestsActions.create({
    url: '/api/requests',
    payload,
    query,
    schema: { request: schemas.requests },
  })

export const createDirectRequest = (payload, { query } = {}) =>
  requestsActions.create({
    url: '/api/approved_requests',
    payload,
    query,
    schema: { request: schemas.requests },
  })

export const fetchRequests = ({ query } = {}) =>
  requestsActions.fetch({
    url: '/api/requests',
    query,
    schema: { requests: [schemas.requests] },
  })

export const fetchRequest = (id, { query } = {}) =>
  requestsActions.fetch({
    url: `/api/requests/${id}`,
    query,
    schema: { request: schemas.requests },
  })

export const updateRequest = (id, payload, { query } = {}) =>
  requestsActions.update({
    url: `/api/requests/${id}`,
    payload,
    query,
    schema: { request: schemas.requests },
  })
