import settings from 'settings'
// Libraries
import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { omitBy } from 'lodash'
import { useTranslation } from 'react-i18next'
// Redux
import {
  createFollowups,
  fetchAdmins,
  fetchPartnerDemands,
  updatePartnerDemand,
  deletePartnerDemand,
} from 'redux/entities/actions'
import {
  creatingFollowup,
  fetchingPartnerDemands,
  getPartnerDemandsError,
  getPartnerDemandsList,
  getPartnerDemandsTotal,
} from 'redux/entities/selectors'
import { getAdminProfile } from 'redux/reducers/admin/selectors'
import { getLocation } from 'redux/reducers/router'
// Components
import { List, Paginate } from 'components/lists'
import { PartnerDemandRow } from './PartnerDemandRow'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { Checkbox, Form, Select, Button } from 'semantic-ui-react'
// Helpers
import { getTranslatedOptions } from '../../helpers/options'
import { partnerDemandsFollowupsActions } from 'helpers/followups'
import { isInvalid } from 'helpers/forms'

const mapStateToProps = (state) => ({
  fromStore: {
    addingFollowup: creatingFollowup(state),
    adminProfile: getAdminProfile(state),
    error: getPartnerDemandsError(state),
    isFetchingPartnerDemands: fetchingPartnerDemands(state),
    location: getLocation(state),
    partnerDemands: getPartnerDemandsList(state),
    totalDemands: getPartnerDemandsTotal(state),
  },
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    addFollowups: (payload) => dispatch(createFollowups(payload)),
    fetchAdmins: () => dispatch(fetchAdmins()),
    fetchPartnerDemands: ({ offset = 0, size = settings.defaultPaginationSize, ...rest }) =>
      dispatch(fetchPartnerDemands({ query: { offset, size, ...rest } })),
    updatePartnerDemand: (id, payload) => dispatch(updatePartnerDemand(id, payload)),
    deletePartnerDemand: (id) => dispatch(deletePartnerDemand(id)),
  },
})

const getHeaders = (t) => [
  { label: t('PartnerDemands::Id'), field: 'id' },
  { label: t('PartnerDemands::Last updated'), field: 'updated_at' },
  { label: t('PartnerDemands::Organization') },
  { label: t('PartnerDemands::Partner') },
  { label: t('PartnerDemands::Locality'), field: 'locality' },
  { label: t('PartnerDemands::Date'), field: 'date' },
  { label: t('PartnerDemands::Reference') },
  { label: t('PartnerDemands::Type'), field: 'event_type' },
  { label: t('PartnerDemands::Total Number Of Seats'), field: 'seats' },
  { label: t('PartnerDemands::Free Seats') },
  { label: t('PartnerDemands::Seats to pay/invoice') },
  { label: t('PartnerDemands::Status'), field: 'status' },
  { label: t('PartnerDemands::Last followup') },
  { label: t('PartnerDemands::Asignee') },
  { label: t('PartnerDemands::Actions') },
]

function _PartnerDemandList({
  actions: { addFollowups, fetchAdmins, fetchPartnerDemands, updatePartnerDemand, deletePartnerDemand },
  fromStore: { adminProfile, error, location, partnerDemands, totalDemands, isFetchingPartnerDemands, addingFollowup },
}) {
  const [t] = useTranslation()
  const doFetchPartnerDemands = useCallback(() => {
    return fetchPartnerDemands(omitBy(location.get('query').toJS(), isInvalid))
  }, [fetchPartnerDemands, location])

  useEffect(() => {
    fetchAdmins()
  }, [fetchAdmins])
  useEffect(() => {
    doFetchPartnerDemands()
  }, [doFetchPartnerDemands])
  const [selection, setSelection] = useState([])
  const [selectedAction, setSelectedAction] = useState()
  const [comment, setComment] = useState()

  function onSelect(checked, demandId) {
    setSelection(checked ? [...selection, demandId] : selection.filter((id) => id !== demandId))
  }

  function selectAll(e, { checked }) {
    setSelection(checked ? partnerDemands.map((p) => p.id).toArray() : [])
  }

  async function submit() {
    await addFollowups({
      followups: selection.map((id) => ({
        action: selectedAction,
        admin_id: adminProfile.id,
        comment: comment,
        following_id: id,
        following_type: 'partner_demands',
      })),
    })
    setSelection([])
    setComment(undefined)
    setSelectedAction(undefined)
    doFetchPartnerDemands()
  }

  const handleAssign =
    (followingId) =>
    async (e, { value }) => {
      await addFollowups({
        followups: [
          {
            action: 'ASSIGNMENT',
            admin_id: adminProfile.id,
            comment: value,
            following_id: followingId,
            following_type: 'partner_demands',
          },
        ],
      })
      doFetchPartnerDemands()
    }

  const handleUpdate = (id) => (payload) => {
    updatePartnerDemand(id, payload)
  }

  const handleDelete = (id) => () => {
    deletePartnerDemand(id)
    doFetchPartnerDemands()
  }

  return (
    <div style={{ overflowX: 'auto', width: '100%' }}>
      <Paginate count={totalDemands} previous next loading={isFetchingPartnerDemands}>
        <Form onSubmit={submit} className='__followupForm'>
          <Form.Group>
            <Form.Field>
              <label>{t('PartnerDemands::Actions')}</label>
              <Select
                onChange={(e, { value }) => setSelectedAction(value)}
                options={getTranslatedOptions(t, partnerDemandsFollowupsActions)}
                value={selectedAction}
              />
            </Form.Field>

            <Form.Field>
              <label>{t('PartnerDemands::Comment')}</label>
              <Form.TextArea rows={1} onChange={(e, { value }) => setComment(value)} value={comment} />
            </Form.Field>

            <Form.Field>
              <label>&nbsp;</label>
              <Button type='submit' disabled={selection.length === 0 || addingFollowup}>
                {t('PartnerDemands::Ok')}
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>

        <List
          striped
          celled
          sortable
          headers={[
            ...getHeaders(t),
            {
              label: <Checkbox onChange={selectAll} checked={selection.length === partnerDemands.size} />,
            },
          ]}
          location={location}
        >
          {partnerDemands.map((partnerDemand, i) => (
            <PartnerDemandRow
              key={i}
              partnerDemand={partnerDemand}
              onSelect={onSelect}
              selected={selection.includes(partnerDemand.id)}
              onAssignTask={handleAssign(partnerDemand.id)}
              onUpdate={handleUpdate(partnerDemand.id)}
              onDelete={handleDelete(partnerDemand.id)}
            />
          ))}
        </List>
      </Paginate>
      <ApiErrorMessage error={error} modal />
    </div>
  )
}

_PartnerDemandList.propTypes = {
  actions: PropTypes.shape({
    fetchAdmins: PropTypes.func.isRequired,
    fetchPartnerDemands: PropTypes.func.isRequired,
    addFollowups: PropTypes.func.isRequired,
    updatePartnerDemand: PropTypes.func.isRequired,
    deletePartnerDemand: PropTypes.func.isRequired,
  }).isRequired,
  fromStore: PropTypes.shape({
    isFetchingPartnerDemands: PropTypes.bool.isRequired,
    location: PropTypes.immutable.map.isRequired,
    partnerDemands: PropTypes.immutable.list.isRequired,
    totalDemands: PropTypes.number.isRequired,
    adminProfile: PropTypes.object,
    error: PropTypes.object,
    addingFollowup: PropTypes.bool,
  }).isRequired,
}

export const PartnerDemandList = connect(mapStateToProps, mapDispatchToProps)(_PartnerDemandList)
