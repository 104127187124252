export function dataURItoBlob(dataURI) {
  const base64 = dataURI.split(',')[1]
  const type = dataURI.split(',')[0].split(':')[1].split(';')[0]
  return base64ToBlob(base64, type)
}

export function base64ToBlob(base64, type) {
  const byteString = atob(base64)
  // write the bytes of the string to an ArrayBuffer
  const arrayBuffer = new ArrayBuffer(byteString.length)
  const typedArray = new Uint8Array(arrayBuffer)
  for (let i = 0; i < byteString.length; i++) {
    typedArray[i] = byteString.charCodeAt(i)
  }
  return new Blob([arrayBuffer], { type })
}
