import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '@vizeat/components/es6/components/Checkbox/Checkbox'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { SectionWrapper } from './SectionWrapper'
import { DeletableBadge } from 'components/badges/DeletableBadge'
import PropTypes from 'helpers/proptypes'

export function EventType({ allowPrivateBookings, allowPublicBookings, onChange, online, showSelectedFilters }) {
  const { t } = useTranslation()

  function handleEventTypeChange(e) {
    onChange({ name: e.target.name, value: e.target.checked })
  }

  if (showSelectedFilters) {
    return (
      <Fragment>
        {allowPublicBookings && (
          <DeletableBadge
            color='mediumGray'
            onClick={() => {
              onChange({ name: 'allowPublicBookings', value: undefined })
            }}
          >
            {t('Playlist::Summary::Public event')}
          </DeletableBadge>
        )}

        {allowPrivateBookings && (
          <DeletableBadge
            color='mediumGray'
            onClick={() => {
              onChange({ name: 'allowPrivateBookings', value: undefined })
            }}
          >
            {t('Playlist::Summary::Private event')}
          </DeletableBadge>
        )}

        {online && (
          <DeletableBadge
            color='mediumGray'
            onClick={() => {
              onChange({ name: 'online', value: undefined })
            }}
          >
            {t('Playlist::Summary::Online Experiences')}
          </DeletableBadge>
        )}
      </Fragment>
    )
  }

  return (
    <SectionWrapper title={t('Playlists::Summary::Type of event')}>
      <Flex flexDirection='column' gap='28px'>
        <Checkbox
          key='allowPublicBookings'
          label={t('Playlist::Summary::Public event')}
          name='allowPublicBookings'
          checked={!!allowPublicBookings}
          onChange={handleEventTypeChange}
          mt='0'
        />
        <Checkbox
          key='allowPrivateBookings'
          label={t('Playlist::Summary::Private event')}
          name='allowPrivateBookings'
          checked={!!allowPrivateBookings}
          onChange={handleEventTypeChange}
          mt='0'
        />
        <Checkbox
          key='online'
          label={t('Playlist::Summary::Online Experiences')}
          name='online'
          checked={!!online}
          onChange={handleEventTypeChange}
          mt='0'
        />
      </Flex>
    </SectionWrapper>
  )
}

EventType.propTypes = {
  allowPrivateBookings: PropTypes.bool,
  allowPublicBookings: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  online: PropTypes.bool,
  showSelectedFilters: PropTypes.bool,
}

EventType.defaultProps = {
  allowPrivateBookings: undefined,
  allowPublicBookings: undefined,
  showSelectedFilters: false,
  online: undefined,
}
