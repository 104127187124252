// Libraries
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
// Components
import { Form, Button, Checkbox } from 'semantic-ui-react'
import { SearchInput, SelectInput } from 'components/inputs'
// Selectors
import { getLocation } from 'redux/reducers/router'
// Redux
import { getTags } from 'redux/entities/selectors'

const mapStateToProps = (state) => ({
  location: getLocation(state),
  tags: getTags(state),
})

class UserListSearchForm extends PureComponent {
  static propTypes = { t: PropTypes.func.isRequired, location: PropTypes.object, tags: PropTypes.object }
  static contextTypes = { router: PropTypes.object }

  UNSAFE_componentWillMount() {
    return this.updateStateFromLocation(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.location.equals(this.props.location)) return this.updateStateFromLocation(nextProps)
  }

  updateStateFromLocation(props) {
    return this.setState({
      search: props.location.getIn(['query', 'search'], ''),
      location: props.location.getIn(['query', 'location'], ''),
      hostOnly: props.location.getIn(['query', 'hostOnly'], '') === 'true',
      tags: props.location.getIn(['query', 'tags'], ''),
    })
  }

  updateQuery(query = this.state) {
    return this.context.router.push(this.props.location.mergeIn(['query'], { ...query, offset: 0 }).toJS())
  }

  clearSorting() {
    return this.context.router.push(this.props.location.mergeIn(['query'], { sortBy: '', order: '' }).toJS())
  }

  clearFilters() {
    return this.setState({ search: '', location: '', tags: '', hostOnly: '' }, () => this.updateQuery())
  }

  handleSubmit(e) {
    e.preventDefault()
    return this.updateQuery()
  }

  handleHostOnlyChange = (_, { checked }) => {
    this.context.router.push(this.props.location.mergeIn(['query'], { hostOnly: checked ? 'true' : '' }).toJS())
  }

  getTagsOpts() {
    if (this.tagsOpts && this.tagsOpts.length) return this.tagsOpts
    this.tagsOpts = this.props.tags
      .toList()
      .sortBy((tag) => tag.title)
      .toJS()
      .map((tag) => ({ text: tag.title, value: tag.title }))
    return this.tagsOpts
  }

  render() {
    const { t } = this.props

    return (
      <Form onSubmit={this.handleSubmit.bind(this)} style={{ marginBottom: '1em' }} autoComplete='off'>
        <Form.Group widths='equal'>
          <Form.Field>
            <label>{t('Users::Search by user')}</label>
            <SearchInput
              placeholder={t('Users::id, name, email, phone')}
              fluid
              icon='search'
              iconPosition='left'
              value={this.state.search}
              onChange={(value) => this.setState({ search: value })}
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Users::Location')}</label>
            <SearchInput
              queryName='location'
              placeholder={t('Users::Address, city, country (iso), postal code')}
              icon='search'
              iconPosition='left'
              value={this.state.location}
              onChange={(value) => this.setState({ location: value })}
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Users::Type')}</label>
            <Checkbox
              label={t('Users::Hosts only')}
              checked={this.state.hostOnly}
              onChange={this.handleHostOnlyChange}
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Users::Tags')}</label>
            <SelectInput
              queryName='tags'
              placeholder={t('Users::tags')}
              multiple
              search
              options={this.getTagsOpts()}
              value={this.state.tags}
            />
          </Form.Field>

          <Form.Field>
            <label>&nbsp;</label>
            <Button type='submit'>{t('Users::Search')}</Button>

            <Button.Group vertical basic compact size='small' style={{ transform: 'translateY(-15px)' }}>
              <Button onClick={() => this.clearFilters()}>{t('Users::Clear filters')}</Button>
              <Button onClick={() => this.clearSorting()}>{t('Users::Clear sorting')}</Button>
            </Button.Group>
          </Form.Field>
        </Form.Group>
      </Form>
    )
  }
}

export default withTranslation('common')(connect(mapStateToProps)(UserListSearchForm))
