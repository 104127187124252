import { get } from 'lodash'

export const baseFollowupsActions = [
  { text: '', value: '' },
  { text: __('FollowUps::Actions::Call'), value: 'Call' },
  { text: __('FollowUps::Actions::Reach out email'), value: 'Reach out email' },
  { text: __('FollowUps::Actions::WhatsApp'), value: 'WhatsApp' },
]

export const bookingsFollowupsActions = [
  ...baseFollowupsActions,
  { text: __('FollowUps::Actions::Inbox'), value: 'Inbox' },
  { text: __('FollowUps::Comments::Entered to PipeDrive'), value: 'Entered to PipeDrive' },
  { text: __('FollowUps::Comments::Host contacted'), value: 'Host contacted' },
  { text: __('FollowUps::Comments::Reserved'), value: 'Reserved' },
  { text: __('FollowUps::Comments::Booked'), value: 'Booked' },
]

export const bookingsFollowupsComments = [
  { text: __('FollowUps::Comments::Calendar to update'), value: 'Calendar to update' },
  { text: __('FollowUps::Comments::No answer'), value: 'No answer' },
  { text: __('FollowUps::Comments::Not available this date'), value: 'Not available this date' },
  { text: __('FollowUps::Comments::Not interested anymore'), value: 'Not interested anymore' },
  { text: __('FollowUps::Comments::Rejected guests number'), value: 'Rejected guests number' },
  { text: __('FollowUps::Comments::Reschedule'), value: 'Reschedule' },
  { text: __('FollowUps::Comments::Short notice'), value: 'Short notice' },
  { text: __('FollowUps::Comments::To draft'), value: 'To draft' },
  { text: __('FollowUps::Comments::Too early'), value: 'Too early' },
  { text: __('FollowUps::Comments::Will accept'), value: 'Will accept' },
  { text: __('FollowUps::Comments::Other'), value: 'Other' },
]

export const conversationsFollowsUpActions = [
  ...baseFollowupsActions,
  { text: __('FollowUps::Actions::Read Inbox/messages'), value: 'Read Inbox/messages' },
  { text: __('FollowUps::Actions::Rejected'), value: 'Rejected' },
]

export const requestsFollowupsActions = [
  ...baseFollowupsActions,
  { text: __('FollowUps::Actions::Read Inbox/messages'), value: 'Read Inbox/messages' },
  { text: __('FollowUps::Comments::Entered to PipeDrive'), value: 'Entered to PipeDrive' },
  { text: __('FollowUps::Comments::Host contacted'), value: 'Host contacted' },
  { text: __('FollowUps::Comments::Reserved'), value: 'Reserved' },
  { text: __('FollowUps::Comments::Booked'), value: 'Booked' },
]

export const eventsFollowupsActions = [
  { text: '', value: '' },
  { text: __('FollowUps::Actions::Copywriting'), value: 'Copywriting' },
  { text: __('FollowUps::Actions::Demo event'), value: 'Demo event' },
  { text: __('FollowUps::Actions::Email missing information'), value: 'Email missing information' },
  { text: __('FollowUps::Actions::Rejected'), value: 'Rejected' },
  { text: __('FollowUps::Actions::Welcome call'), value: 'Welcome call' },
]

export const eventsFollowupsComments = [
  { text: __('FollowUps::Comments::Approved'), value: 'Approved' },
  { text: __('FollowUps::Comments::Calendar update'), value: 'Calendar update' },
  { text: __('FollowUps::Comments::Date set'), value: 'Date set' },
  { text: __('FollowUps::Comments::No answer'), value: 'No answer' },
  { text: __('FollowUps::Comments::No event description'), value: 'No event description' },
  { text: __('FollowUps::Comments::No photos'), value: 'No photos' },
  { text: __('FollowUps::Comments::Payments information'), value: 'Payments information' },
  { text: __('FollowUps::Comments::Other'), value: 'Other' },
]

export const partnerDemandsFollowupsActions = [
  ...baseFollowupsActions,
  { text: __('FollowUps::Actions::Read Inbox/messages'), value: 'Read Inbox/messages' },
]

export const partnersFollowupsActions = [
  { text: '', value: '' },
  { text: __('FollowUps::Actions::Email'), value: 'Email' },
  { text: __('FollowUps::Actions::Rejected'), value: 'Rejected' },
  { text: __('FollowUps::Actions::Welcome call'), value: 'Welcome call' },
]

export const partnersFollowupsComments = [{ text: __('FollowUps::Comments::Other'), value: 'Other' }]

export function getLastAssigne(followups) {
  return get(
    followups
      .filter((followup) => followup.action === 'ASSIGNMENT')
      .sort((a, b) => a.id - b.id)
      .last(),
    'comment',
  )
}
