import React, { useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import { StandardMarker } from '@vizeat/components/es6/components/Map'
import { getCoordinatesFromPlace } from 'helpers/places'

export function MapEventMarker({ place }) {
  const coordinates = useMemo(() => getCoordinatesFromPlace(place) || undefined, [place])

  if (!coordinates) return null

  return <StandardMarker latitude={coordinates.lat} longitude={coordinates.lng} />
}

MapEventMarker.propTypes = {
  place: PropTypes.immutable.record.isRequired,
}
