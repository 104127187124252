import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const accountsActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'accounts' })

export const deactivateAccount = (id, { query } = {}) =>
  accountsActions.delete({
    url: `/api/accounts/${id}`,
    query,
    schema: { account: schemas.accounts },
  })

export const updateAccount = (id, payload, { query } = {}) =>
  accountsActions.update({
    url: `/api/accounts/${id}`,
    payload,
    query,
    schema: { account: schemas.accounts },
  })

export const updateAccountLanguage = (id, languageId) => updateAccount(id, { language_id: languageId })

export const activateAccount = (id, { query } = {}) => updateAccount(id, { reactivate: true }, { query })
