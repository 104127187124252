// Libraries
import React, { PureComponent } from 'react'
import { Form } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
// Helpers
import PropTypes from 'helpers/proptypes'
import { businessApi } from 'helpers/api'
import { connectAs } from 'helpers/users'

class _UserSection extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    city: PropTypes.string,
    placeholder: PropTypes.string,
    inputLabel: PropTypes.string,
    onUserChange: PropTypes.func.isRequired,
    isHost: PropTypes.bool,
    isDisabled: PropTypes.bool,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      account_id: PropTypes.number,
      firstname: PropTypes.string,
    }),
  }

  static defaultProps = {
    city: '',
    placeholder: '',
    isHost: false,
    inputLabel: '',
    isDisabled: false,
    user: undefined,
    event: undefined,
  }

  state = {
    loadingUsers: false,
    users: [],
  }

  componentDidMount = () =>
    this.props.user && !!this.props.user.firstname
      ? this.setState({ users: [this.props.user] })
      : this.searchUsers(this.props.user && this.props.user.id)

  formatUsersOptions = (users) =>
    users.map((user) => {
      return {
        text: `
      ${user.id} - ${user.firstname} ${user.lastname}
      ${` - ${user.locality}, ${user.country}`}
      ${user.phone ? ` - ${user.phone}` : ''}
      ${user.account && user.account.email ? `(${user.account.email})` : ''}
    `,
        value: user.id,
      }
    })

  searchUsers = (searchQuery) => {
    const { city, isHost, onUserChange } = this.props
    this.setState({ loadingUsers: true }, () => {
      if (!searchQuery || String(searchQuery).trim() === '') return
      const query = { search: searchQuery }

      if (isHost) {
        Object.assign(query, city ? { city, host: true } : { hostOnly: true })
      }
      businessApi.delayedGet({
        path: '/api/users/basic',
        config: { params: query },
        successCallback: ({ users }) => {
          this.setState({ users, loadingUsers: false }, onUserChange(users.find((u) => u.id === searchQuery)))
        },
      })
    })
  }

  render() {
    const { t, onUserChange, placeholder, inputLabel, isDisabled, user } = this.props
    const { users, loadingUsers } = this.state

    return (
      <Form.Group className='__item'>
        <Form.Field required width={13}>
          <label>{inputLabel}</label>
          <Form.Dropdown
            placeholder={placeholder}
            disabled={isDisabled}
            search
            fluid
            selection
            value={user && user.id}
            options={this.formatUsersOptions(users)}
            onChange={(e, { value }) => onUserChange(users.find((u) => u.id === value))}
            onSearchChange={(e, { searchQuery }) => this.searchUsers(searchQuery)}
            noResultsMessage={loadingUsers ? t('EventsCalendar::Loading...') : t('EventsCalendar::No results found.')}
          />
        </Form.Field>

        {user && (
          <Form.Button
            basic
            color='blue'
            type='button'
            onClick={() => connectAs(this.props.user.account_id)}
            disabled={!user}
            content={t('EventsCalendar::Connect As')}
            style={{ marginTop: 1.65 + 'em' }}
          />
        )}
      </Form.Group>
    )
  }
}

export const UserSection = withTranslation('common')(_UserSection)
