// Libs
import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
// Components
import { Button, Dimmer, Loader, Modal } from 'semantic-ui-react'
import { PartnerDemandForm } from './components/PartnerDemandForm'
// Redux
import { createPartnerDemand, fetchPartnerDemands } from 'redux/entities/actions'
import { creatingPartnerDemand, getPartnerDemandsError } from 'redux/entities/selectors'
// Hooks
import { useHasLoadingSucceeded } from 'hooks'

const mapStateToProps = (state) => ({
  fromStore: {
    isCreating: creatingPartnerDemand(state),
    partnerDemandError: getPartnerDemandsError(state),
  },
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    createPartnerDemand: (payload) => dispatch(createPartnerDemand(payload)),
    fetchPartnerDemands: () => dispatch(fetchPartnerDemands()),
  },
})

function _CreatePartnerDemand({
  actions: { createPartnerDemand, fetchPartnerDemands },
  fromStore: { isCreating, partnerDemandError },
}) {
  const [isOpen, setIsOpen] = useState(false)
  const isCreatingSucceeded = useHasLoadingSucceeded(isCreating, partnerDemandError)
  const [t] = useTranslation()

  useEffect(() => {
    if (isCreatingSucceeded) {
      toast.success(`${t('ToastNotification::The partner demand has been correctly created')} 👍`, {
        type: toast.TYPE.SUCCESS,
      })
      fetchPartnerDemands() // required to refetch because list is updated on metadata count and not demands in the store
      setIsOpen(false)
    }
  }, [fetchPartnerDemands, isCreatingSucceeded])

  function submitNewPartnerDemand(partnerDemand) {
    createPartnerDemand({
      age_interval: partnerDemand.ageInterval,
      begins_at: partnerDemand.beginsAt,
      country_iso: partnerDemand.countryIso,
      currency_id: partnerDemand.currencyId,
      dates: partnerDemand.selectedDays,
      event_type: partnerDemand.eventType,
      locality: partnerDemand.locality,
      partner_id: partnerDemand.partnerId,
      price: partnerDemand.price,
      reference_code_per_date: partnerDemand.referenceCodePerDate,
      seats: partnerDemand.seats,
      free_seats: partnerDemand.freeSeats,
      status: partnerDemand.status,
    })
  }

  return (
    <Fragment>
      <Button
        primary
        floated='right'
        icon='plus'
        style={{ margin: '16px 0px' }}
        content={t('PartnerDemands::Create new demand')}
        onClick={() => setIsOpen(true)}
      />
      <Modal open={isOpen} closeOnDimmerClick={false} closeIcon onClose={() => setIsOpen(false)}>
        <Dimmer active={isCreating} inverted>
          <Loader inverted content={t('Loading::Loading')} />
        </Dimmer>
        <Modal.Header>{t('PartnerDemands::Create new partner demand')}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <PartnerDemandForm
              formError={partnerDemandError}
              handleFormSubmission={submitNewPartnerDemand}
              isLoading={isCreating}
            />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </Fragment>
  )
}

_CreatePartnerDemand.propTypes = {
  actions: PropTypes.shape({
    createPartnerDemand: PropTypes.func.isRequired,
    fetchPartnerDemands: PropTypes.func,
  }).isRequired,
  fromStore: PropTypes.shape({
    isCreating: PropTypes.bool.isRequired,
    partnerDemandError: PropTypes.shape({
      data: PropTypes.shape({
        message: PropTypes.string,
        stack_trace: PropTypes.any,
      }),
      status: PropTypes.number,
      statusText: PropTypes.string,
    }),
  }).isRequired,
}

export const CreatePartnerDemand = connect(mapStateToProps, mapDispatchToProps)(_CreatePartnerDemand)
