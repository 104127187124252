// libs
import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
// helpers
import PropTypes from 'helpers/proptypes'
// components
import { Card, List } from 'semantic-ui-react'

export const PartnerCard = ({
  firstname,
  lastname,
  email,
  civility,
  description,
  billingInfos,
  language,
  currency,
  organization,
  phone,
  newsletter,
  tos,
}) => {
  const [t] = useTranslation()

  const itemContents = [
    { i18nKey: __('Partners::Summary::Name: <b>{{firstname}} {{lastname}}</b>'), values: { firstname, lastname } },
    { i18nKey: __('Partners::Summary::Email: <b>{{email}}</b>'), values: { email } },
    { i18nKey: __('Partners::Summary::Civility: <b>{{civility}}</b>'), values: { civility } },
    { i18nKey: __('Partners::Summary::Description: <b>{{description}}</b>'), values: { description } },
    { i18nKey: __('Partners::Summary::Billing Informations: <b>{{billingInfos}}</b>'), values: { billingInfos } },
    { i18nKey: __('Partners::Summary::Language: <b>{{language}}</b>'), values: { language } },
    { i18nKey: __('Partners::Summary::Currency: <b>{{currency}}</b>'), values: { currency } },
    { i18nKey: __('Partners::Summary::Phone: <b>{{phone}}</b>'), values: { phone } },
    { i18nKey: __('Partners::Summary::Organization: <b>{{organization}}</b>'), values: { organization } },
    {
      i18nKey: __('Partners::Summary::Newsletter subscription: <b>{{isSubscribed}}</b>'),
      values: { isSubscribed: newsletter ? t('Partners::Summary::yes') : t('Partners::Summary::no') },
    },
    {
      i18nKey: __('Partners::Summary::Terms of service validated: <b>{{tos}}</b>'),
      values: { tos: tos ? t('Partners::Summary::yes') : t('Partners::Summary::no') },
    },
  ]

  return (
    <Card>
      <Card.Content>
        <Card.Header>{t('Partners::Summary::Partner preview')}</Card.Header>
        <List bulleted>
          {itemContents.map(({ i18nKey, values }, i) => (
            <List.Item key={i}>
              <Trans i18nKey={i18nKey} values={values} components={{ b: <b /> }} />
            </List.Item>
          ))}
        </List>
      </Card.Content>
    </Card>
  )
}

PartnerCard.propTypes = {
  t: PropTypes.func.isRequired,
  firstname: PropTypes.string.isRequired,
  lastname: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  civility: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  billingInfos: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  organization: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  newsletter: PropTypes.bool.isRequired,
  tos: PropTypes.bool.isRequired,
}
