import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import './index.css'

const gifs = [
  'https://media.giphy.com/media/1YIAAENRqYZry/giphy.gif',
  'https://media.giphy.com/media/l0MYC0LajbaPoEADu/giphy.gif',
  'https://media.giphy.com/media/l2JHZ0dIcyFo5UQGQ/giphy.gif',
  'https://media.giphy.com/media/vFKqnCdLPNOKc/giphy.gif',
]

const Home = () => {
  const [t] = useTranslation()
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIndex((index + 1) % gifs.length)
    }, 2000)
    return () => clearTimeout(timer)
  }, [index, setIndex])

  return (
    <div className='App'>
      <p>{t("Home::Hello World... Click on 'User' to get started :-)")}</p>
      <p>{t('Home::More usefull stuff coming here soon...')}</p>
      <div className='welcome-gifs-container'>
        <img src={gifs[index]} />
      </div>
    </div>
  )
}

export default Home
