import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { omit } from 'lodash'
import { Input } from 'semantic-ui-react'

const mapStateToProps = (state) => ({
  location: state.getIn(['routing', 'locationBeforeTransitions']),
})

class _SearchInput extends PureComponent {
  static propTypes = {
    as: PropTypes.any,
    queryName: PropTypes.string.isRequired,
    preventNavigation: PropTypes.bool,

    action: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,

    location: PropTypes.object, // Immutable
    dispatch: PropTypes.func.isRequired,
  }

  static contextTypes = {
    router: PropTypes.object,
    store: PropTypes.object,
  }

  static defaultProps = {
    queryName: 'search',
  }

  UNSAFE_componentWillMount() {
    if (!this.props.value) {
      this.setState({
        value: this.props.location.getIn(['query', this.props.queryName]),
      })
    }
  }

  updateLocation = (value) => {
    this.context.router.push(this.props.location.mergeIn(['query'], { [this.props.queryName]: value }).toJS())
  }

  handleChange = (e) => {
    const value = e.target.value
    this.setState({ value })

    if (this.props.onChange) this.props.onChange(value)
  }

  handleActionClick = (e) => {
    // Called only if not used in a search form. No need to use this.props.value here
    if (!this.props.action || e.target.tagName !== 'BUTTON') return
    this.updateLocation(this.state.value)
  }

  render() {
    return (
      <Input
        {...omit(this.props, Object.keys(_SearchInput.propTypes))}
        as={this.props.as}
        action={this.props.action}
        value={this.props.value !== undefined ? this.props.value : this.state.value}
        onChange={this.handleChange}
        onClick={this.handleActionClick}
      />
    )
  }
}

export const SearchInput = connect(mapStateToProps)(_SearchInput)
