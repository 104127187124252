import { schema } from 'normalizr'
import { Record, List, Map } from 'immutable'
import { amenities } from './statics'

const _schema = new schema.Entity('places', {
  amenities: [amenities.schema],
})

const _Record = Record({
  id: undefined,
  user_id: undefined,
  additional_info: undefined,
  administrative_area_level_1: undefined,
  administrative_area_level_2: undefined,
  address: undefined,
  formatted: undefined,
  provider: undefined,
  provider_id: undefined,
  amenities: new List(),
  amenities_ids: new List(),
  coordinates: new Map({
    latitude: undefined,
    longitude: undefined,
    zoom: undefined,
    radius: undefined,
  }),
  lat: undefined,
  lng: undefined,
  country: undefined,
  country_iso: undefined,
  locality: undefined,
  postal_code: undefined,
  timezone: undefined,
})

export const places = {
  schema: _schema,
  Record: _Record,
}
