import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Form, Button } from 'semantic-ui-react'
import { SelectInput } from 'components/inputs'
import { getTranslatedOptions } from 'helpers/options'
import { mealTypesOptions } from 'helpers/events'
import { buildOptionsWithArray } from 'helpers/forms'
import { getSortedTags, fetchingEvents } from 'redux/entities/selectors'

export const DEFAULT_QUERY = {
  search: '',
  host: '',
  instantbooking: '',
  location: '',
  minGuests: undefined,
  maxGuests: undefined,
  tags: [],
  type: [],
  status: 'approved',
  isFrozen: 0,
  published: 1,
}

export function EventsSearchForm({ onClearFilters, onSearch }) {
  const { t } = useTranslation()
  const sortedTags = useSelector(getSortedTags)
  const isFetchingExperiences = useSelector(fetchingEvents)
  const tagOptions = useMemo(() => buildOptionsWithArray(sortedTags.map((t) => t.title)), [sortedTags])
  const experienceTypeOptions = useMemo(() => getTranslatedOptions(t, mealTypesOptions), [t])
  const instantBookingOptions = useMemo(
    () => [
      { text: t('Experiences::InstantBookingOptions::All'), value: '' },
      { text: t('Experiences::InstantBookingOptions::Enabled'), value: true },
      { text: t('Experiences::InstantBookingOptions::Disabled'), value: false },
    ],
    [t],
  )
  const [query, setQuery] = useState(DEFAULT_QUERY)

  const areThereSelectedFilters = useMemo(() => {
    const { status, isFrozen, published, ...rest } = query
    return (
      Object.values({ ...rest, instantbooking: String(rest.instantbooking) })
        .flat()
        .filter((qp) => !!qp).length === 0
    )
  }, [query])

  function handleChange(_, { name, value }) {
    setQuery({ ...query, [name]: value })
  }

  function handleSubmit() {
    const { tags, type, instantbooking, ...rest } = query
    onSearch({
      ...(tags.length > 0 && { tags: tags.join(',') }),
      ...(type.length > 0 && { type: type.join(',') }),
      ...(typeof query.instantbooking === 'boolean' && { instantbooking: Number(query.instantbooking) }),
      ...Object.entries(rest).reduce((acc, [key, value]) => ({ ...acc, ...(!!value && { [key]: value }) }), {}),
    })
  }

  function handleClearFilter() {
    setQuery(DEFAULT_QUERY)
    onClearFilters()
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group widths='equal'>
        <Form.Field>
          <label>{t('Playlists::Summary::Search by experience')}</label>
          <Form.Input
            name='search'
            icon='search'
            iconPosition='left'
            placeholder={t('Playlists::Summary::id or title')}
            value={query.search}
            onChange={handleChange}
          />
        </Form.Field>

        <Form.Field>
          <label>{t('Playlists::Summary::Search by host')}</label>
          <Form.Input
            name='host'
            icon='search'
            iconPosition='left'
            placeholder={t('Playlists::Summary::id, first/last name, email')}
            value={query.host}
            onChange={handleChange}
          />
        </Form.Field>

        <Form.Field>
          <label>{t('Playlists::Summary::Experience type')}</label>
          <SelectInput
            queryName='type'
            options={experienceTypeOptions}
            value={query.type.join(',')}
            onChange={(value) => handleChange(null, { name: 'type', value })}
            multiple
            search
            preventNavigation
          />
        </Form.Field>

        <Form.Field>
          <label>{t('Playlists::Summary::Experience tags')}</label>
          <SelectInput
            queryName='tags'
            options={tagOptions}
            value={query.tags.join(',')}
            onChange={(value) => handleChange(null, { name: 'tags', value })}
            multiple
            search
            preventNavigation
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field>
          <label>{t('Playlists::Summary::Location')}</label>
          <Form.Input
            name='location'
            icon='search'
            iconPosition='left'
            placeholder={t('Playlists::Summary::Address, city, country (iso), postal code')}
            value={query.location}
            onChange={handleChange}
          />
        </Form.Field>

        <Form.Field>
          <label>{t('Playlists::Summary::Min Guest')}</label>
          <Form.Input
            name='minGuests'
            icon='search'
            iconPosition='left'
            type='number'
            placeholder={t('Playlists::Summary::Min Guest')}
            value={query.minGuests || ''}
            min={0}
            onChange={handleChange}
          />
        </Form.Field>

        <Form.Field>
          <label>{t('Playlists::Summary::Max Guest')}</label>
          <Form.Input
            name='maxGuests'
            icon='search'
            iconPosition='left'
            type='number'
            placeholder={t('Playlists::Summary::Max Guest')}
            value={query.maxGuests || ''}
            min={0}
            onChange={handleChange}
          />
        </Form.Field>

        <Form.Field>
          <label>{t('Playlists::Summary::Instant bookings')}</label>
          <Form.Select
            name='instantbooking'
            value={query.instantbooking}
            onChange={handleChange}
            options={instantBookingOptions}
            fluid
            search
          />
        </Form.Field>
      </Form.Group>
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <Form.Field style={{ display: 'inline-block' }}>
          <Button type='submit' loading={isFetchingExperiences} disabled={areThereSelectedFilters}>
            {t('Playlists::Summary::Search')}
          </Button>
        </Form.Field>

        <Form.Field style={{ display: 'inline-block' }}>
          <Button type='button' basic onClick={handleClearFilter} disabled={areThereSelectedFilters}>
            {t('Playlists::Summary::Clear filters')}
          </Button>
        </Form.Field>
      </div>
    </Form>
  )
}

EventsSearchForm.propTypes = {
  onClearFilters: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
}
