import React, { PureComponent } from 'react'
import { List } from 'semantic-ui-react'
import { Link } from 'react-router'
import { getUser } from 'redux/entities/selectors'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const mapStateToProps = (state, props) => ({
  getUser: (id) => getUser(state, { id }),
})

export class _HostList extends PureComponent {
  static propTypes = {
    hosts: PropTypes.PropTypes.shape({
      id: PropTypes.number,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      email: PropTypes.string,
    }),
    getUser: PropTypes.func.isRequired,
  }

  static defaultProps = {
    hosts: undefined,
  }

  render () {
    const { hosts } = this.props
    return (
      <div>
        {hosts.size > 0 && (
          <List>
            {hosts.map((userId, i) => {
              const user = this.props.getUser(userId)
              return (
                <List.Item key={i}>
                  <Link to={`/users/${user.id}`}>{user.id}</Link>
                  {` - ${user.firstname} ${user.lastname}`}
                </List.Item>
              )
            })}
          </List>
        )}
      </div>
    )
  }
}

export const HostList = connect(mapStateToProps)(_HostList)
