// libs
import React, { useEffect } from 'react'
import PropTypes from 'helpers/proptypes'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import moment from 'moment'
import { List } from 'immutable'
// Hooks
import { useHasLoadingSucceeded } from 'hooks'
// Components
import { Link } from 'react-router'
import { Grid, Loader, Segment, Image, Button, Label, Message } from 'semantic-ui-react'
import { UserCard } from 'components/cards/UserCard'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { Rating } from '@vizeat/components/es6/components/Rating'
import { ReviewCriteria } from 'components/reviews/ReviewCriteria'
import { ReviewPublicationModal } from 'components/modals/ReviewPublicationModal'
// Redux
import { connectAs } from 'helpers/users'
import { fetchReview, fetchUser, deleteFile } from 'redux/entities/actions'
import {
  getReview,
  fetchingReviews,
  getReviewError,
  getUser,
  getReviewFiles,
  deleteFileError,
  deletingFile,
} from 'redux/entities/selectors'
// Helpers
import { scaleCropFileUrl } from 'helpers/files'

const publishedText = __('Reviews::Summary::Published')
const unpublishedText = __('Reviews::Summary::Unpublished')

function ReviewPhotos({ file, review }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const error = useSelector((state) => deleteFileError(state, file.id))
  const isDeletingFile = useSelector((state) => deletingFile(state, file.id))
  const hasPhotoBeenUnpublished = useHasLoadingSucceeded(isDeletingFile, error)

  useEffect(() => {
    if (hasPhotoBeenUnpublished) dispatch(fetchReview(review.id))
  }, [dispatch, hasPhotoBeenUnpublished, review.id])

  return (
    <React.Fragment>
      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        <Label circular empty color={file.deleted_at ? 'red' : 'green'} />
        <b>{file.deleted_at ? t(unpublishedText) : t(publishedText)}</b>
      </div>
      <Image src={scaleCropFileUrl(file, '300x200')} />
      <br />
      {!file.deleted_at && (
        <Button primary basic onClick={() => dispatch(deleteFile(file.id))}>
          {t('Reviews::Summary::Unpublish')}
        </Button>
      )}
    </React.Fragment>
  )
}

ReviewPhotos.propTypes = {
  file: PropTypes.immutable.record.isRequired,
  review: PropTypes.immutable.record.isRequired,
}

function ReviewDetail({ params }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const error = useSelector((state) => getReviewError(state, params.id))
  const isLoadingReview = useSelector(fetchingReviews)
  const review = useSelector((state) => getReview(state, { id: params.id }))
  const reviewer = useSelector((state) => getUser(state, { id: review.user.id }))
  const files = useSelector((state) => getReviewFiles(state, { id: params.id }))

  const bookingId = review.getIn(['booking', 'id'])
  const eventId = review.getIn(['booking', 'event', 'id'])
  const eventTitle = review.getIn(['booking', 'event', 'title'])
  const eventDate = review.getIn(['booking', 'date'])
  const reviewDate = moment(review.updated_at || review.created_at).format('lll')

  useEffect(() => {
    if (!review.id) dispatch(fetchReview(params.id))
  }, [dispatch, params.id, review.id])

  useEffect(() => {
    // fetch user data to be displayed in the user card
    if (review.user.id) dispatch(fetchUser(review.user.id))
  }, [dispatch, review.user.id])

  if (isLoadingReview)
    return (
      <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <Loader />
      </div>
    )

  if (error) return <ApiErrorMessage error={error} modal />

  return (
    <div style={{ width: '60vw', margin: 'auto' }}>
      <Grid>
        <Grid.Row>
          <Grid.Column width={5}>
            {reviewer.id && (
              <UserCard user={reviewer} connectAs={() => connectAs(reviewer.account_id)} showUserDetails />
            )}

            <Segment>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '16px' }}>
                <span>
                  <Trans
                    i18nKey={__('Reviews::Summary::Status: <b>{{status}}</b>')}
                    values={{ status: review.hidden_at ? t(unpublishedText) : t(publishedText) }}
                    components={{ b: <b /> }}
                  />
                </span>
                <ReviewPublicationModal review={review} />
              </div>
            </Segment>
          </Grid.Column>

          <Grid.Column width={11}>
            {review.offline && (
              <Message info>
                <Message.Header>{t('Reviews::Offline review')}</Message.Header>
              </Message>
            )}

            <Segment>
              <h2>{t('Reviews::Summary::Event details')}</h2>
              <Grid>
                {(bookingId || eventId) && (
                  <Grid.Row>
                    {bookingId && (
                      <Grid.Column width={7}>
                        <label>
                          <Trans
                            i18nKey={__('Reviews::Summary::Booking: <bookingURL>{{bookingId}}</bookingURL>')}
                            values={{ bookingId }}
                            components={{
                              bookingURL: <Link to={`../bookings/${bookingId}`} />,
                            }}
                          />
                        </label>
                      </Grid.Column>
                    )}
                    {eventId && (
                      <Grid.Column width={7}>
                        <label>
                          <Trans
                            i18nKey={__('Reviews::Summary::Event: <eventURL>{{eventId}} - {{eventTitle}}</eventURL>')}
                            values={{ eventId, eventTitle }}
                            components={{
                              eventURL: <Link to={`../events/${eventId}`} />,
                            }}
                          />
                        </label>
                      </Grid.Column>
                    )}
                  </Grid.Row>
                )}
                <Grid.Row>
                  {eventDate && (
                    <Grid.Column width={7}>
                      <label>
                        <Trans
                          i18nKey={__('Reviews::Summary::Event date: <b>{{eventDate}}</b>')}
                          values={{ eventDate: moment(eventDate).format('lll') }}
                          components={{ b: <b /> }}
                        />
                      </label>
                    </Grid.Column>
                  )}
                  <Grid.Column width={7}>
                    <label>
                      <Trans
                        i18nKey={__('Reviews::Summary::Review date: <b>{{reviewDate}}</b>')}
                        values={{ reviewDate }}
                        components={{ b: <b /> }}
                      />
                    </label>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>

            <Segment>
              <h2>{t('Reviews::Summary::Review Rating')}</h2>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Trans
                  i18nKey={__('Reviews::Summary::Rating: <b>{{score}}</b>')}
                  values={{ score: review.score }}
                  components={{ b: <b /> }}
                />
                <Rating score={review.score} ml='4px' />
              </div>
              <ReviewCriteria ratings={new List(review.ratings)} />

              <h2>{t('Reviews::Summary::Comment')}</h2>
              <label>{review.body}</label>
            </Segment>

            {files.size > 0 && (
              <Segment>
                <h2>{t('Reviews::Summary::Photos')}</h2>
                <Grid>
                  <Grid.Row>
                    {files.map((file) => (
                      <Grid.Column width={4} key={file.id} textAlign='center'>
                        <ReviewPhotos file={file} review={review} />
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                </Grid>
              </Segment>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

ReviewDetail.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
}

export default ReviewDetail
