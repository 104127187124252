import { getUser } from './users'
import { getCurrency } from './statics'
import {
  getEntities,
  getError,
  isFetching,
  getEntity,
  getEntityCount,
  getEntityOrder,
  getEntityTotal,
  isUpdating,
} from './_utils'

export const getPayouts = getEntities('payouts')
export const getPayoutsCount = getEntityCount('payouts')
export const getPayoutsTotal = getEntityTotal('payouts')
export const getPayoutsOrder = getEntityOrder('payouts')

export function getPayout (state, { id }) {
  const payout = getEntity('payouts')(state, { id })
  return payout
    .set('user', getUser(state, { id: payout.user_id || payout.user }))
    .set('currency', getCurrency(state, { id: payout.currency_id || payout.currency }))
}

export const getPayoutsList = (state) => getPayoutsOrder(state).map((id) => getPayout(state, { id }))

export const fetchingPayouts = isFetching('/api/payouts')
export const getPayoutsError = getError('/api/payouts')
export const getPayoutError = (state, id) => getError(`/api/payouts/${id}`)(state)

export const updatingPayout = (state, id) => isUpdating(`/api/payouts/${id}`)(state)

export const getBatchedPayouts = getEntities('batchedPayouts')
