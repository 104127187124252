import React from 'react'
import { Label } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { getColorFromStatus } from 'helpers/hostApplications'
import { upperFirst } from 'lodash'

export const HostApplicationStatus = ({ status, completionRate }) => {
  if (!status) return null

  return <Label color={getColorFromStatus(status)}>{upperFirst(status)} {status === 'draft' && <Label.Detail>{completionRate}%</Label.Detail>}</Label>
}

HostApplicationStatus.propTypes = {
  status: PropTypes.string.isRequired,
  completionRate: PropTypes.number,
}

HostApplicationStatus.defaultProps = {
  completionRate: 0,
}
