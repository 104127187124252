import React from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from 'semantic-ui-react'

export function Toolbar() {
  const [t] = useTranslation()
  return (
    <div className='Toolbar'>
      <div>
        <Icon circular inverted color='red' name='lock' size='small' />
        <span>{t('EventsCalendar::Private')}</span>
      </div>
      <div>
        <Icon circular inverted color='green' name='lightning' size='small' />
        <span>{t('EventsCalendar::Instant')}</span>
      </div>
      <div>
        <Icon circular inverted color='yellow' name='plane' size='small' />
        <span>{t('EventsCalendar::Demo')}</span>
      </div>
      <div>
        <Icon circular inverted color='purple' name='asterisk' size='small' />
        <span>{t('EventsCalendar::Frozen')}</span>
      </div>
      <div>
        <Icon circular inverted color='orange' name='group' size='small' />
        <span>{t('EventsCalendar::Partner Demand')}</span>
      </div>
      <div className='backgroundLabel' style={{ backgroundColor: '#808080' }}>
        {t('EventsCalendar::No bookings')}
      </div>
      <div className='backgroundLabel' style={{ backgroundColor: '#FDC67B' }}>
        {t('EventsCalendar::PE without booking')}
      </div>
      <div className='backgroundLabel' style={{ backgroundColor: '#FC9306' }}>
        {t('EventsCalendar::Pending request/bookings')}
      </div>
      <div className='backgroundLabel' style={{ backgroundColor: '#25ABF2' }}>
        {t('EventsCalendar::Below min seats')}
      </div>
      <div className='backgroundLabel' style={{ backgroundColor: '#4588DD' }}>
        {t('EventsCalendar::Above min seats')}
      </div>
      <div className='backgroundLabel' style={{ backgroundColor: '#8EA3A6' }}>
        {t('EventsCalendar::Sold out')}
      </div>
    </div>
  )
}
