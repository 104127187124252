import { getEntities, isFetching, getError, getEntity, getEntityCount, getEntityTotal, getEntityOrder } from './_utils'
import { getBooking } from './bookings'
import { getReply } from './replies'
import { getUser } from './users'
import { getFollowup } from './followups'

export const getConversations = getEntities('conversations')
export const getConversationsCount = getEntityCount('conversations')
export const getConversationsTotal = getEntityTotal('conversations')
export const getConversationsOrder = getEntityOrder('conversations')
export const getConversation = (state, { id }) => {
  const conversation = getEntity('conversations')(state, { id })
  return conversation
    .set('booking', getBooking(state, { id: conversation.booking_id || conversation.booking }))
    .set('recipient', getUser(state, { id: conversation.recipient_id || conversation.recipient }))
    .set('sender', getUser(state, { id: conversation.sender_id || conversation.sender }))
    .set('recipient_first_reply', getReply(state, { id: conversation.recipient_first_reply }))
    .set('sender_first_reply', getReply(state, { id: conversation.sender_first_reply }))
    .set('replies', conversation.replies ? conversation.replies.map((id) => getReply(state, { id })) : null)
    .set('followups', conversation.followups ? conversation.followups.map((id) => getFollowup(state, { id })) : null)
}

export const getConversationsList = (state) => getConversationsOrder(state).map((id) => getConversation(state, { id }))

export const fetchingConversation = (state, id) => isFetching(`/api/conversations/${id}`)(state)
export const fetchingConversations = isFetching('/api/conversations')
export const getConversationsError = getError('/api/conversations')
