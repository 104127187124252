import { schema } from 'normalizr'
import { Record, List, Map } from 'immutable'
import { users } from './users'
import { places } from './places'
import { events } from './events'

const _schema = new schema.Entity(
  'hostApplications',
  {
    user: users.schema,
    place: places.schema,
    event: events.schema,
  },
  {
    processStrategy: (entity) => {
      if (entity.sample_experience && entity.sample_experience.min_price && !entity.sample_experience.price) {
        return {
          ...entity,
          sample_experience: {
            ...entity.sample_experience,
            price: entity.sample_experience.min_price,
            is_price_flexible: true,
          },
        }
      }
      return entity
    },
  },
)

const _Record = Record({
  id: undefined,
  toReviewOrder: undefined,
  host: Map({
    culinary_experience: undefined,
    spoken_languages: List([{}]),
    motivation: undefined,
    online_profiles: new Map(),
    assets: List(),
    description: undefined,
    interested_in_experience: new Map(),
    availabilities: undefined,
    host_name: undefined,
  }),
  sample_experience: Map({
    type: List(),
    place: new Map(),
    description: undefined,
    cooking_class: undefined,
    duration: undefined,
    min_seats: undefined,
    max_seats: undefined,
    guest_type: List(),
    user_video: new Map(),
    price: undefined,
    is_price_flexible: undefined,
    policy: undefined,
  }),
  admin: Map({
    assigned_cm: undefined,
    application_rate: undefined,
    followups: List(),
  }),
  user: new users.Record(),
  place: new places.Record(),
  event: new events.Record(),
  metadata: Map({
    version: undefined,
    affid: undefined,
    referrer: undefined,
    completion_rate: undefined,
    created_at: undefined,
    updated_at: undefined,
    submitted_at: undefined,
  }),
})

export const hostApplications = {
  schema: _schema,
  Record: _Record,
}
