// libs
import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { get, omitBy } from 'lodash'
import { withTranslation } from 'react-i18next'

// components
import EditPageMenu from 'components/menus/EditPageMenu'
import { PartnerForm } from './PartnerForm'
import { PartnerCard } from './PartnerCard'
import { Grid, Rail, Segment } from 'semantic-ui-react'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { OrganizationFormWrapper } from 'pages/organizations/edit'
import { FormModal } from 'components/modals'
import { toast } from 'react-toastify'

// helpers
import { isInvalid } from 'helpers/forms'

// redux
import { fetchPartner, fetchOrganizations, updatePartner, createPartner } from 'redux/entities/actions'
import {
  getPartner,
  getLanguages,
  getOrganizations,
  getCurrencies,
  getUpdatingPartnerError,
  getCreatingPartnerError,
  getLanguage,
  getCurrency,
  updatingPartner,
  creatingPartner,
} from 'redux/entities/selectors'

const mapStateToProps = (state, props) => ({
  fromStore: {
    partner: props.params && getPartner(state, { id: props.params.id }),
    languages: getLanguages(state),
    organizations: getOrganizations(state),
    currencies: getCurrencies(state),
    error:
      props.params && props.params.id
        ? getUpdatingPartnerError(state, props.params.id)
        : getCreatingPartnerError(state),
    isSubmitting: props.params && props.params.id ? updatingPartner(state, props.params.id) : creatingPartner(state),
    getLanguage: (id) => getLanguage(state, { id }),
    getCurrency: (id) => getCurrency(state, { id }),
  },
})

const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    fetchPartner: (id) => dispatch(fetchPartner(id)),
    fetchOrganizations: () => dispatch(fetchOrganizations({ query: { all: true } })),
    updatePartner: (id, payload) => dispatch(updatePartner(id, payload)),
    createPartner: (payload) => dispatch(createPartner(payload)),
  },
})

export class _PartnerFormWrapper extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fromStore: PropTypes.shape({
      partner: PropTypes.object,
      error: PropTypes.error,
      languages: PropTypes.immutable.map,
      organizations: PropTypes.immutable.map,
      currencies: PropTypes.immutable.map,
      getLanguage: PropTypes.func,
      getCurrency: PropTypes.func,
      isSubmitting: PropTypes.bool,
    }).isRequired,
    actions: PropTypes.shape({
      fetchPartner: PropTypes.func,
      fetchOrganizations: PropTypes.func,
      updatePartner: PropTypes.func,
      createPartner: PropTypes.func,
    }).isRequired,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    hideMenu: PropTypes.bool,
    hideCard: PropTypes.bool,
  }

  static defaultProps = {
    params: undefined,
    hideMenu: false,
    hideCard: false,
  }

  getMenuItems = (t) => [
    { text: t('Partners::Summary::Firstname'), value: 'firstname' },
    { text: t('Partners::Summary::Lastname'), value: 'lastname' },
    { text: t('Partners::Summary::Email'), value: 'email' },
    { text: t('Partners::Summary::Civility'), value: 'civility' },
    { text: t('Partners::Summary::Password'), value: 'password' },
    { text: t('Partners::Summary::Organization'), value: 'organization' },
    { text: t('Partners::Summary::Description'), value: 'description' },
    { text: t('Partners::Summary::Billing informations'), value: 'billing_infos' },
    { text: t('Partners::Summary::Language'), value: 'language' },
    { text: t('Partners::Summary::Currency'), value: 'currency' },
    { text: t('Partners::Summary::Phone'), value: 'phone' },
    { text: t('Partners::Summary::Newsletter'), value: 'newsletter' },
    { text: t('Partners::Summary::Terms of services'), value: 'tos' },
  ]

  getBillingInfos = (billingInfos, firstname, lastname, email) => billingInfos || `${firstname} ${lastname} - ${email}`

  getOrganization = () => {
    return get(this.props.fromStore.organizations.values().next().value, 'name', '')
  }

  state = {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    desiredOrganization: '',
    civility: '',
    description: '',
    billingInfos: this.getBillingInfos(undefined, '', '', ''),
    language: get(this.props.fromStore.getLanguage(), 'title', ''),
    currency: get(this.props.fromStore.getCurrency(), 'iso_3', ''),
    organization: '',
    phone: '',
    newsletter: true,
    tos: true,
    isOrganizationModalOpen: false,
    hideMenu: false,
    hideCard: false,
  }

  componentDidMount() {
    const { params, actions } = this.props

    actions.fetchOrganizations()

    if (params && params.id) {
      actions.fetchPartner(params.id)
    }
  }

  componentDidUpdate(prevProps) {
    const { organization, submitted } = this.state
    const { t, fromStore } = this.props

    if (organization === '' && fromStore.organizations && fromStore.organizations.size !== 0) {
      const organization =
        get(fromStore.partner, ['organization', 'name'], this.state.organization) || this.getOrganization()
      this.setState({
        organization,
        description: organization,
      })
    }

    if (fromStore.partner && prevProps.fromStore.partner !== fromStore.partner) {
      this.setState({
        isEditing: this.props.params && !!this.props.params.id,
        firstname: get(fromStore.partner, ['user', 'firstname'], this.state.firstname),
        lastname: get(fromStore.partner, ['user', 'lastname'], this.state.lastname),
        email: get(fromStore.partner, ['account', 'email'], this.state.email),
        civility: get(fromStore.partner, ['user', 'civility'], this.state.civility),
        desiredOrganization: get(fromStore.partner, 'desired_organization', this.state.desiredOrganization),
        description:
          get(fromStore.partner, 'description', this.state.description) ||
          get(fromStore.partner, ['organization', 'name'], this.state.organization),
        billingInfos: this.getBillingInfos(
          get(fromStore.partner, 'billing_infos', this.state.billingInfos),
          get(fromStore.partner, ['user', 'firstname'], this.state.firstname),
          get(fromStore.partner, ['user', 'lastname'], this.state.lastname),
          get(fromStore.partner, ['account', 'email'], this.state.email),
        ),
        language: get(fromStore.partner, ['account', 'language', 'title'], this.state.language),
        currency: get(fromStore.partner, ['currency', 'iso_3'], this.state.currency),
        phone: get(fromStore.partner, 'phone', this.state.phone),
        newsletter: get(fromStore.partner, 'newsletter', this.state.newsletter),
        tos: get(fromStore.partner, 'tos', this.state.tos),
      })
    }

    if (submitted && prevProps.fromStore.isSubmitting && !fromStore.isSubmitting && !fromStore.error) {
      toast.success(
        this.state.isEditing
          ? t('ToastNotification::Summary::The partner {{partnerFirstName}} {{partnerLastName}} has been updated', {
              partnerFirstName: this.state.firstname,
              partnerLastName: this.state.lastname,
            })
          : t("ToastNotification::Summary::Well done! You've just add a new partner"),
      )
      this.setState({ submitted: false })
    }
  }

  handleChange = (data) => {
    this.setState({
      ...this.state.form,
      ...data,
    })
  }

  extractIdFromMap = (map, attributeName, filter) => {
    const filteredObject = map
      .filter((item) => item[attributeName] === filter)
      .values()
      .next().value

    return filteredObject ? filteredObject.id : undefined
  }

  handleSubmit = async (e) => {
    if (e) e.preventDefault()
    const { actions, params, fromStore } = this.props
    const {
      firstname,
      lastname,
      email,
      password,
      civility,
      description,
      billingInfos,
      language,
      currency,
      organization,
      phone,
      tos,
      isEditing,
      newsletter,
    } = this.state

    this.setState({ error: undefined, submitted: true })

    const partnerPayload = omitBy(
      {
        firstname,
        lastname,
        email,
        password,
        civility,
        description,
        billing_infos: billingInfos,
        language_id: this.extractIdFromMap(fromStore.languages, 'title', language),
        currency_id: this.extractIdFromMap(fromStore.currencies, 'iso_3', currency),
        organization_id: this.extractIdFromMap(fromStore.organizations, 'name', organization),
        phone,
        tos,
        newsletter,
      },
      isInvalid,
    )

    if (isEditing) {
      await actions.updatePartner(params.id, partnerPayload)
    } else {
      await actions.createPartner(partnerPayload)
    }
  }

  getColumnNumber = () => {
    const { hideCard, hideMenu } = this.props
    switch (true) {
      case hideMenu && hideCard:
        return 1
      case (hideMenu && !hideCard) || (!hideMenu && hideCard):
        return 2
      default:
        return 3
    }
  }

  onSumbitOrganization = (organizationName) =>
    this.setState(
      { isOrganizationModalOpen: false, organization: organizationName, description: organizationName },
      () => this.props.actions.fetchOrganizations(),
    )

  render() {
    const { t, fromStore, hideMenu, hideCard } = this.props

    const {
      firstname,
      lastname,
      email,
      password,
      civility,
      description,
      billingInfos,
      desiredOrganization,
      phone,
      newsletter,
      tos,
      language,
      currency,
      organization,
    } = this.state

    return (
      <Fragment>
        <Grid centered columns={this.getColumnNumber()}>
          <Grid.Column>
            <Segment basic>
              {!hideMenu && (
                <Rail position='left'>
                  <EditPageMenu listName='partners' menuItems={this.getMenuItems(t)} />
                </Rail>
              )}

              <PartnerForm
                firstname={firstname}
                lastname={lastname}
                email={email}
                password={password}
                civility={civility}
                description={description}
                billingInfos={billingInfos}
                language={language}
                currency={currency}
                desiredOrganization={desiredOrganization}
                organization={organization}
                languages={fromStore.languages}
                organizations={fromStore.organizations}
                currencies={fromStore.currencies}
                phone={phone}
                newsletter={newsletter}
                tos={tos}
                handleChange={this.handleChange}
                handleSubmit={(e) => this.handleSubmit(e)}
                handleAddOrganization={() => this.setState({ isOrganizationModalOpen: true })}
              />

              {!hideCard && (
                <Rail position='right'>
                  <PartnerCard
                    firstname={firstname}
                    lastname={lastname}
                    email={email}
                    civility={civility}
                    description={description}
                    billingInfos={billingInfos}
                    phone={phone}
                    newsletter={newsletter}
                    tos={tos}
                    language={language}
                    currency={currency}
                    organization={organization}
                  />
                </Rail>
              )}
            </Segment>
          </Grid.Column>
        </Grid>

        <ApiErrorMessage error={fromStore.error} modal />

        <FormModal
          openModal={() => this.setState({ isOrganizationModalOpen: true })}
          closeModal={() => this.setState({ isOrganizationModalOpen: false })}
          isOpen={this.state.isOrganizationModalOpen}
          headerTitle={t('Partners::Summary::Add new Organization')}
        >
          <OrganizationFormWrapper
            hideMenu
            hideCard
            closeModal={() => this.setState({ isOrganizationModalOpen: false })}
            onSubmit={this.onSumbitOrganization}
            btnSubmitTitle={t('Partners::Summary::Schedule an event')}
          />
        </FormModal>
      </Fragment>
    )
  }
}

export const PartnerFormWrapper = withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(_PartnerFormWrapper),
)
