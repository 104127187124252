// Libraries
import React, { PureComponent } from 'react'
// Components
import { RequestSearchForm } from './RequestSearchForm'
import { RequestList } from './RequestList'

export default class BookingIndex extends PureComponent {
  render () {
    return (
      <div>
        <RequestSearchForm />
        <RequestList />
      </div>
    )
  }
}
