/**
 * CONVENTION
 * code are formatted prefixed with impacted resource or context
 * followed by a short sentence describing the error
 * ex: BOOKING_INVALID_DATE
 */
export const API_ERROR_CODES = {
  STRIPE_CHARGES_DISABLED: 'STRIPE_CHARGES_DISABLED',
  USER_BIRTH_DATE_NOT_PROVIDED: 'USER_BIRTH_DATE_NOT_PROVIDED',
  USER_BIRTH_DATE_INVALID: 'USER_BIRTH_DATE_INVALID',
  USER_UNDERAGED: 'USER_UNDERAGED',
  USER_REAUTHENTICATION_REQUIRED: 'USER_REAUTHENTICATION_REQUIRED',
  BOOKING_ON_DAY_NOT_SCHEDULED: 'BOOKING_ON_DAY_NOT_SCHEDULED',
  DATE_ALREADY_PRIVATIZED: 'DATE_ALREADY_PRIVATIZED',
  DATE_ALREADY_BOOKED: 'DATE_ALREADY_BOOKED',
  PENDING_PRIVATE_BOOKINGS_ON_DATE: 'PENDING_PRIVATE_BOOKINGS_ON_DATE',
}
