// Libs
import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { isArray, isEmpty, omit } from 'lodash'
import { withTranslation } from 'react-i18next'
// Components
import { Dropdown } from 'semantic-ui-react'

const mapStateToProps = (state) => ({
  location: state.getIn(['routing', 'locationBeforeTransitions']),
})

/**
 * SelectInput works two ways => alone or in a search form
 * Alone, it will update itself the query if passed a queryName
 * In a form, it is used as a controlled input by providing props 'value' and 'onChange'
 */

class _SelectInput extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    queryName: PropTypes.string,
    preventNavigation: PropTypes.bool,
    value: PropTypes.string, // Same as the value in querystring
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    options: PropTypes.array,
    multiple: PropTypes.bool,
    search: PropTypes.bool,
    location: PropTypes.immutable.map.isRequired, // Immutable
  }

  static contextTypes = {
    router: PropTypes.object,
    store: PropTypes.object,
  }

  static defaultProps = {
    queryName: 'select',
    multiple: false,
    search: false,
    preventNavigation: false,
    onChange: undefined,
    onClose: undefined,
    value: '',
    options: [],
  }

  UNSAFE_componentWillMount() {
    this.setState({ value: this.getCurrentQuery() })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentValue =
      this.props.value !== undefined
        ? this.props.value || ''
        : this.props.location.getIn(['query', this.props.queryName], '')

    const nextValue =
      nextProps.value !== undefined
        ? nextProps.value || ''
        : nextProps.location.getIn(['query', nextProps.queryName], '')

    if (currentValue !== nextValue) {
      return this.setState(
        this.props.multiple
          ? { value: !isEmpty(nextValue) && nextValue[0].length > 0 ? nextValue.split(',') : [] }
          : { value: nextValue },
      )
    }
  }

  updateLocation = (value) => {
    this.context.router.push(this.props.location.mergeIn(['query'], { [this.props.queryName]: value }).toJS())
  }

  getCurrentQuery() {
    const query = this.props.value || this.props.location.getIn(['query', this.props.queryName])
    if (!query || query.length === 0 || isEmpty(query) || (isArray(query) && query[0].length === 0)) {
      return !this.props.multiple ? '' : []
    } else return !this.props.multiple ? query : query.split(',')
  }

  handleChange = (e, input) => {
    const { preventNavigation, onChange, multiple } = this.props
    const values = !multiple ? input.value : input.value.filter((v) => !!v)

    // Force search when removing element
    if (multiple && !preventNavigation && values.length < this.state.value.length) this.updateLocation(values.join(','))

    this.setState({ value: values })
    if (onChange) onChange(values)

    if (!preventNavigation && !multiple) this.updateLocation(values)
  }

  handleClose = (e, input) => {
    const { preventNavigation, onClose, multiple } = this.props
    if (!preventNavigation && multiple && input.value) this.updateLocation(input.value.filter((v) => !!v).join(','))
    if (onClose) onClose(e, input)
  }

  render() {
    const { t, queryName, options, multiple, search } = this.props
    return (
      <Dropdown
        {...omit(this.props, Object.keys(_SelectInput.propTypes))}
        autoComplete='off'
        name={queryName}
        multiple={multiple}
        closeOnBlur
        selection
        value={this.state.value}
        onChange={this.handleChange}
        onClose={this.handleClose}
        placeholder={t('Inputs::Select...')}
        options={options}
        search={search}
      />
    )
  }
}

export const SelectInput = withTranslation('common')(connect(mapStateToProps)(_SelectInput))
