import { schema } from 'normalizr'
import { Record } from 'immutable'
import { languages } from './statics'

const _schema = new schema.Entity('accounts', {
  language: languages.schema,
})

const _Record = Record({
  id: undefined,
  email: '',
  facebook_id: undefined,
  facebook_token: undefined,
  google_id: undefined,
  language: new languages.Record(),
  language_id: undefined,
  oauth_token: undefined,
  stripe_account_id: undefined,
  stripe_customer_id: undefined,
  paypal_id: undefined,
  payout_gateway: undefined,
  validation_token: undefined,
  validated_at: undefined,
  created_at: undefined,
  updated_at: undefined,
  deactivated_at: undefined,
  ewid: undefined,
})

export const accounts = {
  schema: _schema,
  Record: _Record,
}
