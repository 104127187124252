import { schema } from 'normalizr'
import { Record, List } from 'immutable'
import { places } from './places'
import { users } from './users'
import { files } from './files'

const _schema = new schema.Entity('reviews', {
  user: users.schema,
  place: places.schema,
  files: [files.schema],
})

const _Record = Record({
  id: undefined,
  body: '',
  booking_id: undefined,
  booking: {}, // cannot plug in the booking Record because it not declared yet :/
  created_at: undefined,
  firstname: undefined,
  ratings: new Map(),
  score: undefined,
  updated_at: undefined,
  user: new users.Record(),
  user_id: undefined,
  ewid: undefined,
  hidden_at: undefined,
  files: new List(),
  offline: undefined,
})

export const reviews = {
  schema: _schema,
  Record: _Record,
}
