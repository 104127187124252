// Libs
import React, { useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { useTranslation, Trans } from 'react-i18next'
import { getScheduleEventWithOverrides } from 'redux/entities/selectors'
// Components
import { Link } from 'react-router'
import { Grid, Message, Segment } from 'semantic-ui-react'
import { computeCancellationDeadline } from 'helpers/bookings'

const replaceUnderscoresbySpaces = (text) => {
  if (typeof text !== 'string') return
  return text.replace(/_/g, ' ')
}

export function BookingDetails({ booking }) {
  const [t] = useTranslation()
  const eventWithOverrides = useSelector((state) =>
    getScheduleEventWithOverrides(state, {
      date: moment.utc(booking.date).format('YYYY-MM-DD'),
      eventId: booking.event.id,
    }),
  )
  const eventBeginningTime = useMemo(
    () => eventWithOverrides.begins_at || booking.event.begins_at,
    [booking.event.begins_at, eventWithOverrides.begins_at],
  )

  return (
    <Segment>
      {booking.status === 'payment_refused' && (
        <Message negative>
          <Message.Header>
            {t('Bookings::Summary::Details::Booking Status: {{status}}', {
              status: replaceUnderscoresbySpaces(booking.status),
            })}
          </Message.Header>
          <p>{replaceUnderscoresbySpaces(booking.getIn(['payment', 'payin_reason']))}</p>
        </Message>
      )}
      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <label>
              <Trans
                i18nKey={__('Bookings::Summary::Details::Booking ID: {{id}} <i>{{ewId}}</i>')}
                values={{
                  id: booking.id,
                  ewId: booking.ewid,
                }}
                components={{ i: <i /> }}
              />
            </label>
          </Grid.Column>

          <Grid.Column width={4}>
            <label>
              {t('Bookings::Summary::Details::Created at: {{createdAt}}', {
                createdAt: moment(booking.created_at).format('lll'),
              })}
            </label>
          </Grid.Column>

          <Grid.Column width={4}>
            <label>
              {booking.date
                ? t('Bookings::Summary::Details::Date: {{date}}', {
                    date: moment(`${booking.date} ${eventBeginningTime}`).format('lll'),
                  })
                : t('Bookings::Summary::Details::Date: None {{status}}', {
                    status: replaceUnderscoresbySpaces(booking.status),
                  })}
            </label>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={4}>
            <label>
              <Trans
                i18nKey={__('Bookings::Summary::Details::Event: <eventURL>{{eventId}} - {{eventTitle}}</eventURL>')}
                values={{ eventId: booking.event.id, eventTitle: booking.event.title }}
                components={{ eventURL: <Link to={`/events?search=${booking.event.id}`} /> }}
              />
            </label>
          </Grid.Column>

          <Grid.Column width={4}>
            <label>
              <Trans
                i18nKey={__(
                  'Bookings::Summary::Details::Host: <userURL>{{userId}} - {{userFirstName}} {{userLastName}}</userURL>',
                )}
                values={{
                  userId: booking.event.user.id,
                  userFirstName: booking.event.user.firstname,
                  userLastName: booking.event.user.lastname,
                }}
                components={{ userURL: <Link to={`/users?search=${booking.event.user.id}`} /> }}
              />
            </label>
          </Grid.Column>

          <Grid.Column width={4}>
            <label>
              <Trans
                i18nKey={__(
                  'Bookings::Summary::Details::Guest: <userURL>{{userId}} - {{userFirstName}} {{userLastName}}</userURL>',
                )}
                values={{
                  userId: booking.user.id,
                  userFirstName: booking.user.firstname,
                  userLastName: booking.user.lastname,
                }}
                components={{ userURL: <Link to={`/users?search=${booking.user.id}`} /> }}
              />
            </label>
          </Grid.Column>

          <Grid.Column width={4}>
            <label>
              {t('Bookings::Summary::Details::Booking Status: {{status}}', {
                status: replaceUnderscoresbySpaces(booking.status),
              })}
            </label>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          {booking.cancellation_deadline && (
            <Grid.Column width={4}>
              <label>
                {t('Bookings::Summary::Details::Cancellation deadline: {{date}}', {
                  date: computeCancellationDeadline(
                    `${booking.date} ${eventBeginningTime}`,
                    booking.cancellation_deadline,
                  ),
                })}
              </label>
            </Grid.Column>
          )}

          {booking.partner.id && (
            <Grid.Column width={4}>
              <label>
                <Trans
                  i18nKey={__(
                    'Bookings::Summary::Details::Partner: <parnerURL>{{partnerId}} - {{partnerName}}</parnerURL>',
                  )}
                  values={{
                    partnerId: booking.partner.id,
                    partnerName: booking.partner.name,
                  }}
                  components={{ parnerURL: <Link to={`/partners/edit/${booking.partner.id}`} /> }}
                />
              </label>
            </Grid.Column>
          )}

          {booking.coupon_code && (
            <Grid.Column width={4}>
              <label>
                {t('Bookings::Summary::Details::Coupon: {{coupon}}', {
                  coupon: booking.coupon_code,
                })}
              </label>
            </Grid.Column>
          )}
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={4}>
            <label>{`${t('Forms::SeatsForm::Seats')}: ${booking.seats}`}</label>
          </Grid.Column>
          <Grid.Column width={4}>
            <label>{`${t('Forms::SeatsForm::EW free seats')}: ${booking.ew_free_seats}`}</label>
          </Grid.Column>
          <Grid.Column width={4}>
            <label>{`${t('Forms::SeatsForm::Host free seats')}: ${booking.host_free_seats}`}</label>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

BookingDetails.propTypes = {
  booking: PropTypes.immutable.record.isRequired,
}
