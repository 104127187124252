import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'semantic-ui-react'

export class MenuButton extends PureComponent {
  static propTypes = { children: PropTypes.any.isRequired }

  render () {
    const { children, ...props } = this.props

    return (
      <div className='menu-container'>
        <Button fluid {...props}>
          {children}
        </Button>
      </div>
    )
  }
}
