import { createSelector } from 'reselect'
import { getCurrency } from './statics'
import { getReference } from './references'
import { getBooking } from './bookings'
import { getRequest } from './requests'
import { getFollowup } from './followups'
import {
  getEntities,
  getError,
  isFetching,
  getEntity,
  getEntityCount,
  getEntityOrder,
  getEntityTotal,
  isCreating,
  isUpdating,
} from './_utils'

export const getPartnerDemands = getEntities('partnerDemands')
export const getPartnerDemandsCount = getEntityCount('partnerDemands')
export const getPartnerDemandsTotal = getEntityTotal('partnerDemands')
export const getPartnerDemandsOrder = getEntityOrder('partnerDemands')

export function getPartnerDemand(state, { id }) {
  const partnerDemand = getEntity('partnerDemands')(state, { id })
  return partnerDemand
    .set('currency', getCurrency(state, { id: partnerDemand.currency }))
    .set('reference', getReference(state, { id: partnerDemand.reference }))
    .set('followups', partnerDemand.followups ? partnerDemand.followups.map((id) => getFollowup(state, { id })) : null)
}

export const getPartnerDemandsList = (state) =>
  getPartnerDemandsOrder(state).map((id) => getPartnerDemand(state, { id }))

export const getPartnerDemandBookings = createSelector(
  getPartnerDemand,
  (state) => state,
  (partnerDemand, state) => {
    return partnerDemand.bookings.map((id) => getBooking(state, { id }))
  },
)

export const getPartnerDemandBookingsValidatedSeats = createSelector(getPartnerDemandBookings, (bookings) =>
  bookings.reduce((seats, b) => (b.groupStatus === 'successful' ? seats + b.seats : seats), 0),
)

export const getPartnerDemandBookingsEwFreeSeats = createSelector(getPartnerDemandBookings, (bookings) =>
  bookings.reduce((seats, b) => (b.ew_free_seats > 0 ? seats + b.ew_free_seats : seats), 0),
)

export const getPartnerDemandBookingsHostFreeSeats = createSelector(getPartnerDemandBookings, (bookings) =>
  bookings.reduce((seats, b) => (b.host_free_seats > 0 ? seats + b.host_free_seats : seats), 0),
)

export const getPartnerDemandRequests = createSelector(
  getPartnerDemand,
  (state) => state,
  (partnerDemand, state) => {
    return partnerDemand.requests.map((id) => getRequest(state, { id }))
  },
)

export const getPartnerDemandRequestedSeats = createSelector(getPartnerDemandRequests, (requests) =>
  requests.reduce((seats, request) => {
    const requestSeats = request.approved_at ? request.seats : 0
    return seats + requestSeats
  }, 0),
)

export const fetchingPartnerDemands = isFetching('/api/partner-demands')
export const updatingPartnerDemand = (state, id) => isUpdating(`/api/partner-demands/${id}`)(state)
export const creatingPartnerDemand = isCreating('/api/partner-demands')

export const getPartnerDemandError = (state, id) => getError(`/api/partner-demands/${id}`)(state)
export const getPartnerDemandsError = getError('/api/partner-demands')
export const updatePartnerDemandError = (state, id) => getError(`/api/partner-demands/${id}`)(state)
