import {
  getEntities,
  isFetching,
  isUpdating,
  isCreating,
  getError,
  getEntity,
  getEntityCount,
  getEntityTotal,
  getEntityOrder,
} from './_utils'
import { getUser } from './users'
import { getPlace } from './places'
import { getFile } from './files'
import { getFollowup } from './followups'
import { getOverride } from './overrides'
import { getCurrency, getFood, getAlcohol, getDiet } from './statics'
import { emptyOverride } from '../schemas/overrides'
import { Set } from 'immutable'

export const getEvents = getEntities('events')
export const getEventsCount = getEntityCount('events')
export const getEventsTotal = getEntityTotal('events')
export const getEventsOrder = getEntityOrder('events')

export const getEvent = (state, { id, date }) => {
  const event = getEntity('events')(state, { id })
  return event
    .mergeWith((a, b) => (b === undefined ? a : b), getOverride(state, { id: `${id}-${date}` }))
    .set('user', getUser(state, { id: event.user_id || event.user }))
    .set('place', getPlace(state, { id: event.place_id || event.place }))
    .set('currency', getCurrency(state, { id: event.currency_id || event.currency }))
    .set('food', getFood(state, { id: event.food_id || event.food }))
    .set('cover', getFile(state, { id: event.cover_id || event.cover }))
    .set(
      'files',
      event.files.map((id) => getFile(state, { id })),
    )
    .set(
      'alcohols',
      event.alcohols.map((id) => getAlcohol(state, { id })),
    )
    .set(
      'diets',
      event.diets.map((id) => getDiet(state, { id })),
    )
    .set('followups', event.followups ? event.followups.map((id) => getFollowup(state, { id })) : null)
}

export const getEventsFromIdsArray = (state, eventsIds = []) => new Set(eventsIds.map((id) => getEvent(state, { id })))
export const getEventOverrides = (state, { id, date }) =>
  date ? getOverride(state, { id: `${id}-${date}` }) : emptyOverride

export const getEventsList = (state) => getEventsOrder(state).map((id) => getEvent(state, { id }))

export const fetchingEvents = isFetching('/api/events')
export const fetchingEvent = (state, id) => isFetching(`/api/events/${id}`)(state)
export const creatingEvent = isCreating('/api/events')
export const duplicatingEvent = (state, id) => isCreating(`/api/events/${id}/duplicate`)
export const updatingEvent = (state, id) =>
  isUpdating(`/api/events/${id}`)(state) || isUpdating(`/api/events/${id}/status`)(state)
export const updatingEventOnDate = (state, id, date) => isUpdating(`/api/events/${id}/planning/${date}`)(state)
export const getEventsError = getError('/api/events')
export const getEventError = (state, id, date) =>
  getError(`/api/events/${id}`)(state) ||
  getError(`/api/events/${id}/status`)(state) ||
  getError(`/api/events/${id}/planning/${date}`)(state)
export const getEventDuplicationError = (state, id) => getError(`/api/events/${id}/duplicate`)(state)
