/**
 * Report on the current Execution Environment
 */
const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement)

export const canUse = {
  dom: canUseDOM,
  workers: typeof Worker !== 'undefined',
  eventListners: canUseDOM && !!(window.addEventListener || 'attachEvent' in window),
  viewport: canUseDOM && !!window.screen,
}
