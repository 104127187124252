import { stringifyGeometryCoordinate } from '../../../helpers/places'
import { getEntities, getEntity, getError, isFetching } from './_utils'
import { List, Map } from 'immutable'
// search

export const getEventsIdsFromViewport = (state, viewport) => {
  const ids = new List()
  if (!viewport) return ids

  const searchStringifiedCoordinates = stringifyGeometryCoordinate({
    northeast: { lat: viewport.nelat, lng: viewport.nelng },
    southwest: { lat: viewport.swlat, lng: viewport.swlng },
  })
  return ids.concat(getEntity('searches')(state, { id: searchStringifiedCoordinates }).get('events'))
}

export const getAlgoliaGeoQuery = (state, viewport) => {
  if (!viewport) return new Map()

  const search = getEntity('searches')(state, { id: stringifyGeometryCoordinate(viewport) })
  return search.algoliaGeoQuery
}

export const getSearchedEvents = getEntities('searches')

export const searchingEvents = isFetching('/api/search')
export const getSearchError = getError('/api/search')
