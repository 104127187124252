import { schema } from 'normalizr'
import { Record, List } from 'immutable'
import { users } from './users'

const _schema = new schema.Entity('availabilitiesByDate', {
  users: [users.schema],
})

const _Record = Record({
  id: undefined,
  users: new List(),
  city: undefined,
  date: undefined,
  availability: undefined,
})

export const availabilitiesByDate = {
  schema: _schema,
  Record: _Record,
}
