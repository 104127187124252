import settings from 'settings'
import React, { useEffect, useMemo } from 'react'
import PropTypes, { playlistPropTypes } from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Card, Icon, Image, Label, Button } from 'semantic-ui-react'
import { Box } from '@vizeat/components/es6/components/Box'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { FileUploader } from 'components/inputs'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { useSearch } from 'hooks/search/useSearch'
import { useHasLoadingSucceeded } from 'hooks'
import {
  creatingPlaylist,
  updatingPlaylist,
  getPlaylistError,
  getPlaylistsError,
  getPlaylistFileError,
} from 'redux/entities/selectors'
import { createPlaylist, updatePlaylist } from 'redux/entities/actions'
import { mergeInForm } from 'redux/forms/actions'
import { withRouter } from 'react-router'
import { getLocalityAndCountry } from 'helpers/places'
import { scaleCropFileUrl } from 'helpers/files'

function _PlaylistCard({ form, playlist, router }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isEditMode = !!router.params.id
  const locality = getLocalityAndCountry(form.key_words)
  const { place } = useSearch({ locality })

  const isUpdatingPlaylist = useSelector((state) => updatingPlaylist(state, playlist.id))
  const isCreatingPlaylist = useSelector(creatingPlaylist)
  const createPlaylistError = useSelector(getPlaylistsError)
  const updatePlaylistError = useSelector((state) => getPlaylistError(state, playlist.id))
  const hasCreationSucceeded = useHasLoadingSucceeded(isCreatingPlaylist, createPlaylistError)
  const hasUpdateSucceeded = useHasLoadingSucceeded(isUpdatingPlaylist, updatePlaylistError)
  const coverUploadError = useSelector((state) => getPlaylistFileError(state, playlist.id))

  const coverPath = useMemo(() => {
    if (!form.coverId) return null
    return isNaN(form.coverId)
      ? `https://ucarecdn.com/${form.coverId}/-/preview/150x100/`
      : scaleCropFileUrl({ id: form.coverId }, '150x100')
  }, [form.coverId])

  const previewLink = useMemo(() => {
    const mealtypes = form.search.mealtypes || []
    const languages = form.search.languages || []
    const queryArray = Object.entries({
      q: place?.formatted,
      pid: place?.provider_id,
      ...mealtypes.reduce((acc, param) => ({ mealtypes: [...acc.mealtypes, param] }), { mealtypes: [] }),
      ...languages.reduce((acc, param) => ({ languages: [...acc.languages, param] }), { languages: [] }),
      online: form.search.online,
      allow_public_bookings: form.search.allowPublicBookings || true,
      allow_private_bookings: form.search.allowPrivateBookings,
      price_max: form.search.priceMax,
      price_min: form.search.priceMin,
      radius: form.search.radius,
    }).filter(([_, value]) => !!value)
    if (queryArray.length === 0) return undefined
    return settings.webappUrlFactory.search(Object.fromEntries(queryArray))
  }, [form, place?.formatted, place?.provider_id])

  function handleSave() {
    const { key_words: keyWords, search, ...rest } = form
    const payload = {
      ...rest,
      ...(Object.keys(keyWords).length > 0 && { key_words: keyWords }),
      ...(Object.keys(search).length > 0 && { search }),
    }
    if (isEditMode) return dispatch(updatePlaylist(playlist.id, payload))
    return dispatch(createPlaylist(payload))
  }

  function onCoverUploaded({ file = {}, fileInfo: { uuid } }) {
    if (isEditMode && file.payload.error) return
    const coverId = isEditMode ? file.payload.data.result.files[0].id : uuid
    dispatch(mergeInForm({ formName: 'playlist', value: { coverId } }))
  }

  useEffect(() => {
    if (hasCreationSucceeded || hasUpdateSucceeded) router.push('/playlists')
  }, [hasCreationSucceeded, hasUpdateSucceeded, router])

  if (Object.keys(form) === 0) return null

  return (
    <Card>
      <Card.Content>
        <Card.Header>{t('Playlists::Summary::Playlist preview')}</Card.Header>
        <Box position='relative' my='16px' width='100%'>
          {coverPath && <Image src={coverPath} size='medium' shape='rounded' />}
          <Flex
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            position={coverPath ? 'absolute' : 'initial'}
            top='0'
            height='100%'
            width='100%'
            color={coverPath ? 'white' : 'black'}
            border={coverPath ? 'none' : '1px solid darkGray'}
            borderRadius='md'
            padding={coverPath ? '0' : '12px'}
            textAlign='center'
          >
            <Flex
              gap='8px'
              flexDirection='column'
              justifyContent='center'
              textShadow={
                coverPath ? '-1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000, 1px 1px 0 #000000' : 'none'
              }
            >
              <h3 style={{ fontSize: '24px', lineHeight: '29px', margin: 0 }}>{form.title}</h3>
              <h4 style={{ margin: 0 }}>{form.body}</h4>
            </Flex>

            <Flex
              width='100%'
              justifyContent='flex-end'
              alignSelf='flex-end'
              position='absolute'
              bottom='5px'
              right='5px'
              padding='0'
              margin='0'
            >
              <FileUploader
                style={{ padding: '5px', margin: '0' }}
                basic={!coverPath}
                model={playlist.id ? 'playlists' : 'gallery'}
                id={playlist.id}
                color='blue'
                cover={!!playlist.id}
                multiple={false}
                afterUpload={onCoverUploaded}
              >
                <Icon style={{ margin: 0 }} name='photo' />
              </FileUploader>
            </Flex>
          </Flex>
        </Box>

        <Card.Description>
          <Icon name='point' />
          {locality || t('Playlists::Summary::No data')}
        </Card.Description>

        {form.search.includeEventIds && (
          <Card.Description>
            <Icon name='check square' />
            {t('Playlists::Summary::{{eventSize}} events selected', {
              eventSize: form.search.includeEventIds.length,
            })}
          </Card.Description>
        )}
      </Card.Content>

      {form.tags.length > 0 && (
        <Card.Content>
          {form.tags.map((tag) => (
            <Label key={tag} color='teal' style={{ marginTop: 5 }}>
              {tag}
            </Label>
          ))}
        </Card.Content>
      )}

      <Card.Content>
        {previewLink && (
          <Box textAlign='center' mb='4px'>
            <a href={previewLink} target='_blank' rel='noopener noreferrer'>
              {t('Playlists::Summary::Preview playlist on site')}
            </a>
          </Box>
        )}
        <Box textAlign='center'>
          <Button primary onClick={handleSave} loading={isUpdatingPlaylist || isCreatingPlaylist}>
            {t('Playlists::Summary::Save playlist')}
          </Button>
        </Box>
      </Card.Content>

      <ApiErrorMessage error={createPlaylistError || updatePlaylistError || coverUploadError} modal />
    </Card>
  )
}

_PlaylistCard.propTypes = {
  form: playlistPropTypes.isRequired,
  playlist: playlistPropTypes,
  router: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
}

_PlaylistCard.defaultProps = {
  playlist: undefined,
}

export const PlaylistCard = withRouter(_PlaylistCard)
