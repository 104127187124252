// libs
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
// components
import { Link } from 'react-router'
import { Table, Button } from 'semantic-ui-react'

const UserRow = ({ tag }) => {
  const { t } = useTranslation()
  const { Row, Cell } = Table
  return (
    <Row>
      <Cell>{tag.id}</Cell>
      <Cell>{tag.title}</Cell>
      <Cell>
        <Button primary compact as={Link} to={`tags/edit/${tag.id}`}>
          {t('Tags::Edit')}
        </Button>
      </Cell>
    </Row>
  )
}

UserRow.propTypes = {
  tag: PropTypes.object.isRequired,
}

export default UserRow
