import init from '@vizeat/redux-entities'
import { normalize } from 'normalizr'
import { recordsByEntities } from 'redux/entities/schemas'

const mergeStrategies = {
  mergeDeepNestedMap: false,
  mergeDeepNestedList: false,
  overrideWithDefaults: {
    overrides: true,
    schedules: true,
  },
}
const { getInitialState, basicHandlers, ActionsCreator, buildHandlers } = init({
  recordsByEntities,
  normalize,
  options: mergeStrategies,
})

export { getInitialState, basicHandlers, ActionsCreator, buildHandlers }

export default {
  getInitialState,
  basicHandlers,
  ActionsCreator,
  buildHandlers,
}
