import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const reviewsActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'reviews' })

export const fetchReviews = ({ query } = {}) =>
  reviewsActions.fetch({
    url: '/api/reviews',
    query,
    schema: { reviews: [schemas.reviews] },
  })

export const fetchReview = (id, { query } = {}) =>
  reviewsActions.fetch({
    url: `/api/reviews/${id}`,
    query,
    schema: { review: schemas.reviews },
  })

export const publishReview = (id) =>
  reviewsActions.update({
    url: `/api/reviews/${id}`,
    schema: { review: schemas.reviews },
  })

export const unpublishReview = (id) =>
  reviewsActions.delete({
    url: `/api/reviews/${id}`,
  })
