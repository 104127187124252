import React, { useMemo, useEffect, useCallback } from 'react'
import PropTypes from 'helpers/proptypes'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNextAvailableOrder } from 'hooks/playlists/useNextAvailableOrder'
import { useHasLoadingSucceeded } from 'hooks'
import { Grid } from 'semantic-ui-react'
import Sticky from 'react-stickynode'
import EditPageMenu from 'components/menus/EditPageMenu'
import { PlaylistCard } from './PlaylistCard'
import { PlaylistForm } from './Form/PlaylistForm'
import { fetchPlaylist } from 'redux/entities/actions'
import { getPlaylist, fetchingPlaylist, getPlaylistError } from 'redux/entities/selectors'
import { getForm } from 'redux/forms/reducer'
import { createForm, removeForm, mergeInForm } from 'redux/forms/actions'
import { konstants } from '@vizeat/helpers'

const { PLAYLIST_VISIBILITY, PLAYLIST_DISPLAY_TYPE } = konstants

function PlaylistCreateEdit({ router }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const order = useNextAvailableOrder()

  const playlistId = router.params.id

  const form = useSelector((state) => getForm(state, 'playlist'))
  const playlist = useSelector((state) => getPlaylist(state, { id: playlistId }))
  const { formPOJO, playlistPOJO } = useMemo(
    () => ({ formPOJO: form.toJS(), playlistPOJO: playlist.toJS() }),
    [form, playlist],
  )
  const isFetchingPlaylist = useSelector((state) => fetchingPlaylist(state, playlistId))
  const fetchPlaylistError = useSelector((state) => getPlaylistError(state, playlistId))
  const hasPlaylistBeenFetched = useHasLoadingSucceeded(isFetchingPlaylist, fetchPlaylistError)

  const getPlaylistFormPayload = useCallback(
    (playlist) => ({
      coverId: playlist?.cover?.id || undefined,
      title: playlist?.title || '',
      body: playlist?.body || '',
      visibility: playlist?.zone || PLAYLIST_VISIBILITY.PRIVATE,
      order: playlist?.order || undefined,
      display_type: playlist?.display_type || PLAYLIST_DISPLAY_TYPE.CTA,
      tags: playlist?.tags || [],
      key_words: playlist?.key_words || {},
      search: playlist?.search || {},
    }),
    [],
  )

  const menuItems = useMemo(
    () => [
      { text: t('Playlists::Summary::Name'), value: 'name' },
      { text: t('Playlists::Summary::Subtitle'), value: 'subtitle' },
      { text: t('Playlists::Summary::Visibility'), value: 'visibility' },
      { text: t('Playlists::Summary::Display type'), value: 'display_type' },
      { text: t('Playlists::Summary::Playlist type'), value: 'type' },
      {
        text: t('Playlists::Summary::Display order'),
        value: 'order',
      },
      { text: t('Playlists::Summary::Tags'), value: 'tags' },
      { text: t('Playlists::Summary::Filters'), value: 'filters' },
    ],
    [t],
  )

  useEffect(() => {
    if (playlistId) dispatch(fetchPlaylist(playlistId))
  }, [dispatch, playlistId])

  useEffect(() => {
    dispatch(createForm({ formName: 'playlist', initialState: getPlaylistFormPayload() }))
    return () => dispatch(removeForm({ formName: 'playlist' }))
  }, [dispatch, getPlaylistFormPayload])

  useEffect(() => {
    if (hasPlaylistBeenFetched) dispatch(mergeInForm({ formName: 'playlist', value: getPlaylistFormPayload(playlist) }))
  }, [dispatch, getPlaylistFormPayload, hasPlaylistBeenFetched, playlist])

  useEffect(() => {
    if (!playlistId) dispatch(mergeInForm({ formName: 'playlist', value: { order } }))
  }, [dispatch, order, playlistId])

  if (form.size === 0) return null

  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column width={3}>
          <Sticky>
            <EditPageMenu listName='playlists' menuItems={menuItems} />
          </Sticky>
        </Grid.Column>

        <Grid.Column width={8}>
          <PlaylistForm form={formPOJO} playlist={playlistPOJO} />
        </Grid.Column>

        <Grid.Column width={3}>
          <Sticky top={15}>
            <PlaylistCard form={formPOJO} playlist={playlistPOJO} />
          </Sticky>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

PlaylistCreateEdit.propTypes = {
  router: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
}

export default PlaylistCreateEdit
