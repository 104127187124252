import React, { Fragment } from 'react'
import { Header, Grid, List } from 'semantic-ui-react'
import PropTypes from 'helpers/proptypes'
import { Map, StandardMarker } from '@vizeat/components/es6/components/Map'
import { Link } from 'react-router'
import { useTranslation } from 'react-i18next'

export const SampleExperienceLocation = ({ sampleExperience, place }) => {
  const [t] = useTranslation()
  const sampleExperiencePlace = sampleExperience.get('place')
  const sampleExperienceDescription = sampleExperience.get('description')
  return (
    <Grid>
      {sampleExperienceDescription && (
        <Grid.Row columns={2}>
          <Grid.Column>
            <Header size='small'>{t('HostApplications::Summary::The venue')}</Header>
            {sampleExperienceDescription.get('location_description')}
          </Grid.Column>
          <Grid.Column>
            <Header size='small'>{t('HostApplications::Summary::The location')}</Header>
            {sampleExperienceDescription.get('neighbourhood') || t('HostApplications::Summary::N/A')}
          </Grid.Column>
        </Grid.Row>
      )}

      <Grid.Row columns={2}>
        {sampleExperiencePlace && (
          <Fragment>
            <Grid.Column>
              <Header size='small'>{t('HostApplications::Summary::Place type')}</Header>
              {sampleExperiencePlace.get('place_type')}
            </Grid.Column>
            <Grid.Column>
              {sampleExperiencePlace.get('WOW_factor') && (
                <Fragment>
                  <Header size='small'>{t('HostApplications::Summary::WOW factor')}</Header>
                  <List bulleted>
                    {sampleExperiencePlace.get('WOW_factor').map((wowFactor) => (
                      <List.Item key={wowFactor}>{wowFactor}</List.Item>
                    ))}
                  </List>
                </Fragment>
              )}
            </Grid.Column>
          </Fragment>
        )}
      </Grid.Row>

      {place && (
        <Grid.Row>
          <Grid.Column>
            <div style={{ height: '300px' }}>
              <Map
                mapViewport={{
                  latitude: place.lat,
                  longitude: place.lng,
                  zoom: 10,
                }}
              >
                <StandardMarker latitude={place.lat} longitude={place.lng} />
              </Map>
            </div>

            <Link
              to={`https://www.google.com/maps/place/${place.lat}+${place.lng}/@${place.lat},${place.lng},15z`}
              target='_blank'
            >
              {place.address}
            </Link>
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  )
}

SampleExperienceLocation.propTypes = {
  sampleExperience: PropTypes.immutable.map.isRequired,
  place: PropTypes.immutable.record.isRequired,
}
