export const DEFAULT_LANGUAGE = 'en'
export const LANGUAGES = ['en', 'fr', 'es', 'it', 'zh-CN', 'zh-TW', 'he']
export const MAP_SUBPATHS_TO_LANGUAGES = {
  en: 'en',
  fr: 'fr',
  es: 'es',
  it: 'it',
  'zh-CN': 'zh-CN',
  'zh-TW': 'zh-TW',
  he: 'he',
}

export const DEFAULT_CURRENCY = {
  iso_3: 'EUR',
  title: 'Euro',
  symbol: '€',
  decimal_point: 2,
}

export const MINIMUM_GIFTCARD_AMOUNT = 2000

const CURRENCIES_DETAILS: {
  [currency_iso3: string]: {
    country?: string
    sign_up_campaign: { value: number; minimum: number }
    default_gift_cards_values: { amount: number; value: number }[]
  }
} = {
  ARS: {
    country: 'AR',
    sign_up_campaign: { value: 65000, minimum: 580000 },
    default_gift_cards_values: [
      { amount: 22850, value: 28000 },
      { amount: 458000, value: 572000 },
      { amount: 915000, value: 1143000 },
    ],
  },
  AUD: {
    country: 'AU',
    sign_up_campaign: { value: 1000, minimum: 9000 },
    default_gift_cards_values: [
      { amount: 3200, value: 4000 },
      { amount: 6400, value: 8000 },
      { amount: 13000, value: 16000 },
    ],
  },
  BRL: {
    country: 'BR',
    sign_up_campaign: { value: 4000, minimum: 38000 },
    default_gift_cards_values: [
      { amount: 13000, value: 16000 },
      { amount: 26000, value: 32000 },
      { amount: 51200, value: 64000 },
    ],
  },
  CAD: {
    country: 'CA',
    sign_up_campaign: { value: 1000, minimum: 9000 },
    default_gift_cards_values: [
      { amount: 3200, value: 4000 },
      { amount: 6000, value: 7000 },
      { amount: 11200, value: 14000 },
    ],
  },
  CHF: {
    country: 'CH',
    sign_up_campaign: { value: 1000, minimum: 9000 },
    default_gift_cards_values: [
      { amount: 2400, value: 3000 },
      { amount: 4000, value: 5000 },
      { amount: 8000, value: 10000 },
    ],
  },
  CNY: {
    country: 'CN',
    sign_up_campaign: { value: 8000, minimum: 72000 },
    default_gift_cards_values: [
      { amount: 17500, value: 20000 },
      { amount: 36500, value: 40000 },
      { amount: 72500, value: 80000 },
    ],
  },
  CZK: {
    country: 'CZ',
    sign_up_campaign: { value: 27000, minimum: 250000 },
    default_gift_cards_values: [
      { amount: 58000, value: 67000 },
      { amount: 120000, value: 134000 },
      { amount: 240000, value: 268000 },
    ],
  },
  DKK: {
    country: 'DK',
    sign_up_campaign: { value: 7500, minimum: 65000 },
    default_gift_cards_values: [
      { amount: 15200, value: 19000 },
      { amount: 30000, value: 37000 },
      { amount: 59200, value: 74000 },
    ],
  },
  EUR: {
    sign_up_campaign: { value: 1000, minimum: 9000 },
    default_gift_cards_values: [
      { amount: 2000, value: 2500 },
      { amount: 4000, value: 5000 },
      { amount: 8000, value: 10000 },
    ],
  },
  GBP: {
    country: 'GB',
    sign_up_campaign: { value: 1000, minimum: 9000 },
    default_gift_cards_values: [
      { amount: 2000, value: 2500 },
      { amount: 4000, value: 5000 },
      { amount: 8000, value: 10000 },
    ],
  },
  HKD: {
    country: 'HK',
    sign_up_campaign: { value: 7500, minimum: 75000 },
    default_gift_cards_values: [
      { amount: 18000, value: 22000 },
      { amount: 35200, value: 44000 },
      { amount: 70400, value: 88000 },
    ],
  },
  ILS: {
    country: 'IL',
    sign_up_campaign: { value: 4000, minimum: 35000 },
    default_gift_cards_values: [
      { amount: 20000, value: 25000 },
      { amount: 35000, value: 40000 },
      { amount: 52000, value: 60000 },
    ],
  },
  INR: {
    country: 'IN',
    sign_up_campaign: { value: 90000, minimum: 790000 },
    default_gift_cards_values: [
      { amount: 200000, value: 219000 },
      { amount: 400000, value: 437000 },
      { amount: 800000, value: 875000 },
    ],
  },
  JPY: {
    country: 'JP',
    sign_up_campaign: { value: 1200, minimum: 10500 },
    default_gift_cards_values: [
      { amount: 2570, value: 3210 },
      { amount: 5140, value: 6420 },
      { amount: 10240, value: 12800 },
    ],
  },
  MXN: {
    country: 'MX',
    sign_up_campaign: { value: 20000, minimum: 190000 },
    default_gift_cards_values: [
      { amount: 48000, value: 60000 },
      { amount: 96000, value: 120000 },
      { amount: 193000, value: 241000 },
    ],
  },
  MYR: {
    country: 'MY',
    sign_up_campaign: { value: 4600, minimum: 42000 },
    default_gift_cards_values: [
      { amount: 10000, value: 12000 },
      { amount: 19500, value: 24000 },
      { amount: 38500, value: 48000 },
    ],
  },
  NOK: {
    country: 'NO',
    sign_up_campaign: { value: 9500, minimum: 85000 },
    default_gift_cards_values: [
      { amount: 21000, value: 26000 },
      { amount: 41000, value: 51000 },
      { amount: 82000, value: 102000 },
    ],
  },
  NZD: {
    country: 'NZ',
    sign_up_campaign: { value: 1000, minimum: 9000 },
    default_gift_cards_values: [
      { amount: 3200, value: 4000 },
      { amount: 6400, value: 8000 },
      { amount: 14000, value: 17000 },
    ],
  },
  RON: {
    country: 'RO',
    sign_up_campaign: { value: 4900, minimum: 44000 },
    default_gift_cards_values: [
      { amount: 10700, value: 12000 },
      { amount: 22000, value: 24000 },
      { amount: 44000, value: 48000 },
    ],
  },
  SEK: {
    country: 'SE',
    sign_up_campaign: { value: 10000, minimum: 95000 },
    default_gift_cards_values: [
      { amount: 21000, value: 26000 },
      { amount: 41000, value: 51000 },
      { amount: 82500, value: 103000 },
    ],
  },
  SGD: {
    country: 'SG',
    sign_up_campaign: { value: 1500, minimum: 13500 },
    default_gift_cards_values: [
      { amount: 3200, value: 4000 },
      { amount: 6400, value: 8000 },
      { amount: 12000, value: 15000 },
    ],
  },
  USD: {
    country: 'US',
    sign_up_campaign: { value: 1000, minimum: 9000 },
    default_gift_cards_values: [
      { amount: 2400, value: 3000 },
      { amount: 4800, value: 6000 },
      { amount: 8800, value: 11000 },
    ],
  },
}

export const CURRENCIES = Object.keys(CURRENCIES_DETAILS)

export const MAP_COUNTRY_TO_CURRENCY = Object.entries(CURRENCIES_DETAILS).reduce((acc, [currency, details]) => {
  if (details.country) acc[details.country] = currency
  return acc
}, {} as { [country_iso2: string]: string })

function extractPropByCurrency(prop) {
  return Object.fromEntries(Object.entries(CURRENCIES_DETAILS).map(([currency, details]) => [currency, details[prop]]))
}

export const SIGN_UP_CAMPAIGN = {
  isActive: false,
  ...extractPropByCurrency('sign_up_campaign'),
}
export const GIFT_CARD_VALUES = extractPropByCurrency('default_gift_cards_values')

export const STRIPE_COUNTRIES_WITH_ROUTING_NUMBER = ['AU', 'BR', 'SG', 'CA', 'GB', 'HK', 'IN', 'MX', 'JP', 'NZ', 'US']
export const IBAN_REGEXP = /^[a-z]{2}[0-9]{2}[a-z0-9]{4}[0-9]{7}([a-z0-9]?){0,16}$/i
export const AU_ROUTING_NUMBER_REGEXP = /^[0-9]{6}/i
export const AU_ACCOUNT_NUMBER_REGEXP = /^[0-9]{8}/i
export const BR_ROUTING_NUMBER_REGEXP = /^[a-z0-9]{7,8}/i
export const BR_ACCOUNT_NUMBER_REGEXP = /^.*?/i
export const CA_ROUTING_NUMBER_REGEXP = /^[a-z0-9]{8}/i
export const CA_ACCOUNT_NUMBER_REGEXP = /^.*?/i
export const GB_ROUTING_NUMBER_REGEXP = /^[0-9]{6}/i
export const GB_ACCOUNT_NUMBER_REGEXP = /^[0-9]{8}/i
export const HK_ROUTING_NUMBER_REGEXP = /^[a-z0-9]{3}-[a-z0-9]{3}/i
export const HK_ACCOUNT_NUMBER_REGEXP = /^[a-z0-9]{5,6}-[a-z0-9]{1,3}/i
export const IN_ROUTING_NUMBER_REGEXP = /^[a-z0-9]{11}/i
export const IN_ACCOUNT_NUMBER_REGEXP = /^.*?/i
export const JP_ROUTING_NUMBER_REGEXP = /^[a-z0-9]{7}/i
export const JP_ACCOUNT_NUMBER_REGEXP = /^[a-z0-9]{6,8}/i
export const MX_ACCOUNT_NUMBER_REGEXP = /^[0-9]{18}/i
export const NZ_ROUTING_NUMBER_REGEXP = /^[0-9]{6}/i
export const NZ_ACCOUNT_NUMBER_REGEXP = /^[0-9]{9,10}/i
export const SG_ROUTING_NUMBER_REGEXP = /^[0-9]{4}-[0-9]{3}/i
export const SG_ACCOUNT_NUMBER_REGEXP = /^[a-z0-9]{6,12}/i
export const US_ROUTING_NUMBER_REGEXP = /^[a-z0-9]{9}/i
export const US_ACCOUNT_NUMBER_REGEXP = /^.*?/i

export const PIXEL_RATIO = {
  EXTRA_HIGH: 'PIXEL_RATIO_EXTRA_HIGH',
  HIGH: 'PIXEL_RATIO_HIGH',
  MEDIUM: 'PIXEL_RATIO_MEDIUM',
}

export const MEAL_TYPES = [
  'Aperitif',
  'Breakfast',
  'Brunch',
  'Cooking classes',
  'Dinner',
  'Food walk',
  'Lunch',
  'Tasting',
  'Tea time',
  'Picnic',
]

export const PRIVATE_EVENT_OCCASIONS = [
  'bachelorette',
  'birthday',
  'business-dinner',
  'delegation',
  'off-site',
  'seminar',
  'shabbat-dinner',
  'workshop',
  'other',
] as const

export const ORGANIZATION_TYPES = [
  'Travel agency',
  'Offline Partner',
  'Online Partner - Merchant',
  'Online Partner - Affiliate',
  'Other',
]

export const PAYOUTS_DEFAULT_DELAY = 24 * 60 * 60 * 1000 // ms = 24h
export const PAYOUTS_SAFER_DELAY = 2 * PAYOUTS_DEFAULT_DELAY // ms = 48h
export const DEFAULT_BOOKING_DEADLINE = 'PT6H'
export const DEFAULT_PE_BOOKING_DEADLINE = 'PT6H'
export const DEFAULT_NO_CANCELLATION_DEADLINE = 'P10Y'
export const DEFAULT_CANCELLATION_DEADLINE = 'P2D'
export const DEFAULT_PE_CANCELLATION_DEADLINE = 'P2D'
export const DEFAULT_SEARCH_INTERVAL = 90 // days

export const BOOKING_DEADLINE_OPTIONS = [
  'P0D',
  'PT1H',
  'PT2H',
  'PT3H',
  'PT4H',
  'PT5H',
  'PT6H',
  'PT12H',
  'PT24H',
  'PT48H',
  'PT72H',
]
export const CANCELLATION_DEADLINE_OPTIONS = [
  'P0D',
  'P1D',
  'P2D',
  'P3D',
  'P4D',
  'P5D',
  'P6D',
  'P7D',
  DEFAULT_NO_CANCELLATION_DEADLINE,
]

export const PE_CANCELLATION_DEADLINE_OPTIONS = ['P2D', 'P1W', 'P2W', 'P3W', 'P4W', DEFAULT_NO_CANCELLATION_DEADLINE]

export const CITIES = {
  paris: {
    name: 'Paris',
    value: 'paris',
    southwest: { lat: 48.69622790527972, lng: 1.9549445449218865 },
    northeast: { lat: 49.001223078964735, lng: 2.823551112304699 },
  },
  berlin: {
    name: 'Berlin',
    value: 'berlin',
    southwest: { lat: 52.445882, lng: 13.232088 },
    northeast: { lat: 52.62156, lng: 13.459919 },
  },
  rome: {
    name: 'Rome',
    value: 'rome',
    southwest: { lat: 41.856835, lng: 12.428341 },
    northeast: { lat: 41.99471, lng: 12.553231 },
  },
  amsterdam: {
    name: 'Amsterdam',
    value: 'amsterdam',
    southwest: { lat: 52.331907, lng: 4.84931 },
    northeast: { lat: 52.377381, lng: 4.934586 },
  },
  lisbon: {
    name: 'Lisbon',
    value: 'lisbon',
    southwest: { lat: 38.705085, lng: -9.158835 },
    northeast: { lat: 38.747553, lng: -9.120095 },
  },
  barcelona: {
    name: 'Barcelona',
    value: 'barcelona',
    southwest: { lat: 41.3755, lng: 2.090381 },
    northeast: { lat: 41.43422, lng: 2.203989 },
  },
  london: {
    name: 'London',
    value: 'london',
    southwest: { lat: 51.2868, lng: -0.5103 },
    northeast: { lat: 51.6923, lng: 0.334 },
  },
  nyc: {
    name: 'New York City',
    value: 'nyc',
    southwest: { lat: 40.4774, lng: -74.326 },
    northeast: { lat: 40.9482, lng: -73.5729 },
  },
  sf: {
    name: 'San Francisco',
    value: 'sf',
    southwest: { lat: 37.75097, lng: -122.462555 },
    northeast: { lat: 37.787213, lng: -122.39195 },
  },
  telaviv: {
    name: 'Tel Aviv',
    value: 'telaviv',
    southwest: { lat: 29.453379, lng: 34.267498 },
    northeast: { lat: 33.335632, lng: 35.895023 },
  },
  athens: {
    name: 'Athens',
    value: 'athens',
    southwest: { lat: 37.957193, lng: 23.710309 },
    northeast: { lat: 37.998526, lng: 23.748722 },
  },
  florence: {
    name: 'Florence',
    value: 'florence',
    southwest: { lat: 43.751647, lng: 11.206572 },
    northeast: { lat: 43.803767, lng: 11.296326 },
  },
  vienna: {
    name: 'Vienna',
    value: 'vienna',
    southwest: { lat: 48.197797, lng: 16.334633 },
    northeast: { lat: 48.230047, lng: 16.403967 },
  },
  dubrovnik: {
    name: 'Dubrovnik',
    value: 'dubrovnik',
    southwest: { lat: 42.641632, lng: 18.091884 },
    northeast: { lat: 42.658638, lng: 18.112505 },
  },
  prague: {
    name: 'Prague',
    value: 'prague',
    southwest: { lat: 49.99924, lng: 14.29539 },
    northeast: { lat: 50.14532, lng: 14.59765 },
  },
  copenhagen: {
    name: 'Copenhagen',
    value: 'copenhagen',
    southwest: { lat: 55.651764, lng: 12.482152 },
    northeast: { lat: 55.683717, lng: 12.610749 },
  },
  nice: {
    name: 'Nice',
    value: 'nice',
    southwest: { lat: 43.688503, lng: 7.234674 },
    northeast: { lat: 43.722729, lng: 7.298685 },
  },
  budapest: {
    name: 'Budapest',
    value: 'budapest',
    southwest: { lat: 47.477922, lng: 18.984916 },
    northeast: { lat: 47.528245, lng: 19.090082 },
  },
  milan: {
    name: 'Milan',
    value: 'milan',
    southwest: { lat: 45.442258, lng: 9.117601 },
    northeast: { lat: 45.507913, lng: 9.241484 },
  },
  venice: {
    name: 'Venice',
    value: 'venice',
    southwest: { lat: 45.434135, lng: 12.166783 },
    northeast: { lat: 45.682363, lng: 12.359726 },
  },
  naples: {
    name: 'Naples',
    value: 'naples',
    southwest: { lat: 40.501269, lng: 13.765869 },
    northeast: { lat: 41.141433, lng: 15.249023 },
  },
  genoa: {
    name: 'Genoa',
    value: 'genoa',
    southwest: { lat: 44.028371, lng: 7.918395 },
    northeast: { lat: 44.715513, lng: 9.997558 },
  },
  bari: {
    name: 'Bari',
    value: 'bari',
    southwest: { lat: 40.451127, lng: 15.996093 },
    northeast: { lat: 41.382991, lng: 17.644042 },
  },
  seville: {
    name: 'Seville',
    value: 'seville',
    southwest: { lat: 37.359641, lng: -6.002902 },
    northeast: { lat: 37.401988, lng: -5.958205 },
  },
  lakegarda: {
    name: 'Lake Garda',
    value: 'lakegarda',
    southwest: { lat: 45.405608, lng: 10.539189 },
    northeast: { lat: 45.590477, lng: 10.998385 },
  },
  lima: {
    name: 'Lima',
    value: 'lima',
    southwest: { lat: -12.141894, lng: -77.049042 },
    northeast: { lat: -12.046374, lng: -76.973377 },
  },
  sanjose: {
    name: 'San Jose',
    value: 'sanjose',
    southwest: { lat: 9.928069, lng: -84.162778 },
    northeast: { lat: 9.958866, lng: -84.090725 },
  },
  montreal: {
    name: 'Montreal',
    value: 'montreal',
    southwest: { lat: 45.478087, lng: -73.895303 },
    northeast: { lat: 45.547074, lng: -73.191047 },
  },
  cannes: {
    name: 'Cannes',
    value: 'cannes',
    southwest: { lat: 43.552106, lng: 6.979976 },
    northeast: { lat: 43.62098, lng: 7.119419 },
  },
  munich: {
    name: 'Munich',
    value: 'munich',
    southwest: { lat: 48.111, lng: 11.44321 },
    northeast: { lat: 48.13811, lng: 11.583925 },
  },
  dublin: {
    name: 'Dublin',
    value: 'dublin',
    southwest: { lat: 53.337524, lng: -6.297919 },
    northeast: { lat: 53.361372, lng: -6.282977 },
  },
  tokyo: {
    name: 'Tokyo',
    value: 'tokyo',
    southwest: { lat: 35.529396, lng: 139.592169 },
    northeast: { lat: 35.757981, lng: 139.838895 },
  },
  porto: {
    name: 'Porto',
    value: 'porto',
    southwest: { lat: 41.146022, lng: -8.657733 },
    northeast: { lat: 41.177657, lng: -8.598838 },
  },
  edinburgh: {
    name: 'Edinburgh',
    value: 'edinburgh',
    southwest: { lat: 55.942297, lng: -3.249496 },
    northeast: { lat: 55.966986, lng: -3.159677 },
  },
  valencia: {
    name: 'Valencia',
    value: 'valencia',
    southwest: { lat: 39.43108, lng: -0.462827 },
    northeast: { lat: 39.500524, lng: -0.345622 },
  },
  la: {
    name: 'Los Angeles',
    value: 'la',
    southwest: { lat: 33.739849, lng: -118.543109 },
    northeast: { lat: 34.169127, lng: -118.10591 },
  },
  neworleans: {
    name: 'New Orleans',
    value: 'neworleans',
    southwest: { lat: 29.65474208562647, lng: -90.65231735766599 },
    northeast: { lat: 30.2437445659396, lng: -89.39918930590818 },
  },
  madrid: {
    name: 'Madrid',
    value: 'madrid',
    southwest: { lat: 40.365898, lng: -3.736639 },
    northeast: { lat: 40.471655, lng: -3.581198 },
  },
  shanghai: {
    name: 'Shanghai',
    value: 'shanghai',
    southwest: { lat: 30.94051, lng: 121.11053 },
    northeast: { lat: 31.560984, lng: 121.83837 },
  },
  chengdu: {
    name: 'Chengdu',
    value: 'chengdu',
    southwest: { lat: 30.33021, lng: 103.6965 },
    northeast: { lat: 30.94875, lng: 104.458 },
  },
  beijing: {
    name: 'Beijing',
    value: 'beijing',
    northeast: { lat: 41.0608157, lng: 117.514625 },
    southwest: { lat: 39.442758, lng: 115.4234112 },
  },
  hangzhou: {
    name: 'Hangzhou',
    value: 'hangzhou',
    northeast: { lat: 30.5665162, lng: 120.7219451 },
    southwest: { lat: 29.18875689999999, lng: 118.3449333 },
  },
}

export const SORTED_CITIES = Object.keys(CITIES)
  .map((c) => ({ key: `city_${CITIES[c].name}`, text: CITIES[c].name, value: c }))
  .sort((a, b) => (a.text > b.text ? 1 : -1))

export const TIER1_CITIES = [
  'amsterdam',
  'barcelona',
  'berlin',
  'budapest',
  'florence',
  'lisbon',
  'london',
  'madrid',
  'nyc',
  'paris',
  'rome',
  'sf',
  'telaviv',
  'venice',
]

export const FOLLOWUPS_OPTIONS = [
  { text: '', value: '' },
  { text: 'Call', value: 'Call' },
  { text: 'Email', value: 'Email' },
  { text: 'Read Inbox/messages', value: 'Read Inbox/messages' },
  { text: 'Rejected', value: 'Rejected' },
  { text: 'SMS', value: 'SMS' },
  { text: 'Whatsapp', value: 'Whatsapp' },
]

export const COOKIE_KEYS = {
  AFFID_CLICKED_AT: 'affid_clicked_at',
  AFFID: 'affid',
  BRAND: 'brand',
  EW_CURRENCY: 'EW_CURRENCY',
  EW_LANGUAGE: 'EW_LANGUAGE',
  EXIT_MODAL_CLOSED: 'exit-modal-closed',
  HIDE_BANNER_VOUCHER: 'hide-banner-voucher',
  HOST_APPLICATION: 'host-application',
  LAST_VIEWED_EXPERIENCES: 'last-viewed-experiences',
  MARRIOTT_TRACKING: 'MARRIOTT_TRACKING',
  NEW_USER: 'new-user',
  NEWSLETTER_SIGNUP: 'newsletter-signup',
  ONBOARDING_BANNER_VIEWS: 'onboarding-banner-views',
  ONBOARDING_BANNER: 'onboarding-banner',
  REAUTHENTICATION_REQUIRED: 'reauthentication-required',
  RECENT_SEARCHES: 'recent-searches',
  REMEMBER_ME_AUTHENTICATION: 'remember-me-authentication',
  SIGNUP_BANNER_CLOSED: 'signup-banner-closed',
  SMARTBANNER_CLOSED: 'smartbanner-closed',
  SMARTBANNER_INSTALLED: 'smartbanner-installed',
  TA_CLICK_ID_CLICKED_AT: 'taclickid_clicked_at',
  TA_CLICK_ID: 'taclickid',
  TOKEN: 'token',
  USER_UNDERAGED: 'user-underaged',
  VIZEAT_ADMIN: 'vizeat-admin',
}

export const CENSORIFY_EXCEPTIONS = [
  /eatwith\.com/i,
  /vizeater\.(?:co|info)/i,
  /eat\.lc/i,
  /\+?(33.?1.?7.?6.?3.?5.?0.?5.?3.?0|972.?3.?7.?6.?3.?0.?7.?1.?0|39.?0.?2.?9.?4.?7.?5.?1.?8.?6.?5|34.?9.?3.?2.?2.?0.?1.?1.?5.?7|1.?6.?4.?6.?9.?3.?3.?4.?1.?7.?2|\(?844\)?.?8.?8.?0.?5.?3.?1.?6)/,
]

export const TAGS = {
  VIZEAT: 'vizeat',
  PARTNER: 'partners',
  PRIVATIZABLE: 'privatizable',
  STRIPE_CHARGES_DISABLED: 'stripe-charges-disabled',
  STRIPE_PAYOUTS_DISABLED: 'stripe-payouts-disabled',
  HIDDEN_STRIPE_UPDATE: 'hidden-stripe-update',
  ONLINE_EXPERIENCE: 'online-experience',
  GOOD_TO_GO: 'good-to-go',
  CALENDAR_DEFAULT_OPEN: 'calendar-default-open',
  DESTINATION_PLAYLIST: 'destination-playlist',
}

export const CANCELLATION_REASONS = {
  HOST_NOT_AVAILABLE: 'host-not-available',
  NOT_ENOUGH_BOOKINGS: 'not-enough-bookings',
  EXPERIENCE_CLOSED: 'experience-closed',
  OTHER_REASON: 'other',
}

export const RESCHEDULE_CHOICES = {
  SUGGEST_NEW_DATES: 'suggest-new-dates',
  SUGGEST_EXPERIENCE: 'suggest-experience',
  NO_SUGGESTION: 'no-suggestion',
}

export const GEOSEARCH_RADIUS = {
  DEFAULT_VALUE: 5000,
  COMPUTED_RADIUS_SCALE_FACTOR: 333,
  AROUND_RADIUS_SCALE_FACTOR: 25,
}

export const PROHIBITED_ALCOHOLIC_DRINK_LOCATIONS = [
  'FR',
  'BL',
  'GP',
  'GF',
  'MF',
  'MQ',
  'NC',
  'PF',
  'PM',
  'RE',
  'TF',
  'WF',
]

export const HOST_TYPOLOGY = {
  NURTURER: 'nurturer',
  ENTERTAINER: 'entertainer',
  CREATOR: 'creator',
  AMBASSADOR: 'ambassador',
}

export const HOST_APPLICATION_SCHEMA_VERSION = 3

export const PLATFORMS = {
  INTERNATIONAL: 'international',
  CHINA: 'china',
}

export const AMENITIES = {
  KID: 'KID',
  WEELCHAIR: 'WEELCHAIR',
  ELEVATOR: 'ELEVATOR',
  SMOKING: 'SMOKING',
  FOOTPRINT: 'FOOTPRINT',
  SNOWFLAKE: 'SNOWFLAKE',
  FREE_PARKING: 'FREE_PARKING',
  STREET_PARKING: 'STREET_PARKING',
  TAXI: 'TAXI',
  PUBLIC_TRANSPORT: 'PUBLIC_TRANSPORT',
  PIN: 'PIN',
  BACK_ARROW: 'BACK_ARROW',
  WIFI: 'WIFI',
}

export const PLAYLIST_DISPLAY_TYPE = {
  CTA: 'cta',
  CAROUSEL: 'carousel',
}

export const PLAYLIST_VISIBILITY = {
  PRIVATE: 'private',
  PUBLIC: 'public',
}

export const SOCIAL_NETWORK_URLS = {
  facebook: {
    gb: 'https://www.facebook.com/eatwithlondon/',
    il: 'https://www.facebook.com/Eatwithil/',
    es: 'https://www.facebook.com/EatwithES/',
    default: 'https://www.facebook.com/Eatwith/',
  },
  twitter: { default: 'https://twitter.com/Eatwith' },
  instagram: {
    gb: 'https://www.instagram.com/eatwith/',
    il: 'https://www.instagram.com/eatwith_il/',
    es: 'https://www.instagram.com/eatwith_es/',
    default: 'https://www.instagram.com/eatwith/',
  },
  wechat: { default: 'https://eatwith.net.cn' },
  linkedin: { default: 'https://www.linkedin.com/company/eatwith/' },
  tiktok: { default: 'https://www.tiktok.com/@eatwithlocals' },
}

export const EVENT_DESCRIPTION_TYPES = {
  Event: 'event',
  Menu: 'menu',
  PartnerEvent: 'partner_event',
  Highlight: 'highlight',
} as const
export type EventDescriptionType = (typeof EVENT_DESCRIPTION_TYPES)[keyof typeof EVENT_DESCRIPTION_TYPES]
