import { screenApi, businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import i18n from 'i18n-client'

export const appSettingsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'appSettings' })

export const settingsActions = {
  SET_LOCALE: '@@appSettings/SET_LOCALE',
}

export const setAppLocale = (locale = i18n.language) => (dispatch) => {
  screenApi.setHeader('X-Language', locale)
  businessApi.setHeader('X-Language', locale)
  return i18n.changeLanguage(locale, () => dispatch({ type: settingsActions.SET_LOCALE, locale }))
}
