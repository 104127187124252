import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const availabilitiesByDateActions = new ActionsCreator({
  Api: businessApi,
  actionTypesPrefix: 'availabilitiesByDate',
})

export const fetchAvailabilitiesByDate = ({ query } = {}) =>
  availabilitiesByDateActions.fetch({
    url: '/api/users/availabilities',
    query,
    schema: { availabilitiesByDate: schemas.availabilitiesByDate },
  })
