import React from 'react'
import { Modal, Button, Icon } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import { useTranslation } from 'react-i18next'

export const VideoModal = ({ videoUrl }) => {
  const [t] = useTranslation()
  if (!videoUrl) return null
  return (
    <Modal
      trigger={
        <Button basic color='blue'>
          <Icon name='play' />
          {t('HostApplications::Summary::Display video')}
        </Button>
      }
      basic
      size='small'
      closeIcon
    >
      <Modal.Content>
        <ReactPlayer className='__videoPlayer' url={videoUrl} controls />
      </Modal.Content>
    </Modal>
  )
}

VideoModal.propTypes = {
  videoUrl: PropTypes.string.isRequired,
}
