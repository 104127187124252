// Libraries
import React, { Fragment, PureComponent } from 'react'
// Components
import { PartnerSearchForm } from './PartnerSearchForm'
import { PartnerList } from './PartnerList'

export class PartnersIndex extends PureComponent {
  render() {
    return (
      <Fragment>
        <PartnerSearchForm />
        <PartnerList />
      </Fragment>
    )
  }
}
