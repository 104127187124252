import settings from 'settings'
// libs
import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Set, List } from 'immutable'
import JSZip from 'jszip'
import axios from 'axios'
import cn from 'classnames'
// helpers
import { isUndefined } from 'lodash'
import PropTypes from 'helpers/proptypes'
import { scaleCropFileUrl, fileFormatOptions, downloadFromBlob } from 'helpers/files'
import { getTranslatedOptions } from 'helpers/options'
import { useIsMounted } from 'hooks/useIsMounted'
// Components
import { Card, Modal, Message, Button, Image, Checkbox, Form, Input, Dropdown } from 'semantic-ui-react'
import { IconButton } from 'components/buttons'

import './DownloadPhotosModal.css'

const SelectableImageCard = ({ file, isSelected, isFailed, onClick }) => {
  const { t } = useTranslation()
  const handleClick = () => {
    onClick(file.id)
  }

  return (
    <Card as='div' onClick={handleClick} className={cn({ __ImageCardError: isFailed })}>
      {isFailed && (
        <Message
          compact
          error
          content={t('Experiences::DownloadPhotosModal::An error has occurred')}
          className='__ImageCardErrorMessage'
        />
      )}
      <Card.Header>
        <Image src={scaleCropFileUrl(file, '300x200')} />
      </Card.Header>
      <Card.Content>
        <Checkbox checked={isSelected} readOnly />
      </Card.Content>
    </Card>
  )
}

SelectableImageCard.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  isFailed: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

const ResizeField = ({ name, value, isRequired, onChange }) => {
  const { t } = useTranslation()
  const label = t('Experiences::DownloadPhotosModal::{{name}}', { name })

  return (
    <Form.Field className='__ResizeField'>
      <label htmlFor={name}>{label}</label>
      <Input
        type='number'
        id={name}
        min={MIN_CUSTOM_SIZE}
        value={value ?? ''} // fallback undefined value to '' in order to keep the field controlled
        onChange={onChange}
        {...(isRequired && {
          error: true,
          label: { icon: 'asterisk' },
          labelPosition: 'left corner',
        })}
      />
    </Form.Field>
  )
}

ResizeField.propTypes = {
  name: PropTypes.oneOf(['width', 'height']).isRequired,
  isRequired: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
}

ResizeField.defaultProps = {
  value: undefined,
}

const getResizedImageURL = (fileId, format) => {
  const path = format === 'original' ? '/-/raw' : `/-/scale_crop/${format}/center/-/progressive/yes`
  return settings.globalUrlFactory.screen.files.transform(fileId, path)
}

const MIN_CUSTOM_SIZE = 1
const isValidField = (size) => isUndefined(size) || size >= MIN_CUSTOM_SIZE

const initialState = {
  isModalVisible: false,
  isDownloading: false,
  selectedFileIds: Set([]),
  failedFileIds: Set([]),
  format: 'original',
  width: undefined,
  height: undefined,
}

export const DownloadPhotosModal = ({ event }) => {
  const { t } = useTranslation()
  const isMounted = useIsMounted()

  // remove duplicate uploadcare_id
  const galleryList = useMemo(() => {
    if (!event.files) return List()
    return event.files
      .groupBy((image) => image.get('uploadcare_id'))
      .map((group) => group.first())
      .toList()
  }, [event.files])

  const [{ isModalVisible, isDownloading, selectedFileIds, failedFileIds, format, width, height }, setState] =
    useState(initialState)

  const handleStateChange = (state) => {
    if (!isMounted()) return
    setState((prevState) => ({
      ...prevState,
      ...state,
    }))
  }

  const handleModalShow = () => {
    handleStateChange({ isModalVisible: true })
  }

  const handleModalHide = () => {
    handleStateChange({ ...initialState, isModalVisible: false })
  }

  const handleToggleSelectedFileId = (id) => {
    handleStateChange({
      selectedFileIds: selectedFileIds.includes(id) ? selectedFileIds.delete(id) : selectedFileIds.add(id),
    })
  }

  const handleSelectAllFileIds = () => {
    const fileIds = [...galleryList.map((image) => Number(image.get('id')))]
    handleStateChange({
      selectedFileIds: selectedFileIds.merge(fileIds),
    })
  }

  const handleDeselectAllFileIds = () => {
    handleStateChange({
      selectedFileIds: selectedFileIds.clear(),
    })
  }

  const handleResizeOptionChange = (name) => (_, data) => {
    let { value } = data
    if (data.type === 'number') {
      value = data.value ? parseInt(data.value) : undefined
    }

    handleStateChange({ [name]: value })
  }

  const isRequiredWidth = useMemo(() => {
    if (!isValidField(width)) return true
    return isUndefined(width) && !isUndefined(height)
  }, [height, width])

  const isRequiredHeight = useMemo(() => {
    if (!isValidField(height)) return true
    return isUndefined(height) && !isUndefined(width)
  }, [height, width])

  const canDownload = selectedFileIds.size > 0 && !isRequiredWidth && !isRequiredHeight

  const getResizedImageData = async (fileId) => {
    const url = getResizedImageURL(fileId, width && height ? `${width}x${height}` : format)

    try {
      const response = await axios.get(url, { responseType: 'blob' })

      // Retrieve the file name from the path https://user-content.eatwith.com/28cdf4e0-2ae4-4b23-932f-351da1324d08/cavatiallanorma.jpg
      const name = galleryList
        .find((file) => file.id === fileId)
        ?.path.split('/')
        .pop()

      return { name: name || `file_${fileId}`, blob: response.data }
    } catch (e) {
      e.id = fileId
      throw e
    }
  }

  const handleDownload = async (e) => {
    e.preventDefault()
    handleStateChange({ isDownloading: true, failedFileIds: failedFileIds.clear() })

    const zip = new JSZip()
    const rejectedIds = []
    const imageDataResults = await Promise.allSettled(selectedFileIds.map(getResizedImageData))

    imageDataResults.forEach((result) => {
      if (result.status === 'fulfilled') {
        zip.file(result.value.name, result.value.blob, { base64: true })
      } else {
        rejectedIds.push(result.reason.id)
      }
    })

    if (rejectedIds.length) {
      handleStateChange({ failedFileIds: failedFileIds.merge(rejectedIds) })
    }

    const zipBlob = await zip.generateAsync({ type: 'blob' })
    downloadFromBlob(zipBlob, `experience_${event.id}`)
    handleStateChange({ isDownloading: false })
  }

  return (
    <React.Fragment>
      <IconButton
        name='download'
        tooltipContent={t('Experiences::DownloadPhotosModal::Download Media')}
        size='large'
        style={{ marginLeft: 8 }}
        onClick={handleModalShow}
      />

      <Modal open={isModalVisible} onClose={handleModalHide} closeIcon className='DownloadPhotosModal'>
        <Modal.Header className='__ModalHeader'>
          <button onClick={handleModalHide} type='button'>
            ← {t('Experiences::DownloadPhotosModal::Back to the experience')}
          </button>
          <h1>{t('Experiences::DownloadPhotosModal::Select photos to download')}</h1>
        </Modal.Header>

        <Modal.Content scrolling>
          <Card.Group itemsPerRow={3}>
            {galleryList.map((file) => (
              <SelectableImageCard
                key={file.uploadcare_id}
                file={file}
                isSelected={selectedFileIds.includes(file.id)}
                isFailed={failedFileIds.includes(file.id)}
                onClick={handleToggleSelectedFileId}
              />
            ))}
          </Card.Group>
        </Modal.Content>

        <Modal.Actions style={{ textAlign: 'center' }}>
          <Button
            type='button'
            disabled={selectedFileIds.size === galleryList.size}
            loading={isDownloading}
            onClick={handleSelectAllFileIds}
          >
            {t('Experiences::DownloadPhotosModal::Select all')}
          </Button>

          <Button
            type='button'
            loading={isDownloading}
            disabled={!selectedFileIds.size}
            onClick={handleDeselectAllFileIds}
          >
            {t('Experiences::DownloadPhotosModal::Deselect all')}
          </Button>
        </Modal.Actions>

        <Modal.Actions>
          <form onSubmit={handleDownload} className='__ResizeForm'>
            <div className='__ResizeOptionItem'>
              {t('Experiences::DownloadPhotosModal::Choose format')}:
              <Dropdown
                button
                basic
                value={format}
                options={getTranslatedOptions(t, fileFormatOptions, undefined, ({ value }, text) => {
                  if (value === 'original') return text
                  return `${text} (${value})`
                })}
                onChange={handleResizeOptionChange('format')}
              />
            </div>

            <div className='__ResizeOptionItem'>
              {t('Experiences::DownloadPhotosModal::or resize')}: (px)
              <ResizeField
                name='width'
                value={width}
                isRequired={isRequiredWidth}
                onChange={handleResizeOptionChange('width')}
              />
              <ResizeField
                name='height'
                value={height}
                isRequired={isRequiredHeight}
                onChange={handleResizeOptionChange('height')}
              />
            </div>

            <Button loading={isDownloading} primary disabled={!canDownload} style={{ minWidth: 134 }}>
              {t('Experiences::DownloadPhotosModal::Download')}
              {canDownload && ` (${selectedFileIds.size})`}
            </Button>
          </form>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  )
}

DownloadPhotosModal.propTypes = {
  event: PropTypes.shape({
    files: PropTypes.immutable.list,
    id: PropTypes.number.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
}
