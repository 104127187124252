// Libs
import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
// Components
import { Icon, Popup } from 'semantic-ui-react'
import { Event } from './Event'
// Redux
import { getScheduleVisibleEvents } from 'redux/entities/selectors'
import { getLocation } from 'redux/reducers/router'

import './DayCell.css'

const mapStateToProps = (state, props) => ({
  fromStore: {
    getVisibleEvents: (statuses) => getScheduleVisibleEvents(state, { date: props.date, statuses }),
    location: getLocation(state),
  },
})

class _DayCell extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    date: PropTypes.string.isRequired,
    fromStore: PropTypes.shape({
      getVisibleEvents: PropTypes.func.isRequired,
      location: PropTypes.object.isRequired,
    }).isRequired,
    openAvailabilitiesModal: PropTypes.func.isRequired,
  }

  state = { showDetailModalForEvent: undefined }

  setShowDetailModalForEvent = (id) => () => this.setState({ showDetailModalForEvent: id })

  render() {
    const {
      t,
      date,
      fromStore: { getVisibleEvents, location },
      openAvailabilitiesModal,
    } = this.props
    const { showDetailModalForEvent } = this.state
    const modalProps = {
      showDetailModalForEvent,
      setShowDetailModalForEvent: this.setShowDetailModalForEvent,
    }

    const visibleEvents = getVisibleEvents(location.getIn(['query', 'statuses']))
    const event = visibleEvents.first()
    return (
      <div className='CalendarDayCell'>
        <p>{moment.utc(date).format('DD')}</p>
        <Icon name='info' color='blue' className='availibilityModalIcon' onClick={openAvailabilitiesModal(date)} />
        {event && <Event date={date} event={event} {...modalProps} />}
        {visibleEvents.size > 1 && (
          <Popup
            content={
              <div>
                <p>{moment.utc(date).format('dddd MMM DD')}</p>
                <div className='CalendarEventsListPopup'>
                  {visibleEvents.map((event) => (
                    <Event date={date} event={event} key={event.id} {...modalProps} />
                  ))}
                </div>
              </div>
            }
            on='click'
            trigger={<p>{t('EventsCalendar::+{{size}} more', { size: visibleEvents.size - 1 })}</p>}
            closeOnDocumentClick={!showDetailModalForEvent}
          />
        )}
      </div>
    )
  }
}

export const DayCell = withTranslation('common')(connect(mapStateToProps, null)(_DayCell))
