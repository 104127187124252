// Libs
import React, { useEffect } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { get } from 'lodash'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
// Components
import { Formik } from 'formik'
import { ReferencesForm } from 'components/forms/references'
import { useHasLoadingSucceeded } from 'hooks'
// Redux
import { updatePartnerDemand } from 'redux/entities/actions'
import { updatingPartnerDemand, getPartnerDemandError } from 'redux/entities/selectors'

const mapStateToProps = (state, props) => ({
  fromStore: {
    isUpdatingPartnerDemand: updatingPartnerDemand(state, props.partnerDemand && props.partnerDemand.id),
    partnerDemandError: getPartnerDemandError(state, props.partnerDemand && props.partnerDemand.id),
  },
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    updatePartnerDemand: (id, payload) => dispatch(updatePartnerDemand(id, payload)),
  },
})

const _ReferencesFormWrapper = ({ partnerDemand, actions, fromStore }) => {
  const isUpdatingSucceded = useHasLoadingSucceeded(fromStore.isUpdatingPartnerDemand, fromStore.partnerDemandError)
  const [t] = useTranslation()

  useEffect(() => {
    if (isUpdatingSucceded) {
      toast.success(`${t('ToastNotification::The reference code has been updated')} 👍`, { type: toast.TYPE.SUCCESS })
    }
  }, [isUpdatingSucceded])

  const handleSubmit = (values) =>
    actions.updatePartnerDemand(partnerDemand.id, {
      reference_code: values.referenceCode,
    })

  return partnerDemand && partnerDemand.id ? (
    <Formik
      initialValues={{
        referenceCode: get(partnerDemand, ['reference', 'code']),
      }}
      onSubmit={handleSubmit}
      component={ReferencesForm}
      validationSchema={Yup.object().shape({
        referenceCode: Yup.string().required(),
      })}
    />
  ) : null
}

_ReferencesFormWrapper.propTypes = {
  partnerDemand: PropTypes.shape({
    id: PropTypes.number,
    payment: PropTypes.object,
  }).isRequired,
  fromStore: PropTypes.shape({
    isUpdatingPartnerDemand: PropTypes.bool,
    partnerDemandError: PropTypes.object,
  }).isRequired,
  actions: PropTypes.shape({
    updatePartnerDemand: PropTypes.func,
  }).isRequired,
}

export const ReferencesFormWrapper = connect(mapStateToProps, mapDispatchToProps)(_ReferencesFormWrapper)
