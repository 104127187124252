import {
  getEntities,
  getError,
  isFetching,
  getEntity,
  getEntityCount,
  getEntityOrder,
  getEntityTotal,
  isCreating,
  isUpdating,
  getCreatingError,
  getUpdatingError,
} from './_utils'

export const getOrganizations = getEntities('organizations')
export const getOrganization = getEntity('organizations')
export const getOrganizationsCount = getEntityCount('organizations')

export const getOrganizationsOrder = getEntityOrder('organizations')
export const getOrganizationsTotal = getEntityTotal('organizations')
export const getOrganizationsList = (state) => getOrganizationsOrder(state).map((id) => getOrganization(state, { id }))

export const fetchingOrganizations = isFetching('/api/organizations')
export const fetchingOrganization = (state, id) => isFetching(`/api/organizations/${id}`)(state)
export const updatingOrganization = (state, id) => isUpdating(`/api/organizations/${id}`)(state)
export const creatingOrganization = isCreating('/api/organizations')

// Errors
export const getCreatingOrganizationError = getCreatingError('/api/partners')
export const getOrganizationsError = getError('/api/partners')
export const getOrganizationError = (state, id) => getError(`/api/partners/${id}`)(state)
export const getUpdatingOrganizationError = (state, id) => getUpdatingError(`/api/partners/${id}`)(state)
