import moment from 'moment'
import { konstants } from '@vizeat/helpers'

const { TAGS } = konstants

export const mealTypesOptions = [
  { text: __('MealType::Aperitif'), value: 'Aperitif' },
  { text: __('MealType::Breakfast'), value: 'Breakfast' },
  { text: __('MealType::Brunch'), value: 'Brunch' },
  { text: __('MealType::Cooking classes'), value: 'Cooking classes' },
  { text: __('MealType::Dinner'), value: 'Dinner' },
  { text: __('MealType::Food walk'), value: 'Food walk' },
  { text: __('MealType::Lunch'), value: 'Lunch' },
  { text: __('MealType::Tasting'), value: 'Tasting' },
  { text: __('MealType::Tea time'), value: 'Tea Time' },
  { text: __('MealType::Picnic'), value: 'Picnic' },
]

export const statusOptions = [
  { text: __('Experiences::StatusOptions::Draft'), value: 'draft' },
  { text: __('Experiences::StatusOptions::Approved'), value: 'approved' },
  { text: __('Experiences::StatusOptions::Rejected'), value: 'rejected' },
  { text: __('Experiences::StatusOptions::Awaiting host approval'), value: 'awaiting_host_approval' },
  { text: __('Experiences::StatusOptions::Approved by host'), value: 'approved_by_host' },
]

export const toReviewStatusOptions = [
  { text: __('Experiences::ToReviewStatusOptions::To review'), value: 'to_review' },
  { text: __('Experiences::ToReviewStatusOptions::Change requested'), value: 'change_requested' },
]

export const dateRangeOptions = [
  { text: '', value: '' },
  { text: __('Experiences::DateRangeOptions::Created at'), value: 'created_at' },
  { text: __('Experiences::DateRangeOptions::Updated at'), value: 'updated_at' },
  { text: __('Experiences::DateRangeOptions::Published at'), value: 'published_at' },
  { text: __('Experiences::DateRangeOptions::Has bookings'), value: 'has_bookings' },
]

export const freezeOpts = [
  { text: '', value: '' },
  { text: __('Experiences::FreezeOptions::Yes'), value: '1' },
  { text: __('Experiences::FreezeOptions::No'), value: '0' },
]

export const isPublishedOpts = [
  { text: __('Experiences::PublishOptions::All'), value: '' },
  { text: __('Experiences::PublishOptions::Published'), value: '1' },
  { text: __('Experiences::PublishOptions::Unpublished'), value: '0' },
]

export const instantBookingOpts = [
  { text: __('Experiences::InstantBookingOptions::All'), value: '' },
  { text: __('Experiences::InstantBookingOptions::Enabled'), value: '1' },
  { text: __('Experiences::InstantBookingOptions::Disabled'), value: '0' },
]

export const bookingModeOpts = [
  { text: __('Experiences::BookingModeOptions::All booking modes'), value: '' },
  { text: __('Experiences::BookingModeOptions::Allow open bookings'), value: 'public' },
  { text: __('Experiences::BookingModeOptions::Allow private bookings'), value: 'private' },
  // { text: __('Experiences::BookingModeOptions::Allow only private bookings'), value: 'private_only' },
]

export const applicationTypeOpts = [
  { text: '', value: '' },
  { text: __('Experiences::ApplicationTypeOptions::New hosts'), value: 'newHosts' },
  { text: __('Experiences::ApplicationTypeOptions::New events'), value: 'newEvents' },
]

const descriptionSkeleton = {
  id: undefined,
  language_id: undefined,
  title: undefined,
  describes: undefined,
  order: undefined,
  items: [],
}

export const getDescriptionSkeleton = () => descriptionSkeleton

const descriptionItemSkeleton = {
  id: undefined,
  title: undefined,
  body: undefined,
  type: 'text', // 'text' or 'geometry'
  order: undefined,
}

export const getDescriptionItemSkeleton = () => descriptionItemSkeleton

/**
 *
 * @param { immutable.record } event
 * @param { boolean } hasOpenedDates
 * @returns { object }
 * example: {
 *  hasError: boolean,
 *  errors Dictionary<boolean>
 * }
 */

export const getExperienceAppearanceInSearchError = (event, hasOpenedDates) => {
  const userTags = event.user.tags
  const eventTags = event.tags

  const doesNotHaveVizeatTag = !eventTags.includes(TAGS.VIZEAT)
  const hostHasChargesDisabled = userTags.includes(TAGS.STRIPE_CHARGES_DISABLED)
  const eventIsUnpublished = !event.published_at
  const hasFutureDateClosed = !hasOpenedDates
  const reindexHasNotFinished =
    !event.published_at || !event.indexed_at || moment.utc(event.indexed_at).add(5, 'minutes').isAfter(moment.utc())

  const errors = {
    doesNotHaveVizeatTag,
    hostHasChargesDisabled,
    eventIsUnpublished,
    hasFutureDateClosed,
    reindexHasNotFinished,
  }

  return {
    hasError: Object.values(errors).some((error) => !!error),
    errors,
  }
}
