// Libs
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
// Components
import { Link } from 'react-router'
import { Grid, Modal, Button, Message, Divider } from 'semantic-ui-react'
import Sticky from 'react-stickynode'
import { EventIdMenu } from './Menu'
import { UserCard } from 'components/cards/UserCard'
import { EventIdUserForm } from './UserForm'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { EventForm } from './Form'
// Redux
import { fetchStatics, fetchEvent, fetchUser, fetchUserFiles, fetchTags } from 'redux/entities/actions'
import {
  getAlcohols,
  getAmenities,
  getCurrencies,
  getFoods,
  getDiets,
  getTags,
  fetchingEvent,
  getEvent,
  getEventError,
  getStaticsError,
} from 'redux/entities/selectors'
import { connectAs } from 'helpers/users'

const mapStateToProps = (state, props) => ({
  statics: {
    alcohols: getAlcohols(state),
    amenities: getAmenities(state),
    foods: getFoods(state),
    diets: getDiets(state),
    currencies: getCurrencies(state),
  },
  tags: getTags(state),
  event: getEvent(state, { id: props.params.id }),
  loading: fetchingEvent(state, props.params.id),
  error: getEventError(state, props.params.id) || getStaticsError(state),
})

const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    loadResources: (resources) => dispatch(fetchStatics(resources)),
    loadTags: () => dispatch(fetchTags()),
    loadEvent: () => dispatch(fetchEvent(props.params.id)),
    loadUser: (id) => dispatch(fetchUser(id)),
    loadGallery: (userId) => dispatch(fetchUserFiles(userId)),
  },
})

class EventIdLayout extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    actions: PropTypes.object,
    statics: PropTypes.object,
    event: PropTypes.object,
    error: PropTypes.object,
    loading: PropTypes.bool,
    form: PropTypes.instanceOf(Map),
  }

  state = { open: false, error: null, initialized: false }

  UNSAFE_componentWillMount() {
    const promises = []
    const resourcesToLoad = Object.keys(this.props.statics).filter((r) => !this.props.statics[r].size)
    if (resourcesToLoad.length) promises.push(this.props.actions.loadResources(resourcesToLoad))

    return Promise.all(promises)
      .then(() => this.props.actions.loadEvent())
      .then(() =>
        Promise.all([
          this.props.actions.loadUser(this.props.event.user.id),
          this.props.actions.loadGallery(this.props.event.user.id),
          this.props.actions.loadTags(),
        ]),
      )
      .then(() => this.setState({ error: this.props.error, initialized: true }))
  }

  render() {
    const { t, event } = this.props
    if (!this.state.initialized) return null
    return (
      <Grid padded>
        <Modal open={this.state.open} onClose={() => this.setState({ open: false })} closeIcon size='small'>
          <Modal.Header>
            {t('Experiences::Summary::Edit user n° {{userId}}: {{firstName}} {{lastName}}', {
              userId: event.user.id,
              firstName: event.user.firstname,
              lastName: event.user.lastname,
            })}
          </Modal.Header>
          <Modal.Content>
            <EventIdUserForm userId={event.user.id} />
          </Modal.Content>
        </Modal>

        <Grid.Row>
          <Grid.Column width={3}>
            <Sticky>
              <EventIdMenu
                event={event}
                onDuplicateEvent={(prevEventId) => this.setState({ duplicatedFrom: prevEventId })}
              />
            </Sticky>
          </Grid.Column>

          <Grid.Column width={10}>
            {!!this.state.duplicatedFrom && (
              <Message info>
                <Message.Content>
                  {t(
                    'Experiences::Summary::This experience has been duplicated from the experience n° {{experienceId}}',
                    {
                      experienceId: this.state.duplicatedFrom,
                    },
                  )}
                </Message.Content>
              </Message>
            )}

            <EventForm event={event} />
          </Grid.Column>

          <Grid.Column width={3}>
            <Sticky top={15}>
              {event.user && event.user.id && (
                <UserCard user={event.user} connectAs={() => connectAs(event.user.account_id)}>
                  <div style={{ textAlign: 'center' }}>
                    <Button as={Link} primary basic compact to={{ pathname: `/users/${event.user.id}/bank-accounts` }}>
                      {t('UserCard::View payout details')}
                    </Button>
                    <Divider horizontal />
                    <Button onClick={() => this.setState({ open: true })}>
                      {t('Experiences::Summary::Edit user')}
                    </Button>
                  </div>
                </UserCard>
              )}
            </Sticky>
          </Grid.Column>
        </Grid.Row>
        <ApiErrorMessage error={this.state.error} modal />
      </Grid>
    )
  }
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(EventIdLayout))
