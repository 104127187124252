import { deadlines } from '@vizeat/helpers'
const { formatCancellationDeadlines, formatBookingDeadlines, isCancellationDeadlineNonRefundable } = deadlines

const UNITS_TRANSLATIONS = {
  singular: {
    years: __('Deadlines::{{count}} year'),
    months: __('Deadlines::{{count}} month'),
    days: __('Deadlines::{{count}} day'),
    hours: __('Deadlines::{{count}} hour'),
    weeks: __('Deadlines::{{count}} week'),
    minutes: __('Deadlines::{{count}} minute'),
  },
  plural: {
    years: __('Deadlines::{{count}} years'),
    months: __('Deadlines::{{count}} months'),
    days: __('Deadlines::{{count}} days'),
    hours: __('Deadlines::{{count}} hours'),
    weeks: __('Deadlines::{{count}} weeks'),
    minutes: __('Deadlines::{{count}} minutes'),
  },
}

const formatText = (t) => (unit, count) => t(UNITS_TRANSLATIONS[count === 1 ? 'singular' : 'plural'][unit], { count })

export function getBookingDeadlineOptions(t, config = {}) {
  return formatBookingDeadlines({
    ...config,
    formatText: formatText(t),
  }).map(({ duration, formattedDuration }) => {
    const text = formattedDuration
      ? t('Deadlines::{{duration}} before the event', { duration: formattedDuration })
      : t('Deadlines::BookingDeadline::The time of the event')
    return { text, value: duration }
  })
}

export function getCancellationDeadlineOptions(t, config = {}) {
  return formatCancellationDeadlines({
    ...config,
    formatText: formatText(t),
  }).map(({ duration, formattedDuration }) => {
    const text = isCancellationDeadlineNonRefundable(duration)
      ? t('Deadlines::CancellationDeadline::Non refundable cancellation')
      : formattedDuration
      ? t('Deadlines::{{duration}} before the event', { duration: formattedDuration })
      : t('Deadlines::CancellationDeadline::At the time of the event')
    return { text, value: duration }
  })
}
