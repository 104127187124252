// libs
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { konstants } from '@vizeat/helpers'
// components
import { Form, Checkbox, Button } from 'semantic-ui-react'
import { SearchInput, SelectInput } from 'components/inputs'
// helpers
import { buildOptionsWithArray } from 'helpers/forms'
// selectors
import { getLocation } from 'redux/reducers/router'
import { getSortedTags } from 'redux/entities/selectors'
import { Link } from 'react-router'

const mapStateToProps = (state) => ({
  location: getLocation(state),
  getTags: () => getSortedTags(state),
})

const { PLAYLIST_VISIBILITY } = konstants

export class PlaylistListSearchForm extends PureComponent {
  static contextTypes = { router: PropTypes.object }
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.object,
    getTags: PropTypes.func.isRequired,
  }

  tagsOptions = []
  UNSAFE_componentWillMount() {
    return this.updateStateFromLocation(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.location.equals(this.props.location)) return this.updateStateFromLocation(nextProps)
  }

  updateStateFromLocation(props) {
    return this.setState({
      name: props.location.getIn(['query', 'name'], undefined),
      locality: props.location.getIn(['query', 'locality'], undefined),
      country: props.location.getIn(['query', 'country'], undefined),
      tags: props.location.getIn(['query', 'tags'], undefined),
      type: props.location.getIn(['query', 'type'], undefined),
      visibility: props.location.getIn(['query', 'visibility'], undefined),
      showDeletedPlaylist: props.location.getIn(['query', 'showDeletedPlaylist'], undefined),
    })
  }

  getTypeOptions() {
    return [
      { text: this.props.t('Playlists::destination'), value: 'destination' },
      { text: this.props.t('Playlists::playlist'), value: 'playlist' },
    ]
  }

  getPlaylistVisibilityOptions() {
    return [
      { text: this.props.t('Playlists::public'), value: PLAYLIST_VISIBILITY.PUBLIC },
      { text: this.props.t('Playlists::private'), value: PLAYLIST_VISIBILITY.PRIVATE },
    ]
  }

  getTagsOptions() {
    if (this.tagsOptions.length === 0) {
      const tags = this.props.getTags()
      if (tags.size) this.tagsOptions = buildOptionsWithArray(tags.map((t) => t.title).unshift(''))
    }
    return this.tagsOptions
  }

  updateQuery(query = this.state) {
    return this.context.router.push(this.props.location.mergeIn(['query'], { ...query, offset: 0 }).toJS())
  }

  clearSorting() {
    return this.context.router.push(
      this.props.location.mergeIn(['query'], { sortBy: undefined, order: undefined }).toJS(),
    )
  }

  clearFilters() {
    return this.setState(
      {
        name: undefined,
        locality: undefined,
        country: undefined,
        tags: undefined,
        type: undefined,
        visibility: undefined,
        showDeletedPlaylist: undefined,
      },
      this.updateQuery,
    )
  }

  handleSubmit(e) {
    e.preventDefault()
    return this.updateQuery()
  }

  render() {
    const { t } = this.props

    return (
      <Form onSubmit={this.handleSubmit.bind(this)} autoComplete='off'>
        <Form.Group widths='equal'>
          <Form.Field>
            <label>{t('Playlists::Search')}</label>
            <SearchInput
              placeholder={t('Playlists::Id, name...')}
              fluid
              icon='search'
              iconPosition='left'
              value={this.state.name || ''}
              onChange={(value) => this.setState({ name: value })}
            />
          </Form.Field>
          <Form.Field>
            <label>{t('Playlists::Locality')}</label>
            <SearchInput
              queryName='locality'
              placeholder={t('Playlists::Locality')}
              icon='search'
              iconPosition='left'
              value={this.state.locality || ''}
              onChange={(value) => this.setState({ locality: value })}
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Playlists::Country')}</label>
            <SearchInput
              queryName='country'
              placeholder={t('Playlists::Country')}
              icon='search'
              iconPosition='left'
              value={this.state.country || ''}
              onChange={(value) => this.setState({ country: value })}
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Playlists::Type')}</label>
            <SelectInput
              queryName='type'
              placeholder={t('Playlists::Type')}
              search
              options={this.getTypeOptions()}
              value={this.state.type || ''}
              onChange={(value) => this.setState({ type: value })}
              preventNavigation
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Playlists::Visibility')}</label>
            <SelectInput
              queryName='visibility'
              placeholder={t('Playlists::Public or private')}
              search
              options={this.getPlaylistVisibilityOptions()}
              value={this.state.visibility || ''}
              onChange={(value) => this.setState({ visibility: value })}
              preventNavigation
            />
          </Form.Field>

          <Form.Field>
            <label>{t('Playlists::Filter by tags')}</label>
            <SelectInput
              queryName='tags'
              placeholder={t('Playlists::Type in tag')}
              multiple
              search
              options={this.getTagsOptions()}
              value={this.state.tags || ''}
              preventNavigation
              onChange={(value) => this.setState({ tags: value.join(',') })}
            />
          </Form.Field>
          <Form.Field>
            <label>{t('Playlists::Show archived playlists')}</label>
            <Checkbox
              onChange={(_, { checked }) => this.setState({ showDeletedPlaylist: checked })}
              checked={String(this.state.showDeletedPlaylist) === 'true'}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Field>
            <label>&nbsp;</label>
            <Button type='submit'>{t('Playlists::Search')}</Button>

            <Button.Group vertical basic compact size='small' style={{ transform: 'translateY(-15px)' }}>
              <Button onClick={() => this.clearFilters()}>{t('Playlists::Clear filters')}</Button>
              <Button onClick={() => this.clearSorting()}>{t('Playlists::Clear sorting')}</Button>
            </Button.Group>
            <Button as={Link} to='/playlists/add' primary style={{ transform: 'translateX(15px)' }}>
              {t('Playlists::Create new')}
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    )
  }
}

export default withTranslation('common')(connect(mapStateToProps)(PlaylistListSearchForm))
