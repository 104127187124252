import React, { Fragment } from 'react'
import PropTypes from 'helpers/proptypes'
import moment from 'moment'
import { Grid, Segment } from 'semantic-ui-react'
import { withTranslation, Trans } from 'react-i18next'

const _FollowupsList = ({ t, followups }) => {
  if (followups.size === 0) return null
  return (
    <Segment>
      <Grid>
        {followups
          .sort((f1, f2) => f2.date - f1.date)
          .map((followup, index) => (
            <Fragment key={index}>
              <Grid.Column width={4}>
                <label>{t('FollowUps::Action: {{action}}', { action: followup.action })}</label>
              </Grid.Column>
              <Grid.Column width={4}>
                <label>{t('FollowUps::Date: {{date}}', { date: moment.utc(followup.created_at).format('lll') })}</label>
              </Grid.Column>
              <Grid.Column width={4}>
                <label>
                  <Trans
                    t={t}
                    i18nKey={__('FollowUps::Admin: {{admin}}')}
                    values={{ admin: followup.admin ? followup.admin.get('fullname') : t('FollowUps::UNKNOWN ADMIN') }}
                  />
                </label>
              </Grid.Column>
              <Grid.Column width={4}>
                <label>{t('FollowUps::Comment: {{comment}}', { comment: followup.comment })}</label>
              </Grid.Column>
            </Fragment>
          ))}
      </Grid>
    </Segment>
  )
}

_FollowupsList.propTypes = {
  t: PropTypes.func.isRequired,
  followups: PropTypes.immutable.list.isRequired,
}

export const FollowupsList = withTranslation('common')(_FollowupsList)
