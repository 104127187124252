import { schema } from 'normalizr'
import { Record, List, Map, fromJS } from 'immutable'

import { users } from './users'
import { places } from './places'
import { files } from './files'
import { overrides } from './overrides'
import { alcohols, currencies, diets, foods } from './statics'

const _schema = new schema.Entity('events', {
  alcohols: [alcohols.schema],
  cover: files.schema,
  currency: currencies.schema,
  diets: [diets.schema],
  files: [files.schema],
  food: foods.schema,
  place: places.schema,
  user: users.schema,
  overrides: overrides.schema,
})

const _Record = Record({
  id: undefined,

  alcohols: new List(),
  begins_at: '',
  booking_deadline: undefined,
  cancellation_deadline: undefined,
  cover: new files.Record(),
  created_at: undefined,
  currency: new currencies.Record(),
  currency_id: undefined,
  description: '',
  descriptions: new List(),
  diets: new List(),
  ends_at: '',
  ewid: undefined,
  fees_rate: undefined,
  files: new List(),
  followups: new List(),
  food: new foods.Record(),
  food_id: undefined,
  frozen_at: undefined,
  indexed_at: undefined,
  instant_booking: false,
  max_seats: undefined,
  min_seats: undefined,
  metadata: fromJS({
    euro_pricing: {
      event_price: undefined,
    },
  }),
  overrides: new Map(),
  pe_booking_deadline: undefined,
  pe_cancellation_deadline: undefined,
  place: new places.Record(),
  place_id: undefined,
  price: undefined,
  priceWithFees: undefined,
  privatized_at: undefined,
  privatized_by: undefined,
  properties: new Map(),
  published_at: undefined,
  situation: undefined,
  status: '',
  tags: new List(),
  title: '',
  type: '',
  updated_at: undefined,
  user: new users.Record(),
  user_id: undefined,
  webpage_url: '',
  allow_private_bookings: undefined,
  allow_public_bookings: undefined,
  submitted_at: undefined,
})

export const events = {
  schema: _schema,
  Record: _Record,
}
