import settings from 'settings'
// Libs
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslation, Trans } from 'react-i18next'
// Hooks
import { usePrevious } from '../../hooks/usePrevious'
// Components
import { Link } from 'react-router'
import { Card, List, Button, Dropdown } from 'semantic-ui-react'
// Helpers
import { getTranslatedOptions } from 'helpers/options'
import { fileFormatOptions } from 'helpers/files'
// Style
import './FileInfo.css'

const copyToClipboard = (format, file) => {
  const ucarePath = format === 'original' ? '/-/raw' : `/-/scale_crop/${format}/center/-/progressive/yes`
  const tmp = document.createElement('input')
  tmp.value = settings.globalUrlFactory.screen.files.transform(file.id, ucarePath)
  document.body.appendChild(tmp)
  tmp.select()
  document.execCommand('copy')
  tmp.remove()
}

const FileInfo = ({ file, onFocus }) => {
  const [t] = useTranslation()
  const didMount = usePrevious(false)
  const [format, setFormat] = useState('original')

  useEffect(() => {
    if (didMount) copyToClipboard(file, format)
  }, [didMount, format, copyToClipboard])

  return (
    <Card className='vz-file-card'>
      <Card.Content>
        <div className='menu'>
          <Button
            label={
              <Dropdown
                defaultValue={format}
                options={getTranslatedOptions(t, fileFormatOptions)}
                onChange={(e, { value }) => setFormat(value)}
              />
            }
            icon='linkify'
            title={t('Gallery::Copy image link')}
            onClick={() => copyToClipboard(format, file)}
            labelPosition='right'
            basic
          />
        </div>
        <Card.Header>
          <img
            className='__file-thumbnail'
            src={settings.globalUrlFactory.screen.files.transform(file.id, '/-/preview/300x300/-/progressive/yes')}
            onClick={() => onFocus(file.path)}
          />
        </Card.Header>
        <List>
          <List.Item>
            <Trans
              i18nKey={__('Gallery::<strong>Uploaded on: </strong>{{date}}')}
              values={{ date: moment(file.created_at).format('ll') }}
              components={{ strong: <strong /> }}
            />
          </List.Item>
          {file.updated_at && (
            <List.Item>
              <Trans
                i18nKey={__('Gallery::<strong>Last updated on: </strong>{{date}}')}
                values={{ date: moment(file.updated_at).format('ll') }}
                components={{ strong: <strong /> }}
              />
            </List.Item>
          )}
          {file.attachable_type && (
            <List.Item>
              <Trans
                i18nKey={__('Gallery::<strong>Attached to: </strong><a>{{fileTitle}} - {{fileId}}</a>')}
                values={{ fileTitle: file.attachable_title || file.attachable_type, fileId: file.attachable_id }}
                components={{
                  strong: <strong />,
                  a: <Link to={`/${file.attachable_type}?search=${file.attachable_id}`} />,
                }}
              />
            </List.Item>
          )}
        </List>
      </Card.Content>
    </Card>
  )
}

FileInfo.propTypes = {
  file: PropTypes.object.isRequired,
  onFocus: PropTypes.func.isRequired,
}

export default FileInfo
